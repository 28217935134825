import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState
} from 'react';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import { getStartDateForTimeRange } from 'ecto-common/lib/types/TimeRangeOptions';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import { TimeRangeOptionsText } from 'ecto-common/lib/types/TimeRangeOptions';
import styles from './TimeRangeSelector.module.css';

import DropdownButton, {
  DropdownButtonOptionType
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import moment from 'moment';
import TimeRangeCalendar from 'ecto-common/lib/TimeRangeSelector/TimeRangeCalendar';
import RadioButton from 'ecto-common/lib/RadioButton/RadioButton';
import { Moment } from 'moment/moment';

export type TimeRangeSelectOption = {
  label: string;
  value: TimeRangeOptions;
};

export const TIME_RANGE_OPTIONS: TimeRangeSelectOption[] = _.map(
  TimeRangeOptionsText,
  (label, value: TimeRangeOptions) => ({ label, value })
);

const buttonLabel = (referenceDate: Moment, timeRange: TimeRangeOptions) => {
  if (referenceDate === null) {
    return TimeRangeOptionsText[timeRange];
  }

  switch (timeRange) {
    case TimeRangeOptions.HOUR:
      return referenceDate.format('YYYY-MM-DD HH:mm');
    case TimeRangeOptions.DAY:
      return referenceDate.format('YYYY-MM-DD');
    case TimeRangeOptions.WEEK:
      return (
        referenceDate.format('YYYY-MM-DD') +
        ' - ' +
        moment(referenceDate).add(7, 'day').format('MM-DD')
      );
    case TimeRangeOptions.MONTH:
      return referenceDate.format('YYYY-MM');
    case TimeRangeOptions.YEAR:
      return referenceDate.format('YYYY');
    case TimeRangeOptions.FIVE_YEARS_BACK:
      return (
        referenceDate.format('YYYY') +
        ' - ' +
        moment(referenceDate).add(5, 'years').format('YYYY')
      );
    default:
      return '';
  }
};

interface TimeRangeSelectorProps {
  value: TimeRangeOptions;
  options?: TimeRangeSelectOption[];
  onValueChanged(value: TimeRangeOptions): void;
  referenceDate?: Moment;
  onReferenceDateChanged?: Dispatch<SetStateAction<Moment>>;
}

const TimeRangeSelector = ({
  value,
  options,
  onValueChanged,
  onReferenceDateChanged,
  referenceDate
}: TimeRangeSelectorProps) => {
  const [useCustomDate, setUseCustomDate] = useState(referenceDate != null);

  const calendarView = useCustomDate && (
    <div className={styles.calendarWrapper}>
      <TimeRangeCalendar
        referenceDate={referenceDate}
        onReferenceDateChanged={onReferenceDateChanged}
        timeRangeOption={value}
      />
    </div>
  );

  useEffect(() => {
    if (!useCustomDate) {
      onReferenceDateChanged(null);
    }
  }, [useCustomDate, onReferenceDateChanged]);

  useEffect(() => {
    onReferenceDateChanged((oldRefDate) => {
      if (oldRefDate != null) {
        return getStartDateForTimeRange(value, oldRefDate);
      }

      return null;
    });
  }, [onReferenceDateChanged, value]);

  const modOptions: DropdownButtonOptionType[] = useMemo(() => {
    return [
      {
        icon: null,
        label: (
          <div className={styles.menuHeader}>
            {T.dashboard.timerange.durationheader}
          </div>
        ),
        disableCloseOnClick: true
      },
      ...options.map((option) => ({
        ...option,
        icon: (
          <RadioButton
            className={styles.radioButton}
            checked={option.value === value}
          />
        ),
        action: () => {
          onValueChanged(option.value);
        },
        disableCloseOnClick: true
      })),
      {
        icon: null,
        label: (
          <span className={styles.menuHeader}>
            {T.dashboard.timerange.positionheader}
          </span>
        ),
        disableCloseOnClick: true
      },
      {
        icon: <Checkbox checked={useCustomDate} />,
        label: T.dashboard.timerange.custom,
        disableCloseOnClick: true,
        action: () => {
          setUseCustomDate((wasUsingCustomDate) => !wasUsingCustomDate);
        }
      }
    ];
  }, [options, onValueChanged, value, useCustomDate]);

  return (
    <DropdownButton
      withArrow
      options={modOptions}
      footer={calendarView}
      className={styles.button}
      closeButtonText={T.common.done}
      optionsMenuClassName={styles.optionsMenu}
    >
      <div>
        <label>{T.graphs.timerange.title}:</label>{' '}
        {buttonLabel(referenceDate, value)}{' '}
      </div>
    </DropdownButton>
  );
};

export default React.memo(TimeRangeSelector);
