import React, { useCallback } from 'react';
import { AuthError, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';

import LoadingScreen from 'ecto-common/lib/LoadingScreen/LoadingScreen';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';

import styles from './AuthenticationWrapper.module.css';

type AuthenticationErrorProps = {
  error?: AuthError;
};

export const AuthenticationErrorComponent = ({
  error
}: AuthenticationErrorProps) => {
  const { instance } = useMsal();

  const handleClick = useCallback(() => {
    instance.logoutRedirect({
      postLogoutRedirectUri: '/'
    });
  }, [instance]);

  return (
    <div className={styles.baseContainer}>
      <div className={styles.content}>
        <div className={styles.text}>
          <strong>{T.authenticate.authentification.failure}</strong>

          <div className={styles.errorMessage}>{error?.errorMessage}</div>

          <Button onClick={handleClick}>{T.authenticate.login.again}</Button>
        </div>
      </div>
    </div>
  );
};

const LoadingComponent = () => (
  <div className={styles.baseContainer}>
    <LoadingScreen isLoading />
  </div>
);

interface AuthenticationWrapperProps {
  authenticationRequest: object;
}

const AuthenticationWrapper = ({
  authenticationRequest
}: AuthenticationWrapperProps) => (
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={authenticationRequest}
    errorComponent={AuthenticationErrorComponent}
    loadingComponent={LoadingComponent}
  />
);

export default AuthenticationWrapper;
