import React, {
  useContext,
  useEffect,
  useState,
  useSyncExternalStore
} from 'react';

import ToastContainer, {
  toastStore
} from 'ecto-common/lib/Toast/ToastContainer';
import Layout from 'ecto-common/lib/Layout/Layout';
import ContentArea from 'ecto-common/lib/Layout/ContentArea/ContentArea';
import { setUserSettings } from 'js/actions/settings';
import BaseContainer from 'ecto-common/lib/BaseContainer/BaseContainer';
import T from 'ecto-common/lib/lang/Language';
import { AuthenticationErrorComponent } from 'ecto-common/lib/AuthenticationWrapper/AuthenticationWrapper';
import { setSignalTypes } from 'ecto-common/lib/actions/setSignalTypes';
import { setEnums } from 'ecto-common/lib/actions/getEnums';
import { setSignalTypeFolders } from 'ecto-common/lib/actions/setSignalTypeFolders';
import useAuthentication from 'ecto-common/lib/hooks/useAuthentication';

import styles from 'js/containers/OperatorContainer.module.css';
import 'css/global.css';
import LoadingScreenWithMenu from 'ecto-common/lib/LoadingScreen/LoadingScreenWithMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import { setNodeTags } from 'ecto-common/lib/actions/getNodeTags';
import { setEquipmentTypes } from 'ecto-common/lib/actions/getEquipmentTypes';
import { useOperatorDispatch } from 'js/reducers/storeOperator';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants/index';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import { AuthError } from '@azure/msal-browser';
import APIGen from 'ecto-common/lib/API/APIGen';
import { useInitialUserSettings } from 'ecto-common/lib/Application/useInitialUserSettings';
import {
  AlarmEvent,
  AlarmUpdateProvider,
  AlarmUpdateContext,
  AlarmEventTypes
} from 'ecto-common/lib/Alarms/V2/useAlarmUpdates';
import { Outlet } from 'react-router-dom';
import { getAlarmUrlV2 } from 'js/utils/routeConstants';
import { NavLink } from 'react-router-dom';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import { SET_ROOT_LEVEL_NODES } from 'ecto-common/lib/actions/actionTypes';

export function getNodesPromise(
  contextSettings: ApiContextSettings,
  signal: AbortSignal
) {
  return Promise.all([
    APIGen.Nodes.getNodes.promise(contextSettings, {}, signal),
    APIGen.Nodes.getGrids.promise(contextSettings, signal)
  ] as const);
}

const AlarmToastEmitter = ({ children }: { children: React.ReactNode }) => {
  const { tenantId } = useContext(TenantContext);
  const { addListener, removeListener } = useContext(AlarmUpdateContext);

  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  useEffect(() => {
    const listener = (data: AlarmEvent) => {
      if (!featureFlagState.newalarms) {
        return;
      }

      if (data.eventType === AlarmEventTypes.Activated) {
        // TODO: New domain model, implement this
        // const equipment = equipmentMap[data.nodeId];
        // const node =
        //   equipment != null ? nodeMap[equipment.nodeId] : nodeMap[data.nodeId];

        const name = '';
        // equipment != null
        //   ? equipment.name + ' - ' + (node?.name ?? '')
        //   : node?.name;

        let alarmText = '';

        if (!isNullOrWhitespace(data.signalName)) {
          alarmText = `${data.signalName} - ${name}`;
        } else {
          alarmText = name ?? T.common.unknown;
        }
        // TODO: New domain model fix this
        const url = getAlarmUrlV2(tenantId, data.nodeId, null);

        const alarmMessage = T.format(
          T.alarms.alarmtriggeredformat,
          <NavLink key="navlink" to={url}>
            {alarmText}
          </NavLink>
        );

        toastStore.addSuccessToast(alarmMessage);
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, featureFlagState.newalarms, removeListener, tenantId]);

  return <> {children} </>;
};

const CoreWrapper = React.memo(() => {
  const { tenantId } = useContext(TenantContext);
  const { userId } = useContext(UserContext);
  const dispatch = useOperatorDispatch();
  const apiEnvironment = getApiEnvironment();
  const { isAuthenticated, instance, currentAccount } = useAuthentication(
    apiEnvironment.scopes.gateway
  );

  const queryOptions = {
    enabled: userId != null && tenantId != null,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  };

  // This is really ugly, but needed to work around syncing between react query and redux.
  // If we only rely on loading state on the query return object, then the UI will render
  // a frame while the data has been loaded, but not synced to redux yet.
  //
  // And if we add the dispatch as part of the promise chain in the query, then it will
  // not run if the query is cached.
  const [loadingState, setLoadingState] = useState({
    nodeTags: true,
    equipmentTypes: true,
    enums: true,
    signalTypes: true,
    signalTypeFolders: true,
    rootLevelNodes: true
  });

  const nodeTagsQuery = APIGen.Nodes.getNodeTags.useQuery(queryOptions);

  useEffect(() => {
    if (nodeTagsQuery.data) {
      dispatch(setNodeTags(nodeTagsQuery.data));
      setLoadingState((prev) => ({ ...prev, nodeTags: false }));
    }
  }, [dispatch, nodeTagsQuery.data]);

  const rootLevelNodesQuery = APIGen.NodesV2.getNodeChildren.useQuery(
    {
      nodeIds: []
    },
    queryOptions
  );

  useEffect(() => {
    if (rootLevelNodesQuery.data) {
      dispatch({
        type: SET_ROOT_LEVEL_NODES,
        payload: {
          nodes: rootLevelNodesQuery.data
        }
      });
      setLoadingState((prev) => ({ ...prev, rootLevelNodes: false }));
    }
  }, [dispatch, rootLevelNodesQuery.data]);

  const userSettingsQuery =
    IdentityServiceAPIGenV2.TenantUser.getUserSettings.useQuery(queryOptions);

  useEffect(() => {
    if (userSettingsQuery.data) {
      const result = userSettingsQuery.data;
      const settings =
        result?.settings && typeof result?.settings === 'string'
          ? JSON.parse(result.settings)
          : {};
      dispatch(setUserSettings(settings));
      setLoadingState((prev) => ({ ...prev, userSettings: false }));
    }
  }, [dispatch, userSettingsQuery.data]);

  useEffect(() => {
    if (userSettingsQuery.isError) {
      dispatch(setUserSettings({}));
    }
  }, [dispatch, userSettingsQuery.data, userSettingsQuery.isError]);

  const equipmentTypesQuery =
    APIGen.Equipments.getEquipmentTypes.useQuery(queryOptions);

  useEffect(() => {
    if (equipmentTypesQuery.data) {
      dispatch(setEquipmentTypes(equipmentTypesQuery.data));
      setLoadingState((prev) => ({ ...prev, equipmentTypes: false }));
    }
  }, [dispatch, equipmentTypesQuery.data]);

  const signalTypesQuery =
    APIGen.AdminSignalTypes.getAllSignalTypes.useQuery(queryOptions);

  useEffect(() => {
    if (signalTypesQuery.data) {
      dispatch(setSignalTypes(signalTypesQuery.data));
      setLoadingState((prev) => ({ ...prev, signalTypes: false }));
    }
  }, [dispatch, signalTypesQuery.data]);

  const signalTypeFoldersQuery =
    APIGen.AdminSignalTypeFolders.getAllSignalTypeFolders.useQuery(
      queryOptions
    );

  const enumsQuery =
    APIGen.Enums.getEnumsAndFixedConfigurations.useQuery(queryOptions);

  useEffect(() => {
    if (signalTypeFoldersQuery.data) {
      dispatch(setSignalTypeFolders(signalTypeFoldersQuery.data));
      setLoadingState((prev) => ({ ...prev, signalTypeFolders: false }));
    }
  }, [dispatch, signalTypeFoldersQuery.data]);

  useEffect(() => {
    if (enumsQuery.data) {
      dispatch(setEnums(enumsQuery.data));
      setLoadingState((prev) => ({ ...prev, enums: false }));
    }
  }, [dispatch, enumsQuery.data]);

  const hasError =
    enumsQuery.isError ||
    nodeTagsQuery.isError ||
    equipmentTypesQuery.isError ||
    signalTypesQuery.isError ||
    signalTypeFoldersQuery.isError;

  useEffect(() => {
    if (hasError && isAuthenticated) {
      toastStore.addErrorToast(T.common.baserequesterror);
    }
  }, [hasError, isAuthenticated]);

  const isLoading = Object.values(loadingState).some((v) => v);

  if (isLoading) {
    return <LoadingScreenWithMenu isLoading />;
  }

  return (
    <BaseContainer msalConfiguration={instance} currentAccount={currentAccount}>
      <AlarmUpdateProvider>
        <AlarmToastEmitter>
          <Outlet />
        </AlarmToastEmitter>
      </AlarmUpdateProvider>
    </BaseContainer>
  );
});

const OperatorContainerContent = React.memo(() => {
  const apiEnvironment = getApiEnvironment();

  const { isLoadingTenants, tenantsFailedToLoad, tenantResources } =
    useContext(TenantContext);
  const {
    isLoading: authenticationIsLoading,
    errorMessage,
    instance,
    currentAccount,
    isLoggingOut
  } = useAuthentication(apiEnvironment.scopes.gateway);
  let _errorMessage = errorMessage;

  const userSettingsQuery = useInitialUserSettings(
    !!currentAccount && !_errorMessage
  );

  const isLoading =
    isLoadingTenants ||
    authenticationIsLoading ||
    isLoggingOut ||
    userSettingsQuery.isLoading;

  if (tenantsFailedToLoad) {
    _errorMessage = AuthError.createUnexpectedError(
      T.tenants.error.failedtoload
    );
  }

  let content: React.ReactNode = null;

  if (isLoading) {
    content = <LoadingScreenWithMenu isLoading />;
  } else if (hasAccessToResource(ResourceType.CORE, tenantResources)) {
    content = <CoreWrapper />;
  } else if (currentAccount && !_errorMessage) {
    content = (
      <BaseContainer
        msalConfiguration={instance}
        currentAccount={currentAccount}
      >
        <Outlet />
      </BaseContainer>
    );
  }

  return (
    <>
      {!_errorMessage ? (
        <div className={styles.baseContainer}>{content}</div>
      ) : (
        <AuthenticationErrorComponent error={_errorMessage} />
      )}
    </>
  );
});

const OperatorContainer = () => {
  const { tenantId } = useContext(TenantContext);

  return (
    <Layout
      contentArea={
        <ContentArea>
          <OperatorContainerContent key={tenantId} />
          <ToastContainer />
        </ContentArea>
      }
    />
  );
};

export default OperatorContainer;
