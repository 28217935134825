import React, { useCallback, useContext, useMemo, useRef } from 'react';
import _ from 'lodash';
import {
  EctoplannerBalancingUnitInfo,
  EctoplannerBalancingUnitPhotovoltaicTable,
  EctoplannerBalancingUnitTable,
  EctoplannerBuildingDemandsInputTable,
  EctoplannerBuildingEquipmentTable,
  EctoplannerCapexBuildingsTable,
  EctoplannerEcologicKPIsTable,
  EctoplannerCostsTable,
  EctoplannerCumulatedInstallationsTable,
  EctoplannerEndEnergyDemandTable,
  EctoplannerEnergyDemandsTable,
  EctoplannerStorageTable
} from 'js/components/Ectoplanner/EctoplannerResultModels';
import styles from 'js/components/Ectoplanner/EctoplannerResults.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import {
  EctoplannerCoolingDemandsGraph,
  EctoplannerElectricityImportMonthlyGraph,
  EctoplannerEndEnergyGraph,
  EctoplannerHeatingDemandsGraph,
  EctoplannerOperationsCostsGraph,
  EctoplannerOtherImportMonthlyGraph
} from 'js/components/Ectoplanner/EctoplannerResultGraphs';

import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import CollapsingSegmentControlPicker from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import EctoplannerBox from 'js/components/Ectoplanner/EctoplannerBox';
import {
  EctoplannerParams,
  getEctoplannerResultsUrl,
  getEctoplannerUrl
} from 'js/utils/routeConstants';
import classNames from 'classnames';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  EctoplannerForm,
  EctoplannerResultAndFlagsType,
  EctoplannerResultType
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import EctoplannerAPIGen, {
  BuildResponse,
  ProjectResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import {
  EctoplannerBuildStatus,
  areEctoplannerResultsAvailable
} from 'js/components/Ectoplanner/EctoplannerTypes';
import ProgressBar, {
  ProgressBarSizes
} from 'ecto-common/lib/ProgressBar/ProgressBar';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownButton, {
  DropdownButtonMenuPosition
} from 'ecto-common/lib/DropdownButton/DropdownButton';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { getEctoplannerInfoView } from 'js/components/Ectoplanner/useEctoplannerFormOptions';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import EctoplannerGraphBrowser from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowser';
import { useQuery } from '@tanstack/react-query';
import {
  citiesAndAirTempPromise,
  compileFormPromise,
  insertEctoplannerDefaultValues
} from 'js/components/Ectoplanner/EctoplannerModelUtils';
import EctoplannerNetworkOverview from 'js/components/Ectoplanner/EctoplannerNetworkOverview';
import HttpStatus from 'ecto-common/lib/utils/HttpStatus';

const centeredGridStyle = {
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
  gridTemplateRows: 'auto auto'
};
const centeredGridStyleFullHeight = {
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)'
};
const centeredGridStyleAutoHeight = {
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
  gridTemplateRows: 'auto auto auto auto auto auto auto'
};
const centeredGridStyleAutoHeightTop = {
  gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
  gridTemplateRows: 'auto minmax(auto, 1fr)'
};
const printGridStyle = { display: 'block', width: '100%' };

interface EctoplannerResultContentViewProps {
  results: EctoplannerResultType;
  projectId: string;
  buildId: string;
  build?: BuildResponse;
  printMode?: boolean;
  project?: ProjectResponse;
  noSolutionFound: boolean;
  isLoading: boolean;
  curSection: string;
  isRunningCalculation: boolean;
  formIsUpToDate: boolean;
  calculationError: string;
  calculatedForm: EctoplannerForm;
}

const useCompiledBuildForm = (buildId: string, checksum: string = '') => {
  const { contextSettings } = useContext(TenantContext);

  const combinedQuery = useQuery({
    queryKey: ['combinedResults', buildId, checksum],

    queryFn: async ({ signal }) => {
      const formDataRes =
        await EctoplannerAPIGen.EctoGridBuilds.formdataDetail.promise(
          contextSettings,
          {
            buildId
          },
          signal
        );
      const [formData] = insertEctoplannerDefaultValues(
        formDataRes as EctoplannerForm
      );
      const currentWeatherStationId = formData?.location?.city?.id;

      if (currentWeatherStationId == null) {
        return [
          null as EctoplannerForm,
          T.ectoplanner.results.locationnotfound
        ] as const;
      }
      try {
        const [airTemp, cityData] = await citiesAndAirTempPromise({
          weatherStationId: currentWeatherStationId,
          signal,
          contextSettings
        });

        try {
          const calculationRes = await compileFormPromise({
            inputForm: formData,
            cityData,
            airTemp,
            calculationIndex: 0
          });

          return [calculationRes.form, null] as const;
        } catch (error) {
          return [
            null as EctoplannerForm,
            T.ectoplanner.results.genericresulterror
          ] as const;
        }
      } catch (error) {
        if (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (error as any)?.response.status === HttpStatus.NOT_FOUND
        ) {
          return [
            null as EctoplannerForm,
            T.ectoplanner.results.locationnotfound
          ] as const;
        }

        return [
          null as EctoplannerForm,
          T.ectoplanner.results.locationerror
        ] as const;
      }
    },

    enabled: buildId != null
  });

  return [
    buildId != null && combinedQuery.isLoading,
    combinedQuery.data?.[1],
    combinedQuery.data?.[0]
  ] as const;
};

const EctoplannerDemandBalancingResultsView = ({
  calculatedForm,
  calculationError
}: {
  calculatedForm: EctoplannerForm;
  calculationError: string;
}) => {
  return (
    <>
      <div className={styles.networkOverview}>
        {calculationError && <ErrorNotice>{calculationError} </ErrorNotice>}
        {calculatedForm && <EctoplannerNetworkOverview form={calculatedForm} />}
      </div>
      <div className={styles.newPage} />
    </>
  );
};

const EctoplannerResultContentView = ({
  results,
  projectId,
  project,
  buildId,
  printMode = false,
  build,
  isLoading,
  curSection = 'demandbalancing',
  noSolutionFound,
  isRunningCalculation,
  formIsUpToDate,
  calculationError,
  calculatedForm
}: EctoplannerResultContentViewProps) => {
  const { tenantId } = useContext(TenantContext);
  const excelQuery =
    EctoplannerAPIGen.EctoGridBuilds.excelfilelinkDetail.useQuery(
      {
        buildId
      },
      {
        enabled:
          build?.status === EctoplannerBuildStatus.ResultsDone || printMode
      }
    );

  const hasValidResults = results && !noSolutionFound;
  const headerRef: React.RefObject<HTMLDivElement> = useRef(null);

  const onScroll: React.UIEventHandler<HTMLDivElement> = useCallback((e) => {
    if (headerRef.current) {
      if ((e.target as HTMLElement).scrollTop > 0) {
        headerRef.current.classList.add(styles.toolbarShadow);
      } else {
        headerRef.current.classList.remove(styles.toolbarShadow);
      }
    }
  }, []);

  const options = useMemo(
    () =>
      _.compact([
        {
          label: T.ectoplanner.results.sections.demandbalancing,
          value: 'demandbalancing',
          view: hasValidResults && (
            <EctoplannerDemandBalancingResultsView
              calculatedForm={calculatedForm}
              calculationError={calculationError}
            />
          )
        },
        {
          label: T.ectoplanner.form.shared.endenergy,
          value: 'endenergy',
          view: hasValidResults && (
            <>
              <div
                className={classNames(styles.grid, styles.viewWithIcons)}
                style={printMode ? printGridStyle : centeredGridStyle}
              >
                <EctoplannerEndEnergyDemandTable results={results} />
                <EctoplannerEndEnergyGraph results={results} />
                <EctoplannerElectricityImportMonthlyGraph results={results} />
                <EctoplannerOtherImportMonthlyGraph results={results} />
              </div>
            </>
          )
        },

        {
          label: T.ectoplanner.results.sections.balancingunit,
          value: 'balancingunit',
          view: hasValidResults && (
            <div
              className={classNames(styles.grid, styles.viewWithIcons)}
              style={printMode ? printGridStyle : centeredGridStyleFullHeight}
            >
              <EctoplannerBox
                title={T.ectoplanner.results.balancingunit.heatingandcooling}
              >
                <EctoplannerBalancingUnitTable results={results} />
                <EctoplannerBalancingUnitInfo results={results} />
              </EctoplannerBox>
              <EctoplannerBox
                title={T.ectoplanner.results.balancingunit.renewablesandstorage}
              >
                <EctoplannerBalancingUnitPhotovoltaicTable results={results} />
                <EctoplannerStorageTable results={results} />
              </EctoplannerBox>
            </div>
          )
        },
        {
          label: T.ectoplanner.form.shared.buildings,
          value: 'buildings',
          view: hasValidResults && (
            <>
              <div
                className={styles.grid}
                style={printMode ? printGridStyle : centeredGridStyleAutoHeight}
              >
                <EctoplannerBox
                  style={{ gridColumn: '1 / span 2' }}
                  title={T.ectoplanner.results.buildings.titles.cumulated}
                >
                  <EctoplannerCumulatedInstallationsTable results={results} />
                </EctoplannerBox>
                <EctoplannerBox
                  title={
                    T.ectoplanner.results.buildings.titles.buildingequipment
                  }
                >
                  <EctoplannerBuildingEquipmentTable results={results} />
                </EctoplannerBox>
                <EctoplannerBox
                  title={T.ectoplanner.results.buildings.titles.capexbuildings}
                >
                  <EctoplannerCapexBuildingsTable results={results} />
                </EctoplannerBox>
                <EctoplannerBox
                  style={{ gridColumn: '1 / span 2' }}
                  title={T.ectoplanner.results.buildings.titles.energydemands}
                >
                  <EctoplannerEnergyDemandsTable results={results} />
                </EctoplannerBox>
              </div>
            </>
          )
        },
        {
          label: T.ectoplanner.results.sections.costs,
          value: 'costs',
          view: hasValidResults && (
            <div
              className={styles.grid}
              style={
                printMode ? printGridStyle : centeredGridStyleAutoHeightTop
              }
            >
              <EctoplannerBox title={T.ectoplanner.results.sections.costs}>
                <EctoplannerCostsTable results={results} />
              </EctoplannerBox>
              <EctoplannerOperationsCostsGraph results={results} />
            </div>
          )
        },
        {
          label: T.ectoplanner.results.sections.buildingdemands,
          value: 'buildingdemands',
          view: hasValidResults && (
            <div
              className={styles.grid}
              style={
                printMode ? printGridStyle : centeredGridStyleAutoHeightTop
              }
            >
              <EctoplannerBox
                style={{ gridColumn: '1 / span 2' }}
                title={T.ectoplanner.results.sections.buildingdemands}
              >
                <EctoplannerBuildingDemandsInputTable results={results} />
              </EctoplannerBox>
              <EctoplannerHeatingDemandsGraph results={results} />
              <EctoplannerCoolingDemandsGraph results={results} />
              <EctoplannerBox
                style={{ gridColumn: '1 / span 2' }}
                title={T.ectoplanner.results.ecologickpis.title}
              >
                <EctoplannerEcologicKPIsTable results={results} />
              </EctoplannerBox>
            </div>
          )
        },
        !printMode && {
          label: T.ectoplanner.graphs.title,
          value: 'graphs',
          view: hasValidResults && (
            <EctoplannerGraphBrowser
              build={build}
              timeseries={results?.timeseries}
              isRunningCalculation={isRunningCalculation}
              formIsUpToDate={formIsUpToDate}
            />
          )
        }
      ]),
    [
      hasValidResults,
      calculatedForm,
      calculationError,
      printMode,
      results,
      build,
      isRunningCalculation,
      formIsUpToDate
    ]
  );

  const params = useParams<EctoplannerParams>();
  const selectedOption =
    options.find((option) => option.value === curSection) ?? options[0];
  const navigate = useNavigate();

  const onChangeOption = useCallback(
    (newOption: (typeof options)[number]) => {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          projectId,
          params.buildId,
          'calculations',
          newOption.value
        )
      );
    },
    [navigate, params.buildId, params.projectType, projectId, tenantId]
  );

  let content;
  let showingInfoView = false;

  if (printMode) {
    content = (
      <div className={styles.printContainer}>
        {options.map((option, idx) => {
          return (
            <div className={styles.printSection} key={option.label}>
              {/* Show page title in first section to avoid issues with page breaks  */}
              {printMode && project && idx === 0 && (
                <div className={styles.projectHeader}>
                  {T.format(T.ectoplanner.results.pagetitle, project.name)}
                </div>
              )}

              <div className={styles.header}>{option.label}</div>
              {option.view}
            </div>
          );
        })}
      </div>
    );
  } else {
    const infoView = getEctoplannerInfoView(
      isRunningCalculation,
      noSolutionFound,
      results == null && !isLoading,
      build?.status as EctoplannerBuildStatus,
      styles.paddedArea
    );

    showingInfoView = infoView != null;

    if (infoView != null) {
      content = infoView;
    } else {
      content = selectedOption.view;
    }
  }

  const dropOptions = useMemo(
    () =>
      _.compact([
        hasValidResults && {
          icon: <Icons.Print />,
          label: T.ectoplanner.results.print,
          action: () => {
            const url = getEctoplannerResultsUrl(tenantId, projectId, buildId);

            // I want to open a new tab with this url
            window.open(url, '_blank');
          }
        },
        excelQuery.data?.link && {
          icon: <Icons.Download />,
          label: T.ectoplanner.results.download,
          action: () => {
            const url = excelQuery.data?.link;
            window.open(url);
          }
        }
      ]),
    [buildId, excelQuery.data?.link, hasValidResults, projectId, tenantId]
  );

  const infoViewDisplaysLoading = !noSolutionFound && isRunningCalculation;

  const containerContent = (
    <div className={styles.container}>
      {!printMode && (
        <Toolbar className={styles.fixedHeader} ref={headerRef}>
          <ToolbarItem className={styles.expandingItem}>
            <CollapsingSegmentControlPicker
              options={options}
              value={selectedOption}
              maxWidth={1.0}
              onChangeValue={onChangeOption}
            />
          </ToolbarItem>
          {dropOptions.length > 0 && (
            <ToolbarItem>
              <DropdownButton
                options={dropOptions}
                menuPosition={DropdownButtonMenuPosition.BOTTOM_RIGHT}
              >
                <Icons.Download /> {T.ectoplanner.download}
              </DropdownButton>
            </ToolbarItem>
          )}
        </Toolbar>
      )}
      {isLoading && !infoViewDisplaysLoading && <LoadingContainer isLoading />}
      {!showingInfoView &&
        !isLoading &&
        !formIsUpToDate &&
        !isRunningCalculation && (
          <div className={styles.paddedArea}>
            <ErrorNotice>
              {T.ectoplanner.calculations.newchangesinfo}
            </ErrorNotice>
          </div>
        )}
      <div
        className={classNames(
          styles.resultViewContainer,
          printMode && styles.printViewContainer
        )}
      >
        {content}
      </div>
    </div>
  );

  if (printMode) {
    return containerContent;
  }

  return (
    <PlainBox isPageBox onScroll={onScroll} className={styles.resultPageBox}>
      {containerContent}
    </PlainBox>
  );
};

interface EctoplannerResultViewProps {
  projectId?: string;
  printMode?: boolean;
  build?: BuildResponse;
  buildId: string;
  curSection?: string;
  isRunningCalculation?: boolean;
  formIsUpToDate?: boolean;
}

const EctoplannerResultView = ({
  projectId,
  build,
  buildId,
  printMode,
  curSection,
  isRunningCalculation = false,
  formIsUpToDate = true
}: EctoplannerResultViewProps) => {
  const shouldDownloadBuild = areEctoplannerResultsAvailable(
    build?.status,
    buildId,
    printMode
  );

  const buildResultQuery =
    EctoplannerAPIGen.EctoGridBuilds.resultsDetail.useQuery(
      {
        buildId: buildId
      },
      {
        enabled: shouldDownloadBuild,
        refetchOnWindowFocus: false
      }
    );

  const loadProjectForPrintModeQuery =
    EctoplannerAPIGen.EctoGridProjects.detail.useQuery(
      {
        projectId: projectId
      },
      {
        enabled: projectId != null && printMode,
        refetchOnWindowFocus: false
      }
    );

  const results = buildResultQuery.data as EctoplannerResultAndFlagsType;
  const noSolutionFound = results?.flags?.optimal_solution_found === false;
  const [isLoadingCalculation, calculationError, calculatedForm] =
    useCompiledBuildForm(buildId, build?.checksum);

  return (
    <EctoplannerResultContentView
      projectId={projectId}
      project={loadProjectForPrintModeQuery.data}
      buildId={buildId}
      results={results?.results}
      printMode={printMode}
      build={build}
      noSolutionFound={noSolutionFound}
      formIsUpToDate={formIsUpToDate}
      curSection={curSection}
      isLoading={
        (shouldDownloadBuild && buildResultQuery.isLoading) ||
        loadProjectForPrintModeQuery.isLoading ||
        (isLoadingCalculation && calculationError == null)
      }
      calculationError={calculationError}
      calculatedForm={calculatedForm}
      isRunningCalculation={isRunningCalculation}
    />
  );
};

export function EctoplannerProgressBar({
  progress,
  isLoading
}: {
  progress: number;
  isLoading: boolean;
}) {
  if (isLoading) {
    return (
      <div className={styles.buildStatus}>
        <ProgressBar percentage={progress} size={ProgressBarSizes.LARGE} />
        <Spinner size={SpinnerSize.SMALL} />
      </div>
    );
  }

  return null;
}

export default React.memo(EctoplannerResultView);
