import { ROOT_NODE_ID } from '../constants';
import _ from 'lodash';
import T, { UnlocalizedString } from '../lang/Language';
import {
  EquipmentResponseModel,
  GridType,
  NodeV2ResponseModel,
  NodeParentInformationResponseModel,
  NodeType
} from 'ecto-common/lib/API/APIGen';

/**
 * A key representation of node and key in nodeMap or equipmentMap
 * @see createNodeMap
 * @see createEquipmentMap
 * @param nodeId
 * @param grid
 * @returns {*}
 */
const nodeKey = (nodeId: string) => (nodeId ? nodeId : null);
export function getNodeFromMap<NodeType>(
  nodeMap: Record<string, NodeType>,
  nodeId: string
): NodeType {
  return nodeMap?.[nodeKey(nodeId)];
}

export function getFullPathNameV2(
  node: NodeV2ResponseModel,
  parents: NodeParentInformationResponseModel[]
) {
  if (node == null) {
    return [];
  }

  let currentParent = parents.find((parent) => parent.nodeId === node.parentId);

  const ret = [node.name];
  while (currentParent != null) {
    ret.unshift(currentParent.name);
    currentParent = parents.find((p) => p.nodeId === currentParent.parentId);
  }

  return ret;
}

/**
 *
 * @param dataTree
 * @param predicateFn
 */
export function findInTree(
  dataTree: NodeV2ResponseModel[],
  predicateFn: (node: NodeV2ResponseModel | EquipmentResponseModel) => boolean
): NodeV2ResponseModel | EquipmentResponseModel {
  if (predicateFn == null) {
    throw new Error('No predicate function supplied');
  }

  return findLocation(dataTree);

  function findLocation(
    tree: NodeV2ResponseModel[] = []
  ): NodeV2ResponseModel | EquipmentResponseModel {
    for (let i = 0; i < tree.length; i++) {
      const item = tree[i];

      if (predicateFn(item)) {
        return item;
      }

      for (const property in item) {
        if (
          Object.prototype.hasOwnProperty.call(item, property) &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Array.isArray((item as any)[property])
        ) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const child = findLocation((item as any)[property]);

          if (child) {
            return child;
          }
        }
      }
    }

    return null;
  }
}

export function localizeLocationType(type: NodeType) {
  const property = type.toLowerCase() as keyof typeof T.nodetypes;
  return T.nodetypes[property] === UnlocalizedString
    ? type
    : T.nodetypes[property];
}

export function localizeGridName(grid: GridType): string {
  const property = grid.toLowerCase() as keyof typeof T.grids;
  return T.grids[property] === UnlocalizedString ? grid : T.grids[property];
}

export const isRootNodeId = (nodeId: string) =>
  _.startsWith(nodeId, ROOT_NODE_ID);

export const isRootNode = (node: NodeV2ResponseModel) =>
  isRootNodeId(node.nodeId);
