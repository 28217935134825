import {
  BrowserCacheLocation,
  Configuration,
  ILoggerCallback,
  LogLevel
} from '@azure/msal-browser';

import isIE from 'ecto-common/lib/utils/isIE';

const loggerCallback: ILoggerCallback = (level, message) => {
  if (level <= LogLevel.Warning) {
    // eslint-disable-next-line no-console
    console.log(message);
  }
};

/**
 * @param clientId - Client ID of your app registered with our Application registration portal : https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/RegisteredAppsPreview in Microsoft Identity Platform
 * @param authority - You can configure a specific authority, defaults to " " or “https://login.microsoftonline.com/common”
 * @param knownAuthorities - An array of URIs that are known to be valid. Used in B2C scenarios.
 * postLogoutRedirectUri - The redirect URI where the window navigates after a successful logout.
 * redirectUri - The redirect URI where authentication responses can be received by your application. It must exactly match one of the redirect URIs registered in the Azure portal.
 * cacheLocation - Used to specify the cacheLocation user wants to set. Valid values are “localStorage” and “sessionStorage”
 * storeAuthStateInCookie - If set, MSAL stores the auth request state required for validation of the auth flows in the browser cookies. By default this flag is set to false.
 */
const createAuthenticationConfiguration = (
  authority: string,
  clientId: string,
  knownAuthorities: string[]
): Configuration => {
  const uri = window.location.origin;

  return {
    auth: {
      clientId,
      authority,
      knownAuthorities,
      postLogoutRedirectUri: uri,
      redirectUri: uri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: false,
        loggerCallback
      }
    }
  };
};

export default createAuthenticationConfiguration;
