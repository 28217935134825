// Auto generated file! Do not edit!
export type ApiConfigType = {  ectotableClientUrl: string;
  ectotableUrl: string;
  url: string;
  integrationUrl: string;
  integrationAdminUrl: string;
  identityServiceUrl: string;
  identityServiceV2Url: string;
  netmoreUrl: string;
  ectoplannerUrl: string;
  ectoplannerHubsUrl: string;
  jobsV2Url: string;
  deviceUrl: string;
  alarmsSignalRUrl: string;
  alarmsUrl: string;
  presentationUrl: string;
  signalRUrl: string;
  smartComfortUrl: string;
  manualAlarmsUrl: string;
  communicationUrl: string;
 }; export type AppScopesType = {  ectotable: string;
  gateway: string;
  integration: string;
  identity: string;
  smartcomfort: string;
  ectoplanner: string;
  jobs: string;
  device: string;
  alarms: string;
  presentation: string;
  manualalarms: string;
  communication: string;
};
export type ApiEnvironmentConfig = {
  name: string;
  authority: '';
  clientId: string;
  scopes: AppScopesType;
  knownAuthorities: string[];
  urls: ApiConfigType;
};
export const emptyEnvironment: ApiEnvironmentConfig = Object.freeze({
  name: '',
  authority: '',
  clientId: '',
  scopes: {
      ectotable: '',
  gateway: '',
  integration: '',
  identity: '',
  smartcomfort: '',
  ectoplanner: '',
  jobs: '',
  device: '',
  alarms: '',
  presentation: '',
  manualalarms: '',
  communication: '',

  },
  knownAuthorities: [],
  urls: {
      ectotableClientUrl: '',
  ectotableUrl: '',
  url: '',
  integrationUrl: '',
  integrationAdminUrl: '',
  identityServiceUrl: '',
  identityServiceV2Url: '',
  netmoreUrl: '',
  ectoplannerUrl: '',
  ectoplannerHubsUrl: '',
  jobsV2Url: '',
  deviceUrl: '',
  alarmsSignalRUrl: '',
  alarmsUrl: '',
  presentationUrl: '',
  signalRUrl: '',
  smartComfortUrl: '',
  manualAlarmsUrl: '',
  communicationUrl: '',

  }
});
