import React, { useMemo } from 'react';
import ErrorBoundary from 'ecto-common/lib/utils/ErrorBoundary';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import styles from 'js/components/Ectoplanner/EctoplannerResultGraphs.module.css';
import T from 'ecto-common/lib/lang/Language';

import _ from 'lodash';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import EctoplannerBox from 'js/components/Ectoplanner/EctoplannerBox';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { EctoplannerResultType } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

const gasColor = '#dea900';
const electricityColor = '#168213';
const heatingColor = '#c00000';
const coolingColor = '#4473c4';
const operationColor = '#666666';
const spaceColor = '#eb8c81';

type EctoplannerGraphSeriesPoint = {
  name: string;
  y: number;
  color: string;
};

type EctoplannerGraphSeries = {
  name?: string;
  data: EctoplannerGraphSeriesPoint[];
};

interface EctoplannerGraphProps {
  series?: EctoplannerGraphSeries[];
  title?: React.ReactNode;
  unit?: string;
  noDataText?: string;
  style?: object;
}

const EctoplannerGraph = ({
  series,
  title,
  unit,
  noDataText = T.ectoplanner.form.shared.nodatafound,
  style
}: EctoplannerGraphProps) => {
  const seriesHasName = _.some(series, (seriesObj) => seriesObj.name != null);

  const formattedSeries = useMemo(() => {
    const ret = _.cloneDeep(series);

    for (const seriesObject of ret) {
      seriesObject.data = _.map(seriesObject.data, (dataEntry) => {
        return {
          ...dataEntry,
          formattedValue: formatNumberUnit(dataEntry.y, unit, 0)
        };
      });
    }

    return ret;
  }, [series, unit]);

  const config: Highcharts.Options = useMemo(
    () => ({
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        liveRedraw: false,
        enabled: false
      },
      chart: {
        type: 'column',
        zoomType: null,
        backgroundColor: null
      },
      boost: {
        allowForce: false,
        turbo: false,
        enabled: false
      },
      xAxis: {
        type: 'category'
      },
      yAxis: {
        labels: {
          formatter: yAxisFormatter
        },
        crosshair: false,
        opposite: false
      },
      tooltip: {
        pointFormat: seriesHasName
          ? '{series.name}: {point.formattedValue}'
          : '{point.formattedValue}'
      },
      legend: {
        enabled: false
      },
      series: formattedSeries.map((_series) => ({
        ..._series,
        type: null
      }))
    }),
    [formattedSeries, seriesHasName]
  );

  return (
    <ErrorBoundary>
      <EctoplannerBox title={title} style={style}>
        <div className={styles.innerGraphBox}>
          <StockChart config={config} noSeriesText={noDataText} />
        </div>
      </EctoplannerBox>
    </ErrorBoundary>
  );
};

const point = (name: string, y: number, color: string) => ({
  name,
  y,
  color
});

interface EctoplannerEndEnergyGraphProps {
  results: EctoplannerResultType;
}

export const EctoplannerEndEnergyGraph = ({
  results
}: EctoplannerEndEnergyGraphProps) => {
  const {
    total_gas,
    total_el_from_grid,
    total_el_to_grid,
    total_PV_power,
    total_from_DH,
    total_from_DC,
    total_from_waste_heat,
    total_from_waste_cold
  } = results;

  const series = [
    {
      data: [
        point(T.ectoplanner.form.shared.naturalgas, total_gas, gasColor),
        point(
          T.ectoplanner.form.shared.electricityfromgrid,
          total_el_from_grid,
          electricityColor
        ),
        point(
          T.ectoplanner.form.shared.feedintogrid,
          total_el_to_grid,
          electricityColor
        ),
        point(
          T.ectoplanner.resultgraphs.pvpower,
          total_PV_power,
          electricityColor
        ),
        point(
          T.ectoplanner.form.shared.districtheating,
          total_from_DH,
          heatingColor
        ),
        point(
          T.ectoplanner.form.shared.districtcooling,
          total_from_DC,
          coolingColor
        ),
        point(
          T.ectoplanner.form.shared.ambientheatsource,
          total_from_waste_heat,
          heatingColor
        ),
        point(
          T.ectoplanner.form.shared.ambientcoldsource,
          total_from_waste_cold,
          coolingColor
        )
      ]
    }
  ];

  return (
    <EctoplannerGraph
      title={T.ectoplanner.resultgraphs.energydemandmwh}
      series={series}
      unit={T.ectoplanner.units.mwh}
    />
  );
};

// Remove empty series from list
const filterSeries = (series: EctoplannerGraphSeries[]) => {
  return _.filter(series, (seriesObject) =>
    _.some(seriesObject.data, (pointVal) => pointVal.y !== 0.0)
  );
};

interface EctoplannerElectricityImportMonthlyGraphProps {
  results: EctoplannerResultType;
}

export const EctoplannerElectricityImportMonthlyGraph = ({
  results
}: EctoplannerElectricityImportMonthlyGraphProps) => {
  const series = filterSeries([
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.el_to_grid, electricityColor)
      ),
      name: T.ectoplanner.form.shared.feedin
    },
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.el_from_grid, electricityColor)
      ),
      name: T.ectoplanner.form.shared.electricityfromgrid
    }
  ]);

  return (
    <EctoplannerGraph
      title={T.ectoplanner.resultgraphs.electricityimportmwh}
      series={series}
      unit={T.ectoplanner.units.mwh}
    />
  );
};

interface EctoplannerOtherImportMonthlyGraphProps {
  results: EctoplannerResultType;
}

export const EctoplannerOtherImportMonthlyGraph = ({
  results
}: EctoplannerOtherImportMonthlyGraphProps) => {
  const series = filterSeries([
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.gas_bldgs, gasColor)
      ),
      name: T.ectoplanner.form.shared.naturalgasbuildings
    },
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.gas_BU, gasColor)
      ),
      name: T.ectoplanner.form.shared.naturalgasbalancingunit
    },
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.distr_heat, heatingColor)
      ),
      name: T.ectoplanner.form.shared.districtheating
    },
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.distr_cool, coolingColor)
      ),
      name: T.ectoplanner.form.shared.districtcooling
    },
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.waste_heat, heatingColor)
      ),
      name: T.ectoplanner.form.shared.ambientheatsource
    },
    {
      data: _.map(results.energy_dem_monthly, (value, month) =>
        point(month, value.waste_cool, coolingColor)
      ),
      name: T.ectoplanner.form.shared.ambientcoldsource
    }
  ]);

  return (
    <EctoplannerGraph
      title={T.ectoplanner.resultgraphs.energyimportmwh}
      series={series}
      unit={T.ectoplanner.units.mwh}
    />
  );
};

interface EctoplannerOperationsCostsGraphProps {
  results: EctoplannerResultType;
  style?: object;
}

export const EctoplannerOperationsCostsGraph = ({
  results,
  style
}: EctoplannerOperationsCostsGraphProps) => {
  const {
    total_gas_costs,
    total_el_from_grid_costs,
    total_el_to_grid_revenue,
    total_from_DH_costs,
    total_from_DC_costs,
    total_waste_heat_costs,
    total_waste_cold_costs,
    ann_total_bu_equip_cost,
    total_bu_om_cost,
    ann_piping_cost,
    enable_netw_costs
  } = results;

  const { ann_total_bldg_equip_cost } = results.all_bldgs;

  const series = [
    {
      data: _.compact([
        point(T.ectoplanner.form.shared.naturalgas, total_gas_costs, gasColor),
        point(
          T.ectoplanner.form.shared.electricity,
          total_el_from_grid_costs,
          electricityColor
        ),
        point(
          T.ectoplanner.form.shared.feedin,
          -total_el_to_grid_revenue,
          gasColor
        ),
        point(
          T.ectoplanner.form.shared.districtheating,
          total_from_DH_costs,
          heatingColor
        ),
        point(
          T.ectoplanner.form.shared.districtcooling,
          total_from_DC_costs,
          coolingColor
        ),
        point(
          T.ectoplanner.form.shared.ambientheatsource,
          total_waste_heat_costs,
          heatingColor
        ),
        point(
          T.ectoplanner.form.shared.ambientcoldsource,
          total_waste_cold_costs,
          coolingColor
        ),
        point(
          T.ectoplanner.resultgraphs.equipmentinbuildings,
          ann_total_bldg_equip_cost,
          operationColor
        ),
        point(
          T.ectoplanner.resultgraphs.equipmentinbalancingunit,
          ann_total_bu_equip_cost,
          operationColor
        ),
        point(
          T.ectoplanner.resultgraphs.operationmaintenancecosts,
          total_bu_om_cost,
          operationColor
        ),
        enable_netw_costs &&
          point(
            T.ectoplanner.form.shared.pipeinstallation,
            ann_piping_cost,
            operationColor
          )
      ])
    }
  ];

  return (
    <EctoplannerGraph
      title={T.ectoplanner.resultgraphs.operationcostseura}
      series={series}
      unit={T.ectoplanner.units.eura}
      style={style}
    />
  );
};

interface EctoplannerHeatingDemandsGraphProps {
  results: EctoplannerResultType;
}

export const EctoplannerHeatingDemandsGraph = ({
  results
}: EctoplannerHeatingDemandsGraphProps) => {
  const series = [
    {
      data: _.map(results.bldg_dem_monthly, (value, month) =>
        point(month, value.tap_dem, heatingColor)
      ),
      name: T.ectoplanner.resultgraphs.tapwaterdemand
    },
    {
      data: _.map(results.bldg_dem_monthly, (value, month) =>
        point(month, value.sh_dem, spaceColor)
      ),
      name: T.ectoplanner.form.shared.spaceheating
    }
  ];

  return (
    <EctoplannerGraph
      title={T.ectoplanner.resultgraphs.heatingdemandmwh}
      series={series}
      unit={T.ectoplanner.units.mwh}
    />
  );
};

interface EctoplannerCoolingDemandsGraphProps {
  results: EctoplannerResultType;
}

export const EctoplannerCoolingDemandsGraph = ({
  results
}: EctoplannerCoolingDemandsGraphProps) => {
  const series = [
    {
      data: _.map(results.bldg_dem_monthly, (value, month) =>
        point(month, value.cool_dem, coolingColor)
      ),
      name: T.ectoplanner.resultgraphs.coolingdemand
    }
  ];

  return (
    <EctoplannerGraph
      title={T.ectoplanner.resultgraphs.coolingdemandmwh}
      series={series}
      unit={T.ectoplanner.units.mwh}
    />
  );
};
