import React from 'react';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import Page from 'ecto-common/lib/Page/Page';
import _ from 'lodash';

export const NoValidResourcesPage = () => {
  const content = (
    <ToolbarContentPage title={T.common.error} showLocationPicker={false}>
      {T.tenants.noresources}
    </ToolbarContentPage>
  );

  return <Page content={content} />;
};
