import { useContext, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import { createSignalNamesModel } from 'ecto-common/lib/Dashboard/modelforms/SignalNamesModelEditor';
import { nodeIdsWithEquipmentIds } from 'ecto-common/lib/hooks/useAvailableSignals';
import NodesDataSource, {
  nodesDataSourceSections
} from 'ecto-common/lib/Dashboard/datasources/NodesDataSource';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import useTimeRange from 'ecto-common/lib/Dashboard/context/useTimeRange';
import {
  getSortedValues,
  getUniqueTimeRanges,
  MatchedSignal,
  MatchedSignalInput,
  MatchSignalsForNodesResult,
  matchSignalsFromResult,
  MatchSignalsResult
} from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import useLatestSignalValues, {
  LastSignalValuesDataSet,
  LastSignalValuesResult,
  SignalValueType
} from 'ecto-common/lib/hooks/useLatestSignalValues';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import moment, { Moment } from 'moment';
import {
  dateRangeFromTimeRange,
  TimeRangeOptions
} from 'ecto-common/lib/types/TimeRangeOptions';
import {
  batchedGetLastValuePromise,
  batchedGetSignalsForNodesPromise,
  PromiseCacheContext
} from 'ecto-common/lib/Dashboard/datasources/signalUtils';

import {
  AggregationType,
  NodeV2ResponseModel,
  SamplingInterval,
  SignalProviderByNodeResponseModel,
  SignalProviderTelemetryResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import { useQuery } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useNodeEquipmentChildren } from 'ecto-common/lib/hooks/useCurrentNode';

/**
 * @typedef {Object} Signal
 * @property {string} name
 * @property {string} signalId
 * @property {string} signalTypeId
 * @property {string} color
 */
/**
 * @typedef {Object} Match
 * @property signalInfo
 * @property {Signal} signal
 */

/**
 * @typedef {Object} SignalProvider
 * @property {string} signalProviderId
 */

/**
 * @typedef {Object} SignalInfo
 * @property {Match[]} matchingSignals
 * @property {Object.<string, string>} signalIdToProviderId
 * @property {Object.<string, SignalProvider>} signalProviders
 */

/**
 * @typedef {Object} Node
 * @property {string} nodeId
 * @property {string} equipmentId
 */

type SignalProviderTelemetryResponseModelWithSignalInput =
  SignalProviderTelemetryResponseModel & {
    signalInput: MatchedSignalInput;
  };

/**
 * Fetch signals from server that matches the filter in 'signals'
 * @param {Node[]} nodes Search these nodes for signals
 * @param {Signal[]} signals Match signal on the nodes to this
 * @param timeRange Query only signal definitions that matches this time range
 * @param {Date} referenceDate Load data from this date
 * @param {bool} useLastValueBeforeRange If set to true, also load values at the end. Socket is not compatible with this.
 * @param {boolean} isAdmin Whether or not we're inside the admin app
 * @param {Object.<string, Object>} equipmentMap Key mapped list over given equipments
 * @param {string} grid specifies which grid that is currently active
 * @param {Object} cacheContext
 * @returns {*|Promise<{signalsForNodes: {signals: {}, signalProviders: {}, signalIdToProviderId: {}, signalInputs: *[]}, matchingSignals: []}>}
 */
type GetSignalInfoResult = MatchSignalsResult & {
  latestSignalValues?: SignalProviderTelemetryResponseModelWithSignalInput[];
};

export const EmptyMatchSignalsForNodesResult: MatchSignalsForNodesResult = {
  signalProviders: {},
  signalIdToProviderId: {},
  nodeIdToSignal: {},
  signals: {},
  signalInputs: [],
  matchingSignals: [],
  timeRange: null
};

const getSignalInfoForNodes = (
  contextSettings: ApiContextSettings,
  nodes: NodeV2ResponseModel[],
  equipmentNodeChildren: NodeV2ResponseModel[],
  signalInputs: SignalInputType[],
  timeRange: TimeRangeOptions,
  referenceDate: Moment,
  useLastValueBeforeRange: boolean,
  cacheContext: PromiseCacheContext,
  abortSignal: AbortSignal
): Promise<GetSignalInfoResult> => {
  const nodeIds = nodeIdsWithEquipmentIds(nodes, equipmentNodeChildren);

  if (_.isEmpty(nodeIds)) {
    return Promise.resolve({
      signalsForNodes: EmptyMatchSignalsForNodesResult,
      filteredSignalInfos: [],
      matchingSignals: [],
      latestSignalValues: []
    });
  }

  const dates = dateRangeFromTimeRange(
    timeRange,
    referenceDate,
    useLastValueBeforeRange
  );

  const nodesPromise = batchedGetSignalsForNodesPromise(
    contextSettings,
    cacheContext
  );

  const valuesPromise = batchedGetLastValuePromise(
    contextSettings,
    cacheContext
  );

  return nodesPromise(nodeIds)
    .then((signalProvidersWithSignals: SignalProviderByNodeResponseModel[]) => {
      const signalData = matchSignalsFromResult(
        signalProvidersWithSignals,
        signalInputs,
        timeRange,
        nodes
      );

      if (abortSignal.aborted) {
        return Promise.reject('Aborted');
      }

      if (referenceDate != null || useLastValueBeforeRange) {
        const signalIds = _.map(signalData.matchingSignals, 'signal.signalId');

        if (signalIds.length > 0) {
          return Promise.all([
            valuesPromise(signalIds, {
              endDate: dates.dateTo && dates.dateTo.toISOString()
            }),
            Promise.resolve(signalData)
          ]);
        }
      }

      return Promise.all([Promise.resolve(null), Promise.resolve(signalData)]);
    })
    .then(
      ([latestSignalValues, signalData]: [
        SignalProviderTelemetryResponseModel[],
        MatchSignalsResult
      ]) => {
        if (abortSignal.aborted) {
          return Promise.reject('Aborted');
        }

        // Remove values that are earlier than dateFrom. Can occur since we fetch the latest value before dateTo, but we have no
        // lower bound in the API call.
        let latestSignalValuesWithInputs: SignalProviderTelemetryResponseModelWithSignalInput[] =
          _.map(latestSignalValues, (signalValue) => {
            return {
              ...signalValue,
              // Order of matchingSignals might not match the order of latestSignalValues, so we need to find the correct signalInput
              // by searching for it. Since last data value does not use aggregation/sampling, there should only be one matching signalInput.
              signalInput: signalData.matchingSignals.find(
                (x) => x.signal.signalId === signalValue.signalId
              )?.signalInfo
            };
          });

        if (referenceDate != null && latestSignalValues != null) {
          latestSignalValuesWithInputs = latestSignalValuesWithInputs.map(
            (signal) => {
              return {
                ...signal,
                signals: _.filter(signal.signals, (value) => {
                  return moment.utc(value.time).isSameOrAfter(dates.dateFrom);
                })
              };
            }
          );
        }

        return {
          latestSignalValues: latestSignalValuesWithInputs,
          ...signalData
        };
      }
    );
};

export type SignalInputType = {
  id: string;
  signalTypeId: string;
  signalProviderType?: string;
  equipmentTypeId?: string;
  color?: string;
  timeRange?: TimeRangeOptions;
  samplingInterval?: SamplingInterval;
  aggregation?: AggregationType;
  category?: string;
  displayName?: string;
  value?: number;
};

type SignalValueTypeWithSignalInput = SignalValueType & {
  signalInput: MatchedSignalInput;
};

export type LastSignalValuesDataSourceResult = {
  signalValues: SignalValueTypeWithSignalInput[];
  signalInfo: MatchSignalsForNodesResult;
  isLoading: boolean;
  hasError: boolean;
  nodes: NodeV2ResponseModel[];
};

type LastSignalValuesLastData = {
  liveSignalData: LastSignalValuesResult;
  values: SignalValueTypeWithSignalInput[];
  signalInfo: MatchSignalsForNodesResult;
};

const EmptySignalIds: string[] = [];

export type LastSignalValuesDataSourceProps = {
  nodeId?: string;
  useSiblings?: boolean;
  nodeIds?: string[];
  signals: SignalInputType[];
  reloadTrigger?: number;
  useLastValueBeforeRange?: boolean;
  cacheContext: PromiseCacheContext;
};

const emptyFilteredSignalInfos: SignalInputType[] = [];
const emptyMatchingSignals: MatchedSignal[] = [];

/**
 * Fetches signal values
 * @param {string=} nodeId - Deprecated
 * @param {boolean=} useSiblings
 * @param {Signal[]} signals
 * @param {string[]=} nodeIds
 * @param {number=} reloadTrigger
 * @param {boolean=} useLastValueBeforeRange
 * @param {Object} cacheContext
 * @returns {{isLoading: boolean, nodes: T[]|unknown[], signalValues: [], signalInfo: SignalInfo, hasError: boolean | undefined}}
 */
const LastSignalValuesDataSource = ({
  nodeId = undefined, // Keep for backward compatibility (old node source)
  useSiblings = false,
  nodeIds = undefined, // New nodes source uses multi nodes
  signals,
  reloadTrigger = 0,
  useLastValueBeforeRange = false,
  cacheContext
}: LastSignalValuesDataSourceProps): LastSignalValuesDataSourceResult => {
  const specifiedTimeRanges = useMemo(
    () => getUniqueTimeRanges(signals),
    [signals]
  );
  const _nodeIdsProps = useMemo(
    () => ({ nodeIds: nodeIds ?? _.compact([nodeId]) }),
    [nodeId, nodeIds]
  );

  const nodes = NodesDataSource({
    nodeIds: _nodeIdsProps?.nodeIds,
    useSiblings
  });

  const { timeRangeOption, referenceDate } = useTimeRange(
    null,
    null,
    signals,
    null,
    null,
    specifiedTimeRanges,
    reloadTrigger
  );

  const { contextSettings } = useContext(TenantContext);

  const allNodeIds = useMemo(() => {
    return _.compact([...nodes.map((node) => node.nodeId)]);
  }, [nodes]);

  // Only load equipment nodes for nodes directly referenced, not from sibling nodes
  // possibly coming in from NodesDataSource
  const allEquipmentNodeIds = useMemo(() => {
    return allNodeIds.filter((otherNodeId) =>
      _nodeIdsProps.nodeIds.includes(otherNodeId)
    );
  }, [allNodeIds, _nodeIdsProps.nodeIds]);

  const { equipmentChildren, isLoading: isLoadingEquipmentNodeChildren } =
    useNodeEquipmentChildren(allEquipmentNodeIds);

  const getSignalInfoQuery = useQuery({
    queryKey: [
      'getSignalInfo',
      allNodeIds,
      signals,
      timeRangeOption,
      referenceDate,
      useLastValueBeforeRange
    ],

    queryFn: ({ signal }) => {
      return getSignalInfoForNodes(
        contextSettings,
        nodes,
        equipmentChildren,
        signals,
        timeRangeOption,
        referenceDate,
        useLastValueBeforeRange,
        cacheContext,
        signal
      );
    },

    refetchOnWindowFocus: false,
    enabled:
      !_.isEmpty(nodes) &&
      !_.isEmpty(signals) &&
      !isLoadingEquipmentNodeChildren
  });

  // TODO: waitingForInitialData

  const filteredSignalInfos =
    getSignalInfoQuery.data?.filteredSignalInfos ?? emptyFilteredSignalInfos;
  const matchingSignals =
    getSignalInfoQuery.data?.matchingSignals ?? emptyMatchingSignals;

  const signalInfo = useMemo(() => {
    if (getSignalInfoQuery.data != null) {
      return getSignalInfoQuery.data.signalsForNodes;
    }

    return {
      ...EmptyMatchSignalsForNodesResult,
      signalInputs: signals
    };
  }, [getSignalInfoQuery.data, signals]);

  const staticSignalData = useMemo(() => {
    if (getSignalInfoQuery.data != null) {
      return {
        values: _.flatMap(
          getSignalInfoQuery.data.latestSignalValues,
          (signal) =>
            signal.signals.map((signalValue) => ({
              ...signalValue,
              signalId: signal.signalId,
              signalInput: signal.signalInput
            }))
        ),
        signalInfo: getSignalInfoQuery.data.signalsForNodes
      };
    }
    return {
      values: [],
      signalInfo: signalInfo
    };
  }, [getSignalInfoQuery.data, signalInfo]);

  const signalIds = useMemo(
    () => _.map(matchingSignals, 'signal.signalId'),
    [matchingSignals]
  );

  // If useLastValueBeforeRange is true, we can't fetch values using the socket. We will have to use a REST
  // call since the socket cannot handle specifying last dates.
  const signalIdsArg =
    useLastValueBeforeRange || referenceDate != null
      ? EmptySignalIds
      : signalIds;
  const liveSignalData = useLatestSignalValues(null, signalIdsArg);
  const waitingForInitialData =
    getSignalInfoQuery.isLoading ||
    (getSignalInfoQuery?.data?.matchingSignals.length > 0 &&
      Object.keys(liveSignalData).length === 0);

  /**
   * Since we want the return values of this function to be in sync with each other, we unfortunately
   * need to use a reference to determine whenever the signal value data changes.
   *
   * The reason for this is that we use a two phase approach to loading the signal data. First, we load
   * all of the signal information that we need to fetch the live values. This is a standard API request
   * (getSignalInfo). We then subscribe to a SignalR endpoint and wait for the values to appear. Since we're
   * using different API mechanisms, we can't do this in one pass.
   * We have to store the result from the first phase in temporary state variables (signalsForNodes etc).
   *
   * What happens then is that those state variables gets updated first and returned from the data source.
   * Some time later, the new signal values are read from the socket. If we change the input parameters to the data source,
   * mismatching data can be returned from the data source (i.e. request state variables from call 2 but values from call 1).
   *
   * We use a reference here to make sure that the state variables and the socket values
   * are changed together, and only when the live signal data property changes (or is initialized).
   *
   * This way we can be certain that the signal info returned from the data source is the same info that
   * was used when the signal values were requested.
   */

  const lastSignalDataRef = useRef<LastSignalValuesLastData>({
    liveSignalData: null,
    values: null,
    signalInfo: { ...EmptyMatchSignalsForNodesResult }
  });

  // To avoid showing data from another node when changing nodes, clear
  // data explicitly.
  useEffect(() => {
    lastSignalDataRef.current = {
      liveSignalData: null,
      values: null,
      signalInfo: { ...EmptyMatchSignalsForNodesResult }
    };
  }, [nodes, signals]);

  const signalValues = useMemo(() => {
    if (lastSignalDataRef.current.liveSignalData !== liveSignalData) {
      // This effect is triggered every time a new value appears, OR if a future value (from forecasted
      // data) is now the latest value. Find the closest matching value in time.
      // TODO: Perhaps this logic should be handled by useLatestSignalValues?
      const currentTime = new Date().toISOString();
      const latestSignalData = _.mapValues(
        liveSignalData,
        (entry: LastSignalValuesDataSet) => {
          return (
            _.findLast(entry.values, (x) => currentTime >= x.time) ??
            _.head(entry.values)
          );
        }
      );

      // Since there is a render delay before signalData is cleared from old signalIds, we need to do
      // a manual cleanup here to ensure we don't return data for signals that are no longer active.
      const ret = _.filter(_.values(latestSignalData), (x) =>
        signalIds.includes(x.signalId)
      );

      // In the case of live signal data results, we can just use the first matching signal input.
      // We do not support aggregation/sampling for live data, so there should not be any duplicates.
      const inputsWithSignalInput: SignalValueTypeWithSignalInput[] = _.map(
        ret,
        (signalValue) => {
          const signalInfoIndex = _.findIndex(filteredSignalInfos, {
            id: signalValue.signalId
          });

          return {
            ...signalValue,
            signalInput: {
              ...filteredSignalInfos[signalInfoIndex],
              matchIndex: signalInfoIndex
            }
          };
        }
      );

      lastSignalDataRef.current = {
        values: getSortedValues(inputsWithSignalInput),
        signalInfo: signalInfo ?? {
          ...EmptyMatchSignalsForNodesResult,
          signalInputs: signals,
          timeRange: timeRangeOption
        },
        liveSignalData
      };
    }

    return lastSignalDataRef.current;
  }, [
    liveSignalData,
    signalIds,
    filteredSignalInfos,
    signalInfo,
    signals,
    timeRangeOption
  ]);

  return useMemo(() => {
    if (useLastValueBeforeRange || referenceDate != null) {
      return {
        signalValues: staticSignalData.values,
        signalInfo: staticSignalData.signalInfo,
        isLoading:
          getSignalInfoQuery.isLoading || isLoadingEquipmentNodeChildren,
        hasError: getSignalInfoQuery.isError,
        nodes
      };
    }

    return {
      signalValues: signalValues.values,
      signalInfo: signalValues.signalInfo,
      isLoading:
        getSignalInfoQuery.isLoading ||
        waitingForInitialData ||
        isLoadingEquipmentNodeChildren,
      hasError: getSignalInfoQuery.isError,
      nodes
    };
  }, [
    useLastValueBeforeRange,
    referenceDate,
    signalValues.values,
    signalValues.signalInfo,
    getSignalInfoQuery.isLoading,
    getSignalInfoQuery.isError,
    waitingForInitialData,
    isLoadingEquipmentNodeChildren,
    nodes,
    staticSignalData.values,
    staticSignalData.signalInfo
  ]);
};

type LastSignalValuesDataSourceArgs = {
  minItems: number;
  optionalSignalModels: ModelDefinition<SignalInputType>[];
};

export const lastSignalValuesDataSourceSections: (
  props: LastSignalValuesDataSourceArgs
) => ModelFormSectionType<LastSignalValuesDataSourceProps>[] = ({
  minItems,
  optionalSignalModels
}: LastSignalValuesDataSourceArgs) => [
  ...nodesDataSourceSections(),
  // No aggregation needed when fetching single value
  {
    label: T.admin.dashboards.datasources.lastvalue.settings,
    lines: [
      {
        models: [
          {
            key: (input) => input.useLastValueBeforeRange,
            label: T.admin.dashboards.datasources.lastvalue.uselastbeforerange,
            modelType: ModelType.BOOL
          }
        ]
      }
    ]
  },
  {
    label: T.admin.dashboards.sections.signals,
    lines: [
      {
        models: [createSignalNamesModel(minItems, optionalSignalModels)]
      }
    ],
    listPriority: SectionListPriority.Signals // Should always be last since it looks odd otherwise
  }
];

export const LastSignalValuesDataSourceTable = (
  props: LastSignalValuesDataSourceProps
) => {
  return LastSignalValuesDataSource(props);
};

export default LastSignalValuesDataSource;
