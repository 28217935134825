import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';
import { ScheduleActions } from 'js/modules/schedule/schedule';
import { convertToGraphValue } from 'js/components/EMPTools/PowerControl/Dispatch/utils/signals';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import {
  StockChartConstants,
  generateStockChartConfigForPlotLines
} from 'ecto-common/lib/SignalSelector/StockChart.config';
import {
  TelemetrySeries,
  createStockChartConfigFromSeries
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { ScheduleSignal } from 'js/modules/schedule/schedule';
import {
  SignalProviderTelemetryResponseModel,
  SignalResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import { UnitResponseModel } from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useResizeDetector } from 'react-resize-detector';

export type AnnotatedScheduleSignal = ScheduleSignal & {
  signal: SignalResponseModel & {
    signalType: SignalTypeResponseModel;
    unit: UnitResponseModel;
  };
};

type ScheduleTelemetrySeries = TelemetrySeries & {
  signalId: string;
  seriesInfo: AnnotatedScheduleSignal;
  zoneAxis?: string;
  zones?: Highcharts.SeriesZonesOptionsObject[];
};

const HOUR_IN_MILLIS = 60 * 60 * 1000;
const YEAR_IN_MILLIS = 365 * 24 * HOUR_IN_MILLIS;
const UPDATE_INTERVAL = 30;
const COLORS = [
  '#1E90FF',
  '#32CD32',
  '#FF9C0C',
  '#08A878',
  '#870F2D',
  '#D7004F'
];

// Helper method to darken color
// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
const _shadeColor2 = (color: string, percent: number) => {
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

const _convertTelemetryToSeries = (
  selectedSignals: AnnotatedScheduleSignal[],
  data: SignalProviderTelemetryResponseModel[]
): ScheduleTelemetrySeries[] => {
  if (!data) {
    return [];
  }

  return data.map(({ signals, signalId, ...other }) => {
    const seriesInfo = selectedSignals.find(
      (s) => s.signal.signalId === signalId
    );

    const series: ScheduleTelemetrySeries = {
      ...other,
      signalId,
      seriesInfo,
      signal: seriesInfo.signal,
      chartSignalId: seriesInfo.signal.signalId,
      groupName: null,
      color: null,
      dataFormat: seriesInfo.signal.dataFormat,
      unit: seriesInfo.signal.unit.unit || 'n/a',
      unitId: seriesInfo.signal.unit.id,
      name: seriesInfo.signal.name + '(' + seriesInfo.signal.unit.unit + ')',
      data: signals.map(convertToGraphValue)
    };

    return series;
  });
};

const createConfig = (
  selectedSignals: AnnotatedScheduleSignal[],
  telemetryData: SignalProviderTelemetryResponseModel[]
) => {
  if (_.isEmpty(selectedSignals)) {
    return {
      ...createStockChartConfigFromSeries([], null),
      ...generateStockChartConfigForPlotLines()
    };
  }

  const series = _convertTelemetryToSeries(
    selectedSignals,
    telemetryData || []
  ).sort((a, b) => {
    return a.seriesInfo.signal.signalType.name.localeCompare(
      b.seriesInfo.signal.signalType.name
    );
  });

  _.forEach(series, (seriesObject, idx: number) => {
    const curTime = new Date().getTime();
    seriesObject.color =
      idx < COLORS.length
        ? COLORS[idx]
        : StockChartConstants.colorTable[
            idx % StockChartConstants.colorTable.length
          ];
    seriesObject.zoneAxis = 'x';
    seriesObject.zones = [
      {
        value: curTime,
        color: seriesObject.color
      },
      {
        value: curTime + YEAR_IN_MILLIS,
        dashStyle: 'Dash',
        color: _shadeColor2(seriesObject.color, 0.5)
      }
    ];
  });

  return {
    ...createStockChartConfigFromSeries(series, null),
    ...generateStockChartConfigForPlotLines()
  };
};

const getZoomRange = (interval: number) => {
  const now = new Date().getTime();
  const diff = interval * (60 * 60 * 1000);
  return { dateFrom: now - diff, dateTo: now + diff };
};

interface PowerControlSignalChartProps {
  seriesInterval: number;
  onGraphZoomed(): void;
}

const PowerControlSignalChart = ({
  onGraphZoomed,
  seriesInterval
}: PowerControlSignalChartProps) => {
  const [currentCount, setCurrentCount] = useState(0);
  const signalValuesRequest = useOperatorSelector(
    (state) => state.schedule.signalValues
  );
  const telemetryData = useOperatorSelector(
    (state) => state.schedule.chartTelemetryData
  );
  const telemetryError = useOperatorSelector(
    (state) => state.schedule.chartTelemetryError
  );
  const basicSelectedSignals = useOperatorSelector(
    (state) => state.schedule.selectedSignals
  );
  const signalTypesMap = useOperatorSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useOperatorSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const selectedSignals: AnnotatedScheduleSignal[] = useMemo(() => {
    return _.map(basicSelectedSignals, (signalInfo) => {
      const signalType =
        signalTypesMap[signalInfo.signal.signalTypeId] ?? EmptySignalType;

      return {
        ...signalInfo,
        signal: {
          ...signalInfo.signal,
          signalType,
          unit: signalUnitTypesMap[signalType.unitId]
        }
      };
    });
  }, [basicSelectedSignals, signalTypesMap, signalUnitTypesMap]);

  const signalsRequest = useOperatorSelector((state) => state.schedule.signals);

  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useOperatorDispatch();
  const { nodeId } = useParams<NodeParams>();
  const [zoomRange, setZoomRange] = useState(getZoomRange(seriesInterval));
  const { contextSettings } = useContext(TenantContext);

  const timerCallback = useCallback(() => {
    if (currentCount >= UPDATE_INTERVAL) {
      setCurrentCount(0);
      if (seriesInterval !== -1) {
        setZoomRange(getZoomRange(seriesInterval));
      }

      dispatch(
        ScheduleActions.getSteerSignalValues(
          contextSettings,
          selectedSignals ?? []
        )
      );
    } else {
      setCurrentCount(currentCount + 1);
    }
  }, [
    currentCount,
    seriesInterval,
    dispatch,
    contextSettings,
    selectedSignals
  ]);

  useInterval(timerCallback, 1000);

  useEffect(() => {
    if (seriesInterval !== -1) {
      setZoomRange(getZoomRange(seriesInterval));
    }
  }, [seriesInterval]);

  useEffect(() => {
    if (
      signalsRequest.state === REQ_STATE_PENDING ||
      signalValuesRequest.state === REQ_STATE_PENDING
    ) {
      setIsLoading(true);
    } else if (
      signalsRequest.state === REQ_STATE_ERROR ||
      signalValuesRequest.state === REQ_STATE_ERROR
    ) {
      setIsLoading(false);
    } else if (signalValuesRequest.state === REQ_STATE_SUCCESS) {
      setIsLoading(false);
    } else if (
      signalsRequest.state === REQ_STATE_SUCCESS &&
      selectedSignals !== null &&
      selectedSignals.length === 0
    ) {
      // In this case we could not find any PC related signals for some reason. Cancel the loading chain.
      // selectedSignals === null means we haven't determined the signals yet, so only do the check
      // after it is set.
      setIsLoading(false);
    }
  }, [signalsRequest, signalValuesRequest, selectedSignals]);

  useEffect(() => {
    dispatch(ScheduleActions.getSignals(contextSettings, nodeId));
    return () => {
      dispatch(ScheduleActions.resetState());
    };
  }, [contextSettings, nodeId, dispatch]);

  useEffect(() => {
    dispatch(
      ScheduleActions.getSteerSignalValues(
        contextSettings,
        selectedSignals ?? []
      )
    );

    return () => {
      dispatch(ScheduleActions.clearSteerSignalValues());
    };
  }, [dispatch, selectedSignals, contextSettings]);
  const { width, ref } = useResizeDetector();

  useEffect(() => {
    dispatch(
      ScheduleActions.updateChartData(
        contextSettings,
        zoomRange.dateFrom,
        zoomRange.dateTo,
        width
      )
    );
  }, [contextSettings, selectedSignals, zoomRange, width, dispatch]);

  const onExtremesChange = useCallback(
    (dateFrom: number, dateTo: number) => {
      setZoomRange({ dateFrom, dateTo });
      onGraphZoomed();
    },
    [onGraphZoomed]
  );

  const config = useMemo(
    () => createConfig(selectedSignals ?? [], telemetryData),
    [selectedSignals, telemetryData]
  );
  const seriesIds = useMemo(
    () => _.map(telemetryData, 'signalId'),
    [telemetryData]
  );

  return (
    <div style={{ height: '100%', width: '100%' }} ref={ref}>
      <StockChart
        seriesIds={seriesIds}
        dateFrom={zoomRange.dateFrom}
        dateTo={zoomRange.dateTo}
        onExtremesChange={onExtremesChange}
        isLoading={isLoading}
        hasError={telemetryError}
        config={config}
      />
    </div>
  );
};

export default React.memo(PowerControlSignalChart);
