import React, { useCallback, useMemo, useState } from 'react';
import _ from 'lodash';

import TreeView, {
  isTreeNodeFolder,
  isTreeNodeRootLevel
} from 'ecto-common/lib/TreeView/TreeView';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';

import styles from './PageMenu.module.css';
import SearchInput from 'ecto-common/lib/SearchInput/SearchInput';
import {
  TreeViewColumnType,
  TreeViewNodeType
} from 'ecto-common/lib/TreeView/TreeViewNode';

type TOC = TreeViewNodeType & {
  title?: string;
  children: TOC[];
};

const getMenuItems = (node: TOC): TOC => {
  node.name = node.title;

  for (const child of node.children ?? []) {
    getMenuItems(child);
  }

  return node;
};

const treeViewIconFormatter =
  (icon: React.ReactNode) => (node: TreeViewNodeType) => {
    if (!isTreeNodeFolder(node)) {
      return icon;
    }

    return null;
  };

const columns: TreeViewColumnType<TreeViewNodeType>[] = [
  {
    dataFormatter: treeViewIconFormatter(
      <span className={styles.bullet}>&bull;</span>
    )
  },
  { dataFormatter: (node) => node.name }
];

interface PageMenuProps {
  toc: TOC[];
  onClick(docName: string): void;
  currentPageId?: string;
}

const PageMenu = ({ toc, onClick, currentPageId }: PageMenuProps) => {
  const selectedNodes = { [currentPageId]: true };
  const onPageMenuClick = useCallback(
    (page: TreeViewNodeType) => onClick(page.id),
    [onClick]
  );
  const [searchTerm, setSearchTerm] = useState<string>(null);

  const nodes = useMemo(() => {
    const foldersMap = _.keyBy(_.map(toc, getMenuItems), 'id');

    _.forEach(foldersMap, (folder) => {
      folder.children = _.concat(
        sortByLocaleCompare(
          _.filter(folder.children, isTreeNodeFolder),
          'title'
        ),
        sortByLocaleCompare(
          _.reject(folder.children, isTreeNodeFolder),
          'title'
        )
      );
    });

    return sortByLocaleCompare(
      _.filter(foldersMap, isTreeNodeRootLevel),
      'title'
    );
  }, [toc]);

  return (
    <div className={styles.container}>
      <SearchInput
        onChange={setSearchTerm}
        wrapperClassName={styles.searchField}
      />
      <TreeView
        nodes={nodes}
        onClickNode={onPageMenuClick}
        selectedNodes={selectedNodes}
        columns={columns}
        selectFolder
        searchFilter={searchTerm}
        searchFolders
      />
    </div>
  );
};

export default PageMenu;
