import React, { useCallback, useState } from 'react';
import TextInput, { TextInputProps } from 'ecto-common/lib/TextInput/TextInput';
import classNames from 'classnames';
import dropdownStyles from './TextInputWithMenu.module.css';
import { usePopper } from 'react-popper';
import dimensions from 'ecto-common/lib/styles/dimensions';
import _ from 'lodash';
import useOnclickOutside from 'react-cool-onclickoutside';

type TextInputMenuOption = {
  value: string;
  label: string;
};

interface TextInputWithMenuProps extends Omit<TextInputProps, 'ref'> {
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  selectedIndex: number;
  showMenu: boolean;
  onSelectMenuOption: (option: TextInputMenuOption) => void;
  menuOptions: TextInputMenuOption[];
  onFocusLost?(): void;
  highlightText?: string;
}

const TextInputWithMenu = ({
  value,
  showMenu,
  menuOptions,
  onSelectMenuOption,
  onEnterKeyPressed,
  onFocusLost,
  selectedIndex,
  highlightText = null,
  ...otherProps
}: TextInputWithMenuProps) => {
  const [referenceElement, setReferenceElement] =
    React.useState<HTMLElement>(null);
  const [popperElement, setPopperElement] = React.useState<HTMLElement>(null);
  useState<google.maps.places.AutocompleteService>(null);

  const _onFocusLost = useCallback(() => {
    onFocusLost();
  }, [onFocusLost]);

  const clickOutsideRef = useOnclickOutside(_onFocusLost);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, dimensions.smallMargin]
        }
      }
    ]
  });

  const _onEnterKeyPressed: TextInputProps['onEnterKeyPressed'] = useCallback(
    (event) => {
      (event.target as HTMLInputElement).blur();
      onEnterKeyPressed(event);
      onFocusLost();
    },
    [onEnterKeyPressed, onFocusLost]
  );
  const getHighlightedText = (text: string, highlight: string) => {
    if (highlight == null) {
      return text;
    }

    // Escape any special regex characters in the search term
    const regex = new RegExp(
      `(${highlight.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`,
      'gi'
    );

    const parts = text.split(regex);
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: 'yellow' }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  return (
    <>
      <TextInput
        ref={setReferenceElement}
        {...otherProps}
        value={value}
        onEnterKeyPressed={_onEnterKeyPressed}
        onTabKeyPressed={onFocusLost}
        className="ignore-onclickoutside"
      />
      <div
        ref={setPopperElement}
        className={classNames(
          'ignore-onclickoutside',
          dropdownStyles.dropdown,
          showMenu && dropdownStyles.dropdownVisible
        )}
        style={styles.popper}
        {...attributes.popper}
      >
        <div className={dropdownStyles.innerDropdown} ref={clickOutsideRef}>
          {menuOptions.map((option, idx) => (
            <div
              className={classNames(
                dropdownStyles.result,
                idx === selectedIndex && dropdownStyles.selected
              )}
              key={idx}
              onClick={() => {
                onSelectMenuOption(option);
                onFocusLost();
              }}
            >
              {getHighlightedText(option.label, highlightText)}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(TextInputWithMenu);
