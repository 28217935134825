import _ from 'lodash';
import React, { useMemo } from 'react';
import animations from 'ecto-common/lib/styles/variables/animations';
import colors from 'ecto-common/lib/styles/variables/colors';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import CommonGraphOptions from 'ecto-common/lib/Graph/CommonGraphOptions';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';

const gaugeOptions: Highcharts.Options = {
  ...CommonGraphOptions,

  chart: {
    type: 'solidgauge',
    backgroundColor: 'transparent'
  },
  pane: {
    center: ['50%', '50%'],
    size: '100%',
    startAngle: -90,
    endAngle: 90,
    background: [
      {
        backgroundColor: colors.surface4Color,
        innerRadius: '75%',
        outerRadius: '100%',
        shape: 'arc',
        borderWidth: 0
      }
    ]
  },
  xAxis: {
    crosshair: false
  },
  // the value axis
  yAxis: {
    crosshair: false,
    tickLength: 10000,
    tickWidth: 0,
    lineWidth: 0,
    minorTickInterval: null,
    labels: {
      y: 16,
      enabled: false
    }
  },
  plotOptions: {
    series: {
      animation: {
        duration: parseFloat(animations.defaultSpeed) * 1000
      }
    },
    solidgauge: {
      innerRadius: '75%',
      borderWidth: 0,
      dataLabels: {
        y: 0,
        borderWidth: 0,
        useHTML: true
      }
    }
  }
};

// Strategy is to allow the user to define the font size, but to use a semi-dynamic
// font size when the viewport gets too small.
const fontSizeFromWidth = (fontSize: number, width: number) => {
  if (width < 160) {
    return [Math.min(fontSize, 12), -25];
  } else if (width < 200) {
    return [Math.min(fontSize, 16), -30];
  } else if (width < 240) {
    return [Math.min(fontSize, 20), -40];
  }

  return [fontSize, -fontSize - 25];
};

export type GaugeGraphProps = {
  value: number;
  min: number;
  max: number;
  hideUnit: boolean;
  unit: string;
  color: string;
  isLoading: boolean;
  hasError: boolean;
  fontSize: number;
  parentSize: {
    width: number;
    height: number;
  };
};

const GaugeGraph = ({
  value,
  min,
  max,
  unit,
  fontSize,
  color,
  parentSize,
  isLoading = false,
  hideUnit,
  hasError = false
}: GaugeGraphProps) => {
  const loadingFontSize = fontSize * 0.65;
  const loadingCenterOffset = (fontSize - loadingFontSize) / 2.0;
  const [_fontSize, marginTop] = fontSizeFromWidth(fontSize, parentSize.width);

  const config: Highcharts.Options = useMemo(() => {
    return _.merge({}, gaugeOptions, {
      yAxis: {
        stops: [[0.1, color]],
        min,
        max,
        showLastLabel: true,
        labels: {
          formatter: function () {
            return formatNumberUnit(this.value, unit);
          }
        }
      },
      loading: {
        labelStyle: {
          // 45% is default option. Offset to center loading label within value text
          top: 'calc(45% - ' + (_fontSize - loadingCenterOffset) + 'px)',
          fontSize: loadingFontSize + 'px'
        }
      },
      series: [
        {
          name: unit,
          data: value == null ? [] : [value],
          dataLabels: {
            format:
              '<div style="text-align:center; margin-top: ' +
              marginTop +
              'px">' +
              '<span style="font-size:' +
              _fontSize +
              'px">' +
              (isLoading
                ? ''
                : formatNumberUnit(value, hideUnit ? null : unit)) +
              '</span><br/>' +
              '</div>'
          },
          tooltip: {
            valueSuffix: ' ' + unit
          }
        }
      ]
    });
  }, [
    value,
    min,
    max,
    color,
    unit,
    hideUnit,
    marginTop,
    isLoading,
    loadingCenterOffset,
    loadingFontSize,
    _fontSize
  ]);

  // The reason we do the width / height encapsulation is that the Highcharts Gauge wants to take up twice the amount
  // of needed vertical space to render the semicircle. Even though we specify that we only want to draw a semicircle
  // and not a full circle, Highcharts requires vertical space for the full circle. So we give it full vertical space
  // and then let the parent component clip the child graph component.
  return (
    <div
      style={{
        width: parentSize.width,
        height: parentSize.height,
        overflow: 'hidden'
      }}
    >
      <StockChart
        config={config}
        hasError={hasError}
        isLoading={isLoading}
        containerWidth={parentSize.width}
        containerProps={{
          style: {
            width: parentSize.width,
            // Try to make circle as wide as possible by giving Highcharts as much vertical space as there is horizontal.
            // However, if it would not fit, then revert to the actual height (times two since Highcharts needs double
            // vertical space for a semicircle). Offset slightly to fit information text.
            height: Math.min(parentSize.width, parentSize.height * 2 - 10),
            flexShrink: 0
          }
        }}
      />
    </div>
  );
};

export default React.memo(GaugeGraph);
