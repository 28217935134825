import React, { Dispatch, SetStateAction, useMemo } from 'react';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import styles from './EditEctoplannerProject.module.css';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import _ from 'lodash';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ectoplannerBusinessModelSections } from 'js/components/Ectoplanner/useEctoplannerFormOptions';
import T from 'ecto-common/lib/lang/Language';

interface EditEctoplannerBusinessModelProps {
  projectId?: string;
  form?: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
}

const EditEctoplannerBusinessModel = ({
  form,
  setForm,
  projectId
}: EditEctoplannerBusinessModelProps) => {
  const onUpdateInput = useOnUpdateFormInput(setForm);
  const environment = useMemo(() => ({ projectId }), [projectId]);
  const [selectedSection, setSelectedSection] =
    React.useState<ModelFormSectionType<EctoplannerForm>>(null);

  const columns: DataTableColumnProps<ModelFormSectionType<EctoplannerForm>>[] =
    useMemo(() => {
      return [
        {
          dataKey: 'label',
          linkColumn: true
        }
      ];
    }, []);

  const rows = useMemo(() => {
    return ectoplannerBusinessModelSections.filter((section) =>
      section.visible ? section.visible(form) : true
    );
  }, [form]);

  return (
    <>
      <ActionModal
        onModalClose={() => {
          setSelectedSection(null);
        }}
        headerIcon={Icons.Edit}
        title={selectedSection?.label}
        onConfirmClick={() => {
          setSelectedSection(null);
        }}
        disableCancel
        actionText={T.common.done}
        isOpen={selectedSection != null}
      >
        {selectedSection != null && (
          <ModelForm
            models={selectedSection.models}
            sections={selectedSection?.sections}
            onUpdateInput={onUpdateInput}
            input={form}
            environment={environment}
            useTooltipHelpTexts
          />
        )}
      </ActionModal>
      <DataTable
        columns={columns}
        data={rows}
        className={styles.techTable}
        onClickRow={(section, _rowIndex) => {
          setSelectedSection(section);
        }}
      />
    </>
  );
};

export default React.memo(EditEctoplannerBusinessModel);
