import classNames from 'classnames';
import DataTableCell from './DataTableCell';
import styles from './DataTable.module.css';
import _ from 'lodash';
import React, { MouseEventHandler, useCallback, useRef, useState } from 'react';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';

// Would have used _.identity, but it gives us warning for too many args
const EmptyDataFormatter = (value: unknown) => value;

export const calculateMinWidthForRow = (
  columns: DataTableColumnProps<unknown>[]
) =>
  _.sum(
    columns.map(({ minWidth = 0, flexShrink = 1, width = 0 }) => {
      if (
        _.isNumber(width) &&
        _.isNumber(minWidth) &&
        width > 0 &&
        flexShrink === 0
      ) {
        return Math.max(width, minWidth);
      }

      return minWidth;
    })
  ) +
  dimensions.smallMargin * 2;

/**
 * Because clicks sometimes ends up on the containing row and not the row cell (since it might not be as tall
 * as the row itself) we need this method to find out what the clicked column actually was. Use the page position
 * from the event to find the row element, then find the element which is located at the clicked X position and the
 * center of the row Y position.
 */
const findColumnForRowClickEvent = (
  event: React.MouseEvent<HTMLDivElement>
) => {
  let elem = event.target;

  // Find the data row that is the ancestor of the clicked node.
  while (
    elem != null &&
    (elem as HTMLElement).getAttribute?.('data-row') == null
  ) {
    elem = (elem as HTMLElement).parentElement;
  }

  if (elem != null) {
    // Go through all of the children of the data row and see which column the
    // click occurred in.
    const rects = _.compact(
      _.map((elem as HTMLElement).childNodes, (child) =>
        (child as HTMLElement).getBoundingClientRect?.()
      )
    );
    const x = event.pageX;

    const col = _.findLastIndex(rects, (rect) => rect.left <= x);

    if (col === -1) {
      // Click was in the left side margin outside the first column div which means
      // first rect.left is >= x.
      return 0;
    }

    return col;
  }

  console.error('DataTable failed to find column');
  return null;
};

interface DataTableRowProps<ObjectType> {
  rowData?: ObjectType;
  columns: DataTableColumnProps<ObjectType>[];
  rowIndex?: number;
  selectedIndex?: number;
  selectedIndexStyle?: string;
  onClickRow?(rowData: ObjectType, row: number, column: number): void;
  inline?: boolean;
  smaller?: boolean;
}

const DataTableRow = <ObjectType,>({
  rowData,
  columns,
  rowIndex,
  selectedIndex,
  selectedIndexStyle,
  onClickRow,
  inline,
  smaller
}: DataTableRowProps<ObjectType>) => {
  const isDataRow = rowIndex !== -1;
  const isSelectable = onClickRow && isDataRow; // Index is -1 for the header
  const isSelected = selectedIndex !== -1 && rowIndex === selectedIndex;

  const className = classNames(
    styles.row,
    isDataRow && styles.dataRow,
    isSelectable && styles.selectableRow,
    isSelected && [styles.selectedRow, selectedIndexStyle],
    inline && styles.inlineRow,
    smaller && styles.smallerRow
  );

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (onClickRow) {
        const column = findColumnForRowClickEvent(event);

        if (column != null) {
          onClickRow(rowData, rowIndex, column);
        }
      }
    },
    [onClickRow, rowData, rowIndex]
  );

  const [hoverColumn, setHoverColumn] = useState<number>(null);
  const rowRef = useRef(null);

  const onMouseOver: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (rowRef.current?.contains(event.target)) {
        const column = findColumnForRowClickEvent(event);
        setHoverColumn(column);
      }
    },
    []
  );

  const onMouseLeave = useCallback(() => {
    setHoverColumn(null);
  }, []);

  return (
    <div
      className={className}
      key={rowIndex}
      onClick={handleClick}
      data-row={rowIndex}
      style={{
        minHeight: smaller
          ? DataTableRowMinHeight.SMALLER
          : DataTableRowMinHeight.STANDARD,
        userSelect: onClickRow != null ? 'none' : null,
        minWidth: calculateMinWidthForRow(columns)
      }}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      ref={rowRef}
    >
      {columns.map((col, index) => {
        const {
          width: columnWidth = 1,
          dataKey,
          maxWidth,
          minWidth,
          dataFormatter = EmptyDataFormatter,
          flexGrow = 1,
          flexShrink = 1,
          truncateText = false,
          linkColumn = false
        } = col;

        let { align = HorizontalAlignments.LEFT } = col;

        if (truncateText && align !== HorizontalAlignments.LEFT) {
          console.error('Must use left align when truncating text');
          align = HorizontalAlignments.LEFT;
        }

        const useLinkColumn = _.isFunction(linkColumn)
          ? linkColumn(rowData)
          : linkColumn;

        return (
          <div
            key={index}
            style={{
              maxWidth,
              minWidth,
              flexGrow,
              flexShrink,
              width: columnWidth
            }}
            data-col={index}
            className={classNames(
              styles.rowColumn,
              useLinkColumn && styles.linkColumn
            )}
          >
            <DataTableCell
              display={
                dataFormatter(
                  _.get(rowData, dataKey),
                  rowData,
                  rowIndex,
                  index === hoverColumn,
                  col
                ) as React.ReactNode
              }
              rowIndex={rowIndex}
              selectedIndex={selectedIndex}
              truncateText={truncateText}
              align={align}
            />
          </div>
        );
      })}
    </div>
  );
};

export const DataTableRowMinHeight = Object.freeze({
  SMALLER: dimensions.dataTableRowHeightSmaller,
  STANDARD: dimensions.dataTableRowHeightStandard,
  WITH_BUTTONS: dimensions.dataTableRowHeightWithButtons,
  WITH_CHECKBOX: dimensions.dataTableRowHeightWithCheckbox,
  WITH_SELECT: dimensions.dataTableRowHeightWithSelect
});

export default typedMemo(DataTableRow);
