import _ from 'lodash';
import React, { useMemo } from 'react';
import animations from 'ecto-common/lib/styles/variables/animations';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import CommonGraphOptions from 'ecto-common/lib/Graph/CommonGraphOptions';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import { yAxisFormatter } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import { GaugeGraphProps } from 'ecto-common/lib/Graph/GaugeGraph';

const gaugeOptions: Highcharts.Options = {
  ...CommonGraphOptions,

  xAxis: {
    crosshair: false
  },
  chart: {
    type: 'gauge',
    plotBorderWidth: 0,
    backgroundColor: null,
    plotBackgroundColor: null,
    plotBackgroundImage: null
  },

  pane: {
    startAngle: -90,
    endAngle: 90,
    background: null,
    size: '100%',
    center: ['50%', '75%']
  },

  // the value axis
  yAxis: {
    crosshair: false,
    // NOTE: Keep an eye on 'tick position' options in newer highcharts
    minorTickPosition: 'inside',
    tickPosition: 'inside',
    labels: {
      // This is ignored explicitly because auto is working as a value, but the definitions do not agree.
      // @ts-ignore-next-line
      rotation: 'auto',
      distance: 20,
      formatter: yAxisFormatter
    }
  },
  plotOptions: {
    series: {
      animation: {
        duration: parseFloat(animations.defaultSpeed) * 1000
      }
    },
    gauge: {
      dataLabels: {
        enabled: false
      },
      dial: {
        radius: '100%'
      }
    }
  }
};

const MeterGraph = ({
  value,
  min,
  max,
  unit,
  hideUnit,
  isLoading = false,
  hasError = false
}: GaugeGraphProps) => {
  const actualUnit = hideUnit ? '' : unit;

  const config: Highcharts.Options = useMemo(() => {
    return _.merge(
      { ...gaugeOptions },
      {
        yAxis: {
          min,
          max,
          showLastLabel: true,
          labels: {
            formatter: function () {
              return formatNumberUnit(this.value, actualUnit);
            }
          },
          title: {
            text: isLoading
              ? ''
              : '<span style="font-size:18px">' +
                formatNumberUnit(value, actualUnit) +
                '</span><br/>',
            y: 0
          }
        },

        series:
          isLoading || value != null
            ? [
                {
                  name: actualUnit,
                  // When loading, the dial should rest at 0
                  data: [value]
                }
              ]
            : []
      }
    );
  }, [value, min, max, actualUnit, isLoading]);

  return (
    <StockChart config={config} hasError={hasError} isLoading={isLoading} />
  );
};

export default React.memo(MeterGraph);
