export const baseLocationUrlPath = '/:tenantId/home/:nodeId/*';

/**
 * Admin and operator location routes do not match exactly, but we still
 * use the same NodeParams to represent them. This is because we will merge
 * operator and admin and most of the properties are the same.
 *
 * Specifically, operator subPage is the same as admin itemId, and operator
 * has no itemId.
 *
 * TODO: Replace operator subPage with itemId in route constant, that way merging them will be easier
 */

export type NodeParams = 'tenantId' | 'nodeId' | 'subPage' | 'itemId' | 'page';
