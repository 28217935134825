import React, { useCallback, useState } from 'react';
import {
  getApiEnvironmentIndex,
  setApiEnvironmentIndex
} from '../utils/apiEnvironment';
import _ from 'lodash';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';

type Environment = {
  name: string;
};

// Compile time constant, will never change dynamically
const options: GenericSelectOption<number>[] = _.map(
  process.env.API_ENVIRONMENTS as unknown as Environment[],
  (env: Environment, index: number) => ({ value: index, label: env.name })
);

const LoginEnvironmentPicker = () => {
  const [currentIndex, setCurrentIndex] = useState(getApiEnvironmentIndex);
  const selectedOption = _.find(options, { value: currentIndex });

  const onChange = useCallback((option: GenericSelectOption<number>) => {
    setApiEnvironmentIndex(option.value);
    setCurrentIndex(option.value);
  }, []);

  if (options.length === 1) {
    return null;
  }

  return (
    <Select options={options} value={selectedOption} onChange={onChange} />
  );
};

export default React.memo(LoginEnvironmentPicker);
