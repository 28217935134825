import React from 'react';
import PropTypes from 'prop-types';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';

type DataTableLoadMoreFooterProps = {
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
};

const DataTableLoadMoreFooter = ({
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage
}: DataTableLoadMoreFooterProps) => {
  if (!hasNextPage) {
    return null;
  }

  return (
    <DataTableFooter alignRight>
      <Button
        onClick={() => fetchNextPage()}
        loading={isFetchingNextPage}
        disabled={isFetchingNextPage}
      >
        {T.common.datatable.loadmore}
      </Button>
    </DataTableFooter>
  );
};

DataTableLoadMoreFooter.propTypes = {
  fetchNextPage: PropTypes.func,
  isFetchingNextPage: PropTypes.bool,
  hasNextPage: PropTypes.bool
};

export default React.memo(DataTableLoadMoreFooter);
