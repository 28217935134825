import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import React from 'react';
import styles from './RecommendedSettingsControl.module.css';
import { SignalTypeIds } from 'ecto-common/lib/utils/constants';
import _ from 'lodash';
import { ScheduleSignal } from 'js/modules/schedule/schedule';
import { SignalProviderTelemetryResponseModel } from 'ecto-common/lib/API/APIGen';
import {
  REQ_STATE_ERROR,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import Heading from 'ecto-common/lib/Heading/Heading';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import Icons from 'ecto-common/lib/Icons/Icons';

const endAfterHours = 3;

const getCurrentOutsideTemperature = (
  selectedSignals: ScheduleSignal[],
  telemetryData: SignalProviderTelemetryResponseModel[]
) => {
  const airTempSignalId = _.find(
    selectedSignals,
    (x) =>
      x.signal.signalTypeId === SignalTypeIds.AIR_TEMPERATURE_SIGNAL_TYPE_ID
  )?.signal?.signalId;

  const airTempTelemetryData = _.find(
    telemetryData,
    (x) => x.signalId === airTempSignalId
  );

  const currentTime = new Date().toISOString();
  const nextItemIndex = _.findIndex(
    airTempTelemetryData?.signals,
    (x) => x.time >= currentTime
  );
  const prevValue =
    airTempTelemetryData?.signals[Math.max(nextItemIndex - 1, 0)];

  return prevValue?.value ?? 0;
};

const getRecommendedControl = (outsideTemperature: number) => {
  const highControl = 25;
  const lowControl = 10;
  const minTemp = -10;
  const maxTemp = 10;
  if (outsideTemperature >= maxTemp) {
    return -highControl;
  } else if (outsideTemperature <= minTemp) {
    return -lowControl;
  }

  const tempRange = maxTemp - minTemp;
  // Temperature from 0 to 1 within the interval -10 to 10 degrees (20 degrees difference)
  const relTemp = (outsideTemperature - minTemp) / tempRange;
  const controlRange = highControl - lowControl;

  return -Math.round(lowControl + relTemp * controlRange);
};

const RecommendedSettingsControl = ({
  onUseRecommendedSettings
}: {
  onUseRecommendedSettings: (
    endOffsetMillis: number,
    amplitude: number
  ) => void;
}) => {
  const signalValuesRequest = useOperatorSelector(
    (state) => state.schedule.signalValues
  );
  const signalProvidersReqState = useOperatorSelector(
    (state) => state.schedule.signals
  );

  const selectedSignals = useOperatorSelector(
    (state) => state.schedule.selectedSignals
  );
  const telemetryData = useOperatorSelector(
    (state) => state.schedule.chartTelemetryData
  );

  const isLoaded =
    signalValuesRequest.state === REQ_STATE_SUCCESS &&
    signalProvidersReqState.state === REQ_STATE_SUCCESS;
  const hasError =
    signalValuesRequest.state === REQ_STATE_ERROR ||
    signalProvidersReqState.state === REQ_STATE_ERROR;
  const outsideTemp = getCurrentOutsideTemperature(
    selectedSignals,
    telemetryData
  );

  const control = getRecommendedControl(outsideTemp);
  const showContent =
    !hasError &&
    (isLoaded || (selectedSignals?.length > 0 && telemetryData?.length > 0));
  return (
    <PlainBox className={styles.box}>
      <Heading level={5}>
        {T.powercontrol.dispatch.recommendedsettings.title}
      </Heading>
      {hasError && <ErrorNotice>{T.common.unknownerror} </ErrorNotice>}
      {!isLoaded && !hasError && !showContent && (
        <Spinner size={SpinnerSize.TINY} />
      )}
      {showContent && (
        <>
          <div>
            <div>
              <label>
                {T.powercontrol.dispatch.recommendedsettings.outsidetemp}:{' '}
              </label>
              {outsideTemp}°C
            </div>
            <div>
              <label>{T.powercontrol.schedule.preset.endoffset}: </label>
              {endAfterHours} {T.common.hours}
            </div>
            <div>
              <label>{T.powercontrol.schedule.preset.amplitude}: </label>
              {control}%
            </div>
          </div>
          <GreyButton
            onClick={(e) => {
              (e.target as HTMLElement)?.blur();
              onUseRecommendedSettings(endAfterHours, control);
            }}
          >
            <Icons.Calendar />
            {T.powercontrol.dispatch.recommendedsettings.usebutton}
          </GreyButton>
        </>
      )}
    </PlainBox>
  );
};

export default React.memo(RecommendedSettingsControl);
