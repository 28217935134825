import {
  AdminAlarmSignalGroupTemplateResponseModel,
  AggregationType,
  EquipmentSignalProviderTemplateResponseModel,
  NodeResponseModel,
  SamplingInterval,
  SignalInfoResponseModel,
  SignalProviderSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { FullSignalProviderResponseModel } from 'ecto-common/lib/API/APIGen';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';

export type SignalWithProvider = SignalProviderSignalResponseModel & {
  provider: FullSignalProviderResponseModel;
};
export type SignalWithProviderAndSignalInfo = SignalWithProvider &
  SignalInfoResponseModel;

export type AdminSignalTemplates = {
  equipmentSignalTemplates: Record<
    string,
    EquipmentSignalProviderTemplateResponseModel
  >;
  alarmSignalTemplates: AdminAlarmSignalGroupTemplateResponseModel[];
};

export type GenericActionType = {
  type?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export type NodeResponseModelAnnotated = NodeResponseModel & {
  parentId?: string;
  grid: string;
};

export enum GraphType {
  LINE = 'LINE',
  SCATTER = 'SCATTER'
}

export enum SeriesInterval {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  ALL = 'all'
}

export type GraphMinMaxSettings = {
  min?: number;
  max?: number;
};

export type GraphSettingsType = {
  name?: string;
  type: GraphType;
  aggregation: AggregationType;
  samplingInterval: SamplingInterval;
  seriesInterval?: SeriesInterval;
  numPoints?: number;

  minMaxSettings?: Record<string, GraphMinMaxSettings>;

  // Since we can have multiple signals with the same index but with different
  // aggregation settings, xAxisSignalId is not specific enough. Similarly, using
  // both signal id and samplingInterval/aggregation to index won't work either since the
  // aggregation and samplingInterval can change. The only realistic alternative is
  // to create a new unique ID for the chart signal type.
  xAxisChartSignalId?: string;
};

export type GraphSettingsTypeDeprecated = GraphSettingsType & {
  // This is deprecated in favour of using xAxisChartSignalId
  xAxisSignalId?: string;
};

export type GraphCollectionType = {
  settings?: GraphSettingsType;
  signals: ChartSignal[];
  id: string;
  name?: string;
};

export type DeviceProvisioningSummary = {
  devicesCount: number;
  unprovisionedDevicesCount: number;
  onlineDevicesCount: number;
  offlineDevicesCount: number;
};

export type SignalProviderSignalWithProviderResponseModel =
  SignalProviderSignalResponseModel & {
    signalProvider: FullSignalProviderResponseModel;
  };
