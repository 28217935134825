import { useCallback, useContext } from 'react';
import { getSeriesName } from 'ecto-common/lib/SignalSelector/ChartUtils';
import _ from 'lodash';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import APIGen, {
  NodeV2ResponseModel,
  NodeParentInformationResponseModel,
  SignalProviderByNodeResponseModel,
  SignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import { ApiContextSettings } from '../../API/APIUtils';

type BatchablePromise = (
  contextSettings: ApiContextSettings,
  itemIds: string[],
  ...restArgs: unknown[]
) => Promise<unknown>;

type CancelToken = {
  cancelled: boolean;
};

type PendingCachePromise = {
  itemIds: string[];
  extraArgs: unknown[];
  resolve: (arg: unknown) => void;
  reject: (arg: unknown) => void;
  cancelToken: CancelToken;
};

type CacheItem = {
  cachedValues: Map<string, BatchablePromise[]>;
  pendingPromises: PendingCachePromise[];
  scheduled: boolean;
};

export type PromiseCacheContext = Map<string, CacheItem>;

export const useSignalSeriesName = ({
  nodes,
  equipmentParentNodes
}: {
  nodes: NodeV2ResponseModel[];
  equipmentParentNodes: NodeParentInformationResponseModel[];
}) => {
  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  return useCallback(
    (
      signal: SignalResponseModel,
      signalProvider: SignalProviderByNodeResponseModel
    ) => {
      return getSeriesName(
        signal,
        signalProvider,
        signalTypesMap,
        signalUnitTypesMap,
        nodes,
        equipmentParentNodes
      );
    },
    [equipmentParentNodes, nodes, signalTypesMap, signalUnitTypesMap]
  );
};

const resolveBatchedResults = (
  result: object,
  promises: PendingCachePromise[],
  cacheObject: CacheItem,
  withCache: boolean,
  resultKey: string
) => {
  for (const promiseInfo of promises) {
    const idArray = promiseInfo.itemIds;

    // Create a partial result with results only for the requested id:s in this call
    const subResults: BatchablePromise[] = _.filter(result, (resultPart) => {
      const partIdObject = _.get(resultPart, resultKey);

      if (_.isArray(partIdObject)) {
        return _.some(partIdObject, (resultNodeId: string) =>
          idArray.includes(resultNodeId)
        );
      }

      return idArray.includes(partIdObject);
    });

    if (withCache) {
      cacheObject.cachedValues.set(idArray.join(','), subResults);
    }

    promiseInfo.resolve(subResults);
  }
};

const handleBatchedPromises = (
  contextSettings: ApiContextSettings,
  promise: (
    contextSettings: ApiContextSettings,
    itemIds: string[],
    ...restArgs: unknown[]
  ) => Promise<unknown>,
  cacheObject: CacheItem,
  withCache: boolean,
  resultKey: string
) => {
  const promises = _.reject(
    cacheObject.pendingPromises,
    'cancelToken.cancelled'
  );
  const grouped = _.groupBy(promises, 'extraArgs');

  cacheObject.scheduled = false;
  cacheObject.pendingPromises = [];

  _.forEach(grouped, (groupedPromises) => {
    // Combine all the pending requests into a single large request
    const allItemIds = _.orderBy(_.uniq(_.flatMap(groupedPromises, 'itemIds')));
    const allItemIdsKey = allItemIds.join(',');
    const sharedExtraArgs = _.head(groupedPromises).extraArgs;

    if (withCache && cacheObject.cachedValues.has(allItemIdsKey)) {
      resolveBatchedResults(
        cacheObject.cachedValues.get(allItemIdsKey),
        groupedPromises,
        cacheObject,
        withCache,
        resultKey
      );
    } else {
      // Pass null for the abort signal argument
      promise(contextSettings, allItemIds, ...sharedExtraArgs, null)
        .then((result: BatchablePromise[]) => {
          if (withCache) {
            cacheObject.cachedValues.set(allItemIdsKey, result);
          }

          resolveBatchedResults(
            result,
            groupedPromises,
            cacheObject,
            withCache,
            resultKey
          );
        })
        .catch((error: unknown) => {
          _.forEach(groupedPromises, (promiseInfo) =>
            promiseInfo.reject(error)
          );
        });
    }
  });
};

/**
 * This method can be used to batch together calls to the same API that happen during the same frame.
 * For instance, if you have a bunch of data sources that make the same request they can be routed
 * through this method so that only a single API call is made.
 *
 * @param promise The actual promise that will be used to call the API
 * @param cacheContext Shared cache context used to coordinate data between requests
 * @param withCache Whether or not requests should be cached
 * @param resultKey The property in the result items that holds the ID or ID:s of the related object. For instance, if you request with a list of signalIds, each response item might have a signalId property.
 * @returns {(function(*, ...[*]): (Promise<unknown>))|*}
 */
const batchedPromise = (
  contextSettings: ApiContextSettings,
  promise: BatchablePromise,
  promiseKey: string,
  cacheContext: PromiseCacheContext,
  withCache: boolean,
  resultKey: string
) => {
  return (itemIds: string[], ...extraArgs: unknown[]) => {
    if (!cacheContext.has(promiseKey)) {
      cacheContext.set(promiseKey, {
        cachedValues: new Map(),
        pendingPromises: [],
        scheduled: false
      });
    }

    const cacheObject = cacheContext.get(promiseKey);
    const itemIdKey = itemIds.join(',');

    if (withCache && cacheObject.cachedValues.has(itemIdKey)) {
      const cachedPromise = Promise.resolve(
        cacheObject.cachedValues.get(itemIdKey)
      );
      return cachedPromise;
    }

    if (!cacheObject.scheduled) {
      cacheObject.scheduled = true;

      // Wait an iteration of the run loop to see if other requests to the same API (promise) will occur.
      // If so, we can batch them together in one large request.
      _.defer(() => {
        handleBatchedPromises(
          contextSettings,
          promise,
          cacheObject,
          withCache,
          resultKey
        );
      });
    }

    // We can't use ordinary full request cancellation since that might interfere with other
    // running requests (plus we wouldn't populate the cache if we did). So just mark the object
    // as cancelled and skip resolving the promise with the results.
    const cancelToken = {
      cancelled: false
    };

    const ret = new Promise((resolve, reject) => {
      cacheObject.pendingPromises.push({
        itemIds,
        extraArgs,
        resolve,
        reject,
        cancelToken
      });
    });

    return ret;
  };
};

const getSignalsPromiseKey = 'getSignalsPromise';
const getLastSignalValuesPromiseKey = 'getLastSignalValuesPromise';

const wrappedGetSignalsPromise = (
  contextSettings: ApiContextSettings,
  itemIds: string[],
  extraArgs: object
) => {
  return APIGen.Signals.getSignalsByNode.promise(
    contextSettings,
    {
      nodesIds: itemIds,
      ...extraArgs
    },
    null
  );
};

const wrappedGetLastValuePromise = (
  contextSettings: ApiContextSettings,
  itemIds: string[],
  extraArgs: object
) => {
  return APIGen.Signals.getLastSignalValues.promise(
    contextSettings,
    {
      signalIds: itemIds,
      ...extraArgs
    },
    null
  );
};

export const batchedGetSignalsForNodesPromise = (
  contextSettings: ApiContextSettings,
  cacheContext: PromiseCacheContext
) => {
  return batchedPromise(
    contextSettings,
    wrappedGetSignalsPromise,
    getSignalsPromiseKey,
    cacheContext,
    true,
    'nodeIds'
  );
};

export const batchedGetLastValuePromise = (
  contextSettings: ApiContextSettings,
  cacheContext: PromiseCacheContext
) => {
  return batchedPromise(
    contextSettings,
    wrappedGetLastValuePromise,
    getLastSignalValuesPromiseKey,
    cacheContext,
    false,
    'signalId'
  );
};
