import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect
} from 'react';
import { CustomModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import Button from 'ecto-common/lib/Button/Button';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { KeyValueInput } from 'ecto-common/lib/KeyValueInput/KeyValueInput';
import Icons from 'ecto-common/lib/Icons/Icons';

import fileEditorStyles from './EctoplannerProfileEditor.module.css';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ArrayUtils from 'js/components/Ectoplanner/Calculation/utils/arrayUtils';
import _ from 'lodash';
import Flex, { FlexItem } from 'ecto-common/lib/Layout/Flex';
import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import T from 'ecto-common/lib/lang/Language';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import Heading from 'ecto-common/lib/Heading/Heading';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';
import { EctoplannerDemandGraphs } from 'js/components/Ectoplanner/EctoplannerDemandGraphs';
import Notice from 'ecto-common/lib/Notice/Notice';
import colors from 'ecto-common/lib/styles/variables/colors';
import {
  EctoplannerDemandTypes,
  EctoplannerFormEnvironment,
  EctoplannerProfileType,
  EctoplannerProfileTypes,
  PlotType,
  PlotTypes
} from 'js/components/Ectoplanner/EctoplannerTypes';
import { EctoplannerDemandType, HOURS_IN_YEAR } from './EctoplannerTypes';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import EctoplannerSelectGraphDialog from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerSelectGraphDialog';

const ProfileUnits = {
  W: 'W',
  kW: 'kW',
  MW: 'MW',
  Btuh: 'Btuh',
  COP: 'cop',
  Price: 'price',
  PriceMwh: 'pricemwh',
  DegC: '°C',
  GCo2Kwh: 'gCO₂eq/kWh',
  M3: 'm³'
} as const;

type ProfileUnit = (typeof ProfileUnits)[keyof typeof ProfileUnits];

const copProfileOptions: GenericSelectOption<ProfileUnit>[] = [
  {
    label: T.ectoplanner.profileeditor.types.cop,
    value: ProfileUnits.COP
  }
];

const priceProfileOptions: GenericSelectOption<ProfileUnit>[] = [
  {
    label: T.ectoplanner.profileeditor.types.price,
    value: ProfileUnits.Price
  },
  {
    label: T.ectoplanner.profileeditor.types.pricemwh,
    value: ProfileUnits.PriceMwh
  }
];

const priceProfileOptionsMwhOnly = priceProfileOptions.filter(
  (option) => option.value === ProfileUnits.PriceMwh
);

const consumptionProfileOptions: GenericSelectOption<ProfileUnit>[] = [
  {
    label: T.ectoplanner.profileeditor.types.consumptionm3,
    value: ProfileUnits.M3
  }
];

const emissionProfileOptions: GenericSelectOption<ProfileUnit>[] = [
  {
    label: T.ectoplanner.profileeditor.types.emissions,
    value: ProfileUnits.GCo2Kwh
  }
];

const temperatureProfileOptions: GenericSelectOption<ProfileUnit>[] = [
  {
    label: T.ectoplanner.profileeditor.types.temperature,
    value: ProfileUnits.DegC
  }
];

const profileOptions: GenericSelectOption<ProfileUnit>[] = [
  {
    label: T.ectoplanner.units.w,
    value: ProfileUnits.W
  },
  {
    label: T.ectoplanner.units.kw,
    value: ProfileUnits.kW
  },
  {
    label: T.ectoplanner.units.mw,
    value: ProfileUnits.MW
  },
  {
    label: T.ectoplanner.units.btuh,
    value: ProfileUnits.Btuh
  }
];

type EctoplannerProfileEditorProps<
  ObjectType extends object,
  EnvironmentType extends object
> = CustomModelEditorProps<ObjectType, EnvironmentType> & {
  profileType?: EctoplannerProfileType;
  energyKind?: EctoplannerDemandType;
  allowDelete?: boolean;
};

const defaultProfileUnitForProfileTypes: Record<
  EctoplannerProfileType,
  ProfileUnit
> = {
  [EctoplannerProfileTypes.Energy]: ProfileUnits.kW,
  [EctoplannerProfileTypes.COP]: ProfileUnits.COP,
  [EctoplannerProfileTypes.Price]: ProfileUnits.Price,
  [EctoplannerProfileTypes.PriceEurMwh]: ProfileUnits.PriceMwh,
  [EctoplannerProfileTypes.Temperature]: ProfileUnits.DegC,
  [EctoplannerProfileTypes.EmissionsGCo2Kwh]: ProfileUnits.GCo2Kwh,
  [EctoplannerProfileTypes.ConsumptionM3]: ProfileUnits.M3
};

export const EctoplannerProfileEditor = <
  ObjectType extends object = EctoplannerForm,
  EnvironmentType extends object = EctoplannerFormEnvironment
>({
  rawValue,
  model,
  updateItem,
  isHorizontal,
  profileType = EctoplannerProfileTypes.Energy,
  energyKind = EctoplannerDemandTypes.Heating,
  helpText = null,
  useTooltipHelpTexts = false,
  allowDelete = true
}: EctoplannerProfileEditorProps<ObjectType, EnvironmentType>) => {
  let rawArray = rawValue;
  if (rawArray && !_.isArray(rawValue)) {
    rawArray = null;
  }

  let color = colors.heatingColor;

  switch (energyKind) {
    case EctoplannerDemandTypes.Heating:
      color = colors.heatingColor;
      break;
    case EctoplannerDemandTypes.Cooling:
      color = colors.coolingColor;
      break;
    case EctoplannerDemandTypes.Electricity:
      color = colors.electricityColor;
      break;
    default:
      break;
  }

  const [importTimeSeriesIsOpen, openImportTimeSeries, hideImportTimeSeries] =
    useSimpleDialogState();
  const [profileEditorIsOpen, openProfileEditor, hideProfileEditor] =
    useSimpleDialogState();
  const [selectedUnit, setSelectedUnit] = useState<ProfileUnit>(
    defaultProfileUnitForProfileTypes[profileType]
  );

  useEffect(() => {
    setSelectedUnit(defaultProfileUnitForProfileTypes[profileType]);
  }, [profileType]);

  const [timeSeriesText, setTimeSeriesText] = useState('');
  const [pendingTimeSeries, setPendingTimeSeries] = useState<number[]>(null);
  const [isValid, setIsValid] = useState(false);
  const [error, setError] = useState<string>(null);
  let profileOptionsToUse: GenericSelectOption<ProfileUnit>[] = [];
  let graphUnit = '';
  let title = '';
  let plotType: PlotType = PlotTypes.Annual;

  // eslint-disable-next-line default-case
  switch (profileType) {
    case EctoplannerProfileTypes.Energy:
      profileOptionsToUse = profileOptions;
      graphUnit = T.ectoplanner.units.kw;
      title = T.ectoplanner.profileeditor.typetitles.energy;
      break;
    case EctoplannerProfileTypes.COP:
      profileOptionsToUse = copProfileOptions;
      title = T.ectoplanner.profileeditor.typetitles.cop;
      graphUnit = '';
      plotType = PlotTypes.AnnualCOP;
      break;
    case EctoplannerProfileTypes.Price:
      profileOptionsToUse = priceProfileOptions;
      title = T.ectoplanner.profileeditor.typetitles.price;
      graphUnit = T.ectoplanner.units.eurkwh;
      plotType = PlotTypes.AnnualPrice;
      break;
    case EctoplannerProfileTypes.PriceEurMwh:
      profileOptionsToUse = priceProfileOptionsMwhOnly;
      title = T.ectoplanner.profileeditor.typetitles.price;
      graphUnit = T.ectoplanner.units.eurmwh;
      plotType = PlotTypes.AnnualPriceEurMwh;
      break;
    case EctoplannerProfileTypes.Temperature:
      profileOptionsToUse = temperatureProfileOptions;
      title = T.ectoplanner.profileeditor.typetitles.temperature;
      graphUnit = T.ectoplanner.units.degc;
      plotType = PlotTypes.AnnualTemperature;
      break;
    case EctoplannerProfileTypes.ConsumptionM3:
      profileOptionsToUse = consumptionProfileOptions;
      title = T.ectoplanner.profileeditor.typetitles.consumption;
      graphUnit = T.ectoplanner.units.m3;
      plotType = PlotTypes.AnnualM3;
      break;
    case EctoplannerProfileTypes.EmissionsGCo2Kwh:
      profileOptionsToUse = emissionProfileOptions;
      title = T.ectoplanner.profileeditor.typetitles.emissions;
      graphUnit = T.ectoplanner.units.gco2kwh;
      plotType = PlotTypes.AnnualGCo2Kwh;
      break;
    default:
      break;
  }

  const fileReaderRef = useRef<FileReader>(null);

  const validateTimeSeries = useCallback(
    (text: string) => {
      let newIsValid = true;
      let newSeries: number[] = [];
      let newError: string = null;

      try {
        let lines: string[] = text.split('\n').filter((x) => x !== '');

        if (lines.length === 1) {
          const csvLines = lines[0].split(',');
          if (csvLines.length > 2) {
            lines = csvLines;
          }
        }

        const joinedLines = lines.join('');
        if (
          joinedLines.indexOf(',') !== -1 &&
          joinedLines.indexOf('.') !== -1
        ) {
          newError = T.ectoplanner.profileeditor.invalidformaterror;
          throw new Error('');
        }

        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];
          lines[i] = line.replace(' ', '').replace(',', '.');
        }

        let rawNumbers: number[] = lines.map((lineStr) => parseFloat(lineStr));

        if (selectedUnit === ProfileUnits.W) {
          rawNumbers = rawNumbers.map((x) => x / 1000.0);
        } else if (selectedUnit === ProfileUnits.MW) {
          rawNumbers = rawNumbers.map((x) => x * 1000.0);
        } else if (selectedUnit === ProfileUnits.Btuh) {
          rawNumbers = rawNumbers.map((x) => x * 0.29307107017222);
        } else if (
          selectedUnit === ProfileUnits.PriceMwh &&
          profileType === EctoplannerProfileTypes.Price
        ) {
          rawNumbers = rawNumbers.map((x) => x / 1000.0);
        }

        if (rawNumbers.length === 1) {
          newSeries = ArrayUtils.createConstantArray(
            HOURS_IN_YEAR,
            rawNumbers[0]
          );
        } else if (rawNumbers.length === 4) {
          newSeries = [
            ...ArrayUtils.createConstantArray(1872, rawNumbers[0]),
            ...ArrayUtils.createConstantArray(2232, rawNumbers[1]),
            ...ArrayUtils.createConstantArray(2256, rawNumbers[2]),
            ...ArrayUtils.createConstantArray(2136, rawNumbers[3]),
            ...ArrayUtils.createConstantArray(264, rawNumbers[0])
          ];
        } else if (rawNumbers.length === 12) {
          newSeries = [
            ...ArrayUtils.createConstantArray(744, rawNumbers[0]),
            ...ArrayUtils.createConstantArray(672, rawNumbers[1]),
            ...ArrayUtils.createConstantArray(744, rawNumbers[2]),
            ...ArrayUtils.createConstantArray(720, rawNumbers[3]),
            ...ArrayUtils.createConstantArray(744, rawNumbers[4]),
            ...ArrayUtils.createConstantArray(720, rawNumbers[5]),
            ...ArrayUtils.createConstantArray(744, rawNumbers[6]),
            ...ArrayUtils.createConstantArray(744, rawNumbers[7]),
            ...ArrayUtils.createConstantArray(720, rawNumbers[8]),
            ...ArrayUtils.createConstantArray(744, rawNumbers[9]),
            ...ArrayUtils.createConstantArray(720, rawNumbers[10]),
            ...ArrayUtils.createConstantArray(744, rawNumbers[11])
          ];
        } else if (rawNumbers.length === 365) {
          for (let i = 0; i < 365; i++) {
            const num = rawNumbers[i];
            newSeries.push(...ArrayUtils.createConstantArray(24, num));
          }
        } else if (rawNumbers.length === HOURS_IN_YEAR) {
          newSeries = rawNumbers;
        } else {
          newError = T.format(
            T.ectoplanner.profileeditor.invalidnumlineserror,
            rawNumbers.length
          ).join('');
          newIsValid = false;
        }
      } catch (e: unknown) {
        console.error(e);
        newIsValid = false;
      }

      setError(newError);
      setIsValid(newIsValid);
      setPendingTimeSeries(newSeries);
      return [newIsValid, newSeries] as const;
    },
    [profileType, selectedUnit]
  );

  const showFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();

      if (!fileReaderRef.current) {
        fileReaderRef.current = new FileReader();
      }

      const file = _.head((e.target as HTMLInputElement).files);

      fileReaderRef.current.onload = (progressEvent) => {
        setTimeSeriesText(progressEvent.target.result as string);
        validateTimeSeries(progressEvent.target.result as string);
      };

      fileReaderRef.current.readAsText(file);
    },
    [validateTimeSeries]
  );

  const validateAndClose = () => {
    const [newSeriesIsValid, newSeries] = validateTimeSeries(timeSeriesText);

    if (newSeriesIsValid) {
      hideProfileEditor();
      updateItem(newSeries);
    }
  };

  const [min, max, annualSum] = useMemo(() => {
    return pendingTimeSeries
      ? [
          _.min(pendingTimeSeries),
          _.max(pendingTimeSeries),
          _.sum(pendingTimeSeries)
        ]
      : [0, 0, 0];
  }, [pendingTimeSeries]);

  const clearProfile = () => {
    updateItem(null);
    setPendingTimeSeries(null);
  };

  const editProfile = () => {
    const newText = rawArray ? rawValue.join('\n') : '';
    setTimeSeriesText(newText);

    if (rawArray != null) {
      validateTimeSeries(newText);
    } else {
      setPendingTimeSeries([]);
    }

    openProfileEditor();
  };

  const onImportDone = (
    _projectId: string,
    _buildId: string,
    _timeSeriesId: string,
    data: number[]
  ) => {
    hideImportTimeSeries();
    setTimeSeriesText(data.join('\n'));
    validateTimeSeries(data.join('\n'));
  };

  const handleDrop = (e: React.DragEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const reader = new FileReader();
    reader.onload = function (event) {
      const newText = event.target.result as string;
      setTimeSeriesText(newText);
      validateTimeSeries(newText);
    };
    reader.readAsText(e.dataTransfer.files[0]);
  };

  return (
    <>
      <KeyValueGeneric
        keyText={model.label}
        isHorizontal={isHorizontal}
        horizontalWeights={model.horizontalWeights}
        helpText={helpText}
        useTooltipHelpTexts={useTooltipHelpTexts}
      >
        <Flex>
          <Button onClick={editProfile}>
            <Icons.File /> {T.common.edit} ...
          </Button>
          {rawValue != null && allowDelete && (
            <DeleteButton onClick={clearProfile}>
              {T.common.delete}
            </DeleteButton>
          )}
        </Flex>
      </KeyValueGeneric>
      <ActionModal
        wide
        isOpen={profileEditorIsOpen}
        onModalClose={hideProfileEditor}
        title={title}
        actionText={T.ectoplanner.profileeditor.action}
        onConfirmClick={validateAndClose}
        headerIcon={Icons.File}
        leftSideButton={
          <>
            <Button onClick={() => validateTimeSeries(timeSeriesText)}>
              <Icons.Checkmark />
              {T.ectoplanner.profileeditor.validatetimeseries}
            </Button>
            <Button onClick={openImportTimeSeries}>
              <Icons.Download />
              {T.ectoplanner.graphs.import}
            </Button>
          </>
        }
      >
        <Flex>
          <FlexItem grow={1}>
            <KeyValueSelectableInput<GenericSelectOption<ProfileUnit>>
              keyText={T.ectoplanner.profileeditor.select.unit}
              value={profileOptionsToUse.find((x) => x.value === selectedUnit)}
              options={profileOptionsToUse}
              onChange={(x) => setSelectedUnit(x.value)}
            />
          </FlexItem>
          <FlexItem grow={1}>
            <KeyValueInput
              keyText={T.ectoplanner.profileeditor.select.timeseriesdata}
              value={timeSeriesText}
              rows={10}
              onChange={(x) => setTimeSeriesText(x.target.value)}
              multiline
              placeholder={
                T.ectoplanner.form.building.params.textareaplaceholder
              }
              onDrop={handleDrop}
            />
          </FlexItem>
          <FlexItem grow={1}>
            <KeyValueGeneric keyText={T.common.selectfile}>
              <label className={fileEditorStyles.uploadLabel}>
                {T.common.selectfile}
                <input type="file" onChange={showFile} />
              </label>
            </KeyValueGeneric>
          </FlexItem>
        </Flex>
        <Heading level={3} withMarginTop>
          {T.ectoplanner.profileeditor.timeseries}
        </Heading>
        {pendingTimeSeries && !isValid && (
          <ErrorNotice>
            {T.format(
              T.ectoplanner.profileeditor.invalidtimeseriesformat,
              error != null ? ': ' + error : ''
            )}
          </ErrorNotice>
        )}
        {!pendingTimeSeries && !isValid && (
          <Notice> {T.ectoplanner.profileeditor.notimeseriesnotice} </Notice>
        )}
        {pendingTimeSeries && isValid && (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ minWidth: 150 }}>
                <KeyValueGeneric keyText={T.ectoplanner.profileeditor.range}>
                  {formatNumberUnit(min, null)} - {formatNumberUnit(max, null)}{' '}
                  {graphUnit}
                </KeyValueGeneric>
                {profileType === EctoplannerProfileTypes.Energy && (
                  <KeyValueGeneric
                    keyText={T.ectoplanner.profileeditor.annualsum}
                  >
                    {formatNumberUnit(
                      annualSum / 1000.0,
                      T.ectoplanner.units.mwh
                    )}
                  </KeyValueGeneric>
                )}
              </div>
              <EctoplannerDemandGraphs
                disableLegend
                primaryGraph={pendingTimeSeries}
                primaryName={T.ectoplanner.profileeditor.manualcurve}
                primaryColor={color}
                plotType={plotType}
              />
            </div>
          </>
        )}
      </ActionModal>
      <EctoplannerSelectGraphDialog
        isOpen={importTimeSeriesIsOpen}
        onModalClose={hideImportTimeSeries}
        onSelectedTimeSeries={onImportDone}
      />
    </>
  );
};
