import React from 'react';

import { FuseIcon } from 'ecto-common/lib/Icon';
import T from 'ecto-common/lib/lang/Language';

import {
  getSignalNameWithSignalType,
  getSignalTypeName
} from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import {
  FullSignalProviderResponseModel,
  NodeV2ResponseModel,
  SignalProviderSignalResponseModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import Flex, { AlignItems } from 'ecto-common/lib/Layout/Flex';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';

interface SignalSelectorTableProps {
  node?: NodeV2ResponseModel;
  isEquipment?: boolean;
  selectedSignals: ChartSignal[];
  signalProviders?: FullSignalProviderResponseModel[];
  addNewSignal?(
    signal: SignalProviderSignalResponseModel,
    provider: FullSignalProviderResponseModel
  ): void;
  hasSignal?(
    signals: ChartSignal[],
    signal: SignalProviderSignalResponseModel,
    provider: FullSignalProviderResponseModel
  ): boolean;
  showSignalType?: boolean;
  validSignalTypeIds?: string[];
}

export const SignalSelectorTable = ({
  node,
  isEquipment,
  selectedSignals,
  signalProviders,
  addNewSignal,
  hasSignal,
  showSignalType,
  validSignalTypeIds
}: SignalSelectorTableProps) => {
  let providers = signalProviders ?? [];
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  // Put Equipment providers first
  const eqProviders = providers.filter(
    (x) => x.signalProviderType === SignalProviderType.Equipment
  );
  const otherProviders = providers.filter(
    (x) => x.signalProviderType !== SignalProviderType.Equipment
  );
  providers = eqProviders.concat(otherProviders);

  if (providers.length === 0) {
    const noDataColumns = [
      {
        dataKey: 'text',
        label: node.name,
        dataFormatter: (text: string) => (
          <Flex alignItems={AlignItems.CENTER} gap={0}>
            <FuseIcon /> {text}
          </Flex>
        )
      }
    ];

    const data = [
      {
        text:
          validSignalTypeIds != null
            ? T.signals.nocompatiblesignalsfound
            : T.signals.nosignalsfound
      }
    ];

    return <DataTable inline columns={noDataColumns} data={data} />;
  }

  const getColumns = (provider: FullSignalProviderResponseModel) => [
    {
      dataKey: 'signalId',
      label:
        node &&
        (isEquipment ? (
          provider.signalProviderName
        ) : (
          <TableColumn
            title={provider.signalProviderName}
            subtitle={node.name}
          />
        )),
      dataFormatter: (
        _signalId: string,
        signal: SignalProviderSignalResponseModel
      ) => {
        return (
          <Flex alignItems={AlignItems.CENTER} gap={0}>
            <FuseIcon />
            <TableColumn
              minHeight={0}
              truncateText={false}
              title={getSignalNameWithSignalType(
                signal,
                showSignalType,
                signalTypesMap,
                signalUnitTypesMap
              )}
              subtitle={getSignalTypeName(signal.signalTypeId, signalTypesMap)}
            />
          </Flex>
        );
      }
    },
    ...standardColumns({
      onAdd: (signal: SignalProviderSignalResponseModel) => {
        addNewSignal(signal, provider);
      },
      shouldDisableAdd: (signal: SignalProviderSignalResponseModel) =>
        hasSignal(selectedSignals, signal, provider)
    })
  ];

  return (
    <div>
      {providers.map((provider) => (
        <DataTable
          key={provider.signalProviderId}
          columns={getColumns(provider)}
          data={provider.signals}
          inline
        />
      ))}
    </div>
  );
};
