export const createAction = (type: string, ...argNames: string[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (...args: any[]) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const action: Record<string, any> = {};
    argNames.forEach((_arg, index) => {
      action[argNames[index]] = args[index];
    });
    return { type, payload: action };
  };
};
