import { useEffect } from 'react';
import _ from 'lodash';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';

const usePageTitleCallback = ({
  mainTitle,
  subTitle,
  onTitleChanged
}: {
  mainTitle: string;
  subTitle: string;
  onTitleChanged: (title: string[]) => void;
}) => {
  const { currentNode } = useCurrentNode();

  useEffect(() => {
    onTitleChanged?.(_.compact([currentNode?.name, subTitle, mainTitle]));
  }, [onTitleChanged, mainTitle, subTitle, currentNode?.name]);
};
export default usePageTitleCallback;
