import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from '../AcknowledgeButton.module.css';
import Button from 'ecto-common/lib/Button/Button';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { AlarmModel } from 'ecto-common/lib/API/AlarmsAPIGen';
import { AcknowledgeAlarmModalV2 } from 'ecto-common/lib/Alarms/V2/AcknowledgeAlarmModalV2';
import {
  AlarmEvent,
  AlarmEventTypes,
  AlarmUpdateContext
} from 'ecto-common/lib/Alarms/V2/useAlarmUpdates';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';

type AcknowledgeButtonProps = {
  node: NodeV2ResponseModel;
  alarm: AlarmModel;
};

type PendingPhase = 'pending' | 'pendingnodata' | 'idle';

const AcknowledgeButtonV2 = ({ node, alarm }: AcknowledgeButtonProps) => {
  const [isAcknowledgeModalOpen, showAcknowledgeModal, hideAcknowledgeModal] =
    useSimpleDialogState();
  const isAcknowledged = alarm.acknowledgedAt != null;
  const [pendingAckState, setPendingAckState] = useState<PendingPhase>('idle');
  const { acknowledgedBy, acknowledgedComment, canAcknowledge } = alarm;
  const { addListener, removeListener } = useContext(AlarmUpdateContext);

  if (pendingAckState !== 'idle' && isAcknowledged) {
    setPendingAckState('idle');
  }

  useEffect(() => {
    const listener = (arg: AlarmEvent) => {
      if (
        arg.eventType === AlarmEventTypes.Acknowledged &&
        arg.sourceUri === alarm.sourceUri
      ) {
        setPendingAckState('pendingnodata');
      }
    };

    addListener(listener);

    return () => {
      removeListener(listener);
    };
  }, [addListener, alarm.sourceUri, node, removeListener]);

  const onSuccess = useCallback(() => {
    setPendingAckState('pending');
    hideAcknowledgeModal();
  }, [hideAcknowledgeModal]);

  if (isAcknowledged) {
    return (
      <div>
        <strong>{acknowledgedBy || T.common.unknown}</strong>
        {': '}
        {acknowledgedComment || <em>{T.alarms.acknowledgedwithoutcomment}</em>}
      </div>
    );
  }

  const loading = pendingAckState !== 'idle' && !isAcknowledged;

  return (
    <div>
      <Button
        className={styles.button}
        onClick={showAcknowledgeModal}
        disabled={!canAcknowledge || loading}
        loading={loading}
      >
        <Icons.Checkmark />
        {T.alarms.acknowledge.action}
      </Button>

      <AcknowledgeAlarmModalV2
        isOpen={isAcknowledgeModalOpen}
        onModalClose={hideAcknowledgeModal}
        node={node}
        alarm={alarm}
        onSuccess={onSuccess}
      />
    </div>
  );
};

export default AcknowledgeButtonV2;
