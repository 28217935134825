import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import moment, { Moment } from 'moment';

import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { AddIcon, EditIcon } from 'ecto-common/lib/Icon';
import T from 'ecto-common/lib/lang/Language';
import { DEFAULT_TIMEZONE } from 'ecto-common/lib/constants';
import APIGen, {
  AddOrUpdatePowerControlScheduleRequestModel,
  NodeV2ResponseModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import { startOfDay } from 'ecto-common/lib/utils/dateUtils';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import { BuildingStatus } from 'ecto-common/lib/API/APIGen';

import { IMPACT_RANGE } from 'js/components/EMPTools/PowerControl/Dispatch/utils/signals';
import {
  createSchedules,
  firstStartDate,
  testPresetSchedules,
  SchedulePresetTypeSchedule
} from 'js/components/EMPTools/PowerControl/Dispatch/TestSchedules';
import EffectControl from 'js/components/EMPTools/PowerControl/Dispatch/Components/EffectControl';
import DurationControl from 'js/components/EMPTools/PowerControl/Dispatch/Components/DurationControl';
import StartTimeControl, {
  TIME_TYPE_CALENDAR,
  TIME_TYPE_OFFSET
} from 'js/components/EMPTools/PowerControl/Dispatch/Components/StartTimeControl';
import PresetsContainer from 'js/components/EMPTools/PowerControl/Dispatch/Components/PresetsContainer';
import ScheduleSummary from 'js/components/EMPTools/PowerControl/Dispatch/Components/ScheduleSummary';
import { HOUR_MILLIS } from 'js/components/EMPTools/PowerControl/Dispatch/utils/dateUtils';
import TemperatureImpactPreview from 'js/components/EMPTools/PowerControl/Dispatch/Components/TemperatureImpactPreview';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleModal.module.css';
import { REQ_STATE_PENDING } from 'ecto-common/lib/utils/requestStatus';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import Heading from 'ecto-common/lib/Heading/Heading';
import Switch from 'ecto-common/lib/Switch/Switch';
import { ScheduleSourceTypeIds } from 'js/components/EMPTools/PowerControl/Dispatch/utils/scheduleConstants';
import HelpPaths from 'ecto-common/help/tocKeys';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import RecommendedSettingsControl from 'js/components/EMPTools/PowerControl/Dispatch/Components/RecommendedSettingsControl';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

const MALMO_TENANT_ID = 'se-malmo';

type ScheduleModalSettings = {
  now: Date;
  startDate: Date;
  endDate: Date;
  startDateIsValid?: boolean;
  startOffset: number;
  duration: number;
  amplitude: number;
  selectedPreset: number;
  timeType: string;
};

const INITIAL_STATE: ScheduleModalSettings = {
  now: undefined,
  startDate: null,
  endDate: null,
  startDateIsValid: true,
  startOffset: 0,
  duration: 1,
  amplitude: 1,
  selectedPreset: -1,
  timeType: TIME_TYPE_OFFSET
};

const roundHalf = (num: number) => Math.round(num * 2) / 2;

export type SchedulePresetType = {
  name: string;
  startOffset: number;
  endOffset?: number;
  amplitude?: number;
  isPowerControlTest: boolean;
  schedules: SchedulePresetTypeSchedule[];
};

const EMPTY_PRESETS: SchedulePresetType[] = [];

const DEFAULT_PRESETS: SchedulePresetType[] = [
  {
    name: T.powercontrol.schedule.preset1,
    startOffset: 0,
    endOffset: 1,
    amplitude: -75,
    isPowerControlTest: false,
    schedules: []
  },
  {
    name: T.powercontrol.schedule.preset2,
    startOffset: 0,
    endOffset: 3,
    amplitude: -50,
    isPowerControlTest: false,
    schedules: []
  },
  {
    name: T.powercontrol.schedule.preset3,
    startOffset: 0,
    endOffset: 4,
    amplitude: -25,
    isPowerControlTest: false,
    schedules: []
  }
];

const getUTCDates = (
  now: Date,
  timeType: string,
  startDate: Date,
  startOffset: number,
  duration: number
): { utcStartDate: Moment; utcEndDate: Moment } => {
  let utcStartDate;
  let utcEndDate;

  if (timeType === TIME_TYPE_CALENDAR) {
    utcStartDate = moment(new Date(startDate).getTime());
    utcEndDate = moment(new Date(startDate).getTime() + duration * HOUR_MILLIS);
  } else {
    utcStartDate = moment(now.getTime() + startOffset * HOUR_MILLIS);
    utcEndDate = moment(now.getTime() + (startOffset + duration) * HOUR_MILLIS);
  }

  return { utcStartDate, utcEndDate };
};

interface ScheduleModalProps {
  isOpen: boolean;
  isEditing: boolean;
  onModalClose: () => void;
  onCreateSchedules(
    schedules: AddOrUpdatePowerControlScheduleRequestModel[]
  ): void;
  initialStartDate?: string;
  initialEndDate?: string;
  initialAmplitude: number;
  selectedNode?: NodeV2ResponseModel;
  scheduleId?: string;
  isLoading?: boolean;
  duration?: number;
  editScheduleSourceTypeId?: string;
  editScheduleIsAcuteStop?: boolean;
}

const ScheduleModal = ({
  isOpen,
  isEditing,
  onModalClose,
  initialStartDate,
  initialEndDate,
  initialAmplitude,
  selectedNode,
  scheduleId,
  editScheduleSourceTypeId,
  editScheduleIsAcuteStop,
  isLoading,
  onCreateSchedules,
  duration
}: ScheduleModalProps) => {
  const { tenantId } = useContext(TenantContext);
  const useRecommendedSettings = tenantId === MALMO_TENANT_ID;
  const defaultPresets = useRecommendedSettings
    ? EMPTY_PRESETS
    : DEFAULT_PRESETS;
  const presets = useMemo(
    () =>
      _.get(selectedNode, 'buildingInfo.buildingStatus') ===
        BuildingStatus.Testing && !isEditing
        ? [...defaultPresets, ...testPresetSchedules]
        : defaultPresets,
    [selectedNode, isEditing, defaultPresets]
  );
  const [confirmDialogIsOpen, showConfirmDialog, hideConfirmDialog] =
    useSimpleDialogState();
  const [confirmText, setConfirmText] = useState('');
  const signalProvidersReqState = useOperatorSelector(
    (state) => state.schedule.signals
  );
  const hasStopSignals =
    _.find(
      signalProvidersReqState.payload ?? [],
      (provider) =>
        provider.signalProviderType ===
        SignalProviderType.PowerControlStopSignal
    ) != null;

  const hasCooling = useMemo(() => {
    const signalProviders = signalProvidersReqState.payload ?? [];
    return signalProviders.some(
      (provider) => provider.signalProviderName === 'Power Control Cooling'
    );
  }, [signalProvidersReqState.payload]);

  const options = useMemo(() => {
    let ret: GenericSelectOption<string>[] = [
      {
        label: T.powercontrol.dispatch.scheduletype.manual,
        value: ScheduleSourceTypeIds.MANUAL
      }
    ];

    if (hasStopSignals) {
      ret = ret.concat([
        {
          label: T.powercontrol.dispatch.scheduletype.stop,
          value: ScheduleSourceTypeIds.STOP
        }
      ]);
    }

    return ret;
  }, [hasStopSignals]);

  const [scheduleSourceTypeId, setScheduleSourceTypeId] = useState<string>(
    ScheduleSourceTypeIds.MANUAL
  );
  const [stopScheduleAcute, setStopScheduleAcute] = useState(false);
  const toggleStopScheduleAcute = useCallback(
    () => setStopScheduleAcute((oldValue) => !oldValue),
    []
  );
  const onChangeScheduleSourceTypeId = useCallback(
    (option: GenericSelectOption<string>) =>
      setScheduleSourceTypeId(option.value),
    []
  );
  const selectedOption = _.find(options, { value: scheduleSourceTypeId });

  const isLoadingProviders =
    signalProvidersReqState.state === REQ_STATE_PENDING;
  const [settings, setSettings] = useState({
    ...INITIAL_STATE,
    now: new Date()
  });
  const isPowerControlTest = useMemo(
    () =>
      _.get(presets, [settings.selectedPreset, 'isPowerControlTest'], false),
    [presets, settings.selectedPreset]
  );
  const startDate = settings.startDate
    ? moment(settings.startDate)
    : moment(settings.now);
  const testScheduleStart =
    isPowerControlTest && settings.selectedPreset !== -1
      ? firstStartDate(presets[settings.selectedPreset].schedules, startDate)
      : null;
  const currentScheduleStart = testScheduleStart
    ? testScheduleStart
    : settings.startDate;
  const startOfToday = startOfDay(settings.now);

  const updateSettings = useCallback(
    (newState: Partial<ScheduleModalSettings>) =>
      setSettings((prevState) => ({ ...prevState, ...newState })),
    []
  );

  useEffect(() => {
    if (isOpen) {
      const now = new Date();
      const newStartDate = initialStartDate
        ? moment.utc(initialStartDate).toDate()
        : now;
      const newEndDate = initialEndDate
        ? moment.utc(initialEndDate).toDate()
        : now;
      const newDuration = roundHalf(
        (newEndDate.valueOf() - newStartDate.valueOf()) / HOUR_MILLIS
      );
      const _newDuration = newDuration === 0 ? 1 : newDuration;

      const newState: ScheduleModalSettings = {
        now: now,
        startDate: newStartDate,
        endDate: newEndDate,
        duration: isEditing ? duration : _newDuration,
        selectedPreset: -1,
        amplitude: initialAmplitude,
        timeType: initialStartDate ? TIME_TYPE_CALENDAR : TIME_TYPE_OFFSET,
        startOffset: 0
      };

      updateSettings(newState);
      setStopScheduleAcute(editScheduleIsAcuteStop);
      setScheduleSourceTypeId(
        editScheduleSourceTypeId ?? ScheduleSourceTypeIds.MANUAL
      );
    }
  }, [
    isOpen,
    updateSettings,
    initialStartDate,
    initialEndDate,
    initialAmplitude,
    isEditing,
    duration,
    editScheduleSourceTypeId,
    editScheduleIsAcuteStop
  ]);

  const resetState = useCallback(() => {
    onModalClose();

    setScheduleSourceTypeId(ScheduleSourceTypeIds.MANUAL);
    setStopScheduleAcute(false);
    updateSettings({ ...INITIAL_STATE, now: new Date() });
  }, [onModalClose, updateSettings]);

  const addScheduleAction = useCallback(() => {
    hideConfirmDialog();

    const _now = new Date();
    const { utcStartDate, utcEndDate } = getUTCDates(
      _now,
      settings.timeType,
      settings.startDate,
      settings.startOffset,
      settings.duration
    );

    if (scheduleSourceTypeId === ScheduleSourceTypeIds.STOP) {
      onCreateSchedules([
        {
          id: undefined,
          signalProviderId: undefined,
          startDate: utcStartDate.toISOString(),
          endDate: utcEndDate.toISOString(),
          desiredAcuteEmergency: stopScheduleAcute,
          desiredEmergency: true,
          sourceTypeId: ScheduleSourceTypeIds.STOP,
          globalAmplitude: -100
        }
      ]);
      return;
    } else if (
      settings.selectedPreset !== -1 &&
      presets[settings.selectedPreset].isPowerControlTest
    ) {
      const testScheduleStartDate = utcStartDate
        .clone()
        .tz(DEFAULT_TIMEZONE)
        .startOf('day');
      const schedules = createSchedules(
        presets[settings.selectedPreset].schedules,
        testScheduleStartDate
      );
      onCreateSchedules(schedules);
      return;
    }

    if (
      !settings.startDateIsValid ||
      moment(utcStartDate) < moment(_now) ||
      utcStartDate >= utcEndDate
    ) {
      toastStore.addErrorToast(T.powercontrol.dispatch.scheduled.dateerror);
      return;
    }

    onCreateSchedules([
      {
        id: undefined,
        signalProviderId: undefined,
        globalAmplitude: settings.amplitude,
        startDate: utcStartDate.toISOString(),
        endDate: utcEndDate.toISOString(),
        desiredEmergency: false,
        desiredAcuteEmergency: false,
        sourceTypeId: ScheduleSourceTypeIds.MANUAL
      }
    ]);
  }, [
    hideConfirmDialog,
    onCreateSchedules,
    presets,
    settings.amplitude,
    settings.duration,
    settings.selectedPreset,
    settings.startDate,
    settings.startDateIsValid,
    settings.startOffset,
    settings.timeType,
    stopScheduleAcute,
    scheduleSourceTypeId
  ]);

  const onUseRecommendedSettings = useCallback(
    (endOffsetHours: number, amplitude: number) => {
      const _now = new Date();
      updateSettings({
        amplitude: amplitude ?? settings.amplitude,
        timeType: TIME_TYPE_OFFSET,
        startDate: new Date(_now.getTime()),
        endDate: new Date(_now.getTime() + endOffsetHours * HOUR_MILLIS),
        startOffset: 0,
        duration: endOffsetHours,
        now: _now
      });
    },
    [settings.amplitude, updateSettings]
  );

  const onPresetChanged = useCallback(
    (preset: SchedulePresetType, index: number) => {
      const _now = new Date();
      updateSettings({
        amplitude: preset.amplitude ?? settings.amplitude,
        selectedPreset: index,
        timeType: preset.isPowerControlTest
          ? TIME_TYPE_CALENDAR
          : TIME_TYPE_OFFSET,
        startDate: new Date(_now.getTime() + preset.startOffset * HOUR_MILLIS),
        endDate: new Date(_now.getTime() + preset.endOffset * HOUR_MILLIS),
        startOffset: preset.startOffset,
        duration: preset.endOffset,
        now: _now
      });
    },
    [updateSettings, settings.amplitude]
  );

  // Validations
  // If selected day is today then we need to add current time so we can see if
  // schedule can be started, eg current time = 08:00 and schedule starts at 09:00 then it's ok to start today
  const realStartDate = startDate.isSame(startOfToday)
    ? settings.now
    : startDate;
  const startTimeError =
    moment(realStartDate).isAfter(currentScheduleStart) ||
    moment(realStartDate).isBefore(settings.now) ||
    !settings.startDateIsValid;
  const { utcStartDate, utcEndDate } = useMemo(
    () =>
      getUTCDates(
        settings.now,
        settings.timeType,
        settings.startDate,
        settings.startOffset,
        settings.duration
      ),
    [
      settings.now,
      settings.timeType,
      settings.startDate,
      settings.startOffset,
      settings.duration
    ]
  );
  const hasInvalidScheduleStart =
    settings.timeType === TIME_TYPE_CALENDAR && startTimeError;
  const resizeModal = presets.length > defaultPresets.length;

  const onTimeTypeChanged = useCallback(
    (type: string) =>
      updateSettings({
        selectedPreset: -1,
        timeType: type,
        startDateIsValid: true,
        now: new Date()
      }),
    [updateSettings]
  );

  const onDateFormatSetValid = useCallback(
    (newStartDateIsValid: boolean) => {
      const newSettings = {
        startDateIsValid: newStartDateIsValid,
        now: undefined as Date
      };

      if (newStartDateIsValid) {
        newSettings.now = new Date();
      }

      updateSettings(newSettings);
    },
    [updateSettings]
  );

  const onStartOffsetChange = useCallback(
    (value: number) =>
      updateSettings({
        selectedPreset: isPowerControlTest ? settings.selectedPreset : -1,
        startOffset: value,
        timeType: TIME_TYPE_OFFSET,
        now: new Date()
      }),
    [updateSettings, isPowerControlTest, settings.selectedPreset]
  );

  const onCalendarDateChanged = useCallback(
    (date: Moment) => {
      updateSettings({
        selectedPreset: isPowerControlTest ? settings.selectedPreset : -1,
        // Clamp to start of day for power control tests
        startDate: (isPowerControlTest
          ? date.clone().startOf('day')
          : date
        ).toDate(),
        timeType: TIME_TYPE_CALENDAR,
        now: new Date()
      });
    },
    [updateSettings, isPowerControlTest, settings.selectedPreset]
  );

  const durationControlOnChange = useCallback(
    (value: number) =>
      updateSettings({
        selectedPreset: -1,
        duration: value,
        now: new Date()
      }),
    [updateSettings]
  );

  const effectControlOnChange = useCallback(
    (value: number) =>
      updateSettings({
        selectedPreset: -1,
        amplitude: value,
        now: new Date()
      }),
    [updateSettings]
  );

  const actionModalTitle = isEditing
    ? T.powercontrol.schedule.editschedule.title
    : T.powercontrol.schedule.createschedule.title;
  const actionText = isEditing
    ? T.powercontrol.schedule.editschedule.action
    : T.common.add;

  const globalAmplitude = settings.amplitude || 0;
  const startNow = settings.timeType === TIME_TYPE_OFFSET;
  const OFFSET_NOW_TIME = 2000;
  const nodeId = selectedNode.nodeId;

  const previewEnabled =
    isOpen && scheduleSourceTypeId === ScheduleSourceTypeIds.MANUAL;

  const previewArgs = useMemo(() => {
    return [
      {
        powerControlScheduleId: scheduleId,
        nodeId,
        globalAmplitude,
        startDate: moment(
          moment(utcStartDate).valueOf() + (startNow ? OFFSET_NOW_TIME : 0)
        ).toISOString(),
        endDate: moment(
          moment(utcEndDate).valueOf() + (startNow ? OFFSET_NOW_TIME : 0)
        ).toISOString()
      }
    ];
  }, [globalAmplitude, nodeId, scheduleId, startNow, utcEndDate, utcStartDate]);

  const previewQuery =
    APIGen.TemperatureImpact.getTemperatureImpactPreview.useQuery(previewArgs, {
      enabled: previewEnabled
    });

  const schedulePreviewBuildings = _.get(
    previewQuery?.data?.[0],
    'buildingTemperatureImpacts'
  );
  const building = _.find(schedulePreviewBuildings, [
    'meteorologyDataMissing',
    false
  ]);
  const schedulePreview = _.get(building, 'temperatureImpactSignalValues', []);

  const _onConfirmClick = useCallback(() => {
    const maximumTemperatureImpact = _.get(
      schedulePreview,
      'buildings[0].maximumTemperatureImpact'
    );

    if (maximumTemperatureImpact > IMPACT_RANGE.max) {
      showConfirmDialog();
      setConfirmText(T.powercontrol.schedule.confirm.above);
    } else if (maximumTemperatureImpact < IMPACT_RANGE.min) {
      showConfirmDialog();
      setConfirmText(T.powercontrol.schedule.confirm.below);
    } else {
      addScheduleAction();
    }
  }, [showConfirmDialog, schedulePreview, addScheduleAction]);

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        isLoading={isLoading || isLoadingProviders}
        title={actionModalTitle}
        onConfirmClick={_onConfirmClick}
        actionText={actionText}
        headerIcon={isEditing ? EditIcon : AddIcon}
        large
        className={classNames(
          styles.modalResizer,
          resizeModal && styles.largerModal
        )}
        messageBodyClassName={styles.messageBody}
        onModalClose={resetState}
        disableActionButton={
          previewQuery.isLoading &&
          scheduleSourceTypeId === ScheduleSourceTypeIds.MANUAL
        }
        helpPath={HelpPaths.docs.operator.powercontrol_create}
      >
        {options.length > 1 && !isEditing && (
          <PlainBox className={styles.content}>
            <Heading level={5}>
              {T.powercontrol.dispatch.schedule.header.type}
            </Heading>
            <Select
              options={options}
              value={selectedOption}
              onChange={onChangeScheduleSourceTypeId}
            />
          </PlainBox>
        )}

        {scheduleSourceTypeId === ScheduleSourceTypeIds.MANUAL &&
          presets.length > 0 && (
            <PresetsContainer
              selected={settings.selectedPreset}
              presets={presets}
              onPresetChanged={onPresetChanged}
            />
          )}

        {useRecommendedSettings && (
          <RecommendedSettingsControl
            onUseRecommendedSettings={onUseRecommendedSettings}
          />
        )}

        <PlainBox
          className={classNames(
            styles.content,
            styles.mainContent,
            scheduleSourceTypeId === ScheduleSourceTypeIds.STOP &&
              styles.stopContent
          )}
        >
          <StartTimeControl
            onTimeTypeChanged={onTimeTypeChanged}
            onDateFormatSetValid={onDateFormatSetValid}
            onStartOffsetChange={onStartOffsetChange}
            onDateChanged={onCalendarDateChanged}
            timeType={settings.timeType}
            disableOffsetChange={isPowerControlTest}
            timeFormat={!isPowerControlTest}
            startTimeError={startTimeError}
            startOffset={settings.startOffset}
            startDate={utcStartDate}
          />
          {!isPowerControlTest && (
            <>
              <DurationControl
                duration={settings.duration}
                onChange={durationControlOnChange}
              />

              {scheduleSourceTypeId === ScheduleSourceTypeIds.MANUAL && (
                <EffectControl
                  amplitude={settings.amplitude}
                  onChange={effectControlOnChange}
                  reverseColors={hasCooling}
                />
              )}

              {scheduleSourceTypeId === ScheduleSourceTypeIds.MANUAL && (
                <TemperatureImpactPreview
                  schedulePreview={schedulePreview}
                  isLoading={previewQuery.isLoading}
                  hasError={previewQuery.error != null}
                  startDate={utcStartDate}
                  endDate={utcEndDate}
                />
              )}
            </>
          )}
          {scheduleSourceTypeId === ScheduleSourceTypeIds.STOP && (
            <>
              <Heading level={5}>
                {T.powercontrol.dispatch.schedule.stopschedulesettings}
              </Heading>

              <div className={styles.flexboxContainer}>
                <label>{T.powercontrol.dispatch.schedule.acutestop}</label>
                <Switch
                  isOn={stopScheduleAcute}
                  onClick={toggleStopScheduleAcute}
                />
              </div>
            </>
          )}
        </PlainBox>
        {currentScheduleStart &&
          scheduleSourceTypeId === ScheduleSourceTypeIds.MANUAL && (
            <PlainBox
              className={classNames(styles.content, styles.summaryContent)}
            >
              <ScheduleSummary
                schedulePreviewBuildings={schedulePreviewBuildings}
                isLoading={previewQuery.isLoading}
                startDate={utcStartDate}
                endDate={utcEndDate}
                hasInvalidScheduleStart={hasInvalidScheduleStart}
                isPowerControlTest={isPowerControlTest}
                currentScheduleStart={currentScheduleStart}
                amplitude={settings.amplitude}
              />
            </PlainBox>
          )}
      </ActionModal>

      <ActionModal
        isOpen={confirmDialogIsOpen}
        onConfirmClick={addScheduleAction}
        onModalClose={hideConfirmDialog}
        title={T.powercontrol.schedule.confirmtitle}
        actionText={T.common.yes}
        cancelText={T.common.no}
      >
        {confirmText}
      </ActionModal>
    </>
  );
};

export default ScheduleModal;
