import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import pageTreeStyles from 'ecto-common/lib/Page/PageTreeView.module.css';
import classNames from 'classnames';
import { GridType } from 'ecto-common/lib/API/APIGen';
import EctoplannerAPIGen, {
  BuildResponse,
  BuildResponseListResponse,
  ProjectResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { useParams } from 'react-router-dom';
import {
  EctoplannerParams,
  ectoplannerMasterBuildId,
  getEctoplannerUrl
} from 'js/utils/routeConstants';
import _ from 'lodash';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { defaultGetEctoplannerBuildArgs } from 'js/components/Ectoplanner/EctoplannerUtils';
import { useQueryClient } from '@tanstack/react-query';
import ToolbarMenuDropdownButton from 'ecto-common/lib/Toolbar/ToolbarMenuDropdownButton';
import { downloadBlobTextWithoutUTF8BOM } from 'ecto-common/lib/utils/downloadBlob';
import { DropdownButtonOptionType } from 'ecto-common/lib/DropdownButton/DropdownButton';
import {
  EctoplannerForm,
  SecosimForm
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import LocationTreeView from 'ecto-common/lib/LocationTreeView/LocationTreeView';
import { LocationTreeViewNodeWithChildren } from 'ecto-common/lib/LocationTreeView/LocationTreeViewRow';

const emptyBuilds: BuildResponse[] = [];

export const EctoplannerAddNewToolbarMenuButton = ({
  onClickAddNew
}: {
  onClickAddNew: () => void;
}) => {
  const addText = T.common.add + '...';

  return (
    <ToolbarMenuButton
      title={addText}
      onClick={onClickAddNew}
      icon={<Icons.Add />}
      tooltipText={addText}
    />
  );
};

export const EctoplannerNavigationTree = ({
  builds,
  allProjects,
  isLoadingBuildsForProject,
  onNavigateProject,
  onNavigateBuild,
  changedForms,
  projectId,
  buildId,
  expanded,
  setExpanded,
  embedded = false
}: {
  onNavigateProject: (projectId: string) => void;
  onNavigateBuild: (projectId: string, buildId: string) => void;
  builds: BuildResponse[];
  allProjects: ProjectResponse[];
  isLoadingBuildsForProject: boolean;
  changedForms: Record<string, boolean>;
  projectId: string;
  buildId: string;
  embedded?: boolean;
  expanded: Record<string, boolean>;
  setExpanded: Dispatch<SetStateAction<Record<string, boolean>>>;
}) => {
  const nodeList = useMemo(() => {
    const validProjectIds = allProjects.map((project) => project.id);
    const filteredBuilds = builds.filter((x) =>
      validProjectIds.includes(x.projectId)
    );

    return [
      ...sortByLocaleCompare(allProjects, 'name').map((project) => ({
        name: project.name,
        nodeId: project.id,
        grids: [GridType.Heating],
        parentId: null as string
      })),
      ..._.filter(filteredBuilds, (build) => !build.isMasterBuild).map(
        (build) => ({
          name: build.description,
          nodeId: build.id,
          parentId: build.projectId
        })
      )
    ];
  }, [allProjects, builds]);

  const onNodeChanged = useCallback(
    (nodeId: string) => {
      const newProject = allProjects.find((project) => project.id === nodeId);
      const newBuild = builds.find((build) => build.id === nodeId);

      if (newProject != null) {
        onNavigateProject(newProject.id);
      } else if (newBuild != null) {
        onNavigateBuild(newBuild.projectId, newBuild.id);
      }
    },
    [allProjects, builds, onNavigateBuild, onNavigateProject]
  );
  const currentBuildId =
    buildId != null && buildId !== ectoplannerMasterBuildId
      ? buildId
      : projectId;

  const selectedIds = useMemo(() => {
    return [currentBuildId];
  }, [currentBuildId]);

  const currentBuildMissingAmongBuildsWhileLoading =
    isLoadingBuildsForProject &&
    buildId != null &&
    buildId !== ectoplannerMasterBuildId &&
    !builds.some((build) => build.id === buildId);

  const renderRowIcons = useCallback(
    (node: LocationTreeViewNodeWithChildren) => {
      const isLoading = isLoadingBuildsForProject && node.nodeId === projectId;
      const formHasChanges = changedForms[node.nodeId];

      return (
        (isLoading || formHasChanges) && (
          <>
            <div style={{ width: 1, flexGrow: 1 }} />
            {formHasChanges && <span>*</span>}
            {isLoading && (
              <Spinner size={SpinnerSize.TREE_VIEW} color={'white'} />
            )}
          </>
        )
      );
    },
    [changedForms, isLoadingBuildsForProject, projectId]
  );

  return (
    <div
      className={classNames(
        !embedded && pageTreeStyles.locationTree,
        pageTreeStyles.open
      )}
    >
      {nodeList.length > 0 &&
        allProjects.length > 0 &&
        !currentBuildMissingAmongBuildsWhileLoading && (
          <LocationTreeView
            className={classNames(pageTreeStyles.locationTreeView)}
            onChangeSelectedState={onNodeChanged}
            nodeList={nodeList}
            selectedIds={selectedIds}
            focusedId={currentBuildId}
            renderRowIcons={renderRowIcons}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        )}
    </div>
  );
};

export function useEctoplannerToolbarMenuOptions({
  onClickAddNew,
  saveButton,
  onClickShare,
  onClickDelete,
  onClickEditName,
  onClickExport,
  selectedBuild,
  currentWeatherStationId,
  airTemp,
  cityData,
  form,
  isLoading,
  calculationRequiresCityData = false
}: {
  onClickAddNew: () => void;
  saveButton: React.ReactNode;
  onClickShare: () => void;
  onClickDelete: () => void;
  onClickEditName: () => void;
  onClickExport: () => void;
  selectedBuild: BuildResponse;
  currentWeatherStationId: string;
  airTemp: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cityData: any;
  form: EctoplannerForm | SecosimForm;
  isLoading: boolean;
  calculationRequiresCityData?: boolean;
}) {
  const { tenantId } = useContext(TenantContext);
  const params = useParams<EctoplannerParams>();

  const exportOptions: DropdownButtonOptionType[] = useMemo(() => {
    return _.compact([
      {
        label: T.ectoplanner.exportproject,
        icon: <Icons.File />,
        action: onClickExport
      },
      currentWeatherStationId != null && {
        label: T.ectoplanner.exportairtemp,
        icon: <Icons.Temperature />,
        action: () => {
          _.defer(() => {
            downloadBlobTextWithoutUTF8BOM(
              airTemp,
              'airTemp_' + currentWeatherStationId + '.txt'
            );
          });
        },
        isEnabled: airTemp != null
      },
      currentWeatherStationId != null && {
        label: T.ectoplanner.exportcitydata,
        icon: <Icons.Building />,
        action: () => {
          _.defer(() => {
            downloadBlobTextWithoutUTF8BOM(
              JSON.stringify(cityData, null, 2),
              'cityData_' + currentWeatherStationId + '.json'
            );
          });
        },
        isEnabled: cityData != null
      },
      {
        label: T.ectoplanner.exportformdata,
        icon: <Icons.File />,
        action: () => {
          _.defer(() => {
            downloadBlobTextWithoutUTF8BOM(
              JSON.stringify(form, null, 2),
              'formdata.json'
            );
          });
        },
        isEnabled: !calculationRequiresCityData || cityData != null
      }
    ]);
  }, [
    airTemp,
    calculationRequiresCityData,
    cityData,
    currentWeatherStationId,
    form,
    onClickExport
  ]);

  return (
    <>
      <EctoplannerAddNewToolbarMenuButton onClickAddNew={onClickAddNew} />
      <ToolbarMenuDivider />
      {saveButton}
      <ToolbarMenuButton
        icon={<Icons.Edit />}
        disabled={params.projectId == null}
        onClick={onClickEditName}
        tooltipText={T.ectoplanner.calculations.editdescription}
      />
      <ToolbarMenuButton
        icon={<Icons.Share />}
        disabled={params.projectId == null}
        onClick={onClickShare}
        tooltipText={T.common.share}
      />
      <ToolbarMenuButton
        onClick={() => {
          const urlToClipboard =
            window.location.origin +
            getEctoplannerUrl(
              tenantId,
              params.projectType,
              params.projectId,
              params.buildId,
              'calculations'
            );
          navigator.clipboard.writeText(urlToClipboard);
          toastStore.addSuccessToast(T.common.copytoclipboard.success);
        }}
        icon={<Icons.Email />}
        tooltipText={T.ectoplanner.calculations.copylinktoclipboard}
        disabled={params.projectId == null}
      />

      <ToolbarMenuDivider />
      <ToolbarMenuButton
        onClick={onClickDelete}
        icon={<Icons.Delete />}
        tooltipText={T.common.delete}
        disabled={selectedBuild == null && params.projectId == null}
      />
      <ToolbarMenuDivider />
      <ToolbarMenuDropdownButton
        disabled={isLoading || params.projectId == null}
        options={exportOptions}
        tooltipText={T.ectoplanner.export}
        disableWrap
      >
        <Icons.Download />
      </ToolbarMenuDropdownButton>
    </>
  );
}

export const useEctoplannerPersistentBuilds = ({
  projectId
}: {
  projectId: string;
}) => {
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();
  const [buildStatuses, setBuildStatuses] = useState<
    Record<string, [string, string]>
  >({});
  const buildsQuery = EctoplannerAPIGen.EctoGridProjects.buildsDetail.useQuery(
    {
      projectId: projectId
    },
    defaultGetEctoplannerBuildArgs,
    {
      refetchOnWindowFocus: false,
      enabled: projectId != null
    }
  );

  const [visitedProjectIds, setVisitedProjectIds] = useState<string[]>(
    projectId ? [projectId] : []
  );

  if (projectId && !visitedProjectIds.includes(projectId)) {
    setVisitedProjectIds((oldVisitedProjectIds) => [
      ...oldVisitedProjectIds,
      projectId
    ]);
  }

  const builds: BuildResponse[] = useMemo(() => {
    const allBuilds = [...(buildsQuery.data?.items ?? emptyBuilds)];

    for (const visitedProjectId of visitedProjectIds) {
      if (visitedProjectId !== projectId) {
        const queryData = queryClient.getQueryData<BuildResponseListResponse>([
          ...EctoplannerAPIGen.EctoGridProjects.buildsDetail.path(
            contextSettings,
            { projectId: visitedProjectId }
          ),
          defaultGetEctoplannerBuildArgs
        ]);
        if (queryData != null) {
          allBuilds.push(...queryData.items);
        }
      }
    }

    const orderedBuilds = _.orderBy(allBuilds, 'created', 'desc');

    return orderedBuilds.map((build) => {
      const status = buildStatuses[build.id];
      if (status != null) {
        const [statusValue, lastUpdated] = status;
        return {
          ...build,
          status: statusValue,
          lastUpdated:
            lastUpdated > build.lastUpdated ? lastUpdated : build.lastUpdated
        };
      }
      return build;
    });
  }, [
    buildsQuery.data?.items,
    visitedProjectIds,
    projectId,
    queryClient,
    contextSettings,
    buildStatuses
  ]);

  return [
    builds,
    buildsQuery.isLoading,
    buildsQuery.error,
    setBuildStatuses
  ] as const;
};

export const EctoplannerConfirmSaveBeforeNewDialog = ({
  isOpen,
  isLoading,
  onSave,
  onHideConfirmSave,
  onClickAddNew
}: {
  onSave: () => void;
  onHideConfirmSave: () => void;
  onClickAddNew: () => void;
  isOpen: boolean;
  isLoading: boolean;
}) => {
  const continueWithoutSave = useCallback(() => {
    onHideConfirmSave();
    onClickAddNew();
  }, [onClickAddNew, onHideConfirmSave]);

  return (
    <ActionModal
      isLoading={isLoading}
      isOpen={isOpen}
      title={T.common.unsavedchanges}
      onModalClose={continueWithoutSave}
      onConfirmClick={onSave}
      headerIcon={Icons.Question}
      actionText={
        <>
          <Icons.Save />
          {T.common.save}
        </>
      }
      cancelText={T.common.no}
    >
      {T.common.unsavedchangesmessage}
    </ActionModal>
  );
};
