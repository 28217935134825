import React, { useContext, useMemo } from 'react';
import {
  createTelemetryFromSignalValues,
  getSignalAggregationModels,
  SignalValuesDataSourceResult
} from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import _ from 'lodash';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { createScatterChartConfigFromSeries } from 'ecto-common/lib/SignalSelector/ChartUtils';
import PanelDataType from 'ecto-common/lib/Dashboard/panels/PanelDataType';
import ScatterChart from 'ecto-common/lib/Charts/ScatterChart';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import T from 'ecto-common/lib/lang/Language';
import { migrateSignalSettingSystemNamesToSignalTypes } from 'ecto-common/lib/Dashboard/migrations/datasourceUtil';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import SignalSettingName from 'ecto-common/lib/SignalSettingName/SignalSettingName';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  CustomPanelProps,
  PanelSizeType
} from 'ecto-common/lib/Dashboard/Panel';
import {
  GetEnumsAndFixedConfigurationsResponseModel,
  SignalTypeResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { GraphMinMaxSettings } from 'ecto-common/lib/types/EctoCommonTypes';
import { graphMinMaxSection } from 'ecto-common/lib/Dashboard/util/GraphMinMaxModelEditor';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { EditDashboardPanelEnvironment } from 'ecto-common/lib/Dashboard/panels';

const MIN_REFRESH_INTERVAL = 15;
const DEFAULT_REFRESH_INTERVAL = MIN_REFRESH_INTERVAL;

type ScatterChartPanelContentProps = SignalValuesDataSourceResult & {
  size: PanelSizeType;
  onReload?(): void;
  refreshInterval?: number;
  xAxisSignalSettingId?: string;
  minMaxSettings?: Record<string, GraphMinMaxSettings>;
};

const ScatterChartPanelContent = ({
  signalValues,
  signalInfo,
  isLoading,
  hasError,
  onReload,
  hasPointsOverflow,
  xAxisSignalSettingId,
  size,
  minMaxSettings,
  nodes,
  equipmentChildren,
  refreshInterval = DEFAULT_REFRESH_INTERVAL
}: ScatterChartPanelContentProps) => {
  const { signalTypesMap, signalUnitTypesMap } =
    useContext(DashboardDataContext);

  const telemetry = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return createTelemetryFromSignalValues(
      signalInfo,
      signalValues,
      signalTypesMap,
      signalUnitTypesMap,
      nodes.concat(equipmentChildren),
      nodes
    );
  }, [
    isLoading,
    signalInfo,
    signalValues,
    signalTypesMap,
    signalUnitTypesMap,
    nodes,
    equipmentChildren
  ]);

  const [config, seriesIds] = useMemo(() => {
    // To make sure we get deterministic behavior in case of multiple matching x axis signals
    const xAxisSignals = _(signalInfo.matchingSignals)
      .filter((info) => info.signalInfo.id === xAxisSignalSettingId)
      .orderBy(['signal.signalId'])
      .value();

    const xAxisSignal = _.head(xAxisSignals);

    // These are the other signals that also match the X axis signal filters. They should not be used
    // since there can only be one X axis signal.
    const filteredXAxisSignalIds: string[] = _(xAxisSignals)
      .drop()
      .map('signal.signalId')
      .value();

    const filteredTelemetry = _.reject(telemetry, (signal) =>
      filteredXAxisSignalIds.includes(signal.signal.signalId)
    );

    const xAxisSeries = _.find(filteredTelemetry, [
      'signal.signalId',
      xAxisSignal?.signal?.signalId
    ]);

    return [
      {
        ...createScatterChartConfigFromSeries(
          filteredTelemetry,
          null,
          xAxisSeries,
          minMaxSettings
        )
      },
      _.without(filteredTelemetry, xAxisSeries).map(
        (telemetryItem) => telemetryItem.chartSignalId
      )
    ];
  }, [
    signalInfo.matchingSignals,
    telemetry,
    minMaxSettings,
    xAxisSignalSettingId
  ]);

  // Reload data, smallest interval 15 minutes
  useInterval(onReload, 1000 * 60 * Math.max(refreshInterval, 15));

  return (
    <ScatterChart
      seriesIds={seriesIds}
      isLoading={isLoading}
      config={config}
      hasPointsOverflow={hasPointsOverflow}
      hasError={hasError}
      containerWidth={size.width}
    />
  );
};

type ScatterChartPanelConfig = {
  refreshInterval: number;
  xAxisSignalSettingId: string;
  minMaxSettings?: Record<string, GraphMinMaxSettings>;
};

type ScatterChartPanelProps = CustomPanelProps & {
  data: {
    values: SignalValuesDataSourceResult;
  } & ScatterChartPanelConfig;
};

const ScatterChartPanel = (props: ScatterChartPanelProps) => {
  const { values, ...other } = props.data;
  return (
    <ScatterChartPanelContent
      {...values}
      {...other}
      onReload={props.panelApi.reload}
      size={props.panelApi.size}
    />
  );
};

function evalModel<
  ObjectType extends object,
  EnvironmentType extends object,
  ValueType
>(
  model: ModelDefinition<ObjectType, EnvironmentType, ValueType>
): ModelDefinition<ObjectType, EnvironmentType, unknown> {
  return model;
}

const sections: ModelFormSectionType<
  ScatterChartPanelConfig,
  EditDashboardPanelEnvironment
>[] = [
  {
    label: T.admin.dashboards.panels.scatterchart,
    initiallyCollapsed: false,
    lines: [
      {
        models: [
          {
            key: (input) => input.refreshInterval,
            modelType: ModelType.NUMBER,
            label: T.admin.dashboards.panels.types.linechart.refreshinterval,
            placeholder: _.toString(DEFAULT_REFRESH_INTERVAL),
            hasError: (value) => value < MIN_REFRESH_INTERVAL
          },
          evalModel({
            key: (input) => input.xAxisSignalSettingId,
            modelType: ModelType.OPTIONS,
            label: T.admin.dashboards.panels.types.scatterchart.xaxissignalname,
            options: (_value, _input, environment): GenericSelectOption[] => {
              return _.map(
                environment.allSignalInputs as SignalInputType[],
                (signal) => ({
                  label: <SignalSettingName signal={signal} />,
                  value: signal.id
                })
              );
            },
            hasError: _.isNil
          })
        ]
      }
    ]
  },
  graphMinMaxSection
];

export const ScatterChartPanelData = {
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS]: {
      optionalSignalModels: getSignalAggregationModels(true)
    }
  },
  emptyTargets: {
    values: {
      sourceType: DataSourceTypes.SIGNALS
    }
  },
  sections,
  migrations: [
    {
      version: 2,
      migration: (
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        panel: any,
        enums: GetEnumsAndFixedConfigurationsResponseModel,
        signalTypesMap: Record<string, SignalTypeResponseModel>
      ) => {
        const { xAxisSignalSystemName } = panel.targets;

        const signalIndex = _.findIndex(panel.targets.values.signals, [
          'systemName',
          xAxisSignalSystemName
        ]);
        panel.targets.values = migrateSignalSettingSystemNamesToSignalTypes(
          panel.targets.values,
          enums,
          signalTypesMap
        );

        if (signalIndex !== -1) {
          panel.targets.xAxisSignalSettingId =
            panel.targets.values.signals[signalIndex].id;
        }

        delete panel.xAxisSignalSystemName;
      }
    }
  ],

  dataType: PanelDataType.CHART,
  helpPath: HelpPaths.docs.dashboard.dashboards.scatter_chart
};

export default React.memo(ScatterChartPanel);
