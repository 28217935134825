import { SignalTypeResponseModel } from 'ecto-common/lib/API/APIGen';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
// Don't attempt to render larger datasets than this - performance will be too poor.
export const MAX_VALUES_FOR_GRAPH_CONTENT = 16000;

export const NodeTypes = {
  SITE: 'Site',
  BUILDING: 'Building',
  isEqual: (a: string, b: string) => {
    return a.toLowerCase() === b.toLowerCase();
  }
} as const;

export const AlarmSignalGroupTemplateIds = Object.freeze({
  ENERGY_MANAGER_ALARMS: '44118be5-5e1f-4745-b9a1-2cb0c6b92395'
});

const NoneUnitId = '56ee9bce-ed29-4b34-a739-7b39479a669a';

export const EmptySignalType: SignalTypeResponseModel = {
  name: '',
  id: null,
  unitId: NoneUnitId,
  isSystem: false
};

export const ComfortSignalTypeIds: Record<string, string> = {
  ENABLE_ALARM_COMFORT_FLOW_TEMP_HIGH: 'b9063541-d48c-b143-4069-807b0fef4088',
  ENABLE_ALARM_COMFORT_FLOW_TEMP_LOW: '7a5ac01a-815b-8a18-c680-cc34365f1e1f',
  ENABLE_ALARM_COMFORT_HEATING_HUMIDITY_HIGH:
    '97a3637d-516b-1ad2-eab3-1c48a42cdd83',
  ENABLE_ALARM_COMFORT_HEATING_TEMP_HIGH:
    'a05f563d-d856-0040-c97b-a28ec19d79e0',
  ENABLE_ALARM_COMFORT_ROOM_TEMP_HIGH: '36c9c72d-1aea-8eb6-e531-c1e6b8d7c8b7',
  ENABLE_ALARM_COMFORT_ROOM_TEMP_LOW: 'c596e85e-5e61-8751-f941-c2ee467a619b',
  ENABLE_ALARM_COMFORT_ROOM_TEMP_SENSOR: 'eb0f4b3c-2a0d-5835-7b69-170b587093e1',
  ENABLE_ALARM_COMFORT_WATER_TEMP_HIGH: 'c0034363-71fd-e9de-aea4-de336363e750',
  ENABLE_ALARM_COMFORT_WATER_TEMP_LOW: 'f8e723f7-64bd-bd13-3138-fc83839ffea6',
  ENABLE_COMFORT_ALARMS: '6fc82cd4-badf-5985-eded-fbafdae62dbd',
  ENABLE_COMFORT_ALGORITHMS: '13891aa0-b3db-e74e-f5f6-33b37c7de7f7',
  ENABLE_COMFORT_MAINTENANCE: 'd4e7b960-d587-3912-ec7d-548a446d56ea',
  ENABLE_COMFORT_SERVICE: '5089f0fe-1c1a-afc5-9e98-9e9d592c7aff',
  ENABLE_GUARD: 'ef1eb34b-d7b7-5cd8-e693-1ba32a02cc05',
  ENABLE_HIGH_PASS: '5aeb82aa-305a-4e0b-0bdc-104e0b065ffc',
  ENABLE_OPT: '1abe30cb-7af4-dd8f-d549-816c127c76ce',
  ENABLE_OUT_TEMP_IGS: 'e59ee85e-9d3f-ba82-0817-5de85085b7c8',
  ENABLE_TIME_OF_DAY_WEEKDAY_IGS: 'fa9cdf19-57c7-45bd-c654-dd0b34cca418',
  ENABLE_TIME_OF_DAY_WEEKEND_IGS: 'a02129ab-0850-22b0-34c7-a07371ee079d',
  ENABLE_WIND_FACTOR_IGS: 'ef8266a7-4815-437e-f9b7-10fdaa9b8f06'
};

export const isConstantSignal = (signal: SignalInputType) =>
  signal.signalTypeId === SignalTypeIds.CONSTANT_SIGNAL;

export const SignalTypeIds = {
  CONSTANT_SIGNAL: '_constant',
  OUTDOOR_SENSOR_TEMPERATURE_SIGNAL_TYPE_ID:
    '821a5e38-c743-3619-e505-7b6db9eaa110',
  AUTOMATIC_SCHEDULES_ENABLED_SIGNAL_TYPE_ID:
    '499873ae-86b5-d7dc-086d-95b52bd72e60',
  AIR_TEMPERATURE_SIGNAL_TYPE_ID: '4d8ab9e3-1aa3-7d12-4bde-6e43033f66ba',
  STEERABLE_POWER_SIGNAL_TYPE_ID: '966e4647-09eb-a5d8-687f-777c53bc9ebd',
  POWER_CONTROL_AMPLITUDE_POWER_CONTROL_MUX:
    '7442eec1-3615-a2dd-8b66-4273ff8c1b72',
  POWER_CONTROL_LIMIT_POWER_CONTROL_MUX: '01c0a081-a1b1-ffb9-bab7-a58e0ff02f1b',
  FLOW_TEMP_SIGNAL_TYPE_ID: '6a26603f-d919-65a4-c82f-1b78244514c7'
} as const;

export const editablePowerControlSignalTypes: string[] = [
  SignalTypeIds.POWER_CONTROL_AMPLITUDE_POWER_CONTROL_MUX,
  SignalTypeIds.POWER_CONTROL_LIMIT_POWER_CONTROL_MUX
];

export const EXISTING_PASSWORD_PLACEHOLDER = '••••••••••••••';

export const DEFAULT_TABLE_PAGE_SIZE_MODAL = 10;
export const DEFAULT_TABLE_PAGE_SIZE_PAGE = 15;

export const DEFAULT_SEARCH_DEBOUNCE_TIME_MS = 250;

// Once the backend implements specific databases for each tenant, we can remove
// this. Right now get nodes will return the same results regardless of the tenant,
// and if we have multiple tenants we will use up all the space in the local storage
// cache. So we use this tenant to determine if the result should be cached or not.
export const DEFAULT_TENANT_ID = 'default';

export const NodeTraitIds = {
  BUILDING: '7e2b4d52-22ba-4532-8949-9834efc69a28',
  EQUIPMENT: '7192a734-a8c3-4fb6-8183-fcff724b5812',
  SITE: 'a7a65a4f-df07-45f3-ad61-0a2dbc339465',
  DISTRICT_HEATING_BUILDING: '7deb616f-2311-443d-b99e-af6ecd954c70',
  FLOOR: '4493d2ea-350c-477e-920a-7316e36adce2',
  APARTMENT: '6ac854b7-0b04-466b-9e71-cfe753b79476',
  ENERGY_MANAGER: '1d6078f6-7c13-4bb9-aa48-d9482161929a',
  ECTOGRID_HEATING_SUBSYSTEM: '87097ba9-1c04-42cd-ad90-d0742c2f3b29',
  ECTOGRID_COOLING_SUBSYSTEM: 'a662e171-24e9-4c86-8ffa-7086b2d3b41f',
  ECTOGRID_ACTIVE_REVERSIBLE_HEAT_PUMP: '7542163b-3a50-4686-9d56-755940265c23', // ectogrid™ Active Balancing Reversible Heat Pump
  ECTOGRID_ACTIVE_DISTRICT_HEATING: 'eaad695a-e50c-4c97-949d-73d876d68bee', // ectogrid™ Active Balancing District Heating
  ECTOGRID_ACTIVE_COOLING_TOWER: 'cc15f8e9-cd5a-4751-b349-b8002bd36516', // ectogrid™ Active Balancing Cooling Tower,
  ECTOGRID_PASSIVE_BALANCING_UNIT: '201a1aa7-d914-4247-ac28-02faa4357f65' // ectogrid™ Passive Balancing Unit
} as const;
