import _ from 'lodash';
import { NavLink } from 'react-router-dom';

import React from 'react';
import { getNodePage } from 'ecto-common/lib/utils/commonLinkUtil';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useNodes } from 'ecto-common/lib/hooks/useCurrentNode';

/**
 * Returns a string value to be shown in the UI for the model.
 */
export function modelFormattedValue<
  ObjectType extends object,
  EnvironmentType extends object
>(
  model: ModelDefinition<ObjectType, EnvironmentType>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawValue: any,
  input: ObjectType
) {
  if (
    (model.modelType === ModelType.LABEL ||
      model.modelType === ModelType.DISABLED_TEXT) &&
    model.dataFormatter != null
  ) {
    return model.dataFormatter(rawValue, input);
  }

  return rawValue;
}

// Get the 'raw' data no transform or value change
export function modelRawDataValue<
  ObjectType extends object,
  EnvironmentType extends object
>(model: ModelDefinition<ObjectType, EnvironmentType>, input: ObjectType) {
  return _.get(input, getPathFromModelKeyFunc(model.key));
}

export const ModelFormattedNodeLinks = ({
  model,
  nodeIds,
  tenantId
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any;
  nodeIds: string[];
  tenantId: string;
}) => {
  const { nodes } = useNodes(nodeIds);

  const formatter = model.dataFormatter ?? _.identity;

  return nodes.map((node, idx) => (
    <NavLink to={getNodePage(tenantId, node)} key={node.nodeId + '-' + idx}>
      {formatter(node.name)}
      {idx < nodeIds.length - 1 && <span>,&nbsp;</span>}
    </NavLink>
  ));
};
