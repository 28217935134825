import { createContext } from 'react';
import { UserModel } from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { AuthenticationResult } from '@azure/msal-browser';

type UserContextProps = {
  signedIn: boolean;
  username: string;
  userId: string;
  signIn: (result: AuthenticationResult) => void;
  userData: UserModel;
  setUserData: (_newUserData: UserModel) => void;
};

const UserContext = createContext<UserContextProps>({
  signedIn: false,
  username: null,
  userId: null,
  signIn: null,
  userData: null,
  setUserData: (_newUserData: UserModel) => {}
});

export default UserContext;
