import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import React from 'react';
import ImageGas from './assets/GasGrid.svg';
import ImageGridExport from './assets/GridExport.svg';
import ImageGridImport from './assets/GridImport.svg';
import ImageDistrict_heating_2 from './assets/DistrictHeating.svg';
import ImageDistrict_cooling_2 from './assets/DistrictCooling.svg';
import ImageWasteHeat from './assets/WasteHeat.svg';
import ImageAmbient_heat from './assets/HeatingDemand.svg';
import ImageAmbient_cold from './assets/CoolingDemand.svg';
import ImageHeat_pump_reversible from './assets/AirSourceHeatpump.svg';
import ImageGeothermal from './assets/Geothermal.svg';
import ImageHeat_pump_heat_only from './assets/HP.svg';
import ImageAquifer_storage from './assets/Aquifer.svg';
import ImageSTC from './assets/SolarSTC.svg';
import ImagePV from './assets/SolarPV.svg';

import ImageEH from './assets/ElectricHeater.svg';
import ImageCC from './assets/CompressionChiller.svg';
import ImageAC from './assets/AbsorptionChillerIcon.svg';
import ImageCHP_ICE from './assets/CHP.svg';
import ImageBOI from './assets/GasBoiler.svg';
import ImagePassiveBalancingUnit from './assets/PassiveBalancingUnit.svg';
import ImageBAT from './assets/Battery.svg';
import ImageHeatStorage from './assets/HeatStorage.svg';
import ImageColdStorage from './assets/ColdStorage.svg';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import T from 'ecto-common/lib/lang/Language';
import styles from 'js/components/Ectoplanner/EctoplannerResultGraphs.module.css';
import _ from 'lodash';
import EctoplannerBox from 'js/components/Ectoplanner/EctoplannerBox';
import { EctoplannerResultType } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';

const renderValue = (
  value: number,
  unit: React.ReactNode = null,
  numberOfDecimals = 1
) => {
  if (value == null) {
    return '';
  }

  return (
    <>
      {' '}
      {formatNumberUnit(value, null, numberOfDecimals)} {unit}{' '}
    </>
  );
};

type EctoplannerResultComponentProps = {
  results: EctoplannerResultType;
};

export const endEnergyDemandColumns: DataTableColumnProps<unknown>[] = [
  {
    dataKey: '[0]',
    label: '',
    maxWidth: 40,
    minWidth: 40,
    align: HorizontalAlignments.CENTER
  },
  {
    dataKey: '[1]',
    flexGrow: 3,
    label: T.ectoplanner.form.shared.endenergy
  },
  {
    dataKey: '[2]',
    flexGrow: 1,
    label: T.ectoplanner.results.endenergydemand.annualenergymwha
  },
  {
    dataKey: '[3]',
    flexGrow: 1,
    label: T.ectoplanner.results.endenergydemand.peakpowerkw
  }
];

const IconColumn: DataTableColumnProps<unknown> = {
  dataKey: '[0]',
  label: '',
  maxWidth: 55,
  minWidth: 55,
  align: HorizontalAlignments.CENTER
};

export const EctoplannerEndEnergyDemandTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const {
    peak_PV,
    peak_from_DC,
    peak_from_DH,
    peak_from_WASTE,
    peak_from_WASTE2,
    peak_from_WASTE_cold,
    peak_gas,
    peak_power_from_grid,
    peak_power_to_grid,
    total_PV_power,
    total_el_from_grid,
    total_el_to_grid,
    total_from_DC,
    total_from_DH,
    total_from_waste_cold,
    total_from_waste_heat,
    total_from_waste_heat2,
    total_gas,
    STC
  } = results;

  const filterEmpty = (entries: (string | JSX.Element)[][]) =>
    _.filter(entries, (entry) => entry[2] !== '0' && entry[3] !== '0');

  const data = [
    [
      <img src={ImageGas} />,
      T.ectoplanner.form.shared.naturalgas,
      renderValue(total_gas),
      renderValue(peak_gas)
    ],
    [
      <img src={ImageGridExport} />,
      T.ectoplanner.form.shared.electricityfromgrid,
      renderValue(total_el_from_grid),
      renderValue(peak_power_from_grid)
    ],
    [
      <img src={ImageGridImport} />,
      T.ectoplanner.form.shared.electricityfeedin,
      renderValue(total_el_to_grid),
      renderValue(peak_power_to_grid)
    ],
    [
      <img src={ImageSTC} />,
      T.ectoplanner.results.solarthermal,
      renderValue(STC?.gen),
      '--'
    ],
    [
      <img src={ImagePV} />,
      T.ectoplanner.results.pvgeneration,
      renderValue(total_PV_power),
      renderValue(peak_PV)
    ],
    [
      <img src={ImageDistrict_heating_2} />,
      T.ectoplanner.form.shared.districtheating,
      renderValue(total_from_DH),
      renderValue(peak_from_DH)
    ],
    [
      <img src={ImageDistrict_cooling_2} />,
      T.ectoplanner.form.shared.districtcooling,
      renderValue(total_from_DC),
      renderValue(peak_from_DC)
    ],
    [
      <img src={ImageAmbient_heat} />,
      T.ectoplanner.form.shared.ambientheatsource,
      renderValue(total_from_waste_heat),
      renderValue(peak_from_WASTE)
    ],
    [
      <img src={ImageWasteHeat} />,
      T.ectoplanner.form.shared.wasteheatsource,
      renderValue(total_from_waste_heat2),
      renderValue(peak_from_WASTE2)
    ],
    [
      <img src={ImageAmbient_cold} />,
      T.ectoplanner.form.shared.ambientcoldsource,
      renderValue(total_from_waste_cold),
      renderValue(peak_from_WASTE_cold)
    ]
  ];

  const filteredData = filterEmpty(data);

  return (
    <EctoplannerBox title={T.ectoplanner.results.endenergydemand.energysources}>
      <DataTable data={filteredData} columns={endEnergyDemandColumns} />
    </EctoplannerBox>
  );
};

export const demandBalancingColumns: DataTableColumnProps<unknown>[] = [
  IconColumn,
  {
    dataKey: '[1]',
    label: T.ectoplanner.form.shared.category,
    flexGrow: 2
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.results.demandbalancing.heatdemandmwha,
    flexGrow: 1
  },
  {
    dataKey: '[3]',
    label: T.ectoplanner.results.demandbalancing.colddemandmwha,
    flexGrow: 1
  },
  {
    dataKey: '[4]',
    label: T.ectoplanner.results.demandbalancing.balanceddemandsmwha,
    flexGrow: 1
  },
  {
    dataKey: '[5]',
    label: T.ectoplanner.results.demandbalancing.balanceddemands,
    flexGrow: 1
  }
];

export const balancingUnitColumns = [
  IconColumn,
  {
    dataKey: '[1]',
    label: T.ectoplanner.form.shared.equipmenttype,
    flexGrow: 2
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.form.shared.capacity,
    flexGrow: 1
  },
  {
    dataKey: '[3]',
    label: T.ectoplanner.results.balancingunit.heatinggeneration,
    flexGrow: 1
  },
  {
    dataKey: '[4]',
    label: T.ectoplanner.results.balancingunit.coolinggeneration,
    flexGrow: 1
  },
  {
    dataKey: '[5]',
    label: T.ectoplanner.form.shared.fullloadhours,
    flexGrow: 1
  }
];

export const EctoplannerBalancingUnitTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const { AC, AQU, BOI, CC, CHP, EH, GSHP, RevHP, RevHP2, SimpHP } = results;

  const data = _.compact([
    RevHP?.inst && [
      <img src={ImageHeat_pump_reversible} />,
      T.ectoplanner.form.shared.airsourceheatpump,
      renderValue(RevHP.cap, T.ectoplanner.units.kwth),
      renderValue(RevHP.gen_heat, T.ectoplanner.units.mwh),
      renderValue(RevHP.gen_cold, T.ectoplanner.units.mwh),
      renderValue(RevHP.hrs, T.ectoplanner.units.ha)
    ],
    RevHP2?.inst && [
      <img src={ImageHeat_pump_reversible} />,
      T.ectoplanner.form.shared.airsourceheatpump + ' 2',
      renderValue(RevHP2.cap, T.ectoplanner.units.kwth),
      renderValue(RevHP2.gen_heat, T.ectoplanner.units.mwh),
      renderValue(RevHP2.gen_cold, T.ectoplanner.units.mwh),
      renderValue(RevHP2.hrs, T.ectoplanner.units.ha)
    ],
    GSHP?.inst && [
      <img src={ImageGeothermal} />,
      T.ectoplanner.form.shared.groundsourceheatpump,
      renderValue(GSHP.cap, T.ectoplanner.units.kwth),
      renderValue(GSHP.gen_heat, T.ectoplanner.units.mwh),
      renderValue(GSHP.gen_cold, T.ectoplanner.units.mwh),
      renderValue(GSHP.hrs, T.ectoplanner.units.ha)
    ],
    SimpHP?.inst && [
      <img src={ImageHeat_pump_heat_only} />,
      T.ectoplanner.form.shared.heatpumpheatingonly,
      renderValue(SimpHP.cap, T.ectoplanner.units.kwth),
      renderValue(SimpHP.gen, T.ectoplanner.units.mwhth),
      '',
      renderValue(SimpHP.hrs, T.ectoplanner.units.ha)
    ],
    AQU?.inst && [
      <img src={ImageAquifer_storage} />,
      T.ectoplanner.form.shared.aquiferstorage,
      renderValue(AQU.cap, T.ectoplanner.units.kwth),
      renderValue(AQU.gen_heat, T.ectoplanner.units.mwh),
      renderValue(AQU.gen_cold, T.ectoplanner.units.mwh),
      renderValue(AQU.hrs, T.ectoplanner.units.ha)
    ],
    EH?.inst && [
      <img src={ImageEH} />,
      T.ectoplanner.form.shared.electricboiler,
      renderValue(EH.cap, T.ectoplanner.units.kwth),
      renderValue(EH.gen, T.ectoplanner.units.mwhth),
      '',
      renderValue(EH.hrs, T.ectoplanner.units.ha)
    ],
    CC?.inst && [
      <img src={ImageCC} />,
      T.ectoplanner.form.shared.compressionchiller,
      renderValue(CC.cap, T.ectoplanner.units.kwth),
      '',
      renderValue(CC.gen, T.ectoplanner.units.mwhth),
      renderValue(CC.hrs, T.ectoplanner.units.ha)
    ],
    AC?.inst && [
      <img src={ImageAC} />,
      T.ectoplanner.form.shared.absorptionchiller,
      renderValue(AC.cap, T.ectoplanner.units.kwth),
      '',
      renderValue(AC.gen, T.ectoplanner.units.mwhth),
      renderValue(AC.hrs, T.ectoplanner.units.ha)
    ],
    CHP?.inst && [
      <img src={ImageCHP_ICE} />,
      T.ectoplanner.form.shared.chpunit,
      renderValue(CHP.cap, T.ectoplanner.units.kwel),
      renderValue(CHP.gen, T.ectoplanner.units.mwhel),
      '',
      renderValue(CHP.hrs, T.ectoplanner.units.ha)
    ],
    BOI?.inst && [
      <img src={ImageBOI} />,
      T.ectoplanner.form.shared.gasboiler,
      renderValue(BOI.cap, T.ectoplanner.units.kwth),
      renderValue(BOI.gen, T.ectoplanner.units.mwhth),
      '',
      renderValue(BOI.hrs, T.ectoplanner.units.ha)
    ]
  ]);

  return <DataTable data={data} columns={balancingUnitColumns} />;
};

export const balancingUnitSecondaryColumns = [
  IconColumn,
  {
    dataKey: '[1]',
    label: T.ectoplanner.form.shared.equipmenttype,
    flexGrow: 2
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.form.shared.capacity,
    flexGrow: 1
  },
  {
    dataKey: '[3]',
    label: T.ectoplanner.results.balancingunitsecondary.area,
    flexGrow: 1
  },
  {
    dataKey: '[4]',
    label: T.ectoplanner.form.shared.generation,
    flexGrow: 1
  },
  {
    dataKey: '[5]',
    label: T.ectoplanner.form.shared.fullloadhours,
    flexGrow: 1
  }
];

export const EctoplannerBalancingUnitPhotovoltaicTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const { STC, PV, total_PV_area } = results;

  const data = [];

  if (PV?.inst) {
    data.push([
      <img src={ImagePV} />,
      T.ectoplanner.results.photovoltaics,
      renderValue(PV.cap, T.ectoplanner.units.kwp),
      renderValue(total_PV_area, T.ectoplanner.units.m2),
      renderValue(PV.gen, T.ectoplanner.units.mwha),
      renderValue(PV.hrs, T.ectoplanner.units.ha)
    ]);
  }

  if (STC?.inst) {
    data.push([
      <img src={ImageSTC} />,
      T.ectoplanner.results.solarthermal,
      '',
      renderValue(STC.cap, T.ectoplanner.units.m2),
      renderValue(STC.gen, T.ectoplanner.units.mwha),
      ''
    ]);
  }

  if (data.length === 0) {
    return null;
  }

  return <DataTable data={data} columns={balancingUnitSecondaryColumns} />;
};

export const storageColumns = [
  IconColumn,
  {
    dataKey: '[1]',
    label: T.ectoplanner.form.shared.equipmenttype,
    flexGrow: 2
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.form.shared.capacity,
    flexGrow: 1
  },
  {
    dataKey: '[3]',
    label: T.ectoplanner.results.storage.volume,
    flexGrow: 1
  },
  {
    dataKey: '[4]',
    label: T.ectoplanner.results.storage.chargingcycles,
    flexGrow: 1
  }
];

export const EctoplannerStorageTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const { ACC, BAT, CTES, TES, storage_inst } = results;

  if (!storage_inst) {
    return null;
  }

  const data = _.compact([
    ACC.inst && [
      <img src={ImagePassiveBalancingUnit} />,
      T.ectoplanner.form.shared.accumulatortank,
      renderValue(ACC.cap, T.ectoplanner.units.kwh),
      renderValue(ACC.vol, T.ectoplanner.units.m3),
      renderValue(ACC.chc)
    ],
    BAT.inst && [
      <img src={ImageBAT} />,
      T.ectoplanner.form.shared.battery,
      renderValue(BAT.cap, T.ectoplanner.units.kwh),
      '--',
      renderValue(BAT.chc)
    ],
    TES.inst && [
      <img src={ImageHeatStorage} />,
      T.ectoplanner.form.shared.heatstorage,
      renderValue(TES.cap, T.ectoplanner.units.kwh),
      renderValue(TES.vol, T.ectoplanner.units.m3),
      renderValue(TES.chc)
    ],
    CTES.inst && [
      <img src={ImageColdStorage} />,
      T.ectoplanner.form.shared.coldstorage,
      renderValue(CTES.cap, T.ectoplanner.units.kwh),
      renderValue(CTES.vol, T.ectoplanner.units.m3),
      renderValue(CTES.chc)
    ]
  ]);
  return <DataTable data={data} columns={storageColumns} />;
};

export const cumulatedInstallationsColumns = [
  {
    dataKey: '[0]',
    label: T.ectoplanner.form.shared.technology
  },
  {
    dataKey: '[1]',
    label: T.ectoplanner.form.shared.capacity
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.results.cumulatedinstallations.powergasdemand
  }
];

export const EctoplannerCumulatedInstallationsTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const { all_bldgs } = results;

  const data = [
    [
      T.ectoplanner.form.shared.heatpump,
      renderValue(all_bldgs.hp_capacity, T.ectoplanner.units.kwth),
      renderValue(all_bldgs.total_power_dem_HP, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.form.shared.chiller,
      renderValue(all_bldgs.cc_capacity, T.ectoplanner.units.kwth),
      renderValue(all_bldgs.total_power_dem_CC, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.form.shared.directcooling,
      renderValue(all_bldgs.drc_capacity, T.ectoplanner.units.kwth),
      renderValue(0, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.form.shared.electricheatingrod,
      renderValue(all_bldgs.eh_capacity, T.ectoplanner.units.kwth),
      renderValue(all_bldgs.total_power_dem_EH, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.form.shared.gasboiler,
      renderValue(all_bldgs.boi_capacity, T.ectoplanner.units.kwth),
      renderValue(all_bldgs.total_gas_dem_bldgs, T.ectoplanner.units.mwha)
    ]
  ];

  return <DataTable data={data} columns={cumulatedInstallationsColumns} />;
};

export const EctoplannerBuildingEquipmentTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const {
    bldg_BOI_enabled,
    bldg_CC_enabled,
    bldg_DRC_enabled,
    bldg_EH_enabled,
    bldgs
  } = results;

  const buildingEquipmentColumns = _.compact([
    {
      dataKey: 'name',
      label: T.ectoplanner.form.shared.building
    },
    {
      dataKey: 'hp_capacity',
      label: T.ectoplanner.form.shared.heatpump
    },
    bldg_EH_enabled && {
      dataKey: 'eh_capacity',
      label: T.ectoplanner.form.shared.electricheatingrod
    },
    bldg_BOI_enabled && {
      dataKey: 'boi_capacity',
      label: T.ectoplanner.form.shared.gasboiler
    },
    bldg_CC_enabled && {
      dataKey: 'cc_capacity',
      label: T.ectoplanner.form.shared.chiller
    },
    bldg_DRC_enabled && {
      dataKey: 'drc_capacity',
      label: T.ectoplanner.form.shared.directcooling
    }
  ]);

  const data = [
    ...Object.values(bldgs).map((building) => ({
      name: building.name,
      hp_capacity: renderValue(building.hp_capacity, T.ectoplanner.units.kwth),
      eh_capacity: renderValue(building.eh_capacity, T.ectoplanner.units.kwth),
      boi_capacity: renderValue(
        building.boi_capacity,
        T.ectoplanner.units.kwth
      ),
      cc_capacity: renderValue(building.cc_capacity, T.ectoplanner.units.kwth),
      drc_capacity: renderValue(building.drc_capacity, T.ectoplanner.units.kwth)
    }))
  ];
  return <DataTable data={data} columns={buildingEquipmentColumns} />;
};

export const EctoplannerCapexBuildingsTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const {
    bldg_BOI_enabled,
    bldg_CC_enabled,
    bldg_DRC_enabled,
    bldg_EH_enabled,
    bldgs
  } = results;

  const capexBuildingsColumns = _.compact([
    {
      dataKey: 'name',
      label: T.ectoplanner.form.shared.building
    },
    {
      dataKey: 'hp_cost',
      label: T.ectoplanner.form.shared.heatpump
    },
    bldg_EH_enabled && {
      dataKey: 'eh_cost',
      label: T.ectoplanner.form.shared.electricheatingrod
    },
    bldg_BOI_enabled && {
      dataKey: 'boi_cost',
      label: T.ectoplanner.form.shared.gasboiler
    },
    bldg_CC_enabled && {
      dataKey: 'cc_cost',
      label: T.ectoplanner.form.shared.chiller
    },
    bldg_DRC_enabled && {
      dataKey: 'drc_cost',
      label: T.ectoplanner.form.shared.directcooling
    }
  ]);

  const data = [
    ...Object.values(bldgs).map((value) => ({
      name: value.name,
      hp_cost: renderValue(value.hp_cost, T.ectoplanner.units.eur),
      eh_cost: renderValue(value.eh_cost, T.ectoplanner.units.eur),
      boi_cost: renderValue(value.boi_cost, T.ectoplanner.units.eur),
      cc_cost: renderValue(value.cc_cost, T.ectoplanner.units.eur),
      drc_cost: renderValue(value.drc_cost, T.ectoplanner.units.eur)
    }))
  ];
  return <DataTable data={data} columns={capexBuildingsColumns} />;
};

export const EctoplannerEnergyDemandsTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const { bldg_BOI_enabled, bldgs } = results;

  const energyDemandsColumns = _.compact([
    {
      dataKey: 'name',
      label: T.ectoplanner.form.shared.building
    },
    {
      dataKey: 'total_heat_sh_dem',
      label: T.ectoplanner.results.energydemands.spaceheatingdemandmwha
    },
    {
      dataKey: 'total_heat_dhw_dem',
      label: T.ectoplanner.results.energydemands.tapwaterdemandmwha
    },
    {
      dataKey: 'total_cool_dem',
      label: T.ectoplanner.results.energydemands.coolingdemandmwha
    },
    {
      dataKey: 'total_power_dem',
      label: T.ectoplanner.results.energydemands.electricitydemandmwha
    },
    bldg_BOI_enabled && {
      dataKey: 'total_gas_dem',
      label: T.ectoplanner.results.energydemands.gasdemandmwha
    },
    {
      dataKey: 'DOC_BES',
      label: T.ectoplanner.results.energydemands.balanceddemandsinbuilding
    },
    {
      dataKey: 'HP_COP',
      label: T.ectoplanner.results.energydemands.meanheatpumpcop
    }
  ]);

  const data = [
    ...Object.values(bldgs).map((value) => ({
      name: value.name,
      total_heat_sh_dem: renderValue(value.total_heat_sh_dem),
      total_heat_dhw_dem: renderValue(value.total_heat_dhw_dem),
      total_cool_dem: renderValue(value.total_cool_dem),
      total_power_dem: renderValue(value.total_power_dem),
      total_gas_dem: renderValue(value.total_gas_dem),
      DOC_BES: renderValue(value.DOC_BES, T.ectoplanner.units.percent),
      HP_COP: renderValue(value.HP_COP)
    }))
  ];
  return <DataTable data={data} columns={energyDemandsColumns} />;
};

export const costsColumns = [
  {
    dataKey: '[0]',
    label: T.ectoplanner.form.shared.category
  },
  {
    dataKey: '[1]',
    label: T.ectoplanner.results.costs.annualcostseura
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.results.costs.investmentseur
  }
];

export const EctoplannerCostsTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const {
    all_bldgs,
    ann_piping_cost,
    ann_total_bu_equip_cost,
    piping_cost,
    total_bu_equip_cost,
    total_bu_om_cost,
    total_el_from_grid_costs,
    total_el_to_grid_revenue,
    total_from_DC_costs,
    total_from_DH_costs,
    total_gas_costs,
    total_waste_cold_costs,
    total_waste_heat_costs,
    enable_netw_costs
  } = results;

  const tableEntries = _.compact([
    [T.ectoplanner.form.shared.naturalgas, renderValue(total_gas_costs), ''],
    [
      T.ectoplanner.form.shared.electricity,
      renderValue(total_el_from_grid_costs),
      ''
    ],
    [
      T.ectoplanner.form.shared.feedin,
      renderValue(total_el_to_grid_revenue),
      ''
    ],
    [
      T.ectoplanner.form.shared.districtheating,
      renderValue(total_from_DH_costs),
      ''
    ],
    [
      T.ectoplanner.form.shared.districtcooling,
      renderValue(total_from_DC_costs),
      ''
    ],
    [
      T.ectoplanner.form.shared.ambientheatsource,
      renderValue(total_waste_heat_costs),
      ''
    ],
    [
      T.ectoplanner.form.shared.ambientcoldsource,
      renderValue(total_waste_cold_costs),
      ''
    ],
    [
      T.ectoplanner.results.investmentbuildings,
      renderValue(all_bldgs.ann_total_bldg_equip_cost),
      renderValue(all_bldgs.total_bldg_equip_cost)
    ],
    [
      T.ectoplanner.results.investmentbalancingunit,
      renderValue(ann_total_bu_equip_cost),
      renderValue(total_bu_equip_cost)
    ],
    [
      T.ectoplanner.results.operationandmaintenancecosts,
      renderValue(total_bu_om_cost),
      ''
    ],
    enable_netw_costs && [
      T.ectoplanner.form.shared.pipeinstallation,
      renderValue(ann_piping_cost),
      renderValue(piping_cost)
    ]
  ]);

  const data = _.filter(
    tableEntries,
    (entry) => entry[1] !== '0' && entry[2] !== '0'
  );

  return <DataTable data={data} columns={costsColumns} />;
};

export const buildingDemandsInputColumns = [
  {
    dataKey: '[0]',
    label: T.ectoplanner.form.shared.category
  },
  {
    dataKey: '[1]',
    label: T.ectoplanner.results.buildingdemandsinput.annualdemandmwha
  }
];

export const EctoplannerBuildingDemandsInputTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const { all_bldgs } = results;

  const data = [
    [
      T.ectoplanner.form.shared.spaceheating,
      renderValue(all_bldgs.total_sh_dem)
    ],
    [T.ectoplanner.results.tapwater, renderValue(all_bldgs.total_dhw_dem)],
    [T.ectoplanner.results.cooling, renderValue(all_bldgs.total_cool_dem)]
  ];
  return <DataTable data={data} columns={buildingDemandsInputColumns} />;
};

export const co2EmissionsColumns = [
  {
    dataKey: '[0]',
    label: T.ectoplanner.form.shared.category
  },
  {
    dataKey: '[1]',
    label: T.ectoplanner.form.shared.co2emissions
  },
  {
    dataKey: '[2]',
    label: T.ectoplanner.results.ecologickpis.primary_energy_demand
  }
];

export const EctoplannerEcologicKPIsTable = ({
  results
}: EctoplannerResultComponentProps) => {
  const {
    CO2,
    el_CO2,
    el_primary_energy,
    gas_primary_energy,
    gas_CO2,
    district_heating_CO2,
    district_heating_primary_energy,
    district_cooling_CO2,
    district_cooling_primary_energy,
    primary_energy,
    waste_heat_CO2,
    waste_heat_primary_energy,
    waste_cold_CO2,
    waste_cold_primary_energy
  } = results;

  const data = [
    [
      T.ectoplanner.form.shared.electricityimport,
      renderValue(el_CO2, T.ectoplanner.results.ta),
      renderValue(el_primary_energy, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.results.burningnaturalgas,
      renderValue(gas_CO2, T.ectoplanner.results.ta),
      renderValue(gas_primary_energy, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.results.district_heating,
      renderValue(district_heating_CO2, T.ectoplanner.results.ta),
      renderValue(district_heating_primary_energy, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.results.district_cooling,
      renderValue(district_cooling_CO2, T.ectoplanner.results.ta),
      renderValue(district_cooling_primary_energy, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.results.waste_heat,
      renderValue(waste_heat_CO2, T.ectoplanner.results.ta),
      renderValue(waste_heat_primary_energy, T.ectoplanner.units.mwha)
    ],
    [
      T.ectoplanner.results.waste_cooling,
      renderValue(waste_cold_CO2, T.ectoplanner.results.ta),
      renderValue(waste_cold_primary_energy, T.ectoplanner.units.mwha)
    ],
    [
      <strong>{T.ectoplanner.results.totalemissions}</strong>,
      renderValue(CO2, T.ectoplanner.results.ta),
      renderValue(primary_energy, T.ectoplanner.units.mwha)
    ]
  ];

  return <DataTable data={data} columns={co2EmissionsColumns} />;
};

export const EctoplannerBalancingUnitInfo = ({
  results
}: EctoplannerResultComponentProps) => {
  const { BU_load_peak } = results;

  return (
    <div className={styles.infoBox}>
      <div>
        {T.format(
          T.ectoplanner.balancingunit.maximumheatingpowerbu,
          <strong key="heat">{renderValue(BU_load_peak.heat)}</strong>
        )}
      </div>
      <div>
        {T.format(
          T.ectoplanner.balancingunit.maximumcoolingpowerbu,
          <strong key="cool">{renderValue(BU_load_peak.cool)}</strong>
        )}
      </div>
    </div>
  );
};
