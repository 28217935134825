import _ from 'lodash';

/**
 * Manages undo and redo history
 */
class UndoManager<Type> {
  private undoHistory: Type[];
  private undoIndex: number;

  constructor(undoHistory: Type[] = [], undoIndex = 0) {
    this.undoHistory = undoHistory ?? [];

    if (undoIndex == null) {
      this.undoIndex = this.undoHistory.length - 1;
    } else {
      this.undoIndex = undoIndex;
    }
  }

  /**
   * Undo an item
   * @param onData Called with the new data, if an undo is done
   * @return Current undo/redo state
   */
  undo = (onData: (value: Type) => void) => {
    if (this.undoIndex === 0) {
      return;
    }

    const oldValue = this.undoHistory[this.undoIndex - 1];
    this.undoIndex -= 1;
    onData?.(oldValue);
  };

  /**
   * Redo an item
   * @param onData Called with the new data, if a redo is done
   * @return Current undo/redo state
   */
  redo = (onData: (value: Type) => void) => {
    if (this.undoIndex === this.undoHistory.length - 1) {
      return;
    }
    this.undoIndex += 1;
    const newData = this.undoHistory[this.undoIndex];
    onData?.(newData);
  };

  /**
   * Add an item to the undo history
   * @param newValue
   * @return Current undo/redo state
   */
  add = (newValue: Type) => {
    // Slice current undo data to remove any future redo because now we write new history
    this.undoHistory = [
      ..._.slice(this.undoHistory, 0, this.undoIndex + 1),
      _.cloneDeep(newValue)
    ];
    this.undoIndex = this.undoHistory.length - 1;
  };

  /**
   * Update the internal state, useful when restoring undo/redo history
   * @param undoHistory
   * @param undoIndex
   */
  update = (undoHistory: Type[], undoIndex: number) => {
    this.undoHistory = undoHistory ?? [];
    if (undoIndex == null) {
      this.undoIndex = this.undoHistory.length - 1;
    } else {
      this.undoIndex = undoIndex;
    }
  };

  /**
   * @return true if undo is possible
   */
  get canUndo(): boolean {
    return this.undoIndex > 0;
  }

  /**
   * @return true if redo is possible
   */
  get canRedo(): boolean {
    return this.undoIndex < this.undoHistory.length - 1;
  }

  get hasHistory(): boolean {
    return this.undoHistory.length > 0;
  }
}

export default UndoManager;
