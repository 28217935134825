import React, { useCallback, useEffect } from 'react';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';

interface DeleteModalProps<ItemType> {
  deleteItem?: ItemType;
  onModalClose(): void;
  onConfirmDelete(item: ItemType): void;
  isLoading?: boolean;
  itemName?: string;
}

function DeleteModal<ItemType>({
  deleteItem,
  onModalClose,
  onConfirmDelete,
  isLoading,
  itemName
}: DeleteModalProps<ItemType>) {
  const [
    isConfirmDeleteDialogVisible,
    showConfirmDeleteDialog,
    _hideConfirmDeleteDialog
  ] = useSimpleDialogState();

  const onDelete = useCallback(() => {
    onConfirmDelete(deleteItem);
  }, [deleteItem, onConfirmDelete]);

  useEffect(() => {
    if (deleteItem) {
      showConfirmDeleteDialog();
    } else {
      _hideConfirmDeleteDialog();
    }
  }, [deleteItem, showConfirmDeleteDialog, _hideConfirmDeleteDialog]);

  const _onModalClose = useCallback(() => {
    onModalClose();
    _hideConfirmDeleteDialog();
  }, [onModalClose, _hideConfirmDeleteDialog]);

  return (
    <ConfirmDeleteDialog
      isLoading={isLoading}
      isOpen={isConfirmDeleteDialogVisible}
      onDelete={onDelete}
      onModalClose={_onModalClose}
      itemName={itemName}
    />
  );
}

export default DeleteModal;
