import _ from 'lodash';

const Ranges3G = [
  {
    from: -130,
    to: -110
  },
  {
    from: -110,
    to: -100
  },
  {
    from: -100,
    to: -86
  },
  {
    from: -85,
    to: -70
  },
  {
    from: -70,
    to: -40
  }
];

const Ranges4G = [
  {
    from: -110,
    to: -95
  },
  {
    from: -95,
    to: -85
  },
  {
    from: -85,
    to: -75
  },
  {
    from: -75,
    to: -65
  },
  {
    from: -65,
    to: -45
  }
];

const getRangesBasedOnAccessTechnology = (accessTechnology: string) => {
  if (_.includes(accessTechnology, '4G')) {
    return Ranges4G;
  }

  if (
    _.includes(accessTechnology, '2G') ||
    _.includes(accessTechnology, '3G')
  ) {
    return Ranges3G;
  }

  return [];
};

export default getRangesBasedOnAccessTechnology;
