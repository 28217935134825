import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import styles from 'ecto-common/lib/SignalStrengthIllustration/SignalStrengthIllustration.module.css';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';

const withinRange = (range: SignalStrengthRange, signalStrength: number) =>
  _.inRange(signalStrength, range.from, range.to) ||
  signalStrength >= range.from;

interface SignalStrengthRange {
  from: number;
  to: number;
}

interface SignalStrengthIllustrationProps {
  ranges: SignalStrengthRange[];
  signalStrength: number;
  unit?: string;
}

const SignalStrengthIllustration = ({
  ranges,
  signalStrength,
  unit = T.common.dBm
}: SignalStrengthIllustrationProps) => (
  <div>
    <Tooltip
      className={styles.tooltip}
      text={
        <>
          {signalStrength} {unit}
        </>
      }
    >
      <div className={styles.barsContainer}>
        {ranges.map((range, key) => (
          <div
            key={key}
            className={classNames(
              styles.bar,
              withinRange(range, signalStrength) && styles.barIsActive
            )}
            style={{ height: 5 + (20 * key) / ranges.length }}
          />
        ))}
      </div>
    </Tooltip>
  </div>
);

export default SignalStrengthIllustration;
