import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect
} from 'react';
import { TimeRangeOptions } from 'ecto-common/lib/types/TimeRangeOptions';
import TimeRangeSelector, {
  TimeRangeSelectOption
} from 'ecto-common/lib/TimeRangeSelector/TimeRangeSelector';
import _ from 'lodash';
import { TIME_RANGE_OPTIONS } from 'ecto-common/lib/TimeRangeSelector/TimeRangeSelector';
import { TimeRangeContextType } from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import moment, { Moment } from 'moment/moment';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';

const getTimeRangesFromConsumers = (
  consumers: React.MutableRefObject<Record<string, TimeRangeOptions[]>>
): TimeRangeOptions[] => {
  const allTimeRanges = _.flatMap(consumers.current);
  return _.uniqBy(allTimeRanges, _.identity);
};

const getHasFreeRangeSelector = (
  consumers: React.MutableRefObject<Record<string, TimeRangeOptions[]>>
) => {
  return _.some(_.values(consumers.current), (x) => x.length === 0);
};

/**
 * Handles a time range selector, and whether or not it should be visible.
 * Use this toghether with the TimeRangeContext and the selector will toggle visiblity depending on usage of the panels
 * @returns {[{addTimeRangeConsumer: function, removeTimeRangeConsumer: function, timeRangeOption: string}, selector]}
 */
const useTimeRangeSelector = (): [TimeRangeContextType, React.ReactNode] => {
  const [timeRangeOption, setTimeRangeOption] = useSearchParamState(
    'range-option',
    TimeRangeOptions.DAY
  );
  const [referenceDate, setReferenceDate] = useSearchParamState(
    'range-date',
    null
  );

  const [timeRangeVisibility, setTimeRangeVisibility] = useState(false);
  const [allTimeRanges, setAllTimeRanges] = useState<string[]>([]);
  const [hasFreeRangeSelector, setHasFreeRangeSelector] = useState(false);
  const consumers = useRef<Record<string, TimeRangeOptions[]>>({});
  const isAlive = useRef(false);
  useEffect(() => {
    isAlive.current = true;
    return () => {
      isAlive.current = false;
    };
  });

  const timeRangeOptions: TimeRangeSelectOption[] = useMemo(() => {
    let options = TIME_RANGE_OPTIONS;

    if (!hasFreeRangeSelector) {
      options = _.filter(TIME_RANGE_OPTIONS, (option) =>
        allTimeRanges.includes(option.value)
      );
    }

    // This only works for pre-aggregated data, so only show if if a source actually has defined data
    // for this period.
    if (!allTimeRanges.includes(TimeRangeOptions.FIVE_YEARS_BACK)) {
      options = _.reject(options, { value: TimeRangeOptions.FIVE_YEARS_BACK });
    }

    return options;
  }, [allTimeRanges, hasFreeRangeSelector]);

  useEffect(() => {
    if (
      timeRangeOptions.length > 0 &&
      _.find(timeRangeOptions, { value: timeRangeOption }) == null
    ) {
      setTimeRangeOption(_.head(timeRangeOptions).value as TimeRangeOptions);
    }
  }, [setTimeRangeOption, timeRangeOption, timeRangeOptions]);

  const referenceDateMoment = useMemo(() => {
    return referenceDate ? moment(referenceDate) : null;
  }, [referenceDate]);

  const setReferenceDateMoment = useCallback(
    (date: Moment | ((newDate: Moment) => Moment)) => {
      if (_.isFunction(date)) {
        setReferenceDate(date(referenceDateMoment)?.toISOString());
      } else {
        setReferenceDate(date?.toISOString());
      }
    },
    [referenceDateMoment, setReferenceDate]
  );

  const selector = useMemo(() => {
    return (
      <>
        {timeRangeVisibility && (
          <TimeRangeSelector
            options={timeRangeOptions}
            value={timeRangeOption as TimeRangeOptions}
            referenceDate={referenceDateMoment}
            onReferenceDateChanged={setReferenceDateMoment}
            onValueChanged={setTimeRangeOption}
          />
        )}
      </>
    );
  }, [
    timeRangeVisibility,
    timeRangeOptions,
    timeRangeOption,
    referenceDateMoment,
    setReferenceDateMoment,
    setTimeRangeOption
  ]);

  const addTimeRangeConsumer = useCallback(
    (consumer: string, specifiedTimeRanges: TimeRangeOptions[]) => {
      consumers.current[consumer] = specifiedTimeRanges;
      _.defer(() => {
        if (isAlive.current) {
          setTimeRangeVisibility(_.keys(consumers.current).length > 0);
          setAllTimeRanges(getTimeRangesFromConsumers(consumers));
          setHasFreeRangeSelector(getHasFreeRangeSelector(consumers));
        }
      });
    },
    []
  );

  const removeTimeRangeConsumer = useCallback((consumer: string) => {
    delete consumers.current[consumer];
    _.defer(() => {
      if (isAlive.current) {
        setTimeRangeVisibility(_.keys(consumers.current).length > 0);
        setAllTimeRanges(getTimeRangesFromConsumers(consumers));
        setHasFreeRangeSelector(getHasFreeRangeSelector(consumers));
      }
    });
  }, []);

  const value = useMemo(
    () => ({
      referenceDate: referenceDateMoment,
      timeRangeOption: timeRangeOption as TimeRangeOptions,
      addTimeRangeConsumer,
      removeTimeRangeConsumer
    }),
    [
      referenceDateMoment,
      timeRangeOption,
      addTimeRangeConsumer,
      removeTimeRangeConsumer
    ]
  );

  return [value, selector];
};

export default useTimeRangeSelector;
