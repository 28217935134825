import React, { useState, useCallback, useEffect } from 'react';
import _ from 'lodash';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import SignalSelectorGeneric from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric';
import styles from './SelectSignalsDialog.module.css';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import UUID from 'uuidjs';
import {
  FullSignalProviderResponseModel,
  SignalProviderSignalResponseModel
} from 'ecto-common/lib/API/APIGen';

interface SelectSignalsDialogProps {
  isOpen: boolean;
  onModalClose(): void;
  onSignalsSelected(newSignals: ChartSignal[]): void;
  selectedSignals: ChartSignal[];
  hasSignal?: (
    signals: ChartSignal[],
    signal: SignalProviderSignalResponseModel
  ) => boolean;
  isSingleSelect?: boolean;
  nodeId?: string;
  title?: string;
  selectFromCurrentNodeOnly?: boolean;
  showSignalType?: boolean;
  validSignalTypeIds?: string[];
}

const SelectSignalsDialog = ({
  isOpen,
  onModalClose,
  onSignalsSelected,
  selectedSignals,
  hasSignal,
  isSingleSelect = false,
  nodeId,
  validSignalTypeIds = null,
  selectFromCurrentNodeOnly = false,
  title = T.graphs.signalselectormodal.title,
  showSignalType = false
}: SelectSignalsDialogProps) => {
  const [_selectedSignals, setSelectedSignals] =
    useState<ChartSignal[]>(selectedSignals);
  useEffect(() => setSelectedSignals(selectedSignals), [selectedSignals]);

  const _addNewSignal = useCallback(
    (
      signal: SignalProviderSignalResponseModel,
      signalProvider: FullSignalProviderResponseModel
    ) => {
      if (isSingleSelect) {
        setSelectedSignals([
          {
            item: signal,
            group: _.omit(signalProvider, 'signals'),
            chartSignalId: UUID.generate(),
            parent: null
          }
        ]);
      } else {
        setSelectedSignals(
          _selectedSignals.concat([
            {
              item: signal,
              chartSignalId: UUID.generate(),
              group: _.omit(signalProvider, 'signals'),
              parent: null
            }
          ])
        );
      }
    },
    [_selectedSignals, isSingleSelect, setSelectedSignals]
  );

  const _onRemoveSignal = useCallback(
    ({ item }: ChartSignal) => {
      const { signalId } = item;
      // Stored signals has format: { item: SignalType, group: string, parent: ... }
      setSelectedSignals(
        _selectedSignals.filter((signal) => signal.item.signalId !== signalId)
      );
    },
    [_selectedSignals, setSelectedSignals]
  );

  const _onRemoveAllSignals = useCallback(
    () => setSelectedSignals([]),
    [setSelectedSignals]
  );
  const _onSignalsSelected = useCallback(
    () => onSignalsSelected(_selectedSignals),
    [_selectedSignals, onSignalsSelected]
  );

  return (
    <ActionModal
      headerIcon={Icons.Edit}
      onModalClose={() => {
        setSelectedSignals(selectedSignals);
        onModalClose();
      }}
      onConfirmClick={_onSignalsSelected}
      isOpen={isOpen}
      title={title}
      className={styles.modalResizer}
      messageBodyClassName={styles.modalBody}
    >
      <SignalSelectorGeneric
        className={styles.signalSelector}
        nodeId={nodeId}
        hasSignal={hasSignal}
        selectedSignals={_selectedSignals}
        addNewSignal={_addNewSignal}
        onRemoveSignal={_onRemoveSignal}
        onRemoveAllSignals={_onRemoveAllSignals}
        setSelectedSignals={_.noop}
        selectFromCurrentNodeOnly={selectFromCurrentNodeOnly}
        showSignalType={showSignalType}
        validSignalTypeIds={validSignalTypeIds}
      />
    </ActionModal>
  );
};

export default React.memo(SelectSignalsDialog);
