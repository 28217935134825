import React, { useCallback, useMemo, useState } from 'react';
import { KeyValueSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueSelectableInput';
import _ from 'lodash';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import {
  ModelDefinitionInternal,
  ModelDynamicOptionsProperty
} from 'ecto-common/lib/ModelForm/ModelPropType';

export type OptionsModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object,
  ValueType = object
> = {
  modelType: typeof ModelType.OPTIONS;
  isClearable?: boolean;
  withCreatableOption?: boolean;
  paging?: {
    loadOptions?: (
      search: string,
      loadedOptions: GenericSelectOption[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      additional: any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) => Promise<any>;
  };
  showOptionWhenEmpty?: boolean;
  emptyValueSelectsAll?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAction?: (value?: any) => void;
  // All available options that can be changed for the current item.
  options?: ModelDynamicOptionsProperty<ObjectType, EnvironmentType, ValueType>;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, ValueType>;

type ModelEditorOptionsProps = ModelEditorProps & {
  options: GenericSelectOption[];
  model: OptionsModelDefinition<object, object, unknown>;
};

const ModelEditorOptions = ({
  model,
  options,
  updateItem,
  disabled,
  rawValue,
  hasError,
  helpText = null,
  isHorizontal = false,
  modelIsLoading = false,
  useTooltipHelpTexts = false
}: ModelEditorOptionsProps) => {
  const [dynamicOptions, setDynamicOptions] = useState<GenericSelectOption[]>(
    []
  );

  const value = useMemo(() => {
    const allOptions = _.concat(options, dynamicOptions);

    if (rawValue == null) {
      if (model.isMultiOption && model.emptyValueSelectsAll) {
        return allOptions;
      }
      return null;
    } else if (model.isMultiOption) {
      if (model.withCreatableOption) {
        // With this option the input can create new options, do not filter them against existing options
        return _.map(rawValue, (raw) => ({ value: raw, label: raw }));
      }
      return _.filter(allOptions, (x) => rawValue.includes(x.value));
    }

    return (
      options.find((x) => x.value === rawValue) ||
      dynamicOptions.find((x) => x.value === rawValue)
    );
  }, [
    options,
    dynamicOptions,
    rawValue,
    model.isMultiOption,
    model.emptyValueSelectsAll,
    model.withCreatableOption
  ]);

  const reduceOptions = useCallback(
    (
      prevOptions: GenericSelectOption[],
      loadedOptions: GenericSelectOption[]
    ) => {
      const allOptions = _.concat(prevOptions, loadedOptions);
      setDynamicOptions(allOptions);
      return allOptions;
    },
    []
  );

  const onChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      if (model.isMultiOption) {
        updateItem(_.map(e, 'value'));
      } else {
        updateItem(e?.value ?? null);
      }
    },
    [model, updateItem]
  );

  const cacheUniqs = useMemo(() => {
    return [model.paging?.loadOptions];
  }, [model.paging?.loadOptions]);

  const allOptions = {
    keyText: model.label,
    disabled: disabled,
    value: value,
    onAction: model.onAction,
    onChange: onChange,
    placeholder: model.placeholder,
    options: options,
    hasError: hasError,
    withCreatableOption: model.withCreatableOption,
    isClearable: model.isClearable,
    horizontalWeights: model.horizontalWeights,
    isHorizontal,
    helpText,
    loadOptions: model.paging?.loadOptions,
    reduceOptions: reduceOptions,
    cacheUniqs: cacheUniqs,
    useTooltipHelpTexts,
    showOptionWhenEmpty: model.showOptionWhenEmpty,
    isLoading: model.paging?.loadOptions != null ? undefined : modelIsLoading
  };

  if (model.isMultiOption) {
    return <KeyValueSelectableInput<object, true> {...allOptions} isMulti />;
  }

  return <KeyValueSelectableInput<object, false> {...allOptions} />;
};

export default React.memo(ModelEditorOptions);
