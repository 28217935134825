import React, {
  useCallback,
  useState,
  useEffect,
  ChangeEventHandler
} from 'react';
import classNames from 'classnames';
import Heading from 'ecto-common/lib/Heading/Heading';
import T from 'ecto-common/lib/lang/Language';
import styles from '../ScheduleModal.module.css';

interface EffectControlProps {
  amplitude: number;
  onChange(amplitude: number): void;
  reverseColors: boolean;
}

const EffectControl = ({
  amplitude,
  onChange,
  reverseColors = false
}: EffectControlProps) => {
  const [value, setValue] = useState(amplitude);

  useEffect(() => {
    setValue(amplitude);
  }, [setValue, amplitude]);

  const onCommitChange = useCallback(() => {
    onChange(Number(value));
  }, [value, onChange]);

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setValue(Number(e.target.value));
    },
    [setValue]
  );

  return (
    <>
      <Heading level={5}>
        {T.powercontrol.dispatch.schedule.header.effect}
      </Heading>

      <div className={styles.flexboxContainer}>
        <label className={styles.amplitudeLabel} htmlFor="amplitude">
          {T.powercontrol.schedule.preset.amplitude}
        </label>

        <input
          type="range"
          step="5"
          className={classNames(
            styles.amplitude,
            reverseColors && styles.reverseAmplitude
          )}
          name="amplitude"
          value={value !== undefined ? value : 0}
          min="-100"
          max="100"
          onChange={onChangeHandler}
          onMouseUp={onCommitChange}
        />

        <div className={styles.amplitudeInput}>
          {value !== undefined ? value : 0}%
        </div>
      </div>
    </>
  );
};

export default React.memo(EffectControl);
