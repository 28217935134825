import React, { useMemo } from 'react';
import colors from 'ecto-common/lib/styles/variables/colors';
import {
  ProcessMapPendingConnectionSymbol,
  ProcessMapDocument,
  ProcessMapPendingConnectionLine,
  ProcessMapRectHandle,
  ProcessMapRectResizeArea,
  ProcessMapObject,
  ProcessMapActionType,
  ProcessMapActionTypes,
  ProcessMapViewSignal
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import _ from 'lodash';
import { objects as processMapObjects } from './Object/ProcessMapObjects';
import ProcessMapGrid from './ProcessMapGrid';
import APIGen, {
  FullSignalProviderResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from '../API/APIGen';
import { LastSignalValuesResultWithMetadata } from '../Dashboard/panels/SignalListPanel';
import { getExternalSignalIds } from './ProcessMapViewUtils';

export type MouseActions = Record<
  ProcessMapActionType,
  {
    onClick: (
      event: MouseEvent,
      node: ProcessMapObject,
      signal: ProcessMapViewSignal,
      isWritable: boolean
    ) => void;
    onMouseOver: (
      event: MouseEvent,
      node: ProcessMapObject,
      signal: ProcessMapViewSignal
    ) => void;
    onMouseOut: (event: MouseEvent, node: ProcessMapObject) => void;
  }
>;

export type ProcessMapViewV2Props = {
  matrixTransform: string;
  processMap: ProcessMapDocument;
  setRectResizeElement: (
    objectIndex: number,
    rectIndex: number,
    resizeArea: ProcessMapRectResizeArea
  ) => void;
  updateTextSize: (
    objectIndex: number,
    rectIndex: number,
    width: number,
    height: number
  ) => void;
  selectedRectHandles?: ProcessMapRectHandle[];
  hoverRectHandles: ProcessMapRectHandle[];
  pendingConnectionLine?: ProcessMapPendingConnectionLine;
  pendingConnectionSymbol?: ProcessMapPendingConnectionSymbol;
  draggingSingleLinePoint?: boolean;
  isMouseDown?: boolean;
  children?: React.ReactNode;
  editMode?: boolean;
  isLoading: boolean;
  connectionCircleRadius: number;
  showDeleteConnections: boolean;
  onAddNewLinePoint: (
    objectIndex: number,
    index: number,
    centerX: number,
    centerY: number
  ) => void;
  showSignalLabelsWhenNotFound?: boolean;
  zoom: number;
  mouseActions: MouseActions;
  signalProviders: FullSignalProviderResponseModel[];
  signalData: LastSignalValuesResultWithMetadata;
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypesMap: Record<string, UnitResponseModel>;
};

const emptySelectedRects: ProcessMapRectHandle[] = [];

const ProcessMapViewV2 = ({
  matrixTransform,
  processMap,
  setRectResizeElement,
  updateTextSize,
  selectedRectHandles = emptySelectedRects,
  hoverRectHandles,
  pendingConnectionLine,
  pendingConnectionSymbol,
  draggingSingleLinePoint,
  isMouseDown,
  children,
  signalData,
  signalProviders,
  signalTypesMap,
  signalUnitTypesMap,
  isLoading,
  connectionCircleRadius,
  showDeleteConnections,
  onAddNewLinePoint,
  mouseActions,
  zoom = 1.0,
  editMode = false,
  showSignalLabelsWhenNotFound = false
}: ProcessMapViewV2Props) => {
  const externalSignalIds = useMemo(() => {
    return getExternalSignalIds(processMap);
  }, [processMap]);

  const externalProvidersQuery =
    APIGen.Signals.getProvidersBySignalIds.useQuery(
      {
        signalIds: externalSignalIds
      },
      {
        enabled: externalSignalIds.length > 0
      }
    );

  const allSignalsBySignalTypeOrSignalId = useMemo(() => {
    const allProviders = signalProviders.concat(
      externalProvidersQuery.data ?? []
    );
    const signals: ProcessMapViewSignal[] = _.flatMap(
      allProviders,
      (signalProvider) =>
        _.map(signalProvider.signals, (signal) => ({
          ...signal,
          signalProvider,
          signalType: signalTypesMap[signal.signalTypeId],
          unit: signalUnitTypesMap[signalTypesMap[signal.signalTypeId]?.unitId],
          rawSignal: signal
        }))
    );

    return {
      ..._.keyBy(signals, (signal) => signal.signalTypeId),
      ..._.keyBy(signals, (signal) => signal.signalId)
    };
  }, [
    externalProvidersQuery.data,
    signalProviders,
    signalTypesMap,
    signalUnitTypesMap
  ]);

  const fallbackAction = ProcessMapActionTypes.Signal;
  return (
    <>
      {editMode && (
        <ProcessMapGrid
          width={processMap.width}
          height={processMap.height}
          matrixTransform={matrixTransform}
        />
      )}
      <g
        style={{ transform: matrixTransform, fill: colors.whiteColor }}
        shapeRendering="auto"
      >
        {processMap.objects.map((node, objectIndex) => {
          const isHovering = hoverRectHandles.some(
            (handle) => handle.objectId === node.id
          );
          const View = processMapObjects[node.type].view;
          if (View) {
            return (
              <View
                key={node.id}
                node={node}
                objectIndex={objectIndex}
                isHovering={isHovering}
                allSignalsBySignalTypeOrSignalId={
                  allSignalsBySignalTypeOrSignalId
                }
                isLoading={isLoading}
                signalData={signalData}
                svgImages={processMap.svgImages}
                updateTextSize={updateTextSize}
                zoom={zoom}
                showSignalLabelsWhenNotFound={showSignalLabelsWhenNotFound}
                selectedRectHandles={selectedRectHandles}
                editMode={editMode}
                onClick={
                  mouseActions?.[node.action?.type ?? fallbackAction]?.onClick
                }
                onMouseOver={
                  mouseActions?.[node.action?.type ?? fallbackAction]
                    ?.onMouseOver
                }
                onMouseOut={
                  mouseActions?.[node.action?.type ?? fallbackAction]
                    ?.onMouseOut
                }
              />
            );
          }
        })}
        {processMap.objects.map((node, objectIndex) => {
          const isHovering = hoverRectHandles.some(
            (handle) => handle.objectId === node.id
          );
          const Overlay = processMapObjects[node.type].overlay;
          if (Overlay) {
            return (
              <Overlay
                key={node.id}
                node={node}
                objectIndex={objectIndex}
                hoverRectHandles={hoverRectHandles}
                isHovering={isHovering}
                selectedRectHandles={selectedRectHandles}
                zoom={zoom}
                lineConnections={processMap.lineConnections}
                symbolLineConnections={processMap.symbolLineConnections}
                setRectResizeElement={setRectResizeElement}
                pendingConnectionLine={pendingConnectionLine}
                pendingConnectionSymbol={pendingConnectionSymbol}
                draggingSingleLinePoint={draggingSingleLinePoint}
                isMouseDown={isMouseDown}
                showDeleteConnections={showDeleteConnections}
                onAddNewLinePoint={onAddNewLinePoint}
                connectionCircleRadius={connectionCircleRadius}
              />
            );
          }
        })}
        {children}
      </g>
    </>
  );
};

export default ProcessMapViewV2;
