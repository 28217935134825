import {
  BuildGraphAggregation,
  BuildGraphType
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import colors from 'ecto-common/lib/styles/variables/colors';
import {
  ObjectValues,
  enumValues
} from 'ecto-common/lib/utils/typescriptUtils';
import { HOURS_IN_YEAR } from 'js/components/Ectoplanner/EctoplannerTypes';
import _ from 'lodash';

export const EctoplannerSamplingIntervals = {
  MONTH: 'monthly',
  DAY: 'daily',
  HOUR: 'hourly'
} as const;

export type EctoplannerSamplingInterval = ObjectValues<
  typeof EctoplannerSamplingIntervals
>;

export const EctoplannerZoomLevels = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day'
} as const;

export type EctoplannerZoomLevel = ObjectValues<typeof EctoplannerZoomLevels>;

export const EctoplannerZoomToSamplingInterval = {
  [EctoplannerZoomLevels.YEAR]: EctoplannerSamplingIntervals.MONTH,
  [EctoplannerZoomLevels.MONTH]: EctoplannerSamplingIntervals.DAY,
  [EctoplannerZoomLevels.DAY]: EctoplannerSamplingIntervals.HOUR
};

export const EctoplannerAggregationOptions = enumValues(
  BuildGraphAggregation
).map((value) => ({
  label:
    T.ectoplanner.graphs.aggregations[
      value.toLowerCase() as keyof typeof T.ectoplanner.graphs.aggregations
    ],
  value: value
}));

export const EctoplannerZoomOptions: GenericSelectOption<EctoplannerZoomLevel>[] =
  Object.entries(EctoplannerZoomLevels).map(([_key, value]) => ({
    label: T.ectoplanner.graphs.zoomlevels[value],
    value: value
  }));

export const EctoplannerAggregatorFunctions = {
  [BuildGraphAggregation.Sum]: (values: number[]) => {
    return _.sum(values);
  },
  [BuildGraphAggregation.Average]: (values: number[]) => {
    return _.sum(values) / values.length;
  }
} as const;

export const ectoplannerMonthBins = [
  31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31
];

export const ectoplannerMonthNames = [
  T.highcharts.shortmonths.january,
  T.highcharts.shortmonths.february,
  T.highcharts.shortmonths.march,
  T.highcharts.shortmonths.april,
  T.highcharts.shortmonths.may,
  T.highcharts.shortmonths.june,
  T.highcharts.shortmonths.july,
  T.highcharts.shortmonths.august,
  T.highcharts.shortmonths.september,
  T.highcharts.shortmonths.october,
  T.highcharts.shortmonths.november,
  T.highcharts.shortmonths.december
];
export const ectoplannerWeekNames = [
  T.highcharts.shortmonths.january,
  '',
  '',
  '',
  '',
  T.highcharts.shortmonths.february,
  '',
  '',
  '',
  T.highcharts.shortmonths.march,
  '',
  '',
  '',
  T.highcharts.shortmonths.april,
  '',
  '',
  '',
  T.highcharts.shortmonths.may,
  '',
  '',
  '',
  T.highcharts.shortmonths.june,
  '',
  '',
  '',
  T.highcharts.shortmonths.july,
  '',
  '',
  '',
  ',',
  T.highcharts.shortmonths.august,
  '',
  '',
  '',
  '',
  '',
  T.highcharts.shortmonths.september,
  '',
  '',
  '',
  '',
  T.highcharts.shortmonths.october,
  '',
  '',
  '',
  '',
  T.highcharts.shortmonths.november,
  '',
  '',
  '',
  '',
  '',
  T.highcharts.shortmonths.december,
  '',
  '',
  '',
  ''
];

export const ectoplannerLongMonthNames = [
  T.highcharts.months.january,
  T.highcharts.months.february,
  T.highcharts.months.march,
  T.highcharts.months.april,
  T.highcharts.months.may,
  T.highcharts.months.june,
  T.highcharts.months.july,
  T.highcharts.months.august,
  T.highcharts.months.september,
  T.highcharts.months.october,
  T.highcharts.months.november,
  T.highcharts.months.december
];

export const ectoplannerDayNames: string[] = new Array(365).fill('');
export const ectoplannerHourNames: string[] = new Array(8760).fill('');

export const ectoplannerDurationCurveHourNames: string[] = new Array(
  HOURS_IN_YEAR
)
  .fill(0)
  .map((_unused, index) => (index + 1).toString());

let hourIndex = 0;

for (
  let month = 0, dayIndex = 0;
  month < ectoplannerMonthBins.length;
  month++
) {
  for (let day = 0; day < ectoplannerMonthBins[month]; day++, dayIndex++) {
    ectoplannerDayNames[dayIndex] =
      ectoplannerMonthNames[month] + ' ' + (day + 1);
    for (let hour = 0; hour < 24; hour++, hourIndex++) {
      ectoplannerHourNames[hourIndex] =
        ectoplannerMonthNames[month] +
        ' ' +
        (day + 1) +
        ' ' +
        hour.toString().padStart(2, '0') +
        ':00';
    }
  }
}

export const getEctoplannerHighchartsCategories = (
  zoomLevel: EctoplannerZoomLevel,
  graphType: BuildGraphType
) => {
  if (graphType === BuildGraphType.DurationCurve) {
    return ectoplannerDurationCurveHourNames;
  } else if (graphType === BuildGraphType.Scatter) {
    return ectoplannerHourNames;
  }

  switch (zoomLevel) {
    case EctoplannerZoomLevels.YEAR:
      return ectoplannerMonthNames;
    case EctoplannerZoomLevels.MONTH:
      return ectoplannerDayNames;
    case EctoplannerZoomLevels.DAY: {
      return ectoplannerHourNames;
    }
    default:
      return [''];
  }
};

export const EctoplannerGraphTypeOptions: GenericSelectOption<BuildGraphType>[] =
  enumValues(BuildGraphType).map((value) => ({
    label:
      T.ectoplanner.graphs.graphtypes[
        value.toLowerCase() as keyof typeof T.ectoplanner.graphs.graphtypes
      ],
    value: value as BuildGraphType
  }));

export const EctoplannerUnitMwh = 'MWh';
export const EctoplannerStandardGraphColors: Record<string, string> = {
  Heating: colors.heatingColor,
  Cooling: colors.coolingColor,
  Electricity: colors.electricityColor,
  Gas: colors.gasColor,
  AirTemp: colors.airTempColor,
  SolarIrradiance: colors.solarIrradianceColor
};
