import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import _ from 'lodash';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import T from 'ecto-common/lib/lang/Language';

import EctoplannerAPIGen, {
  WeatherCountryResponse,
  WeatherStationResponse,
  WeatherStationResponseListResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { EctoplannerFormEnvironment } from 'js/components/Ectoplanner/EctoplannerTypes';
import { CalculationForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import { NetworkSections } from 'js/components/Ectoplanner/EctoplannerModels';
import styles from './EditEctoplannerProject.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';

const getStationFilter = (countryCode: string) => {
  return "countryCode eq '" + countryCode + "'";
};

const stationToOption = (
  station: WeatherStationResponse
): GenericSelectOption<string> => ({
  label: station.city,
  value: station.id
});

const WeatherStationIdKey = 'weatherStationId';

interface EditEctoplannerLocationProps {
  form: CalculationForm;
  setFormFromUserInput: Dispatch<SetStateAction<CalculationForm>>;
  weatherCountries: WeatherCountryResponse[];
  footerText?: string;
  disableNetworkParams?: boolean;
}

type EctoplannnerLocation = {
  countryCode: string;
  weatherStationId: string;
  action?: string;
};

const EditEctoplannerLocation = ({
  form,
  setFormFromUserInput,
  weatherCountries,
  disableNetworkParams = false,
  footerText = null
}: EditEctoplannerLocationProps) => {
  const stationLookup = useRef<Record<string, WeatherStationResponse>>({});
  const { contextSettings } = useContext(TenantContext);
  const onUpdateMainForm = useOnUpdateFormInput(setFormFromUserInput);
  const [showingNetworkParams, showNetworkParams, hideNetworkParams] =
    useDialogState('edit-network-params');

  const [location, setLocation] = useState<EctoplannnerLocation>({
    countryCode: weatherCountries.find(
      (weatherCountry) =>
        weatherCountry.countryName === form.location?.country?.name
    )?.countryCode,
    weatherStationId: form.location?.city?.id
  });

  if (
    location.weatherStationId !== form.location?.city?.id &&
    form.location?.city?.id != null
  ) {
    setLocation({
      countryCode: weatherCountries.find(
        (weatherCountry) =>
          weatherCountry.countryName === form.location?.country?.name
      )?.countryCode,
      weatherStationId: form.location?.city?.id
    });
  }

  const countryOptions = useMemo(() => {
    return _.map(weatherCountries, (country) => ({
      value: country.countryCode,
      label: country.countryName
    }));
  }, [weatherCountries]);

  const loadWeatherStations = useCallback(
    (
      search: string,
      loadedOptions: GenericSelectOption<string>[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      additional: any
    ) => {
      if (location.countryCode == null) {
        return Promise.resolve({
          options: [],
          hasMore: false
        });
      }

      const query = {
        $orderBy: 'city',
        $top: 1000,
        continuationToken: additional?.continuationToken,
        $filter: getStationFilter(location.countryCode),
        $search: undefined as string
      };

      if (!isNullOrWhitespace(search)) {
        query.$search = search;
      }

      return EctoplannerAPIGen.Weather.stationsList
        .promise(contextSettings, query, null)
        .then((res: WeatherStationResponseListResponse) => {
          _.forEach(res.items, (station) => {
            stationLookup.current[station.id] = station;
          });

          let options = _.map(res.items, stationToOption);
          const allOptions = _.concat(options, loadedOptions);

          if (
            location.weatherStationId != null &&
            _.find(allOptions, { value: location.weatherStationId }) == null
          ) {
            const fullStation =
              stationLookup.current[location.weatherStationId];
            options = [stationToOption(fullStation), ...options];
          }

          const { continuationToken } = res;

          return Promise.resolve({
            options,
            hasMore: continuationToken != null,
            additional: {
              continuationToken
            }
          });
        });
    },
    [contextSettings, location.countryCode, location.weatherStationId]
  );

  const sections: ModelFormSectionType<
    EctoplannnerLocation,
    EctoplannerFormEnvironment
  >[] = useMemo(() => {
    return [
      {
        label: T.ectoplanner.location.title,
        wrapContent: true,
        lines: _.compact([
          {
            models: [
              {
                key: (input) => input.countryCode,
                modelType: ModelType.OPTIONS,
                label: T.ectoplanner.location.country,
                hasError: _.isNil,
                isHorizontal: true,
                placeholder: T.ectoplanner.location.country,
                options: countryOptions,
                isLoading: countryOptions.length === 0,
                onDidUpdate: () => {
                  return [[(input) => input.weatherStationId, null]];
                }
              }
            ]
          },
          {
            models: [
              {
                key: (input) => input.weatherStationId,
                modelType: ModelType.OPTIONS,
                label: T.ectoplanner.location.city,
                placeholder: T.ectoplanner.location.city,
                hasError: _.isNil,
                isHorizontal: true,
                paging: {
                  loadOptions: loadWeatherStations
                },
                helpText: footerText
              }
            ]
          },
          !disableNetworkParams && {
            models: [
              {
                key: (input) => input,
                modelType: ModelType.BUTTON,
                label: T.ectoplanner.form.network.buttontitle,
                icon: <Icons.Network />,
                onAction: showNetworkParams
              }
            ]
          }
        ])
      }
    ];
  }, [
    footerText,
    countryOptions,
    loadWeatherStations,
    disableNetworkParams,
    showNetworkParams
  ]);

  const onUpdateInput = useOnUpdateFormInput(setLocation);

  const _onUpdateInput = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: string[], value: any) => {
      onUpdateInput(key, value);

      if (key[0] === WeatherStationIdKey) {
        const fullStation = stationLookup.current[value];
        const country = weatherCountries.find(
          (item) => item.countryCode === fullStation?.countryCode
        );

        setFormFromUserInput((oldForm: CalculationForm) => ({
          ...oldForm,
          location: {
            city: {
              name: fullStation?.city,
              id: fullStation?.id,
              file: fullStation?.epwFilename
            },
            country: {
              name: country?.countryName ?? oldForm.location?.country?.name
            }
          }
        }));
      }
    },
    [onUpdateInput, setFormFromUserInput, weatherCountries]
  );

  return (
    <>
      <ModelForm
        sections={sections}
        input={location}
        onUpdateInput={_onUpdateInput}
        sectionClassName={styles.buildingSectionItem}
      />
      <ActionModal
        isOpen={showingNetworkParams}
        onModalClose={hideNetworkParams}
        title={T.ectoplanner.form.network.title}
        onConfirmClick={hideNetworkParams}
        headerIcon={Icons.Network}
        className={styles.editBuildingModal}
      >
        <ModelForm
          input={form}
          sections={NetworkSections}
          onUpdateInput={onUpdateMainForm}
        />
      </ActionModal>
    </>
  );
};

export default React.memo(EditEctoplannerLocation);
