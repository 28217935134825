import {
  SecosimForm,
  EctoplannerForm,
  EctoplannerFormBuilding
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import _ from 'lodash';
import { getPathFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import { CalculationResult } from 'js/components/Ectoplanner/Calculation/EctoplannerCalculator';
import { ectoplannerCityDataKeys } from 'js/components/Ectoplanner/EctoplannerModels';
import { updateTotalDemandForBuilding } from 'js/components/Ectoplanner/EditEctoplannerBuildings';
import defaultForm from './assets/defaultForm.json';

import defaultFormBuilding from './assets/defaultFormBuilding.json';
import EctoplannerAPIGen from 'ecto-common/lib/API/EctoplannerAPIGen';
import { ApiContextSettings } from 'ecto-common/lib/API/APIUtils';

const getGasPrice = (
  economicParameters: EctoplannerForm['economicParameters']
) => {
  // Get required variables from input fields
  const spot_gas_price_t0 = economicParameters.price_ex_gas;
  let spot_gas_price_growth_rate = economicParameters.price_growth_gas / 100;
  const grid_surcharge_gas_t0 = economicParameters.price_grid_gas;
  const grid_surcharge_growth_gas = economicParameters.grid_growth_gas / 100;
  const tax_rate = economicParameters.tax_rate / 100;
  let interest_rate = economicParameters.interest_rate / 100;
  const lifetime = economicParameters.project_lifetime;

  // In case interest and growth rate are equal, slightly increase the growth rate to avoid division by zero
  if (spot_gas_price_growth_rate === interest_rate) {
    spot_gas_price_growth_rate += Number.EPSILON;
  }

  // Calculate Rentenbarwertfaktor
  const RBF =
    ((1 + interest_rate) ** lifetime - 1) /
    ((1 + interest_rate) ** lifetime * interest_rate);

  // Calculate NPV of the gas price
  const annualized_price_growth =
    (1 + spot_gas_price_growth_rate) *
    ((1 -
      ((1 + spot_gas_price_growth_rate) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - spot_gas_price_growth_rate));

  if (interest_rate === grid_surcharge_growth_gas) {
    interest_rate += Number.EPSILON;
  }

  const annualized_grid_surcharge_growth_gas =
    (1 + grid_surcharge_growth_gas) *
    ((1 - ((1 + grid_surcharge_growth_gas) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - grid_surcharge_growth_gas));

  const gas_price_npv =
    (1 + tax_rate) *
    (spot_gas_price_t0 * annualized_price_growth +
      annualized_grid_surcharge_growth_gas * grid_surcharge_gas_t0);

  // Annualize the gas price
  let gas_price_ann = gas_price_npv / RBF;

  // Round gas price to 2 decimal places
  gas_price_ann = Math.round((gas_price_ann + Number.EPSILON) * 100) / 100;

  return gas_price_ann;
};

const getElectricityPrice = (
  economicParameters: EctoplannerForm['economicParameters']
) => {
  // Get required variables from input fields
  const spot_el_price_t0 = economicParameters.price_ex_el;
  let spot_el_price_growth_rate = economicParameters.price_growth_el / 100;
  const grid_surcharge_el_t0 = economicParameters.price_grid_el;
  const grid_surcharge_growth_el = economicParameters.grid_growth_el / 100;
  const environmental_surcharge = economicParameters.env_surcharge;
  const other_surcharges = economicParameters.oth_surcharge;
  const tax_rate = economicParameters.tax_rate / 100;
  let interest_rate = economicParameters.interest_rate / 100;
  const lifetime = economicParameters.project_lifetime;

  // In case interest and growth rate are equal, slightly increase the growth rate to avoid division by zero
  if (spot_el_price_growth_rate === interest_rate) {
    spot_el_price_growth_rate += Number.EPSILON;
  }
  // Calculate Rentenbarwertfaktor
  const RBF =
    ((1 + interest_rate) ** lifetime - 1) /
    ((1 + interest_rate) ** lifetime * interest_rate);

  // Calculate NPV of the electricity price
  const annualized_price_growth =
    (1 + spot_el_price_growth_rate) *
    ((1 - ((1 + spot_el_price_growth_rate) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - spot_el_price_growth_rate));

  if (interest_rate === grid_surcharge_growth_el) {
    interest_rate += Number.EPSILON;
  }

  const annualized_grid_surcharge_growth_el =
    (1 + grid_surcharge_growth_el) *
    ((1 - ((1 + grid_surcharge_growth_el) / (1 + interest_rate)) ** lifetime) /
      (interest_rate - grid_surcharge_growth_el));

  const el_price_npv =
    (1 + tax_rate) *
    (spot_el_price_t0 * annualized_price_growth +
      annualized_grid_surcharge_growth_el * grid_surcharge_el_t0 +
      RBF * environmental_surcharge +
      RBF * other_surcharges);

  // Annualize the electricity price
  let el_price_ann = el_price_npv / RBF;

  // Round the electricity price to 2 decimal places
  el_price_ann = Math.round((el_price_ann + Number.EPSILON) * 100) / 100;

  return el_price_ann;
};

type EctoplannerPatch = [
  key: (path: EctoplannerForm) => unknown,
  value: unknown
];

const parametersWithChanges = (
  form: EctoplannerForm,
  changes: EctoplannerPatch[]
): EctoplannerForm['economicParameters'] => {
  const tempForm = _.cloneDeep(form.economicParameters);

  for (const [key, value] of changes) {
    const keyPath = getPathFromModelKeyFunc(key);

    // Slice to remove 'economicParameters' from path
    _.set(tempForm, keyPath.slice(1), value);
  }

  return tempForm;
};

export const updateGasAndElectricityPriceWithChanges = (
  changes: EctoplannerPatch[],
  form: EctoplannerForm
): EctoplannerPatch[] => {
  const tempForm = parametersWithChanges(form, changes);

  return [
    ...changes,
    [(input) => input.energyCosts.price_gas, getGasPrice(tempForm)],
    [(input) => input.energyCosts.price_el, getElectricityPrice(tempForm)]
  ];
};

export const updateGasPriceWithChanges = (
  changes: EctoplannerPatch[],
  form: EctoplannerForm
): EctoplannerPatch[] => {
  return [
    ...changes,
    [
      (input) => input.energyCosts.price_gas,
      getGasPrice(parametersWithChanges(form, changes))
    ]
  ];
};

export const updateElectricityPriceWithChanges = (
  changes: EctoplannerPatch[],
  form: EctoplannerForm
): EctoplannerPatch[] => {
  return [
    ...changes,
    [
      (input) => input.energyCosts.price_el,
      getElectricityPrice(parametersWithChanges(form, changes))
    ]
  ];
};

let calculationIdCounter = 0;

type NproPromiseInput = {
  inputForm: EctoplannerForm;
  cityData: object;
  airTemp: string;
  calculationIndex: number;
  buildDescription?: string;
};

export const citiesAndAirTempPromise = ({
  contextSettings,
  signal,
  weatherStationId
}: {
  contextSettings: ApiContextSettings;
  signal: AbortSignal;
  weatherStationId: string;
}) => {
  return Promise.all([
    EctoplannerAPIGen.Weather.airTempDetail.promise(
      contextSettings,
      { weatherStationId },
      signal
    ),
    EctoplannerAPIGen.Weather.cityDataDetail.promise(
      contextSettings,
      { weatherStationId },
      signal
    )
  ] as const);
};

export const compileFormPromise = ({
  inputForm,
  cityData,
  airTemp,
  calculationIndex
}: NproPromiseInput): Promise<CalculationResult> => {
  const worker = new Worker(
    new URL('./Calculation/EctoplannerCalculationWorker.ts', import.meta.url)
  );
  const idToUse = calculationIdCounter++;

  const promise = new Promise<CalculationResult>((resolve, reject) => {
    worker.onmessage = ({ data: { form, checksum, id, error } }) => {
      if (id === idToUse) {
        if (error != null) {
          reject(error);
        } else {
          resolve({
            form: form as unknown as EctoplannerForm,
            checksum: checksum as string,
            calculationIndex
          });
        }
      }
    };
  });

  worker.postMessage({
    form: inputForm,
    id: idToUse,
    cityData,
    airTemp
  });

  return promise;
};

export function insertSecosimDefaultValues(
  formJson: SecosimForm,
  defaultSecosimForm: SecosimForm
) {
  if (formJson == null) {
    return [null, false] as const;
  }

  if (_.isEmpty(formJson)) {
    return [_.cloneDeep(defaultSecosimForm), true] as const;
  }

  // Make sure new default sections are available for older projects
  const clone = _.merge({}, defaultSecosimForm, formJson);
  const hasChanges = !_.isEqual(clone, formJson);

  return [clone, hasChanges] as const;
}

export function insertEctoplannerDefaultValues(formJson: EctoplannerForm) {
  if (formJson == null) {
    return [null, false] as const;
  }

  if (_.isEmpty(formJson)) {
    // TODO: Type casting due to JSON import. Perhaps there is a better way?
    return [
      _.cloneDeep(defaultForm as unknown as EctoplannerForm),
      true
    ] as const;
  }

  const defaultFormBuildingTyped =
    defaultFormBuilding as EctoplannerFormBuilding;

  // Make sure new default sections are available for older projects
  const clone = _.cloneDeep({
    ..._.merge({}, defaultForm, formJson),
    buildings: formJson.buildings
  });

  // Force insulation to be set to 'insulated' for all networks for now,
  // only option we support.
  clone.network.netwInsulation = 'insulated';

  for (const building of clone.buildings) {
    if (building.params == null) {
      building.params = _.cloneDeep(defaultFormBuildingTyped.params);
    } else {
      building.params = _.merge(
        {},
        _.cloneDeep(defaultFormBuildingTyped.params),
        building.params
      );
    }
  }

  const hasChanges = !_.isEqual(clone, formJson);

  return [clone, hasChanges] as const;
}

export function migrateEctoplannerBuildings(
  buildings: EctoplannerFormBuilding[],
  cityData: object
): EctoplannerFormBuilding[] {
  return _.map(buildings, (building) => {
    // If we do not have city data for the building type - can happen when we change to a new country with
    // different building types - we should reset the building type and subtype
    if (
      // @ts-expect-error
      cityData[building.params?.buildingType ?? ''] == null ||
      // @ts-expect-error
      cityData[building.params.buildingType][
        building.params.buildingSubtype ?? ''
      ] == null
    ) {
      return {
        ...building,
        params: {
          ...building.params,
          buildingType: null,
          buildingSubtype: null
        }
      };
    }

    // Update the building with demand figures from the new city data
    const ret: EctoplannerFormBuilding = {
      ...building,
      params: { ...building.params }
    };

    for (const key of ectoplannerCityDataKeys) {
      _.set(
        ret.params,
        key,
        // @ts-expect-error
        cityData[building.params.buildingType][building.params.buildingSubtype][
          key
        ]
      );
    }

    updateTotalDemandForBuilding(ret.params);
    return ret;
  });
}
