import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import T from 'ecto-common/lib/lang/Language';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';

import ConfirmExportSignalsDialog from './ConfirmExportSignalsDialog';
import styles from './ExportDataPage.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import Button from 'ecto-common/lib/Button/Button';
import SelectSignalCollectionDialog from 'ecto-common/lib/SelectSignalCollectionDialog/SelectSignalCollectionDialog';
import { GraphCollectionType } from 'ecto-common/lib/types/EctoCommonTypes';
import ExportOptions, {
  defaultExportOptions,
  ExportOptionsType
} from 'js/components/ExportData/ExportOptions';
import ExportDataSignals from 'js/components/ExportData/ExportDataSignals';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';

const ExportDataPage = () => {
  const [isConfirmDialogVisible, showConfirmDialog, hideConfirmDialog] =
    useSimpleDialogState();
  const [
    signalCollectionDialogVisible,
    showSignalCollectionDialog,
    hideSignalCollectionDialog
  ] = useDialogState('show-select-signal-collection');
  const [options, setOptions] = useState<ExportOptionsType>(
    _.cloneDeep(defaultExportOptions())
  );
  const hasValidData = !_.isEmpty(options.selectedSignals);

  useEffect(() => {
    document.title = T.exportpage.title;
  }, []);

  const toolbarItems = [
    <ToolbarFlexibleSpace key="space" />,
    <ToolbarItem key="loadfromcollection">
      <Button onClick={showSignalCollectionDialog}>
        <Icons.Signal />
        {T.exportpage.loadfromcollection}
      </Button>
    </ToolbarItem>,
    <ToolbarItem key="export">
      <Button onClick={showConfirmDialog} disabled={!hasValidData}>
        <Icons.Download />
        {T.exportpage.exportdata}
      </Button>
    </ToolbarItem>
  ];

  const onSignalCollectionSelected = (collection: GraphCollectionType) => {
    // In the graph collection the same signal can appear multiple times with
    // different aggregation settings. Here we have one aggregation setting for
    // the whole export, so make sure we only include each signal once.
    setOptions((oldOptions) => ({
      ...oldOptions,
      selectedSignals: _.uniqBy(
        collection.signals,
        (signal) => signal.item.signalId
      )
    }));
    hideSignalCollectionDialog();
  };

  return (
    <ToolbarContentPage
      title={T.exportpage.title}
      toolbarItems={toolbarItems}
      showLocationPicker={false}
      className={styles.box}
      helpPath={HelpPaths.docs.operator.export_data}
    >
      <div className={styles.optionSection}>
        <div className={styles.optionContainer}>
          <KeyValueLine>
            <ExportOptions options={options} setOptions={setOptions} />
          </KeyValueLine>
        </div>
      </div>

      <div className={styles.signalsContainer}>
        <ExportDataSignals options={options} setOptions={setOptions} />
      </div>

      <ConfirmExportSignalsDialog
        data={options}
        isOpen={isConfirmDialogVisible}
        onModalClose={hideConfirmDialog}
      />
      <SelectSignalCollectionDialog
        isOpen={signalCollectionDialogVisible}
        onModalClose={hideSignalCollectionDialog}
        onCollectionSelected={onSignalCollectionSelected}
      />
    </ToolbarContentPage>
  );
};

export default ExportDataPage;
