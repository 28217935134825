import React, { Dispatch, Fragment, SetStateAction } from 'react';
import TableSearchInput from './TableSearchInput';

import styles from './SearchableTable.module.css';
import DataTable, { DataTableProps } from 'ecto-common/lib/DataTable/DataTable';
import { typedMemo } from 'ecto-common/lib/utils/typescriptUtils';

type SearchableTableProps<ObjectType> = DataTableProps<ObjectType> & {
  searchPlaceholder?: string;
  searchString?: string;
  onSearch: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
};

function SearchableTable<ObjectType extends object>({
  searchPlaceholder,
  searchString,
  disabled = false,
  onSearch,
  ...tableProps
}: SearchableTableProps<ObjectType>) {
  return (
    <Fragment>
      <TableSearchInput
        searchPlaceholder={searchPlaceholder}
        searchString={searchString}
        disabled={disabled}
        onSearch={onSearch}
      />
      <div className={styles.tableContainer}>
        <DataTable<ObjectType>
          className={styles.staticTable}
          useAllAvailableHeight
          {...tableProps}
        />
      </div>
    </Fragment>
  );
}

export default typedMemo(SearchableTable);
