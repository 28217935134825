import { EmptySignalType } from 'ecto-common/lib/utils/constants';
import {
  SignalProviderSignalResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';

/**
 * Unlike other places where we sometimes do not show unit when it's Binary/None,
 * we would like to always show the unit when presenting signal types for the
 * sake of consistency.
 */
export const formatSignalTypeUnit = (unit: string) => {
  if (unit == null) {
    return '';
  }

  return ' (' + unit + ')';
};

type SignalTypeMap = Record<string, SignalTypeResponseModel>;
type SignalUnitTypesMap = Record<string, UnitResponseModel>;

export const getSignalNameWithSignalType = (
  signal: SignalProviderSignalResponseModel,
  showSignalType: boolean,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>
) => {
  const signalType = signalTypesMap[signal.signalTypeId];
  const signalTypeName = signalType?.name ?? '';
  const unit = signalUnitTypesMap[signalType?.unitId];

  const unitSymbol = unit?.unit ?? '';

  if (showSignalType) {
    return (
      signalTypeName + ' - ' + signal.name + formatSignalTypeUnit(unitSymbol)
    );
  }

  return signal.name + formatSignalTypeUnit(unitSymbol);
};

export const getSignalTypeName = (
  signalTypeId: string,
  signalTypesMap: SignalTypeMap
) => {
  const signalType = signalTypesMap[signalTypeId] ?? EmptySignalType;
  return signalType.name;
};

export const getSignalTypeNameWithUnit = (
  signalType: SignalTypeResponseModel,
  unit: UnitResponseModel
) => {
  const unitSymbol = unit?.unit ?? '';
  return signalType.name + formatSignalTypeUnit(unitSymbol);
};

export const getSignalTypeNameWithUnitFromMap = (
  signalTypeId: string,
  signalTypesMap: SignalTypeMap,
  signalUnitTypesMap: SignalUnitTypesMap
): string => {
  const signalType = signalTypesMap[signalTypeId] ?? EmptySignalType;
  const unit = signalUnitTypesMap[signalType?.unitId];

  return getSignalTypeNameWithUnit(signalType, unit);
};

export const getSignalTypeUnitObject = (
  signalTypeId: string,
  signalTypesMap: SignalTypeMap,
  signalUnitTypesMap: SignalUnitTypesMap
): UnitResponseModel => {
  const signalType = signalTypesMap[signalTypeId] ?? EmptySignalType;
  return signalUnitTypesMap[signalType.unitId];
};

export const getSignalTypeUnit = (
  signalTypeId: string,
  signalTypesMap: SignalTypeMap,
  signalUnitTypesMap: SignalUnitTypesMap
): string => {
  return getSignalTypeUnitObject(
    signalTypeId,
    signalTypesMap,
    signalUnitTypesMap
  ).unit;
};

export const getSignalTypeUnitSuffix = (
  unitId: string,
  signalUnitTypesMap: SignalUnitTypesMap
) => formatSignalTypeUnit(signalUnitTypesMap[unitId]?.unit);
