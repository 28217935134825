import React, { useContext, useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { GraphCollectionType } from 'ecto-common/lib/types/EctoCommonTypes';
import { useQuery } from '@tanstack/react-query';
import { getSignalCollectionsPromise } from 'ecto-common/lib/SignalSelector/ChartUtils';
import T from 'ecto-common/lib/lang/Language';
import { matchSorter } from 'match-sorter';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';

type SelectSignalCollectionDialogProps = {
  isOpen: boolean;
  onCollectionSelected: (collection: GraphCollectionType) => void;
  onModalClose: () => void;
};

const columns: DataTableColumnProps<GraphCollectionType>[] = [
  {
    dataKey: 'name',
    linkColumn: true,
    label: T.selectsignalcollectiondialog.tableheader,
    dataFormatter: (name: string) => {
      return (
        <>
          <Icons.Signal /> {name}{' '}
        </>
      );
    }
  }
];

const SelectSignalCollectionDialog = ({
  isOpen,
  onModalClose,
  onCollectionSelected
}: SelectSignalCollectionDialogProps) => {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const { contextSettings } = useContext(TenantContext);

  const { data, isLoading } = useQuery({
    queryKey: ['graph-user-collections'],
    queryFn: ({ signal }) =>
      getSignalCollectionsPromise(contextSettings, signal, false),
    enabled: isOpen
  });

  useEffect(() => {
    setSearchFilter('');
  }, [isOpen]);

  const filteredData = useMemo(() => {
    if (isNullOrWhitespace(searchFilter) || data == null) {
      return data;
    }

    return matchSorter<GraphCollectionType>(data, searchFilter, {
      keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }]
    });
  }, [data, searchFilter]);

  return (
    <ActionModal
      onModalClose={onModalClose}
      disableCancel
      onConfirmClick={onModalClose}
      isOpen={isOpen}
      title={T.selectsignalcollectiondialog.title}
      headerIcon={Icons.Edit}
      actionText={T.common.done}
      searchText={searchFilter}
      withSearchField
      onSearchTextChanged={setSearchFilter}
    >
      <DataTable<GraphCollectionType>
        columns={columns}
        data={filteredData}
        isLoading={isLoading}
        onClickRow={onCollectionSelected}
        minHeight={250}
      />
    </ActionModal>
  );
};

export default React.memo(SelectSignalCollectionDialog);
