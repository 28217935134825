import React, { useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';
import { components } from 'react-select';
import { Moment } from 'moment';

import T from 'ecto-common/lib/lang/Language';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import dimensions from 'ecto-common/lib/styles/dimensions';
import Icons from 'ecto-common/lib/Icons/Icons';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarDateTimeRangePicker from 'ecto-common/lib/ToolbarDateTimePicker/ToolbarDateTimeRangePicker';

import styles from 'js/containers/GraphsPage.module.css';
import IntervalSelector from 'js/components/OperatorChart/IntervalSelector';
import GraphToolbarMenu from 'js/containers/GraphComponents/GraphToolbarMenu';
import { getSelectedSignalCollection } from 'js/containers/GraphComponents/GraphsUtils';
import { SignalCollectionActions } from 'js/modules/signalCollections/signalCollections';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import {
  GraphCollectionType,
  GraphType,
  SeriesInterval
} from 'ecto-common/lib/types/EctoCommonTypes';
import { TelemetryZoomRange } from '../../modules/signalCollections/signalCollections';
import { ChartZoomSettings } from 'ecto-common/lib/SignalSelector/ChartUtils';

interface GraphToolbarItemsProps {
  showExport(): void;
  showMinMaxSettings(): void;
  setSeriesInterval: (newInterval: SeriesInterval) => void;
  canClearInterval: boolean;
  zoomSettings: ChartZoomSettings;
  setZoomSettings: Dispatch<SetStateAction<ChartZoomSettings>>;
  zoomRange: TelemetryZoomRange;
  setCustomZoomRange: (zoomRange: TelemetryZoomRange) => void;
  showSignalSelector(): void;
  dataAnalyticsEnabled: boolean;
  setDataAnalyticsEnabled: Dispatch<SetStateAction<boolean>>;
  graphType: GraphType;
}

const GraphToolbarItems = ({
  showExport,
  showMinMaxSettings,
  setSeriesInterval,
  canClearInterval,
  zoomSettings: autoUpdateEnabled,
  setZoomSettings: setAutoUpdateEnabled,
  zoomRange,
  setCustomZoomRange,
  showSignalSelector,
  dataAnalyticsEnabled,
  setDataAnalyticsEnabled,
  graphType
}: GraphToolbarItemsProps) => {
  const selectedSignalCollection = useOperatorSelector((state) =>
    getSelectedSignalCollection(state.signalCollections)
  );
  const changedSignalCollections = useOperatorSelector(
    (state) => state.signalCollections.changedSignalCollections
  );
  const isSavingCollections = useOperatorSelector(
    (state) => state.signalCollections.isSavingCollections
  );
  const tempCollections = useOperatorSelector(
    (state) => state.signalCollections.tempCollections
  );

  const signalCollections = useOperatorSelector(
    (state) => state.signalCollections.signalCollections
  );
  const dispatch = useOperatorDispatch();
  const signalCollectionActions = useMemo(
    () => bindActionCreators(SignalCollectionActions, dispatch),
    [dispatch]
  );

  const dateFrom = zoomRange?.dateFrom;
  const dateTo = zoomRange?.dateTo;

  const collectionLabel = useCallback(
    (collection: GraphCollectionType) => {
      const hasUnsavedChanges =
        changedSignalCollections[collection.id] === true;
      let name: React.ReactNode = collection.name;

      if (hasUnsavedChanges) {
        name = (
          <>
            {name}
            <label className={styles.unsavedLabel}>
              &nbsp;{T.graphs.unsavedsuffix}
            </label>
          </>
        );
      } else if (tempCollections[collection.id]) {
        name = (
          <>
            {name}
            <label className={styles.unsavedLabel}>
              &nbsp;{T.graphs.tempsuffix}
            </label>
          </>
        );
      }

      return name;
    },
    [changedSignalCollections, tempCollections]
  );

  const mapCollectionToOption = (
    collection: GraphCollectionType
  ): GenericSelectOption<GraphCollectionType> => ({
    label: collection.name,
    value: collection
  });

  const signalCollectionOptions = signalCollections.map(mapCollectionToOption);
  const selectedCollectionOption = !selectedSignalCollection
    ? null
    : mapCollectionToOption(selectedSignalCollection);

  // eslint-disable-next-line react/prop-types
  const SingleValue = ({
    children: _children,
    ...props
  }: {
    children: React.ReactNode;
  }) => (
    // @ts-ignore-next-line
    <components.SingleValue {...props}>
      <label className={styles.label}>{T.graphs.signalcollection}</label>{' '}
      {collectionLabel(selectedSignalCollection)}
    </components.SingleValue>
  );

  const _setDateTo = useCallback(
    (_dateTo: Moment) => {
      setCustomZoomRange({
        dateFrom: zoomRange?.dateFrom,
        dateTo: _dateTo.startOf('day').valueOf(),
        seriesInterval: null
      });
    },
    [setCustomZoomRange, zoomRange?.dateFrom]
  );

  const _setDateFrom = useCallback(
    (_dateFrom: Moment) => {
      setCustomZoomRange({
        dateFrom: _dateFrom.startOf('day').valueOf(),
        dateTo: zoomRange?.dateTo,
        seriesInterval: null
      });
    },
    [setCustomZoomRange, zoomRange?.dateTo]
  );

  const formatOptionLabel = useCallback(
    (option: GenericSelectOption<GraphCollectionType>) => {
      const collection = option.value;
      return (
        <div>
          <Icons.Graph /> {collectionLabel(collection)}
        </div>
      );
    },
    [collectionLabel]
  );

  return (
    <>
      <ToolbarItem key="graph-selector">
        <Select<GenericSelectOption<GraphCollectionType>, false>
          isLoading={isSavingCollections}
          className={styles.smallSelector}
          components={{ SingleValue }}
          additionalStyles={{
            input: (provided) => ({
              ...provided,
              marginLeft: dimensions.insetMargin
            })
          }}
          formatOptionLabel={formatOptionLabel}
          onChange={(e) =>
            signalCollectionActions.changeSignalCollection(e.value.id)
          }
          value={selectedCollectionOption}
          options={signalCollectionOptions}
        />
      </ToolbarItem>

      <ToolbarItem key="toolbar-menu">
        <GraphToolbarMenu
          showExport={showExport}
          showMinMaxSettings={showMinMaxSettings}
          showSignalSelector={showSignalSelector}
          dataAnalyticsEnabled={dataAnalyticsEnabled}
          setDataAnalyticsEnabled={setDataAnalyticsEnabled}
          graphType={graphType}
        />
      </ToolbarItem>

      <ToolbarFlexibleSpace className={styles.collapsingSpace} key="space" />

      <ToolbarItem key="date-menu">
        <ToolbarMenu>
          <ToolbarDateTimeRangePicker
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChangeFrom={_setDateFrom}
            onChangeTo={_setDateTo}
          />
        </ToolbarMenu>
      </ToolbarItem>

      <ToolbarItem key="interval-selector">
        <IntervalSelector
          seriesInterval={zoomRange?.seriesInterval}
          setSeriesInterval={setSeriesInterval}
          zoomSettings={autoUpdateEnabled}
          setZoomSettings={setAutoUpdateEnabled}
          isClearable={canClearInterval}
        />
      </ToolbarItem>
    </>
  );
};

export default React.memo(GraphToolbarItems);
