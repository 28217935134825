import React, { useMemo, memo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import styles from './PowerControl.module.css';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import ControlDispatch from 'js/components/EMPTools/PowerControl/Dispatch/ControlDispatch';
import {
  NodeV2ResponseModel,
  SignalGroupLiteResponseModel
} from 'ecto-common/lib/API/APIGen';

interface PowerControlProps {
  selectedNode: NodeV2ResponseModel;
  controls: SignalGroupLiteResponseModel[];
}

const PowerControl = ({ selectedNode, controls }: PowerControlProps) => {
  const powerControls = useMemo(
    () =>
      controls.map((control) => {
        if (!control.canSchedule) {
          // Functionality for manual control is disabled
          return null;
        }

        return (
          <div
            className={styles.powerControlSection}
            key={control.signalGroupId}
          >
            <ControlDispatch
              selectedPowerControl={control}
              selectedNode={selectedNode}
            />
          </div>
        );
      }),
    [controls, selectedNode]
  );

  return (
    <>
      {controls.length === 0 && (
        <NoDataMessage message={T.powercontrol.nodata} />
      )}

      {controls.length > 0 && powerControls}
    </>
  );
};

export default memo(PowerControl);
