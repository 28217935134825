export function downloadBlob(blob: Blob, name: string) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = name;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}

// Some file formats require us to add a utf-8 BOM to the beginning of the file
export function downloadBlobFromText(
  text: File | string,
  name = 'Ectocloud_Download.csv'
) {
  const blob = new Blob(['\ufeff', text], { type: 'octet/stream' });
  return downloadBlob(blob, name);
}

export function downloadBlobTextWithoutUTF8BOM(text: string, name: string) {
  const blob = new Blob([text], { type: 'octet/stream' });
  return downloadBlob(blob, name);
}
