import React from 'react';

interface ToolbarFixedSpaceProps {
  width?: number;
}

/**
 * Use this component to add a fixed width spacing between toolbar items.
 */
const ToolbarFixedSpace = ({ width }: ToolbarFixedSpaceProps) => {
  return <div style={{ width: width + 'px' }} />;
};

export default React.memo(ToolbarFixedSpace);
