import _ from 'lodash';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { RoleType } from 'ecto-common/lib/constants';
import { PageNavLink, PageNavLinkSubLink } from '../Page/Page';
import {
  ResourceModel,
  TenantUserModel,
  RoleModel
} from '../API/IdentityServiceAPIGenV2';
import { FeatureFlagState } from '../FeatureFlags/FeatureFlags';

export const hasAdminRole = (tenantRoles: RoleModel[]) =>
  _.find(tenantRoles, ['name', RoleType.ADMIN]) != null;

/**
 * Removes menu items based on user access
 * @returns {string[]} Menu items accessible by the user
 */
export const filterMenuByAccess = (
  links: PageNavLinkSubLink[],
  tenantResources: ResourceModel[],
  featureFlagState: FeatureFlagState
) => {
  return _.filter(links, (menuItem) => {
    return (
      (menuItem.featureFlag == null ||
        featureFlagState[menuItem.featureFlag]) &&
      (menuItem.resourceTypes == null ||
        _.every(menuItem.resourceTypes, (type) =>
          hasAccessToResource(type, tenantResources ?? [])
        )) &&
      (menuItem.hideIfHasResourceTypes == null ||
        _.every(
          menuItem.hideIfHasResourceTypes,
          (type) => !hasAccessToResource(type, tenantResources ?? [])
        ))
    );
  });
};

export const navLinksFilteredByTenantResources = (
  navLinks: PageNavLink[],
  tenantResources: ResourceModel[],
  featureFlagState: FeatureFlagState
) => {
  return _.map(navLinks, (menuItem) => ({
    ...menuItem,
    links: filterMenuByAccess(menuItem.links, tenantResources, featureFlagState)
  }));
};

export const isAdminInAnyTenant = (tenantUsers: TenantUserModel[]) =>
  _.find(tenantUsers, (tenantUser) => hasAdminRole(tenantUser.roles)) != null;
