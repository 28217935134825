import T from 'ecto-common/lib/lang/Language';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { DashboardResponseModel } from 'ecto-common/lib/API/APIGen';

export const InitialDashboard: DashboardResponseModel = {
  name: T.admin.dashboards.initialname,
  description: null,
  dashboardId: undefined
};

export const InitialDashboardFileData: DashboardFile = {
  version: 1,
  panels: []
};

export type DashboardFile = {
  version: number;
  panels: DashboardPanel[];
};

export type DashboardId = string;

export type DashboardData = {
  jsonData: DashboardFile;
  info: DashboardResponseModel;
};
