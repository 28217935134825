import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import APIGen, {
  NodeV2ResponseModel,
  NodePropertyResponseModel,
  NodePropertyValueResponseModel,
  NodeTraitResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import SegmentedContentView from 'ecto-common/lib/SegmentControl/SegmentedContentView';
import { useParams } from 'react-router-dom';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { getNodesV2Url } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import AddButton from 'ecto-common/lib/Button/AddButton';
import UUID from 'uuidjs';
import {
  keepPreviousData,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import TextInput from 'ecto-common/lib/TextInput/TextInput';

import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import NodeSearchTable from 'ecto-common/lib/Page/NodeSearchTable';

type NodesV2PageProps = {
  onTitleChanged: (newTitle: string[]) => void;
};

const NodesSearch = () => {
  const [nodeDetailId, setNodeDetailId] = useSearchParamState('node-id', null);

  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const [editNode, setEditNode] = useState<NodeV2ResponseModel | null>(null);

  const nodeSpecificQuery = APIGen.NodesV2.getNodesByIds.useQuery(
    {
      nodeIds: [nodeDetailId]
    },
    {
      enabled: !!nodeDetailId
    }
  );

  const getNodeTraitsForNodeQuery = APIGen.NodesV2.getNodeTraitsByIds.useQuery(
    {
      traitIds: editNode?.nodeTraitIds
    },
    {
      enabled: !!editNode?.nodeTraitIds,
      placeholderData: keepPreviousData
    }
  );

  const nodePropertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery({});

  const nodePropertyValuesQuery = APIGen.NodesV2.getNodePropertyValues.useQuery(
    {
      nodeId: editNode?.nodeId
    },
    {
      enabled: !!editNode
    }
  );

  const [nodePropertyValues, setNodePropertyValues] = useState<
    Record<string, string>
  >({});

  const allPropertyIds = useMemo(() => {
    return _.flatMap(
      getNodeTraitsForNodeQuery.data,
      (trait) => trait.propertyIds
    );
  }, [getNodeTraitsForNodeQuery.data]);

  const allProperties = useMemo(() => {
    return allPropertyIds.map((x) =>
      nodePropertiesQuery.data.find((y) => y.id === x)
    );
  }, [allPropertyIds, nodePropertiesQuery.data]);

  const propertyColumns: DataTableColumnProps<NodePropertyResponseModel>[] = [
    {
      dataKey: 'name',
      label: 'Property'
    },
    {
      dataKey: 'id',
      label: 'Value',
      dataFormatter: (id: string) => {
        const value =
          nodePropertyValues[id] ??
          nodePropertyValuesQuery.data?.find((x) => x.nodePropertyId === id)
            ?.value;
        return (
          <TextInput
            value={value}
            onChange={(e) => {
              setNodePropertyValues((oldValues) => {
                return {
                  ...oldValues,
                  [id]: e.target.value
                };
              });
            }}
          />
        );
      }
    }
  ];

  if (nodeSpecificQuery.data?.nodes?.length === 1 && editNode == null) {
    setEditNode(_.cloneDeep(nodeSpecificQuery.data?.nodes?.[0]));
  }

  const onClickRow = (node: NodeV2ResponseModel) => {
    setNodeDetailId(node.nodeId);
  };

  const nodeModels = useMemo<ModelDefinition<NodeV2ResponseModel>[]>(() => {
    return [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.street,
        label: 'Street',
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.nodeTraitIds,
        label: 'Node traits',
        modelType: ModelType.OPTIONS,
        isMultiOption: true,
        options: traitsQuery.data?.map((trait) => ({
          value: trait.id,
          label: trait.name
        }))
      }
    ];
  }, [traitsQuery.data]);

  const { contextSettings } = useContext(TenantContext);

  const queryClient = useQueryClient();

  const saveNodeAndProperties = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
      });

      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
      });
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodePropertyValues.path(contextSettings)
      });
    },
    mutationFn: async ({
      properties,
      node
    }: {
      properties: NodePropertyValueResponseModel[];
      node: NodeV2ResponseModel;
    }) => {
      await APIGen.NodesV2.addOrUpdateNodes.promise(
        contextSettings,
        { nodes: [node] },
        null
      );
      await APIGen.NodesV2.addOrUpdateNodePropertyValues.promise(
        contextSettings,
        {
          nodeId: node.nodeId,
          propertyValues: properties
        },
        null
      );
    }
  });

  const saveNode = useCallback(() => {
    const valueDtos: NodePropertyValueResponseModel[] = _.compact(
      _.map(allPropertyIds, (propertyId) => {
        const existingValue = _.find(
          nodePropertyValuesQuery.data,
          (x) => x.nodePropertyId === propertyId
        );

        const value = nodePropertyValues[propertyId] ?? existingValue.value;

        if (isNullOrWhitespace(value)) {
          return null;
        }

        if (existingValue) {
          return {
            ...existingValue,
            value
          };
        }

        return {
          id: UUID.generate(),
          nodeId: editNode.nodeId,
          nodePropertyId: propertyId,
          value
        };
      })
    );

    saveNodeAndProperties.mutate({
      properties: valueDtos,
      node: editNode
    });
  }, [
    allPropertyIds,
    editNode,
    nodePropertyValues,
    nodePropertyValuesQuery.data,
    saveNodeAndProperties
  ]);

  return (
    <>
      <NodeSearchTable onClickNode={onClickRow} />

      <ActionModal
        isOpen={!!nodeDetailId}
        onModalClose={() => {
          setNodePropertyValues({});
          setEditNode(null);
          setNodeDetailId(null);
        }}
        title="Node"
        cancelText={T.common.done}
        actionText={
          <>
            <Icons.Save /> {T.common.save}{' '}
          </>
        }
        onConfirmClick={saveNode}
        headerIcon={Icons.Edit}
        isLoading={
          nodeSpecificQuery.isLoading || saveNodeAndProperties.isPending
        }
      >
        <ModelForm
          input={editNode}
          setInput={setEditNode}
          models={nodeModels}
          disabled={editNode == null}
        />
        {nodePropertyValues && allProperties.length > 0 && (
          <DataTable data={allProperties} columns={propertyColumns} />
        )}
      </ActionModal>
    </>
  );
};

const propertyModels: ModelDefinition<NodePropertyResponseModel>[] = [
  {
    key: (input) => input.name,
    label: T.common.name,
    modelType: ModelType.TEXT,
    autoFocus: true
  },
  {
    key: (input) => input.description,
    label: T.common.description,
    modelType: ModelType.TEXT
  }
];

const NodeTraits = () => {
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const columns = useMemo<
    DataTableColumnProps<NodeTraitResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'name',
        label: T.common.name,
        linkColumn: true
      }
    ];
  }, []);

  const [traitDetailId, setTraitDetailId] = useSearchParamState(
    'trait-id',
    null
  );

  const propertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery();

  const propertyOptions = useMemo(() => {
    return _.map(propertiesQuery.data, (property) => {
      return {
        label: property.name,
        value: property.id
      };
    });
  }, [propertiesQuery.data]);

  const onClickRow = (trait: NodeTraitResponseModel) => {
    setTraitDetailId(trait.id);
  };

  const specificTraitQuery = APIGen.NodesV2.getNodeTraitsByIds.useQuery(
    {
      traitIds: [traitDetailId]
    },
    {
      enabled: !!traitDetailId
    }
  );

  const [editTrait, setEditTrait] = useState<NodeTraitResponseModel | null>(
    null
  );
  const specificTrait = _.head(specificTraitQuery.data);

  if (editTrait == null && specificTrait != null) {
    setEditTrait(_.cloneDeep(specificTrait));
  }
  const isEditingTrait = editTrait?.id === specificTrait?.id;

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);
  const saveMutation = APIGen.NodesV2.addOrUpdateNodeTraits.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodeTraitsByIds.path(contextSettings)
      });
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
      });

      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
      });

      if (!isEditingTrait) {
        setEditTrait(null);
      }
    }
  });
  const save = useCallback(() => {
    saveMutation.mutate({
      nodeTraits: [editTrait]
    });
  }, [editTrait, saveMutation]);

  const addTrait = useCallback(() => {
    setEditTrait({
      id: UUID.generate(),
      name: 'New trait',
      isGlobal: false,
      isLocked: false,
      propertyIds: []
    });
  }, []);

  const traitModels: ModelDefinition<NodeTraitResponseModel>[] = useMemo(
    () => [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.isGlobal,
        label: 'Is global',
        modelType: ModelType.BOOL
      },
      {
        key: (input) => input.isLocked,
        label: 'Is locked',
        modelType: ModelType.BOOL
      },
      {
        key: (input) => input.propertyIds,
        label: 'Properties',
        modelType: ModelType.OPTIONS,
        isMultiOption: true,
        options: propertyOptions,
        isHorizontal: true
      }
    ],
    [propertyOptions]
  );

  return (
    <>
      <DataTable
        columns={columns}
        data={traitsQuery.data || []}
        isLoading={traitsQuery.isLoading}
        onClickRow={onClickRow}
      />
      <DataTableFooter alignRight>
        <AddButton onClick={addTrait}>Add trait</AddButton>
      </DataTableFooter>

      <ActionModal
        isOpen={traitDetailId != null || editTrait != null}
        onModalClose={() => {
          setEditTrait(null);
          setTraitDetailId(null);
        }}
        title={isEditingTrait ? 'Edit trait' : 'Add trait'}
        onConfirmClick={save}
        cancelText={T.common.done}
        headerIcon={Icons.Edit}
        isLoading={specificTraitQuery.isLoading || saveMutation.isPending}
        actionText={
          <>
            <Icons.Save />
            {T.common.save}
          </>
        }
      >
        {editTrait && (
          <>
            <ModelForm
              input={editTrait}
              setInput={setEditTrait}
              models={traitModels}
            />
          </>
        )}
      </ActionModal>
    </>
  );
};

const NodeProperties = () => {
  const propertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery();

  const propertiesColumns = useMemo<
    DataTableColumnProps<NodePropertyResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'name',
        label: 'Property name',
        linkColumn: true
      },
      {
        dataKey: 'description',
        label: 'Description'
      }
    ];
  }, []);

  const [propertyDetailId, setPropertyDetailId] = useSearchParamState(
    'property-id',
    null
  );

  const onClickRow = (property: NodePropertyResponseModel) => {
    setPropertyDetailId(property.id);
  };

  const [editProperty, setEditProperty] =
    useState<NodePropertyResponseModel | null>(null);

  if (propertyDetailId != null && editProperty == null) {
    const specificProperty = propertiesQuery.data.find(
      (x) => x.id === propertyDetailId
    );

    if (specificProperty != null) {
      setEditProperty(_.cloneDeep(specificProperty));
    }
  }

  const isEditingTrait = propertyDetailId != null;

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);
  const saveMutation = APIGen.NodesV2.addOrUpdateNodeProperties.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodeProperties.path(contextSettings)
      });

      setPropertyDetailId(null);
      setEditProperty(null);
    }
  });
  const save = useCallback(() => {
    saveMutation.mutate({
      properties: [editProperty]
    });
  }, [editProperty, saveMutation]);

  const addProperty = useCallback(() => {
    setEditProperty({
      id: UUID.generate(),
      name: 'New property',
      description: 'Property description'
    });
  }, []);

  return (
    <>
      <DataTable
        columns={propertiesColumns}
        data={propertiesQuery.data || []}
        isLoading={propertiesQuery.isLoading}
        onClickRow={onClickRow}
      />
      <DataTableFooter alignRight>
        <AddButton onClick={addProperty}>Add property</AddButton>
      </DataTableFooter>

      <ActionModal
        isOpen={propertyDetailId != null || editProperty != null}
        onModalClose={() => {
          setPropertyDetailId(null);
          setEditProperty(null);
        }}
        title={isEditingTrait ? 'Edit trait' : 'Add trait'}
        onConfirmClick={save}
        cancelText={T.common.done}
        headerIcon={Icons.Edit}
        isLoading={propertiesQuery.isLoading || saveMutation.isPending}
        actionText={
          <>
            <Icons.Save />
            {T.common.save}
          </>
        }
      >
        {editProperty && (
          <>
            <ModelForm
              input={editProperty}
              setInput={setEditProperty}
              models={propertyModels}
            />
          </>
        )}
      </ActionModal>
    </>
  );
};

const NodesV2Page = ({ onTitleChanged }: NodesV2PageProps) => {
  _.noop(onTitleChanged);

  const params = useParams<NodeParams>();
  useEffect(() => {
    document.title = 'Nodes V2';
  }, []);
  const { tenantId } = useContext(TenantContext);

  const sections = useMemo(() => {
    return [
      {
        title: 'Nodes',
        key: 'nodes',
        view: <NodesSearch />,
        icon: <Icons.Site />,
        url: getNodesV2Url(tenantId, params.nodeId, 'nodes')
      },
      {
        title: 'Node traits',
        key: 'nodetraits',
        icon: <Icons.Network />,
        view: <NodeTraits />,
        url: getNodesV2Url(tenantId, params.nodeId, 'nodetraits')
      },
      {
        title: 'Properties',
        key: 'properties',
        icon: <Icons.Data />,
        view: <NodeProperties />,
        url: getNodesV2Url(tenantId, params.nodeId, 'properties')
      }
    ];
  }, [params.nodeId, tenantId]);

  let startIndex = _.findIndex(sections, { key: params.subPage });

  if (startIndex === -1) {
    startIndex = 0;
  }

  return (
    <ToolbarContentPage title="Nodes V2" wrapContent showLocationPicker={false}>
      <SegmentedContentView sections={sections} index={startIndex} />
    </ToolbarContentPage>
  );
};

export default React.memo(NodesV2Page);
