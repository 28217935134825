import _ from 'lodash';
import defaultFormBuilding from './assets/defaultFormBuilding.json';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

export const stripCalculationData = (
  form: EctoplannerForm
): EctoplannerForm => {
  // Do this instead of cloneDeep since we have a really large
  // form object, and cloneDeep is very slow.
  const ret = JSON.parse(JSON.stringify(form));
  for (const building of ret.buildings) {
    if (building.params == null) {
      building.params = {};
    }

    building.params.calculations = _.cloneDeep(
      defaultFormBuilding.params.calculations
    );
  }

  delete ret.gridTemperatureSuggestion;
  delete ret.calculations;

  return ret;
};
