import React, { createContext, useCallback, useMemo, useState } from 'react';
import {
  useBlocker,
  Location,
  useBeforeUnload,
  BlockerFunction
} from 'react-router-dom';

export type CustomBlockerFunction = (args: {
  currentLocation: Location;
  nextLocation: Location;
}) => boolean;

type PromptFunction = (args: {
  currentLocation: Location;
  nextLocation: Location;
}) => string;

export type BlockerContextType = {
  addBlockListener: (listener: CustomBlockerFunction) => void;
  removeBlockListener: (listener: CustomBlockerFunction) => void;
  addPromptListener: (listener: PromptFunction) => void;
  removePromptListener: (listener: PromptFunction) => void;
};

export const BlockerContext = createContext<BlockerContextType>(null);

export const BlockerContainer = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [promptListeners, setPromptListeners] = useState<PromptFunction[]>([]);
  const [blockListeners, setBlockListeners] = useState<CustomBlockerFunction[]>(
    []
  );

  const addBlockListener = useCallback((listener: CustomBlockerFunction) => {
    setBlockListeners((listeners) => [...listeners, listener]);
  }, []);

  const removeBlockListener = useCallback((listener: CustomBlockerFunction) => {
    setBlockListeners((listeners) => listeners.filter((l) => l !== listener));
  }, []);

  const addPromptListener = useCallback((listener: PromptFunction) => {
    setPromptListeners((listeners) => [...listeners, listener]);
  }, []);

  const removePromptListener = useCallback((listener: PromptFunction) => {
    setPromptListeners((listeners) => listeners.filter((l) => l !== listener));
  }, []);

  const contextValue = useMemo(() => {
    return {
      addBlockListener,
      removeBlockListener,
      addPromptListener,
      removePromptListener
    };
  }, [
    addBlockListener,
    addPromptListener,
    removeBlockListener,
    removePromptListener
  ]);

  const blocker = useBlocker(
    useCallback<BlockerFunction>(
      ({ nextLocation, currentLocation, historyAction }) => {
        // Don't invoke the blocker when moving into state
        if (historyAction !== 'POP') {
          return false;
        }

        for (let i = blockListeners.length - 1; i >= 0; i--) {
          const listener = blockListeners[i];

          if (listener({ nextLocation, currentLocation })) {
            return true;
          }
        }

        for (const listener of promptListeners) {
          const message = listener({ nextLocation, currentLocation });
          if (message != null) {
            // eslint-disable-next-line no-alert
            const result = !window.confirm(message);
            return result;
          }
        }

        return false;
      },
      [blockListeners, promptListeners]
    )
  );

  const prevState = React.useRef(blocker.state);
  React.useEffect(() => {
    if (blocker.state === 'blocked') {
      blocker.reset();
    }
    prevState.current = blocker.state;
  }, [blocker]);

  useBeforeUnload(
    React.useCallback(
      (event) => {
        if (promptListeners.length > 0) {
          event.preventDefault();
        }
      },
      [promptListeners]
    ),
    { capture: true }
  );

  return (
    <BlockerContext.Provider value={contextValue}>
      {children}
    </BlockerContext.Provider>
  );
};

export function useBlockerFunction(
  blockerFunction: CustomBlockerFunction,
  enabled = true
) {
  const context = React.useContext(BlockerContext);
  const addBlockListener = context?.addBlockListener;
  const removeBlockListener = context?.removeBlockListener;

  React.useEffect(() => {
    if (enabled) {
      addBlockListener?.(blockerFunction);
      return () => {
        removeBlockListener?.(blockerFunction);
      };
    }
  }, [
    addBlockListener,
    blockerFunction,
    context,
    enabled,
    removeBlockListener
  ]);
}

export function usePromptFunction(
  promptFunction: PromptFunction,
  enabled = true
) {
  const context = React.useContext(BlockerContext);
  const addPromptListener = context?.addPromptListener;
  const removePromptListener = context?.removePromptListener;

  React.useEffect(() => {
    if (enabled) {
      addPromptListener?.(promptFunction);
      return () => {
        removePromptListener?.(promptFunction);
      };
    }
  }, [promptFunction, addPromptListener, removePromptListener, enabled]);
}

export function usePromptMessage(message: string, when = true) {
  const context = React.useContext(BlockerContext);
  const addPromptListener = context.addPromptListener;
  const removePromptListener = context.removePromptListener;

  React.useEffect(() => {
    if (when) {
      const listener = () => {
        return message;
      };

      addPromptListener(listener);
      return () => {
        removePromptListener(listener);
      };
    }
  }, [addPromptListener, when, message, removePromptListener]);
}
