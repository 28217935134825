import React from 'react';
import {
  ModelDefinition,
  ModelFormSectionType
} from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import T from 'ecto-common/lib/lang/Language';
import { ectoplannerHorizontalWeights } from 'js/components/Ectoplanner/EctoplannerModels';
import { EctoplannerProfileEditor } from 'js/components/Ectoplanner/EctoplannerProfileEditor';
import {
  EctoplannerDemandTypes,
  EctoplannerProfileTypes
} from 'js/components/Ectoplanner/EctoplannerTypes';
import _ from 'lodash';
import { SecosimForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

function patchModels(
  models: ModelDefinition<SecosimForm>[]
): ModelDefinition<SecosimForm>[] {
  return models.map((model) => {
    return {
      ...model,
      isHorizontal: true,
      horizontalWeights: model.horizontalWeights || ectoplannerHorizontalWeights
    };
  });
}

export const ScenarioDefinitionModelSections: ModelFormSectionType<SecosimForm>[] =
  [
    {
      label: T.ectoplanner.secosim.models.scenario_definition,
      wrapContent: true,
      models: patchModels([
        {
          key: (input) => input.parameters.goal,
          label: T.ectoplanner.secosim.models.goal.title,
          modelType: ModelType.OPTIONS,
          options: [
            {
              label: T.ectoplanner.secosim.models.goal.cost,
              value: 'cost'
            },
            {
              label: T.ectoplanner.secosim.models.goal.co2,
              value: 'co2'
            },
            {
              label: T.ectoplanner.secosim.models.goal.reference,
              value: 'reference'
            }
          ]
        },
        {
          key: (input) => input.parameters.ATES,
          label: T.ectoplanner.secosim.models.ates.title,
          modelType: ModelType.BOOL
        }
      ])
    }
  ];

export const Y = {
  ectoplanner: {
    secosim: {
      models: {
        pv: 'PV'
      }
    }
  }
};

export const PVSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: Y.ectoplanner.secosim.models.pv,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].pv.pv_capacity,
        label: T.ectoplanner.secosim.models.pv_capacity.title,
        unit: T.ectoplanner.units.kwp,
        modelType: ModelType.NUMBER,
        hasError: (input) => input == null
      },
      {
        key: (input) => input.buildings[buildingIndex].timeseries.pv_gen,
        label: T.ectoplanner.secosim.models.pv_gen.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            energyKind={EctoplannerDemandTypes.Electricity}
            allowDelete={false}
          />
        )
      }
    ])
  }
];

export const BatterySections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.battery,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].battery.battery_capacity,
        label: T.ectoplanner.secosim.models.battery_capacity.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) => input.buildings[buildingIndex].battery.battery_power,
        label: T.ectoplanner.secosim.models.battery_power.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].battery.battery_efficiency,
        label: T.ectoplanner.secosim.models.battery_efficiency.title,
        modelType: ModelType.NUMBER
      }
    ])
  }
];

export const GridConnectionSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.grid_connection,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].network.grid_capacity,
        label: T.ectoplanner.secosim.models.grid_capacity.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) => input.buildings[buildingIndex].network.feed_in_capacity,
        label: T.ectoplanner.secosim.models.feed_in_capacity.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].network.heat_to_ates_capacity,
        label: T.ectoplanner.secosim.models.heat_to_ates_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kw
      }
    ])
  }
];

export const HeatPumpSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.air_hp,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].air_hp.air_hp_capacity,
        label: T.ectoplanner.secosim.models.hp_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) => input.buildings[buildingIndex].air_hp.air_hp_eta,
        label: T.ectoplanner.secosim.models.hp_eta.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) => input.buildings[buildingIndex].air_hp.air_hp_max_temp,
        label: T.ectoplanner.secosim.models.hp_max_temp.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) => input.buildings[buildingIndex].air_hp.air_pump_cop,
        label: T.ectoplanner.secosim.models.hp_pump_cop.title,
        modelType: ModelType.NUMBER
      }
    ])
  },
  {
    label: T.ectoplanner.secosim.models.dhw_air_hp,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_air_hp.dhw_air_hp_capacity,
        label: T.ectoplanner.secosim.models.hp_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_air_hp.dhw_air_hp_eta,
        label: T.ectoplanner.secosim.models.hp_eta.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_air_hp.dhw_air_hp_max_temp,
        label: T.ectoplanner.secosim.models.hp_max_temp.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_air_hp.dhw_air_pump_cop,
        label: T.ectoplanner.secosim.models.hp_pump_cop.title,
        modelType: ModelType.NUMBER
      }
    ])
  },
  {
    label: T.ectoplanner.secosim.models.heat_hp,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].heat_hp.heat_hp_capacity,
        label: T.ectoplanner.secosim.models.hp_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) => input.buildings[buildingIndex].heat_hp.heat_hp_eta,
        label: T.ectoplanner.secosim.models.hp_eta.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) => input.buildings[buildingIndex].heat_hp.heat_hp_max_temp,
        label: T.ectoplanner.secosim.models.hp_max_temp.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) => input.buildings[buildingIndex].heat_hp.heat_pump_cop,
        label: T.ectoplanner.secosim.models.hp_pump_cop.title,
        modelType: ModelType.NUMBER
      }
    ])
  },
  {
    label: T.ectoplanner.secosim.models.dhw_hp,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].dhw_hp.dhw_hp_capacity,
        label: T.ectoplanner.secosim.models.hp_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) => input.buildings[buildingIndex].dhw_hp.dhw_hp_eta,
        label: T.ectoplanner.secosim.models.hp_eta.title,
        modelType: ModelType.NUMBER
      },
      {
        key: (input) => input.buildings[buildingIndex].dhw_hp.dhw_hp_max_temp,
        label: T.ectoplanner.secosim.models.hp_max_temp.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) => input.buildings[buildingIndex].dhw_hp.dhw_pump_cop,
        label: T.ectoplanner.secosim.models.hp_pump_cop.title,
        modelType: ModelType.NUMBER
      }
    ])
  }
];

export const CoolingSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.cooling_direct,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].indoors_cooling_buffer,
        label: T.ectoplanner.secosim.models.indoors_cooling_buffer.title,
        modelType: ModelType.BOOL
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].cooling_direct.cooling_capacity,
        label: T.ectoplanner.secosim.models.cooling_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].cooling_direct.cooling_direct_cop,
        label: T.ectoplanner.secosim.models.hp_pump_cop.title,
        modelType: ModelType.NUMBER
      }
    ])
  }
];

export const BufferTankSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.heat_buffer,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].heat_buffering,
        label: T.ectoplanner.secosim.models.heat_buffering,
        modelType: ModelType.BOOL
      },
      {
        key: (input) => input.buildings[buildingIndex].indoors_heating_buffer,
        label: T.ectoplanner.secosim.models.heating_buffer.title,
        modelType: ModelType.BOOL
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].heat_buffer.volume_heat_buffer,
        label: T.ectoplanner.secosim.models.volume_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.m3
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].heat_buffer.max_temp_heat_buffer,
        label: T.ectoplanner.secosim.models.max_temp_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].heat_buffer.min_temp_heat_buffer,
        label: T.ectoplanner.secosim.models.min_temp_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].heat_buffer.loss_heat_buffer,
        label: T.ectoplanner.secosim.models.loss_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwh
      }
    ])
  },
  {
    label: T.ectoplanner.secosim.models.dhw_buffer,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].dhw_buffering,
        label: T.ectoplanner.secosim.models.dhw_buffering,
        modelType: ModelType.BOOL
      },
      {
        key: (input) => input.buildings[buildingIndex].indoors_dhw_buffer,
        label: T.ectoplanner.secosim.models.indoors_dhw_buffer.title,
        modelType: ModelType.BOOL
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_buffer.volume_dhw_buffer,
        label: T.ectoplanner.secosim.models.volume_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.m3
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_buffer.max_temp_dhw_buffer,
        label: T.ectoplanner.secosim.models.max_temp_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_buffer.min_temp_dhw_buffer,
        label: T.ectoplanner.secosim.models.min_temp_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.degc
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].dhw_buffer.loss_dhw_buffer,
        label: T.ectoplanner.secosim.models.loss_buffer.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwh
      }
    ])
  }
];

export const BoilersSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.e_boiler,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) =>
          input.buildings[buildingIndex].e_boiler.e_boiler_dhw_capacity,
        label: T.ectoplanner.secosim.models.dhw_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].e_boiler.e_boiler_heating_capacity,
        label: T.ectoplanner.secosim.models.heating_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      }
    ])
  },
  {
    label: T.ectoplanner.secosim.models.gas_boiler,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) =>
          input.buildings[buildingIndex].gas_boiler.gas_boiler_dhw_capacity,
        label: T.ectoplanner.secosim.models.dhw_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].gas_boiler.gas_boiler_heating_capacity,
        label: T.ectoplanner.secosim.models.heating_capacity.title,
        modelType: ModelType.NUMBER,
        unit: T.ectoplanner.units.kwth
      }
    ])
  }
];

const SecosimProfileEditor = EctoplannerProfileEditor<SecosimForm, object>;

export const TimeSeriesSections: ModelFormSectionType<SecosimForm>[] = [
  {
    label: T.ectoplanner.secosim.models.timeseries,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.timeseries.co2_data,
        label: T.ectoplanner.secosim.models.co2_data.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.EmissionsGCo2Kwh}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.timeseries.el_import_price,
        label: T.ectoplanner.secosim.models.el_import_price.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.PriceEurMwh}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.timeseries.el_export_price,
        label: T.ectoplanner.secosim.models.el_export_price.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.PriceEurMwh}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.timeseries.gas_prices,
        label: T.ectoplanner.secosim.models.gas_prices.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.PriceEurMwh}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.timeseries.hist_gas_h_consumption_m3,
        label: T.ectoplanner.secosim.models.hist_gas_h_consumption_m3.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.ConsumptionM3}
            allowDelete={false}
          />
        )
      }
    ])
  }
];

export const BuildingTimeSeriesSections = (
  buildingIndex: number
): ModelFormSectionType<SecosimForm>[] => [
  {
    label: T.ectoplanner.secosim.models.timeseries,
    wrapContent: true,
    models: patchModels([
      {
        key: (input) => input.buildings[buildingIndex].timeseries.pv_gen,
        label: T.ectoplanner.secosim.models.pv_gen.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            energyKind={EctoplannerDemandTypes.Electricity}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.buildings[buildingIndex].timeseries.el_demand,
        label: T.ectoplanner.secosim.models.el_demand.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].timeseries.demand_heating,
        label: T.ectoplanner.secosim.models.demand_heating.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) => input.buildings[buildingIndex].timeseries.demand_dhw,
        label: T.ectoplanner.secosim.models.demand_dhw.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].timeseries.demand_cooling,
        label: T.ectoplanner.secosim.models.demand_cooling.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Energy}
            allowDelete={false}
          />
        )
      },
      {
        key: (input) =>
          input.buildings[buildingIndex].timeseries.demand_temperature,
        label: T.ectoplanner.secosim.models.demand_temperature.title,
        modelType: ModelType.CUSTOM,
        render: (props, model) => (
          <SecosimProfileEditor
            {...props}
            model={model}
            profileType={EctoplannerProfileTypes.Temperature}
            allowDelete={false}
          />
        )
      }
    ])
  }
];
