import React from 'react';
import { ProcessMapObjectsProps } from '../ProcessMapObjectProps';
import {
  ProcessMapTextObject,
  defaultTextSettings
} from '../ProcessMapViewConstants';
import { ProcessMapTextView } from '../View/ProcessMapTextView';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { textAlignmentOption, textStyleSections } from './commonModels';

export const TextView = ({
  allSignalsBySignalTypeOrSignalId,
  node: nodeIn,
  objectIndex,
  selectedRectHandles,
  updateTextSize,
  isHovering,
  editMode,
  onClick,
  onMouseOver,
  onMouseOut
}: ProcessMapObjectsProps) => {
  const node = nodeIn as ProcessMapTextObject;
  const amongSelected = selectedRectHandles.some(
    (handle) => handle.objectIndex === objectIndex && handle.rectIndex === 0
  );

  let shouldHide = false;

  if (
    (node.hideWhenNoSignalTypeId != null &&
      allSignalsBySignalTypeOrSignalId[node.hideWhenNoSignalTypeId] == null) ||
    (node.hideWhenNoSignalId != null &&
      allSignalsBySignalTypeOrSignalId[node.hideWhenNoSignalId] == null)
  ) {
    shouldHide = true;
  }

  if (!editMode && shouldHide) {
    return null;
  }

  return (
    <ProcessMapTextView
      objectIndex={objectIndex}
      key={node.id}
      node={node}
      isDragging={amongSelected}
      updateTextSize={updateTextSize}
      title={node.text}
      isHovering={isHovering}
      titleTextSettings={node.textSettings}
      shouldHide={shouldHide}
      onMouseOver={(event) => onMouseOver?.(event, node)}
      onMouseOut={(event) => onMouseOut?.(event, node)}
      onClick={(event) => onClick?.(event, node)}
    />
  );
};

export const textSections = (
  previewNodeId: string
): ModelFormSectionType<ProcessMapTextObject>[] => {
  return [
    {
      label: T.admin.processmaps.objecteditor.textobject,
      models: [
        {
          label: T.admin.processmaps.objecteditor.text,
          key: (input) => input.text,
          modelType: ModelType.TEXT,
          multiline: true,
          rows: 4
        },
        textAlignmentOption
      ]
    },
    ...textStyleSections<ProcessMapTextObject>(
      T.admin.processmaps.objecteditor.textstyle,
      (input) => input.textSettings,
      defaultTextSettings
    ),
    {
      label: T.admin.processmaps.objecteditor.hideitem,
      initiallyCollapsed: true,
      lines: [
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignal,
              key: (input) => input.hideWhenNoSignalTypeId,
              modelType: ModelType.SIGNAL_TYPE,
              isClearable: true,
              helpText: T.admin.processmaps.objecteditor.hiderequiredsignalhelp
            }
          ]
        },
        {
          models: [
            {
              label: T.admin.processmaps.objecteditor.hiderequiredsignalid,
              key: (input) => input.hideWhenNoSignalId,
              modelType: ModelType.SIGNAL,
              isClearable: true,
              helpText:
                T.admin.processmaps.objecteditor.hiderequiredsignalidhelp,
              nodeId: previewNodeId
            }
          ]
        }
      ]
    }
  ];
};
