import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { useContext } from 'react';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';

interface NodeDataSourceProps {
  nodeId?: string;
}

const NodeDataSource = ({
  nodeId
}: NodeDataSourceProps): NodeV2ResponseModel => {
  const { nodeId: currentNodeId } = useContext(DashboardDataContext);

  const { node } = useNode(nodeId ?? currentNodeId);

  return node;
};

export const nodeDataSourceSections: () => ModelFormSectionType<NodeDataSourceProps>[] =
  () => {
    return [
      {
        lines: [
          {
            models: [
              {
                key: (input) => input.nodeId,
                label: T.admin.dashboards.panels.types.nodename.node,
                modelType: ModelType.NODE,
                placeholder:
                  T.admin.dashboards.panels.types.nodename.nodeplaceholder
              }
            ]
          }
        ],
        listPriority: SectionListPriority.Location
      }
    ];
  };

export default NodeDataSource;
