import panelDefinitions from 'ecto-common/lib/Dashboard/panels/index';
import { DASHBOARD_COLUMN_COUNT } from 'ecto-common/lib/Dashboard/Dashboard';
import dimensions from 'ecto-common/lib/styles/dimensions';
import _ from 'lodash';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';

export type DashboardRectangle = {
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  i: string;
};

const MinWidths = _.reduce(
  panelDefinitions,
  (dict, panelType, key) => {
    if (panelType.data.minWidth != null) {
      dict[key] = panelType.data.minWidth;
    }
    return dict;
  },
  {} as Record<string, number>
);

const FixedPanels = _.reduce(
  panelDefinitions,
  (fixed, panelType, key) => {
    if (panelType.data.fixedSizeInRelayout) {
      fixed.push(key);
    }
    return fixed;
  },
  []
);

const maxY = (panelRects: DashboardRectangle[], expandedPanelIds: string[]) => {
  let max = 0;

  _.forEach(panelRects, (panelRect) => {
    if (!expandedPanelIds.includes(panelRect.i)) {
      max = Math.max(max, panelRect.y + panelRect.h);
    }
  });

  return max;
};

const overlapsOtherPanelOrSides = (
  panelRects: DashboardRectangle[],
  comparePos: DashboardRectangle,
  originalPos: DashboardRectangle,
  expandedPanelIds: string[]
) => {
  if (
    comparePos.x + comparePos.w > DASHBOARD_COLUMN_COUNT ||
    comparePos.x < 0
  ) {
    return true;
  }

  for (const panelRect of panelRects) {
    if (expandedPanelIds.includes(panelRect.i) || panelRect === originalPos) {
      continue;
    }

    if (
      comparePos.x < panelRect.x + panelRect.w &&
      comparePos.x + comparePos.w > panelRect.x &&
      comparePos.y < panelRect.y + panelRect.h &&
      comparePos.y + comparePos.h > panelRect.y
    ) {
      return true;
    }
  }

  return false;
};

const findTooSmallPanelIndex = (
  panelRects: DashboardRectangle[],
  panels: DashboardPanel[],
  panelColumnWidth: number,
  expandedPanelIds: string[]
) => {
  if (panels == null) {
    return -1;
  }

  const panelIndices = [];

  for (let i = 0; i < panelRects.length; i++) {
    const panelRect = panelRects[i];
    const panel = panels[i];
    const minWidth = MinWidths[panel.type] ?? 0;

    let panelWidth = panelRect.w * panelColumnWidth;

    if (panelRect.x + panelRect.w < DASHBOARD_COLUMN_COUNT) {
      panelWidth -= dimensions.standardMargin;
    }

    if (panelWidth < minWidth && !expandedPanelIds.includes(panel.id)) {
      panelIndices.push(i);
    }
  }

  // Return the smallest panel first (deemed to be least important / most OK to put
  // at the end)
  if (panelIndices.length > 0) {
    return _.minBy(panelIndices, (val) => panelRects[val].w);
  }

  return -1;
};

const expandPanelWidthsToFillHoles = (
  panelRects: DashboardRectangle[],
  panels: DashboardPanel[],
  expandedPanelIds: string[]
) => {
  _.forEach(panelRects, (panelRect, panelIndex) => {
    const panel = panels[panelIndex];

    if (
      !FixedPanels.includes(panel.type) &&
      !expandedPanelIds.includes(panel.id)
    ) {
      let nextPanelRect = { ...panelRect, w: panelRect.w + 1 };

      while (
        !overlapsOtherPanelOrSides(
          panelRects,
          nextPanelRect,
          panelRect,
          expandedPanelIds
        )
      ) {
        panelRect.w++;
        nextPanelRect.w++;
      }

      nextPanelRect = { ...panelRect, x: panelRect.x - 1, w: panelRect.w + 1 };

      while (
        !overlapsOtherPanelOrSides(
          panelRects,
          nextPanelRect,
          panelRect,
          expandedPanelIds
        )
      ) {
        panelRect.w++;
        panelRect.x--;
        nextPanelRect.w++;
        nextPanelRect.x--;
      }
    }
  });
};

/**
 * Given a layout, check and see if certain panels get too little horizontal space
 * to show meaningful content with the current available width. If they do, simply
 * alter the layout and put the smallest panel after all the other panels and make
 * it as wide as the dashboard is.
 *
 * Then, go through and see if you can expand other panels to make use of the
 * newly available space. Then, repeat the procedure to see if there are other panels
 * which are too small.
 */
export const generateResponsiveDashboardLayout = (
  panelRects: DashboardRectangle[],
  panels: DashboardPanel[],
  width: number
) => {
  const panelColumnWidth = width / DASHBOARD_COLUMN_COUNT;
  const expandedPanelIds: string[] = [];
  let tooSmallIndex = findTooSmallPanelIndex(
    panelRects,
    panels,
    panelColumnWidth,
    expandedPanelIds
  );

  while (tooSmallIndex !== -1) {
    const compactPanelRect = panelRects[tooSmallIndex];
    expandedPanelIds.push(compactPanelRect.i);
    expandPanelWidthsToFillHoles(panelRects, panels, expandedPanelIds);
    tooSmallIndex = findTooSmallPanelIndex(
      panelRects,
      panels,
      panelColumnWidth,
      expandedPanelIds
    );
  }

  let insertionPos = maxY(panelRects, expandedPanelIds);

  for (const insertedId of expandedPanelIds) {
    const panelRect = _.find(panelRects, ['i', insertedId]);
    panelRect.x = 0;
    panelRect.y = insertionPos;
    panelRect.w = DASHBOARD_COLUMN_COUNT;
    insertionPos += panelRect.h;
  }

  return panelRects;
};
