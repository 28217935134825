import { BuildResponse } from 'ecto-common/lib/API/EctoplannerAPIGen';
import fnv from 'fnv-plus';
import { stripCalculationData } from 'js/components/Ectoplanner/EctoplannerResultType';
import {
  SecosimForm,
  EctoplannerForm
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import moment from 'moment';

export const PlotTypes = {
  Monthly: 'monthly',
  MonthlyCOP: 'monthlyCOP',
  Annual: 'annual',
  AnnualGCo2Kwh: 'annualGCo2Kwh',
  AnnualM3: 'annualM3',
  DurationCurve: 'durationCurve',
  DurationCurveCOP: 'durationCurveCOP',
  AnnualCOP: 'annualCOP',
  AnnualPrice: 'annualPrice',
  AnnualPriceEurMwh: 'annualPriceEurMwh',
  AnnualTemperature: 'annualTemperature'
} as const;

export const EctoplannerProjectTypes = {
  Ectoplanner: 'ectoplanner',
  Secosim: 'secosim'
} as const;

export type EctoplannerProjectType =
  (typeof EctoplannerProjectTypes)[keyof typeof EctoplannerProjectTypes];

export type EctoplannerFormEnvironment = {
  projectId: string;
};

export type PlotType = (typeof PlotTypes)[keyof typeof PlotTypes];

export type EctoplannerFormError = {
  sectionTitle: React.ReactNode;
  errorLabel: string;
  errorText: string;
  errorKeyPath: string;
};

export const EctoplannerDemandTypes = {
  Heating: 'heating',
  Cooling: 'cooling',
  Electricity: 'electricity',
  CombinedHeatingCooling: 'heatingcooling'
} as const;

export const EctoplannerEnergyTypes = {
  COP: 'cop',
  Demand: 'demand',
  Import: 'import'
} as const;

export type EctoplannerDemandType =
  (typeof EctoplannerDemandTypes)[keyof typeof EctoplannerDemandTypes];
export type EctoplannerEnergyType =
  (typeof EctoplannerEnergyTypes)[keyof typeof EctoplannerEnergyTypes];

export const EctoplannerProfileTypes = {
  Energy: 'energy',
  COP: 'cop',
  Price: 'price',
  PriceEurMwh: 'pricemwh',
  ConsumptionM3: 'consumptionm3',
  EmissionsGCo2Kwh: 'gco2kwh',
  Temperature: 'temperature'
} as const;

export type EctoplannerProfileType =
  (typeof EctoplannerProfileTypes)[keyof typeof EctoplannerProfileTypes];

export const HOURS_IN_YEAR = 8760;

export enum EctoplannerBuildStatus {
  Created = 'CREATED',
  Queued = 'QUEUED',
  Started = 'STARTED',
  DownloadingFiles = 'DOWNLOADING_FILES',
  WritingResult = 'WRITING_RESULT',
  CreateExcelFile = 'CREATE_EXCEL_FILE',
  CalcBalancingUnit = 'CALC_BALANCING_UNIT',
  CalcBuildingBalancing = 'CALC_BUILDING_BALANCING',
  LoadingParams = 'LOADING_PARAMS',
  ResultsDone = 'RESULTS_DONE',
  NoSolutionFound = 'NO_SOLUTION_FOUND',
  Error = 'ERROR'
}

export const EctoplannerBuildStatusProgress: Record<
  EctoplannerBuildStatus,
  number
> = {
  [EctoplannerBuildStatus.Error]: 0,
  [EctoplannerBuildStatus.Created]: 0,
  [EctoplannerBuildStatus.Queued]: 0,
  [EctoplannerBuildStatus.Started]: 3,
  [EctoplannerBuildStatus.DownloadingFiles]: 5,
  [EctoplannerBuildStatus.LoadingParams]: 8,
  [EctoplannerBuildStatus.CalcBuildingBalancing]: 10,
  [EctoplannerBuildStatus.CalcBalancingUnit]: 20,
  [EctoplannerBuildStatus.CreateExcelFile]: 70,
  [EctoplannerBuildStatus.WritingResult]: 95,
  [EctoplannerBuildStatus.ResultsDone]: 0,
  [EctoplannerBuildStatus.NoSolutionFound]: 0
};

export const areEctoplannerResultsAvailable = (
  status: string,
  buildId?: string,
  printMode?: boolean
) => {
  return (
    buildId != null &&
    (printMode ||
      status === EctoplannerBuildStatus.ResultsDone ||
      status === EctoplannerBuildStatus.NoSolutionFound)
  );
};

export const isEctoplannerStatusInProgress = (status: string) =>
  status != null &&
  status !== EctoplannerBuildStatus.Created &&
  status !== EctoplannerBuildStatus.ResultsDone &&
  status !== EctoplannerBuildStatus.Error &&
  status !== EctoplannerBuildStatus.NoSolutionFound;

export const ectoplannerBuildTimedOut = (build: BuildResponse) => {
  const timeDiff = moment().diff(moment(build.lastUpdated), 'hours');
  return timeDiff >= 2;
};

export function calculateEctoplannerChecksum(form: EctoplannerForm): string {
  const trimmedCopy = JSON.stringify(
    stripCalculationData(JSON.parse(JSON.stringify(form)))
  );
  const checksum = fnv.hash(trimmedCopy);
  return checksum.str();
}

export function calculateSecosimChecksum(form: SecosimForm): string {
  const checksum = fnv.hash(JSON.stringify(form));
  return checksum.str();
}
