import { SET_UI_LANGUAGE } from 'ecto-common/lib/actions/actionTypes';
import IdentityServiceAPIGenV2 from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import { featureFlagStore } from 'ecto-common/lib/FeatureFlags/FeatureFlags';
import {
  useCommonDispatch,
  useCommonSelector
} from 'ecto-common/lib/reducers/storeCommon';
import {
  getSelectedLanguage,
  setSelectedLanguage
} from 'ecto-common/lib/utils/localStorageUtil';
import { useEffect, useRef } from 'react';

export const useInitialUserSettings = (enabled: boolean) => {
  const dispatch = useCommonDispatch();
  const language = useCommonSelector((state) => state.general.language);

  const userSettingsQuery =
    IdentityServiceAPIGenV2.User.getUserSettings.useQuery({
      enabled,
      refetchOnWindowFocus: false
    });

  const initialFetch = useRef<boolean>(true);

  useEffect(() => {
    if (userSettingsQuery.data?.userSettings?.language) {
      const serverLanguage = userSettingsQuery.data?.userSettings?.language;
      dispatch({
        type: SET_UI_LANGUAGE,
        payload: serverLanguage
      });

      if (initialFetch.current && serverLanguage !== getSelectedLanguage()) {
        setSelectedLanguage(serverLanguage);
        window.location.reload();
      }

      initialFetch.current = false;
    }
    if (userSettingsQuery.data?.userSettings?.experimentalFeatures) {
      featureFlagStore.update(
        userSettingsQuery.data?.userSettings?.experimentalFeatures
      );
    }
  }, [
    dispatch,
    language,
    userSettingsQuery.data?.userSettings?.experimentalFeatures,
    userSettingsQuery.data?.userSettings?.language
  ]);

  return userSettingsQuery;
};
