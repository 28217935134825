import { AnyAction } from '@reduxjs/toolkit';

type BaseActionType = {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

export function createReducer<T, ActionType extends BaseActionType = AnyAction>(
  initialState: T,
  handlers: Record<string, (state: T, action: ActionType) => T>,
  prefix = ''
): (state: T, action: ActionType) => T {
  if (prefix !== '') {
    handlers = Object.entries(handlers).reduce(
      (newHandlers, [type, func]) => {
        newHandlers[prefix + type] = func;
        return newHandlers;
      },
      {} as Record<string, (state: T, action: ActionType) => T>
    );
  }

  return (state = initialState, action) => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action.payload);
    }
    return state;
  };
}
