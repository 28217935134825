import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';

import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { useUpdateModelFormInput } from 'ecto-common/lib/ModelForm/formUtils';
import _ from 'lodash';
import {
  NodeV2ResponseModel,
  NodeParentInformationResponseModel,
  SamplingInterval,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { getSignalAggregationModels } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { SeriesIntervalToString } from 'js/modules/signalCollections/signalCollections';
import { modelFormSectionsAreValid } from 'ecto-common/lib/ModelForm/validateForm';
import {
  ChartSignal,
  getSeriesName
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import {
  GraphSettingsType,
  GraphType,
  SeriesInterval
} from 'ecto-common/lib/types/EctoCommonTypes';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { useNodesEx } from 'ecto-common/lib/hooks/useCurrentNode';

const getOption = (
  signal: ChartSignal,
  signalTypesMap: Record<string, SignalTypeResponseModel>,
  signalUnitTypesMap: Record<string, UnitResponseModel>,
  nodes: NodeV2ResponseModel[],
  equipmentParentNodes: (
    | NodeParentInformationResponseModel
    | NodeV2ResponseModel
  )[]
) => {
  return {
    // In this instance, only show samplingInterval / aggregation if it is different from the graph settings
    label: getSeriesName(
      signal.item,
      signal.group,
      signalTypesMap,
      signalUnitTypesMap,
      nodes,
      equipmentParentNodes,
      signal.settings?.samplingInterval,
      signal.settings?.aggregation
    ),
    value: signal.chartSignalId,
    signal
  };
};

const intervalOptions = SeriesIntervalToString().map(
  ([value, localization]) => {
    return {
      label: localization,
      value
    };
  }
);

type ChartSettingsProps = {
  graphSettings: GraphSettingsType;
  setGraphSettings: Dispatch<SetStateAction<GraphSettingsType>>;
  selectedSignals: ChartSignal[];
  setHasError: Dispatch<SetStateAction<boolean>>;
};

const ChartSettings = ({
  graphSettings,
  setGraphSettings,
  selectedSignals,
  setHasError
}: ChartSettingsProps) => {
  const onUpdateInput = useUpdateModelFormInput(setGraphSettings);
  const signalTypesMap = useOperatorSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useOperatorSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const nodeIds = useMemo(() => {
    return _.map(selectedSignals, (selectedSignal) =>
      _.head(selectedSignal.group.nodeIds)
    );
  }, [selectedSignals]);

  const { nodes } = useNodesEx(nodeIds);

  const selectedSignalOptions = useMemo(
    () =>
      _.map(selectedSignals, (signal) =>
        getOption(
          signal,
          signalTypesMap,
          signalUnitTypesMap,
          nodes.nodes,
          nodes.parents
        )
      ),
    [
      nodes.nodes,
      nodes.parents,
      selectedSignals,
      signalTypesMap,
      signalUnitTypesMap
    ]
  );

  useEffect(() => {
    const xAxisChartSignalId = graphSettings.xAxisChartSignalId;

    // If we've removed the x axis signal, we should also remove it from this setting
    if (
      xAxisChartSignalId != null &&
      !_.some(selectedSignals, ['chartSignalId', xAxisChartSignalId])
    ) {
      setGraphSettings((oldGraphSettings) => ({
        ...oldGraphSettings,
        xAxisChartSignalId: null
      }));
    }
  }, [selectedSignals, graphSettings.xAxisChartSignalId, setGraphSettings]);

  const samplingIntervalIsSet =
    graphSettings.samplingInterval != null &&
    graphSettings.samplingInterval !== SamplingInterval.Raw;

  useEffect(() => {
    if (samplingIntervalIsSet) {
      setGraphSettings((oldGraphSettings) => {
        const newGraphSettings = { ...oldGraphSettings };
        delete newGraphSettings.numPoints;
        return newGraphSettings;
      });
    }
  }, [samplingIntervalIsSet, setGraphSettings]);

  const sections: ModelFormSectionType<GraphSettingsType>[] = useMemo(() => {
    const ret: ModelFormSectionType<GraphSettingsType>[] = [
      {
        lines: [
          {
            models: _.compact([
              {
                modelType: ModelType.TEXT,
                key: (input) => input.name,
                label: T.graphs.signalselector.name.placeholder,
                placeholder: T.graphs.signalselector.name.placeholder,
                autoFocus: true
              },
              {
                modelType: ModelType.OPTIONS,
                key: (input) => input.type,
                label: T.graphs.editmodal.settings.typelabel,
                placeholder: T.graphs.select.graph.type,
                options: [
                  {
                    label: T.graphs.type.line,
                    value: GraphType.LINE
                  },
                  {
                    label: T.graphs.type.scatter,
                    value: GraphType.SCATTER
                  }
                ]
              },
              graphSettings.type === GraphType.SCATTER && {
                modelType: ModelType.OPTIONS,
                key: (input) => input.xAxisChartSignalId,
                label: T.graphs.editmodal.settings.xaxislabel,
                placeholder: T.graphs.editmodal.settings.xaxisplaceholder,
                options: selectedSignalOptions,
                hasError: _.isNil
              },
              {
                modelType: ModelType.OPTIONS,
                key: (input) => input.seriesInterval,
                label: T.graphs.editmodal.settings.seriesinterval,
                placeholder: SeriesIntervalToString().find(
                  ([interval]) => interval === SeriesInterval.DAY
                )?.[1],
                options: intervalOptions,
                isClearable: true
              }
            ])
          },
          {
            models: [
              ...getSignalAggregationModels(false), // Borrow from dashboard,
              {
                modelType: ModelType.NUMBER,
                key: (input) => input.numPoints,
                label: T.graphs.editmodal.settings.numpointslabel,
                hasError: (value: number) =>
                  value != null && (value < 10 || value > 5000),
                placeholder: samplingIntervalIsSet
                  ? T.graphs.editmodal.settings.numpointsplaceholderwithsampling
                  : T.graphs.editmodal.settings.numpointsplaceholder,
                enabled: (settings) =>
                  settings.samplingInterval === SamplingInterval.Raw ||
                  settings.samplingInterval === null
              },
              {
                modelType: ModelType.SPACE,
                key: (input) => input
              }
            ]
          }
        ]
      }
    ];

    const topLineModels = ret[0].lines[0].models;

    while (topLineModels.length < 4) {
      topLineModels.push({
        modelType: ModelType.SPACE,
        key: (input) => input
      });
    }

    return ret;
  }, [graphSettings.type, selectedSignalOptions, samplingIntervalIsSet]);

  useEffect(() => {
    setHasError(!modelFormSectionsAreValid(sections, graphSettings, null));
  }, [graphSettings, setHasError, sections]);

  return (
    <ModelForm
      sections={sections}
      onUpdateInput={onUpdateInput}
      input={graphSettings}
    />
  );
};

export default React.memo(ChartSettings);
