import React, { useContext, useEffect, useMemo } from 'react';
import EventHubService, {
  EventHubServiceContext,
  EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE
} from 'ecto-common/lib/EventHubConnection/EventHubService';
import { AuthenticationContext } from 'ecto-common/lib/hooks/useAuthentication';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { storeCurrentNodeId } from 'ecto-common/lib/utils/cacheKeys';
import _ from 'lodash';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { ResourceType } from 'ecto-common/lib/constants';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { BlockerContainer } from 'ecto-common/lib/hooks/useBlockerListener';

export type RouteItem = {
  element: React.ReactNode;
  exact?: boolean;
  path: string;
  resourceTypes?: ResourceType[];
  hideIfHasResourceTypes?: ResourceType[];
};

interface BaseContainerProps {
  msalConfiguration: IPublicClientApplication;
  currentAccount: AccountInfo;
  children: React.ReactChild;
}

const BaseContainer = ({
  msalConfiguration,
  currentAccount,
  children
}: BaseContainerProps) => {
  const apiEnvironment = getApiEnvironment();
  const { contextSettings } = useContext(TenantContext);

  const connection = useMemo(
    () =>
      new EventHubService(
        contextSettings,
        EVENT_HUB_SERVICE_DEFAULT_TIMEOUT_VALUE,
        [apiEnvironment.scopes.gateway],
        msalConfiguration,
        currentAccount
      ),
    [currentAccount, msalConfiguration, apiEnvironment.scopes, contextSettings]
  );
  const contextParams = useMemo(
    () => ({
      msalConfiguration,
      scopes: apiEnvironment.scopes,
      currentAccount
    }),
    [msalConfiguration, currentAccount, apiEnvironment.scopes]
  );
  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const { tenantId } = useContext(TenantContext);

  useEffect(() => {
    if (nodeId) {
      storeCurrentNodeId(tenantId, nodeId);
    }
  }, [nodeId, tenantId]);

  useEffect(() => {
    if (connection) {
      connection.connect();

      return () => {
        connection.disconnect();
      };
    }
  }, [connection]);

  return (
    <BlockerContainer>
      <AuthenticationContext.Provider value={contextParams}>
        <EventHubServiceContext.Provider value={connection}>
          {children}
        </EventHubServiceContext.Provider>
      </AuthenticationContext.Provider>
    </BlockerContainer>
  );
};

export default BaseContainer;
