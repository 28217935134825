/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ActivityLogListResponseModel {
  /** @format int32 */
  totalPages: number;
  activityLogs?: ActivityLogResponseModel[];
}

export interface ActivityLogResponseModel {
  /** @format guid */
  activityLogId?: string;
  /** @format guid */
  locationId: string;
  /** @format guid */
  userId?: string;
  username?: string;
  message?: string;
  /** @format date-time */
  created?: string;
  /**
   * Gets or sets the signal identifier. This is only set if ActivityLogType is Signal.
   * @format guid
   */
  signalId?: string;
  /** Gets or sets the name of the signal. This is only set if ActivityLogType is Signal. */
  signalName?: string;
  signalDescription?: string;
  /** @format double */
  oldValue?: number;
  /** @format double */
  newValue?: number;
  /** This enumeration contains the different types of activity logs that we can have. */
  activityLogType: ActivityLogType;
  /** @format guid */
  unitId: string;
}

/** This enumeration contains the different types of activity logs that we can have. */
export enum ActivityLogType {
  Unknown = 'Unknown',
  User = 'User',
  System = 'System',
  Signal = 'Signal'
}

export interface ProblemDetails {
  type?: string;
  title?: string;
  /** @format int32 */
  status?: number;
  detail?: string;
  instance?: string;
  extensions?: Record<string, any>;
  [key: string]: any;
}

/**
 * Represents the different type of grids.
 * May be combined.
 */
export enum GridType {
  Unknown = 'Unknown',
  Heating = 'Heating',
  Cooling = 'Cooling',
  Electricity = 'Electricity',
  Ectogrid = 'Ectogrid',
  Generic = 'Generic',
  Inherited = 'Inherited'
}

export interface AddActivityLogRequestModel {
  /** @format guid */
  locationId: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  message: string;
}

/**
 * Returns a specific Admin Alarm Signal Group template.
 * Alarm Signal Group template is used to group Alarms for an equipment template
 * that it self is a part of a bigger template used to create full buildings at once.
 */
export interface AdminAlarmSignalGroupTemplateResponseModel {
  /**
   * Id for that template group template.
   * @format guid
   */
  alarmSignalGroupTemplateId: string;
  /**
   * Name that is visible in
   * https://admin.ectocloud.io/controlPanel/alarms
   */
  name?: string;
  /** List of alarm templates that belong to the group. */
  alarmSignalTemplates?: AdminAlarmSignalTemplateResponseModel[];
}

/**
 * Alarm Signal Template is used as a part of a big template group to instantiate a full building.
 * Alarm Signal Template is a part of a group template that is a part of an equipment template.
 */
export interface AdminAlarmSignalTemplateResponseModel {
  /**
   * Template Id
   * @format guid
   */
  id: string;
  /** Display name */
  name?: string;
  /** IsWritable specifies if the frontend should/can allow the user the user to update the value. */
  isWritable: boolean;
  /** Returns the Signal Settings Template for that signal */
  signalSettings?: SignalSettingsTemplateResponseModel;
  /** User friendly Description */
  description?: string;
  /**
   * Alarm severity is handled by the backend as simple integers while frontend tend to use letters
   * 0 = A
   * 1 = B
   * 2 = C
   * This may not be a complete list. In the future there can be more or changed mappings.
   * @format int32
   */
  severity: number;
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
   * Stateless - Can be seen as notifications for the user.
   */
  alarmType: AlarmType;
  /** Format of the timeseries. Continues or Discrete. */
  dataFormat: DataFormat;
  /** If the signal is an input or output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. This list can be empty. */
  signalCategoryIds?: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
  /** Gets or sets if alarm signal should be acknowledged by an user. */
  needAcknowledgment: boolean;
}

/**
 * Signal Settings template includes information needed to create a Signals Settings entity in the IoT domain.
 * Signal Settings is all the data the IoT domain needs for the Energy Manager to handle the signal.
 * A signal does not always have not have any SignalSettings.
 */
export interface SignalSettingsTemplateResponseModel {
  /**
   * Modbus address (address notation starting at 1). Valid range: 1-65535
   *
   * @format int32
   */
  modbusAddress?: number;
  /**
   * Default value to give EM for this signal.
   * @format float
   */
  defaultValue?: number;
  /** Must be set for all signal settings and may not be set to  Unknown. */
  signalFunction?: SignalFunction;
  /** Modbus configuration template that would used if the connection was in slave mode on the EM. */
  signalModbusConfigSlave?: SignalModbusConfigTemplateResponseModel;
  /** Modbus configuration template that would used if the connection was in master mode on the EM. */
  signalModbusConfigMaster?: SignalModbusConfigTemplateResponseModel;
}

/** Signal Function is used to give the EM a way to understand that a signal is to be used with a function.  */
export enum SignalFunction {
  Unknown = 'Unknown',
  None = 'None',
  MobileSignalStrength = 'MobileSignalStrength'
}

/**
 * Template for signal modbus config.
 * A signal may need to have modbus configuration set for it to work properly on the EM.
 */
export interface SignalModbusConfigTemplateResponseModel {
  /** Signal modbus type, if set, may not be Unknown. */
  type?: SignalModbusType;
  /** Specifies possible watchdog behaviour */
  signalModbusWatchdog?: SignalModbusWatchdogTemplateResponseModel;
  /**
   * If bitwise Modbus register values should be read or written, specify which bit (0-15).
   * Value -1 sets to non-bitwise.
   * @format int32
   */
  bit?: number;
  /** Sets if "int" (integer) or "real"(float) (device default: "int") */
  datatype?: ModbusDataType;
  /**
   * Sets interpreted byte order as "abcd", "badc", "cdab" or "dcba".
   * Used for datatype real and int with size 2 (only "abcd" and "cdab" valid) or 4.
   */
  byteOrder?: ByteOrder;
  /**
   * Sets the Modbus sync interval for master mode in seconds.
   * @format int32
   */
  delayModbus?: number;
  /**
   * Conversion factor between raw and decimal value.
   * @format float
   */
  factor?: number;
  /**
   * Size of Modbus value in Modbus registers (1, 2 and 4 supported giving 16, 32 and 64 bit)
   * @format int32
   */
  size?: number;
  /**
   * Sets if value is r read or w write for holding registers and coil.
   *
   */
  readwrite?: ReadWrite;
  /**
   * Sets minimum database sync/update interval in seconds.
   * @format int32
   */
  delayDb?: number;
  /** Value if it is signed, ie can be negative/positive or only positive (true or false) */
  signed?: boolean;
  /**
   * Sets minimum value change in decimal for a value to be logged or changed in seconds.
   * @format float
   */
  step?: number;
  /** If enabled and this config will be included into Signals file config. */
  disabled?: boolean;
  /** Specifies if the Request model is master or slave. */
  isMaster: boolean;
}

/**
 * Signal Modbus type specifies the  Modbus register type.
 * Definitions taken from http://www.modbus.org/docs/Modbus_Application_Protocol_V1_1b3.pdf
 * The EM needs to know what register type to use.
 */
export enum SignalModbusType {
  Unknown = 'Unknown',
  DiscreteInput = 'discrete input',
  Coil = 'coil',
  InputRegister = 'input register',
  HoldingRegister = 'holding register'
}

/** Template to be used for creating a watchdog. */
export interface SignalModbusWatchdogTemplateResponseModel {
  /**
   * Duty cycle period in seconds for write watchdog.
   * A read watchdog signal logs timeout if period is exceeds without external toggling (and may also set an associated alarm signal).
   * If a modbus watchdog is specified the Period must be set!
   * @format int32
   */
  period?: number;
  /**
   * (Required for write signal) Number of seconds to keep watchdog value at 1. Required for write signal. Must be less than period.
   *
   * @format int32
   */
  high?: number;
  /**
   * (Only valid for write signal) Signal type identifier for the enable signal watchdog signal.
   * When a template is applied as a part of a template group,
   * that signal type identifier will be looked up and GUID for the signal found will be used instead.
   * @format guid
   */
  enableSignalTypeId?: string;
  /**
   * (Only valid for read signal) Signal type identifier for the alarm signal to set on timeout. Only used for read watchdog.
   * When a template is applied as a part of a template group,
   * that signal type identifier will be looked up and GUID for the signal found will be used instead.
   * @format guid
   */
  alarmSignalTypeId?: string;
}

/** Sets if to read the data as an "int" (integer) or "real"(float). */
export enum ModbusDataType {
  Unknown = 'Unknown',
  Int = 'int',
  Real = 'real'
}

/**
 * Sets interpreted byte order as "abcd", "badc", "cdab" or "dcba".
 * Used for datatype real and int with size 2 (only "abcd" and "cdab" valid) or 4.
 */
export enum ByteOrder {
  Unknown = 'Unknown',
  Abcd = 'abcd',
  Badc = 'badc',
  Cdab = 'cdab',
  Dcba = 'dcba'
}

/** Sets if value is r read or w write for holding registers and coils. */
export enum ReadWrite {
  Unknown = 'Unknown',
  Read = 'Read',
  Write = 'Write'
}

/**
 * There are two types of alarms in Ectocloud.
 * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
 * Stateless - Can be seen as notifications for the user.
 */
export enum AlarmType {
  Unknown = 'Unknown',
  Stateful = 'Stateful',
  Stateless = 'Stateless'
}

/** Format of the timeseries. Discrete or Continuous. */
export enum DataFormat {
  Unknown = 'Unknown',
  Continuous = 'Continuous',
  Discrete = 'Discrete'
}

/** If the signal is an input signal or an output signal. */
export enum SignalDirection {
  Unknown = 'Unknown',
  Input = 'Input',
  Output = 'Output'
}

/** How the data is suggested to be showed in the graph. */
export enum GraphicalRepresentation {
  Unknown = 'Unknown',
  Event = 'Event',
  Step = 'Step',
  Linear = 'Linear',
  Spline = 'Spline'
}

/** Groups several AlarmSignalTemplates into one group to be used for building templates. */
export interface AddOrUpdateAlarmSignalGroupTemplateRequestModel {
  /**
   * Name to be used in the frontend so the user can know what the template does
   * @minLength 1
   */
  name: string;
  /**
   * ID identifying this template.
   * If GUID is not found in the database  a new template will be created.
   * IF GUID is found the template will be updated according to the new one.
   * @format guid
   */
  alarmSignalGroupTemplateId: string;
  /** AlarmSignalTemplates are used to specify all the alarm information needed when creating a group of alarms. */
  alarmSignalTemplates: AdminAlarmSignalTemplateRequestModel[];
}

/**
 * The Template included all information needed to create an alarm that includes the information needed
 * for SignalProvider, IoT and the Alarm domain.
 */
export interface AdminAlarmSignalTemplateRequestModel {
  /**
   * If one wants to update an existing template, that specific GUID must be specified.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name of the signal.
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /** Is user allowed to write to the signal in the frontend */
  isWritable: boolean;
  /**
   * Specifies any Signal settings needed for the alarm to work properly on the EM
   * This includes modbus parameters, default signal value and signal function.
   */
  signalSettings?: SignalSettingsTemplateRequestModel;
  /** Human readable description shown in Admin frontend. */
  description?: string;
  /**
   * Alarm severity is handled by the backend as simple integers while frontend tend to use letters
   * 0 = A
   * 1 = B
   * 2 = C
   * This may not be a complete list. In the future there can be more or changed mappings.
   * @format int32
   */
  severity: number;
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
   * Stateless - Can be seen as notifications for the user.
   * Unknown is not allowed to be used.
   */
  alarmType: AlarmType;
  /** Format of the timeseries. Continues or Discrete. */
  dataFormat: DataFormat;
  /** If the signal is an input or output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. It is ok to leave this list empty. */
  signalCategoryIds: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
  /** Gets or sets if alarm needs acknowledgment form a user or not. */
  needAcknowledgment: boolean;
}

/**
 * Signal Settings template includes information needed to create a Signals Settings entity in the IoT domain.
 * Signal Settings is all the data the IoT domain needs for the Energy Manager to handle the signal.
 * A signal does not always have not have any SignalSettings.
 */
export interface SignalSettingsTemplateRequestModel {
  /**
   * Modbus address (address notation starting at 1). Valid range: 1-65535
   *
   * @format int32
   */
  modbusAddress?: number;
  /**
   * Default value to give EM for this signal.
   * @format float
   */
  defaultValue?: number;
  /** Must be set for all signal settings and may not be set to  Unknown. */
  signalFunction?: SignalFunction;
  /** Modbus configuration template that would used if the connection was in slave mode on the EM. */
  signalModbusConfigSlave?: SignalModbusConfigTemplateRequestModel;
  /** Modbus configuration template that would used if the connection was in master mode on the EM. */
  signalModbusConfigMaster?: SignalModbusConfigTemplateRequestModel;
}

/**
 * Template for signal modbus config.
 * A signal may need to have modbus configuration set for it to work properly on the EM.
 */
export interface SignalModbusConfigTemplateRequestModel {
  /** Signal modbus type, if set, may not be Unknown. */
  type?: SignalModbusType;
  /** Specifies possible watchdog behaviour */
  signalModbusWatchdog?: ModbusWatchdogTemplateRequestModel;
  /**
   * If bitwise Modbus register values should be read or written, specify which bit (0-15).
   * Value -1 sets to non-bitwise.
   * @format int32
   */
  bit?: number;
  /** Sets if "int" (integer) or "real"(float) (device default: "int") */
  datatype?: ModbusDataType;
  /**
   * Sets interpreted byte order as "abcd", "badc", "cdab" or "dcba".
   * Used for datatype real and int with size 2 (only "abcd" and "cdab" valid) or 4.
   */
  byteOrder?: ByteOrder;
  /**
   * Sets the Modbus sync interval for master mode in seconds.
   * For write signals, setting this to -1 ensures write on update only in Modbus master mode, i.e. no periodic writing.
   * @format int32
   */
  delayModbus?: number;
  /**
   * Conversion factor between raw and decimal value.
   * @format float
   */
  factor?: number;
  /**
   * Size of Modbus value in Modbus registers (1, 2 and 4 supported giving 16, 32 and 64 bit)
   * @format int32
   */
  size?: number;
  /**
   * Sets if value is Read or Write for holding registers and coil.
   * Read signals are defined as
   *    For slave connection: If type is "coil" or "holding register" and readwrite is "r".
   *    For master connection: If type is "discrete input" or "input register" or if readwrite is "r"
   * Write signals are defined as:
   *    For slave connection: If type is "discrete" input" or "input register" or if readwrite is "w".
   *    For master connection: If type is "coil" or "holding register" and readwrite is "w".
   *
   */
  readwrite?: ReadWrite;
  /**
   * Sets minimum database sync/update interval in seconds.
   * @format int32
   */
  delayDb?: number;
  /** Value if it is signed, ie can be negative/positive or only positive (true or false) */
  signed?: boolean;
  /**
   * Sets minimum value change in decimal for a value to be logged or changed in seconds.
   * @format float
   */
  step?: number;
  /** If enabled and this config will be included into Signals file config. */
  disabled?: boolean;
  /** Specifies if the Request model is master or slave. */
  isMaster: boolean;
}

/**
 * Template to be used for creating a watchdog.
 * Sets if value is watchdog toggled, e.g. if a written value should toggle between 1 and 0 with a period and high(1) time.
 */
export interface ModbusWatchdogTemplateRequestModel {
  /**
   * Duty cycle period in seconds for write watchdog.
   * A read watchdog signal logs timeout if period is exceeds without external toggling (and may also set an associated alarm signal).
   * If a modbus watchdog is specified the Period must be set!
   * @format int32
   */
  period: number;
  /**
   * (Required for write signal) Number of seconds to keep watchdog value at 1. Required for write signal. Must be less than period.
   *
   * @format int32
   */
  high?: number;
  /**
   * (Only valid for write signal) Signal type identifier for the enable signal watchdog signal.
   * When a template is applied as a part of a template group,
   * that signal type identifier will be looked up and GUID for the signal found will be used instead.
   * @format guid
   */
  enableSignalTypeId?: string;
  /**
   * (Only valid for read signal) Signal type identifier for the alarm signal to set on timeout. Only used for read watchdog.
   * When a template is applied as a part of a template group,
   * that signal type identifier will be looked up and GUID for the signal found will be used instead.
   * @format guid
   */
  alarmSignalTypeId?: string;
}

export interface DeleteAlarmSignalGroupTemplatesRequestModel {
  /**
   * Gets or sets the alarm signal group template ids to delete.
   * (This is the same thing as the signal provider template id.)
   */
  alarmSignalGroupTemplateIds: string[];
}

/**
 * Returns Equipment Signal data returned after add or update.
 * As this response is used in multiple places the request and response do not necessarily match on those APIs.
 */
export interface AdminEquipmentSignalResponseModel {
  /**
   * The signal Id
   * @format guid
   */
  signalId: string;
  /**
   * Signals are tied to to an equipment node.
   * The node Id in this case is the same as EquipmentId.
   * @format guid
   */
  equipmentId: string;
  /** Display name shown for the user. */
  name?: string;
  /** Description to show the user for that alarm */
  description?: string;
  /** Modbus settings for the signal, if any. */
  signalSettings?: SignalSettingsResponseModel;
  /**
   * Alarm severity is handled by the backend as simple integers while frontend tend to use letters
   * 0 = A
   * 1 = B
   * 2 = C
   * This may not be a complete list. In the future there can be more or changed mappings.
   * If the signal type is not "Alarm" this field should be ignored.
   * @format int32
   */
  severity: number;
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
   * Stateless - Can be seen as notifications for the user.
   * If the signal type is not "Alarm" this field should be ignored.
   */
  alarmType: AlarmType;
  /** IsWritable specifies if the frontend should/can allow the user the user to update the value. */
  isWritable: boolean;
  /** Specifies the signal type. */
  type: AdminEquipmentSignalType;
  /**
   * The connection the alarm signal belongs to in the IoT domain.
   * @format guid
   */
  connectionId?: string;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Format of the timeseries. Discrete or Continuous. */
  dataFormat: DataFormat;
  /** Gets or sets the signal category identifiers. This list may be empty. */
  signalCategoryIds?: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
  /** Gets or sets if the signal should be acknowledged by an user. */
  needAcknowledgment: boolean;
}

/** Signal Settings Response model */
export interface SignalSettingsResponseModel {
  /**
   * Modbus address (address notation starting at 1). Valid range: 1-65535
   *
   * @format int32
   */
  modbusAddress?: number;
  /**
   * Default value to give EM for this signal.
   * @format float
   */
  defaultValue?: number;
  /** Signal Function is used to give the EM a way to understand that a signal is to be used with a function. */
  signalFunction: SignalFunction;
  /** Configuration used if connection is in slave mode */
  signalModbusConfigSlave?: SignalModbusConfigResponseModel;
  /** Configuration used if connection is in master mode */
  signalModbusConfigMaster?: SignalModbusConfigResponseModel;
}

export interface SignalModbusConfigResponseModel {
  /** Signal Modbus type specifies the Modbus register type the EM needs to use when communicating with the BMS. */
  type: SignalModbusType;
  /** Specifies possible watchdog behaviour */
  signalModbusWatchdog?: SignalModbusWatchdogResponseModel;
  /**
   * If bitwise Modbus register values should be read or written, specify which bit (0-15).
   * Value -1 sets to non-bitwise.
   * @format int32
   */
  bit?: number;
  /** Sets if "int" (integer) or "real"(float) (device default: "int") */
  datatype?: ModbusDataType;
  /**
   * Sets interpreted byte order as "abcd", "badc", "cdab" or "dcba".
   * Used for datatype real and int with size 2 (only "abcd" and "cdab" valid) or 4.
   */
  byteOrder?: ByteOrder;
  /**
   * Sets the Modbus sync interval for master mode in seconds.
   * For write signals, setting this to -1 ensures write on update only in Modbus master mode, i.e. no periodic writing.
   * @format int32
   */
  delayModbus?: number;
  /**
   * Conversion factor between raw and decimal value.
   * @format float
   */
  factor?: number;
  /**
   * Size of Modbus value in Modbus registers (1, 2 and 4 supported giving 16, 32 and 64 bit)
   * @format int32
   */
  size?: number;
  /**
   * Sets if value is r read or w write for holding registers and coil.
   * Read signals are defined as
   *    For slave connection: If type is "coil" or "holding register" and readwrite is "r".
   *    For master connection: If type is "discrete input" or "input register" or if readwrite is "r"
   * Write signals are defined as:
   *    For slave connection: If type is "discrete" input" or "input register" or if readwrite is "w".
   *    For master connection: If type is "coil" or "holding register" and readwrite is "w".
   *             Please that these rules are not currently enforced by the backend.
   *
   */
  readwrite?: ReadWrite;
  /**
   * Sets minimum database sync/update interval in seconds.
   * @format int32
   */
  delayDb?: number;
  /** Value if it is signed, ie can be negative/positive or only positive (true or false) */
  signed?: boolean;
  /**
   * Sets minimum value change in decimal for a value to be logged or changed in seconds.
   * @format float
   */
  step?: number;
  /** If enabled and this config will be included into Signals file config. */
  disabled?: boolean;
  /** Specifies if the Request model is master or slave. */
  isMaster: boolean;
}

/** Sets if value is watchdog toggled, e.g. if a written value should toggle between 1 and 0 with a period and high(1) time. */
export interface SignalModbusWatchdogResponseModel {
  /**
   * Duty cycle period in seconds for write watchdog.
   * A read watchdog signal logs timeout if period is exceeds without external toggling (and may also set an associated alarm signal).
   * @format int32
   */
  period: number;
  /**
   * (Required for write signal) Number of seconds to keep watchdog value at 1. Required for write signal. Must be less than period.
   *
   * @format int32
   */
  high?: number;
  /**
   * (Only valid for write signal) Signal ID of enable signal watchdog.
   * @format guid
   */
  enableSignalId?: string;
  /**
   * (Only valid for read signal) Signal ID of alarm signal to set on timeout. Only used for read watchdog.
   * @format guid
   */
  alarmSignalId?: string;
}

/** Specifies the Signal type when returning AdminEquipmentSignals */
export enum AdminEquipmentSignalType {
  Unknown = 'Unknown',
  Equipment = 'Equipment',
  Alarm = 'Alarm',
  PowerControl = 'PowerControl',
  LinearOptimisation = 'LinearOptimisation'
}

/** Information needed to add or update an alarm signal */
export interface AddOrUpdateAlarmSignalsRequestModel {
  /**
   * If guid must be specified, and if not in database a new signal and a new guid will be created.
   * @format guid
   */
  signalId: string;
  /**
   * Alarm signals are tied to to an equipment node.
   * The node Id in this case is the same as EquipmentId.
   * @format guid
   */
  equipmentId: string;
  /**
   * Gets or sets the name of the signal.
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /**
   * Description to show the user for that alarm
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /** Is user allowed to write to the signal in the frontend */
  isWritable: boolean;
  /** Modbus settings for the alarm signal, if any. */
  signalSettings?: SignalSettingsRequestModel;
  /**
   * Alarm severity is handled by the backend as simple integers while frontend tend to use letters
   * 0 = A
   * 1 = B
   * 2 = C
   * This may not be a complete list. In the future there can be more or changed mappings.
   * @format int32
   */
  severity: number;
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
   * Stateless - Can be seen as notifications for the user.
   * Unknown is not allowed to be used.
   */
  alarmType: AlarmType;
  /**
   * The connection the alarm signal belongs to in the IoT domain.
   * @format guid
   */
  connectionId?: string;
  /** Format of the timeseries. Continues or Discrete. */
  dataFormat: DataFormat;
  /** If the signal is an input or output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. It is ok to leave this list empty. */
  signalCategoryIds: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
  /** Gets or sets if alarm needs acknowledgment form a user or not. */
  needAcknowledgment: boolean;
}

/**
 * Signal Settings is all the data the IoT domain needs for the Energy Manager to handle the signal.
 * A signal may not have any SignalSettings.
 */
export interface SignalSettingsRequestModel {
  /**
   * Modbus address (address notation starting at 1). Valid range: 1-65535
   *
   * @format int32
   */
  modbusAddress?: number;
  /**
   * Default value to give EM for this signal.
   * @format float
   */
  defaultValue?: number;
  /**
   * Must be set for all signal settings and may not be set to  Unknown.
   * By default signals should be set SignalFunction to None.
   */
  signalFunction: SignalFunction;
  /** Modbus configuration that would used if the connection was in slave mode on the EM. */
  signalModbusConfigSlave?: SignalModbusConfigRequestModel;
  /** Modbus configuration that would be used if the connection was in master mode in the EM. */
  signalModbusConfigMaster?: SignalModbusConfigRequestModel;
}

/** A signal may need to have modbus configuration set for it to work properly on the EM. */
export interface SignalModbusConfigRequestModel {
  /**
   * Signal modbus type is required to be set and may not be Unknown.
   * Signal Modbus type specifies the Modbus register type the EM needs to use when communicating with the BMS.
   */
  type: SignalModbusType;
  /** Specifies possible watchdog behaviour */
  signalModbusWatchdog?: ModbusWatchdogRequestModel;
  /**
   * If bitwise Modbus register values should be read or written, specify which bit (0-15).
   * Value -1 sets to non-bitwise.
   * @format int32
   */
  bit?: number;
  /** Sets if "int" (integer) or "real"(float) (device default: "int") */
  datatype?: ModbusDataType;
  /**
   * Sets interpreted byte order as "abcd", "badc", "cdab" or "dcba".
   * Used for datatype real and int with size 2 (only "abcd" and "cdab" valid) or 4.
   */
  byteOrder?: ByteOrder;
  /**
   * Sets the Modbus sync interval for master mode in seconds.
   * For write signals, setting this to -1 ensures write on update only in Modbus master mode, i.e. no periodic writing.
   * @format int32
   */
  delayModbus?: number;
  /**
   * Conversion factor between raw and decimal value.
   * @format float
   */
  factor?: number;
  /**
   * Size of Modbus value in Modbus registers (1, 2 and 4 supported giving 16, 32 and 64 bit)
   * @format int32
   */
  size?: number;
  /**
   * Sets if value is Read or Write for holding registers and coil.
   * Read signals are defined as
   *    For slave connection: If type is "coil" or "holding register" and readwrite is "r".
   *    For master connection: If type is "discrete input" or "input register" or if readwrite is "r"
   * Write signals are defined as:
   *    For slave connection: If type is "discrete" input" or "input register" or if readwrite is "w".
   *    For master connection: If type is "coil" or "holding register" and readwrite is "w".
   *             Please that these rules are not currently enforced by the backend.
   *             TODO Enforce rules!
   *
   */
  readwrite?: ReadWrite;
  /**
   * Sets minimum database sync/update interval in seconds.
   * @format int32
   */
  delayDb?: number;
  /** Value if it is signed, ie can be negative/positive or only positive (true or false) */
  signed?: boolean;
  /**
   * Sets minimum value change in decimal for a value to be logged or changed in seconds.
   * @format float
   */
  step?: number;
  /** If enabled and this config will be included into Signals file config. */
  disabled?: boolean;
  /** Specifies if the Request model is master or slave. */
  isMaster: boolean;
}

/** Sets if value is watchdog toggled, e.g. if a written value should toggle between 1 and 0 with a period and high(1) time. */
export interface ModbusWatchdogRequestModel {
  /**
   * Duty cycle period in seconds for write watchdog.
   * A read watchdog signal logs timeout if period is exceeds without external toggling (and may also set an associated alarm signal).
   * If a modbus watchdog is specified the Period must be set!
   * @format int32
   */
  period: number;
  /**
   * (Required for write signal) Number of seconds to keep watchdog value at 1. Required for write signal. Must be less than period.
   *
   * @format int32
   */
  high?: number;
  /**
   * (Only valid for write signal) Signal ID of enable signal watchdog.
   * @format guid
   */
  enableSignalId?: string;
  /**
   * (Only valid for read signal) Signal ID of alarm signal to set on timeout. Only used for read watchdog.
   * @format guid
   */
  alarmSignalId?: string;
}

/** All information needed to instantiate a building. */
export interface CreateBuildingByTemplateDataResponseModel {
  /** Data used to create the building node and its equipment nodes */
  addOrUpdateNodes?: AddOrUpdateNodeResponseModel[];
  /** Data used to create equipment signal providers and all the equipment signals. */
  addOrUpdateEquipmentSignalProviders?: AddOrUpdateEquipmentSignalProviderResponseModel[];
  /** If a new device have to be created this data is used create it and then to update the device to handle the new building. */
  addOrUpdateDevices?: AddOrUpdateDeviceResponseModel[];
  /**
   * If a device already exists, this data is used to update the device to handle the new building
   * through a connection.
   */
  addOrUpdateConnections?: ConnectionResponseModel[];
  /** Add Alarm Signals to any relevant equipment. */
  addOrUpdateAlarmSignalProviders?: AddOrUpdateAlarmSignalProviderResponseModel[];
  /** Add Power Control to all nodes that should have it. */
  addOrUpdatePowerControls?: AddOrUpdatePowerControlPowerControlResponseModel[];
  /** Add Linear Optimisation to any equipment that should have it. */
  addOrUpdateLinearOptimisations?: AddOrUpdateLinearOptimisationResponseModel[];
  /** Update the device Signal provider relationship so that the device knows all the equipment signals it is responsible for. */
  addOrUpdateDeviceSignalProviderRelationships?: AddOrUpdateDeviceSignalProviderRelationshipResponseModel[];
  /** Update the IoT domain with all needed signal information for each signal created here. */
  addOrUpdateIoTSignals?: AddOrUpdateIoTSignalResponseModel[];
}

/** Used to create a node in the location domain */
export interface AddOrUpdateNodeResponseModel {
  /**
   * The node Id this node is to have
   * @format guid
   */
  nodeId: string;
  /** What parents, if any, it shall be child to. */
  parentIds?: string[];
  /** Node name */
  name?: string;
  /** Node type. Ie if it is a building, site or equipment. */
  nodeType: NodeType;
  /**
   * Longitude of the node. (useful if it is a site or building)
   * @format double
   */
  longitude?: number;
  /**
   * Latitude of the node. (useful if it is a site or building)
   * @format double
   */
  latitude?: number;
  /** Street address of the node. (useful if its a building) */
  street?: string;
  /** Grids specify what the node can handle */
  grids?: GridType[];
  /**
   * Any tags the node should have.
   * Tags are free test fields useful for giving ad hoc info on the node.
   */
  tags?: string[];
  /** Gets or sets the description. */
  description?: string;
  /**
   * Gets or sets the equipment type identifier.
   * @format guid
   */
  equipmentTypeId?: string;
}

/** Type of node */
export enum NodeType {
  Unknown = 'Unknown',
  Site = 'Site',
  Building = 'Building',
  Equipment = 'Equipment'
}

/** All information needed to add an equipment signal provider to a node. */
export interface AddOrUpdateEquipmentSignalProviderResponseModel {
  /**
   * Node to put signal provider on.
   * @format guid
   */
  nodeId: string;
  /** Signal provider display name. */
  displayName?: string;
  /**
   * Signal provider Id for the alarm signals.
   * @format guid
   */
  signalProviderId: string;
  /**
   * The equipment template to use when creating the equipment.
   * @format guid
   */
  equipmentTemplateId: string;
  /** Equipment signals. */
  signals?: AddOrUpdateEquipmentSignalResponseModel[];
}

/** All info to add or update an equipment signal. */
export interface AddOrUpdateEquipmentSignalResponseModel {
  /**
   * Equipment signal Id.
   * @format guid
   */
  id: string;
  /**
   * Signal Template Id.
   * @format guid
   */
  signalTemplateId: string;
  /**
   * Equipment Id.
   * @format guid
   */
  equipmentId: string;
  /** Signal Display Name. */
  name?: string;
  /** IsWritable specifies if the frontend should/can allow the user the user to update the value. */
  isWritable: boolean;
  /** Signal Description. */
  description?: string;
  /** The Signal direction (input or output) */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Format of the timeseries. Discrete or Continues. */
  dataFormat: DataFormat;
  /** Gets or sets the signal category identifiers. It is ok to leave this list empty. */
  signalCategoryIds: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
}

/** Adds a device or updates an existing device. */
export interface AddOrUpdateDeviceResponseModel {
  /**
   * Device Id
   * @format guid
   */
  id: string;
  /**
   * Adds or updates Cloud To device records.
   * This should not normally be changed as they are generated by Backend.
   */
  cloudToDeviceRecords?: AddOrUpdateDeviceCloudToDeviceRecordResponseModel[];
  /** Adds or updates the connections with their signals and all modbus config. */
  connections?: ConnectionResponseModel[];
  /** Adds or updates all device signal provider relationships.  */
  deviceSignalProviderRelationships?: AddOrUpdateDeviceSignalProviderRelationshipWithIdResponseModel[];
}

/** Cloud record info created when doing Cloud To Device communication on IoT Hub. */
export interface AddOrUpdateDeviceCloudToDeviceRecordResponseModel {
  /**
   * Record Id-
   * @format guid
   */
  id: string;
  /**
   * When the message was received.
   * @format date-time
   */
  timestamp: string;
  /** Message status. */
  messageStatus: MessageStatus;
}

export enum MessageStatus {
  Unknown = 'Unknown',
  Sent = 'Sent',
  Success = 'Success',
  Expired = 'Expired',
  DeliveryCountExceeded = 'DeliveryCountExceeded',
  Rejected = 'Rejected',
  Purged = 'Purged',
  UnknownFeedbackStatusCode = 'UnknownFeedbackStatusCode'
}

/** Connection with its signal and modbus config */
export interface ConnectionResponseModel {
  /**
   * Connection Id.
   * Connection Id is always the same as the Equipment Id for the Equipment node it is connected to.
   * @format guid
   */
  id: string;
  /**
   * Device id the connection belongs to.
   * @format guid
   */
  deviceId: string;
  /** Human readable connection name used to distinguish a connection when a device has multiple. */
  name?: string;
  /** Connection Modbus config */
  connectionModbusConfig?: ConnectionModbusConfigResponseModel;
  /** Connection signals with their modbus config.  */
  signals?: IoTSignalResponseModel[];
}

/** Connection Modbus config response model. */
export interface ConnectionModbusConfigResponseModel {
  /**
   * Modbus config id
   * @format guid
   */
  id: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * ///
   * @format int32
   */
  byteTimeoutMicroseconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   */
  slaveId: number;
  /**
   * Watchdog modbus Alarm Signal Id.
   * @format guid
   */
  watchdogModbusAlarmSignalId: string;
  /**
   * Modbus watchdog timeout.
   *
   * @format int32
   */
  watchdogModbusTimeout: number;
  /**
   * Modbus Version Signal Id.
   * @format guid
   */
  modbusVersionSignalId: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor: number;
}

/**
 * Modbus mode - "slave", "master"
 * Slave is only allowed to read.
 */
export enum ModbusMode {
  Unknown = 'Unknown',
  Slave = 'slave',
  Master = 'master'
}

/** Modbus communication type - "RTU", "TCP" */
export enum ModbusType {
  Unknown = 'Unknown',
  Rtu = 'RTU',
  Tcp = 'TCP'
}

/**
 * Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd
 *
 */
export enum Parity {
  Unknown = 'Unknown',
  None = 'N',
  Even = 'E',
  Odd = 'O'
}

/** IoT Signal Response model */
export interface IoTSignalResponseModel {
  /**
   * Signal Id
   * @format guid
   */
  signalId: string;
  /** Signal Settings response model */
  signalSettings?: SignalSettingsResponseModel;
}

/** Relationship between device and signal provider gives the device a way to figure out all the signals its responsible for. */
export interface AddOrUpdateDeviceSignalProviderRelationshipWithIdResponseModel {
  /**
   * Relationship Id.
   * @format guid
   */
  id: string;
  /**
   * Device Id.
   * @format guid
   */
  deviceId: string;
  /**
   * Signal Provider Id
   * @format guid
   */
  signalProviderId: string;
}

/** All information needed to add alarm signals to a node. */
export interface AddOrUpdateAlarmSignalProviderResponseModel {
  /**
   * Node to put alarm signals on.
   * @format guid
   */
  nodeId: string;
  /** Alarm signal provider name. */
  name?: string;
  /**
   * Signal provider Id for the alarm signals.
   * @format guid
   */
  alarmSignalProviderId: string;
  /** Alarm signals to be created for that Alarm Signal provider. */
  signals?: AddOrUpdateAlarmSignalResponseModel[];
}

/** All data needed to create an alarm signal for an alarm signal provider. */
export interface AddOrUpdateAlarmSignalResponseModel {
  /**
   * Alarm Signal Id.
   * @format guid
   */
  id: string;
  /** Gets or sets the name of the signal. */
  name?: string;
  /** Type of alarm. */
  alarmType: AlarmType;
  /**
   * Alarm severity is handled by the backend as simple integers while frontend tend to use letters
   * 0 = A
   * 1 = B
   * 2 = C
   * This may not be a complete list. In the future there can be more or changed mappings.
   * @format int32
   */
  severity: number;
  /** Description of the Alarm Signal. */
  description?: string;
  /** Indicates if a user is allowed to write to the signal in the frontend. */
  isWritable: boolean;
  /**
   * Node this Alarm belongs to.
   * @format guid
   */
  nodeId: string;
  /**
   * Signal Template it is connected to.
   * @format guid
   */
  signalTemplateId: string;
  /**
   * Equipment Template eit is connected to.
   * @format guid
   */
  equipmentTemplateId: string;
  /** Format of the timeseries. Continues or Discrete. */
  dataFormat: DataFormat;
  /** If the signal is an input or output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. */
  signalCategoryIds?: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
  /** Gets or sets if alarm signal should be acknowledged by an user. */
  needAcknowledgment: boolean;
}

/** Information needed to add or update Power Control */
export interface AddOrUpdatePowerControlPowerControlResponseModel {
  /**
   * Node id to base the power control on.
   * @format guid
   */
  nodeId: string;
  /** Power control type. */
  powerControlType: PowerControlType;
  /** Power control algorithm type */
  algorithmType: PowerControlAlgorithmType;
  /**
   * Signal provider it belongs to.
   * @format guid
   */
  signalProviderId: string;
}

export enum PowerControlType {
  Unknown = 'Unknown',
  Heating = 'Heating',
  Cooling = 'Cooling'
}

export enum PowerControlAlgorithmType {
  Unknown = 'Unknown',
  Basic = 'Basic',
  Tracking = 'Tracking',
  Transmit = 'Transmit',
  TrackingVentilation = 'TrackingVentilation'
}

export interface AddOrUpdateLinearOptimisationResponseModel {
  /**
   * Node for the linear optimisation.
   * @format guid
   */
  nodeId: string;
  /** Number of points to give optimisation for. */
  numberOfPoints: number;
  /**
   * Signal provider Id for the optimisation signals.
   * @format guid
   */
  signalProviderId: string;
}

/** Relationship between a signal provider and a device. */
export interface AddOrUpdateDeviceSignalProviderRelationshipResponseModel {
  /**
   * Device Id
   * @format guid
   */
  deviceId: string;
  /**
   * Signal Provider Id
   * @format guid
   */
  signalProviderId: string;
}

/** Information for adding or updating IoT Signals. */
export interface AddOrUpdateIoTSignalResponseModel {
  /**
   * Signal Id for the signal to Add or update.
   * @format guid
   */
  signalId: string;
  /**
   * Connection id that the signal is to belong to.
   * @format guid
   */
  connectionId?: string;
  /** Signal settings that includes all modbus configuration. */
  signalSettings?: SignalSettingsResponseModel;
}

/** Request to get a detailed recipe of creating a building based on a group/building template */
export interface AddOrUpdateBuildingsByTemplatesRequestModel {
  /**
   * The template to base it on.
   * @format guid
   * @minLength 1
   */
  equipmentTemplateGroupId: string;
  /**
   * The node the building shall be under.
   * @format guid
   * @minLength 1
   */
  nodeId: string;
  /**
   * If new device Id is given a device matching that Id will be created
   * @format guid
   * @minLength 1
   */
  deviceId: string;
  /** Overrides, if any to the EM connection Modbus config values. */
  connectionModbusConfigOverride?: AddOrUpdateConnectionModbusConfigByTemplateRequestModel;
  /** Overrides, if any existing Equipment Templates within the Equipment Template group. */
  equipmentTemplateInstanceOverrides?: EquipmentTemplateInstanceOverrideRequestModel[];
}

/** Allows for adding or updating a connection modbus config template. */
export interface AddOrUpdateConnectionModbusConfigByTemplateRequestModel {
  /** @format guid */
  connectionModbusConfigTemplateId?: string;
  /**
   * Id for the modbus config.
   * If not set, a new one will be created.
   * @format guid
   */
  id?: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port?: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode?: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType?: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate?: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits?: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity?: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits?: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout?: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds?: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutMicroseconds?: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds?: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds?: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   */
  slaveId?: number;
  /**
   * Each connection must have a watchdog alarm Signal Id specified.
   * Signal Name in the template is looked up and replaced with Signal Id when instantiating the template.
   * @format guid
   */
  watchdogModbusAlarmSignalTypeId?: string;
  /**
   * Modbus watchdog timeout
   *
   * @format int32
   */
  watchdogModbusTimeout?: number;
  /**
   * Each connection must have a modbus version signal Signal Id specified.
   * Signal Name in the template is looked up and replaced with Signal Id when instantiating the template.
   * @format guid
   */
  modbusVersionSignalTypeId?: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus?: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb?: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus?: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb?: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback?: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor?: number;
}

export interface EquipmentTemplateInstanceOverrideRequestModel {
  /** @format guid */
  equipmentTemplateId: string;
  description?: string;
  enabled: boolean;
  name?: string;
}

export interface BuildingTemplateResponseModel {
  /**
   * Equipment template group id
   * @format guid
   */
  equipmentTemplateGroupId: string;
  /**
   * Name of the group template
   * shown in https://admin.ectocloud.io/controlPanel/buildings
   */
  name?: string;
  /** Description of the group template. */
  description?: string;
  /**
   * Always includes just one connection template.
   * In the future it may change.
   */
  connectionModbusConfig?: ConnectionModbusConfigTemplateResponseModel;
  /** Equipment templates that specify Equipment information needed when creating an Equipment. */
  equipmentTemplates?: EquipmentTemplateResponseModel[];
}

/**
 * Connection Modbus config template does not need to include all data as
 * when instantiating a group template, the backend will use default values
 * set the database when no template values are given.
 * Return data for the connection modbus config template.
 */
export interface ConnectionModbusConfigTemplateResponseModel {
  /**
   * Connection Modbus Config Template Id that can be used when updating this specific template.
   * @format guid
   */
  id: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port?: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode?: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType?: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate?: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits?: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity?: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits?: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout?: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds?: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * ///
   * @format int32
   */
  byteTimeoutMicroseconds?: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds?: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds?: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   */
  slaveId?: number;
  /**
   * Signal type identifier for the Watchdog modbus alarm.
   * @format guid
   */
  watchdogModbusAlarmSignalTypeId?: string;
  /**
   * Modbus watchdog timeout
   *
   * @format int32
   */
  watchdogModbusTimeout?: number;
  /**
   * Signal type identifier for the Modbus version signal.
   * @format guid
   */
  modbusVersionSignalTypeId?: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus?: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb?: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus?: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb?: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback?: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor?: number;
}

export interface EquipmentTemplateResponseModel {
  /**
   * If no guid is given a new Id will be created and it will be treated as a new equipment template.
   * Please observe that
   * @format guid
   */
  id: string;
  /** Name for the equipment template to have. */
  name?: string;
  /**
   * Alarm template to use for this equipment template.
   * @format guid
   */
  alarmSignalGroupTemplateId?: string;
  /**
   * EquipmentType id to use.
   * When instantiating the equipment first the equipment type is used.
   * For example it could be  "Ectogrid Hot Tap" and thus it includes
   * flowTemp and returnTemp Signals or "Energy Manager"
   * with its signals.
   * @format guid
   */
  equipmentTypeId: string;
  /** Description. */
  description?: string;
  /** Specifies a list of Tool types to apply on the equipment. */
  toolTypes?: string[];
  /**
   * List of Signal Template Overrides to be used to add signal or modify signals already
   * a apart of selected Equipment Type.
   */
  signalTemplateOverrides?: EquipmentSignalTemplateOverrideResponseModel[];
}

/**
 * Returns Signal Template override for a Signal group template.
 * The overrides are used to change default signal behaviours for signals
 * in the EquipmentType or add signals
 * when creating a building based on the group template.
 */
export interface EquipmentSignalTemplateOverrideResponseModel {
  /**
   * Id of the Equipment Signal Template Override
   * @format guid
   */
  id: string;
  /** Display name */
  name?: string;
  /** Description */
  description?: string;
  /**
   * SignalTemplate to override
   * @format guid
   */
  signalTemplateId: string;
  /** Signal Template override includes settings template to use. Only set values will be used in the override.  */
  signalTemplateOverride?: SignalTemplateOverrideResponseModel;
}

/**
 * Returns Signal Template override for a Signal group template.
 * The overrides are used to change default signal behaviours for signals
 * in the EquipmentType or add signals
 * when creating a building based on the group template.
 */
export interface SignalTemplateOverrideResponseModel {
  /**
   * Id of the Equipment Signal Template Override
   * @format guid
   */
  id: string;
  /** Settings template to use. Only set values will be used in the override.  */
  signalSettings?: SignalSettingsTemplateResponseModel;
}

/**
 * Adds or updates a group template.
 * Group templates are then used to create a building with its equipment, Configuration and tools in one shot.
 * This template is complex as it needs to specify data stored in multiple domains (IoT, SignalProvider, Equipment, Alarm)
 */
export interface AddOrUpdateBuildingTemplateRequestModel {
  /**
   * Description is used to tell the user what the template can create.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /**
   * Name for the group template
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * If one is given the existing template in the database will be updated, otherwise new template will be created.
   * @format guid
   */
  equipmentTemplateGroupId: string;
  /**
   * The group template, currently, is built with the idea that a building only has one connection.
   * The connection modbus config template needs to be specified
   */
  connectionModbusConfig: AddOrUpdateConnectionModbusConfigTemplateRequestModel;
  /** All equipment are specified here. */
  equipmentTemplates: AddOrUpdateEquipmentTemplateRequestModel[];
}

/** Adds or updates Connection Modbus Config template. */
export interface AddOrUpdateConnectionModbusConfigTemplateRequestModel {
  /**
   * Id for the modbus config.
   * If not set, a new one will be created.
   * @format guid
   */
  id?: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port?: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode?: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType?: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate?: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits?: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity?: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits?: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout?: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds?: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutMicroseconds?: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds?: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds?: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   */
  slaveId?: number;
  /**
   * Signal type identifier for the Watchdog modbus alarm.
   * @format guid
   */
  watchdogModbusAlarmSignalTypeId?: string;
  /**
   * Modbus watchdog timeout
   *
   * @format int32
   */
  watchdogModbusTimeout?: number;
  /**
   * Signal type identifier for the Modbus version signal.
   * @format guid
   */
  modbusVersionSignalTypeId?: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus?: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb?: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus?: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb?: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback?: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor?: number;
}

/**
 * Equipment template specifies what Equipment it is (equipmentTypeId),
 * what alarms are on that equipment, what tools this equipment should have (PowerControl, LineOpt..),
 * and what signal overrides it should have (Signals are otherwise specified by EquipmentTypeId, but can be
 * added to or overridden by SignalTemplateOverrides)
 */
export interface AddOrUpdateEquipmentTemplateRequestModel {
  /**
   * Gets or sets the identifier. If the identifier already exists then an update will
   * be performed; otherwise a new entity will be added.
   * @format guid
   */
  id: string;
  /** Name for the equipment template to have. */
  name?: string;
  /** Description. */
  description?: string;
  /**
   * Alarm template to use for this equipment template.
   * @format guid
   */
  alarmSignalGroupTemplateId: string;
  /**
   * EquipmentType id to use.
   * When instantiating the equipment first the equipment type is used.
   * For example it could be  "Ectogrid Hot Tap" and thus it includes
   * flowTemp and returnTemp Signals or "Energy Manager"
   * with its signals.
   * @format guid
   */
  equipmentTypeId: string;
  /**
   * Specifies a list of Tool types to apply on the equipment.
   *
   */
  toolTypes: ToolType[];
  /**
   * List of Signal Template Overrides to be used to add signal or modify signals already
   * a apart of selected Equipment Type.
   */
  signalTemplateOverrides: AddOrUpdateAlarmOrEquipmentSignalTemplateOverrideRequestModel[];
}

/**
 * Used in templates to specify the tools to be created for a equipment.
 * Each tool require different signals to be added and other configurations done in the
 * backend system.
 */
export enum ToolType {
  Unknown = 'Unknown',
  PowerControlHeatingTracking = 'PowerControlHeatingTracking',
  PowerControlHeatingBasic = 'PowerControlHeatingBasic',
  PowerControlCoolingTracking = 'PowerControlCoolingTracking',
  PowerControlCoolingBasic = 'PowerControlCoolingBasic',
  LinearOptimisation = 'LinearOptimisation',
  PowerControlHeatingTransmit = 'PowerControlHeatingTransmit',
  PowerControlCoolingTransmit = 'PowerControlCoolingTransmit',
  PowerControlHeatingTrackingVentilation = 'PowerControlHeatingTrackingVentilation',
  PowerControlCoolingTrackingVentilation = 'PowerControlCoolingTrackingVentilation'
}

/**
 * Adds or updates Signal Template override for a Signal group template.
 * The overrides are used to change default signal behaviours for signals
 * in the EquipmentType or add signals
 * when creating a building based on the group template.
 */
export interface AddOrUpdateAlarmOrEquipmentSignalTemplateOverrideRequestModel {
  /**
   * Gets or sets the identifier. If the identifier already exists then an update will
   * be performed; otherwise a new entity will be added.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name of the signal.
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /** Description */
  description?: string;
  /**
   * SignalTemplate to override
   * @format guid
   */
  signalTemplateId: string;
  /** Signal Template Override includes Settings template to use. Only set values will be used in the override. */
  signalTemplateOverride?: AddOrUpdateSignalTemplateOverrideRequestModel;
}

/**
 * The overrides are used to change default signal behaviours for signals
 * in the EquipmentType or add signals
 * when creating a building based on the group template.
 */
export interface AddOrUpdateSignalTemplateOverrideRequestModel {
  /**
   * Id of the Equipment Signal Template Override
   * @format guid
   */
  id: string;
  /** Settings template to use. Only set values will be used in the override. */
  signalSettings?: SignalSettingsTemplateRequestModel;
}

export interface BuildingListResponseModel {
  /**
   * Gets or sets the total amount of pages.
   * @format int32
   */
  totalPages: number;
  /**
   * Gets or sets the total amount of records.
   * @format int32
   */
  totalRecords: number;
  /** Gets or sets the buildings. */
  buildings?: SimpleNodeResponseModel[];
}

export type SimpleNodeResponseModel = BaseNodeResponseModel & {
  parentIds?: string[];
  tags?: string[];
};

export interface BaseNodeResponseModel {
  /** @format guid */
  nodeId: string;
  name?: string;
  /** @format double */
  longitude?: number;
  /** @format double */
  latitude?: number;
  street?: string;
  description?: string;
  /** Type of node */
  nodeType: NodeType;
  grids?: GridType[];
  buildingInfo?: BuildingInfoResponseModel;
}

export interface BuildingInfoResponseModel {
  /** @format guid */
  id: string;
  /** @format guid */
  buildingId: string;
  districtHeatingFacilityId?: string;
  /** Gets or sets the building status. */
  buildingStatus: BuildingStatus;
  /**
   * Gets or sets the timestamp when the building status changed.
   * @format date-time
   */
  buildingStatusTimestamp: string;
}

/** This enumeration contains the different status that a building can have. */
export enum BuildingStatus {
  Unknown = 'Unknown',
  Created = 'Created',
  Testing = 'Testing',
  Production = 'Production'
}

export interface GetBuildingsByFiltersRequestModel {
  /**
   * Gets or sets the parent node identifier. If it is used then only nodes directly below this node is searched.
   * @format guid
   */
  parentNodeId?: string;
  /** Gets or sets the building statuses. */
  buildingStatuses: BuildingStatus[];
  /**
   * Gets or sets the tags. All the supplied tags must be matched by the buildings.
   * Tags that don't exist in the database are removed before filtering.
   */
  tags: string[];
  /**
   * Gets or sets the building status timestamp. This will lead to that buildings
   * that had any of the supplied "statuses" at this time are returned.
   * @format date-time
   */
  buildingStatusTimestamp?: string;
  /**
   * Gets or sets the type of the building status timestamp comparison. For this to work then BuildingStatuses and BuildingStatusTimestamp
   * must have values.
   */
  buildingStatusTimestampComparisonType?: DateComparisonType;
  /**
   * Gets or sets the search phrase. This will lead to filtering on the name, street and district heating facility id of the building.
   * The search is done with wildcard in the beginning and at the end.
   */
  searchPhrase?: string;
  /**
   * Gets or sets the sort column. Possible values are Name, BuildingStatus
   * and BuildingStatusTimestamp; the case is ignored.
   */
  sortColumn?: string;
  /** Gets or sets the sort order. Possible values are ASC and DESC; the case is ignored. */
  sortOrder?: string;
  /**
   * Page number to return from the results. The value is 0 based.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  page: number;
  /**
   * Gets or sets the size of the page i.e the number of items it should contain.
   * @format int32
   * @min 1
   * @max 100
   */
  pageSize: number;
}

/**
 * This enumeration contains different types of date comparisons.
 * The value will be used in conjunction with a datetime value.
 */
export enum DateComparisonType {
  Unknown = 'Unknown',
  Before = 'Before',
  After = 'After',
  Exact = 'Exact'
}

export interface GetBuildingsExportRequestModel {
  /**
   * Gets or sets the parent node identifier. If it is used then only nodes directly below this node is searched.
   * @format guid
   */
  parentNodeId?: string;
  /** Gets or sets the building statuses. */
  buildingStatuses: BuildingStatus[];
  /**
   * Gets or sets the tags. All the supplied tags must be matched by the buildings.
   * Tags that don't exist in the database are removed before filtering.
   */
  tags: string[];
  /**
   * Gets or sets the building status timestamp. This will lead to that buildings
   * that had any of the supplied "statuses" at this time are returned.
   * @format date-time
   */
  buildingStatusTimestamp?: string;
  /**
   * Gets or sets the type of the building status timestamp comparison. For this to work then BuildingStatuses and BuildingStatusTimestamp
   * must have values.
   */
  buildingStatusTimestampComparisonType?: DateComparisonType;
  /**
   * Gets or sets the search phrase. This will lead to filtering on the name, street and district heating facility id of the building.
   * The search is done with wildcard in the beginning and at the end.
   */
  searchPhrase?: string;
  /**
   * Gets or sets the sort column. Possible values are Name, BuildingStatus
   * and BuildingStatusTimestamp; the case is ignored.
   */
  sortColumn?: string;
  /** Gets or sets the sort order. Possible values are ASC and DESC; the case is ignored. */
  sortOrder?: string;
}

export interface CreateComfortHeatingByTemplateResponseModel {
  /** The comfort provider templates that can be created or updated */
  addOrUpdateComfortHeatingProvider?: AddOrUpdateComfortHeatingProviderResponseModel;
  /** An alarm signal provider that can be created or updated */
  addOrUpdateAlarmSignalProvider?: AddOrUpdateAlarmSignalProviderResponseModel;
  /** A device/signal-provider relationship that can be created or updated */
  addOrUpdateDeviceSignalProviderRelationship?: AddOrUpdateDeviceSignalProviderRelationshipResponseModel;
  /** A list of mapping requests templates that will be created/updated */
  addOrUpdateMappings?: MappingResponseModel[];
}

export interface AddOrUpdateComfortHeatingProviderResponseModel {
  /**
   * Gets or sets the provider identifier. If this isn't supplied then it will be automatically generated on the server.
   * @format guid
   */
  providerId: string;
  /**
   * Gets or sets the node identifier that the provider belongs to. This should be a building.
   * @format guid
   */
  nodeId: string;
  /**
   * The equipment id of the provider.
   * @format guid
   */
  equipmentId: string;
  /** The display name of the provider. */
  displayName?: string;
  /**
   * The signal id for the currently set desired temperature.
   *
   * @format guid
   */
  desiredTemperatureSignalId: string;
  /**
   * The id of the mean temperature signal.
   * @format guid
   */
  meanTemperatureSignalId: string;
  /**
   * The id of the outdoor temperature signal.
   * @format guid
   */
  outTemperatureSignalId: string;
  /**
   * The id of the wind speed signal.
   * @format guid
   */
  windSpeedSignalId?: string;
  /**
   * The id of the tap water equipment.
   * @format guid
   */
  tapWaterEquipmentId?: string;
  /** The ComfortToolSet, which contains more settings for the various parts like alarms and algorithm parameters. */
  addOrUpdateComfortToolSet?: AddOrUpdateComfortToolSetResponseModel;
}

export interface AddOrUpdateComfortToolSetResponseModel {
  /**
   * The id of the Comfort Tool Set
   * @format guid
   */
  id: string;
  /** Settings for the PidControl algorithm */
  pidControl?: PidControlResponseModel;
  /** Settings for the HighPass control algorithm */
  highPassControl?: HighPassControlResponseModel;
  /** Settings for the IntegralGainSchedulingOutTemp algorithm */
  integralGainSchedulingOutTemp?: IntegralGainSchedulingOutTempResponseModel;
  /** Settings for the  IntegralGainSchedulingWindFactor algorithm */
  integralGainSchedulingWindFactor?: IntegralGainSchedulingWindFactorResponseModel;
  /** Settings for the IntegralGainSchedulingTimeOfDay algorithm */
  integralGainSchedulingTimeOfDayWeekend?: IntegralGainSchedulingTimeOfDayResponseModel;
  /** Settings for the IntegralGainSchedulingTimeOfDay algorithm */
  integralGainSchedulingTimeOfDayWeekday?: IntegralGainSchedulingTimeOfDayResponseModel;
  /** The equipment signals added or updated. */
  addOrUpdateEquipmentSignals?: AddOrUpdateEquipmentSignalResponseModel[];
  /** Settings for the comfort alarm configuration. */
  alarmConfigurations?: ComfortAlarmConfigurationResponseModel[];
}

export interface PidControlResponseModel {
  /** @format guid */
  id: string;
  /** The sign of PID output. */
  sign: Sign;
  /**
   * The controller gain.
   * @format float
   */
  gain: number;
  /**
   * The integration time in seconds.
   * @format float
   */
  integralTime?: number;
  /**
   * The derivative time in seconds.
   * @format float
   */
  derivativeTime?: number;
  /**
   * The upper limit of input control error dead-band.
   * @format float
   */
  inputDeadbandDiffUpper?: number;
  /**
   * The lower limit of input control error dead-band.
   * @format float
   */
  inputDeadbandDiffLower?: number;
  /**
   * The maximum output value.
   * @format float
   */
  maxOutputValue?: number;
  /**
   * The minimum output value.
   * @format float
   */
  minOutputValue?: number;
  /**
   * The id for the output enable signal.
   * @format guid
   */
  outputEnableId: string;
  /**
   * The offset id signal.
   * @format guid
   */
  outputSignalId: string;
  /**
   * The id of the signal specifying if the tool should be enabled or not.
   * @format guid
   */
  inputEnableId: string;
  /**
   * The id of the feedback control signal signal.
   * @format guid
   */
  inputSignalId: string;
  /**
   * The id of the control setpoint signal.
   * @format guid
   */
  inputSetpointId: string;
}

/**
 * Defines what the sign should be for the
 * Tool output for the tools PID and High-Pass
 */
export enum Sign {
  Unknown = 'Unknown',
  Negative = 'Negative',
  Positive = 'Positive'
}

export interface HighPassControlResponseModel {
  /** @format guid */
  id: string;
  /**
   * Defines what the sign should be for the
   * Tool output for the tools PID and High-Pass
   */
  sign: Sign;
  timeConstant: number;
  /** @format float */
  maxOutputValue?: number;
  /** @format float */
  minOutputValue?: number;
  /** @format guid */
  outputEnableId: string;
  /** @format guid */
  outputSignalId: string;
  /** @format guid */
  inputEnableId: string;
  /** @format guid */
  inputSignalId: string;
}

export interface IntegralGainSchedulingOutTempResponseModel {
  /** @format guid */
  id: string;
  /** @format float */
  integralTime?: number;
  /** @format float */
  integralGain?: number;
  timeConstant?: number;
  /** @format float */
  maxOutputValue?: number;
  /** @format float */
  minOutputValue?: number;
  /** @format float */
  minRoomTempSetpointValue: number;
  /** @format float */
  maxRoomTempSetpointValue: number;
  /** @format float */
  minRoomTempValue: number;
  /** @format float */
  maxRoomTempValue: number;
  /** @format float */
  minOutTempValue: number;
  /** @format float */
  maxOutTempValue: number;
  /**
   * (Optional) Number of rolling hours to consider average outdoor temperature to detect seasonal mode
   * @format float
   */
  outTempMeanHours?: number;
  /**
   * (Optional) Maximum mean outdoor temperature for integration/learning of control points
   * @format float
   */
  outTempMeanMaxIntegration?: number;
  /** @format guid */
  outputEnableId: string;
  /** @format guid */
  outputSignalId: string;
  /** @format guid */
  inputEnableId: string;
  /** @format guid */
  inputRoomTempSetpointId: string;
  /** @format guid */
  inputRoomTempId: string;
  /** @format guid */
  inputOutTempId: string;
  inputControlPoints?: InputControlPointResponseModel[];
}

export interface InputControlPointResponseModel {
  /** @format guid */
  id: string;
  position: number;
}

export interface IntegralGainSchedulingWindFactorResponseModel {
  /** @format guid */
  id: string;
  /** @format float */
  integralTime?: number;
  /** @format float */
  integralGain?: number;
  timeConstant?: number;
  /** @format float */
  maxOutputValue?: number;
  /** @format float */
  minOutputValue?: number;
  /** @format float */
  minRoomTempSetpointValue: number;
  /** @format float */
  maxRoomTempSetpointValue: number;
  /** @format float */
  minRoomTempValue: number;
  /** @format float */
  maxRoomTempValue: number;
  /** @format float */
  minWindSpeedValue: number;
  /** @format float */
  maxWindSpeedValue: number;
  /** @format float */
  minOutTempValue: number;
  /** @format float */
  maxOutTempValue: number;
  /**
   * (Optional) Number of rolling hours to consider average outdoor temperature to detect seasonal mode
   * @format float
   */
  outTempMeanHours?: number;
  /**
   * (Optional) Maximum mean outdoor temperature for integration/learning of control points
   * @format float
   */
  outTempMeanMaxIntegration?: number;
  /** @format guid */
  outputEnableId: string;
  /** @format guid */
  outputSignalId: string;
  /** @format guid */
  inputEnableId: string;
  /** @format guid */
  inputRoomTempSetpointId: string;
  /** @format guid */
  inputRoomTempId: string;
  /** @format guid */
  inputOutTempId: string;
  /** @format guid */
  inputWindSpeedId: string;
  /** @format guid */
  inputWindFactorId: string;
  inputControlPoints?: InputControlPointResponseModel[];
}

export interface IntegralGainSchedulingTimeOfDayResponseModel {
  /** @format guid */
  id: string;
  /**
   * The integration time in seconds. Default: 604800 seconds (1 week)
   * @format float
   */
  integralTime?: number;
  /**
   * The integral control gain
   * @format float
   */
  integralGain?: number;
  /** The output low-pass filter time constant in seconds (Defaults to no filtering) */
  timeConstant?: number;
  /**
   * The maximum output value
   * @format float
   */
  maxOutputValue?: number;
  /**
   * The minimum output value
   * @format float
   */
  minOutputValue?: number;
  /**
   * The minimum room temperature setpoint value to allow control
   * @format float
   */
  minRoomTempSetpointValue: number;
  /**
   * The maximum room temperature setpoint value to allow control
   * @format float
   */
  maxRoomTempSetpointValue: number;
  /**
   * The minimum room temperature value to define integral gain
   * scheduling range and to allow control
   * @format float
   */
  minRoomTempValue: number;
  /**
   * The maximum room temperature value to define integral gain
   * scheduling range and to allow control
   * @format float
   */
  maxRoomTempValue: number;
  /**
   * Sets the maximum outdoor temperature to use in the integral gain scheduling range
   * @format float
   */
  maxOutTempValue: number;
  /**
   * (Optional) Number of rolling hours to consider average outdoor temperature to detect seasonal mode
   * @format float
   */
  outTempMeanHours?: number;
  /**
   * (Optional) Maximum mean outdoor temperature for integration/learning of control points
   * @format float
   */
  outTempMeanMaxIntegration?: number;
  /** @format guid */
  outputEnableId: string;
  /** @format guid */
  outputSignalId: string;
  /**
   * The id of the signal specifying if the tool should be enabled or not.
   * @format guid
   */
  inputEnableId: string;
  /**
   * Signal ID of room temperature setpoint signal
   * @format guid
   */
  inputRoomTempSetpointId: string;
  /**
   * Signal ID of room temperature signal
   * @format guid
   */
  inputRoomTempId: string;
  /**
   * Signal ID of value of outdoor temperature signal
   * @format guid
   */
  inputOutTempId: string;
  /**
   * Signal ID of time-of-day signal
   * @format guid
   */
  inputTimeOfDayId: string;
  inputControlPoints?: InputControlPointResponseModel[];
}

export interface ComfortAlarmConfigurationResponseModel {
  /**
   * The id of the comfort alarm configuration.
   * @format guid
   */
  id: string;
  /**
   * The id of the alarm signal
   * @format guid
   */
  alarmSignalId: string;
  /**
   * The id of the signal for enabling/disabling the alarm.
   * @format guid
   */
  enableSignalId: string;
  /**
   * The id of the comfort tool set related to the comfort alarm.
   * @format guid
   */
  comfortToolSetId: string;
  /** The type of the alarm. */
  comfortAlarmType: ComfortAlarmType;
  /**
   * The time delay in seconds before this alarm is triggered. The alarm will not be triggered
   * if the cause for the alarm goes away within the specified time
   */
  timeDelay: number;
  /** A list of configurations, specifying the conditions for the alarm. */
  inputSignalConfigurations?: InputSignalConfigurationResponseModel[];
}

export enum ComfortAlarmType {
  Unknown = 'Unknown',
  FlowTempHigh = 'FlowTempHigh',
  FlowTempLow = 'FlowTempLow',
  RoomTempHigh = 'RoomTempHigh',
  RoomTempLow = 'RoomTempLow',
  RoomTempSensor = 'RoomTempSensor',
  HeatingHumidityHigh = 'HeatingHumidityHigh',
  HeatingTempHigh = 'HeatingTempHigh',
  WaterTempHigh = 'WaterTempHigh',
  WaterTempLow = 'WaterTempLow'
}

export interface InputSignalConfigurationResponseModel {
  /**
   * The id of the configuration.
   * @format guid
   */
  id: string;
  /**
   * The comfort alarm this configuration is related to.
   * @format guid
   */
  comfortAlarmConfigurationId: string;
  /**
   * The input signal this configuration is set for.
   * @format guid
   */
  inputSignalId: string;
  /**
   * A signal that will be used to compare against InputSignalId
   * @format guid
   */
  inputSetSignalId?: string;
  /**
   * The maximum value allowed for the inputSignal.
   * @format double
   */
  maxValue?: number;
  /**
   * The minimum value allowed for the inputSignal.
   * @format double
   */
  minValue?: number;
  /**
   * The alarm will trigger if the value of inputSignalId is higher
   * than valueOf(inputSetSignalId) + MaxDiffValue
   * @format double
   */
  maxDiffValue?: number;
  /**
   * The alarm will trigger if the value of inputSignalId is lower
   * than valueOf(inputSetSignalId) - MinDiffValue
   * @format double
   */
  minDiffValue?: number;
}

/** Adds or Updates Signal Mapping */
export interface MappingResponseModel {
  /**
   * The id of the mapping.
   * @format guid
   */
  id: string;
  /** A human readable name of the mapping. */
  name?: string;
  /**
   * Decides what the mapper should do.
   * I.e Nothing, send on push or cyclic.
   */
  mappingAction: MappingAction;
  /**
   * How often, in minutes, to look for, and send values for.
   * Used if MappingAction is set to If set to SendBasedOnCycleTime.
   */
  cycleTime: number;
  /** Destination configuration. */
  destinationConfig?: DestinationConfigResponseModel;
  /** Source Configuration */
  sourceConfig?: SourceConfigResponseModel;
}

/** Decides what action the mapper should do. */
export enum MappingAction {
  Unknown = 'Unknown',
  NoAction = 'NoAction',
  SendOnPush = 'SendOnPush',
  SendBasedOnCycleTime = 'SendBasedOnCycleTime'
}

export interface DestinationConfigResponseModel {
  /**
   * The id of the destination config.
   * @format guid
   */
  id: string;
  /**
   * Time To Live in seconds needs to be set for when sending messages to EM in order to not
   * increase the EM queue unnecessarily.
   */
  ttl: number;
  /** The destination signals to send the values to. */
  configSignals?: DestinationConfigSignalResponseModel[];
}

/** Adds or Updates the destination signals.  */
export interface DestinationConfigSignalResponseModel {
  /**
   * Destination signal configuration Id.
   * @format guid
   */
  id: string;
  /**
   * Destination signals may be time shifted compared to their inputs.
   * This could be used in instances where each hour in the day should be represented as a signal.
   * Thus the input can be time shifted say three hours and be put to a specific modbus signal.
   * @format int64
   */
  timeOffset: number;
  /**
   * Default value is to be set if there are no values sent to the EM.
   * @format double
   */
  defaultValue?: number;
  /**
   * Destination signal Id.
   * @format guid
   */
  signalId: string;
}

export interface SourceConfigResponseModel {
  /**
   * The id of the source config.
   * @format guid
   */
  id: string;
  /**
   * When one uses MappingAction.SendBasedOnCycleTime there is a need to not only send signal values
   * from now and forward, but to also include older values that can have been missed due to the cycle time
   * or that older values can be useful to send also.
   * MappingAction.SendOnPush is not affected by this value as it will send exactly what it got at that point in time.
   */
  hoursBack?: number;
  /** If the value is set only a certain amount of hours will be included in the signal time series. */
  hoursForward?: number;
  /** What sampling interval to use. */
  samplingInterval: SamplingInterval;
  /** Types of aggregation when retrieving signal time series data. */
  aggregation: AggregationType;
  /** Overrides the StartDate and tries to get the latest value before given start date, if exists. */
  useLastValueBeforeStartDate: boolean;
  /**
   * Source config signals.
   * If more than one source is used, aggregation must be specified.
   */
  configSignals?: SourceConfigSignalResponseModel[];
}

/** If return the time series data as is (raw) or sample it by a given time length. */
export enum SamplingInterval {
  Unknown = 'Unknown',
  Raw = 'Raw',
  Minute = 'Minute',
  Hour = 'Hour',
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  MinutesFive = 'MinutesFive',
  MinutesFifteen = 'MinutesFifteen'
}

/** Types of aggregation when retrieving signal time series data. */
export enum AggregationType {
  Unknown = 'Unknown',
  None = 'None',
  Mean = 'Mean',
  Max = 'Max',
  Min = 'Min',
  First = 'First',
  Last = 'Last',
  Count = 'Count',
  Sum = 'Sum',
  Median = 'Median',
  Mode = 'Mode',
  NoneWithTags = 'NoneWithTags'
}

export interface SourceConfigSignalResponseModel {
  /**
   * The id of the source config signal.
   * @format guid
   */
  id: string;
  /**
   * The signal, who's value will be used in the mapping.
   * @format guid
   */
  signalId: string;
}

export interface AddOrUpdateComfortHeatingByTemplateRequestModel {
  /**
   * Gets or sets the provider id.
   * @format guid
   */
  providerId: string;
  /**
   * Gets or sets the node identifier that the provider belongs to. This should be a building.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the equipment for which to enable comfort.
   * @format guid
   */
  equipmentId: string;
  /**
   * Gets or sets the mean signal id. When the provider is created, a signal mapping will be generated
   * between this signal and a roomTemp-signal. The roomTemp-signal will then be sent to the Energy Manager.
   * @format guid
   */
  meanTemperatureSignalId: string;
  /**
   * The signal that should be used for the outdoor temperature. A signal mapping will be created to allow for this signal
   * to be sent to the Energy Manager.
   * @format guid
   */
  outTemperatureSignalId: string;
  /**
   * The signal that should be used for the wind speed. A signal mapping will be created to allow for this signal
   * to be sent to the Energy Manager.
   * @format guid
   */
  windSpeedSignalId?: string;
  /**
   * The id of the tap water equipment. It is used for some of the alarms for getting the correct signals for
   * flowTemp and flowTempSetPoint.
   * @format guid
   */
  tapWaterEquipmentId?: string;
  /** The display name of the provider. */
  displayName?: string;
}

export interface ComfortHeatingProviderResponseModel {
  /**
   * The id of the provider.
   * @format guid
   */
  providerId: string;
  /**
   * The id of the node.
   * @format guid
   */
  nodeId: string;
  /**
   * The id of the equipment connected the provider
   * @format guid
   */
  equipmentId: string;
  /** The display name of the provider */
  displayName?: string;
  /** The name of the node. */
  nodeName?: string;
  /**
   * The id of the desired temperature signal.
   * @format guid
   */
  desiredTemperatureSignalId: string;
  /**
   * The id of the mean temperature signal.
   * @format guid
   */
  meanTemperatureSignalId: string;
  /**
   * The id of the out temperature signal.
   * @format guid
   */
  outTemperatureSignalId: string;
  /**
   * The id of the wind speed signal.
   * @format guid
   */
  windSpeedSignalId?: string;
  /**
   * The id of the tap water equipment.
   * @format guid
   */
  tapWaterEquipmentId?: string;
  /** The comfort tool set for the provider */
  comfortToolSet?: ComfortToolSetResponseModel;
}

export interface ComfortToolSetResponseModel {
  /** @format guid */
  id: string;
  alarmConfigurations?: ComfortAlarmConfigurationResponseModel[];
}

export interface GetComfortHeatingProvidersByNodeIdRequestModel {
  /** The node ids to retrieve providers for. */
  nodeIds: string[];
}

export interface GetComfortHeatingProvidersByProviderIdRequestModel {
  /** The ids of the providers to retrieve. */
  providerIds: string[];
}

export interface AddOrUpdateComfortAlarmRequestModel {
  /**
   * The id of the comfort alarm.
   * @format guid
   */
  id?: string;
  /**
   * The id of the equipment this alarm is connected to.
   * @format guid
   */
  equipmentId: string;
  /**
   * The id of the comfort tool this alarm is connected to.
   * @format guid
   */
  comfortToolSetId: string;
  /** The type of comfort alarm. */
  comfortAlarmType: ComfortAlarmType;
  /**
   * The time delay in seconds before this alarm is triggered. The alarm will not be triggered
   * if the cause for the alarm goes away within the specified time
   */
  timeDelay: number;
  /**
   * A list of configurations, specifying the conditions for the alarm to be triggered.
   * All these conditions must be fulfilled including the TimeDelay for the alarm to be triggered.
   */
  inputSignalConfigurations?: AddOrUpdateInputSignalConfigurationRequestModel[];
}

export interface AddOrUpdateInputSignalConfigurationRequestModel {
  /**
   * The id of the configuration.
   * @format guid
   */
  id?: string;
  /**
   * The comfort alarm this configuration is related to.
   * @format guid
   */
  comfortAlarmConfigurationId?: string;
  /**
   * A signal whose value will be compared to inputSignalId.
   * @format guid
   */
  inputSignalId: string;
  /**
   * A signal that will be used to compare against InputSignalId.
   * @format guid
   */
  inputSetSignalId?: string;
  /**
   * The maximum value allowed for the inputSignal.
   * @format double
   */
  maxValue?: number;
  /**
   * The minimum value allowed for the inputSignal.
   * @format double
   */
  minValue?: number;
  /**
   * The alarm will trigger if the value of inputSignalId is higher
   * than valueOf(inputSetSignalId) + MaxDiffValue
   * @format double
   */
  maxDiffValue?: number;
  /**
   * The alarm will trigger if the value of inputSignalId is lower
   * than valueOf(inputSetSignalId) - MinDiffValue
   * @format double
   */
  minDiffValue?: number;
}

export interface DeleteComfortHeatingProvidersRequestModel {
  /** Ids of the comfort signal providers to be deleted. */
  providerIds: string[];
}

export interface DeleteComfortAlarmConfigurationsRequestModel {
  /** Ids of the comfort alarm configurations to be deleted. */
  configurationIds: string[];
}

export interface DashboardResponseModel {
  /**
   * The ID of the dashboard.
   * @format guid
   */
  dashboardId: string;
  /** The name of this dashboard. */
  name?: string;
  /** The description of this dashboard. */
  description?: string;
  /**
   * If this dashboard belongs to a user then this is the ID of that user.
   * @format guid
   */
  userId?: string;
}

export interface GetDashboardByIdRequestModel {
  /** The dashboard ID:s to search for. */
  dashboardIds?: string[];
}

export interface PagedDashboardsResponseModel {
  /**
   * The total amount of pages for this query.
   * @format int32
   */
  totalPages: number;
  /**
   * The total amount of records for this query.
   * @format int32
   */
  totalRecords: number;
  /** The list of dashboards for this page. */
  dashboards?: DashboardResponseModel[];
}

export interface GetPagedDashboardsRequestModel {
  /**
   * Page number to return from the results. The value is 0 based.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  page: number;
  /**
   * Gets or sets the size of the page i.e the number of items it should contain.
   * @format int32
   * @min 1
   * @max 100
   */
  pageSize: number;
}

export interface AddOrUpdateDashboardRequestModel {
  /**
   * The ID of the dashboard.
   * @format guid
   */
  dashboardId: string;
  /**
   * The name of the dashboard.
   * @minLength 1
   */
  name: string;
  /** The description of the dashboard. */
  description?: string;
  /**
   * If this dashboard belongs to a user then this is the ID of that user.
   * @format guid
   */
  userId?: string;
}

export interface DeleteDashboardCollectionNodeRelationsRequestModel {
  /** The ID:s of the nodes for which dashboard relations should be removed */
  nodeIds?: string[];
}

export interface DeleteDashboardCollectionBuildingTemplateRelationsRequestModel {
  /** The ID:s of the building templates for which dashboard relations should be removed */
  buildingTemplateIds: string[];
}

export interface DeleteDashboardsRequestModel {
  /** The ID:s of the dashboards that should be deleted. */
  dashboardIds: string[];
}

export interface PagedDashboardCollectionResponseModel {
  /**
   * The total amount of pages for this query.
   * @format int32
   */
  totalPages: number;
  /**
   * The total amount of records for this query.
   * @format int32
   */
  totalRecords: number;
  /** The list of dashboards for this page. */
  dashboardCollections?: DashboardCollectionResponseModel[];
}

export interface DashboardCollectionResponseModel {
  /**
   * Gets or sets the id of collection.
   * @format guid
   */
  dashboardCollectionId: string;
  /** The name of this collection. */
  name?: string;
  /** The description of this collection. */
  description?: string;
  /**
   * Gets or sets the id of the default dashboard in the collection.
   * @format guid
   */
  defaultDashboardId?: string;
  /** Gets or sets the list of dashboards included in this collection. */
  dashboards?: DashboardResponseModel[];
  /** Gets or sets the list of node ID:s related to this collection */
  nodeIds?: string[];
  /** Gets or sets the list of building template ID:s related to this collection */
  buildingTemplateIds?: string[];
}

export interface GetPagedDashboardCollectionsRequestModel {
  /**
   * Page number to return from the results. The value is 0 based.
   * @format int32
   * @min 0
   * @max 2147483647
   */
  page: number;
  /**
   * Gets or sets the size of the page i.e the number of items it should contain.
   * @format int32
   * @min 1
   * @max 100
   */
  pageSize: number;
}

export interface GetDashboardCollectionsRequestModel {
  /** The dashboard collection ID:s to search for. */
  dashboardCollectionIds?: string[];
  /** The node ID:s used to find a collection. */
  nodeIds?: string[];
  /** The building template ID:s used to find a collection. */
  buildingTemplateIds?: string[];
  /** The dashboard ID:s used to find a collection. */
  dashboardIds?: string[];
}

export interface AddOrUpdateDashboardCollectionRequestModel {
  /**
   * The id of the collection.
   * @format guid
   */
  dashboardCollectionId: string;
  /**
   * The name of the dashboard.
   * @minLength 1
   */
  name: string;
  /** The description of the dashboard. */
  description?: string;
  /**
   * The default dashboard ID of this collection.
   * @format guid
   */
  defaultDashboardId?: string;
  /** The list of dashboard ID:s that are associated with this collection. */
  dashboardIds: string[];
}

export interface AddOrUpdateDashboardCollectionNodeRelationRequestModel {
  /**
   * The id of the collection.
   * @format guid
   */
  dashboardCollectionId: string;
  /**
   * The id of the node that should be associated with the collection.
   * @format guid
   */
  nodeId: string;
}

export interface AddOrUpdateDashboardCollectionBuildingTemplateRelationRequestModel {
  /**
   * The id of the collection.
   * @format guid
   */
  dashboardCollectionId: string;
  /**
   * The id of the building template that should be associated with the collection.
   * @format guid
   */
  buildingTemplateId: string;
}

export interface DeleteDashboardCollectionsRequestModel {
  /** The ID:s of the dashboards that should be deleted. */
  dashboardCollectionIds: string[];
}

export interface SetDefaultDashboardCollectionIdRequestModel {
  /**
   * The ID of the dashboard collection that should be the new global default dashboard collection.
   * @format guid
   */
  dashboardCollectionId: string;
}

export interface DashboardCollectionIdResponseModel {
  /**
   * Gets or sets the id of collection.
   * @format guid
   */
  dashboardCollectionId: string;
}

export interface AddOrUpdateDashboardFileRequestModel {
  /**
   * The ID of the dashboard for which the file contents should be updated.
   * @format guid
   */
  dashboardId: string;
  /** The JSON data that should be stored for the dashboard. */
  jsonData: Record<string, any>;
}

export interface DashboardFileResponseModel {
  /**
   * The ID of the dashboard
   * @format guid
   */
  dashboardId: string;
  /** The JSON data of the dashboard */
  jsonData?: Record<string, any>;
}

export interface GetDashboardFilesRequestModel {
  /**
   * The ID of the dashboard to fetch file data for.
   * @format guid
   */
  dashboardId: string;
}

/** Device info for device found during search query. */
export interface SlaveIdResponseModel {
  /**
   * Suggested Slave Id the front end can use when it creates a new connection to the device.
   * @format int32
   */
  slaveId: number;
}

/** Returns status of the deploy request */
export interface DeployResponseModel {
  /** True if the device twin have been updated. */
  deviceTwinUpdated: boolean;
  /** Includes detailed data on how the device update went. */
  deviceStatus?: DeviceStatusResponseModel;
}

/**
 * Shows the status of the device files on the EM, In the backend and reported from IoT Hub.
 * Also shows reported versions.
 */
export interface DeviceStatusResponseModel {
  /** Current status is the status the in the EM. */
  current?: DeviceStatusTableResponseModel;
  /** Desired is the status that backend wants the EM to have. */
  desired?: DeviceStatusTableResponseModel;
  /**
   * Reported is the status Returned through the IoT hub Device Twin.
   * This value will change when for example an em goes online and syncs with device twin and then downloads the files.
   */
  reported?: DeviceStatusTableResponseModel;
  /** Reports device program versions. */
  reportedVersions?: DeviceStatusVersionResponseModel[];
  /** Reports device module versions. */
  reportedModuleVersions?: DeviceModuleStatusVersionResponseModel[];
  /** TODO description */
  hasTwin: boolean;
  /**
   * Gets or sets the deployment time i.e. the latest time a user clicked on the deploy config files button.
   * @format date-time
   */
  deploymentTime?: string;
}

/** Device MD5 hashes for Device Config, SignalsConfig and Tools Config. */
export interface DeviceStatusTableResponseModel {
  /** Device Config hash returned from the Device. */
  deviceConfigHash?: string;
  /** Signals Config hash returned from the Device. */
  signalsHash?: string;
  /** Tools Config hash returned from the Device. */
  toolsHash?: string;
}

/**
 * Device has many programs and they can be of different versions.
 * Here a program can have its version returned to the user.
 */
export interface DeviceStatusVersionResponseModel {
  /** Program in the EM. */
  program?: string;
  /** Corresponding program version. */
  version?: string;
}

/**
 * Device has many modules and they can be of different versions.
 * Here a module can have its version returned to the user.
 */
export interface DeviceModuleStatusVersionResponseModel {
  /** Module name in the EM. */
  name?: string;
  /** Corresponding module version. */
  version?: string;
}

/** Deploys all config files to one Device */
export interface DeployModbusConfigRequestModel {
  /**
   * Equipment Id representing an EM.
   * This is the same as ConnectionId.
   * @format guid
   */
  equipmentId: string;
}

/** List of device statuses after device file push request. */
export interface DeployDeviceConfigsResponseModel {
  /** Info, per device on how the download request went. */
  deployDeviceConfig?: DeployDeviceConfigResponseModel[];
}

/** Device deploy information */
export interface DeployDeviceConfigResponseModel {
  /**
   * Device Id for that deploy
   * @format guid
   */
  deviceId: string;
  /** Was the device twin updated? */
  deviceTwinUpdated: boolean;
  /** Detailed deploy status for that device. */
  deviceStatus?: DeviceStatusResponseModel;
}

/** Lists of device Ids to push config files to */
export interface DeployModbusConfigsRequestModel {
  deviceIds: string[];
}

export interface DeviceInfoResponseModel {
  /** @format guid */
  deviceId: string;
  /** @format guid */
  equipmentId: string;
}

export interface GetDeviceEquipmentInfoFromEquipmentIdsRequestModel {
  /** At least one equipment id must be supplied. */
  equipmentIds?: string[];
}

/** Device info for device found during search query. */
export interface DeviceResponseModel {
  /**
   * Device Id
   * @format guid
   */
  deviceId: string;
  /** Equipment ids the device manages. */
  equipmentIds?: string[];
  /** The IoT Device Its connected to */
  ioTDevice?: IoTDeviceWithHeartbeatResponseModel;
  /** All connections belonging to the device including connection modbus configs and signals with their configs. */
  connections?: ConnectionResponseModel[];
  /**
   * Gets or sets the deployment time i.e. the latest time a user clicked on the deploy config files button.
   * @format date-time
   */
  deploymentTime?: string;
}

export interface IoTDeviceWithHeartbeatResponseModel {
  /** @format guid */
  id: string;
  deviceName?: string;
  simCardNumber?: string;
  simApn?: string;
  manufacturer?: string;
  /** @format date-time */
  manufacturingDate: string;
  model?: string;
  notes?: string;
  /** @format guid */
  deviceId?: string;
  tags?: string[];
  /** @format date-time */
  heartbeatTimestamp?: string;
  address?: string;
}

export interface GetDeviceStatusFromDeviceIdsRequestModel {
  /** At least one device id must be supplied. */
  deviceIds?: string[];
}

export interface GetDeviceModulesResponseModel {
  deviceModules?: DeviceModulesResponseModel[];
}

export interface DeviceModulesResponseModel {
  /** @format guid */
  deviceId: string;
  modules?: DeviceModuleResponseModel[];
}

export interface DeviceModuleResponseModel {
  name?: string;
  /** @format date-time */
  lastSeen?: string;
  /** @format date-time */
  lastComms?: string;
  version?: string;
}

/** Subset of equipment info returned to show users. */
export interface SimplifiedEquipmentResponseModel {
  /**
   * Equipment Id
   * @format guid
   */
  equipmentId: string;
  /** Name of the Equipment. */
  name?: string;
  /** Description of the equipment. */
  description?: string;
  /** The name of the Equipment Type this equipment belongs to. */
  type?: string;
}

/** Request Connection info for all supplied Device Ids. */
export interface GetConnectionsFromDeviceIdsRequestModel {
  /** At least one Device Id must be supplied. */
  deviceIds?: string[];
}

/**
 * Connection Default Modbus Config Values.
 * These values are used when instantiating templates
 * that do not have all values set within the template.
 */
export interface ConnectionDefaultModbusConfigResponseModel {
  /**
   * Id of the Connection Default Modbus Config
   * @format guid
   */
  id: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * ///
   * @format int32
   */
  byteTimeoutMicroseconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   */
  slaveId: number;
  /**
   * Modbus watchdog timeout.
   *
   * @format int32
   */
  watchdogModbusTimeout: number;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor: number;
}

/**
 * Adds or updates the default connection modbus config values in the backend database.
 * These configs are used when instantiating incomplete connection modbus templates/template Overrides.
 * Any values not set by the templates/template overrides will get the default value in the database.
 * Default connection modbus config values are only used when instantiating new templates,
 * existing connection modbus configs will not be updated if these values change.
 */
export interface AddOrUpdateConnectionsDefaultModbusConfigRequestModel {
  /**
   * Default port number for Modbus TCP.
   * @format int32
   */
  port: number;
  /** Default IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Default Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode: ModbusMode;
  /** Default Modbus communication type - "RTU", "TCP" */
  modbusType: ModbusType;
  /** Default  file descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Default Baudrate for Modbus RTU
   * @format int32
   */
  baudrate: number;
  /**
   * Default Data bits for Modbus RTU
   * @format int32
   */
  databits: number;
  /** Default Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity: Parity;
  /**
   * Default Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout: number;
  /**
   * Specifies Default Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds: number;
  /**
   * Specifies Default Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutMicroseconds: number;
  /**
   * Specifies Default Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds: number;
  /**
   * Specifies Default Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds: number;
  /**
   * Default Modbus slave ID 1-255
   * @format int32
   * @min 1
   * @max 255
   */
  slaveId: number;
  /**
   * Each connection must have a watchdog alarm Signal Id specified.
   * If no signal Id or System Name is given during the initiation of a template the default system name
   * will be used to get the Signal Id.
   * @format guid
   */
  watchdogModbusAlarmSignalTypeId: string;
  /**
   * Default Modbus watchdog timeout
   * @format int32
   */
  watchdogModbusTimeout: number;
  /**
   * Each connection must have a modbus version signal Signal Id specified.
   * If no signal Id or System Name is given during the initiation of a template the default system name
   * will be used to get the Signal Id.
   * @format guid
   */
  modbusVersionSignalTypeId: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   * @format int32
   */
  delayReadModbus: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   * If multiple connections share the same physical interface, the largest queue size factor will be selected.
   * @format int32
   */
  queueSizeFactor: number;
}

export interface ConnectionNameResponseModel {
  /** @format guid */
  id: string;
  name?: string;
}

/** Updates a connection name. */
export interface UpdateConnectionNameRequestModel {
  /**
   * Connection Id.
   * @format guid
   */
  id: string;
  /**
   * A human readable name to set for that connection.
   * When a device has many connections, name is used to help the user distinguish between connections.
   */
  name?: string;
}

/** Connection to add or update. */
export interface AddOrUpdateConnectionRequestModel {
  /**
   * Device this connection should belong to.
   * @format guid
   */
  deviceId: string;
  /**
   * The connection is always connected to a node in the location domain.
   * @format guid
   */
  id: string;
  /**
   * Connections can get a human readable name to more easily separate them from other connections if
   * a device has multiple connections.
   */
  name?: string;
  /** Every connection must have a connection modbus config set. */
  connectionModbusConfig: AddOrUpdateConnectionModbusConfigRequestModel;
  /** Specifies zero or more of signals that belongs to this connection. */
  signals?: SignalRequestModel[];
}

/** Adds or updates connection modbus config. */
export interface AddOrUpdateConnectionModbusConfigRequestModel {
  /**
   * If Id is not given a new one will be set.
   * @format guid
   */
  id?: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutMicroseconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   * @min 1
   * @max 255
   */
  slaveId: number;
  /**
   * Each connection must have a watchdog alarm Signal Id specified.
   * @format guid
   */
  watchdogModbusAlarmSignalId: string;
  /**
   * Modbus watchdog timeout
   *
   * @format int32
   */
  watchdogModbusTimeout: number;
  /**
   * Each connection must have a modbus version signal Signal Id specified.
   * @format guid
   */
  modbusVersionSignalId: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor: number;
}

/** Adds or updates a signal with its modbus config in the IoT domain. */
export interface SignalRequestModel {
  /**
   * Signal Id must be set.
   * If id does not match any in the database a new signal in the IoT domain will be created.
   * @format guid
   */
  signalId: string;
  /** Signal settings to set that is used by the EM to handle the signal correctly., */
  signalSettings?: SignalSettingsRequestModel;
}

/** Connection modbus config response model with all the data including the Connection Id. */
export interface FullConnectionModbusConfigResponseModel {
  /**
   * The Connection this modbus config belongs to.
   * @format guid
   */
  connectionId: string;
  /**
   * Modbus config id
   * @format guid
   */
  id: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * ///
   * @format int32
   */
  byteTimeoutMicroseconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   */
  slaveId: number;
  /**
   * Watchdog modbus Alarm Signal Id.
   * @format guid
   */
  watchdogModbusAlarmSignalId: string;
  /**
   * Modbus watchdog timeout.
   *
   * @format int32
   */
  watchdogModbusTimeout: number;
  /**
   * Modbus Version Signal Id.
   * @format guid
   */
  modbusVersionSignalId: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor: number;
}

/**
 * List of connection modbus configs to add or update.
 * Please observe that Adding a connection is not likely due to mobus connections usually have their modbus configs created at the same time.
 */
export interface AddOrUpdateConnectionModbusConfigsRequestModel {
  /** List of Modbus configs to update. */
  modbusConfigs?: ConnectionsModbusConfigRequestModel[];
}

/**
 * Connection modbus config to add or update.
 * Every connection must have a config, and all values must be set.
 */
export interface ConnectionsModbusConfigRequestModel {
  /**
   * The connection id specifying what connection that the config should belong to.
   * @format guid
   */
  connectionId: string;
  /**
   * Id for the modbus config.
   * If not set, a new one will be created.
   * @format guid
   */
  id?: string;
  /**
   * Port number for Modbus TCP.
   * @format int32
   */
  port: number;
  /** IP address for Modbus TCP. */
  ipAddress?: string;
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusMode: ModbusMode;
  /** Modbus communication type - "RTU", "TCP" */
  modbusType: ModbusType;
  /** File descriptor of Modbus RTU serial communication device (e.g. "/dev/ttySC0" for RS-485 on NPE-9500) */
  serialName?: string;
  /**
   * Baudrate for Modbus RTU
   * @format int32
   */
  baudrate: number;
  /**
   * Data bits for Modbus RTU
   * @format int32
   */
  databits: number;
  /** Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd */
  parity: Parity;
  /**
   * Number of stop bits for Modbus RTU
   * @format int32
   */
  stopbits: number;
  /**
   * Idle time before connection is closed (for Modbus master mode) in seconds
   * @format int32
   */
  connectionTimeout: number;
  /**
   * Specifies Modbus byte timeout in seconds.
   * This is the first half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutSeconds: number;
  /**
   * Specifies Modbus byte timeout in microseconds.
   * This is the second half of the full time out.
   * ByteTimeoutSeconds+ByteTimeoutMicroseconds are used together.
   * @format int32
   */
  byteTimeoutMicroseconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is the first half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutSeconds: number;
  /**
   * Specifies Modbus response timeout in seconds.
   * This is second half of the full time out.
   * ResponseTimeoutSeconds+ResponseTimeoutMicroseconds are used together.
   * @format int32
   */
  responseTimeoutMicroseconds: number;
  /**
   * Modbus slave ID 1-255
   * @format int32
   * @min 1
   * @max 255
   */
  slaveId: number;
  /**
   * Each connection must have a watchdog alarm Signal Id specified.
   * @format guid
   */
  watchdogModbusAlarmSignalId: string;
  /**
   * Modbus watchdog timeout
   *
   * @format int32
   */
  watchdogModbusTimeout: number;
  /**
   * Each connection must have a modbus version signal Signal Id specified.
   * @format guid
   */
  modbusVersionSignalId: string;
  /**
   * Default Modbus read delay/period (for master mode), in seconds.
   *
   * @format int32
   */
  delayReadModbus: number;
  /**
   * Default database read value saving delay/period, in seconds
   * @format int32
   */
  delayReadDb: number;
  /**
   * Default Modbus write delay/period (for master mode), in seconds.
   * Value -1 ensures only write upon updated value in db.
   * @format int32
   */
  delayWriteModbus: number;
  /**
   * Default database write value fetching delay/period, in seconds.
   * @format int32
   */
  delayWriteDb: number;
  /**
   * Default read back tracking delay, in seconds.
   * If write value has a flag, read back can only occur when flag is reset to 0.
   * Value -1 sets to no tracking, only comparison for flag reset.
   * @format int32
   */
  delayWriteReadback: number;
  /**
   * Message queue size factor, which is a multiple of the total number of Modbus signals (see signals configuration).
   *             If multiple connections share the same physical interface, the largest queue size factor will be selected.
   *
   * @format int32
   */
  queueSizeFactor: number;
}

export interface AddOrUpdateSignalConnectionsRequestModel {
  signalIds?: string[];
  /** @format guid */
  connectionId: string;
}

export interface SendEmailRequest {
  /** Gets or sets the email addresses to send the messages to. */
  toAddresses?: string[];
  /**
   * Gets or sets the subject.
   * @minLength 1
   */
  subject: string;
  /**
   * Gets or sets the text body.
   * @minLength 1
   */
  textBody: string;
  /** Gets or sets the CC email addresses. */
  ccAddresses?: string[];
}

export interface EquipmentResponseModel {
  /** @format guid */
  equipmentId: string;
  name?: string;
  description?: string;
  /** @format guid */
  equipmentTypeId: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  alarmSignalProviderId?: string;
  /** @format guid */
  powerControlProviderId?: string;
  /** @format guid */
  remoteOptimisationProviderId?: string;
}

/** Adds or updates EnergyManager by equipment type. */
export interface AddOrUpdateEnergyManagerByEquipmentTypeRequestModel {
  /**
   * If new Equipment Id is given it will be treated as a new Energy Manager equipment.
   * @format guid
   */
  equipmentId: string;
  /**
   * Specifies the device Id the EM Equipment should be connected to.
   * @format guid
   */
  deviceId: string;
  /** Name of the EM equipment. */
  name?: string;
  /** Description of the EM Equipment */
  description?: string;
  /**
   * The equipment node must be under another already existing node.
   * @format guid
   * @minLength 1
   */
  nodeId: string;
  /** Modbus configuration for the new connection to be created for the new EM Equipment. */
  connectionModbusConfig?: AddOrUpdateConnectionModbusConfigByTemplateRequestModel;
  /**
   * Alarm Signals will be instantiated based on this Alarm Signal Provider.
   * @format guid
   */
  alarmSignalGroupTemplateId: string;
  /** Any tools that should be installed. */
  toolTypes: ToolType[];
}

/** Add or update Equipment Signals */
export interface AddOrUpdateEquipmentSignalsRequestModel {
  /**
   * Gets or sets the signal identifier.
   * @format guid
   */
  signalId: string;
  /**
   * Equipment Id for the equipment the equipment signal belongs to.
   * @format guid
   */
  equipmentId: string;
  /**
   * Gets or sets the name of the signal.
   * @minLength 0
   * @maxLength 100
   */
  name?: string;
  /**
   * Description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /** IsWritable specifies if the frontend should/can allow the user the user to update the value. */
  isWritable: boolean;
  /** Signal Modbus settings for the signal, if any. */
  signalSettings?: SignalSettingsRequestModel;
  /**
   * The connection the alarm signal belongs to in the IoT domain.
   * @format guid
   */
  connectionId?: string;
  /** Format of the timeseries. Continues or Discrete. */
  dataFormat: DataFormat;
  /** If the signal is an input or output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. It is ok to leave this list empty. */
  signalCategoryIds: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
}

export interface AddOrUpdateEquipmentByEquipmentTypeRequestModel {
  /** @format guid */
  equipmentId?: string;
  name?: string;
  description?: string;
  /**
   * @format guid
   * @minLength 1
   */
  equipmentTypeId: string;
  /**
   * @format guid
   * @minLength 1
   */
  nodeId: string;
  /**
   * @format guid
   * @minLength 1
   */
  deviceEquipmentId: string;
  /** @format guid */
  alarmSignalGroupTemplateId: string;
  toolTypes: ToolType[];
}

/** Update Equipment Name and Description */
export interface AddOrUpdateEquipmentRequestModelOBSOLETE {
  /**
   * Id of the equipment to be updated.
   * @format guid
   */
  equipmentId: string;
  /** New name to set. */
  name?: string;
  /** New Description to set. */
  description?: string;
}

/** Model for updating the type, name and description of an existing equipment. */
export interface UpdateEquipmentRequestModel {
  /**
   * The identifier of the equipment to be updated.
   * @format guid
   */
  equipmentId: string;
  /**
   * The equipment type identifier to set.
   * @format guid
   */
  equipmentTypeId: string;
  /** The new name to set. */
  name?: string;
  /** The new description to set. */
  description?: string;
}

/** Equipment type information. */
export interface EquipmentTypeResponseModel {
  /**
   * Id of the Equipment type.
   * @format guid
   */
  equipmentTypeId: string;
  /** Equipment type Name. */
  name?: string;
}

/** Adds or updates an equipment type. */
export interface AddOrUpdateEquipmentTypeRequestModel {
  /**
   * Equipment type Name.
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * If Id is not set a new one will be created and it will be handled as a new Equipment Type.
   * @format guid
   */
  equipmentTypeId?: string;
}

/** Signal Templates for each equipment type. */
export interface EquipmentSignalProviderTemplateResponseModel {
  /**
   * Equipment type Id that each signal template belongs to.
   * @format guid
   */
  equipmentTypeId: string;
  /** All Signal Template */
  signalTemplates?: EquipmentSignalTemplateResponseModel[];
}

/** All settings for the Equipment Signal Template. */
export interface EquipmentSignalTemplateResponseModel {
  /**
   * Id for the Equipment Signal Template
   * @format guid
   */
  id: string;
  /** Human readable display name. */
  name?: string;
  /** If it is ok for frontend to write to signal. */
  isWritable: boolean;
  /** Settings template to use. Only set values will be used in the override. */
  signalSettings?: SignalSettingsTemplateResponseModel;
  /** Description */
  description?: string;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Format of the timeseries. Discrete or Continuous. */
  dataFormat: DataFormat;
  /** Gets or sets the signal category identifiers. */
  signalCategoryIds?: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
}

/** Equipment template to add or update. */
export interface AddOrUpdateEquipmentSignalProviderTemplateRequestModel {
  /**
   * Equipment type Id for the Equipment Template
   * @format guid
   */
  equipmentTypeId: string;
  signalTemplates: AddOrUpdateEquipmentSignalTemplateRequestModel[];
}

/** Adds or updates equipment signal templates */
export interface AddOrUpdateEquipmentSignalTemplateRequestModel {
  /**
   * Gets or sets the identifier. If the identifier already exists then an update will
   * be performed; otherwise a new entity will be added.
   * @format guid
   */
  id: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /**
   * If a signal is writable to the EM.
   * Will be removed later when SignalDirection is implemented.
   */
  isWritable: boolean;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /**
   * Signal Settings template includes information needed to create a Signals Settings entity in the IoT domain.
   * Signal Settings is all the data the IoT domain needs for the Energy Manager to handle the signal.
   * A signal does not always have not have any SignalSettings.
   */
  signalSettings?: SignalSettingsTemplateRequestModel;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Format of the timeseries. Discrete or Continuous. */
  dataFormat: DataFormat;
  /** Gets or sets the signal category identifiers. It is ok to leave this list empty. */
  signalCategoryIds: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
}

export interface DeleteEquipmentSignalProviderTemplatesRequestModel {
  /**
   * Gets or sets the equipment type ids to delete.
   * (This is the same as the signal provider template ids.)
   */
  equipmentTypeIds: string[];
}

export interface IoTDeviceResponseModel {
  /** @format guid */
  id: string;
  deviceName?: string;
  simCardNumber?: string;
  simApn?: string;
  manufacturer?: string;
  /** @format date-time */
  manufacturingDate: string;
  model?: string;
  notes?: string;
  /** @format guid */
  deviceId?: string;
  tags?: string[];
  address?: string;
}

export interface AddOrUpdateIoTDeviceRequestModel {
  /** @format guid */
  id: string;
  deviceName?: string;
  simCardNumber?: string;
  simApn?: string;
  manufacturer?: string;
  /** @format date-time */
  manufacturingDate: string;
  model?: string;
  notes?: string;
  /** @format guid */
  deviceId?: string;
  tags?: string[];
  address?: string;
}

export interface IoTDevicesSearchResponseModel {
  summary?: IoTDevicesSummaryResponseModel;
  results?: IoTDeviceWithDeviceResponseModel[];
}

export interface IoTDevicesSummaryResponseModel {
  /** @format int32 */
  devicesCount: number;
  /** @format int32 */
  unprovisionedDevicesCount: number;
  /** @format int32 */
  onlineDevicesCount: number;
}

export interface IoTDeviceWithDeviceResponseModel {
  ioTDevice?: IoTDeviceResponseModel;
  /** @format date-time */
  heartbeatTimestamp?: string;
  isOnline: boolean;
  device?: IoTDeviceDeviceResponseModel;
}

export interface IoTDeviceDeviceResponseModel {
  /** @format guid */
  deviceId: string;
  equipmentIds?: string[];
  connections?: ConnectionResponseModel[];
}

export enum IoTDeviceQueryType {
  Unknown = 'Unknown',
  Paired = 'Paired',
  Unpaired = 'Unpaired',
  All = 'All'
}

/** This enumeration contains the values for how we can filter when searching for IoT devices. */
export enum IoTDeviceStatusQueryType {
  Unknown = 'Unknown',
  Online = 'Online',
  Offline = 'Offline',
  All = 'All'
}

export interface IoTDeviceViewResponseModel {
  ioTDevice?: IoTDeviceResponseModel;
  /** @format date-time */
  heartbeatTimestamp?: string;
  isOnline: boolean;
  desiredSettings?: DeviceTwinDesiredSettingsResponseModel;
  reportedSettings?: DeviceTwinSettingsResponseModel;
  reportedModules?: DeviceModuleResponseModel[];
  reportedSystem?: DeviceTwinSystemResponseModel;
}

export interface DeviceTwinDesiredSettingsResponseModel {
  /** Gets or sets the default network interface. */
  defaultNetworkInterface: NetworkInterfaceType;
  /** Gets or sets the ethernet network settings. */
  eth0?: EthernetNetworkResponseModel;
  /** Gets or sets the ethernet network settings. */
  eth1?: EthernetNetworkResponseModel;
  /** Gets or sets the ethernet network settings. */
  eth2?: EthernetNetworkResponseModel;
  /** Gets or sets the ethernet network settings. */
  eth3?: EthernetNetworkResponseModel;
}

export enum NetworkInterfaceType {
  Unknown = 'Unknown',
  PPP0 = 'ppp0',
  Eth0 = 'eth0',
  Eth1 = 'eth1',
  Eth2 = 'eth2',
  Eth3 = 'eth3'
}

export interface EthernetNetworkResponseModel {
  type: IpType;
  ipAddress?: string;
  gateway?: string;
  mask?: string;
}

export enum IpType {
  Unknown = 'Unknown',
  Static = 'Static',
  DHCP = 'DHCP'
}

export interface DeviceTwinSettingsResponseModel {
  /** Gets or sets the default network interface. */
  defaultNetworkInterface: NetworkInterfaceType;
  /** Gets or sets the ethernet network settings. */
  eth0?: EthernetNetworkResponseModel;
  /** Gets or sets the ethernet network settings. */
  eth1?: EthernetNetworkResponseModel;
  /** Gets or sets the ethernet network settings. */
  eth2?: EthernetNetworkResponseModel;
  /** Gets or sets the ethernet network settings. */
  eth3?: EthernetNetworkResponseModel;
  /** Gets the modem network settings. */
  ppp0?: ModemNetworkResponseModel;
}

export interface ModemNetworkResponseModel {
  ipAddress?: string;
  mask?: string;
}

export interface DeviceTwinSystemResponseModel {
  /** @format int64 */
  uptime?: number;
  /** @format int32 */
  signalStrength?: number;
  operator?: string;
  accessTechnology?: string;
  simICCID?: string;
  defaultNetworkInterface: NetworkInterfaceType;
  eth0?: EthernetNetworkResponseModel;
  eth1?: EthernetNetworkResponseModel;
  eth2?: EthernetNetworkResponseModel;
  eth3?: EthernetNetworkResponseModel;
  ppp0?: ModemNetworkResponseModel;
}

export interface IoTDeviceSettingsResponseModel {
  /** @format guid */
  ioTDeviceId: string;
  /** Gets or sets the reported settings. These are settings the device is configured to use. */
  reportedSettings?: DeviceTwinSettingsResponseModel;
  /** Gets or sets the reported system settings. These are settings actually used. */
  reportedSystem?: DeviceTwinSystemResponseModel;
  /** Gets or sets the desired settings. These are settings that the device is requested to use. */
  desiredSettings?: DeviceTwinDesiredSettingsResponseModel;
}

export interface AddOrUpdateIoTDeviceSettingsRequestModel {
  /**
   * Gets or sets the iot device id for which to update settings.
   * @format guid
   */
  ioTDeviceId: string;
  /** Gets or sets the network interface that should be set as default. */
  defaultNetworkInterface: NetworkInterfaceType;
  /** Gets or sets the eth0 settings */
  eth0?: EthernetNetworkRequestModel;
  /** Gets or sets the eth1 settings */
  eth1?: EthernetNetworkRequestModel;
  /** Gets or sets the eth2 settings */
  eth2?: EthernetNetworkRequestModel;
  /** Gets or sets the eth3 settings */
  eth3?: EthernetNetworkRequestModel;
}

export interface EthernetNetworkRequestModel {
  /** Gets or sets the type of ethernet network. */
  type: IpType;
  /** Gets or sets the ip address. */
  ipAddress?: string;
  /** Gets or sets the gateway address. */
  gateway?: string;
  /** Gets or sets the network mask. */
  mask?: string;
}

export interface ModuleResponseModel {
  name?: string;
  version?: string;
}

export interface AddOrUpdateModuleRequestModel {
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  version: string;
}

export interface DeleteModuleRequestModel {
  /** @minLength 1 */
  name: string;
}

export interface RebootDeviceResponseModel {
  /** Gets or sets the device name. */
  deviceName?: string;
  /** Gets or sets a value indicating if the sync call was successful or not. */
  success: boolean;
  /**
   * Gets or sets the status of device method invocation.
   * @format int32
   */
  status: number;
  /** Gets or sets the message from the device. */
  messageFromDevice?: string;
}

/** Requests a reboot of the matching IoT device. */
export interface RebootDeviceRequestModel {
  /**
   * IoT Device ID
   * @format guid
   */
  id: string;
}

export interface SyncDeviceTwinResponseModel {
  /** Gets or sets the device name. */
  deviceName?: string;
  /** Gets or sets a value indicating if the sync call was successful or not. */
  success: boolean;
  /**
   * Gets or sets the status of device method invocation.
   * @format int32
   */
  status: number;
  /** Gets or sets the message from the device. */
  messageFromDevice?: string;
}

/** Request device twin sync for one or more IoT device Ids using direct method (device must be online). */
export interface SyncDeviceTwinsRequestModel {
  /** Gets or sets the IoT device ids. */
  ids: string[];
}

export interface DirectHeartbeatResponseModel {
  /** Gets or sets the device name. */
  deviceName?: string;
  /** Gets or sets a value indicating if the heartbeat was successful or not. */
  success: boolean;
  /**
   * Gets or sets the status of device method invocation.
   * @format int32
   */
  status: number;
  /** Gets or sets the message from the device. */
  messageFromDevice?: string;
}

/** Use direct method to request heart beats for one or more IoT devices. */
export interface SendDirectHeartbeatsRequestModel {
  /** Gets or sets the IoT device ids. */
  ids: string[];
}

/** List of device ping responses. */
export interface PingDevicesResponseModel {
  /** Ping responses, one for each device requested. */
  pingResponses?: PingDeviceResponseModel[];
}

/** Ping response for a device. */
export interface PingDeviceResponseModel {
  /** Gets or sets the device name. */
  deviceName?: string;
  /** Gets or sets a value indicating whether the ping was a success. */
  success: boolean;
  /**
   * Gets or sets the status of device method invocation.
   * @format int32
   */
  status: number;
  /** Gets or sets the message from the device. */
  message?: string;
  /**
   * Gets or sets the response time. The value will be null if the ping failed.
   * @format int32
   */
  responseTime?: number;
}

export interface PingDevicesRequestModel {
  pingRequests: PingDeviceRequestModel[];
}

/** For a device request the EM to ping an ip or a host name and return the response. */
export interface PingDeviceRequestModel {
  /**
   * Gets or sets the IoT device identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the the IP address or host name to ping.
   * @minLength 1
   * @maxLength 253
   */
  ipAddressOrHostName: string;
}

export interface RestartModulesResponseModel {
  restartModuleResponses?: RestartModuleResponseModel[];
}

export interface RestartModuleResponseModel {
  /** Gets or sets the device name. */
  deviceName?: string;
  /** Gets or sets a value indicating whether the restart was a success. */
  success: boolean;
  /**
   * Gets or sets the status of device method invocation.
   * @format int32
   */
  status: number;
  /** Gets or sets the message from the device. */
  message?: string;
}

export interface RestartModulesRequestModel {
  restartModuleRequests: RestartModuleRequestModel[];
}

export interface RestartModuleRequestModel {
  /**
   * Gets or sets the IoT device identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name of the module to restart.
   * @minLength 1
   */
  moduleName: string;
}

export interface SwitchIoTDeviceRequestModel {
  /** @format guid */
  oldIoTDeviceId: string;
  /** @format guid */
  newIoTDeviceId: string;
}

export interface AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel {
  /**
   * Gets or sets the provider identifier.
   * @format guid
   */
  providerId: string;
  /**
   * Gets or sets the node identifier that the provider belongs to. This should be a building.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the id of the device that the provider belongs to.
   * @format guid
   */
  deviceId: string;
  /**
   * Gets or sets the id of the power electricity grid.
   * @format guid
   */
  powerElectricityGridSignalId: string;
  /**
   * Gets or sets the maximum value in percentage for the state of charge.
   * @min 0
   * @max 100
   */
  stateOfChargeMax: number;
  /**
   * Gets or sets the minimum value in percentage for the state of charge.
   * @min 0
   * @max 100
   */
  stateOfChargeMin: number;
  /** Gets or sets the time constant. */
  timeConstant: number;
  /** Gets or sets maximum charge rate (KW) */
  maxChargeRate: number;
  /**
   * Gets or sets maximum discharge rate (KW)
   *
   */
  maxDischargeRate: number;
}

export interface DeleteLoBaBatteryChargingThresholdProvidersRequestModel {
  /** Gets or sets the provider ids to delete. */
  providerIds?: string[];
}

export interface LoBaBatteryChargingThresholdProviderResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  providerId: string;
  /**
   * Gets or sets the node id.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the id of the power electricity grid signal.
   * @format guid
   */
  powerElectricityGridSignalId: string;
  /** Gets or sets the maximum value of the state of charge. */
  stateOfChargeMax: number;
  /** Gets or sets the minimum value of the state of charge. */
  stateOfChargeMin: number;
  /** Gets or sets the time constant. */
  timeConstant: number;
  /** Gets or sets maximum charge rate (KW) */
  maxChargeRate: number;
  /**
   * Gets or sets maximum discharge rate (KW)
   *
   */
  maxDischargeRate: number;
}

export interface GetLoBaBatteryChargingThresholdProvidersByNodeIdRequestModel {
  /** Gets or sets the node ids. */
  nodeIds?: string[];
}

export interface GetLoBaBatteryChargingThresholdProvidersByProviderIdRequestModel {
  /** Gets or sets the provider ids. */
  providerIds?: string[];
}

export type NodeResponseModel = BaseNodeResponseModel & {
  /** @format int32 */
  alarmStatus?: number;
  parentIds?: string[];
  equipments?: NodeEquipmentResponseModel[];
  powerControls?: SignalGroupLiteResponseModel[];
  /** @format int32 */
  numberOfActiveAlarms: number;
  tags?: string[];
};

export interface NodeEquipmentResponseModel {
  /** @format guid */
  equipmentId: string;
  name?: string;
  description?: string;
  /** @format guid */
  equipmentTypeId: string;
  /** @format guid */
  nodeId: string;
  linearOptimisations?: SignalGroupLiteResponseModel[];
  powerControls?: SignalGroupLiteResponseModel[];
}

export interface SignalGroupLiteResponseModel {
  /** @format guid */
  signalGroupId: string;
  name?: string;
  canSchedule: boolean;
}

export type AdminNodeResponseModel = BaseNodeResponseModel & {
  parentIds?: string[];
  equipments?: NodeEquipmentResponseModel[];
  tags?: string[];
};

export interface AddOrUpdateNodeRequestModel {
  /** @format guid */
  nodeId?: string;
  parentIds?: string[];
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /** Type of node */
  nodeType: NodeType;
  /** @format double */
  longitude?: number;
  /** @format double */
  latitude?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  street?: string;
  grids?: GridType[];
  tags?: string[];
  buildingInfo?: AddOrUpdateNodeBuildingInfoRequestModel;
}

export interface AddOrUpdateNodeBuildingInfoRequestModel {
  /** @format guid */
  id?: string;
  districtHeatingFacilityId?: string;
  /** This enumeration contains the different status that a building can have. */
  buildingStatus: BuildingStatus;
}

export interface DeleteNodeRequestModel {
  /** @format guid */
  nodeId: string;
}

export interface GetBuildingInfoByBuildingIdsRequestModel {
  buildingIds: string[];
}

export interface UpdateBuildingInfoRequestModel {
  /** @format guid */
  id: string;
  /** @format guid */
  buildingId: string;
  districtHeatingFacilityId?: string;
  /** This enumeration contains the different status that a building can have. */
  buildingStatus: BuildingStatus;
}

export interface GetAlarmSignalTypeIdsForNodesResponseModel {
  signalTypeIds?: string[];
}

export interface AddOrUpdateAlarmNotificationsResponseModel {
  alarmNotifications?: AlarmNotificationResponseModel[];
}

export interface AlarmNotificationResponseModel {
  /** @format guid */
  id: string;
  name?: string;
  nodeIds?: string[];
  userIds?: string[];
  signalTypeIds?: string[];
  /** Gets or sets the severities. This is the integer representations for A, B and C. */
  severities?: number[];
}

export interface AddOrUpdateAlarmNotificationsRequestModel {
  alarmNotifications: AlarmNotificationRequestModel[];
}

export interface AlarmNotificationRequestModel {
  /**
   * @format guid
   * @minLength 1
   */
  id: string;
  /** @minLength 1 */
  name: string;
  nodeIds: string[];
  userIds: string[];
  signalTypeIds?: string[];
  /** Gets or sets the severities. This is the integer representations for A, B and C. */
  severities?: number[];
}

export interface GetAlarmNotificationsResponseModel {
  alarmNotifications?: AlarmNotificationResponseModel[];
}

export interface DeleteAlarmNotificationsRequestModel {
  ids: string[];
}

export interface AddOrUpdatePowerDeltaControlProviderRequestModel {
  /**
   * Gets or sets the provider identifier.
   * @format guid
   */
  providerId: string;
  /**
   * Gets or sets the node identifier that the provider belongs to. This should be a building.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the id of the device that the provider belongs to.
   * @format guid
   */
  deviceId: string;
  /** Gets or sets the type of the Power Delta Control. */
  powerDeltaControlType: PowerDeltaControlType;
  /**
   * Gets or sets the signal id of the return temperature signal.
   * @format guid
   */
  returnTempSignalId: string;
  /** Gets or sets the time constant. */
  timeConstant: number;
}

export enum PowerDeltaControlType {
  Unknown = 'Unknown',
  Heating = 'Heating',
  Cooling = 'Cooling'
}

export interface DeletePowerDeltaControlProvidersRequestModel {
  /** Gets or sets the provider ids to delete. */
  providerIds: string[];
}

export interface PowerDeltaControlProviderResponseModel {
  /** @format guid */
  providerId: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  returnTempSignalId: string;
  powerDeltaControlType: PowerDeltaControlType;
  timeConstant: number;
}

export interface GetPowerDeltaControlProvidersByNodeIdRequestModel {
  /** Gets or sets the node ids. */
  nodeIds: string[];
}

export interface GetPowerDeltaControlProvidersByProviderIdRequestModel {
  /** Gets or sets the provider ids. */
  providerIds: string[];
}

export interface ProcessMapResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the name. */
  name?: string;
  /** Gets or sets the description. */
  description?: string;
}

export interface GetProcessMapsByIdRequestModel {
  ids: string[];
}

export interface AddOrUpdateProcessMapRequestModel {
  /**
   * If not specified a new guid will be created and it will be treated as a new entity.
   * @format guid
   */
  id?: string;
  /**
   * Gets or sets the name.
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * Gets or sets the description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
}

export interface DeleteProcessMapsRequestModel {
  ids: string[];
}

export interface ProcessMapRevisionResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the process map identifier.
   * @format guid
   */
  processMapId: string;
  /** Gets or sets the map. This is base64 encoded string containing the SVG. */
  map?: string;
  /** Gets or sets the comment. */
  comment?: string;
  /** Gets or sets who created the revision. */
  revisedBy?: string;
  /**
   * Gets or sets when the revision was created.
   * @format date-time
   */
  revisedAt: string;
}

export interface GetProcessMapRevisionsByIdRequestModel {
  ids: string[];
}

export interface GetProcessMapRevisionsByProcessMapIdRequestModel {
  processMapIds: string[];
}

export interface AddOrUpdateProcessMapRevisionRequestModel {
  /**
   * If not specified a new guid will be created and it will be treated as a new entity.
   * @format guid
   */
  id?: string;
  /**
   * Gets or sets the process map identifier.
   * @format guid
   */
  processMapId: string;
  /** Gets or sets the base64 encoded string containing the SVG. */
  map?: string;
  /**
   * Gets or sets the comment.
   * @minLength 0
   * @maxLength 500
   */
  comment: string;
}

export interface DeleteProcessMapRevisionsRequestModel {
  ids: string[];
}

export interface EquipmentTypeProcessMapRelationshipResponseModel {
  /**
   * Gets or sets the equipment type identifier.
   * @format guid
   */
  equipmentTypeId: string;
  /**
   * Gets or sets the process map identifier. This will be null if no relationship exists.
   * @format guid
   */
  processMapId?: string;
}

export interface GetEquipmentTypeProcessMapRelationshipsRequestModel {
  equipmentTypeIds: string[];
}

export interface AddOrUpdateEquipmentTypeProcessMapRelationshipRequestModel {
  /** @format guid */
  equipmentTypeId: string;
  /** @format guid */
  processMapId: string;
}

export interface DeleteEquipmentTypeProcessMapRelationshipsRequestModel {
  equipmentTypeIds: string[];
}

export interface NodeProcessMapRelationshipResponseModel {
  /**
   * Gets or sets the node identifier.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the process map identifier. This will be null if no relationship exists.
   * @format guid
   */
  processMapId?: string;
}

export interface GetNodeProcessMapRelationshipsRequestModel {
  nodeIds: string[];
}

export interface AddOrUpdateNodeProcessMapRelationshipRequestModel {
  /** @format guid */
  nodeId: string;
  /** @format guid */
  processMapId: string;
}

export interface DeleteNodeProcessMapRelationshipsRequestModel {
  nodeIds: string[];
}

export interface AddOrUpdateScheduleTypeRequestModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name.
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * Gets or sets the description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /** Gets or sets the type of the signal provider. */
  signalProviderType: SignalProviderType;
  /** Gets or sets the parent child rule. */
  parentChildRule: ParentChildRule;
  /**
   * Gets or sets the timing tolerance. This will determine how long time after the
   * start time that a schedule will be accepted by the recipient (for instance the energy manager).
   */
  timingToleranceInSeconds: number;
  /** Gets or sets the source types. */
  sourceTypes: SourceTypeRequestModel[];
  /**
   * Gets or sets the signal types. These must be validated so that they exist
   * for the selected SignalProviderType
   */
  signalTypeIds: string[];
}

/**
 * Signal Provider Type defines the owner of the Signal Provider and its signal.
 * The service that owns the Signal Provider Type also is responsible for writing values
 */
export enum SignalProviderType {
  Unknown = 'Unknown',
  SteerablePower = 'SteerablePower',
  PowerControl = 'PowerControl',
  LinearOptimisation = 'LinearOptimisation',
  Alarm = 'Alarm',
  Meteorology = 'Meteorology',
  Equipment = 'Equipment',
  HeatingForecast = 'HeatingForecast',
  CoolingForecast = 'CoolingForecast',
  COPCoolingForecast = 'COPCoolingForecast',
  COPHeatingForecast = 'COPHeatingForecast',
  ReversibleHeatPumpCOP = 'ReversibleHeatPumpCOP',
  ReversibleHeatPumpScheduler = 'ReversibleHeatPumpScheduler',
  AccumulatorTankEnergyModel = 'AccumulatorTankEnergyModel',
  EctogridSetpointHeating = 'EctogridSetpointHeating',
  EctogridSetpointCooling = 'EctogridSetpointCooling',
  EctogridSetpointModel = 'EctogridSetpointModel',
  EctogridControlBoundary = 'EctogridControlBoundary',
  DisaIntegration = 'DisaIntegration',
  TemperatureImpact = 'TemperatureImpact',
  ComfortHeating = 'ComfortHeating',
  EcoguardIntegration = 'EcoguardIntegration',
  ReversibleHeatPumpSchedulerConfig = 'ReversibleHeatPumpSchedulerConfig',
  ReversibleHeatPumpControl = 'ReversibleHeatPumpControl',
  OptimaControlSignals = 'OptimaControlSignals',
  ElvacoIntegration = 'ElvacoIntegration',
  PowerDeltaControl = 'PowerDeltaControl',
  ElectricityPeakGuardControlLoop = 'ElectricityPeakGuardControlLoop',
  ElectricityPeakGuardCooling = 'ElectricityPeakGuardCooling',
  ElectricityPeakGuardHeating = 'ElectricityPeakGuardHeating',
  EctogridEnergyUsage = 'EctogridEnergyUsage',
  EctogridEnergyElectricityUsage = 'EctogridEnergyElectricityUsage',
  EctogridEnergyHeatingUsage = 'EctogridEnergyHeatingUsage',
  EctogridEnergyUsageHeating = 'EctogridEnergyUsageHeating',
  EctogridEnergyUsageCooling = 'EctogridEnergyUsageCooling',
  EctogridEnergyDelivery = 'EctogridEnergyDelivery',
  EctogridEnergyHeatingDelivery = 'EctogridEnergyHeatingDelivery',
  EctogridEnergyCoolingDelivery = 'EctogridEnergyCoolingDelivery',
  EctogridEctoCOP = 'EctogridEctoCOP',
  EctogridEctoCOPHeating = 'EctogridEctoCOPHeating',
  EctogridEctoCOPCooling = 'EctogridEctoCOPCooling',
  EctogridEnergyActiveBalancing = 'EctogridEnergyActiveBalancing',
  EctogridEnergyHeatingActiveBalancing = 'EctogridEnergyHeatingActiveBalancing',
  EctogridEnergyCoolingActiveBalancing = 'EctogridEnergyCoolingActiveBalancing',
  EctogridEnergyUsageSubstation = 'EctogridEnergyUsageSubstation',
  EctogridEnergyElectricityUsageSubstation = 'EctogridEnergyElectricityUsageSubstation',
  EctogridEnergyUsageActiveBalancing = 'EctogridEnergyUsageActiveBalancing',
  EctogridEnergyElectricityUsageActiveBalancing = 'EctogridEnergyElectricityUsageActiveBalancing',
  EctogridEnergyHeatingUsageActiveBalancing = 'EctogridEnergyHeatingUsageActiveBalancing',
  EctogridExcessBalancingRatio = 'EctogridExcessBalancingRatio',
  EctogridEnergyHeatingFromGrid = 'EctogridEnergyHeatingFromGrid',
  EctogridEnergyHeatingToGrid = 'EctogridEnergyHeatingToGrid',
  EctogridEnergyCoolingFromGrid = 'EctogridEnergyCoolingFromGrid',
  EctogridEnergyCoolingToGrid = 'EctogridEnergyCoolingToGrid',
  EctogridEnergyToGrid = 'EctogridEnergyToGrid',
  EctogridEnergyFromGrid = 'EctogridEnergyFromGrid',
  AutomaticPowerControlSchedules = 'AutomaticPowerControlSchedules',
  LoBaEnergyElectricityFromGrid = 'LoBaEnergyElectricityFromGrid',
  LoBaEnergyElectricityToGrid = 'LoBaEnergyElectricityToGrid',
  LoBaEnergyElectricityFromBattery = 'LoBaEnergyElectricityFromBattery',
  LoBaEnergyElectricityToBattery = 'LoBaEnergyElectricityToBattery',
  LoBaEnergyElectricityFromPV = 'LoBaEnergyElectricityFromPV',
  LoBaEnergyElectricityConsumption = 'LoBaEnergyElectricityConsumption',
  LoBaSelfSufficiencyRatio = 'LoBaSelfSufficiencyRatio',
  LoBaElectricityPeakShaving = 'LoBaElectricityPeakShaving',
  EctoGridKPIConfig = 'EctoGridKPIConfig',
  LocalBalancingKPIConfig = 'LocalBalancingKPIConfig',
  LoBaBatteryChargingThreshold = 'LoBaBatteryChargingThreshold',
  PowerControlStopSignal = 'PowerControlStopSignal',
  LoBaSelfConsumptionRatio = 'LoBaSelfConsumptionRatio',
  EctogridPreloadingHeating = 'EctogridPreloadingHeating',
  EctogridPreloadingCooling = 'EctogridPreloadingCooling',
  EctogridPreloadingHeatingMapping = 'EctogridPreloadingHeatingMapping',
  EctogridPreloadingCoolingMapping = 'EctogridPreloadingCoolingMapping',
  NordPoolIntegration = 'NordPoolIntegration',
  NetmoreIntegration = 'NetmoreIntegration',
  HeatingDemandPriceLoadShifting = 'HeatingDemandPriceLoadShifting',
  EctogridEnergyHeatingFromGridForecast = 'EctogridEnergyHeatingFromGridForecast',
  LoBaEnergyElectricityConsumptionForecast = 'LoBaEnergyElectricityConsumptionForecast',
  EctogridEnergyCoolingFromGridForecast = 'EctogridEnergyCoolingFromGridForecast',
  LoBaOptimization = 'LoBaOptimization',
  EctoThermal = 'EctoThermal',
  EctoThermalPBU = 'EctoThermalPBU',
  EctoThermalPABU = 'EctoThermalPABU',
  EctoThermalABU = 'EctoThermalABU',
  EctogridEnergyElectricityAncillary = 'EctogridEnergyElectricityAncillary',
  EctogridEnergyHeatingAuxiliarySubstation = 'EctogridEnergyHeatingAuxiliarySubstation',
  EctogridEnergyCoolingAuxiliarySubstation = 'EctogridEnergyCoolingAuxiliarySubstation',
  EctogridEnergyCoolingConsumptionSubstation = 'EctogridEnergyCoolingConsumptionSubstation',
  EctogridEnergyHeatingConsumptionSubstation = 'EctogridEnergyHeatingConsumptionSubstation',
  EctogridEnergyConsumptionSubstation = 'EctogridEnergyConsumptionSubstation',
  EctogridEnergyAuxiliarySubstation = 'EctogridEnergyAuxiliarySubstation',
  EctogridActiveBalancingCOP = 'EctogridActiveBalancingCOP',
  EctogridSubstationCOP = 'EctogridSubstationCOP',
  EctogridSubstationCoolingCOP = 'EctogridSubstationCoolingCOP',
  EctogridEctoSubstationHeatingCOP = 'EctogridEctoSubstationHeatingCOP',
  EctoThermalSetpoint = 'EctoThermalSetpoint',
  EctogridEnergyHeatingToHeatPump = 'EctogridEnergyHeatingToHeatPump',
  EctogridEnergyCoolingToChiller = 'EctogridEnergyCoolingToChiller',
  EctogridEnergyElectricityUsageShared = 'EctogridEnergyElectricityUsageShared',
  EctogridEnergyElectricityUsageHeatingOnly = 'EctogridEnergyElectricityUsageHeatingOnly',
  EctogridEnergyElectricityUsageCoolingOnly = 'EctogridEnergyElectricityUsageCoolingOnly',
  EctogridAuxiliaryEnergyHeatingDelivery = 'EctogridAuxiliaryEnergyHeatingDelivery',
  EctogridAuxiliaryEnergyCoolingDelivery = 'EctogridAuxiliaryEnergyCoolingDelivery',
  EctogridAncillaryElectricityUsage = 'EctogridAncillaryElectricityUsage',
  EctogridEnergyUsageHeatingSubstation = 'EctogridEnergyUsageHeatingSubstation',
  EctogridEnergyUsageCoolingSubstation = 'EctogridEnergyUsageCoolingSubstation',
  EctogridEnergyCoolingFromHeatPump = 'EctogridEnergyCoolingFromHeatPump',
  EctogridEnergyHeatingFromChiller = 'EctogridEnergyHeatingFromChiller',
  EctogridEnergyElectricityUsageHeating = 'EctogridEnergyElectricityUsageHeating',
  EctogridEnergyElectricityUsageCooling = 'EctogridEnergyElectricityUsageCooling',
  EctogridElectricityUsageHeatingModeling = 'EctogridElectricityUsageHeatingModeling',
  EctogridElectricityUsageCoolingModeling = 'EctogridElectricityUsageCoolingModeling',
  EctogridEnergyFromGridNet = 'EctogridEnergyFromGridNet',
  EctogridAuxiliaryEnergyDelivery = 'EctogridAuxiliaryEnergyDelivery',
  EctogridTotalEnergyConsumption = 'EctogridTotalEnergyConsumption',
  EctogridTotalEnergyHeatingConsumption = 'EctogridTotalEnergyHeatingConsumption',
  EctogridTotalEnergyCoolingConsumption = 'EctogridTotalEnergyCoolingConsumption',
  EctogridEnergyCoverageRatio = 'EctogridEnergyCoverageRatio',
  EctogridEnergyHeatingCoverageRatio = 'EctogridEnergyHeatingCoverageRatio',
  EctogridEnergyCoolingCoverageRatio = 'EctogridEnergyCoolingCoverageRatio',
  EctogridEctoCOPSubstation = 'EctogridEctoCOPSubstation',
  EctogridEctoCOPHeatingSubstation = 'EctogridEctoCOPHeatingSubstation',
  EctogridEctoCOPCoolingSubstation = 'EctogridEctoCOPCoolingSubstation',
  EctogridEctoCOPActiveBalancing = 'EctogridEctoCOPActiveBalancing',
  EctoThermalPreloadingControlHeating = 'EctoThermalPreloadingControlHeating',
  EctoThermalPreloadingControlCooling = 'EctoThermalPreloadingControlCooling',
  EctogridEnergyHeatingToHeatPumpForecast = 'EctogridEnergyHeatingToHeatPumpForecast',
  EctogridEnergyCoolingToChillerForecast = 'EctogridEnergyCoolingToChillerForecast',
  EctogridEnergyHeatingDeliveryForecast = 'EctogridEnergyHeatingDeliveryForecast',
  EctogridEnergyCoolingDeliveryForecast = 'EctogridEnergyCoolingDeliveryForecast',
  EctogridWarmPipeMaxEctoThermal = 'EctogridWarmPipeMaxEctoThermal',
  EctogridColdPipeMaxEctoThermal = 'EctogridColdPipeMaxEctoThermal',
  EctogridWarmPipeMinEctoThermal = 'EctogridWarmPipeMinEctoThermal',
  EctogridColdPipeMinEctoThermal = 'EctogridColdPipeMinEctoThermal',
  EctogridEctoControlGrid = 'EctogridEctoControlGrid',
  EctogridEctoControlActiveBalancingUnit = 'EctogridEctoControlActiveBalancingUnit',
  EctogridActiveBalancingUnitForecast = 'EctogridActiveBalancingUnitForecast',
  EctogridEctoControlPassiveBalancingUnit = 'EctogridEctoControlPassiveBalancingUnit',
  EctogridEctoControlSubstation = 'EctogridEctoControlSubstation',
  EctogridElectricityUsageCoolingModelingForecast = 'EctogridElectricityUsageCoolingModelingForecast',
  EctogridElectricityUsageHeatingModelingForecast = 'EctogridElectricityUsageHeatingModelingForecast',
  EctogridEnergyElectricityUsageForecast = 'EctogridEnergyElectricityUsageForecast',
  EctogridEnergyFromGridNetForecast = 'EctogridEnergyFromGridNetForecast',
  EctogridEnergyElectricityUsageSubstationForecast = 'EctogridEnergyElectricityUsageSubstationForecast',
  EctogridElectricityUsageCompressorSubstation = 'EctogridElectricityUsageCompressorSubstation',
  EctogridElectricityUsageCompressorSubstationForecast = 'EctogridElectricityUsageCompressorSubstationForecast'
}

export enum ParentChildRule {
  Unknown = 'Unknown',
  ChildWins = 'ChildWins',
  ParentWins = 'ParentWins'
}

export interface SourceTypeRequestModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name. This can for instance be "Optima Power Control".
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * Gets or sets the description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /**
   * Gets or sets the priority. Used when there are multiple source types.
   * Priority is used to decide which source trumps which when their schedules overlap.
   */
  priority: number;
}

export interface DeleteScheduleTypesRequestModel {
  ids?: string[];
}

export interface ScheduleTypeResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the name. */
  name?: string;
  /** Gets or sets the description. */
  description?: string;
  /** Gets or sets the type of the signal provider. */
  signalProviderType: SignalProviderType;
  /** Gets or sets the parent child rule. */
  parentChildRule: ParentChildRule;
  /**
   * Gets or sets the timing tolerance. This will determine how long time after the
   * start time that a schedule will be accepted by the recipient (for instance the energy manager).
   */
  timingToleranceInSeconds: number;
  /** Gets or sets the source types. */
  sourceTypes?: SourceTypeResponseModel[];
  /**
   * Gets or sets the signal type identifiers. These must be validated so that they exist
   * for the selected SignalProviderType
   */
  signalTypeIds?: string[];
}

export interface SourceTypeResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the name. This can for instance be "Optima Power Control". */
  name?: string;
  /** Gets or sets the description. */
  description?: string;
  /**
   * Gets or sets the priority. Used when there are multiple source types.
   * Priority is used to decide which source trumps which when their schedules overlap.
   */
  priority: number;
}

export interface GetScheduleTypesByIdRequestModel {
  scheduleTypeIds?: string[];
}

export interface SignalCategoryResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the name. */
  name?: string;
  /** Gets or sets the description. */
  description?: string;
  /** Gets or sets a value indicating whether this instance is a system category. */
  isSystem: boolean;
}

export interface GetSignalCategoriesByIdRequestModel {
  /**
   * Gets or sets the identifiers of the signal categories to retrieve.
   * At least one identifier must be passed.
   */
  ids?: string[];
}

export interface AddOrUpdateSignalCategoryRequestModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name.
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * Gets or sets the description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /** Gets or sets a value indicating whether this instance is a system category. */
  isSystem: boolean;
}

export interface DeleteSignalCategoriesRequestModel {
  /** Gets or sets the identifiers of the signal categories to delete. */
  ids?: string[];
}

export interface FullSignalProviderResponseModel {
  nodeIds?: string[];
  signals?: SignalProviderSignalResponseModel[];
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   */
  signalProviderType: SignalProviderType;
  signalProviderName?: string;
  /** @format guid */
  signalProviderId: string;
}

export interface SignalProviderSignalResponseModel {
  description?: string;
  /** @format guid */
  signalId: string;
  name?: string;
  isWritable: boolean;
  /** Format of the timeseries. Discrete or Continuous. */
  dataFormat: DataFormat;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. This list may be empty. */
  signalCategoryIds?: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
}

export interface GetSignalProvidersBySignalIdsRequestModel {
  signalIds: string[];
}

export interface SignalProviderByNodeResponseModel {
  /** @format guid */
  signalProviderId: string;
  signalProviderName?: string;
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   */
  signalProviderType: SignalProviderType;
  signals?: SignalResponseModel[];
  nodeIds?: string[];
}

/** The signal with all its data fields. */
export interface SignalResponseModel {
  /**
   * Id of the signal
   * @format guid
   */
  signalId: string;
  /** Human readable display name. */
  name?: string;
  /** Signal description. */
  description?: string;
  /** Format of the timeseries. Continuous vs Discrete */
  dataFormat: DataFormat;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Indicates if a user is allowed to write to the signal in the frontend. */
  isWritable: boolean;
  /** Gets or sets the signal category identifiers. This list may be empty. */
  signalCategoryIds?: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
}

export interface GetSignalsByNodeRequestModel {
  nodesIds?: string[];
  signalProviderTypes?: SignalProviderType[];
}

export interface GetDetailedSignalsByNodeRequestModel {
  /**
   * @format guid
   * @minLength 1
   */
  nodeId: string;
  signalProviderTypes?: SignalProviderType[];
  recursive: boolean;
  /**
   * Represents the different type of grids.
   * May be combined.
   */
  grid: GridType;
}

export interface GetSignalsByMapIdsRequestModel {
  mapIds: string[];
}

/** Gets signal mappings by search criteria. */
export interface GetMappingsRequestModel {
  /** The id of the mappings one wants to get the mappings for. */
  ids?: string[];
  /** Get mappings that include specified signal Ids as either source or desired signals. */
  signalIds?: string[];
  /** The nodes for which one wants any mappings there is for, including their children. */
  nodeIds?: string[];
  /** Get mappings for specific signal providers. */
  signalProviderIds?: string[];
  /** Get mappings for specific signal provider types. */
  signalProviderTypes?: SignalProviderType[];
  /** Returns only a subset where both the source and destination signals belong to the same signal provider. */
  onlyInternalMappings: boolean;
}

/** Adds or Updates Signal Mapping */
export interface AddOrUpdateMappingRequestModel {
  /**
   * The id of the mapping.
   * @format guid
   */
  id?: string;
  /** A human readable name of the mapping. */
  name?: string;
  /**
   * Decides what the mapper should do.
   * I.e Nothing, send on push or cyclic.
   */
  mappingAction: MappingAction;
  /**
   * How often, in minutes, to look for, and send values for.
   * Used if MappingAction is set to If set to SendBasedOnCycleTime.
   * @min 0
   * @max 59
   */
  cycleTime: number;
  /** Destination configuration. */
  destinationConfig: AddOrUpdateDestinationConfig;
  /** Source Configuration */
  sourceConfig: AddOrUpdateSourceConfig;
}

export interface AddOrUpdateDestinationConfig {
  /**
   * The id of the destination config.
   * @format guid
   */
  id?: string;
  /**
   * Time To Live in seconds needs to be set for when sending messages to EM in order to not
   * increase the EM queue unnecessarily.
   */
  ttl: number;
  /** The destination signals to send the values to. */
  configSignals?: AddOrUpdateDestinationConfigSignalRequestModel[];
}

/** Adds or Updates the destination signals.  */
export interface AddOrUpdateDestinationConfigSignalRequestModel {
  /**
   * Destination signal configuration Id.
   * @format guid
   */
  id?: string;
  /**
   * Destination signals may be time shifted compared to their inputs.
   * This could be used in instances where each hour in the day should be represented as a signal.
   * Thus the input can be time shifted say three hours and be put to a specific modbus signal.
   * @format int64
   */
  timeOffset: number;
  /**
   * Default value is to be set if there are no values sent to the EM.
   * @format double
   */
  defaultValue?: number;
  /**
   * Destination signal Id.
   * @format guid
   */
  signalId: string;
}

export interface AddOrUpdateSourceConfig {
  /**
   * The id of the source config.
   * @format guid
   */
  id?: string;
  /**
   * When one uses MappingAction.SendBasedOnCycleTime there is a need to not only send signal values
   * from now and forward, but to also include older values that can have been missed due to the cycle time
   * or that older values can be useful to send also.
   * MappingAction.SendOnPush is not affected by this value as it will send exactly what it got at that point in time.
   */
  hoursBack: number;
  /** If the value is set only a certain amount of hours will be included in the signal time series. */
  hoursForward?: number;
  /** What sampling interval to use. */
  samplingInterval: SamplingInterval;
  /** Types of aggregation when retrieving signal time series data. */
  aggregation: AggregationType;
  /** Overrides the StartDate and tries to get the latest value before given start date, if exists. */
  useLastValueBeforeStartDate: boolean;
  /**
   * Source config signals.
   * If more than one source is used, aggregation must be specified.
   */
  configSignals?: AddOrUpdateSourceConfigSignal[];
}

export interface AddOrUpdateSourceConfigSignal {
  /**
   * The id of the source config signal.
   * @format guid
   */
  id?: string;
  /**
   * The signal, who's value will be used in the mapping.
   * @format guid
   */
  signalId: string;
}

/** Delete a Signal Mapping */
export interface DeleteMappingRequestModel {
  /**
   * The id of the mapping to delete.
   * @format guid
   */
  id: string;
}

/** Runs a mapping, with the option to specify how the signals should be. */
export interface SignalMappingToActionResponseModel {
  /**
   * Mapping Id
   * @format guid
   */
  id: string;
  /** Optional configuration of the resulting timeseries */
  signalMappingToActionOverride?: SignalMappingToActionOverrideResponseModel;
}

/** Defines properties that can be overriden for a given signal mapping */
export interface SignalMappingToActionOverrideResponseModel {
  /**
   * Override any set Time to live when sent to Device.
   * @format time-span
   */
  ttl: string;
  /**
   * Override the mappings defined start date.
   * @format date-time
   */
  startDate: string;
  /**
   * Override the mappings defined end date.
   * @format date-time
   */
  endDate: string;
  /** Override the mappings defined, if any,  Sampling interval. */
  samplingInterval: SamplingInterval;
  /** Override the mappings defined, if any, aggregation type. */
  aggregationType: AggregationType;
  /** Set if the date is inclusive or not. */
  isInclusive: boolean;
  /**
   * The fill option. Make sure to set value.NumericalFillValue.NumericalFillValue
   * if Numerical is supplied here. For more information about the options see here: https://docs.influxdata.com/influxdb/v1.7/query_language/data_exploration/#group-by-time-intervals-and-fill
   */
  fillOption: FillOption;
  /**
   * The numerical fill value. This value will be used if value.FillOption.FillOption equals Numerical
   * @format decimal
   */
  numericalFillValue: number;
}

/**
 * This enumeration contains the different fill options that can be used when getting data from Influx.
 * For more information: https://docs.influxdata.com/influxdb/v1.7/query_language/data_exploration/#group-by-time-intervals-and-fill.
 */
export enum FillOption {
  None = 'None',
  Previous = 'Previous',
  Linear = 'Linear',
  Null = 'Null',
  Numerical = 'Numerical'
}

/** Runs a mapping, with the option to specify how the signals should be. */
export interface MappingToActionRequestModel {
  /**
   * Mapping Id
   * @format guid
   * @minLength 1
   */
  id: string;
  /** Optional configuration of the resulting timeseries */
  mappingToActionOverride?: MappingToActionOverrideRequestModel;
}

/** Defines properties that can be overriden for a given signal mapping */
export interface MappingToActionOverrideRequestModel {
  /**
   * Override any set Time to live when sent to Device.
   * @format time-span
   */
  ttl: string;
  /**
   * Override the mappings defined start date.
   * @format date-time
   */
  startDate: string;
  /**
   * Override the mappings defined end date.
   * @format date-time
   */
  endDate: string;
  /** Override the mappings defined, if any,  Sampling interval. */
  samplingInterval: SamplingInterval;
  /** Override the mappings defined, if any, aggregation type. */
  aggregationType: AggregationType;
  /** Set if the date is inclusive or not. */
  isInclusive: boolean;
  /**
   * The fill option. Make sure to set value.NumericalFillValue.NumericalFillValue
   * if Numerical is supplied here. For more information about the options see here: https://docs.influxdata.com/influxdb/v1.7/query_language/data_exploration/#group-by-time-intervals-and-fill
   */
  fillOption: FillOption;
  /**
   * The numerical fill value. This value will be used if value.FillOption.FillOption equals Numerical
   * @format decimal
   */
  numericalFillValue: number;
  /** Overrides the StartDate and tries to get the latest value before given start date, if exists. */
  useLastValueBeforeStartDate: boolean;
}

/** Delete a Signal value on a specific date */
export interface DeleteSignalValueRequestModel {
  /** @format guid */
  signalId: string;
  /**
   * @format date-time
   * @minLength 1
   */
  date: string;
}

/** Delete a Signal value on a specific time range */
export interface DeleteSignalValuesInTimeRangeRequestModel {
  /** @format guid */
  signalId: string;
  /**
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  endDate: string;
}

export interface BackfillEIoTRequestModel {
  /**
   * Start date of the interval
   * @format date-time
   */
  startDate: string;
  /**
   * End date of the interval
   * If no value is provided DateTime.MaxValue will be used
   * @format date-time
   */
  endDate?: string;
  /** A list of signal types that will be excluded */
  excludedSignalTypes?: string[];
  /** Flag to specify if should backfill deleted signals */
  includeDeletedSignals: boolean;
}

export interface SignalTypeFolderResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the name. */
  name?: string;
  /** Gets or sets the description. */
  description?: string;
  /**
   * Gets or sets the parent identifier. If this is null then it is a root folder.
   * There are several root folders in the system.
   * @format guid
   */
  parentId?: string;
  /** Gets or sets a value indicating whether this instance is a system signal type folder. */
  isSystem: boolean;
}

export interface GetSignalTypeFoldersByIdRequestModel {
  /**
   * Gets or sets the identifiers of the signal type folders to retrieve.
   * At least one identifier must be passed.
   */
  ids?: string[];
}

export interface AddOrUpdateSignalTypeFolderRequestModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name.
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * Gets or sets the description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /**
   * Gets or sets the parent identifier. If this is null then it is a root folder.
   * There are several root folders in the system.
   * @format guid
   */
  parentId?: string;
  /** Gets or sets a value indicating whether this instance is a system category. */
  isSystem: boolean;
}

export interface DeleteSignalTypeFoldersRequestModel {
  /** Gets or sets the identifiers of the signal type folders to delete. */
  ids?: string[];
}

export interface SignalTypeResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the name. */
  name?: string;
  /** Gets or sets the description. */
  description?: string;
  /**
   * Gets or sets the unit identifier.
   * @format guid
   */
  unitId: string;
  /**
   * Gets or sets the folder identifier.
   * @format guid
   */
  signalTypeFolderId?: string;
  /** Gets or sets a value indicating whether this instance is a system type. */
  isSystem: boolean;
}

export interface GetSignalTypesByIdRequestModel {
  /**
   * Gets or sets the identifiers of the signal types to retrieve.
   * At least one identifier must be passed.
   */
  ids?: string[];
}

export interface AddOrUpdateSignalTypeRequestModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the name.
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /**
   * Gets or sets the description.
   * @minLength 0
   * @maxLength 500
   */
  description?: string;
  /**
   * Gets or sets the unit identifier.
   * @format guid
   */
  unitId: string;
  /**
   * Gets or sets the folder identifier.
   * @format guid
   */
  signalTypeFolderId?: string;
  /** Gets or sets a value indicating whether this instance is a system category. */
  isSystem: boolean;
}

export interface DeleteSignalTypesRequestModel {
  /** Gets or sets the identifiers of the signal types to delete. */
  ids?: string[];
}

export interface TemperatureImpactProviderSignalsResponseModel {
  /**
   * Gets or sets the node identifier.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the power control global amplitude desired signal identifier.
   * If this is empty then the building doesn't have a power control provider.
   * @format guid
   */
  powerControlGlobalAmplitudeDesiredSignalId?: string;
  /**
   * Gets or sets the meteorology T signal identifier.
   * If this is empty then the building doesn't have a weather provider.
   * @format guid
   */
  meteorologyTSignalId?: string;
}

export interface GetTemperatureImpactProviderSignalsRequestModel {
  /** Gets or sets the node identifiers. This is the ids of the buildings to retrieve the signals for. */
  nodeIds: string[];
}

export interface AddOrUpdateTemperatureImpactProviderRequestModel {
  /**
   * Gets or sets the provider identifier.
   * @format guid
   */
  providerId: string;
  /**
   * Gets or sets the node identifier that the provider belongs to. This should be a building.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the power control global amplitude desired signal identifier.
   * @format guid
   */
  powerControlGlobalAmplitudeDesiredSignalId: string;
  /**
   * Gets or sets the meteorology T signal identifier. This will be used for getting the temperature from the meteorology signal provider.
   * @format guid
   */
  meteorologyTSignalId: string;
}

export interface DeleteTemperatureImpactProvidersRequestModel {
  /** Gets or sets the provider ids to delete. */
  providerIds: string[];
}

export interface MigrateTemperatureImpactProvidersResponseModel {
  /** Gets or sets the message indicating the outcome of the migration. */
  message?: string;
}

export interface TenantResponseModel {
  id?: string;
  name?: string;
  description?: string;
  isDeactivated: boolean;
}

export interface PublishToTenantsResponseModel {
  /** Gets or sets a value indicating whether the operation was a success. */
  success: boolean;
  /** Gets or sets the error messages describing the problems that occurred during publishing. */
  errorMessages?: string[];
}

export interface PublishToTenantsRequestModel {
  tenantIds?: string[];
}

export interface AlarmsListResponseModel {
  /** @format int32 */
  totalPages: number;
  alarms?: AlarmInfoResponseModel[];
}

export interface AlarmInfoResponseModel {
  /** @format date-time */
  alarmDate: string;
  /** @format guid */
  signalId: string;
  /** @format int32 */
  severity?: number;
  /** @format date-time */
  isActiveDate?: string;
  /** @format date-time */
  isAcknowledgeDate?: string;
  acknowledgedBy?: string;
  description?: string;
  alarmCommentMessage?: string;
  isActive?: boolean;
  isAcknowledged?: boolean;
  /** @format int32 */
  numberOfActiveAlarms: number;
  equipmentName?: string;
  /** @format guid */
  nodeId: string;
  name?: string;
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
   * Stateless - Can be seen as notifications for the user.
   */
  alarmType: AlarmType;
  needAcknowledgment: boolean;
}

export interface AcknowledgeAlarmRequestModel {
  /** @format guid */
  signalId: string;
  /**
   * @minLength 0
   * @maxLength 250
   */
  comment?: string;
  /** @format date-time */
  alarmDate: string;
}

export interface AcknowledgeAllAlarmsRequestModel {
  /** @format guid */
  nodeId?: string;
  /**
   * Represents the different type of grids.
   * May be combined.
   */
  grid: GridType;
  /**
   * @minLength 0
   * @maxLength 250
   */
  comment?: string;
  /** Gets or sets the search phrase. */
  searchPhrase?: string;
  /** Gets or sets the building statuses. Leave this list empty if no filter on building status is wanted. */
  buildingStatuses?: BuildingStatus[];
}

/**
 * Returns Equipment Signal data returned after add or update.
 * As this response is used in multiple places the request and response do not necessarily match on those APIs.
 */
export interface AlarmSignalResponseModel {
  /**
   * The signal Id
   * @format guid
   */
  id: string;
  /**
   * Signals are tied to to an equipment node.
   * The node Id in this case is the same as EquipmentId.
   * @format guid
   */
  nodeId: string;
  /** Display name shown for the user. */
  name?: string;
  /** Description to show the user for that alarm */
  description?: string;
  /**
   * Alarm severity is handled by the backend as simple integers while frontend tend to use letters
   * 0 = A
   * 1 = B
   * 2 = C
   * This may not be a complete list. In the future there can be more or changed mappings.
   * If the signal type is not "Alarm" this field should be ignored.
   * @format int32
   */
  severity: number;
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling.
   * Stateless - Can be seen as notifications for the user.
   * If the signal type is not "Alarm" this field should be ignored.
   */
  alarmType: AlarmType;
  /** IsWritable specifies if the frontend should/can allow the user the user to update the value. */
  isWritable: boolean;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /** Format of the timeseries. Discrete or Continuous. */
  dataFormat: DataFormat;
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   */
  signalTypeId: string;
  /** Gets or sets if the signal should be acknowledged by an user. */
  needAcknowledgment: boolean;
  /** Gets or sets the signal category identifiers. This list may be empty. */
  signalCategoryIds?: string[];
}

export interface GetAlarmSignalsByIdRequestModel {
  signalIds: string[];
}

export interface GetAlarmSignalsByDeviceNameAndSignalTypeIdRequestModel {
  /**
   * Gets or sets the name of the device that the signals are connected to.
   * This is the same as the value for "device id" on the IoT hub.
   * @minLength 1
   */
  deviceName: string;
  signalTypeIds: string[];
}

export enum LogEventLevel {
  Verbose = 'Verbose',
  Debug = 'Debug',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
  Fatal = 'Fatal'
}

export interface KeyValuePairOfStringAndString {
  key: string;
  value: string;
}

export interface ClearCacheRequestModel {
  caches?: EfCacheTag[];
}

export enum EfCacheTag {
  Unknown = 'Unknown',
  Node = 'Node',
  AlarmSignal = 'AlarmSignal',
  AlarmSignalTemplate = 'AlarmSignalTemplate',
  EquipmentTemplate = 'EquipmentTemplate',
  Device = 'Device',
  SignalProvider = 'SignalProvider',
  SignalProviderTemplate = 'SignalProviderTemplate',
  User = 'User',
  PowerControl = 'PowerControl',
  RemoteOptimisation = 'RemoteOptimisation',
  TemperatureImpact = 'TemperatureImpact',
  Meteorology = 'Meteorology',
  Comfort = 'Comfort',
  DeviceTemplate = 'DeviceTemplate',
  ProcessMap = 'ProcessMap',
  Schedule = 'Schedule',
  PowerDeltaControl = 'PowerDeltaControl',
  LoBaBatteryChargingThreshold = 'LoBaBatteryChargingThreshold'
}

export interface ComfortHeatingBuildingResponseModel {
  /**
   * The id of the node.
   * @format guid
   */
  nodeId: string;
  /** The facility id of the building. */
  facilityId?: string;
  /** The name of the node. */
  nodeName?: string;
  /** The comfort equipments of the building. */
  comfortEquipments?: ComfortEquipmentResponseModel[];
}

export interface ComfortEquipmentResponseModel {
  /**
   * The id of the provider.
   * @format guid
   */
  providerId: string;
  /**
   * The id of the equipment.
   * @format guid
   */
  equipmentId: string;
  /** The display name of the provider. */
  displayName?: string;
  /**
   * The id of the desired temperature signal.
   * @format guid
   */
  desiredTemperatureSignalId: string;
  /**
   * The id of the mean temperature signal.
   * @format guid
   */
  meanTemperatureSignalId: string;
  comfortServiceEnabled: boolean;
  comfortAlgorithmsEnabled: boolean;
  comfortAlarmsEnabled: boolean;
  comfortMaintenanceEnabled: boolean;
  /** The comfort alarm signals for the provider. */
  alarmSignals?: ComfortAlarmSignalResponseModel[];
}

export interface ComfortAlarmSignalResponseModel {
  /**
   * The id of the alarm signal
   * @format guid
   */
  id: string;
  /** Gets or sets the name of the signal. */
  name?: string;
}

export interface GetComfortHeatingBuildingsByNodeIdRequestModel {
  /** The node ids to retrieve comfort buildings for. */
  nodeIds: string[];
}

export interface GetComfortHeatingBuildingsByFacilityIdRequestModel {
  /** The facility ids to retrieve comfort buildings for. */
  facilityIds: string[];
}

/**
 * Adds signal values to backend system.
 * Also if signal values are for a Desired signal it will be routed to the appropriate provider.
 */
export interface AddEquipmentSignalValuesRequestModel {
  /**
   * Signal to add the values for.
   * @format guid
   * @minLength 1
   */
  signalId: string;
  /** The values to add for that signal. */
  values: EquipmentSignalValueRequestModel[];
}

export interface EquipmentSignalValueRequestModel {
  /**
   * @format date-time
   * @minLength 1
   */
  time: string;
  /** @format double */
  value: number;
}

export interface TelemetryResponseModel {
  /**
   * The id of the signal.
   * @format guid
   */
  signalId: string;
  /** The values for the signal within the requested time range. */
  signals?: TelemetryValueResponseModel[];
  /** The last value before the requested time range. */
  previousValue?: TelemetryValueResponseModel;
}

export interface TelemetryValueResponseModel {
  /** @format date-time */
  time: string;
  /** @format double */
  value: number;
}

export interface GetLatestEquipmentSignalValuesRequestModel {
  signalIds: string[];
  /** @format date-time */
  endDate?: string;
}

export interface GetEquipmentSignalValuesByTimeRangeRequestModel {
  /** The id of the signals to get values for. */
  signalIds: string[];
  /**
   * The start date to retrieve values for.
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * The end date to retrieve values for.
   * @format date-time
   * @minLength 1
   */
  endDate: string;
  /** The sampling interval to return. Use Raw to retrieve individual values. */
  samplingInterval: SamplingInterval;
  /** Specifies the aggregation to use for the values. */
  aggregation: AggregationType;
  /**
   * If true, the last value (if any), before StartDate will be included in the result.
   * @default false
   */
  includeLastPreviousValue: boolean;
}

export interface ComfortAlarmsListResponseModel {
  /**
   * Gets or sets the total amount of pages.
   * @format int32
   */
  totalPages: number;
  /**
   * Gets or sets the total amount of records.
   * @format int32
   */
  totalRecords: number;
  /** The list of alarm signals for the provider. */
  alarms?: ComfortAlarmResponseModel[];
}

export interface ComfortAlarmResponseModel {
  /**
   * Id of the alarm signal
   * @format guid
   */
  signalId: string;
  /**
   * The id of the node the alarm is connected to.
   * @format guid
   */
  nodeId: string;
  /** A comment about the alarm. */
  alarmCommentMessage?: string;
  /** The name of the alarm. */
  name?: string;
  /** The description of the alarm. */
  description?: string;
  /** Flag indicating whether the alarm is active or not. */
  isActive?: boolean;
  /**
   * The date the alarm became active.
   * @format date-time
   */
  isActiveDate?: string;
  /** Flag indicating whether the alarm is acknowledged or not. */
  isAcknowledged?: boolean;
  /**
   * The date the alarm was acknowledged.
   * @format date-time
   */
  isAcknowledgeDate?: string;
  isRead?: boolean;
  /** @format date-time */
  isReadDate?: string;
  /**
   * The number of active alarms since the last time the alarm was acknowledged.
   * @format int32
   */
  numberOfActiveAlarms: number;
  /**
   * The severity of the alarm.
   * @format int32
   */
  severity: number;
  /**
   * The date the alarm occurred.
   * @format date-time
   */
  alarmDate?: string;
}

export interface GetComfortAlarmHistoryRequestModel {
  /**
   * The id of the facility to get alarm history for.
   * @minLength 1
   */
  facilityId: string;
  /** Gets or sets the severities. Leave this list empty if no filter on severity is wanted. */
  severities?: number[];
  /** Gets or sets the is active filter. Pass null if no filter is wanted. */
  isActive?: boolean;
  /** Gets or sets the is acknowledged. Pass null if no filter is wanted. */
  isAcknowledged?: boolean;
  /**
   * Gets or sets from date. Pass null if no filter is wanted.
   * @format date-time
   */
  fromDate?: string;
  /**
   * Gets or sets the to date. Pass null if no filter is wanted.
   * @format date-time
   */
  toDate?: string;
  /**
   * Name of the column to sort by.
   * Value is one of:
   *
   *
   *    date
   *
   *
   *    severity
   *
   *
   *    signal
   *
   *
   *    status
   *
   */
  sortColumn?: string;
  /**
   * Sort order of the result.
   * Value is one of:
   *
   *
   *  asc
   *
   *
   *  desc
   *
   */
  sortOrder?: string;
  /**
   * Specifies which page that should be returned.
   * @min 0
   * @max 2147483647
   */
  page: number;
  /**
   * Maximum number of items per page.
   * @min 1
   * @max 100
   */
  pageSize: number;
}

export interface GetComfortAlarmsRequestModel {
  /**
   * The facility id to get alarms for.
   * @minLength 1
   */
  facilityId: string;
  /**
   * If set, filters the alarms based on severity.
   * @format int32
   */
  severity?: number;
  /**
   * If set, specifies the name of the column to sort by.
   * Value is one of:
   *
   *
   *    date
   *
   *
   *    severity
   *
   *
   *    signal
   *
   *
   *    status
   *
   */
  sortColumn?: string;
  /**
   * If set, specifies the sort order.
   * Value is one of:
   *
   *
   *  asc
   *
   *
   *  desc
   *
   */
  sortOrder?: string;
  /**
   * Specifies which page that should be returned.
   * @min 1
   * @max 2147483647
   */
  page: number;
  /**
   * Maximum number of items per page.
   * @min 1
   * @max 100
   */
  pageSize: number;
}

export interface AddOrUpdateComfortNotificationReceiversRequestModel {
  /**
   * Facility id to add receivers for.
   * @minLength 1
   */
  facilityId: string;
  /** The list of receivers */
  emails?: string[];
}

export interface ComfortNotificationReceiversResponseModel {
  /** The facility id of the notification receivers. */
  facilityId?: string;
  /**
   * A list of emails of the receivers. These will be notified if a comfort
   * alarm occurs on a node within the facility.
   */
  emails?: string[];
}

export interface GetComfortNotificationReceiversRequestModel {
  /**
   * The id of the facility to retrieve notification receivers from.
   * @minLength 1
   */
  facilityId: string;
}

export interface DeleteComfortNotificationReceiversRequestModel {
  /** The list of facility ids from which all notification receivers will be removed. */
  facilityIds?: string[];
}

export interface DashboardCollectionNodeRelationsResponseModel {
  /** The collection of all relations between nodes and dashboard collections. */
  nodeToDashboardCollectionRelations?: Record<string, string>;
}

export interface DashboardCollectionViewResponseModel {
  /**
   * Gets or sets the id of collection.
   * @format guid
   */
  dashboardCollectionId: string;
  /** The name of this collection. */
  name?: string;
  /** The description of this collection. */
  description?: string;
  /**
   * Gets or sets the id of the default dashboard in the collection.
   * @format guid
   */
  defaultDashboardId?: string;
  /** Gets or sets the list of dashboards included in this collection. */
  dashboards?: DashboardViewResponseModel[];
}

export interface DashboardViewResponseModel {
  /**
   * The ID of the dashboard.
   * @format guid
   */
  dashboardId: string;
  /** The name of this dashboard. */
  name?: string;
  /** The description of this dashboard. */
  description?: string;
  /** The JSON content of this dashboard. */
  jsonData?: any;
}

export interface GetDashboardCollectionViewByNodeIdRequestModel {
  /**
   * The node id used to find a collection.
   * @format guid
   */
  nodeId: string;
}

export interface ResetServiceRequestModel {
  serviceNames: ResetServiceNames;
}

export enum ResetServiceNames {
  None = 'None',
  Alarm = 'Alarm',
  IoT = 'IoT',
  Core = 'Core',
  PowerControl = 'PowerControl',
  RemoteOptimisation = 'RemoteOptimisation',
  Meteorology = 'Meteorology',
  Notification = 'Notification',
  TemperatureImpact = 'TemperatureImpact',
  ComfortService = 'ComfortService',
  Schedule = 'Schedule',
  Dashboard = 'Dashboard',
  PowerDeltaControl = 'PowerDeltaControl',
  LoBaBatteryChargingThreshold = 'LoBaBatteryChargingThreshold',
  All = 'All'
}

export interface SetSignalResponseModel {
  /**
   * Gets or sets the signal identifier.
   * @format guid
   */
  signalId: string;
  /** Gets or sets the name of the device. */
  deviceName?: string;
  /** Gets or sets a value indicating whether the operation was a success. */
  success: boolean;
  /**
   * Gets or sets the status of device method invocation.
   * @format int32
   */
  status: number;
  /** Gets or sets the message from the device. */
  message?: string;
}

export interface SetSignalRequestModel {
  /** @format guid */
  equipmentSignalId: string;
  /** @format double */
  value: number;
}

export interface SetSignalsRequestModel {
  signalValues?: SetSignalRequestModel[];
}

export interface SetSignalWithAuditRequestModel {
  /** @format guid */
  equipmentSignalId: string;
  /** @format double */
  value: number;
  /** @format double */
  oldValue?: number;
  message?: string;
}

export interface SetSignalsWithAuditRequestModel {
  signalValues?: SetSignalWithAuditRequestModel[];
}

export interface LiveTelemetryRequestModel {
  /** @format guid */
  equipmentId: string;
}

export interface DisaIntegrationProviderResponseModel {
  /** @format guid */
  providerId: string;
  /** @format guid */
  nodeId: string;
  displayName?: string;
  facilityId?: string;
}

export interface AddOrUpdateDisaIntegrationProviderRequestModel {
  /** @format guid */
  providerId: string;
  /** @format guid */
  nodeId: string;
  /** @minLength 1 */
  displayName: string;
  /** @minLength 1 */
  facilityId: string;
}

export interface SignalProviderTelemetryResponseModel {
  /** @format guid */
  signalId: string;
  signals?: SignalProviderTelemetryValueResponseModel[];
}

export interface SignalProviderTelemetryValueResponseModel {
  /** @format date-time */
  time: string;
  /** @format double */
  value: number;
}

export interface GetLatestSignalValuesRequestModel {
  signalIds: string[];
  /** @format date-time */
  endDate?: string;
}

/** All Enums and fixed values used by the Gateway. */
export interface GetEnumsAndFixedConfigurationsResponseModel {
  /**
   * There are two types of alarms in Ectocloud.
   * Stateful - Used for normal Alarm handling. https://confluence.eon.com/display/BTGCES/Alarm+Domain
   * Stateless - Can be seen as notifications for the user.
   */
  alarmTypes?: AlarmType[];
  /** Represents the different type of grids. */
  gridTypes?: GridType[];
  /** Type of node */
  nodeTypes?: NodeType[];
  /** Status of a Power Control Schedule. */
  scheduleStatus?: ScheduleStatus[];
  /** Types of aggregation when retrieving signal time series data. */
  aggregationTypes?: AggregationType[];
  /** If return the time series data as is (raw) or sample it by a given time length. */
  samplingIntervals?: SamplingInterval[];
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   * and may have signals that it requests input from (TODO Not completely true yet).
   */
  signalProviderTypes?: SignalProviderType[];
  /**
   * Modbus mode - "slave", "master"
   * Slave is only allowed to read.
   */
  modbusModes?: ModbusMode[];
  /** Modbus communication type - "RTU", "TCP" */
  modbusTypes?: ModbusType[];
  /**
   * Parity bit for Modbus RTU, "N"=None, "E"=Even, "O"=Odd
   *
   */
  parities?: Parity[];
  /**
   * Units like meter per second and such can be created SetUnitRequestModel.
   * They are then used to define what each signals unit is.
   */
  units?: UnitResponseModel[];
  /** This enumeration contains the different formats that data can be exported in. */
  exportFormats?: ExportFormat[];
  /** Status of an export request. */
  exportStatus?: ExportStatus[];
  /** Signal Function is used to give the EM a way to understand that a signal is to be used with a function. */
  signalFunctions?: SignalFunction[];
  /** Sets if value is r read or w write for holding registers and coils */
  readWrites?: ReadWrite[];
  /**
   * Signal Modbus type specifies the  Modbus register type.
   * Definitions taken from http://www.modbus.org/docs/Modbus_Application_Protocol_V1_1b3.pdf
   * The EM needs to know what register type to use.
   */
  signalModbusTypes?: SignalModbusType[];
  /**
   * Sets interpreted byte order as "abcd", "badc", "cdab" or "dcba".
   * Used for datatype real and int with size 2 (only "abcd" and "cdab" valid) or 4.
   */
  byteOrders?: ByteOrder[];
  /** Sets if to read the data as an "int" (integer) or "real"(float). */
  modbusDataTypes?: ModbusDataType[];
  /**
   * Used in templates to specify the tools to be created for a equipment.
   * Each tool require different signals to be added and other configurations done in the
   * backend system.
   */
  toolTypes?: ToolType[];
  /**
   * Connection Default Modbus Config Values.
   * These values are used when instantiating templates
   * that do not have all values set within the template.
   */
  connectionModbusConfigDefaults?: ConnectionDefaultModbusConfigResponseModel;
  /**
   * The building status is used to separate buildings in production
   * from newly create buildings and buildings in test.
   */
  buildingStatus?: BuildingStatus[];
  /**
   * This enumeration contains the different fill options that can be used when getting data from Influx.
   * For more information: https://docs.influxdata.com/influxdb/v1.7/query_language/data_exploration/#group-by-time-intervals-and-fill.
   */
  fillOption?: FillOption[];
  /** Decides what action the signal provider signal mapper should do. */
  mappingAction?: MappingAction[];
  /** If the signal is an input signal or an output signal. */
  signalDirection?: SignalDirection[];
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation?: GraphicalRepresentation[];
  /** Format of the timeseries. */
  dataFormat?: DataFormat[];
  /** Tags for the caching middle layer. */
  efCacheTag?: EfCacheTag[];
  /** Gets or sets the different types of the date comparisons. */
  dateComparisonType?: DateComparisonType[];
  /** Gets or sets the different comfort alarm types. */
  comfortAlarmType?: ComfortAlarmType[];
  /** Gets or sets the network interface type. */
  networkInterfaceType?: NetworkInterfaceType[];
  /** Gets or sets the different control types for the power delta control. */
  powerDeltaControlType?: PowerDeltaControlType[];
  /** Gets or sets the different types that an activity log can have. */
  activityLogType?: ActivityLogType[];
}

/** Status of a Power Control Schedule. */
export enum ScheduleStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Running = 'Running',
  Finished = 'Finished',
  Aborted = 'Aborted'
}

export interface UnitResponseModel {
  name?: string;
  /** @format guid */
  id: string;
  dataType?: string;
  unit?: string;
}

/** This enumeration contains the different formats that data can be exported in. */
export enum ExportFormat {
  Unknown = 'Unknown',
  Csv = 'Csv',
  Excel = 'Excel'
}

/** Status of an export request. */
export enum ExportStatus {
  Unknown = 'Unknown',
  Pending = 'Pending',
  Running = 'Running',
  Finished = 'Finished',
  Aborted = 'Aborted'
}

export interface LoBaBuildingResponseModel {
  /**
   * The id of the node.
   * @format guid
   */
  nodeId: string;
  /** The facility id of the building. */
  facilityId?: string;
  /** The name of the node. */
  nodeName?: string;
  /** The LoBa equipments of the building. */
  loBaEquipments?: LoBaEquipmentResponseModel[];
}

export interface LoBaEquipmentResponseModel {
  /**
   * The id of the provider.
   * @format guid
   */
  providerId: string;
  /**
   * The id of the equipment.
   * @format guid
   */
  equipmentId: string;
  /** The display name of the provider. */
  displayName?: string;
  /** LoBa equipment type */
  equipmentType: LoBaEquipmentType;
  /**
   * LoBa electricity grid energy from grid signal id
   * @format guid
   */
  energyElectricityFromGridSignalId?: string;
  /**
   * LoBa electricity grid energy to grid signal id
   * @format guid
   */
  energyElectricityToGridSignalId?: string;
  /**
   * LoBa battery/electricity grid/ PV panel energy electricity signal id
   * @format guid
   */
  energyElectricitySignalId?: string;
  /**
   * LoBa battery/electricity grid/ PV panel power electricity signal id
   * @format guid
   */
  powerElectricitySignalId?: string;
  /**
   * LoBa battery electricity energy from battery signal id
   * @format guid
   */
  energyElectricityFromBatterySignalId?: string;
  /**
   * LoBa battery electricity energy to battery signal id
   * @format guid
   */
  energyElectricityToBatterySignalId?: string;
  /**
   * LoBa battery state of charge signal id
   * @format guid
   */
  stateOfChargeSignalId?: string;
}

export enum LoBaEquipmentType {
  Unknown = 'Unknown',
  Battery = 'Battery',
  ElectricityGrid = 'ElectricityGrid',
  PVPanels = 'PVPanels'
}

export interface GetLoBaBuildingsByNodeIdRequestModel {
  /** The node ids to retrieve LoBa buildings for. */
  nodeIds: string[];
}

export interface GetLoBaBuildingsByFacilityIdRequestModel {
  /** The facility ids to retrieve LoBa buildings for. */
  facilityIds: string[];
}

export interface SunriseSunsetResponseModel {
  /** @format date-time */
  sunrise: string;
  /** @format date-time */
  sunset: string;
  /** @format double */
  suntransit: number;
}

export interface GeographicalPointResponseModel {
  /** @format guid */
  id: string;
  name?: string;
  /** @format double */
  longitude: number;
  /** @format double */
  latitude: number;
  nodeIds?: string[];
}

export interface AddGeographicalPointRequestModel {
  /** @format guid */
  id?: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  /** @format double */
  longitude: number;
  /** @format double */
  latitude: number;
  nodeIds: string[];
}

export interface DeleteGeographicalPointsRequestModel {
  ids: string[];
}

export interface NodeWithAllParentsResponseModel {
  /** Gets the node matching the identifier that you searched for. */
  node?: SimpleNodeResponseModel;
  /** Gets the parent nodes for the specific node. */
  parentNodes?: SimpleNodeResponseModel[];
}

export interface GetNodesWithAllParentsRequestModel {
  nodeIds?: string[];
}

export interface PowerControlProviderResponseModel {
  /** @format guid */
  signalProviderId: string;
  /** @format guid */
  nodeId: string;
  powerControlType: PowerControlType;
  algorithmType: PowerControlAlgorithmType;
  /** @format guid */
  deviceId: string;
}

export interface AddOrUpdatePowerControlProviderRequestModel {
  /** @format guid */
  signalProviderId?: string;
  /** @format guid */
  nodeId: string;
  powerControlType: PowerControlType;
  algorithmType: PowerControlAlgorithmType;
  /** @format guid */
  deviceId: string;
}

export interface PowerControlResponseModel {
  /** @format guid */
  signalProviderId: string;
  powerControlType: PowerControlType;
  algorithmType: PowerControlAlgorithmType;
  enableSignal?: SignalValuePairResponseModel;
  /** @format guid */
  nodeId: string;
  signals?: SignalValuePairResponseModel[];
  /** @format guid */
  deviceId: string;
}

export interface SignalValuePairResponseModel {
  /** @format guid */
  signalId: string;
  /** @format double */
  value: number;
  /** @format guid */
  signalTypeId: string;
  /**
   * Gets or sets the name. This model is used in many places and this property doesn't
   * always contain a value. So look at the data before using it!
   */
  name?: string;
}

export interface GetPowerControlByNodeV2ResponseModel {
  powerControls?: SignalGroupLiteResponseModel[];
}

export interface GetPowerControlByNodeV2RequestModel {
  nodeIds?: string[];
}

export interface GetPowerControlByNodeRequestModel {
  /** @format guid */
  nodeId: string;
  powerControlType: PowerControlType;
}

export interface AddOrUpdatePowerControlScheduleRequestModel {
  /** @format guid */
  id: string;
  /** @format guid */
  signalProviderId: string;
  /**
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * @format date-time
   * @minLength 1
   */
  endDate: string;
  /** @format guid */
  sourceTypeId: string;
  /** @format double */
  globalAmplitude: number;
  desiredEmergency: boolean;
  desiredAcuteEmergency: boolean;
}

export interface ScheduleCollectionResponseModel {
  schedules?: ScheduleResponseModel[];
  signals?: SignalResponseModel[];
}

export interface ScheduleResponseModel {
  /** @format guid */
  scheduleId: string;
  /** @format guid */
  signalGroupId: string;
  type?: string;
  /** @format date-time */
  startDate: string;
  /** @format date-time */
  endDate: string;
  /** @format guid */
  locationId: string;
  status?: string;
  user?: string;
  signals?: SignalValueResponseModel[];
  history?: ScheduleHistoryResponseModel[];
  /**
   * Gets or sets the total number of buildings that should receive the schedule.
   * @format int32
   */
  totalNumberOfBuildings: number;
  /**
   * Gets or sets the number of succeeded buildings. This is the number of buildings
   * where the EM has received the schedule. If a building has more than one EM then
   * it will count when one EM has acknowledged.
   * @format int32
   */
  numberOfSucceededBuildings: number;
  /**
   * Total theoretical steerable power
   * @format double
   */
  totalPower?: number;
  /**
   * Actual activated power. This value was calculated as NumberOfSucceededBuildings/ TotalNumberOfBuildings * TotalPower
   * @format double
   */
  actualPower?: number;
  /**
   * Gets or sets schedule source type id
   * @format guid
   */
  sourceTypeId: string;
}

export interface SignalValueResponseModel {
  /** @format guid */
  signalId: string;
  name?: string;
  /** @format double */
  value?: number;
  /** @format guid */
  signalTypeId: string;
}

export interface ScheduleHistoryResponseModel {
  status?: string;
  /** @format date-time */
  date: string;
  user?: string;
  comment?: string;
}

export interface DeletePowerControlScheduleRequestModel {
  /** @format guid */
  scheduleId: string;
}

export interface AbortPowerControlScheduleRequestModel {
  /** @format guid */
  scheduleId: string;
}

export interface AddLimitAndAmplitudeValuesRequestModel {
  /** @format guid */
  signalProviderId: string;
  /** @format double */
  limitValue: number;
  /** @format double */
  amplitudeValue: number;
}

export interface AddLimitAndAmplitudeValuesWithAuditRequestModel {
  /** @format guid */
  signalProviderId: string;
  /** @format double */
  limitValue: number;
  /** @format double */
  amplitudeValue: number;
  /** @format double */
  oldLimitValue?: number;
  /** @format double */
  oldAmplitudeValue?: number;
  message?: string;
}

export interface LinearOptimisationProviderResponseModel {
  /** @format guid */
  signalProviderId: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  deviceId: string;
  numberOfPoints: number;
}

export interface AddOrUpdateLinearOptimisationRequestModel {
  /** @format guid */
  signalProviderId: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  deviceId: string;
  numberOfPoints: number;
}

export interface LinearOptimisationResponseModel {
  /** @format guid */
  signalProviderId: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  deviceId: string;
  enableSignal?: SignalValuePairResponseModel;
  remoteOptimisationPoints?: RemoteOptimisationPointResponseModel[];
}

export interface RemoteOptimisationPointResponseModel {
  signalX?: SignalValuePairResponseModel;
  signalY?: SignalValuePairResponseModel;
}

export interface LinearOptimisationV2ResponseModel {
  linearOptimisations?: SignalGroupLiteResponseModel[];
}

export interface GetLinearOptimisationByNodeRequestModel {
  nodesIds?: string[];
}

export interface SetLinearOptimisationSignalsRequestModel {
  /** @format guid */
  signalProviderId: string;
  signalValues?: SignalValuePairRequestModel[];
}

export interface SignalValuePairRequestModel {
  /** @format guid */
  signalId: string;
  /** @format double */
  value: number;
}

export interface AddOrUpdateMasterScheduleRequestModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the node identifier of the schedule.
   * @format guid
   * @minLength 1
   */
  nodeId: string;
  /**
   * Gets or sets the schedule type id.
   * @format guid
   * @minLength 1
   */
  scheduleTypeId: string;
  /**
   * Gets or sets the source type id.
   * @format guid
   * @minLength 1
   */
  sourceTypeId: string;
  /**
   * Gets or sets the start date of the schedule.
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * Gets or sets the end date of the schedule.
   * @format date-time
   * @minLength 1
   */
  endDate: string;
  /** Gets or sets the signal time series of the schedule. */
  signalTimeSeries?: SignalTimeSerieRequestModel[];
}

export interface SignalTimeSerieRequestModel {
  /**
   * Gets the signal type identifier of the signal that the series is related to.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
  /** Gets the signal values. */
  signalValues?: SignalValueRequestModel[];
}

export interface SignalValueRequestModel {
  /**
   * Gets the time when the flank occurs.
   * @format date-time
   * @minLength 1
   */
  time: string;
  /**
   * Gets the value.
   * @format double
   */
  value: number;
}

export interface ScheduleResponseModel2 {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the schedule level.
   * @format int32
   */
  level: number;
  /** Gets or sets the master schedule. */
  masterSchedule?: MasterScheduleResponseModel;
  /**
   * Gets or sets the node id.
   * @format guid
   */
  nodeId: string;
  /** Gets or sets the schedule status. */
  status: ScheduleStatus;
}

export interface MasterScheduleResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /**
   * Gets or sets the node id.
   * @format guid
   */
  nodeId: string;
  /** Gets or sets the schedule status. */
  status: ScheduleStatus;
  /**
   * Gets or sets the schedule type id.
   * @format guid
   */
  scheduleTypeId: string;
  /**
   * Gets or sets the source type id.
   * @format guid
   */
  sourceTypeId: string;
  /**
   * Gets or sets the from date.
   * @format date-time
   */
  fromDate: string;
  /**
   * Gets or sets the to date.
   * @format date-time
   */
  toDate: string;
  /** The signal time series */
  signalTimeSeries?: SignalTimeSerieResponseModel[];
}

export interface SignalTimeSerieResponseModel {
  /** @format guid */
  signalTypeId: string;
  signalValues?: SignalValueResponseModel2[];
}

export interface SignalValueResponseModel2 {
  /** @format date-time */
  time: string;
  /** @format double */
  value: number;
}

export interface GetSchedulesRequestModel {
  /**
   * The end date to retrieve schedules for.
   * @format date-time
   * @minLength 1
   */
  fromDate: string;
  /**
   * The end date to retrieve schedules for.
   * @format date-time
   * @minLength 1
   */
  toDate: string;
  /** The node ids to retrieve schedules for. */
  nodeIds?: string[];
  /**
   * The ids of the schedule types to filter on.
   * @format guid
   */
  scheduleTypeId?: string;
  /** The ids of the source types to filter on. */
  sourceTypeIds?: string[];
}

export interface AbortMasterSchedulesRequestModel {
  /** The ids of the master schedules to abort. */
  id?: string[];
}

export interface SignalProviderResponseModel {
  nodeIds?: string[];
  displayName?: string;
  /** @format guid */
  id: string;
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   */
  signalProviderType: SignalProviderType;
}

export interface AddOrUpdateSignalProviderRequestModel {
  /** @format guid */
  signalProviderId: string;
  /** @minLength 1 */
  displayName: string;
  nodeIds: string[];
  signals?: AddOrUpdateSignalRequestModel[];
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   */
  signalProviderType: SignalProviderType;
}

/** Allows for adding of updating a signal provider signal. */
export interface AddOrUpdateSignalRequestModel {
  /** @format guid */
  id: string;
  /**
   * @minLength 0
   * @maxLength 100
   */
  name: string;
  isWritable: boolean;
  description?: string;
  /** Format of the timeseries. Discrete or Continuous. */
  dataFormat: DataFormat;
  /** If the signal is an input signal or an output signal. */
  signalDirection: SignalDirection;
  /** How the data is suggested to be showed in the graph. */
  graphicalRepresentation: GraphicalRepresentation;
  /** Gets or sets the signal category identifiers. It is ok to leave this list empty. */
  signalCategoryIds: string[];
  /**
   * Gets or sets the signal type identifier.
   * @format guid
   * @minLength 1
   */
  signalTypeId: string;
}

export interface DeleteSignalProvidersRequestModel {
  signalProviderIds: string[];
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   */
  signalProviderType: SignalProviderType;
}

export interface SignalProviderTemplateResponseModel {
  /** @format guid */
  id: string;
  displayName?: string;
  /**
   * Signal Provider Type defines the owner of the Signal Provider and its signal.
   * The service that owns the Signal Provider Type also is responsible for writing values
   */
  signalProviderType: SignalProviderType;
  signalTemplates?: SignalTemplateResponseModel[];
}

export interface SignalTemplateResponseModel {
  /** @format guid */
  id: string;
  name?: string;
  isWritable: boolean;
  description?: string;
  /** @format guid */
  signalProviderTemplateId: string;
  signalCategoryIds?: string[];
  /** @format guid */
  signalTypeId: string;
}

export interface SetUnitRequestModel {
  /** @format guid */
  id: string;
  /** @minLength 1 */
  name: string;
  /** @minLength 1 */
  symbol: string;
  dataType: UnitDataType;
}

export enum UnitDataType {
  Unknown = 'Unknown',
  Boolean = 'Boolean',
  Float = 'Float'
}

/**
 * Adds signal values to backend system.
 * Also if signal values are for a Desired signal it will be routed to the appropriate provider.
 */
export interface AddSignalValuesRequestModel {
  /**
   * Signal Provider Id for the signal sent.
   * @format guid
   * @minLength 1
   */
  providerId: string;
  /**
   * Signal to add the values for.
   * @format guid
   * @minLength 1
   */
  signalId: string;
  /** The value/timestamp timeseries to add for that signal. */
  values: SignalValueRequestModel2[];
  /**
   * Only used if the signal will be sent to a Device.
   * If no one is give the default in the IoT Hub will be used.
   */
  timeToLiveInSeconds?: number;
}

export interface SignalValueRequestModel2 {
  /**
   * @format date-time
   * @minLength 1
   */
  time: string;
  /** @format double */
  value: number;
}

export interface AddSignalValueWithAuditRequestModel {
  /**
   * @format guid
   * @minLength 1
   */
  providerId: string;
  /**
   * @format guid
   * @minLength 1
   */
  signalId: string;
  /** @format double */
  value: number;
  /** @format double */
  oldValue?: number;
  message?: string;
}

export interface SignalExportResponseModel {
  /**
   * Gets or sets the identifier.
   * @format guid
   */
  id: string;
  /** Gets or sets the signal ids and the display names for them. */
  signalExportMappings?: SignalExportMappingResponseModel[];
  /**
   * Gets or sets the start date for the export.
   * @format date-time
   */
  startDate: string;
  /**
   * Gets or sets the end date for the export.
   * @format date-time
   */
  endDate: string;
  /** Gets or sets the sampling interval. */
  samplingInterval: SamplingInterval;
  /** Gets or sets the aggregation. */
  aggregationType: AggregationType;
  /** Gets or sets the export format. */
  exportFormat: ExportFormat;
  /** Gets or sets the time zone. */
  timeZone?: string;
  /** Gets or sets the export status. */
  exportStatus: ExportStatus;
  /**
   * Gets or sets the export status timestamp.
   * @format date-time
   */
  exportStatusTimestamp: string;
}

export interface SignalExportMappingResponseModel {
  /**
   * Gets or sets the signal identifier.
   * @format guid
   */
  signalId: string;
  /** Gets or sets the display name to use for the signal in the export. */
  displayName?: string;
}

export interface AddSignalExportRequestModel {
  /** Gets or sets the signal ids and the display names for them. */
  signalExportMappings: SignalExportMappingRequestModel[];
  /**
   * Gets or sets the start date for the export.
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * Gets or sets the end date for the export.
   * @format date-time
   * @minLength 1
   */
  endDate: string;
  /** Gets or sets the sampling interval. */
  samplingInterval: SamplingInterval;
  /** Gets or sets the aggregation type. */
  aggregationType: AggregationType;
  /** Gets or sets the export format. */
  exportFormat: ExportFormat;
  /** Gets or sets the time zone. */
  timeZone?: string;
}

export interface SignalExportMappingRequestModel {
  /**
   * Gets or sets the signal identifier.
   * @format guid
   */
  signalId: string;
  /**
   * Gets or sets the display name to use for the signal in the export.
   * @minLength 1
   */
  displayName: string;
}

export interface DownloadSignalExportResponseModel {
  /**
   * Gets or sets the download URL. The URL leads to BLOB storage and has a SAS token already attached to it.
   * Use this URL to make a redirect.
   */
  downloadUrl?: string;
}

export interface DeleteSignalsRequestModel {
  signalIds?: string[];
}

export interface EquipmentSignalViewResponseModel {
  /**
   * Gets or sets the equipment identifier.
   * @format guid
   */
  equipmentId: string;
  /** Gets or sets the map. This is base64 encoded string containing the latest revision of the SVG. */
  map?: string;
  /** Gets or sets the signals. */
  signals?: SignalInfoResponseModel[];
}

export interface SignalInfoResponseModel {
  /** @format guid */
  signalId: string;
  /** @format guid */
  energyManagerDeviceId?: string;
  /** @format float */
  step?: number;
  isWritable: boolean;
}

export interface GetSignalViewsRequestModel {
  equipmentIds: string[];
}

export interface NodeSignalViewResponseModel {
  /**
   * Gets or sets the node identifier.
   * @format guid
   */
  nodeId: string;
  /** Gets or sets the map. This is base64 encoded string containing the latest revision of the SVG. */
  map?: string;
  /** Gets or sets the equipment signals. */
  equipmentSignals?: SignalInfoResponseModel[];
}

export interface GetNodeSignalViewsRequestModel {
  nodeIds: string[];
}

export interface TemperatureImpactProviderResponseModel {
  /** @format guid */
  providerId: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  powerControlGlobalAmplitudeDesiredSignalId: string;
  /** @format guid */
  meteorologyTSignalId: string;
}

export interface GetTemperatureImpactProvidersByNodeIdRequestModel {
  /** Gets or sets the node ids. The nodes should be buildings. */
  nodeIds: string[];
}

export interface GetTemperatureImpactProvidersByProviderIdRequestModel {
  /** Gets or sets the provider ids. */
  providerIds: string[];
}

export interface TemperatureImpactPreviewResponseModel {
  /**
   * Gets or sets the original node identifier from the request.
   * @format guid
   */
  requestNodeId: string;
  /** Gets or sets the building temperature impacts. */
  buildingTemperatureImpacts?: BuildingTemperatureImpactPreviewResponseModel[];
}

export interface BuildingTemperatureImpactPreviewResponseModel {
  /**
   * Gets or sets the node identifier of the building.
   * @format guid
   */
  nodeId: string;
  /** Gets or sets the name of the node. */
  nodeName?: string;
  /** Gets or sets a value indicating whether meteorology data is missing so the impact can't be calculated for this building. */
  meteorologyDataMissing: boolean;
  /**
   * Gets or sets the maximum temperature impact in Celsius. This should be inside +-0.5 degrees.
   * @format double
   */
  maximumTemperatureImpact?: number;
  /** Gets or sets the temperature impact signal values. */
  temperatureImpactSignalValues?: SignalValueResponseModel3[];
}

export interface SignalValueResponseModel3 {
  /**
   * Gets or sets the time.
   * @format date-time
   */
  time: string;
  /**
   * Gets or sets the value.
   * @format double
   */
  value: number;
}

export interface GetTemperatureImpactPreviewRequestModel {
  /**
   * Gets or sets the power control schedule identifier.
   * This is used when the user is editing an existing schedule.
   * @format guid
   */
  powerControlScheduleId?: string;
  /**
   * Gets or sets the node identifier. This can be a site or a building.
   * @format guid
   */
  nodeId: string;
  /**
   * Gets or sets the start date of the schedule.
   * @format date-time
   * @minLength 1
   */
  startDate: string;
  /**
   * Gets or sets the end date of the schedule.
   * @format date-time
   * @minLength 1
   */
  endDate: string;
  /**
   * Gets or sets the global amplitude of the schedule.
   * @format double
   */
  globalAmplitude: number;
}

export interface AddOrUpdateNodesRequestModel {
  nodes?: NodeV2ResponseModel[];
}

export interface NodeV2ResponseModel {
  description?: string;
  /** @format guid */
  nodeId: string;
  /** @format guid */
  parentId?: string;
  /** @minLength 1 */
  path: string;
  /** @minLength 1 */
  name: string;
  /** @format double */
  latitude?: number;
  /** @format double */
  longitude?: number;
  street?: string;
  nodeTraitIds?: string[];
}

export interface NodePropertyValueSuggestionsResponseModel {
  /** Suggestions for possible node property values. */
  suggestions?: string[];
  hasMore: boolean;
}

export interface NodeSearchResultsResponseModel {
  nodes?: NodeV2ResponseModel[];
  parents?: NodeParentInformationResponseModel[];
  propertiesAndValues?: NodePropertyValueResponseModel[];
  continuationToken?: string;
}

export interface NodeParentInformationResponseModel {
  /** @format guid */
  nodeId: string;
  /** @format guid */
  parentId?: string;
  name?: string;
}

export interface NodePropertyValueResponseModel {
  /** @format guid */
  id: string;
  /** @format guid */
  nodePropertyId: string;
  /** @format guid */
  nodeId: string;
  value?: string;
}

export interface SearchNodesRequestModel {
  nodeTraitIds?: string[];
  nodePropertyIds?: string[];
  searchPhrase?: string;
  propertySearchPhrase?: string;
  continuationToken?: string;
  /**
   * Total number of records in a full page.
   * @format int32
   * @min 1
   * @max 10000
   */
  pageSize: number;
}

export interface GetNodesByIdResponseModelV2 {
  nodes?: NodeV2ResponseModel[];
  parents?: NodeParentInformationResponseModel[];
}

export interface NodeTraitResponseModel {
  /** @format guid */
  id: string;
  name?: string;
  isLocked: boolean;
  isGlobal: boolean;
  propertyIds?: string[];
}

export interface NodePropertyResponseModel {
  /** @format guid */
  id: string;
  name?: string;
  description?: string;
}

export interface AddOrUpdateNodePropertiesRequestModel {
  properties?: NodePropertyResponseModel[];
}

export interface AddOrUpdateNodeTraitsRequestModel {
  nodeTraits?: NodeTraitResponseModel[];
}

export interface NodePropertyAndValueResponseModel {
  /** @format guid */
  nodeId: string;
  /** @format guid */
  propertyId: string;
  /** @format guid */
  valueId: string;
  name?: string;
  description?: string;
  value?: string;
}

export interface AddOrUpdateNodePropertyValuesRequestModel {
  /** @format guid */
  nodeId: string;
  propertyValues?: NodePropertyValueResponseModel[];
}

export interface ActivityLogGetParams {
  LocationIds?: string[] | null;
  SignalIds?: string[] | null;
  ActivityLogTypes?: ActivityLogType[] | null;
  /** @format int32 */
  Page?: number;
  /** @format int32 */
  PageSize?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SearchPhrase?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SortColumn?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SortOrder?: string | null;
}

export interface ActivityLogDeleteParams {
  /** @format guid */
  Id?: string;
}

export interface ActivityLogGetRecursiveParams {
  /** @format guid */
  LocationId?: string;
  Grid?:
    | 'Unknown'
    | 'Heating'
    | 'Cooling'
    | 'Electricity'
    | 'Ectogrid'
    | 'Generic'
    | 'Inherited';
  ActivityLogTypes?: ActivityLogType[] | null;
  /** @format int32 */
  Page?: number;
  /** @format int32 */
  PageSize?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SearchPhrase?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SortColumn?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SortOrder?: string | null;
}

export interface AdminAlarmsGetAlarmSignalsParams {
  EquipmentIds?: string[] | null;
}

export interface AdminBuildingsDeleteEquipmentTemplateGroupsParams {
  EquipmentTemplateGroupIds?: string[] | null;
}

export interface AdminDevicesGetSuggestedSlaveIdParams {
  /**
   * Equipment Id representing an EM so that we can find the device the front end is to use.
   * This is the same as ConnectionId.
   * @format guid
   */
  EquipmentId?: string;
}

export interface AdminDevicesGetModbusDeviceConfigFileParams {
  /**
   * Represent the IoT device Name
   * @minLength 0
   * @maxLength 255
   */
  DeviceName?: string | null;
  /**
   * Represents the EM EquipmentId which is the same as the ConnectionId
   * @format guid
   */
  EquipmentId?: string | null;
  /**
   * Represents the Id of the Device that owns the config files.
   * @format guid
   */
  DeviceId?: string | null;
  /**
   * If false then a preview version of the work in progress will be returned.
   * If true then the version that has been deployed to the device will be returned.
   */
  Deployed?: boolean | null;
}

export interface AdminDevicesGetModbusToolsConfigFileParams {
  /**
   * Represent the IoT device Name
   * @minLength 0
   * @maxLength 255
   */
  DeviceName?: string | null;
  /**
   * Represents the EM EquipmentId which is the same as the ConnectionId
   * @format guid
   */
  EquipmentId?: string | null;
  /**
   * Represents the Id of the Device that owns the config files.
   * @format guid
   */
  DeviceId?: string | null;
  /**
   * If false then a preview version of the work in progress will be returned.
   * If true then the version that has been deployed to the device will be returned.
   */
  Deployed?: boolean | null;
}

export interface AdminDevicesGetDeviceEquipmentInfoParams {
  /** @format guid */
  EquipmentId?: string;
}

export interface AdminDevicesGetDevicesParams {
  /**
   * Node Id to start search from.
   * If specified the search will be limited to devices connected to it or its
   * children.
   * @format guid
   */
  NodeId?: string | null;
  /**
   * GridType restricts where in the node tree to search.
   * Only nodes that includes the requested grid type will be a part of the search.
   */
  Grid?:
    | 'Unknown'
    | 'Heating'
    | 'Cooling'
    | 'Electricity'
    | 'Ectogrid'
    | 'Generic'
    | 'Inherited';
  /**
   * Page number to return from the results.
   * @format int32
   */
  Page?: number;
  /**
   * Page size each page should have
   * @format int32
   * @min 1
   * @max 100
   */
  PageSize?: number;
  /**
   * Search phase that can be used to limit the search by
   * Name and such.
   * @minLength 0
   * @maxLength 255
   */
  SearchPhrase?: string | null;
  /** What column to use when sorting the response. */
  SortColumn?: string | null;
}

export interface AdminDevicesGetDeviceStatusParams {
  /** @format guid */
  EquipmentId?: string;
}

export interface AdminDevicesGetDeviceModulesParams {
  /** Gets or sets the device ids to retrieve the module information from. */
  DeviceIds?: string[] | null;
}

export interface AdminDevicesGetEquipmentsListConfigFileParams {
  /**
   * Represent the IoT device Name
   * @minLength 0
   * @maxLength 255
   */
  DeviceName?: string | null;
  /**
   * Represents the EM EquipmentId which is the same as the ConnectionId
   * @format guid
   */
  EquipmentId?: string | null;
  /**
   * Represents the Id of the Device that owns the config files.
   * @format guid
   */
  DeviceId?: string | null;
  /**
   * If false then a preview version of the work in progress will be returned.
   * If true then the version that has been deployed to the device will be returned.
   */
  Deployed?: boolean | null;
}

export interface AdminDevicesGetModbusSignalsConfigParams {
  /**
   * Represent the IoT device Name
   * @minLength 0
   * @maxLength 255
   */
  DeviceName?: string | null;
  /**
   * Represents the EM EquipmentId which is the same as the ConnectionId
   * @format guid
   */
  EquipmentId?: string | null;
  /**
   * Represents the Id of the Device that owns the config files.
   * @format guid
   */
  DeviceId?: string | null;
  /**
   * If false then a preview version of the work in progress will be returned.
   * If true then the version that has been deployed to the device will be returned.
   */
  Deployed?: boolean | null;
}

export interface AdminDevicesGetConnectionsParams {
  /** At least one connection Id must be supplied. */
  ConnectionIds?: string[] | null;
}

export interface AdminEquipmentsGetEquipmentSignalsParams {
  /** Equipment Ids to search Signals for. */
  EquipmentIds?: string[] | null;
}

export interface AdminEquipmentsGetEquipmentSignalProviderTemplatesParams {
  /**
   * If an Equipment Type Id is specified, only templates for that type is returned.
   * @format guid
   */
  EquipmentTypeId?: string | null;
}

export interface AdminEquipmentsGetToolSignalsParams {
  NodeIds?: string[] | null;
}

export interface AdminIoTDevicesGetIoTDeviceByIdParams {
  Ids?: string[] | null;
}

export interface AdminIoTDevicesDeleteIoTDevicesByIdParams {
  Ids?: string[] | null;
}

export interface AdminIoTDevicesGetIoTDeviceByDeviceIdParams {
  DeviceIds?: string[] | null;
}

export interface AdminIoTDevicesGetIoTDeviceByDeviceNameParams {
  DeviceNames?: string[] | null;
}

export interface AdminIoTDevicesGetIoTDevicesSearchParams {
  /** Gets or sets the search phrase. */
  SearchPhrase?: string | null;
  /**
   * Gets or sets the sort column. Supported values are "heartbeat" and "deviceName".
   * If something else is sent then "deviceName" will be used.
   */
  SortColumn?: string | null;
  /**
   * Gets or sets the type of the query. This will let you search for IoT devices that
   * aren't connected to a device.
   */
  QueryType?: 'Unknown' | 'Paired' | 'Unpaired' | 'All';
  /**
   * Gets or sets the type of the status query.
   * This will let you search for devices that are online or offline.
   */
  StatusQueryType?: 'Unknown' | 'Online' | 'Offline' | 'All';
  FilteredTags?: string[] | null;
  /**
   * Gets or sets the size of the page.
   * @format int32
   */
  PageSize?: number;
  /**
   * Gets or sets the page. The value is 0 based!
   * @format int32
   */
  Page?: number;
}

export interface AdminIoTDevicesGetIoTDeviceViewByDeviceIdParams {
  DeviceIds?: string[] | null;
}

export interface AdminIoTDevicesGetIoTDeviceViewByIoTDeviceIdParams {
  Ids?: string[] | null;
}

export interface AdminIoTDevicesGetDeviceTwinSettingsParams {
  IoTDeviceIds?: string[] | null;
}

export interface AdminJobsTriggerStructureExportJobParams {
  /** if set to true the method will not return until the job completes; otherwise the job will be scheduled to run in the background. */
  waitForCompletion?: boolean;
}

export interface AdminMeteorologyDownloadMeteomaticsHistoricalDataParams {
  /**
   * Id of a meteorology point to download historical data
   * @format guid
   */
  geographicalPointId?: string;
  /**
   * Start year of the interval
   * @format int32
   * @default 2015
   */
  startYear?: number;
}

export interface AdminNodesGetNodesParams {
  /** @format guid */
  NodeId?: string | null;
  /** @format guid */
  ParentId?: string | null;
  NodeType?: 'Unknown' | 'Site' | 'Building' | 'Equipment';
  Grids?: GridType[] | null;
}

export interface AdminNodesGetNodesByDistrictHeatingFacilityIdParams {
  DistrictHeatingFacilityIds?: string[] | null;
}

export interface AdminNodesGetNodesByBuildingStatusParams {
  BuildingStatuses?: BuildingStatus[] | null;
}

export interface AdminNotificationsGetAlarmSignalTypeIdsForNodesParams {
  NodeIds?: string[] | null;
}

export interface AdminNotificationsGetAlarmNotificationsByIdParams {
  Ids?: string[] | null;
}

export interface AdminNotificationsGetAlarmNotificationsByNodeIdsParams {
  NodeIds?: string[] | null;
}

export interface AlarmsGetAlarmListParams {
  /** @format guid */
  NodeId?: string | null;
  Grid?:
    | 'Unknown'
    | 'Heating'
    | 'Cooling'
    | 'Electricity'
    | 'Ectogrid'
    | 'Generic'
    | 'Inherited';
  /** @format int32 */
  Page?: number;
  /**
   * @format int32
   * @min 0
   * @max 100
   */
  PageSize?: number;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SearchPhrase?: string | null;
  SortColumn?: string | null;
  SortOrder?: string | null;
  /** @format int32 */
  Severity?: number | null;
  /** Gets or sets the building statuses. Leave this list empty if no filter on building status is wanted. */
  BuildingStatuses?: BuildingStatus[] | null;
}

export interface AlarmsGetAlarmHistoryParams {
  /**
   * Gets or sets the node identifier. This is used when the user has selected a specific node in the tree.
   * @format guid
   */
  NodeId?: string | null;
  /** Gets or sets the grid. */
  Grid?:
    | 'Unknown'
    | 'Heating'
    | 'Cooling'
    | 'Electricity'
    | 'Ectogrid'
    | 'Generic'
    | 'Inherited';
  /** Gets or sets the severities. Leave this list empty if no filter on severity is wanted. */
  Severities?: number[] | null;
  /** Gets or sets the is active filter. Pass null if no filter is wanted. */
  IsActive?: boolean | null;
  /** Gets or sets the is acknowledged. Pass null if no filter is wanted. */
  IsAcknowledged?: boolean | null;
  /** Gets or sets the building statuses. Leave this list empty if no filter on building status is wanted. */
  BuildingStatuses?: BuildingStatus[] | null;
  /**
   * Gets or sets from date. Pass null if no filter is wanted.
   * @format date-time
   */
  FromDate?: string | null;
  /**
   * Gets or sets the to date. Pass null if no filter is wanted.
   * @format date-time
   */
  ToDate?: string | null;
  Page?: number;
  PageSize?: number;
  /** @format guid */
  SignalId?: string | null;
  /**
   * @minLength 0
   * @maxLength 255
   */
  SearchPhrase?: string | null;
  SortColumn?: string | null;
  SortOrder?: string | null;
}

export interface DisaIntegrationGetDisaIntegrationProviderParams {
  FacilityIds?: string[] | null;
}

export interface EIoTSignalsGetSignalValuesParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  /**
   * @format int32
   * @min 10
   * @max 5000
   */
  Points?: number;
}

export interface EIoTSignalsGetSignalValuesByTimeRangeParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  SamplingInterval?:
    | 'Unknown'
    | 'Raw'
    | 'Minute'
    | 'Hour'
    | 'Day'
    | 'Week'
    | 'Month'
    | 'MinutesFive'
    | 'MinutesFifteen';
  Aggregation?:
    | 'Unknown'
    | 'None'
    | 'Mean'
    | 'Max'
    | 'Min'
    | 'First'
    | 'Last'
    | 'Count'
    | 'Sum'
    | 'Median'
    | 'Mode'
    | 'NoneWithTags';
  /** Gets a value indicating whether StartDate and EndDate are inclusive. */
  IsInclusive?: boolean;
}

export interface EIoTSignalsGetSignalValuesByTimeIntervalParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  Date?: string;
  /**
   * @format int32
   * @min 0
   * @max 168
   */
  HoursBack?: number;
  /**
   * @format int32
   * @min 0
   * @max 168
   */
  HoursForward?: number;
  SamplingInterval?:
    | 'Unknown'
    | 'Raw'
    | 'Minute'
    | 'Hour'
    | 'Day'
    | 'Week'
    | 'Month'
    | 'MinutesFive'
    | 'MinutesFifteen';
  Aggregation?:
    | 'Unknown'
    | 'None'
    | 'Mean'
    | 'Max'
    | 'Min'
    | 'First'
    | 'Last'
    | 'Count'
    | 'Sum'
    | 'Median'
    | 'Mode'
    | 'NoneWithTags';
}

export interface MeteorologyGetSunInfoForDateParams {
  /** @format double */
  Longitude?: number;
  /** @format double */
  Latitude?: number;
  /** @format date-time */
  StartDateUtc?: string;
  /** @format date-time */
  EndDateUtc?: string;
}

export interface NodesGetNodesParams {
  /** @format guid */
  ParentId?: string | null;
  NodeIds?: string[] | null;
  NodeType?: 'Unknown' | 'Site' | 'Building' | 'Equipment';
  Grid?:
    | 'Unknown'
    | 'Heating'
    | 'Cooling'
    | 'Electricity'
    | 'Ectogrid'
    | 'Generic'
    | 'Inherited';
}

export interface NodesGetNodesByDistrictHeatingFacilityIdParams {
  DistrictHeatingFacilityIds?: string[] | null;
}

export interface NodesGetNodesByBuildingStatusParams {
  BuildingStatuses?: BuildingStatus[] | null;
}

export interface PowerControlGetPowerControlByProviderIdParams {
  /** Gets or sets the provider identifiers. If no ProviderIds are passed then an empty result is returned. */
  ProviderIds?: string[] | null;
}

export interface PowerControlGetPowerControlByNodeParams {
  requestModel?: GetPowerControlByNodeRequestModel[] | null;
}

export interface PowerControlGetPowerControlScheduleParams {
  NodeIds?: string[] | null;
  PowerControlType?: 'Unknown' | 'Heating' | 'Cooling';
  /** @format int32 */
  NumberOfHistoricalSchedules?: number;
}

export interface RemoteOptimisationGetLinearOptimisationByProviderParams {
  ProviderIds?: string[] | null;
}

export interface RemoteOptimisationGetLinearOptimisationByNodeParams {
  NodesIds?: string[] | null;
}

export interface SignalsGetSignalProvidersParams {
  SignalProviderTypes?: SignalProviderType[] | null;
}

export interface SignalsGetSignalProviderTemplatesParams {
  Ids?: string[] | null;
  SignalProviderType?:
    | 'Unknown'
    | 'SteerablePower'
    | 'PowerControl'
    | 'LinearOptimisation'
    | 'Alarm'
    | 'Meteorology'
    | 'Equipment'
    | 'HeatingForecast'
    | 'CoolingForecast'
    | 'COPCoolingForecast'
    | 'COPHeatingForecast'
    | 'ReversibleHeatPumpCOP'
    | 'ReversibleHeatPumpScheduler'
    | 'AccumulatorTankEnergyModel'
    | 'EctogridSetpointHeating'
    | 'EctogridSetpointCooling'
    | 'EctogridSetpointModel'
    | 'EctogridControlBoundary'
    | 'DisaIntegration'
    | 'TemperatureImpact'
    | 'ComfortHeating'
    | 'EcoguardIntegration'
    | 'ReversibleHeatPumpSchedulerConfig'
    | 'ReversibleHeatPumpControl'
    | 'OptimaControlSignals'
    | 'ElvacoIntegration'
    | 'PowerDeltaControl'
    | 'ElectricityPeakGuardControlLoop'
    | 'ElectricityPeakGuardCooling'
    | 'ElectricityPeakGuardHeating'
    | 'EctogridEnergyUsage'
    | 'EctogridEnergyElectricityUsage'
    | 'EctogridEnergyHeatingUsage'
    | 'EctogridEnergyUsageHeating'
    | 'EctogridEnergyUsageCooling'
    | 'EctogridEnergyDelivery'
    | 'EctogridEnergyHeatingDelivery'
    | 'EctogridEnergyCoolingDelivery'
    | 'EctogridEctoCOP'
    | 'EctogridEctoCOPHeating'
    | 'EctogridEctoCOPCooling'
    | 'EctogridEnergyActiveBalancing'
    | 'EctogridEnergyHeatingActiveBalancing'
    | 'EctogridEnergyCoolingActiveBalancing'
    | 'EctogridEnergyUsageSubstation'
    | 'EctogridEnergyElectricityUsageSubstation'
    | 'EctogridEnergyUsageActiveBalancing'
    | 'EctogridEnergyElectricityUsageActiveBalancing'
    | 'EctogridEnergyHeatingUsageActiveBalancing'
    | 'EctogridExcessBalancingRatio'
    | 'EctogridEnergyHeatingFromGrid'
    | 'EctogridEnergyHeatingToGrid'
    | 'EctogridEnergyCoolingFromGrid'
    | 'EctogridEnergyCoolingToGrid'
    | 'EctogridEnergyToGrid'
    | 'EctogridEnergyFromGrid'
    | 'AutomaticPowerControlSchedules'
    | 'LoBaEnergyElectricityFromGrid'
    | 'LoBaEnergyElectricityToGrid'
    | 'LoBaEnergyElectricityFromBattery'
    | 'LoBaEnergyElectricityToBattery'
    | 'LoBaEnergyElectricityFromPV'
    | 'LoBaEnergyElectricityConsumption'
    | 'LoBaSelfSufficiencyRatio'
    | 'LoBaElectricityPeakShaving'
    | 'EctoGridKPIConfig'
    | 'LocalBalancingKPIConfig'
    | 'LoBaBatteryChargingThreshold'
    | 'PowerControlStopSignal'
    | 'LoBaSelfConsumptionRatio'
    | 'EctogridPreloadingHeating'
    | 'EctogridPreloadingCooling'
    | 'EctogridPreloadingHeatingMapping'
    | 'EctogridPreloadingCoolingMapping'
    | 'NordPoolIntegration'
    | 'NetmoreIntegration'
    | 'HeatingDemandPriceLoadShifting'
    | 'EctogridEnergyHeatingFromGridForecast'
    | 'LoBaEnergyElectricityConsumptionForecast'
    | 'EctogridEnergyCoolingFromGridForecast'
    | 'LoBaOptimization'
    | 'EctoThermal'
    | 'EctoThermalPBU'
    | 'EctoThermalPABU'
    | 'EctoThermalABU'
    | 'EctogridEnergyElectricityAncillary'
    | 'EctogridEnergyHeatingAuxiliarySubstation'
    | 'EctogridEnergyCoolingAuxiliarySubstation'
    | 'EctogridEnergyCoolingConsumptionSubstation'
    | 'EctogridEnergyHeatingConsumptionSubstation'
    | 'EctogridEnergyConsumptionSubstation'
    | 'EctogridEnergyAuxiliarySubstation'
    | 'EctogridActiveBalancingCOP'
    | 'EctogridSubstationCOP'
    | 'EctogridSubstationCoolingCOP'
    | 'EctogridEctoSubstationHeatingCOP'
    | 'EctoThermalSetpoint'
    | 'EctogridEnergyHeatingToHeatPump'
    | 'EctogridEnergyCoolingToChiller'
    | 'EctogridEnergyElectricityUsageShared'
    | 'EctogridEnergyElectricityUsageHeatingOnly'
    | 'EctogridEnergyElectricityUsageCoolingOnly'
    | 'EctogridAuxiliaryEnergyHeatingDelivery'
    | 'EctogridAuxiliaryEnergyCoolingDelivery'
    | 'EctogridAncillaryElectricityUsage'
    | 'EctogridEnergyUsageHeatingSubstation'
    | 'EctogridEnergyUsageCoolingSubstation'
    | 'EctogridEnergyCoolingFromHeatPump'
    | 'EctogridEnergyHeatingFromChiller'
    | 'EctogridEnergyElectricityUsageHeating'
    | 'EctogridEnergyElectricityUsageCooling'
    | 'EctogridElectricityUsageHeatingModeling'
    | 'EctogridElectricityUsageCoolingModeling'
    | 'EctogridEnergyFromGridNet'
    | 'EctogridAuxiliaryEnergyDelivery'
    | 'EctogridTotalEnergyConsumption'
    | 'EctogridTotalEnergyHeatingConsumption'
    | 'EctogridTotalEnergyCoolingConsumption'
    | 'EctogridEnergyCoverageRatio'
    | 'EctogridEnergyHeatingCoverageRatio'
    | 'EctogridEnergyCoolingCoverageRatio'
    | 'EctogridEctoCOPSubstation'
    | 'EctogridEctoCOPHeatingSubstation'
    | 'EctogridEctoCOPCoolingSubstation'
    | 'EctogridEctoCOPActiveBalancing'
    | 'EctoThermalPreloadingControlHeating'
    | 'EctoThermalPreloadingControlCooling'
    | 'EctogridEnergyHeatingToHeatPumpForecast'
    | 'EctogridEnergyCoolingToChillerForecast'
    | 'EctogridEnergyHeatingDeliveryForecast'
    | 'EctogridEnergyCoolingDeliveryForecast'
    | 'EctogridWarmPipeMaxEctoThermal'
    | 'EctogridColdPipeMaxEctoThermal'
    | 'EctogridWarmPipeMinEctoThermal'
    | 'EctogridColdPipeMinEctoThermal'
    | 'EctogridEctoControlGrid'
    | 'EctogridEctoControlActiveBalancingUnit'
    | 'EctogridActiveBalancingUnitForecast'
    | 'EctogridEctoControlPassiveBalancingUnit'
    | 'EctogridEctoControlSubstation'
    | 'EctogridElectricityUsageCoolingModelingForecast'
    | 'EctogridElectricityUsageHeatingModelingForecast'
    | 'EctogridEnergyElectricityUsageForecast'
    | 'EctogridEnergyFromGridNetForecast'
    | 'EctogridEnergyElectricityUsageSubstationForecast'
    | 'EctogridElectricityUsageCompressorSubstation'
    | 'EctogridElectricityUsageCompressorSubstationForecast';
}

export interface SignalsGetSignalTemplatesParams {
  Ids?: string[] | null;
  /** @format guid */
  SignalProviderTemplateId?: string | null;
}

export interface SignalsGetSignalValuesParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  /**
   * @format int32
   * @min 10
   * @max 5000
   */
  Points?: number;
}

export interface SignalsGetSignalValuesByTimeRangeParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  SamplingInterval?:
    | 'Unknown'
    | 'Raw'
    | 'Minute'
    | 'Hour'
    | 'Day'
    | 'Week'
    | 'Month'
    | 'MinutesFive'
    | 'MinutesFifteen';
  Aggregation?:
    | 'Unknown'
    | 'None'
    | 'Mean'
    | 'Max'
    | 'Min'
    | 'First'
    | 'Last'
    | 'Count'
    | 'Sum'
    | 'Median'
    | 'Mode'
    | 'NoneWithTags';
  /** Gets a value indicating whether StartDate and EndDate are inclusive. */
  IsInclusive?: boolean;
}

export interface SignalsGetSignalValuesByTimeIntervalParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  Date?: string;
  /**
   * @format int32
   * @min 0
   * @max 168
   */
  HoursBack?: number;
  /**
   * @format int32
   * @min 0
   * @max 168
   */
  HoursForward?: number;
  SamplingInterval?:
    | 'Unknown'
    | 'Raw'
    | 'Minute'
    | 'Hour'
    | 'Day'
    | 'Week'
    | 'Month'
    | 'MinutesFive'
    | 'MinutesFifteen';
  Aggregation?:
    | 'Unknown'
    | 'None'
    | 'Mean'
    | 'Max'
    | 'Min'
    | 'First'
    | 'Last'
    | 'Count'
    | 'Sum'
    | 'Median'
    | 'Mode'
    | 'NoneWithTags';
}

export interface SignalsGetLastSignalValuesAsyncObsoleteParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  EndDate?: string | null;
}

export interface SignalsGetLastSignalValuesByNodeIdsParams {
  NodeIds?: string[] | null;
  SignalProviderTypes?: SignalProviderType[] | null;
  /** @format date-time */
  EndDate?: string | null;
}

export interface SignalsGetSignalValuesByTimeRangeExportToCsvParams {
  SignalIds?: string[] | null;
  /** @format date-time */
  StartDate?: string;
  /** @format date-time */
  EndDate?: string;
  SamplingInterval?:
    | 'Unknown'
    | 'Raw'
    | 'Minute'
    | 'Hour'
    | 'Day'
    | 'Week'
    | 'Month'
    | 'MinutesFive'
    | 'MinutesFifteen';
  Aggregation?:
    | 'Unknown'
    | 'None'
    | 'Mean'
    | 'Max'
    | 'Min'
    | 'First'
    | 'Last'
    | 'Count'
    | 'Sum'
    | 'Median'
    | 'Mode'
    | 'NoneWithTags';
  TimeZone?: string | null;
}

export interface SignalsDownloadSignalExportParams {
  /**
   * Gets or sets the signal export identifier.
   * @format guid
   */
  SignalExportId?: string;
}

export interface TenantsAddTenantParams {
  /** The tenant identifier. */
  tenantId?: string | null;
}

export interface NodesV2GetNodeChildrenParams {
  nodeIds?: string[] | null;
}

export interface NodesV2GetNodeAndAncestorsParams {
  /** @format guid */
  nodeId?: string;
}

export interface NodesV2GetNodeValueSuggestionsParams {
  /** @format guid */
  nodePropertyId?: string;
  searchPhrase?: string | null;
  /** @format int32 */
  numSuggestions?: number;
}

export interface NodesV2GetNodesByIdsParams {
  nodeIds?: string[] | null;
}

export interface NodesV2GetNodeTraitsByIdsParams {
  traitIds?: string[] | null;
}

export interface NodesV2GetNodePropertyValuesWithPropertiesParams {
  /** @format guid */
  nodeId?: string;
}

export interface NodesV2GetNodePropertyValuesParams {
  /** @format guid */
  nodeId?: string;
}

/**
 * @title Ectocloud gateway API
 * @version 1.0.0
 * @baseUrl http://localhost:2222
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.APIGen;

export interface AdminAlarmsGetAlarmSignalGroupTemplatePath {
  /**
   * Required GUID of existing template
   * @format guid
   */
  alarmSignalGroupTemplateId: string;
}

export interface BackendChangeLogLevelPath {
  logEventLevel:
    | 'Verbose'
    | 'Debug'
    | 'Information'
    | 'Warning'
    | 'Error'
    | 'Fatal';
}

export interface BackendLogMessagePath {
  logEventLevel:
    | 'Verbose'
    | 'Debug'
    | 'Information'
    | 'Warning'
    | 'Error'
    | 'Fatal';
  message: string | null;
}

export interface SignalsGetAllProviderSignalsPath {
  /** @format guid */
  providerId: string;
}

export interface SignalsAddOrUpdateProviderSignalsPath {
  /** @format guid */
  providerId: string;
}

const APIGen = {
  ActivityLog: {
    get: apiEndpointEmptyUsingQueryParams<
      ActivityLogGetParams,
      ActivityLogListResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/activitylog`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    create: apiEndpoint<
      AddActivityLogRequestModel,
      ActivityLogResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/activitylog`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    delete: apiEndpointEmptyUsingQueryParams<
      ActivityLogDeleteParams,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/activitylog`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getRecursive: apiEndpointEmptyUsingQueryParams<
      ActivityLogGetRecursiveParams,
      ActivityLogListResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/activitylog/recursive`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminAlarms: {
    /**
     * @description It includes both updating the AlarmSignalProvider templates and the corresponding SignalModbusTemplates. If AlarmSignalGroupTemplateId is not provided a new guid will be created and it will be added as a new template. * @summary Adds or updates alarm Signal templates.
     */
    addOrUpdateAlarmSignalGroupTemplate: apiEndpoint<
      AddOrUpdateAlarmSignalGroupTemplateRequestModel,
      AdminAlarmSignalGroupTemplateResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/alarmSignalTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns All Alarm Signal Templates with their modbus config if any.
     */
    getAllAlarmSignalGroupTemplates: apiEndpointEmpty<
      AdminAlarmSignalGroupTemplateResponseModel[],
      any
    >(
      Method.GET,
      `/api/admin/alarmSignalTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the alarm signal provider templates in the supplied list.
     */
    deleteAlarmSignalGroupTemplates: apiEndpoint<
      DeleteAlarmSignalGroupTemplatesRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/alarmSignalTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns a specific Alarm Signal Group Template.
     */
    getAlarmSignalGroupTemplate: apiEndpointEmptyWithPath<
      AdminAlarmSignalGroupTemplateResponseModel,
      ProblemDetails,
      AdminAlarmsGetAlarmSignalGroupTemplatePath
    >(
      Method.GET,
      ({
        alarmSignalGroupTemplateId
      }: AdminAlarmsGetAlarmSignalGroupTemplatePath) =>
        `/api/admin/alarmSignalTemplates/${alarmSignalGroupTemplateId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description TODO: Make it consistent with how we do requests of multiple items. * @summary Adds or updates a list of alarm signals.
     */
    addOrUpdateAlarmSignals: apiEndpoint<
      AddOrUpdateAlarmSignalsRequestModel[],
      AdminEquipmentSignalResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/alarmSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns  specified Alarm Signals. If no Alarm signal Ids are specified nothing is returned.
     */
    getAlarmSignals: apiEndpointEmptyUsingQueryParams<
      AdminAlarmsGetAlarmSignalsParams,
      AdminEquipmentSignalResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/alarmSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary This method re-initializes all alarm events using data from the influx store.
     */
    initializeAlarmEvents: apiEndpointEmpty<void, any>(
      Method.PUT,
      `/api/admin/initializeAlarmEvents`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminBuildings: {
    /**
 * @description The data needs to be applied by using PUT. The user can modify the returned instruction list information before doing PUT, but internal consistency is not checked at that point. Unless the user knows what he/she is doing, it's recommended to not modify the GUIDs in the returned instruction list. POST is not normally used as if it is a GET-operation. However GET is limited to [FromQuery] and the data we supply is too complex for that. TODO when redoing templating make changes so only one call is made per api in total, instead of one per building. * @summary For given Building template and override it will return an instruction list to create all data that will be used to create a building with Equipment and
tools.
            
*/
    getAddOrUpdateBuildingsByTemplates: apiEndpoint<
      AddOrUpdateBuildingsByTemplatesRequestModel[],
      CreateBuildingByTemplateDataResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/buildings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description The following will be created: A new Building Any equipment that belong to the building. Any users defined in the instruction list will get access to the building. All tools and signals defined will be created. * @summary For a given building instruction list / instantiation recipe the  Building, equipment, tools, signals and connections will be created.
            
*/
    addOrUpdateBuildingsByTemplates: apiEndpoint<
      CreateBuildingByTemplateDataResponseModel[],
      CreateBuildingByTemplateDataResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/buildings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Returns all Equipment Template Groups.
            
*/
    getBuildingTemplates: apiEndpointEmpty<
      BuildingTemplateResponseModel[],
      any
    >(
      Method.GET,
      `/api/admin/buildingTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates one or more equipment template groups.
     */
    addOrUpdateBuildingTemplates: apiEndpoint<
      AddOrUpdateBuildingTemplateRequestModel[],
      BuildingTemplateResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/buildingTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Deletes an Equipment Template Group
            
*/
    deleteEquipmentTemplateGroups: apiEndpointEmptyUsingQueryParams<
      AdminBuildingsDeleteEquipmentTemplateGroupsParams,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/buildingTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets all the building nodes that match the supplied filters.
     */
    getBuildingsByFilters: apiEndpoint<
      GetBuildingsByFiltersRequestModel,
      BuildingListResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/buildings/search`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Exports all the building nodes that match the supplied filters to a CSV file.
     */
    getBuildingsExport: apiEndpoint<
      GetBuildingsExportRequestModel,
      File,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/buildings/export`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminComfort: {
    /**
 * @summary This is used to create a template of the comfort providers to be created/updated. A PUT using the response
of this method must be used to actually create something.
*/
    getAddOrUpdateComfortHeatingByTemplateRequestModel: apiEndpoint<
      AddOrUpdateComfortHeatingByTemplateRequestModel[],
      CreateComfortHeatingByTemplateResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/comfort/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates or updates comfort providers using a template.
     */
    addOrUpdateComfortHeatingByTemplates: apiEndpoint<
      CreateComfortHeatingByTemplateResponseModel[],
      CreateComfortHeatingByTemplateResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/comfort/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the comfort heating providers in the supplied list.
     */
    deleteComfortHeatingProviders: apiEndpoint<
      DeleteComfortHeatingProvidersRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/comfort/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets all comfort heating providers.
     */
    getAllComfortHeatingProviders: apiEndpointEmpty<
      ComfortHeatingProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/comfort/providers/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the comfort heating providers for the supplied node ids.
     */
    getComfortHeatingProvidersByNodeId: apiEndpoint<
      GetComfortHeatingProvidersByNodeIdRequestModel,
      ComfortHeatingProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/comfort/providers/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the comfort heating providers for the supplied provider ids.
     */
    getComfortHeatingProvidersByProviderId: apiEndpoint<
      GetComfortHeatingProvidersByProviderIdRequestModel,
      ComfortHeatingProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/comfort/providers/byProviderId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Creates or updates comfort alarms.
     */
    addOrUpdateComfortAlarmConfigurations: apiEndpoint<
      AddOrUpdateComfortAlarmRequestModel[],
      ComfortAlarmConfigurationResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/comfort/alarms`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the alarm configurations in the supplied list.
     */
    deleteComfortAlarmConfigurations: apiEndpoint<
      DeleteComfortAlarmConfigurationsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/comfort/alarms/configurations`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminDashboard: {
    /**
     * @summary Gets the dashboards based on object ID:s.
     */
    getDashboardsById: apiEndpoint<
      GetDashboardByIdRequestModel,
      DashboardResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/dashboards/byIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets a paged list of dashboards that match the supplied parameters.
     */
    getPagedDashboards: apiEndpoint<
      GetPagedDashboardsRequestModel,
      PagedDashboardsResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the dashboards specified in the request models.
     */
    addOrUpdateDashboards: apiEndpoint<
      AddOrUpdateDashboardRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Removes the specified dashboards.
     */
    deleteDashboards: apiEndpoint<
      DeleteDashboardsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/dashboards`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Removes the specified relationships between dashboard collections and nodes.
     */
    deleteDashboardCollectionNodeRelations: apiEndpoint<
      DeleteDashboardCollectionNodeRelationsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/dashboards/collections/relations/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates dashboard collection associations with nodes.
     */
    addOrUpdateDashboardCollectionNodeRelations: apiEndpoint<
      AddOrUpdateDashboardCollectionNodeRelationRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/dashboards/collections/relations/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Removes the specified relationships between dashboard collections and building templates.
     */
    deleteDashboardCollectionBuildingTemplateRelations: apiEndpoint<
      DeleteDashboardCollectionBuildingTemplateRelationsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/dashboards/collections/relations/buildingTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates dashboard collection associations with building templates.
     */
    addOrUpdateDashboardCollectionBuildingTemplateRelations: apiEndpoint<
      AddOrUpdateDashboardCollectionBuildingTemplateRelationRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/dashboards/collections/relations/buildingTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets a paged list of dashboard collections that match the supplied parameters.
     */
    getPagedDashboardCollections: apiEndpoint<
      GetPagedDashboardCollectionsRequestModel,
      PagedDashboardCollectionResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/dashboards/collections`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the dashboard collections specified in the request models.
     */
    addOrUpdateDashboardCollections: apiEndpoint<
      AddOrUpdateDashboardCollectionRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/dashboards/collections`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Removes the specified dashboard collections.
     */
    deleteDashboardsCollections: apiEndpoint<
      DeleteDashboardCollectionsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/dashboards/collections`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the dashboard collections based on collection or related object ID:s.
     */
    getDashboardCollections: apiEndpoint<
      GetDashboardCollectionsRequestModel,
      DashboardCollectionResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/dashboards/collections/byIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Sets which dashboard collection is the default, i.e. which collection will be the
fall-back whenever we don't have a context specific collection to show.
*/
    setDefaultDashboardCollectionId: apiEndpoint<
      SetDefaultDashboardCollectionIdRequestModel,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/dashboards/collections/defaultId`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @summary Gets the ID of the default dashboard collection that should be used when there are no custom
collections for a given situation.
*/
    getDefaultDashboardCollectionId: apiEndpointEmpty<
      DashboardCollectionIdResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/dashboards/collections/defaultId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the file contents of the dashboards specified in the request models.
     */
    addOrUpdateDashboardFiles: apiEndpoint<
      AddOrUpdateDashboardFileRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/dashboards/files`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the dashboard file contents that are related to specific dashboards.
     */
    getDashboardFiles: apiEndpoint<
      GetDashboardFilesRequestModel[],
      DashboardFileResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/dashboards/files`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminDevices: {
    /**
     * @description Slave id needs to be different between connections. Also the users prefer the slave id to be sequential. Therefore backend needs to figure out the highest currently used Slave Id for a Device and return max(slaveId) + 1. * @summary The frontend can request for a given EM Equipment to get a new suggested slave id to use when adding a new connection.
     */
    getSuggestedSlaveId: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetSuggestedSlaveIdParams,
      SlaveIdResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/devices/suggestedSlaveId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Either device Name or Equipment Id should be specified. Otherwise 404 may be returned. Equipment Id in this case would be the same as Connection Id. Verbose specifies if extra data should be in the file returned like Name and Description. The structure of the json file is described in https://cpmmanage.atlassian.net/wiki/spaces/BTGCES/pages/118824086/Modbus+Interface * @summary Returns device config file for a requested IoT device name, device Id or Equipment Id.
     */
    getModbusDeviceConfigFile: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetModbusDeviceConfigFileParams,
      any,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceFiles/deviceConfigFile`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description It is a json file defined in https://cpmmanage.atlassian.net/wiki/spaces/BTGCES/pages/125730863/Control+Module The tools config tells the EM what tools each equipment has, what signals Id those tools has, and what input and output time series signals that are defined. Either device Name, device Id or Equipment Id should be specified. Otherwise 404 may be returned. Equipment Id in this case would be the same as Connection Id. Verbose specifies if extra data should be in the file returned like Name and Description. * @summary Returns the tools config file.
     */
    getModbusToolsConfigFile: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetModbusToolsConfigFileParams,
      any,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceFiles/toolsConfigFile`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description TODO why not use device id? - Previously frontend had no way of getting device Id from Equipment. Should be deprecated now. TODO should be renamed as it does more than it says? * @summary Requests physical Energy Manager to download Device Config, Tools Config and Signal Config files using device twin
and device login. The config files will be regenerated and stored in the database so that the Energy Manager
will download the latest version.
*/
    deployModbusDeviceConfig: apiEndpoint<
      DeployModbusConfigRequestModel,
      DeployResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/deployDeviceConfig`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description TODO should be renamed as it does more than it says? * @summary Requests one or more physical Energy Managers to download Device Config, Tools Config and Signal Config files using device twin
and device login. The config files will be regenerated and stored in the database so that the Energy Manager
will download the latest version.
*/
    deployModbusDeviceConfigs: apiEndpoint<
      DeployModbusConfigsRequestModel,
      DeployDeviceConfigsResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/deployDeviceConfigs`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description TODO should be renamed as it does more than it says? * @summary Requests all physical Energy Managers to download Device Config, Tools Config and Signal Config files using device twin
and device login. The config files will be regenerated and stored in the database so that the Energy Manager
will download the latest version.
*/
    deployAllModbusDeviceConfigs: apiEndpointEmpty<
      DeployDeviceConfigsResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/deployDeviceConfigs/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description TODO: Should be deprecated in favor of GetDeviceEquipmentInfoFromEquipmentIds. This method assumes the equipment Id is that of an Energy Manager, the other method makes no such assumption. Should have a more descriptive endpoint name. * @summary Returns the device that equipment acts as a connection for. Equipment ID must correspond to the Equipment ID
of an Energy Manager (which in turn represents a unique connection to a device). Used when navigating through
an equipment hierarchy where we do not have access to the device information.
*/
    getDeviceEquipmentInfo: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetDeviceEquipmentInfoParams,
      DeviceInfoResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceInfo`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Returns device id/equipment id pair. Equipment ID can be any kind of equipment ID - it will return the device
that is responsible for handling its signals.
*/
    getDeviceEquipmentInfoFromEquipmentIds: apiEndpoint<
      GetDeviceEquipmentInfoFromEquipmentIdsRequestModel,
      DeviceInfoResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/deviceInfo/byEquipmentIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns zero or more devices for a specified search query.
     */
    getDevices: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetDevicesParams,
      DeviceResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/devices`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Returns device status for device given Equipment Id. This method uses the Equipment Id of an Energy Manager
since the frontend navigation is based on equipment nodes where we do not have access to the underlying
Device Id.
*/
    getDeviceStatus: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetDeviceStatusParams,
      DeviceStatusResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceStatus`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns device status for each supplied Device Id.
     */
    getDeviceStatusFromDeviceIds: apiEndpoint<
      GetDeviceStatusFromDeviceIdsRequestModel,
      DeviceStatusResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/deviceStatus/byDeviceIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the modules from the device twin for all the specified devices.
     */
    getDeviceModules: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetDeviceModulesParams,
      GetDeviceModulesResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceModules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets Equipment list for a search criteria including IoT Device Name or Equipment Id.
     */
    getEquipmentsListConfigFile: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetEquipmentsListConfigFileParams,
      SimplifiedEquipmentResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceFiles/equipmentsConfigFile`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns a device signal config file based on search criteria.
     */
    getModbusSignalsConfig: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetModbusSignalsConfigParams,
      any[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/deviceFiles/signalsConfigFile`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns connection data for each supplied connection Id.
     */
    getConnections: apiEndpointEmptyUsingQueryParams<
      AdminDevicesGetConnectionsParams,
      ConnectionResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/devices/connections`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Please observe that all the data is required, otherwise the connection will not work. * @summary Allows of the creation or update of one or more Connections.
     */
    addOrUpdateConnections: apiEndpoint<
      AddOrUpdateConnectionRequestModel[],
      ConnectionResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/devices/connections`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns connection data for the supplied list of device ids.
     */
    getConnectionsByDeviceIds: apiEndpoint<
      GetConnectionsFromDeviceIdsRequestModel,
      ConnectionResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/devices/connections/byDeviceIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description This modbus config is the one used first when creating a connection. That means if a building is created and no connection modbus config is set there and no template override is set, the values here are the default ones. * @summary Returns the connection default modbus config.
     */
    getConnectionDefaultModbusConfig: apiEndpointEmpty<
      ConnectionDefaultModbusConfigResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/devices/connections/defaultModbusConfig`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description This modbus config is the one used first when creating a connection. That means if a building is created and no connection modbus config is set there and no template override is set, the values here are the default ones. There can only be one connection default modbus config, and AddOrUpdate will overwrite any old values. When a new database is initiated default values will be set from the start. These initial defaults can be changed here of course. * @summary Adds or updates the connection default modbus config.
     */
    addOrUpdateConnectionDefaultModbusConfig: apiEndpoint<
      AddOrUpdateConnectionsDefaultModbusConfigRequestModel,
      ConnectionDefaultModbusConfigResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/devices/connections/defaultModbusConfig`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Name can be used to differentiate different connections for one device in a human readable form. * @summary Allows to partly update one or more connection and setting values for Name.
     */
    setConnectionName: apiEndpoint<
      UpdateConnectionNameRequestModel[],
      ConnectionNameResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/devices/connections/names`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Each connection has at most one connection modbus config and by default as we do it now, all new connections get an modbus config. Therefore it will not very often be the case that there are connections where one needs to add a configuration. * @summary Allows for the update of connection modbus configs.
     */
    addOrUpdateConnections2: apiEndpoint<
      AddOrUpdateConnectionModbusConfigsRequestModel,
      FullConnectionModbusConfigResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/devices/connections/modbusconfigs`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Allows existing signals to be moved from one connection to another.
     */
    addOrUpdateSignalConnections: apiEndpoint<
      AddOrUpdateSignalConnectionsRequestModel,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/signalModbusConfigs/connections`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminEmail: {
    /**
     * @description The email will be sent from noreply@ectocloud.io. * @summary Sends an email to the supplied recipients.
     */
    sendEmail: apiEndpoint<SendEmailRequest, void, ProblemDetails>(
      Method.PUT,
      `/api/admin/email`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminEquipments: {
    /**
     * @description It includes both data from Signal Provider domain and IoT domain. As the response is shared, some parameters should be ignored (Severity and AlarmType). * @summary Returns equipment Signal.
     */
    getEquipmentSignals: apiEndpointEmptyUsingQueryParams<
      AdminEquipmentsGetEquipmentSignalsParams,
      AdminEquipmentSignalResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/equipmentSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description It includes both updating the Equipment Signal in SignalProvider domain and in IoT. * @summary Adds or updates Equipment Signals.
     */
    addOUpdateEquipmentSignals: apiEndpoint<
      AddOrUpdateEquipmentSignalsRequestModel[],
      AdminEquipmentSignalResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/equipmentSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Although an energyManager is just an equipment, it is handled specially by it self to avoid issues and making sure that the energy manager gets its connection modbus config in the IoT domain. Thus we assume that the EquipmentType to use as a base is the EnergyManagerEquipmentType. A Equipment node is updated/added in the location domain. IoT Connection is updated/Added. Device is created or updated with Connection Info. Equipment Signals and Alarm Signals are updated/added in Signal Provider domain. Alarms are updated/added to Alarm Domain. * @summary Adds or updates Energy Manager equipment.
     */
    addOrUpdateEnergyManagerByEquipmentType: apiEndpoint<
      AddOrUpdateEnergyManagerByEquipmentTypeRequestModel,
      EquipmentResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/equipments/energyManagers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Please observe that Energy Manager has its own API. Use this only for other equipment. A Equipment node is updated/added in the location domain under a given parent node. If specified Power Control and LinearOptimisation is added. Equipment Signals and Alarm Signals are updated/added in Signal Provider domain. Alarms are updated/added to Alarm Domain. * @summary Adds or updates equipment given a Equipment Type Id for each equipment.
     */
    addOrUpdateEquipmentsByEquipmentTypes: apiEndpoint<
      AddOrUpdateEquipmentByEquipmentTypeRequestModel[],
      EquipmentResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/equipments/byEquipmentType`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates name and description for one or more equipments.
     * @deprecated
     */
    updateEquipmentsAsyncObsolete: apiEndpoint<
      AddOrUpdateEquipmentRequestModelOBSOLETE[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/equipments/nameAndDescription`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Updates some settings for one or more equipments.
     */
    updateEquipments: apiEndpoint<
      UpdateEquipmentRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/equipments`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns all Equipment types.
     */
    getEquipmentTypes: apiEndpointEmpty<EquipmentTypeResponseModel[], any>(
      Method.GET,
      `/api/admin/equipmentTypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description An equipment type can be for example Energy Manager or Radiator or Hot Tap. * @summary Adds or updates equipment types.
     */
    addOrUpdateEquipmentTypes: apiEndpoint<
      AddOrUpdateEquipmentTypeRequestModel[],
      EquipmentTypeResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/equipmentTypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description This api only return Signal information but not other template information. * @summary Returns all equipment Signal templates, or if an Equipment Type is specified it returns all
Equipment Signal templates of that type.
*/
    getEquipmentSignalProviderTemplates: apiEndpointEmptyUsingQueryParams<
      AdminEquipmentsGetEquipmentSignalProviderTemplatesParams,
      EquipmentSignalProviderTemplateResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/equipmentTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Add or update Equipment templates.
     */
    addOrUpdateEquipmentSignalProviderTemplates: apiEndpoint<
      AddOrUpdateEquipmentSignalProviderTemplateRequestModel[],
      EquipmentSignalProviderTemplateResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/equipmentTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Deletes the equipment signal provider templates in the supplied list.
This will also delete the related equipment type if it is allowed.
Basically we can't delete anything if the equipment type is used anywhere.
*/
    deleteEquipmentSignalProviderTemplates: apiEndpoint<
      DeleteEquipmentSignalProviderTemplatesRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/equipmentTemplates`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns all Tool signals for a given node.
     */
    getToolSignals: apiEndpointEmptyUsingQueryParams<
      AdminEquipmentsGetToolSignalsParams,
      AdminEquipmentSignalResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/toolSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminIoTDevices: {
    /**
 * @summary Gets the IoT devices matching the supplied identifiers. If no identifiers are supplied then
all IoT devices are returned. This is one of the few endpoints that returns all data if
no parameter is passed!
*/
    getIoTDeviceById: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetIoTDeviceByIdParams,
      IoTDeviceResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateIoTDevice: apiEndpoint<
      AddOrUpdateIoTDeviceRequestModel[],
      IoTDeviceResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/iotdevices`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteIoTDevicesById: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesDeleteIoTDevicesByIdParams,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/iotdevices`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getIoTDeviceByDeviceId: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetIoTDeviceByDeviceIdParams,
      IoTDeviceResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices/byDeviceId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIoTDeviceByDeviceName: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetIoTDeviceByDeviceNameParams,
      IoTDeviceResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices/byDeviceName`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getIoTDeviceTags: apiEndpointEmpty<string[], any>(
      Method.GET,
      `/api/admin/iotdevices/deviceTags`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Performs a paged search for IoT Devices.
     */
    getIoTDevicesSearch: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetIoTDevicesSearchParams,
      IoTDevicesSearchResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices/view/search`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets information about IoT Devices and Device Twins based on the supplied device identifiers.
     */
    getIoTDeviceViewByDeviceId: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetIoTDeviceViewByDeviceIdParams,
      IoTDeviceViewResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices/view/byDeviceId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets information about IoT Devices and Device Twins based on the supplied IoT device identifiers.
     */
    getIoTDeviceViewByIoTDeviceId: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetIoTDeviceViewByIoTDeviceIdParams,
      IoTDeviceViewResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices/view/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getDeviceTwinSettings: apiEndpointEmptyUsingQueryParams<
      AdminIoTDevicesGetDeviceTwinSettingsParams,
      IoTDeviceSettingsResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/iotdevices/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateDeviceTwinSettings: apiEndpoint<
      AddOrUpdateIoTDeviceSettingsRequestModel[],
      IoTDeviceSettingsResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/iotdevices/settings`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getModules: apiEndpointEmpty<ModuleResponseModel[], any>(
      Method.GET,
      `/api/admin/iotdevices/modules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateModules: apiEndpoint<
      AddOrUpdateModuleRequestModel[],
      ModuleResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/iotdevices/modules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteModules: apiEndpoint<
      DeleteModuleRequestModel[],
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/iotdevices/modules`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @description The method only accepts one id and should probably be modify to accept a list. * @summary Requests a physical Energy Manager to reboot it self.
     */
    rebootDevice: apiEndpoint<
      RebootDeviceRequestModel,
      RebootDeviceResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/iotdevices/reboot`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Device must be online. * @summary Request device twin sync for one or more IoT devices using direct method.
     */
    syncDeviceTwins: apiEndpoint<
      SyncDeviceTwinsRequestModel,
      SyncDeviceTwinResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/iotdevices/syncDeviceTwin`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Request heart beat status over Direct Method.
     */
    sendDirectHeartbeats: apiEndpoint<
      SendDirectHeartbeatsRequestModel,
      DirectHeartbeatResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/iotdevices/heartbeat`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Request over Direct Method one or more Devices to, for each device, ping a specified IP-address or hostname.
     */
    pingDevices: apiEndpoint<
      PingDevicesRequestModel,
      PingDevicesResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/iotdevices/ping`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    restartModules: apiEndpoint<
      RestartModulesRequestModel,
      RestartModulesResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/iotdevices/restartModules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Switches the IoT device for a device and copies the existing tags.
It will also copy the network settings from the device twin of
the current IoT device to the new IoT device.
*/
    switchIoTDevice: apiEndpoint<
      SwitchIoTDeviceRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/iotdevices/switch`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminJobs: {
    /**
 * @summary Triggers the job that BLOB storage export of the structures that ML uses.
The job is triggered to run in the background by Hangfire so this methods returns
immediately without waiting for the job to finish.
*/
    triggerStructureExportJob: apiEndpointEmptyUsingQueryParams<
      AdminJobsTriggerStructureExportJobParams,
      void,
      any
    >(
      Method.POST,
      `/api/admin/jobs/trigger-structure-export`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminLoBaBatteryChargingThreshold: {
    /**
     * @summary Adds or updates the supplied local balancing battery charging threshold providers.
     */
    addOrUpdateLoBaBatteryChargingThresholdProviders: apiEndpoint<
      AddOrUpdateLoBaBatteryChargingThresholdProviderRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/loBaBatteryChargingThreshold/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the local balancing battery charging threshold providers in the supplied list.
     */
    deleteLoBaBatteryChargingThresholdProviders: apiEndpoint<
      DeleteLoBaBatteryChargingThresholdProvidersRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/loBaBatteryChargingThreshold/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the local balancing battery charging threshold providers for the supplied node identifiers.
     */
    getLoBaBatteryChargingThresholdProvidersByNodeId: apiEndpoint<
      GetLoBaBatteryChargingThresholdProvidersByNodeIdRequestModel,
      LoBaBatteryChargingThresholdProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/loBaBatteryChargingThreshold/providers/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the local balancing battery charging threshold providers for the supplied provider identifiers.
     */
    getLoBaBatteryChargingThresholdProvidersByProviderId: apiEndpoint<
      GetLoBaBatteryChargingThresholdProvidersByProviderIdRequestModel,
      LoBaBatteryChargingThresholdProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/loBaBatteryChargingThreshold/providers/byProviderId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminMeteorology: {
    /**
 * @summary Downloads historical weather data for a given weather point.
It will download data from {startYear}-01-01 00:00:00 (UTC) until DateTime.UtcNow
*/
    downloadMeteomaticsHistoricalData: apiEndpointEmptyUsingQueryParams<
      AdminMeteorologyDownloadMeteomaticsHistoricalDataParams,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/meteorology/download-meteomatics-historical-data`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminNodes: {
    getNodes: apiEndpointEmptyUsingQueryParams<
      AdminNodesGetNodesParams,
      NodeResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateNode: apiEndpoint<
      AddOrUpdateNodeRequestModel,
      AdminNodeResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteNode: apiEndpoint<DeleteNodeRequestModel, void, ProblemDetails>(
      Method.DELETE,
      `/api/admin/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getNodeTypes: apiEndpointEmpty<string[], any>(
      Method.GET,
      `/api/admin/nodetypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodeTags: apiEndpointEmpty<string[], any>(
      Method.GET,
      `/api/admin/nodes/tags`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the building information for the supplied building ids.
     */
    getBuildingInfoByBuildingIds: apiEndpoint<
      GetBuildingInfoByBuildingIdsRequestModel,
      BuildingInfoResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/nodes/buildingInfo/byBuildingId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Updates building information. Information can't be added from here since that is always done automatically when a new building is created.
     */
    updateBuildingInfo: apiEndpoint<
      UpdateBuildingInfoRequestModel[],
      BuildingInfoResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/nodes/buildingInfo`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodesByDistrictHeatingFacilityId: apiEndpointEmptyUsingQueryParams<
      AdminNodesGetNodesByDistrictHeatingFacilityIdParams,
      SimpleNodeResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/nodes/byDistrictHeatingFacilityId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the building nodes that match on the supplied status.
     */
    getNodesByBuildingStatus: apiEndpointEmptyUsingQueryParams<
      AdminNodesGetNodesByBuildingStatusParams,
      SimpleNodeResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/nodes/byBuildingStatus`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminNotifications: {
    /**
     * @summary Gets all the alarm signal type identifiers that exists in the node sub trees for the supplied node ids.
     */
    getAlarmSignalTypeIdsForNodes: apiEndpointEmptyUsingQueryParams<
      AdminNotificationsGetAlarmSignalTypeIdsForNodesParams,
      GetAlarmSignalTypeIdsForNodesResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/alarmNotifications/signalTypeIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the supplied alarm notifications.
     */
    addOrUpdateAlarmNotifications: apiEndpoint<
      AddOrUpdateAlarmNotificationsRequestModel,
      AddOrUpdateAlarmNotificationsResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/alarmNotifications`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all the alarm notifications matching the supplied ids.
     */
    getAlarmNotificationsById: apiEndpointEmptyUsingQueryParams<
      AdminNotificationsGetAlarmNotificationsByIdParams,
      GetAlarmNotificationsResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/alarmNotifications`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the alarm notifications with the supplied ids.
     */
    deleteAlarmNotifications: apiEndpoint<
      DeleteAlarmNotificationsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/alarmNotifications`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the alarm notifications that are configured for the supplied node ids.
     */
    getAlarmNotificationsByNodeIds: apiEndpointEmptyUsingQueryParams<
      AdminNotificationsGetAlarmNotificationsByNodeIdsParams,
      GetAlarmNotificationsResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/alarmNotifications/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all the existing alarm notifications.
     */
    getAlarmNotifications: apiEndpointEmpty<
      GetAlarmNotificationsResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/alarmNotifications/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminPowerDeltaControl: {
    /**
     * @summary Adds or updates the supplied power delta control providers.
     */
    addOrUpdatePowerDeltaControlProviders: apiEndpoint<
      AddOrUpdatePowerDeltaControlProviderRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/powerdeltacontrol/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the power delta control providers in the supplied list.
     */
    deletePowerDeltaControlProviders: apiEndpoint<
      DeletePowerDeltaControlProvidersRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/powerdeltacontrol/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the power delta control providers for the supplied node identifiers.
     */
    getPowerDeltaControlProvidersByNodeId: apiEndpoint<
      GetPowerDeltaControlProvidersByNodeIdRequestModel,
      PowerDeltaControlProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/powerdeltacontrol/providers/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the power delta control providers for the supplied provider identifiers.
     */
    getPowerDeltaControlProvidersByProviderId: apiEndpoint<
      GetPowerDeltaControlProvidersByProviderIdRequestModel,
      PowerDeltaControlProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/powerdeltacontrol/providers/byProviderId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminProcessMaps: {
    /**
     * @summary Gets all the process maps.
     */
    getAllProcessMaps: apiEndpointEmpty<ProcessMapResponseModel[], any>(
      Method.POST,
      `/api/admin/processMaps/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the process maps with the supplied ids.
     */
    getProcessMapsById: apiEndpoint<
      GetProcessMapsByIdRequestModel,
      ProcessMapResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/processMaps/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the process maps.
     */
    addOrUpdateProcessMaps: apiEndpoint<
      AddOrUpdateProcessMapRequestModel[],
      ProcessMapResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/processMaps`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the process maps matching the supplied ids.
     */
    deleteProcessMaps: apiEndpoint<
      DeleteProcessMapsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/processMaps`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the process map revisions with the supplied ids.
     */
    getProcessMapRevisionsById: apiEndpoint<
      GetProcessMapRevisionsByIdRequestModel,
      ProcessMapRevisionResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/processMaps/revisions/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the process map revisions with the supplied process map ids.
     */
    getProcessMapRevisionsByProcessMapId: apiEndpoint<
      GetProcessMapRevisionsByProcessMapIdRequestModel,
      ProcessMapRevisionResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/processMaps/revisions/byProcessMapId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description The frontend is NOT supposed to use the update functionality. It is here in case we want to use it from Swagger for some reason. * @summary Adds or updates the process map revisions. Only use the "add" functionality!
     */
    addOrUpdateProcessMapRevisions: apiEndpoint<
      AddOrUpdateProcessMapRevisionRequestModel[],
      ProcessMapRevisionResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/processMaps/revisions`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description This method is not supposed to be used by the frontend but it may be good to have it available in Swagger. * @summary Deletes the process map revisions matching the supplied ids.
     */
    deleteProcessMapRevisions: apiEndpoint<
      DeleteProcessMapRevisionsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/processMaps/revisions`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the equipment type process map relationships for the supplied equipment type ids.
     */
    getProcessMapRelationshipsByEquipmentTypeId: apiEndpoint<
      GetEquipmentTypeProcessMapRelationshipsRequestModel,
      EquipmentTypeProcessMapRelationshipResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/processMaps/equipmentTypeRelationships`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the equipment type process map relationships.
     */
    addOrUpdateEquipmentTypeProcessMapRelationships: apiEndpoint<
      AddOrUpdateEquipmentTypeProcessMapRelationshipRequestModel[],
      EquipmentTypeProcessMapRelationshipResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/processMaps/equipmentTypeRelationships`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the process map relationships matching the supplied equipment type ids.
     */
    deleteEquipmentTypeProcessMapRelationships: apiEndpoint<
      DeleteEquipmentTypeProcessMapRelationshipsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/processMaps/equipmentTypeRelationships`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Gets the node process map relationships for the supplied node ids.
     */
    getProcessMapRelationshipsByNodeId: apiEndpoint<
      GetNodeProcessMapRelationshipsRequestModel,
      NodeProcessMapRelationshipResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/processMaps/nodeRelationships`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the node process map relationships.
     */
    addOrUpdateNodeProcessMapRelationships: apiEndpoint<
      AddOrUpdateNodeProcessMapRelationshipRequestModel[],
      NodeProcessMapRelationshipResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/processMaps/nodeRelationships`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes the process map relationships matching the supplied node ids.
     */
    deleteNodeProcessMapRelationships: apiEndpoint<
      DeleteNodeProcessMapRelationshipsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/processMaps/nodeRelationships`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminSchedules: {
    /**
     * @summary Adds or updates the supplied schedule types
     */
    addOrUpdateScheduleTypes: apiEndpoint<
      AddOrUpdateScheduleTypeRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/schedules/types`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the schedule types in the supplied list.
     */
    deleteScheduleTypes: apiEndpoint<
      DeleteScheduleTypesRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/schedules/types`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns all schedule types.
     */
    getAllScheduleTypes: apiEndpointEmpty<
      ScheduleTypeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/schedules/types/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns the schedule types matching the list of the provided ids.
     */
    getScheduleTypesById: apiEndpoint<
      GetScheduleTypesByIdRequestModel,
      ScheduleTypeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/schedules/types/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminSignalCategories: {
    /**
     * @summary Gets all the signal categories.
     */
    getAllSignalCategories: apiEndpointEmpty<
      SignalCategoryResponseModel[],
      any
    >(
      Method.POST,
      `/api/admin/signalCategories/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the signal categories with the supplied ids.
     */
    getSignalCategoriesById: apiEndpoint<
      GetSignalCategoriesByIdRequestModel,
      SignalCategoryResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signalCategories/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the signal categories.
     */
    addOrUpdateSignalCategories: apiEndpoint<
      AddOrUpdateSignalCategoryRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/signalCategories`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the signal categories matching the supplied ids.
     */
    deleteSignalCategories: apiEndpoint<
      DeleteSignalCategoriesRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/signalCategories`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminSignals: {
    /**
 * @summary Returns signal provider data for all of the supplied signals, along with extended signal information.
Note that the provider object will only contain signal information for the signals you supplied, not all of
the signals the provider owns.
*/
    getProvidersBySignalIds: apiEndpoint<
      GetSignalProvidersBySignalIdsRequestModel,
      FullSignalProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signals/providers/bySignalIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalsByNode: apiEndpoint<
      GetSignalsByNodeRequestModel,
      SignalProviderByNodeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signals/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getDetailedSignalsByNode: apiEndpoint<
      GetDetailedSignalsByNodeRequestModel,
      SignalProviderByNodeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signals/nodes/detailed`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalsByMapIds: apiEndpoint<
      GetSignalsByMapIdsRequestModel,
      SignalProviderByNodeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signals/maps`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Return all Signal mappings.
     */
    getAllSignalMappings: apiEndpointEmpty<
      MappingResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/maps/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description The system will only do one select with a priority for: 1. Ids 2. SignalIds (Will check both source and destination signals) 3. NodeIds 4. SignalProviderIds 5. SignalProviderTypes If more than one of the lists (Ids, NodeIds, SignalProviderIds, SignalProviderTypes) are set in the request model an error will be returned. * @summary Gets signal mappings by search criteria.
     */
    getSignalMappingsById: apiEndpoint<
      GetMappingsRequestModel,
      MappingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/maps`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description If guid is not set it will be assumed to be a new mapping and a guid will be generated. Please observe that multiple mappings with the same Input and output signals can exist at once. CycleTimeInSeconds: Should be at least 60 seconds, or 0 for not sending cyclically. * @summary Adds or Updates Signal Mapping
     */
    addOrUpdateSignalMappings: apiEndpoint<
      AddOrUpdateMappingRequestModel[],
      MappingResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/maps`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes Signal Mappings
     */
    deleteSignalMappings: apiEndpoint<
      DeleteMappingRequestModel[],
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/maps`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @description This is useful when the mapping is not automatic. ie not OnPush or Cyclic. * @summary Runs a mapping, with the option to specify how the signals should be.
     */
    sendValuesFromSourceToDestinationsForMappings: apiEndpoint<
      MappingToActionRequestModel[],
      SignalMappingToActionResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/maps/run`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Deletes signal values at specific times
     */
    deleteSignalValues: apiEndpoint<
      DeleteSignalValueRequestModel[],
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/signals/values/delete`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes signal values in time range
     */
    deleteSignalValuesInTimeRange: apiEndpoint<
      DeleteSignalValuesInTimeRangeRequestModel[],
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/signals/values/range/delete`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @summary Backfills eIoT with signal values in a specified interval.
Following signal types will be ignored:

    watchdogEM
    watchdogBMS
*/
    backfillEIoTSignalValues: apiEndpoint<
      BackfillEIoTRequestModel,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signals/values/backfill-eiot`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminSignalTypeFolders: {
    /**
     * @summary Gets all the signal type folders.
     */
    getAllSignalTypeFolders: apiEndpointEmpty<
      SignalTypeFolderResponseModel[],
      any
    >(
      Method.POST,
      `/api/admin/signalTypeFolders/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the signal type folders with the supplied ids.
     */
    getSignalTypeFoldersById: apiEndpoint<
      GetSignalTypeFoldersByIdRequestModel,
      SignalTypeFolderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signalTypeFolders/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the signal type folders.
     */
    addOrUpdateSignalTypeFolders: apiEndpoint<
      AddOrUpdateSignalTypeFolderRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/signalTypeFolders`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the signal type folders matching the supplied ids.
     */
    deleteSignalTypeFolders: apiEndpoint<
      DeleteSignalTypeFoldersRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/signalTypeFolders`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminSignalTypes: {
    /**
     * @summary Gets all the signal types.
     */
    getAllSignalTypes: apiEndpointEmpty<SignalTypeResponseModel[], any>(
      Method.POST,
      `/api/admin/signalTypes/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the signal types with the supplied ids.
     */
    getSignalTypesById: apiEndpoint<
      GetSignalTypesByIdRequestModel,
      SignalTypeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/signalTypes/byId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the signal types.
     */
    addOrUpdateSignalTypes: apiEndpoint<
      AddOrUpdateSignalTypeRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/signalTypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the signal types matching the supplied ids.
     */
    deleteSignalTypes: apiEndpoint<
      DeleteSignalTypesRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/signalTypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  AdminTemperatureImpact: {
    /**
     * @description If any of the supplied node ids isn't a building then they will not be included in the result. * @summary Gets the signals needed to add a new temperature impact provider to a node.
     */
    getTemperatureImpactProviderSignals: apiEndpoint<
      GetTemperatureImpactProviderSignalsRequestModel,
      TemperatureImpactProviderSignalsResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/admin/temperatureImpact/providerSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates the supplied temperature impact providers.
     */
    addOrUpdateTemperatureImpactProviders: apiEndpoint<
      AddOrUpdateTemperatureImpactProviderRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/temperatureImpact/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the temperature impact providers in the supplied list.
     */
    deleteTemperatureImpactProviders: apiEndpoint<
      DeleteTemperatureImpactProvidersRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/admin/temperatureImpact/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @description This method should not be removed until temperature impact is part of the templates! * @summary Creates a temperature impact provider for all buildings that have power control and meteorology.
It is safe to run this method multiple times. Buildings under 'Örebro FJV' will be ignored.
*/
    migrateTemperatureImpactProviders: apiEndpointEmpty<
      MigrateTemperatureImpactProvidersResponseModel,
      any
    >(
      Method.PUT,
      `/api/admin/temperatureImpact/migrateProviders`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  AdminTemplates: {
    /**
     * @description This method is added here since the endpoint in identity service is protected with a resource that the template management tenant doesn't have. If this endpoint is needed in other places then we should move it to TenantsController. If we move to operation based authorization then this endpoint should be removed. * @summary Gets a list with all the existing tenants.
     */
    getAllTenants: apiEndpointEmpty<TenantResponseModel[], any>(
      Method.POST,
      `/api/admin/templates/tenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description This method always returns 200 so check the property Success in the response model for the actual result. * @summary Publishes templates and other global data to all existing tenants.
     */
    publishToAllTenants: apiEndpointEmpty<PublishToTenantsResponseModel, any>(
      Method.PUT,
      `/api/admin/templates/publishToAllTenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description This method always returns 200 so check the property Success in the response model for the actual result. * @summary Publishes templates and other global data to specific tenants.
     */
    publishToTenants: apiEndpoint<
      PublishToTenantsRequestModel,
      PublishToTenantsResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/admin/templates/publishToTenants`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Alarms: {
    /**
     * @summary Get alarm list
     */
    getAlarmList: apiEndpointEmptyUsingQueryParams<
      AlarmsGetAlarmListParams,
      AlarmsListResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/alarms`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getAlarmHistory: apiEndpointEmptyUsingQueryParams<
      AlarmsGetAlarmHistoryParams,
      AlarmsListResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/alarms/history`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    acknowledgeAlarm: apiEndpoint<
      AcknowledgeAlarmRequestModel,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/alarms/acknowledge`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    acknowledgeAllAlarms: apiEndpoint<
      AcknowledgeAllAlarmsRequestModel,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/alarms/acknowledgeAll`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns the alarm signals matching the supplied alarm signal ids.
     */
    getAlarmSignals: apiEndpoint<
      GetAlarmSignalsByIdRequestModel,
      AlarmSignalResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/alarms/alarmSignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns the alarm signals matching the supplied device name and signal type identifiers.
     */
    getAlarmSignalsByDeviceNameAndSignalTypeId: apiEndpoint<
      GetAlarmSignalsByDeviceNameAndSignalTypeIdRequestModel,
      AlarmSignalResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/alarms/alarmSignals/byDeviceNameAndSignalTypeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Backend: {
    /**
     * @summary Changes the Log Level without restart.
     */
    changeLogLevel: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      BackendChangeLogLevelPath
    >(
      Method.PUT,
      ({ logEventLevel }: BackendChangeLogLevelPath) =>
        `/api/admin/backend/LogLevel/${logEventLevel}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Log message
     */
    logMessage: apiEndpointEmptyWithPath<
      void,
      ProblemDetails,
      BackendLogMessagePath
    >(
      Method.PUT,
      ({ logEventLevel, message }: BackendLogMessagePath) =>
        `/api/admin/backend/LogLevel/${logEventLevel}/message/${message}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Log message
     */
    getConfiguration: apiEndpointEmpty<
      KeyValuePairOfStringAndString[],
      ProblemDetails
    >(
      Method.GET,
      `/api/admin/backend/Configuration`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    clearCache: apiEndpointEmpty<void, ProblemDetails>(
      Method.PUT,
      `/api/admin/cache/clear/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    clearCache2: apiEndpoint<ClearCacheRequestModel, void, ProblemDetails>(
      Method.PUT,
      `/api/admin/cache/clear`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    enableCache: apiEndpointEmpty<void, ProblemDetails>(
      Method.PUT,
      `/api/admin/cache/enable`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    disableCache: apiEndpointEmpty<void, ProblemDetails>(
      Method.PUT,
      `/api/admin/cache/disable`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Comfort: {
    /**
     * @summary Gets the comfort heating buildings for the supplied node ids.
     */
    getComfortHeatingBuildingsByNodeId: apiEndpoint<
      GetComfortHeatingBuildingsByNodeIdRequestModel,
      ComfortHeatingBuildingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/buildings/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the comfort heating buildings for the supplied facility ids.
     */
    getComfortHeatingBuildingsByFacilityId: apiEndpoint<
      GetComfortHeatingBuildingsByFacilityIdRequestModel,
      ComfortHeatingBuildingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/buildings/byFacilityId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all comfort heating enabled buildings.
     */
    getAllComfortHeatingBuildings: apiEndpointEmpty<
      ComfortHeatingBuildingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/buildings/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Adds new values to a comfort signal. If the id of a non-comfort signal is given, the request will not be allowed.
If the comfort service is in maintenance mode, it is not allowed to set a new value.
*/
    addComfortSignalValues: apiEndpoint<
      AddEquipmentSignalValuesRequestModel,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/signals/values`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns the last values for the provided signals.
     */
    getLastComfortSignalValues: apiEndpoint<
      GetLatestEquipmentSignalValuesRequestModel,
      TelemetryResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/signals/values/last`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Returns a range of values for a given set of signals. It is possible to aggregate the values in various
way as well as group values by interval.
*/
    getComfortSignalValuesByTimeRange: apiEndpoint<
      GetEquipmentSignalValuesByTimeRangeRequestModel,
      TelemetryResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/signals/values/timerange`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets active comfort alarms for a certain node.
     */
    getComfortAlarmHistory: apiEndpoint<
      GetComfortAlarmHistoryRequestModel,
      ComfortAlarmsListResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/alarms/history`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets active comfort alarms for a certain node.
     */
    getActiveComfortAlarms: apiEndpoint<
      GetComfortAlarmsRequestModel,
      ComfortAlarmsListResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/alarms/active`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets inactive comfort alarms for a certain node.
     */
    getInactiveComfortAlarms: apiEndpoint<
      GetComfortAlarmsRequestModel,
      ComfortAlarmsListResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/alarms/inactive`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateComfortNotificationReceivers: apiEndpoint<
      AddOrUpdateComfortNotificationReceiversRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/comfort/buildings/notificationreceivers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getComfortNotificationReceivers: apiEndpoint<
      GetComfortNotificationReceiversRequestModel,
      ComfortNotificationReceiversResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/comfort/buildings/notificationreceivers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deleteComfortNotificationReceivers: apiEndpoint<
      DeleteComfortNotificationReceiversRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/comfort/buildings/notificationreceivers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Dashboard: {
    /**
     * @summary Returns all node ID to dashboard collection ID relations that are defined.
     */
    getDashboardCollectionViewRelations: apiEndpointEmpty<
      DashboardCollectionNodeRelationsResponseModel,
      any
    >(
      Method.POST,
      `/api/dashboards/collections/view/relations`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Gets the default dashboard collection that should be used when there is no specific collection
associated with a node.
*/
    getDefaultDashboardCollectionView: apiEndpointEmpty<
      DashboardCollectionViewResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/dashboards/collections/view/default`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Gets the dashboard collection that is related to a specific node ID. If no
related collection is found, try to return the default collection.
*/
    getDashboardCollectionViewByNodeId: apiEndpoint<
      GetDashboardCollectionViewByNodeIdRequestModel,
      DashboardCollectionViewResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/dashboards/collections/view/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Development: {
    resetService: apiEndpoint<ResetServiceRequestModel, void, ProblemDetails>(
      Method.POST,
      `/api/admin/resetService`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    clearCache: apiEndpointEmpty<void, ProblemDetails>(
      Method.PUT,
      `/api/admin/clearCache/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    clearCache2: apiEndpoint<ClearCacheRequestModel, void, ProblemDetails>(
      Method.PUT,
      `/api/admin/clearCache`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Devices: {
    /**
     * @summary Sets the device signals via a direct method.
     * @deprecated
     */
    setSignal: apiEndpoint<
      SetSignalRequestModel,
      SetSignalResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/devices/device/message/setsignal`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Sets multiple device signals via direct method.
     * @deprecated
     */
    setSignalAll: apiEndpoint<
      SetSignalsRequestModel,
      SetSignalResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/devices/device/message/setsignals`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Sets the device signals via a direct method and also creates an audit log.
     */
    setSignalWithAudit: apiEndpoint<
      SetSignalWithAuditRequestModel,
      SetSignalResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/devices/device/message/setSignalWithAudit`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Sets multiple device signals via direct method and also creates an audit log.
     */
    setSignalsWithAudit: apiEndpoint<
      SetSignalsWithAuditRequestModel,
      SetSignalResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/devices/device/message/setSignalsWithAudit`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    liveTelemetry: apiEndpoint<LiveTelemetryRequestModel, void, ProblemDetails>(
      Method.POST,
      `/api/devices/device/message/livetelemetry`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  DisaIntegration: {
    /**
     * @summary Creates or updates a Disa integration provider
     */
    addOrUpdateDisaIntegrationProvider: apiEndpoint<
      AddOrUpdateDisaIntegrationProviderRequestModel[],
      DisaIntegrationProviderResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/disaIntegration/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Get Disa integration providers
     */
    getDisaIntegrationProvider: apiEndpointEmptyUsingQueryParams<
      DisaIntegrationGetDisaIntegrationProviderParams,
      DisaIntegrationProviderResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/disaIntegration/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description This method should not be removed since ML calls it to create new providers! * @summary Makes sure that all existing buildings with a facilityId, which also is defined in the Disa system, will
get a Disa Signal Provider
*/
    migrateDisaIntegrationProviders: apiEndpointEmpty<void, any>(
      Method.PUT,
      `/api/disaIntegration/migrateDisaIntegrationProviders`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  EIoTSignals: {
    getSignalValues: apiEndpointEmptyUsingQueryParams<
      EIoTSignalsGetSignalValuesParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/eiot-signals/values`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalValuesByTimeRange: apiEndpointEmptyUsingQueryParams<
      EIoTSignalsGetSignalValuesByTimeRangeParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/eiot-signals/values/timerange`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalValuesByTimeInterval: apiEndpointEmptyUsingQueryParams<
      EIoTSignalsGetSignalValuesByTimeIntervalParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/eiot-signals/values/timeinterval`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getLastSignalValues: apiEndpoint<
      GetLatestSignalValuesRequestModel,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/eiot-signals/values/last`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Enums: {
    /**
     * @description The enums returned include only allowed enum values. In general enum value 0 (called Unknown) is reserved and is not allowed to be used in an API, therefore here it will not be returned. * @summary Returns all Enums and other fixed values used in the gateway.
     */
    getEnumsAndFixedConfigurations: apiEndpointEmpty<
      GetEnumsAndFixedConfigurationsResponseModel,
      any
    >(
      Method.GET,
      `/api/enums`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Equipments: {
    getEquipmentTypes: apiEndpointEmpty<EquipmentTypeResponseModel[], any>(
      Method.GET,
      `/api/equipments/equipmentTypes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  LoBa: {
    /**
     * @summary Gets the LoBa buildings for the supplied node ids.
     */
    getLoBaBuildingsByNodeId: apiEndpoint<
      GetLoBaBuildingsByNodeIdRequestModel,
      LoBaBuildingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/loba/buildings/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the LoBa buildings for the supplied facility ids.
     */
    getLoBaBuildingsByFacilityId: apiEndpoint<
      GetLoBaBuildingsByFacilityIdRequestModel,
      LoBaBuildingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/loba/buildings/byFacilityId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets all LoBa enabled buildings.
     */
    getAllLoBaBuildings: apiEndpointEmpty<
      LoBaBuildingResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/loba/buildings/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Meteorology: {
    /**
     * @description The calculation will include today's sun information and a given number of days afterwards in a list. If date is not given, current date will be used. If number of days is not given, only today will be returned in the list. * @summary Get sun information based on a the number of days needed and longitude/latitude.
     */
    getSunInfoForDate: apiEndpointEmptyUsingQueryParams<
      MeteorologyGetSunInfoForDateParams,
      SunriseSunsetResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/meteorology/twilight`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Requires: string name, double longitude, double latitude, string[] nodeIds Will be added as a Signal Provider * @summary Create and register Geographical Point
     */
    addOrUpdateGeographicalPoint: apiEndpoint<
      AddGeographicalPointRequestModel,
      GeographicalPointResponseModel,
      ProblemDetails
    >(
      Method.PUT,
      `/api/meteorology/point`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @description It doesn't validate if the point is used anywhere before deleting so use this endpoint with caution. The primary goal was to be able to delete an incorrect point after it was created. * @summary Deletes the geographical points with the supplied ids. It will of course also delete
the related signal providers.
*/
    deleteGeographicalPoints: apiEndpoint<
      DeleteGeographicalPointsRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/meteorology/points`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getGeographicalPoints: apiEndpointEmpty<
      GeographicalPointResponseModel[],
      any
    >(
      Method.GET,
      `/api/meteorology/points`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    updateForecastDatabase: apiEndpointEmpty<void, any>(
      Method.POST,
      `/api/meteorology/forecast`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    updateMeteomaticsForecastDatabase: apiEndpointEmpty<void, any>(
      Method.POST,
      `/api/meteorology/meteomatics-forecast`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Nodes: {
    getGrids: apiEndpointEmpty<string[], any>(
      Method.GET,
      `/api/nodes/grids`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description Equipment nodes will not be returned unless you explicitly search for them via the node id. * @summary Get nodes based on a set of parameters
     */
    getNodes: apiEndpointEmptyUsingQueryParams<
      NodesGetNodesParams,
      NodeResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/nodes/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodesByDistrictHeatingFacilityId: apiEndpointEmptyUsingQueryParams<
      NodesGetNodesByDistrictHeatingFacilityIdParams,
      SimpleNodeResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/nodes/nodes/byDistrictHeatingFacilityId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodesByBuildingStatus: apiEndpointEmptyUsingQueryParams<
      NodesGetNodesByBuildingStatusParams,
      SimpleNodeResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/nodes/nodes/byBuildingStatus`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodeTags: apiEndpointEmpty<string[], any>(
      Method.GET,
      `/api/nodes/tags`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodesWithAllParents: apiEndpoint<
      GetNodesWithAllParentsRequestModel,
      NodeWithAllParentsResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/nodes/withAllParents`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  PowerControl: {
    addOrUpdatePowerControlProvider: apiEndpoint<
      AddOrUpdatePowerControlProviderRequestModel[],
      PowerControlProviderResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/powercontrols`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getPowerControlByProviderId: apiEndpointEmptyUsingQueryParams<
      PowerControlGetPowerControlByProviderIdParams,
      PowerControlResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/powercontrols/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getPowerControlByNodeV2: apiEndpoint<
      GetPowerControlByNodeV2RequestModel,
      GetPowerControlByNodeV2ResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/powercontrols/nodes/powerControlsForNode`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getPowerControlByNode: apiEndpointEmptyUsingQueryParams<
      PowerControlGetPowerControlByNodeParams,
      PowerControlResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/powercontrols/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdatePowerControlSchedule: apiEndpoint<
      AddOrUpdatePowerControlScheduleRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/powercontrols/schedules`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getPowerControlSchedule: apiEndpointEmptyUsingQueryParams<
      PowerControlGetPowerControlScheduleParams,
      ScheduleCollectionResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/powercontrols/schedules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    deletePowerControlSchedule: apiEndpoint<
      DeletePowerControlScheduleRequestModel[],
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/powercontrols/schedules`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    abortPowerControlSchedule: apiEndpoint<
      AbortPowerControlScheduleRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/powercontrols/schedules/abort`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    sendAllSchedulesToDevices: apiEndpointEmpty<void, any>(
      Method.PUT,
      `/api/powercontrols/schedules/process`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    addLimitAndAmplitudeValues: apiEndpoint<
      AddLimitAndAmplitudeValuesRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/powercontrols/signalvalues`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    addLimitAndAmplitudeValuesWithAudit: apiEndpoint<
      AddLimitAndAmplitudeValuesWithAuditRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/powercontrols/signalvaluesWithAudit`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  RemoteOptimisation: {
    addOrUpdateLinearOptimisationProvider: apiEndpoint<
      AddOrUpdateLinearOptimisationRequestModel[],
      LinearOptimisationProviderResponseModel[],
      ProblemDetails
    >(
      Method.PUT,
      `/api/remoteoptimisations/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getLinearOptimisationByProvider: apiEndpointEmptyUsingQueryParams<
      RemoteOptimisationGetLinearOptimisationByProviderParams,
      LinearOptimisationResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/remoteoptimisations/linearoptimisations/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getLinearOptimisationsByNodeV2: apiEndpoint<
      GetLinearOptimisationByNodeRequestModel,
      LinearOptimisationV2ResponseModel,
      ProblemDetails
    >(
      Method.POST,
      `/api/remoteoptimisations/nodes/linearOptimisationsForNode`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getLinearOptimisationByNode: apiEndpointEmptyUsingQueryParams<
      RemoteOptimisationGetLinearOptimisationByNodeParams,
      LinearOptimisationResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/remoteoptimisations/linearoptimisations/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    setLinearOptimisationSignals: apiEndpoint<
      SetLinearOptimisationSignalsRequestModel[],
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/remoteoptimisations/signalvalues`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Schedules: {
    /**
     * @summary Adds or updates the supplied master schedules.
     */
    addOrUpdateMasterSchedules: apiEndpoint<
      AddOrUpdateMasterScheduleRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/masterSchedules`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Returns the schedules matching the supplied filter.
     */
    getSchedules: apiEndpoint<
      GetSchedulesRequestModel,
      ScheduleResponseModel2[],
      ProblemDetails
    >(
      Method.POST,
      `/api/schedules`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Aborts the master schedules in the supplied list.
     */
    abortMasterSchedules: apiEndpoint<
      AbortMasterSchedulesRequestModel,
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/masterSchedules/abort`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Signals: {
    /**
 * @summary Returns signal provider data for all of the supplied signals, along with extended signal information.
Note that the provider object will only contain signal information for the signals you supplied, not all of
the signals the provider owns.
*/
    getProvidersBySignalIds: apiEndpoint<
      GetSignalProvidersBySignalIdsRequestModel,
      FullSignalProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/signals/providers/bySignalIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Returns signal providers that matches the SignalProviderTypes supplied in the request.
     */
    getSignalProviders: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalProvidersParams,
      SignalProviderResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Adds or updates signal providers.
     */
    addOrUpdateSignalProviders: apiEndpoint<
      AddOrUpdateSignalProviderRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @summary Deletes the signal providers matching the supplied identifiers.
     */
    deleteSignalProviders: apiEndpoint<
      DeleteSignalProvidersRequestModel,
      void,
      ProblemDetails
    >(
      Method.DELETE,
      `/api/providers`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getSignalProviderTemplates: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalProviderTemplatesParams,
      SignalProviderTemplateResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/providers/templates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalTemplates: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalTemplatesParams,
      SignalTemplateResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/templates`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getAllProviderSignals: apiEndpointEmptyWithPath<
      FullSignalProviderResponseModel[],
      ProblemDetails,
      SignalsGetAllProviderSignalsPath
    >(
      Method.GET,
      ({ providerId }: SignalsGetAllProviderSignalsPath) =>
        `/api/signals/providers/${providerId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateProviderSignals: apiEndpointWithPath<
      AddOrUpdateSignalRequestModel[],
      void,
      ProblemDetails,
      SignalsAddOrUpdateProviderSignalsPath
    >(
      Method.PUT,
      ({ providerId }: SignalsAddOrUpdateProviderSignalsPath) =>
        `/api/signals/providers/${providerId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getUnits: apiEndpointEmpty<UnitResponseModel[], any>(
      Method.GET,
      `/api/signals/units`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateUnits: apiEndpoint<SetUnitRequestModel[], void, ProblemDetails>(
      Method.PUT,
      `/api/signals/units`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getSignalsByNode: apiEndpoint<
      GetSignalsByNodeRequestModel,
      SignalProviderByNodeResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/signals/nodes`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalValues: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalValuesParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/values`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @description If the signal values belong to Desired signal additional routing will be done so that the data will be sent to the responsible signal provider. Currently if the Desired Signal is of type Equipment the data will be sent to the responsible device. When that happens the TTL provided will be used, or if no TTL is provided the IoTHub default will be used. * @summary Allows for adding signal values to signals.
     */
    addSignalValues: apiEndpoint<
      AddSignalValuesRequestModel,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/signals/values`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @description If the signal values belong to Desired signal additional routing will be done so that the data will be sent to the responsible signal provider. Currently if the Desired Signal is of type Equipment the data will be sent to the responsible device. When that happens the TTL provided will be used, or if no TTL is provided the IoTHub default will be used. * @summary Allows for adding a signal value to a signal.
     */
    addSignalValuesWithAudit: apiEndpoint<
      AddSignalValueWithAuditRequestModel,
      void,
      ProblemDetails
    >(
      Method.POST,
      `/api/signals/valueWithAudit`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getSignalValuesByTimeRange: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalValuesByTimeRangeParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/values/timerange`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalValuesByTimeInterval: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalValuesByTimeIntervalParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/values/timeinterval`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getLastSignalValues: apiEndpoint<
      GetLatestSignalValuesRequestModel,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/signals/values/last`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @deprecated
     */
    getLastSignalValuesAsyncObsolete: apiEndpointEmptyUsingQueryParams<
      SignalsGetLastSignalValuesAsyncObsoleteParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/values/last`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getLastSignalValuesByNodeIds: apiEndpointEmptyUsingQueryParams<
      SignalsGetLastSignalValuesByNodeIdsParams,
      SignalProviderTelemetryResponseModel[],
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/nodes/values/last`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getSignalValuesByTimeRangeExportToCsv: apiEndpointEmptyUsingQueryParams<
      SignalsGetSignalValuesByTimeRangeExportToCsvParams,
      string,
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/values/csv`,
      apiTitle,
      ContentType.Json,
      ContentType.Text,
      ContentType.Json
    ),
    /**
     * @summary Gets the signal exports that are configured for the logged in user.
     */
    getSignalExports: apiEndpointEmpty<SignalExportResponseModel[], any>(
      Method.GET,
      `/api/signals/exports`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Adds one or several signal exports for the currently logged in user.
The export is not done immediately. A mail will be sent to the user when the export has completed.
*/
    addSignalExports: apiEndpoint<
      AddSignalExportRequestModel[],
      void,
      ProblemDetails
    >(
      Method.PUT,
      `/api/signals/exports`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
 * @summary Generates a download link to the file blob and returns the url. Use this url to make a redirect.
The download link has a limited lifetime.
*/
    downloadSignalExport: apiEndpointEmptyUsingQueryParams<
      SignalsDownloadSignalExportParams,
      DownloadSignalExportResponseModel,
      ProblemDetails
    >(
      Method.GET,
      `/api/signals/exports/download`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
 * @summary Processes all the configured signal exports. This is normally done automatically by a
scheduled job so this endpoint should be used only for debugging and testing purposes!
*/
    processSignalExports: apiEndpointEmpty<void, any>(
      Method.PUT,
      `/api/signals/exports/process`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    deleteSignals: apiEndpoint<DeleteSignalsRequestModel, void, ProblemDetails>(
      Method.DELETE,
      `/api/signals`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  SignalTypeFolders: {
    /**
     * @summary Gets all the signal type folders.
     */
    getAllSignalTypeFolders: apiEndpointEmpty<
      SignalTypeFolderResponseModel[],
      any
    >(
      Method.POST,
      `/api/signalTypeFolders/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  SignalTypes: {
    /**
     * @summary Gets all the signal types.
     */
    getAllSignalTypes: apiEndpointEmpty<SignalTypeResponseModel[], any>(
      Method.POST,
      `/api/signalTypes/all`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  SignalViews: {
    /**
     * @summary Gets the data necessary for the signal view for the specified equipment.
     */
    getEquipmentSignalViews: apiEndpoint<
      GetSignalViewsRequestModel,
      EquipmentSignalViewResponseModel[],
      any
    >(
      Method.POST,
      `/api/equipmentSignalViews`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the data necessary for the signal view for the specified node.
     */
    getNodeSignalViews: apiEndpoint<
      GetNodeSignalViewsRequestModel,
      NodeSignalViewResponseModel[],
      any
    >(
      Method.POST,
      `/api/nodeSignalViews`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  TemperatureImpact: {
    /**
     * @summary Gets the temperature impact providers for the supplied node identifiers.
     */
    getTemperatureImpactProvidersByNodeId: apiEndpoint<
      GetTemperatureImpactProvidersByNodeIdRequestModel,
      TemperatureImpactProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/temperatureImpact/providers/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Gets the temperature impact providers for the supplied provider identifiers.
     */
    getTemperatureImpactProvidersByProviderId: apiEndpoint<
      GetTemperatureImpactProvidersByProviderIdRequestModel,
      TemperatureImpactProviderResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/temperatureImpact/providers/byProviderId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @summary Calculates a preview of the temperature impact based on the supplied schedules.
     */
    getTemperatureImpactPreview: apiEndpoint<
      GetTemperatureImpactPreviewRequestModel[],
      TemperatureImpactPreviewResponseModel[],
      ProblemDetails
    >(
      Method.POST,
      `/api/temperatureImpact/preview`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Tenants: {
    /**
 * @summary Creates a new database and performs all the migrations for the tenant with the supplied identifier.
The name of the created database will be the value that is passed in tenantId.
If the database already exists then nothing will happen.
*/
    addTenant: apiEndpointEmptyUsingQueryParams<
      TenantsAddTenantParams,
      void,
      any
    >(
      Method.PUT,
      `/api/tenants/addTenant`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  NodesV2: {
    addOrUpdateNodes: apiEndpoint<AddOrUpdateNodesRequestModel, void, any>(
      Method.PUT,
      `/api/nodes/v2`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getNodeChildren: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodeChildrenParams,
      NodeV2ResponseModel[],
      any
    >(
      Method.GET,
      `/api/nodes/v2/children`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodeAndAncestors: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodeAndAncestorsParams,
      NodeV2ResponseModel[],
      any
    >(
      Method.GET,
      `/api/nodes/v2/ancestors`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodeValueSuggestions: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodeValueSuggestionsParams,
      NodePropertyValueSuggestionsResponseModel,
      any
    >(
      Method.GET,
      `/api/nodes/v2/values/uniqueValues`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    searchForNodes: apiEndpoint<
      SearchNodesRequestModel,
      NodeSearchResultsResponseModel,
      any
    >(
      Method.POST,
      `/api/nodes/v2/search`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodesByIds: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodesByIdsParams,
      GetNodesByIdResponseModelV2,
      any
    >(
      Method.GET,
      `/api/nodes/v2/byIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listNodeTraits: apiEndpointEmpty<NodeTraitResponseModel[], any>(
      Method.GET,
      `/api/nodes/v2/traits/list`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodeTraitsByIds: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodeTraitsByIdsParams,
      NodeTraitResponseModel[],
      any
    >(
      Method.GET,
      `/api/nodes/v2/traits/byIds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodeProperties: apiEndpointEmpty<NodePropertyResponseModel[], any>(
      Method.GET,
      `/api/nodes/v2/properties`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateNodeProperties: apiEndpoint<
      AddOrUpdateNodePropertiesRequestModel,
      void,
      any
    >(
      Method.PUT,
      `/api/nodes/v2/properties`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    addOrUpdateNodeTraits: apiEndpoint<
      AddOrUpdateNodeTraitsRequestModel,
      void,
      any
    >(
      Method.PUT,
      `/api/nodes/v2/traits`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    getNodePropertyValuesWithProperties: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodePropertyValuesWithPropertiesParams,
      NodePropertyAndValueResponseModel[],
      any
    >(
      Method.GET,
      `/api/nodes/v2/valuesWithProperties/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    getNodePropertyValues: apiEndpointEmptyUsingQueryParams<
      NodesV2GetNodePropertyValuesParams,
      NodePropertyValueResponseModel[],
      any
    >(
      Method.GET,
      `/api/nodes/v2/values/byNodeId`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    addOrUpdateNodePropertyValues: apiEndpoint<
      AddOrUpdateNodePropertyValuesRequestModel,
      void,
      any
    >(
      Method.PUT,
      `/api/nodes/v2/values`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    migrateTraits: apiEndpointEmpty<string[], any>(
      Method.POST,
      `/api/nodes/v2/traits/migrate`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default APIGen;
