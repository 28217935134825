import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';
import colors from 'ecto-common/lib/styles/variables/colors';
import StockChart from 'ecto-common/lib/Charts/StockChart';
import ScatterChart from 'ecto-common/lib/Charts/ScatterChart';
import {
  arrayMergerHighcharts,
  ChartSignal,
  createScatterChartConfigFromSeries,
  createStockChartConfigFromSeries,
  TelemetrySeries
} from 'ecto-common/lib/SignalSelector/ChartUtils';
import useInterval from 'ecto-common/lib/hooks/useInterval';
import useReloadTrigger from 'ecto-common/lib/hooks/useReloadTrigger';
import {
  GraphSettingsType,
  GraphType
} from 'ecto-common/lib/types/EctoCommonTypes';
import { TelemetryZoomRange } from 'js/modules/signalCollections/signalCollections';
import { HighchartsReactRefObject } from 'highcharts-react-official';

type GraphChartProps = {
  graphType?: GraphType;
  graphSettings: GraphSettingsType;
  selectedXAxisScatterSignal?: ChartSignal;
  hasError: boolean;
  isLoading: boolean;
  telemetrySeries: TelemetrySeries[];
  sparseTelemetrySeries: TelemetrySeries[];
  hasPointsOverflow: boolean;
  zoomRange: TelemetryZoomRange;
  setCustomZoomRange: (zoomRange: TelemetryZoomRange) => void;
  enableDataAnalytics?: boolean;
};

// TODO: MUST IMPLEMENT AUTO UPDATE

const GraphChart = React.forwardRef<HighchartsReactRefObject, GraphChartProps>(
  (
    {
      graphType = null,
      graphSettings,
      selectedXAxisScatterSignal,
      hasError,
      isLoading,
      telemetrySeries,
      sparseTelemetrySeries,
      hasPointsOverflow,
      zoomRange,
      setCustomZoomRange,
      enableDataAnalytics = false
    }: GraphChartProps,
    ref
  ) => {
    const _onDateChange = useCallback(
      (dateFrom: number, dateTo: number, userDidZoom: boolean) => {
        if (userDidZoom) {
          setCustomZoomRange({ dateFrom, dateTo, seriesInterval: null });
        }
      },
      [setCustomZoomRange]
    );

    const dateFrom = zoomRange?.dateFrom;
    const dateTo = zoomRange?.dateTo;

    const [reloadTrigger, triggerReload] = useReloadTrigger();
    useInterval(triggerReload, 60000); // 1 minute

    const config = useMemo(() => {
      // for eslint; reloadTrigger should trigger a new memo value
      _.noop(reloadTrigger);

      switch (graphType) {
        case GraphType.SCATTER: {
          const xAxisSeries = _.find(telemetrySeries, [
            'chartSignalId',
            selectedXAxisScatterSignal?.chartSignalId
          ]);
          return createScatterChartConfigFromSeries(
            telemetrySeries,
            sparseTelemetrySeries,
            xAxisSeries,
            graphSettings?.minMaxSettings
          );
        }
        case GraphType.LINE: {
          return _.mergeWith(
            {},
            createStockChartConfigFromSeries(
              telemetrySeries,
              sparseTelemetrySeries,
              graphSettings?.minMaxSettings
            ),
            {
              // Current time
              xAxis: {
                plotLines: [
                  {
                    color: colors.accent5Color,
                    width: 2,
                    dashStyle: 'dash',
                    value: new Date().getTime()
                  }
                ]
              }
            },
            arrayMergerHighcharts
          );
        }
        default:
          return createStockChartConfigFromSeries(
            telemetrySeries,
            sparseTelemetrySeries,
            graphSettings?.minMaxSettings
          );
      }
    }, [
      reloadTrigger,
      graphType,
      telemetrySeries,
      sparseTelemetrySeries,
      selectedXAxisScatterSignal?.chartSignalId,
      graphSettings
    ]);

    const seriesIds = useMemo(() => {
      let series = [...(telemetrySeries ?? [])];

      if (graphType === GraphType.SCATTER) {
        const xAxisSeries = _.find(telemetrySeries, [
          'chartSignalId',
          selectedXAxisScatterSignal?.chartSignalId
        ]);
        series = _.without(series, xAxisSeries);
      }

      return _.map(series, (item) => item.chartSignalId);
    }, [telemetrySeries, graphType, selectedXAxisScatterSignal?.chartSignalId]);

    return (
      <>
        {graphType === GraphType.LINE && (
          <StockChart
            seriesIds={seriesIds}
            dateFrom={dateFrom}
            dateTo={dateTo}
            hasError={hasError}
            onExtremesChange={_onDateChange}
            config={config}
            isLoading={isLoading}
            hasPointsOverflow={hasPointsOverflow}
            ref={ref}
            enableDataAnalytics={enableDataAnalytics}
          />
        )}

        {graphType === GraphType.SCATTER && (
          <ScatterChart
            seriesIds={seriesIds}
            hasError={hasError}
            config={config}
            isLoading={isLoading}
            hasPointsOverflow={hasPointsOverflow}
            ref={ref}
          />
        )}
      </>
    );
  }
);

export default React.memo(GraphChart);
