/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface BuildCreateRequest {
  projectId: string;
  sourceBuildId?: string | null;
  startBuild?: boolean | null;
  description: string;
  checksum?: string | null;
  calculateMasterBuild?: boolean | null;
}

export enum BuildGraphAggregation {
  Sum = 'Sum',
  Average = 'Average'
}

export interface BuildGraphResponse {
  name?: string | null;
  series?: BuildGraphSeries[] | null;
  graphType?: BuildGraphType;
  scatterPlotXAxisSeriesId?: string | null;
  buildId?: string | null;
  id?: string | null;
}

export interface BuildGraphResponseListResponse {
  items?: BuildGraphResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface BuildGraphSeries {
  id?: string | null;
  aggregation?: BuildGraphAggregation;
}

export enum BuildGraphType {
  Line = 'Line',
  Column = 'Column',
  Scatter = 'Scatter',
  DurationCurve = 'DurationCurve'
}

export interface BuildGraphUpsertRequest {
  name?: string | null;
  series?: BuildGraphSeries[] | null;
  scatterPlotXAxisSeriesId?: string | null;
  graphType?: BuildGraphType;
  buildId?: string | null;
  id?: string | null;
}

export interface BuildRecalculateRequest {
  Checksum: string;
}

export interface BuildResponse {
  id?: string | null;
  isMasterBuild?: boolean;
  checksum?: string | null;
  description?: string | null;
  projectName?: string | null;
  projectId?: string | null;
  status?: string | null;
  /** @format date-time */
  created?: string;
  /** @format date-time */
  lastUpdated?: string;
}

export interface BuildResponseListResponse {
  items?: BuildResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface FileInformation {
  filename?: string | null;
  /** @format uri */
  link?: string | null;
  /** @format date-time */
  lastModified?: string | null;
  userDisplayName?: string | null;
  /** @format int64 */
  contentLength?: number | null;
}

export interface FileResponse {
  filename?: string | null;
  /** @format uri */
  link?: string | null;
  /** @format date-time */
  lastModified?: string | null;
  userDisplayName?: string | null;
  /** @format int64 */
  contentLength?: number | null;
}

export interface FileResponseListResponse {
  items?: FileResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface ProblemDetails {
  type?: string | null;
  title?: string | null;
  /** @format int32 */
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
}

export interface ProjectInsertRequest {
  name: string;
  projectType?: string | null;
  /**
   * @format double
   * @min -80
   * @max 80
   */
  longitude?: number;
  /**
   * @format double
   * @min -180
   * @max 180
   */
  latitude?: number;
  /**
   * @format int32
   * @min 2000
   * @max 2030
   */
  exampleWeatherYear?: number;
  copySourceId?: string | null;
  copyWeatherStationId?: string | null;
}

export interface ProjectResponse {
  id?: string | null;
  name?: string | null;
  projectType?: string | null;
  /** @format double */
  longitude?: number;
  /** @format double */
  latitude?: number;
  /** @format int32 */
  exampleWeatherYear?: number;
  /** @format date-time */
  created?: string | null;
  weatherStation?: WeatherStationResponse;
}

export interface ProjectResponseListResponse {
  items?: ProjectResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface ProjectUpdateNameRequest {
  name: string;
}

export interface ProjectUpsertRequest {
  name: string;
  projectType?: string | null;
  /**
   * @format double
   * @min -80
   * @max 80
   */
  longitude?: number;
  /**
   * @format double
   * @min -180
   * @max 180
   */
  latitude?: number;
  /**
   * @format int32
   * @min 2000
   * @max 2030
   */
  exampleWeatherYear?: number;
  copySourceId?: string | null;
  copyWeatherStationId?: string | null;
  /** @pattern ^[A-Za-z0-9-]{5,50}$ */
  id: string;
}

export interface SetBuildDescriptionRequest {
  description: string;
}

export interface SetWeatherRequest {
  weatherStationId: string;
}

export interface UserResponse {
  displayName?: string | null;
  mail?: string | null;
}

export interface UserResponseListResponse {
  items?: UserResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface UserUpdateRequest {
  mailaddresses?: string[] | null;
}

export interface WeatherCountryResponse {
  countryCode?: string | null;
  countryName?: string | null;
}

export interface WeatherCountryResponseListResponse {
  items?: WeatherCountryResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface WeatherRegionResponse {
  regionCode?: string | null;
  regionName?: string | null;
}

export interface WeatherRegionResponseListResponse {
  items?: WeatherRegionResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface WeatherStationResponse {
  id?: string | null;
  city?: string | null;
  countryCode?: string | null;
  regionCode?: string | null;
  regionName?: string | null;
  /** @deprecated */
  subRegion?: string | null;
  /** @format double */
  longitude?: number;
  /** @format double */
  latitude?: number;
  filename?: string | null;
  epwFilename?: string | null;
  dataYears?: string | null;
  wmoId?: string | null;
  /** @format double */
  altitude?: number;
}

export interface WeatherStationResponseListResponse {
  items?: WeatherStationResponse[] | null;
  continuationToken?: string | null;
  links?: Record<string, string>;
  /** @format int32 */
  total?: number | null;
}

export interface ListProjectsParams {
  $filter?: any;
  $top?: number;
  /** Order by */
  $orderby?: any;
}

export interface ListProjectsV2Params {
  continuationToken?: string;
  $filter?: any;
  $top?: number;
  /** Order by */
  $orderby?: any;
}

export interface EctoGridProjectsBuildsDetailParams {
  $filter?: any;
  $top?: number;
  /** Order by */
  $orderby?: any;
  projectId: string;
}

export interface UserListParams {
  email?: string;
}

export interface WeatherStationsListParams {
  continuationToken?: string;
  $filter?: any;
  $search?: string;
  $top?: number;
  /** Order by */
  $orderby?: any;
}

export interface WeatherCountriesListParams {
  $filter?: any;
  $top?: number;
  /** Order by */
  $orderby?: any;
}

export interface WeatherCountriesRegionsDetailParams {
  $filter?: any;
  $top?: number;
  /** Order by */
  $orderby?: any;
  countryCode: string;
}

/**
 * @title EON.EctoPlanner
 * @version 1.0
 */

import { APIGenType } from './APIGenType';
import {
  ContentType,
  Method,
  apiEndpoint,
  apiEndpointEmpty,
  apiEndpointEmptyUsingQueryParams,
  apiEndpointEmptyWithPath,
  apiEndpointEmptyWithPathUsingQueryParams,
  apiEndpointWithPath
} from './APIUtils';

const apiTitle = APIGenType.EctoplannerAPIGen;

export interface GetBuildPath {
  buildId: string;
}

export interface EctoGridBuildsDeletePath {
  buildId: string;
}

export interface EctoGridBuildsFilesDetailPath {
  buildId: string;
}

export interface EctoGridBuildsFilesDetail2Path {
  buildId: string;
  filename: string;
}

export interface EctoGridBuildsTimeseriesDetailPath {
  buildId: string;
  filename: string;
}

export interface EctoGridBuildsFormdataDetailPath {
  buildId: string;
}

export interface EctoGridBuildsExcelfileDetailPath {
  buildId: string;
}

export interface EctoGridBuildsExcelfilelinkDetailPath {
  buildId: string;
}

export interface EctoGridBuildsResultsDetailPath {
  buildId: string;
}

export interface EctoGridBuildsGraphsDetailPath {
  buildId: string;
}

export interface EctoGridBuildsDescriptionCreatePath {
  buildId: string;
}

export interface EctoGridBuildsGraphsUpdatePath {
  buildId: string;
  graphId: string;
}

export interface EctoGridBuildsGraphsDeletePath {
  buildId: string;
  graphId: string;
}

export interface EctoGridProjectsNameCreatePath {
  projectId: string;
}

export interface PutProjectPath {
  projectId: string;
}

export interface EctoGridProjectsDetailPath {
  projectId: string;
}

export interface EctoGridProjectsDeletePath {
  projectId: string;
}

export interface EctoGridProjectsUsersDetailPath {
  projectId: string;
}

export interface EctoGridProjectsUsersCreatePath {
  projectId: string;
}

export interface EctoGridProjectsUsersDeletePath {
  projectId: string;
}

export interface EctoGridProjectsWeatherCreatePath {
  projectId: string;
}

export interface EctoGridProjectsTimeseriesfileUpdatePath {
  projectId: string;
}

export interface EctoGridProjectsTimeseriesfileCreatePath {
  projectId: string;
}

export interface EctoGridProjectsTimeseriesfileUpdate2Path {
  projectId: string;
  filename: string;
}

export interface EctoGridProjectsTimeseriesfileDetailPath {
  projectId: string;
  filename: string;
}

export interface EctoGridProjectsFormdataUpdatePath {
  projectId: string;
}

export interface EctoGridProjectsFormdataDetailPath {
  projectId: string;
}

export interface EctoGridProjectsFormdatalinkDetailPath {
  projectId: string;
}

export interface EctoGridProjectsFilesDetailPath {
  projectId: string;
}

export interface EctoGridProjectsExportDetailPath {
  projectId: string;
}

export interface EctoGridProjectsBuildsExportDetailPath {
  projectId: string;
  buildId: string;
}

export interface EctoGridProjectsBuildsFormdataUpdatePath {
  projectId: string;
  buildId: string;
}

export interface EctoGridProjectsBuildsRecalculateCurrentVersionCreatePath {
  projectId: string;
  buildId: string;
}

export interface EctoGridProjectsBuildsMasterBuildCreatePath {
  projectId: string;
}

export interface CreateBuildPath {
  projectId: string;
}

export interface EctoGridProjectsBuildsDetailPath {
  projectId: string;
}

export interface WeatherCountriesRegionsDetailPath {
  countryCode: string;
}

export interface WeatherCityDataDetailPath {
  weatherStationId: string;
}

export interface WeatherAirTempDetailPath {
  weatherStationId: string;
}

export interface WeatherWeatherDataDetailPath {
  weatherStationId: string;
}

const EctoplannerAPIGen = {
  EctoGridBuilds: {
    getBuild: apiEndpointEmptyWithPath<
      BuildResponse,
      void | ProblemDetails,
      GetBuildPath
    >(
      Method.GET,
      ({ buildId }: GetBuildPath) => `/api/EctoGridBuilds/${buildId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    delete: apiEndpointEmptyWithPath<
      void,
      void | ProblemDetails,
      EctoGridBuildsDeletePath
    >(
      Method.DELETE,
      ({ buildId }: EctoGridBuildsDeletePath) =>
        `/api/EctoGridBuilds/${buildId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    filesDetail: apiEndpointEmptyWithPath<
      FileResponseListResponse,
      void | ProblemDetails,
      EctoGridBuildsFilesDetailPath
    >(
      Method.GET,
      ({ buildId }: EctoGridBuildsFilesDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/files`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    /**
     * @originalName ectoGridBuildsFilesDetail
     * @duplicate
     */
    filesDetail2: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      EctoGridBuildsFilesDetail2Path
    >(
      Method.GET,
      ({ buildId, filename }: EctoGridBuildsFilesDetail2Path) =>
        `/api/EctoGridBuilds/${buildId}/files/${filename}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    timeseriesDetail: apiEndpointEmptyWithPath<
      string,
      void | ProblemDetails,
      EctoGridBuildsTimeseriesDetailPath
    >(
      Method.GET,
      ({ buildId, filename }: EctoGridBuildsTimeseriesDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/timeseries/${filename}`,
      apiTitle,
      ContentType.Json,
      ContentType.Text,
      ContentType.Json
    ),
    formdataDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      EctoGridBuildsFormdataDetailPath
    >(
      Method.GET,
      ({ buildId }: EctoGridBuildsFormdataDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/formdata`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    excelfileDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      EctoGridBuildsExcelfileDetailPath
    >(
      Method.GET,
      ({ buildId }: EctoGridBuildsExcelfileDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/excelfile`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    excelfilelinkDetail: apiEndpointEmptyWithPath<
      FileInformation,
      void | ProblemDetails,
      EctoGridBuildsExcelfilelinkDetailPath
    >(
      Method.GET,
      ({ buildId }: EctoGridBuildsExcelfilelinkDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/excelfilelink`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    resultsDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      EctoGridBuildsResultsDetailPath
    >(
      Method.GET,
      ({ buildId }: EctoGridBuildsResultsDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/results`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    graphsDetail: apiEndpointEmptyWithPath<
      BuildGraphResponseListResponse,
      void | ProblemDetails,
      EctoGridBuildsGraphsDetailPath
    >(
      Method.GET,
      ({ buildId }: EctoGridBuildsGraphsDetailPath) =>
        `/api/EctoGridBuilds/${buildId}/graphs`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    descriptionCreate: apiEndpointWithPath<
      SetBuildDescriptionRequest,
      void,
      ProblemDetails | void,
      EctoGridBuildsDescriptionCreatePath
    >(
      Method.POST,
      ({ buildId }: EctoGridBuildsDescriptionCreatePath) =>
        `/api/EctoGridBuilds/${buildId}/description`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    graphsUpdate: apiEndpointWithPath<
      BuildGraphUpsertRequest,
      BuildGraphResponseListResponse,
      void | ProblemDetails,
      EctoGridBuildsGraphsUpdatePath
    >(
      Method.PUT,
      ({ buildId, graphId }: EctoGridBuildsGraphsUpdatePath) =>
        `/api/EctoGridBuilds/${buildId}/graphs/${graphId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    graphsDelete: apiEndpointEmptyWithPath<
      BuildGraphResponseListResponse,
      void | ProblemDetails,
      EctoGridBuildsGraphsDeletePath
    >(
      Method.DELETE,
      ({ buildId, graphId }: EctoGridBuildsGraphsDeletePath) =>
        `/api/EctoGridBuilds/${buildId}/graphs/${graphId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  EctoGridProjects: {
    create: apiEndpoint<
      ProjectInsertRequest,
      ProjectResponse,
      ProblemDetails | void
    >(
      Method.POST,
      `/api/EctoGridProjects`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    listProjects: apiEndpointEmptyUsingQueryParams<
      ListProjectsParams,
      ProjectResponseListResponse,
      void
    >(
      Method.GET,
      `/api/EctoGridProjects`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    nameCreate: apiEndpointWithPath<
      ProjectUpdateNameRequest,
      void,
      ProblemDetails | void,
      EctoGridProjectsNameCreatePath
    >(
      Method.POST,
      ({ projectId }: EctoGridProjectsNameCreatePath) =>
        `/api/EctoGridProjects/${projectId}/name`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    putProject: apiEndpointWithPath<
      ProjectUpsertRequest,
      ProjectResponse,
      ProblemDetails | void,
      PutProjectPath
    >(
      Method.PUT,
      ({ projectId }: PutProjectPath) => `/api/EctoGridProjects/${projectId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    detail: apiEndpointEmptyWithPath<
      ProjectResponse,
      void | ProblemDetails,
      EctoGridProjectsDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsDetailPath) =>
        `/api/EctoGridProjects/${projectId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    delete: apiEndpointEmptyWithPath<
      void,
      void | ProblemDetails,
      EctoGridProjectsDeletePath
    >(
      Method.DELETE,
      ({ projectId }: EctoGridProjectsDeletePath) =>
        `/api/EctoGridProjects/${projectId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    listProjectsV2: apiEndpointEmptyUsingQueryParams<
      ListProjectsV2Params,
      ProjectResponseListResponse,
      void
    >(
      Method.GET,
      `/api/v2/EctoGridProjects`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    usersDetail: apiEndpointEmptyWithPath<
      UserResponseListResponse,
      void,
      EctoGridProjectsUsersDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsUsersDetailPath) =>
        `/api/EctoGridProjects/${projectId}/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    usersCreate: apiEndpointWithPath<
      UserUpdateRequest,
      void,
      ProblemDetails | void,
      EctoGridProjectsUsersCreatePath
    >(
      Method.POST,
      ({ projectId }: EctoGridProjectsUsersCreatePath) =>
        `/api/EctoGridProjects/${projectId}/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    usersDelete: apiEndpointWithPath<
      UserUpdateRequest,
      void,
      ProblemDetails | void,
      EctoGridProjectsUsersDeletePath
    >(
      Method.DELETE,
      ({ projectId }: EctoGridProjectsUsersDeletePath) =>
        `/api/EctoGridProjects/${projectId}/users`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    weatherCreate: apiEndpointWithPath<
      SetWeatherRequest,
      void,
      ProblemDetails | void,
      EctoGridProjectsWeatherCreatePath
    >(
      Method.POST,
      ({ projectId }: EctoGridProjectsWeatherCreatePath) =>
        `/api/EctoGridProjects/${projectId}/weather`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    timeseriesfileUpdate: apiEndpointWithPath<
      {
        /** @format binary */
        file?: File;
      },
      void,
      ProblemDetails | void,
      EctoGridProjectsTimeseriesfileUpdatePath
    >(
      Method.PUT,
      ({ projectId }: EctoGridProjectsTimeseriesfileUpdatePath) =>
        `/api/EctoGridProjects/${projectId}/timeseriesfile`,
      apiTitle,
      ContentType.FormData,
      ContentType.Empty,
      ContentType.Json
    ),
    timeseriesfileCreate: apiEndpointWithPath<
      {
        /** @format binary */
        file?: File;
      },
      void,
      ProblemDetails | void,
      EctoGridProjectsTimeseriesfileCreatePath
    >(
      Method.POST,
      ({ projectId }: EctoGridProjectsTimeseriesfileCreatePath) =>
        `/api/EctoGridProjects/${projectId}/timeseriesfile`,
      apiTitle,
      ContentType.FormData,
      ContentType.Empty,
      ContentType.Json
    ),
    /**
     * @originalName ectoGridProjectsTimeseriesfileUpdate
     * @duplicate
     */
    timeseriesfileUpdate2: apiEndpointWithPath<
      string,
      void,
      ProblemDetails | void,
      EctoGridProjectsTimeseriesfileUpdate2Path
    >(
      Method.PUT,
      ({ projectId, filename }: EctoGridProjectsTimeseriesfileUpdate2Path) =>
        `/api/EctoGridProjects/${projectId}/timeseriesfile/${filename}`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    timeseriesfileDetail: apiEndpointEmptyWithPath<
      string,
      void | ProblemDetails,
      EctoGridProjectsTimeseriesfileDetailPath
    >(
      Method.GET,
      ({ projectId, filename }: EctoGridProjectsTimeseriesfileDetailPath) =>
        `/api/EctoGridProjects/${projectId}/timeseriesfile/${filename}`,
      apiTitle,
      ContentType.Json,
      ContentType.Text,
      ContentType.Json
    ),
    formdataUpdate: apiEndpointWithPath<
      string,
      void,
      ProblemDetails | void,
      EctoGridProjectsFormdataUpdatePath
    >(
      Method.PUT,
      ({ projectId }: EctoGridProjectsFormdataUpdatePath) =>
        `/api/EctoGridProjects/${projectId}/formdata`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    formdataDetail: apiEndpointEmptyWithPath<
      any,
      void,
      EctoGridProjectsFormdataDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsFormdataDetailPath) =>
        `/api/EctoGridProjects/${projectId}/formdata`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    formdatalinkDetail: apiEndpointEmptyWithPath<
      FileInformation,
      void | ProblemDetails,
      EctoGridProjectsFormdatalinkDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsFormdatalinkDetailPath) =>
        `/api/EctoGridProjects/${projectId}/formdatalink`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    filesDetail: apiEndpointEmptyWithPath<
      FileResponseListResponse,
      void | ProblemDetails,
      EctoGridProjectsFilesDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsFilesDetailPath) =>
        `/api/EctoGridProjects/${projectId}/files`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    exportDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      EctoGridProjectsExportDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsExportDetailPath) =>
        `/api/EctoGridProjects/${projectId}/export`,
      apiTitle,
      ContentType.Json,
      ContentType.Blob,
      ContentType.Json
    ),
    buildsExportDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      EctoGridProjectsBuildsExportDetailPath
    >(
      Method.GET,
      ({ projectId, buildId }: EctoGridProjectsBuildsExportDetailPath) =>
        `/api/EctoGridProjects/${projectId}/builds/${buildId}/export`,
      apiTitle,
      ContentType.Json,
      ContentType.Blob,
      ContentType.Json
    ),
    buildsFormdataUpdate: apiEndpointWithPath<
      string,
      void,
      void | ProblemDetails,
      EctoGridProjectsBuildsFormdataUpdatePath
    >(
      Method.PUT,
      ({ projectId, buildId }: EctoGridProjectsBuildsFormdataUpdatePath) =>
        `/api/EctoGridProjects/${projectId}/builds/${buildId}/formdata`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    ),
    buildsRecalculateCurrentVersionCreate: apiEndpointWithPath<
      BuildRecalculateRequest,
      BuildResponse,
      void | ProblemDetails,
      EctoGridProjectsBuildsRecalculateCurrentVersionCreatePath
    >(
      Method.POST,
      ({
        projectId,
        buildId
      }: EctoGridProjectsBuildsRecalculateCurrentVersionCreatePath) =>
        `/api/EctoGridProjects/${projectId}/builds/${buildId}/recalculateCurrentVersion`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    buildsMasterBuildCreate: apiEndpointWithPath<
      BuildRecalculateRequest,
      BuildResponse,
      void | ProblemDetails,
      EctoGridProjectsBuildsMasterBuildCreatePath
    >(
      Method.POST,
      ({ projectId }: EctoGridProjectsBuildsMasterBuildCreatePath) =>
        `/api/EctoGridProjects/${projectId}/builds/masterBuild`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    createBuild: apiEndpointWithPath<
      BuildCreateRequest,
      BuildResponse,
      void | ProblemDetails,
      CreateBuildPath
    >(
      Method.POST,
      ({ projectId }: CreateBuildPath) =>
        `/api/EctoGridProjects/${projectId}/builds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    buildsDetail: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        EctoGridProjectsBuildsDetailParams,
        keyof EctoGridProjectsBuildsDetailPath
      >,
      BuildResponseListResponse,
      void | ProblemDetails,
      EctoGridProjectsBuildsDetailPath
    >(
      Method.GET,
      ({ projectId }: EctoGridProjectsBuildsDetailPath) =>
        `/api/EctoGridProjects/${projectId}/builds`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  User: {
    list: apiEndpointEmptyUsingQueryParams<
      UserListParams,
      BuildResponse,
      ProblemDetails
    >(
      Method.GET,
      `/api/User`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  },
  Version: {
    version: apiEndpointEmpty<object, any>(
      Method.GET,
      `/Version`,
      apiTitle,
      ContentType.Json,
      ContentType.Empty,
      ContentType.Json
    )
  },
  Weather: {
    stationsList: apiEndpointEmptyUsingQueryParams<
      WeatherStationsListParams,
      WeatherStationResponseListResponse,
      void
    >(
      Method.GET,
      `/api/Weather/stations`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    countriesList: apiEndpointEmptyUsingQueryParams<
      WeatherCountriesListParams,
      WeatherCountryResponseListResponse,
      void
    >(
      Method.GET,
      `/api/Weather/countries`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    countriesRegionsDetail: apiEndpointEmptyWithPathUsingQueryParams<
      Omit<
        WeatherCountriesRegionsDetailParams,
        keyof WeatherCountriesRegionsDetailPath
      >,
      WeatherRegionResponseListResponse,
      void,
      WeatherCountriesRegionsDetailPath
    >(
      Method.GET,
      ({ countryCode }: WeatherCountriesRegionsDetailPath) =>
        `/api/Weather/countries/${countryCode}/regions`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Empty
    ),
    create: apiEndpoint<
      {
        /** @format binary */
        request?: File;
      },
      WeatherStationResponse,
      ProblemDetails
    >(
      Method.POST,
      `/api/Weather`,
      apiTitle,
      ContentType.FormData,
      ContentType.Json,
      ContentType.Json
    ),
    cityDataDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      WeatherCityDataDetailPath
    >(
      Method.GET,
      ({ weatherStationId }: WeatherCityDataDetailPath) =>
        `/api/Weather/CityData/${weatherStationId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    ),
    airTempDetail: apiEndpointEmptyWithPath<
      string,
      void | ProblemDetails,
      WeatherAirTempDetailPath
    >(
      Method.GET,
      ({ weatherStationId }: WeatherAirTempDetailPath) =>
        `/api/Weather/AirTemp/${weatherStationId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Text,
      ContentType.Json
    ),
    weatherDataDetail: apiEndpointEmptyWithPath<
      any,
      void | ProblemDetails,
      WeatherWeatherDataDetailPath
    >(
      Method.GET,
      ({ weatherStationId }: WeatherWeatherDataDetailPath) =>
        `/api/Weather/WeatherData/${weatherStationId}`,
      apiTitle,
      ContentType.Json,
      ContentType.Json,
      ContentType.Json
    )
  }
};

export default EctoplannerAPIGen;
