import React, { useState } from 'react';
import _ from 'lodash';
import SearchableTable from 'ecto-common/lib/SearchableTable/SearchableTable';
import T from 'ecto-common/lib/lang/Language';
import { BuildingTemperatureImpactPreviewResponseModel } from 'ecto-common/lib/API/APIGen';
import useSearchResult from 'ecto-common/lib/hooks/useSearchResult';

const columns = [
  {
    label: T.common.nodename,
    dataKey: 'nodeName'
  },
  {
    label: T.common.affected,
    dataKey: 'meteorologyDataMissing',
    width: 80,
    flexGrow: 0,
    dataFormatter: (meteorologyDataMissing: boolean) => {
      return <>{meteorologyDataMissing ? T.common.no : T.common.yes}</>;
    }
  }
];

interface TemperatureImpactBuildingsTableProps {
  buildings: BuildingTemperatureImpactPreviewResponseModel[];
  disabled?: boolean;
}

const TemperatureImpactBuildingsTable = (
  props: TemperatureImpactBuildingsTableProps
) => {
  const [searchString, setSearchString] = useState('');
  const buildings = useSearchResult(props.buildings, 'nodeName', searchString);

  return (
    <SearchableTable
      disabled={props.disabled}
      data={buildings}
      columns={columns}
      noDataText={
        T.powercontrol.dispatch.schedule.temperatureimpact.affectedbuildings
          .empty
      }
      onSearch={setSearchString}
      onClickRow={_.noop}
    />
  );
};

export default React.memo(TemperatureImpactBuildingsTable);
