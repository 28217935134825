import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
  SetStateAction,
  Dispatch
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import ProcessMap from 'ecto-common/lib/ProcessMap/ProcessMap';
import useProcessMapDropdown from 'ecto-common/lib/ProcessMap/useProcessMapDropdown';
import { SignalsActions } from 'ecto-common/lib/modules/signals/signals';
import RemoteOptimisationSettings from 'js/components/EMPTools/RemoteOptimisation/RemoteOptimisationSettings';

import T from 'ecto-common/lib/lang/Language';
import { ROOT_NODE_ID } from 'ecto-common/lib/constants';
import {
  REQ_STATE_ERROR,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';

import styles from './SignalProvidersPage.module.css';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import Notice from 'ecto-common/lib/Notice/Notice';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import HelpPaths from 'ecto-common/help/tocKeys';
import SignalsToolbar from 'js/components/SignalProvidersPage/SignalsToolbar';
import {
  useOperatorSelector,
  useOperatorDispatch
} from 'js/reducers/storeOperator';
import APIGen, {
  FullSignalProviderResponseModel
} from 'ecto-common/lib/API/APIGen';
import { getSignalsUrl } from 'js/utils/routeConstants';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { Moment } from 'moment';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import moment from 'moment';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import useEquipmentLinkAction from 'ecto-common/lib/ProcessMap/useEquipmentLinkAction';
import useNodeNavigationLinkAction from 'ecto-common/lib/ProcessMap/useNodeNavigationLinkAction';
import {
  ASC,
  DESC,
  SortDirectionType
} from 'ecto-common/lib/DataTable/SortDirection';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';

const urlBuilder = (
  tenantId: string,
  newNodeId: string,
  _subPage?: string,
  searchParams?: URLSearchParams
) => {
  const url = getSignalsUrl(tenantId, newNodeId);

  if (searchParams?.size > 0) {
    return `${url}?${searchParams.toString()}`;
  }

  return url;
};

interface SignalProvidersPageProps {
  onTitleChanged: (title: string[]) => void;
}

const SignalProvidersPage = ({ onTitleChanged }: SignalProvidersPageProps) => {
  const { currentNode, isLoading: isLoadingCurrentNode } = useCurrentNode();

  const params = useParams<NodeParams>();
  const dispatch = useOperatorDispatch();
  const providersRequest = useOperatorSelector(
    (state) => state.signals.providersReqState
  );

  const navigate = useNavigate();

  const isLoading =
    isLoadingCurrentNode || providersRequest.state === REQ_STATE_PENDING;
  const hasError = providersRequest.state === REQ_STATE_ERROR;
  const [selectedNodeId, setSelectedNodeId] = useState<string>(null);
  const { contextSettings } = useContext(TenantContext);

  const { nodeId } = params;

  useEffect(() => {
    setSelectedNodeId(nodeId);
  }, [nodeId]);

  const signalProviders: FullSignalProviderResponseModel[] = useMemo(() => {
    const currentNodeId = selectedNodeId;

    if (
      providersRequest.state === REQ_STATE_SUCCESS &&
      providersRequest.payload
    ) {
      return _(providersRequest.payload)
        .filter((provider) => provider.nodeIds.includes(currentNodeId))
        .uniqBy('signalProviderId')
        .value();
    }
    return [];
  }, [providersRequest.state, providersRequest.payload, selectedNodeId]);

  const {
    ProcessMapDropdown: EquipmentDropdown,
    showMenu: showEquipmentMenu,
    hideMenu: hideEquipmentMenu
  } = useProcessMapDropdown();

  const {
    ProcessMapDropdown: NodeDropdown,
    showMenu: showNodeMenu,
    hideMenu: hideNodeMenu
  } = useProcessMapDropdown();

  useEffect(() => {
    if (currentNode) {
      if (currentNode.nodeId.startsWith(ROOT_NODE_ID)) {
        dispatch(SignalsActions.cancelRequests());
        dispatch(SignalsActions.resetRequests());
      } else {
        dispatch(SignalsActions.getProviders(contextSettings, currentNode));
      }
    } else {
      dispatch(SignalsActions.cancelRequests());
    }

    return () => dispatch(SignalsActions.cancelRequests());
  }, [contextSettings, dispatch, currentNode]);

  const [searchParams] = useSearchParams();

  const linearOptimisationQuery =
    APIGen.RemoteOptimisation.getLinearOptimisationsByNodeV2.useQuery(
      {
        nodesIds: [nodeId]
      },
      {
        enabled: nodeId != null
      }
    );

  const selectedOptimisation = _.head(
    linearOptimisationQuery.data?.linearOptimisations
  );

  const [signalsSearchFilter, setSignalsSearchFilter] = useSearchParamState(
    'search-filter',
    null
  );

  const [selectedSignalIdsList, setSelectedSignalIdsList] = useSearchParamState(
    'selected-signals',
    ''
  );
  const [selectedImageId, setSelectedImageId] = useSearchParamState(
    'selected-image-id',
    null
  );

  const selectedSignalIds = useMemo(() => {
    if (selectedSignalIdsList === '') {
      return {};
    }
    const ids = selectedSignalIdsList.split('.');
    const result: Record<string, boolean> = {};
    ids.forEach((id) => {
      result[id] = true;
    });
    return result;
  }, [selectedSignalIdsList]);

  const setSelectedSignalIds = useCallback<
    Dispatch<SetStateAction<Record<string, boolean>>>
  >(
    (args) => {
      let newArgs: Record<string, boolean> = {};
      if (_.isFunction(args)) {
        newArgs = args(selectedSignalIds);
      } else {
        newArgs = args;
      }

      const newIds = _.keys(newArgs).join('.');
      setSelectedSignalIdsList(newIds, true);
    },
    [selectedSignalIds, setSelectedSignalIdsList]
  );

  const [fromDateString, setFromDateString] = useSearchParamState(
    'from-date',
    null
  );

  const setFromDate = useCallback(
    (newFromDate: Moment) => {
      setFromDateString(newFromDate?.toISOString());
    },
    [setFromDateString]
  );

  const fromDate = useMemo(() => {
    if (fromDateString == null) {
      return null;
    }
    return moment(fromDateString);
  }, [fromDateString]);

  const confirmNavigationToNodeId = useCallback(
    (newNodeId: string) => {
      navigate(
        urlBuilder(contextSettings.tenantId, newNodeId, null, searchParams)
      );
    },
    [contextSettings.tenantId, navigate, searchParams]
  );

  const onClickNavigateNode = useNodeNavigationLinkAction({
    showMenu: showNodeMenu,
    hideMenu: hideNodeMenu,
    confirmNavigationToNodeId
  });

  const onOpenEquipmentType = useEquipmentLinkAction({
    nodeId,
    showMenu: showEquipmentMenu,
    hideMenu: hideEquipmentMenu
  });

  const onMouseUpContainer = useCallback(() => {
    hideEquipmentMenu();
    hideNodeMenu();
  }, [hideEquipmentMenu, hideNodeMenu]);

  usePageTitleCallback({
    mainTitle: T.location.tabs.signalproviders,
    subTitle: '',
    onTitleChanged
  });

  const [sortByString, setSortByString] = useSearchParamState(
    'sort',
    'name-' + ASC
  );

  const [sortBy, sortDirection] = useMemo(() => {
    const [sortByRes, sortDirectionSplit] = (sortByString ?? '').split('-');
    let sortDirectionRes = sortDirectionSplit;

    if (sortDirectionRes !== ASC && sortDirectionRes !== DESC) {
      sortDirectionRes = ASC;
    }
    return [sortByRes ?? 'name', sortDirectionRes as SortDirectionType];
  }, [sortByString]);

  const onSortChange = useCallback(
    (newOrderBy: string, newSortDirection: string) => {
      setSortByString(newOrderBy + '-' + newSortDirection);
    },
    [setSortByString]
  );

  if (!currentNode) {
    return undefined;
  }
  const isEmpty = signalProviders.length === 0;
  const isRootNode = currentNode.nodeId.startsWith(ROOT_NODE_ID);
  return (
    <ToolbarContentPage
      selectEquipment
      title={T.location.tabs.signalproviders}
      padContent={false}
      wrapContent={false}
      urlBuilder={urlBuilder}
      helpPath={HelpPaths.docs.operator.signals}
      dockToolbar
      toolbarItems={
        <SignalsToolbar
          nodeId={nodeId}
          fromDate={fromDate}
          setFromDate={setFromDate}
          searchFilter={signalsSearchFilter}
          setSearchFilter={setSignalsSearchFilter}
          selectedSignalIds={selectedSignalIds}
          setSelectedSignalIds={setSelectedSignalIds}
        />
      }
    >
      {isRootNode && isEmpty && !isLoading && (
        <PlainBox className={styles.infoBox}>
          <Notice className={styles.emptyNotice} showHeader>
            {T.signalproviders.emptylist}
          </Notice>
        </PlainBox>
      )}
      <div onMouseUp={onMouseUpContainer}>
        {selectedOptimisation && (
          <PlainBox className={styles.optimisationBox}>
            <RemoteOptimisationSettings
              selectedOptimisation={selectedOptimisation}
            />
          </PlainBox>
        )}

        {!isRootNode && (
          <ProcessMap
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            isLoading={isLoading}
            selectedSignalProviders={signalProviders}
            selectedNodeId={params.nodeId}
            key={selectedNodeId}
            setSelectedSignalIds={setSelectedSignalIds}
            selectedImageId={selectedImageId}
            onSelectedImageIdChanged={setSelectedImageId}
            searchFilter={signalsSearchFilter}
            selectedSignalIds={selectedSignalIds}
            hasError={hasError}
            fromDate={fromDate}
            onOpenEquipmentType={onOpenEquipmentType}
            onNavigateToNodeId={onClickNavigateNode}
          />
        )}
        <EquipmentDropdown />
        <NodeDropdown />
      </div>
    </ToolbarContentPage>
  );
};

export default SignalProvidersPage;
