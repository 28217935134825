import React, { useContext, useEffect } from 'react';
import Page, { PageNavLink } from 'ecto-common/lib/Page/Page';
import T from '../lang/Language';
import TenantContext from '../hooks/TenantContext';

const emptyArray: PageNavLink[] = [];

interface LoadingScreenWithMenuProps {
  isLoading?: boolean;
}

const LoadingScreenWithMenu = ({ isLoading }: LoadingScreenWithMenuProps) => {
  const { tenantId, tenants } = useContext(TenantContext);

  useEffect(() => {
    if (isLoading) {
      const currentTenant = tenants?.find((tenant) => tenant.id === tenantId);
      document.title =
        (currentTenant?.name ? currentTenant?.name + ' > ' : '') +
        T.common.loading;
    }
  }, [isLoading, tenants, tenantId]);

  if (!isLoading) {
    return null;
  }

  const content = <></>;

  return <Page isLoading content={content} navLinks={emptyArray} />;
};

export default React.memo(LoadingScreenWithMenu);
