import React from 'react';
import styles from './ModalHeader.module.css';

interface ModalInnerHeaderProps {
  /**
   * The content of the header.
   */
  children?: React.ReactNode;
}

/**
 *  This header can be used inside a ModalBody to provide section headers. Looks better than using the <Header> component.
 */
const ModalInnerHeader = ({
  children,
  ...otherProps
}: ModalInnerHeaderProps) => (
  <div className={styles.modalInnerHeader} {...otherProps}>
    {children}
  </div>
);

export default ModalInnerHeader;
