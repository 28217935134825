import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore
} from 'react';
import T, { ValidLanguages } from 'ecto-common/lib/lang/Language';
import { KeyValueLine } from 'ecto-common/lib/KeyValueInput/KeyValueLine';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import { setSelectedLanguage } from 'ecto-common/lib/utils/localStorageUtil';

import styles from 'ecto-common/lib/LocationSidebar/UserSettings.module.css';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import EditButton from 'ecto-common/lib/Button/EditButton';
import Flex from 'ecto-common/lib/Layout/Flex';
import ModelFormDialog from 'ecto-common/lib/ModelForm/ModelFormDialog';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  useCommonDispatch,
  useCommonSelector
} from 'ecto-common/lib/reducers/storeCommon';
import IdentityServiceAPIGenV2, {
  UserModel
} from 'ecto-common/lib/API/IdentityServiceAPIGenV2';
import {
  FeatureFlag,
  featureFlagOptions,
  featureFlagStore
} from '../FeatureFlags/FeatureFlags';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { SET_UI_LANGUAGE } from 'ecto-common/lib/actions/actionTypes';
import { useQueryClient } from '@tanstack/react-query';

type LanguageOption = {
  label: string;
  value: string;
};

const languageOptions: LanguageOption[] = ValidLanguages.map(
  (lang: keyof typeof T.language) => {
    return {
      label: T.language[lang],
      value: lang
    };
  }
);

const models: ModelDefinition<UserModel>[] = [
  {
    key: (input) => input.displayName,
    modelType: ModelType.TEXT,
    label: T.navbar.displayname,
    hasError: isNullOrWhitespace,
    autoFocus: true
  }
];

interface UserSettingsProps {
  onLogoutClicked?(): void;
}

const UserSettings = ({ onLogoutClicked }: UserSettingsProps) => {
  const { reloadUser } = useContext(TenantContext);
  const { username, userData } = useContext(UserContext);
  const dispatch = useCommonDispatch();

  const language = useCommonSelector((state) => state.general.language);
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();
  const featureFlagState = useSyncExternalStore(
    featureFlagStore.subscribe,
    featureFlagStore.getSnapshot
  );

  const updateUserSettings =
    IdentityServiceAPIGenV2.User.createOrUpdateUserSettings.useMutation({
      onSuccess: (result, newSettings) => {
        dispatch({
          type: SET_UI_LANGUAGE,
          payload: result.language
        });
        setSelectedLanguage(result.language);

        featureFlagStore.update(newSettings.userSettings?.experimentalFeatures);
        queryClient.setQueryData(
          IdentityServiceAPIGenV2.User.getUserSettings.path(contextSettings),
          {
            userSettings: result
          }
        );

        if (
          language !== result.language ||
          featureFlagState['eiot-signals'] !==
            result.experimentalFeatures['eiot-signals']
        ) {
          window.location.reload();
        }
      }
    });

  const pendingUserSettings = updateUserSettings.variables?.userSettings;

  const pendingFeatureFlagState =
    !updateUserSettings.isError &&
    pendingUserSettings?.experimentalFeatures != null
      ? pendingUserSettings?.experimentalFeatures
      : featureFlagState;

  const selectedOptions = useMemo(() => {
    return featureFlagOptions.filter(
      (option) => pendingFeatureFlagState[option.value]
    );
  }, [pendingFeatureFlagState]);

  const [editUser, setEditUser] = useState<UserModel>(null);
  const ref = useRef(null);

  const saveUserMutation = IdentityServiceAPIGenV2.User.updateUser.useMutation({
    onSuccess: () => {
      reloadUser?.();
      setEditUser(null);
    },
    onError: () => {
      toastStore.addErrorToastForUpdatedItem(editUser?.displayName, false);
    }
  });

  const onModalClose = useCallback(() => {
    setEditUser(null);
  }, []);

  const updateLanguage = useCallback(
    (newLanguage: string) => {
      updateUserSettings.mutate({
        userSettings: {
          language: newLanguage,
          experimentalFeatures: featureFlagState
        }
      });
    },
    [updateUserSettings, featureFlagState]
  );

  const updateFeatureFlags = useCallback(
    (newFeatureFlags: GenericSelectOption<FeatureFlag>[]) => {
      const newFeatureFlagState = newFeatureFlags.reduce(
        (acc, curr) => {
          acc[curr.value] = true;
          return acc;
        },
        {} as Record<FeatureFlag, boolean>
      );

      updateUserSettings.mutate({
        userSettings: {
          language,
          experimentalFeatures: newFeatureFlagState
        }
      });
    },
    [language, updateUserSettings]
  );

  const pendingLanguage =
    !updateUserSettings.isError && pendingUserSettings?.language != null
      ? pendingUserSettings?.language
      : language;

  const selectedLanguageOption = languageOptions.find(
    (languageOption) => languageOption.value === pendingLanguage
  );

  const editDisplayName = useCallback(() => {
    setEditUser(userData);
  }, [userData]);

  return (
    <div className={styles.settingsContainer}>
      <div ref={ref} className={styles.settings}>
        <KeyValueLine>
          <KeyValueGeneric keyText={T.navbar.displayname}>
            <span>{userData?.displayName ?? T.common.loading}</span>
          </KeyValueGeneric>
        </KeyValueLine>
        <KeyValueLine>
          <KeyValueGeneric keyText={T.navbar.username}>
            <span>{username}</span>
          </KeyValueGeneric>
        </KeyValueLine>
        <KeyValueLine>
          <KeyValueGeneric keyText={T.navbar.applanguage}>
            <div data-test="languageSelector">
              <Select
                isSearchable={false}
                isLoading={updateUserSettings.isPending}
                onChange={(val: GenericSelectOption) =>
                  updateLanguage(val.value)
                }
                value={selectedLanguageOption}
                options={languageOptions}
              />
            </div>
          </KeyValueGeneric>
        </KeyValueLine>
        <KeyValueLine>
          <KeyValueGeneric keyText={T.navbar.featureflags}>
            <Select<GenericSelectOption<FeatureFlag>, true>
              isSearchable={false}
              className={styles.select}
              onChange={updateFeatureFlags}
              isLoading={updateUserSettings.isPending}
              value={selectedOptions}
              options={featureFlagOptions}
              isMulti
            />
          </KeyValueGeneric>
        </KeyValueLine>
        <Flex>
          <EditButton disabled={userData == null} onClick={editDisplayName}>
            {T.common.edit}
          </EditButton>
          <Button onClick={onLogoutClicked} data-test="logout">
            <Icons.Logout /> {T.navbar.logout}
          </Button>
        </Flex>
      </div>
      <ModelFormDialog
        saveAsArray={false}
        onModalClose={onModalClose}
        saveInput={(args) => saveUserMutation.mutate({ user: args })}
        isSavingInput={saveUserMutation.isPending}
        models={models}
        input={editUser}
        addTitle={T.navbar.adduser}
        editTitle={T.navbar.edituser}
      />
    </div>
  );
};

export default UserSettings;
