import { SET_NODE_TAGS } from './actionTypes';

export function setNodeTags(nodeTags: string[] = []) {
  return {
    type: SET_NODE_TAGS,
    payload: {
      nodeTags
    }
  };
}
