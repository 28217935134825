import ArrayUtils from './arrayUtils';
import changeBuildingList from './changeBuildingList';
import calcBes from './calcBes';

// Sums of import profiles of all selected buildings
function calcResidualHeatFlows(state, pipeSizingModalState) {
  let allBldgsImportProfile = {
    heat: ArrayUtils.createConstantArray(8760, 0),
    cool: ArrayUtils.createConstantArray(8760, 0),
    elec: ArrayUtils.createConstantArray(8760, 0) // not needed
  };
  Object.keys(state.buildingList).forEach((bldg) => {
    if (pipeSizingModalState['bldg-' + bldg]) {
      allBldgsImportProfile['heat'] = ArrayUtils.arraySum(
        state.buildingList[bldg]['heatImportProfile'],
        allBldgsImportProfile['heat']
      );
      allBldgsImportProfile['cool'] = ArrayUtils.arraySum(
        state.buildingList[bldg]['coolImportProfile'],
        allBldgsImportProfile['cool']
      );
    }
  });

  // Parameters for function 'calcEhLoadProfiles'
  const params = {
    networkOption: state.networkOption,
    diversityFactorHeat: Number(state.diversityFactorHeat),
    diversityFactorCold: Number(state.diversityFactorCold),
    diversityFactorLowEx: Number(state.diversityFactorLowEx),
    heatLossesRel: 0,
    heatLossesAbs: 0,
    coldLossesRel: 0,
    coldLossesAbs: 0,
    heatPumpWork: 0,
    coldPumpWork: 0,
    lowExPumpWork: 0
  };

  // Calculate sum at pipe that supplies all downstream buildings with 'energy hub'-function
  const pipeLoadProfiles = changeBuildingList.calcEhLoadProfiles(
    allBldgsImportProfile,
    params
  );

  // Return the correct heat flow profile
  let pipeLoadProfile = [];
  if (state.networkOption === 'separate') {
    if (state.sizingSeparateNetwType === 'heat') {
      pipeLoadProfile = pipeLoadProfiles.heat;
    } else if (state.sizingSeparateNetwType === 'cool') {
      pipeLoadProfile = pipeLoadProfiles.cool;
    }
  } else if (state.networkOption === 'lowEx') {
    pipeLoadProfile = ArrayUtils.arraySum(
      pipeLoadProfiles.heat,
      pipeLoadProfiles.cool
    );
  }
  return pipeLoadProfile; // kW
}

// Calculates hourly velocity profile
function calcVelocityProfile(
  heatFlowProfile,
  diameter,
  state,
  gridMeanTProfile
) {
  let waterProps = null;
  const crossSectionalArea = ((diameter * diameter) / 4) * Math.PI;
  let velocityFlowProfile = [];
  //console.log('examle of water', calcWaterProps(gridMeanTProfile[0]));
  for (let t = 0; t < 8760; t++) {
    waterProps = calcWaterProps(gridMeanTProfile[t]);
    velocityFlowProfile.push(
      (heatFlowProfile[t] * 1000) /
        waterProps.heatCapacity /
        state.deltaTPipeSizing /
        waterProps.density /
        crossSectionalArea
    );
  }
  return velocityFlowProfile;
}

// Calculates hourly profile of specific pressure loss
function calcSpecPressureLossProfile(
  velocityProfile,
  diameter,
  state,
  gridMeanTProfile
) {
  let waterProps = null;
  let specPressureLossProfile = [];
  let specPressureLoss = null;
  for (let t = 0; t < 8760; t++) {
    waterProps = calcWaterProps(gridMeanTProfile[t]);
    specPressureLoss = calcSpecPressureLoss(
      velocityProfile[t],
      diameter,
      waterProps,
      state
    );
    specPressureLossProfile.push(specPressureLoss);
  }
  return specPressureLossProfile;
}

// Calculate pressure loss
function calcSpecPressureLoss(velocity, diameter, waterProps, state) {
  const eps = state.pipeRoughnessPipeSizing / 1000;

  const Re = reynoldsNumber(
    diameter,
    velocity,
    waterProps['density'],
    waterProps['viscosity']
  );

  let lambda = calcLambdaTurbFlow(diameter, eps);

  lambda = solveColebrookEquation(eps, diameter, Re, lambda);

  const specPressureLoss = calcPressureLoss(
    waterProps['density'],
    lambda,
    diameter,
    velocity
  );

  return specPressureLoss;
}

// Calculate volume flow rate from a single flow
// Modify to add Temperture difference
function calcFlowRatesFromHeatFlow(heatFlowProfile, state, gridMeanTProfile) {
  //const waterProps = getPhysicalPropertiesWater(state);
  // Iterate and return the maxial mass flow rate and volumetric flow rate

  let waterProps = null;
  let heatFlow = null;

  let maxMassFlowRate_kg_s = 0;
  let maxVolFlowRate_m3_s = 0;
  let massFlowRate_kg_s = null;
  let volFlowRate_m3_s = null;
  for (let t = 0; t < 8760; t++) {
    waterProps = calcWaterProps(gridMeanTProfile[t]);
    heatFlow = heatFlowProfile[t];
    massFlowRate_kg_s =
      (heatFlow * 1000) / state.deltaTPipeSizing / waterProps.heatCapacity;
    volFlowRate_m3_s = massFlowRate_kg_s / waterProps.density;

    if (massFlowRate_kg_s > maxMassFlowRate_kg_s) {
      maxMassFlowRate_kg_s = massFlowRate_kg_s;
    }
    if (volFlowRate_m3_s > maxVolFlowRate_m3_s) {
      maxVolFlowRate_m3_s = volFlowRate_m3_s;
    }
  }

  return {
    volFlowRate_m3_s: maxVolFlowRate_m3_s.toFixed(1),
    volFlowRate_m3_h: (maxVolFlowRate_m3_s * 3600).toFixed(1),
    volFlowRate_ltr_s: (maxVolFlowRate_m3_s * 1000).toFixed(1),
    massFlowRate_kg_s: maxMassFlowRate_kg_s.toFixed(1),
    massFlowRate_t_h: ((maxMassFlowRate_kg_s / 1000) * 3600).toFixed(1)
  };
}

// Pressure loss formula
function calcPressureLoss(density, lambda, diameter, velocity) {
  if (velocity === 0) {
    return 0;
  } else {
    return (((density * lambda) / diameter) * Math.pow(velocity, 2)) / 2;
  }
}

// Colebrook equation
function solveColebrookEquation(eps, diameter, Re, lambda) {
  const maxNumIter = 4;
  let iterNum = 1;
  let oldLambda = 100000;
  while ((Math.abs(oldLambda - lambda) > 0.01) & (iterNum < maxNumIter)) {
    oldLambda = lambda;
    lambda = colebrookEquation(eps, diameter, Re, lambda);
    iterNum += 1;
  }
  return lambda;
}

// Lambda for completely turbulent flow
function calcLambdaTurbFlow(diameter, eps) {
  const lambda = Math.pow(1.14 + 2 * Math.log10(diameter / eps), -2);
  return lambda;
}

// Reynolds number
// The viscosity is the dynamic viscosity
function reynoldsNumber(diameter, velocity, density, viscosity) {
  return (diameter * velocity * density) / viscosity;
}

// RHS of Colebrook equation
function colebrookEquation(eps, diameter, Re, lambda) {
  const innerTerm = eps / diameter / 3.72 + 2.51 / (Re * Math.pow(lambda, 0.5));
  const newLambda = Math.pow(-2 * Math.log10(innerTerm), -2);
  return newLambda;
}

// Calculate specific annual pump work
function calcAnnualPumpWork(velocityProfile, diameter, pressureLossProfile) {
  const crossSectionalArea = ((diameter * diameter) / 4) * Math.PI;
  let annualPumpWork = 0;
  for (let t = 0; t < 8760; t++) {
    annualPumpWork +=
      crossSectionalArea * velocityProfile[t] * pressureLossProfile[t];
  }
  return (annualPumpWork * 3600) / 1000 / 3600; // J/m -> kWh/m
}

// Get relative deviation
function calcRelDeviationToOptimum(maxVelocity, diameter) {
  const optimumVelocity = {
    25: 0.9, // DN: velocity in m/s
    32: 0.93,
    40: 0.98,
    50: 1,
    60: 1.08,
    65: 1.12,
    80: 1.23,
    100: 1.45,
    125: 1.65,
    150: 1.85,
    200: 2.25,
    250: 2.4,
    300: 2.53,
    350: 2.58,
    400: 2.7,
    450: 2.8,
    500: 2.9,
    600: 2.95,
    700: 3.1,
    800: 3.25
  };
  return (
    Math.abs(maxVelocity - optimumVelocity[diameter]) /
    optimumVelocity[diameter]
  );
}

// Get relative deviation
// newly created , changed from DN to PN
function calcRelDeviationToOptimumByDe(maxVelocity, dy, pressureClass) {
  // dy is the outside diameter of the pipe
  // The pressureclass is either PN10 or PN16

  // The optimum velocity for different Dy, PN class 10
  const optimumVelocityPN10 = {
    32: 1.21,
    40: 1.23,
    50: 1.26,
    63: 1.3,
    75: 1.33,
    90: 1.38,
    110: 1.43,
    125: 1.48,
    140: 1.52,
    160: 1.58,
    180: 1.64,
    200: 1.69,
    225: 1.77,
    250: 1.84,
    280: 1.93,
    315: 2.03,
    355: 2.14,
    400: 2.27,
    450: 2.42,
    500: 2.56,
    560: 2.74,
    630: 2.94,
    710: 3.17,
    740: 3.26
  };

  // The optimum velocity for different Dy, PN class 16
  const optimumVelocityPN16 = {
    32: 1.2,
    40: 1.22,
    50: 1.25,
    63: 1.29,
    75: 1.32,
    90: 1.36,
    110: 1.41,
    125: 1.45,
    140: 1.49,
    160: 1.55,
    180: 1.6,
    200: 1.65,
    225: 1.72,
    250: 1.79,
    280: 1.87,
    315: 1.96,
    355: 2.07,
    400: 2.19,
    450: 2.32,
    500: 2.46,
    560: 2.62,
    630: 2.81,
    710: 3.02,
    740: 3.1
  };

  if (pressureClass === 'PN10') {
    return (
      Math.abs(maxVelocity - optimumVelocityPN10[dy]) / optimumVelocityPN10[dy]
    );
  } else {
    return (
      Math.abs(maxVelocity - optimumVelocityPN16[dy]) / optimumVelocityPN16[dy]
    );
  }
}

// Obtain the grid Mean T profile
/* function calcGridMeanTProfile(state) {
  if (
    state.network.use_grid_t_suggestion &&
    state.network.suggested_grid_mean_t_available
  ) {
    return state.network.suggested_grid_mean_t;
  } else if (state.network.enable_temp_upload) {
    return state.temp_time_series;
  } else {
    return ArrayUtils.createConstantArray(
      8760,
      (state.network.netw_temp_warm_pipe + state.network.netw_temp_cold_pipe) /
        2
    );
  }
}
  **/

function calcGridMeanTProfile(state) {
  // To create a new temporary state to be abel to use the getCurrNetwTemp function
  let statefortemp = {
    gridTMethodOption: state.network.grid_t_method_option,
    netwTempProfile: state.network.temp_time_series,
    meanNetwTemp:
      (state.network.netw_temp_warm_pipe + state.network.netw_temp_cold_pipe) /
      2.0,
    netwTempProfile: state.network.temp_time_series,
    suggestedGridTAvailable: state.network.suggested_grid_mean_t_available,
    suggestedGridT: state.network.suggested_grid_mean_t
  };
  //console.log('State: ', statefortemp);
  let gridMeanTProfile = [];
  let currMeanT = 0;
  for (let i = 0; i < 8760; i++) {
    currMeanT = calcBes.getCurrNetwTemp(statefortemp, i);
    gridMeanTProfile.push(currMeanT);
  }
  return gridMeanTProfile;
}

// return the wather parameters, density and dynamic viscosity at a given T
function calcWaterProps(temperature) {
  if (temperature < 0 || temperature > 60) {
    throw new Error(
      'Temperature out of range. Please enter a temperature between 0 and 60 °C.'
    );
  }

  // Coefficients for the polynomial equation to calculate water density
  const a0 = 999.83952;
  const a1 = 16.945176;
  const a2 = -7.9870401e-3;
  const a3 = -46.170461e-6;
  const a4 = 105.56302e-9;
  const a5 = -280.54253e-12;

  // Calculate density in kg/m^3 using the polynomial equation
  let density =
    a0 +
    a1 * temperature +
    a2 * Math.pow(temperature, 2) +
    a3 * Math.pow(temperature, 3) +
    a4 * Math.pow(temperature, 4) +
    a5 * Math.pow(temperature, 5);

  // Coefficients for the polynomial equation to calculate water viscosity

  const a = 2.414;
  const b = 247.8;
  const c = 140;

  // Convert temperature from Celsius to Kelvin
  let temperatureKelvin = temperature + 273.15;

  // Calculate dynamic viscosity using the empirical formula
  let viscosity = (a * Math.pow(10, b / (temperatureKelvin - c))) / 100 / 1000; // Pa.s, dynamic viscosity

  // Coefficients for the specific heat capacity equation
  const aheat = 4.217;
  const bheat = -3.322e-3;
  const cheat = 1.55e-6;

  // Calculate specific heat capacity in J/(kg·K) using the polynomial equation
  let specificHeatCapacity =
    (aheat + bheat * temperature + cheat * Math.pow(temperature, 2)) * 1000;

  return {
    density: density, // kg/m^3
    viscosity: viscosity, // Pa.s
    heatCapacity: specificHeatCapacity // J/(kg·K) 4180 for example
  };
}

// Get three best diameters
function getBest3Diameters(recommendedDiam) {
  let diamList = [];
  diamList.push(recommendedDiam);
  const deDiameters = this.getDeDiameters();
  const index = deDiameters.indexOf(recommendedDiam);
  if (index > 0) {
    diamList.push(deDiameters[index - 1]);
  }
  if (index < deDiameters.length - 1) {
    diamList.push(deDiameters[index + 1]);
  }
  return diamList;
}

// Physical properties
// Need to be changed as the viscosity should be dependent on the T
function getPhysicalPropertiesWater(state) {
  if (state.networkOption === 'separate') {
    if (state.sizingSeparateNetwType === 'heat') {
      return {
        density: 983,
        viscosity: 0.0004655,
        heatCapacity: 4185
      };
    } else if (state.sizingSeparateNetwType === 'cool') {
      return {
        density: 1000,
        viscosity: 0.001308,
        heatCapacity: 4200
      };
    }
  } else if (state.networkOption === 'lowEx') {
    return {
      density: 987,
      viscosity: 0.0008913,
      heatCapacity: 4180
    };
  }
}

// Norm diameters
// SHould be the Dy, instead of DN.
function getNormDiameters() {
  return [
    25, 32, 40, 50, 60, 65, 80, 100, 125, 150, 200, 250, 300, 350, 400, 450,
    500, 600, 700, 800
  ];
}

// return the outside diameter of plastic pipes.
// Inner diameter will be denpent on the thickness of the pipe, which further depends on the pressure class
function getDeDiameters() {
  return [
    32, 40, 50, 63, 75, 90, 110, 125, 140, 160, 180, 200, 225, 250, 280, 315,
    355, 400, 450, 500, 560, 630, 710, 740
  ];
}

function getInnerDiameter(Dy, pressureClass) {
  // The thickness of pipes at different Dy, PN class 10, or SDR 17
  const thicknessPN10 = {
    32: 2,
    40: 2.4,
    50: 3,
    63: 3.8,
    75: 4.5,
    90: 5.4,
    110: 6.6,
    125: 7.4,
    140: 8.3,
    160: 9.5,
    180: 10.7,
    200: 11.9,
    225: 13.4,
    250: 14.8,
    280: 16.6,
    315: 18.7,
    355: 21.1,
    400: 23.7,
    450: 26.7,
    500: 29.7,
    560: 33.2,
    630: 37.4,
    710: 42.1,
    740: 43.6
  };

  // The thickness of pipes at different Dy, PN class 16, or SDR 11
  const thicknessPN16 = {
    32: 3,
    40: 3.7,
    50: 4.6,
    63: 5.8,
    75: 6.8,
    90: 8.2,
    110: 10,
    125: 11.4,
    140: 12.7,
    160: 14.6,
    180: 16.4,
    200: 18.2,
    225: 20.5,
    250: 22.7,
    280: 25.4,
    315: 28.6,
    355: 32.3,
    400: 36.4,
    450: 40.9,
    500: 45.4,
    560: 50.8,
    630: 57.2,
    710: 64.5,
    740: 67.3
  };

  if (pressureClass === 'PN10' || pressureClass === 'SDR11') {
    return Dy - 2 * thicknessPN10[Dy];
  } else {
    return Dy - 2 * thicknessPN16[Dy];
  }
}

const exportedFunctions = {
  calcSpecPressureLoss,
  getNormDiameters,
  calcResidualHeatFlows,
  calcVelocityProfile,
  calcSpecPressureLossProfile,
  calcFlowRatesFromHeatFlow,
  calcRelDeviationToOptimum,
  getBest3Diameters,
  calcAnnualPumpWork,
  getDeDiameters,
  getInnerDiameter,
  calcRelDeviationToOptimumByDe,
  calcGridMeanTProfile
};
export default exportedFunctions;
