import React from 'react';
import styles from './ToolbarMenu.module.css';

/**
 * This component draws a vertical line separating different groups of ToolbarMenu items.
 */
const ToolbarMenuDivider = () => {
  return (
    <div className={styles.toolbarSeparator}>
      <div />
    </div>
  );
};

export default ToolbarMenuDivider;
