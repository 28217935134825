import {
  UseMutationOptions,
  UseQueryResult,
  useMutation,
  useQueryClient
} from '@tanstack/react-query';
import {
  ProcessMapDataType,
  UpdateNodeArguments
} from 'ecto-common/lib/ProcessMaps/ProcessMapDataHandling';
import PresentationAPIGen, {
  ListPicturesResponse,
  ProblemDetails
} from 'ecto-common/lib/API/PresentationAPIGen';
import { useContext, useMemo } from 'react';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import _ from 'lodash';
import T from '../lang/Language';
import { NodeType } from '../API/APIGen';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';

export type ListItemsHook =
  | typeof PresentationAPIGen.Pictures.listPictures.useQuery
  | typeof PresentationAPIGen.TenantDashboards.listTenantDashboards.useQuery;
type ListItemsHookOptions = Parameters<ListItemsHook>[1];

// Common code for dashboards and pictures
export const useListQuery = (
  listItemsHook: ListItemsHook,
  nodeTypes?: string[],
  options?: ListItemsHookOptions
): UseQueryResult<ProcessMapDataType[]> => {
  const nodeTypesFilter = useMemo(() => {
    if (nodeTypes?.length > 0) {
      const nodeTypesString = _.map(
        nodeTypes,
        (value) => "'" + value + "'"
      ).join(',');
      return `allowedNodeTypes/any(nodeType: nodeType in (${nodeTypesString}))`;
    }
    return undefined;
  }, [nodeTypes]);

  return listItemsHook(
    {
      $top: 1000, // "Disable" pagination for now until we add proper UI support for it.,
      $orderby: 'name asc',
      $filter: nodeTypesFilter
    },
    {
      ...(options ?? {}),
      meta: _.get(options, 'meta') ?? {
        errorString: T.admin.processmaps.maps.error
      },
      select: (data: ListPicturesResponse) => {
        return data.items as ListPicturesResponse;
      }
    }
  ) as UseQueryResult<ProcessMapDataType[]>;
};

export type GetItemHookType =
  | typeof PresentationAPIGen.Nodes.getPictures.useQuery
  | typeof PresentationAPIGen.Nodes.getDashboards.useQuery;
type GetItemHookOptions = Parameters<GetItemHookType>[1];

export const sortPriority = <ItemType extends { priority?: number }>(
  items: ItemType[]
): ItemType[] => {
  return _.reverse(_.sortBy(items, (item) => item.priority));
};

/**
 * Fetches presentation items related to a node or equipment type
 * @example
 * const { query, nodeTypes } = usePresentationItemQuery({
 *  queryHook: PresentationAPIGen.Nodes.getPictures.useQuery,
 *  nodeId: 'nodeId'
 *  options: { enabled: true }
 *  });
 */
export const usePresentationItemQuery = ({
  queryHook,
  nodeId,
  equipmentTypeId,
  nodeType,
  options
}: {
  /**
   * Fetches presentation items on a specific api endpoint
   */
  queryHook: GetItemHookType;
  /**
   * Fetch items related to the node that matches this node id
   */
  nodeId: string;
  /**
   * Fetch items related to this equipment type
   */
  equipmentTypeId?: string;
  /**
   * Fetch items related to this node type
   */
  nodeType?: string;
  /**
   * Additional options for the query
   */
  options?: GetItemHookOptions;
}) => {
  const equipmentOrNodeId = nodeId ?? equipmentTypeId;

  const { node } = useNode(nodeId);

  const nodeTypes = useMemo(() => {
    const result = _.compact([
      ...(node?.nodeTraitIds ?? []),
      nodeType,
      equipmentTypeId,
      // also add generic equipment for equipment types
      equipmentTypeId ? NodeType.Equipment : null
    ]);
    return result.length === 0 ? undefined : result;
  }, [node?.nodeTraitIds, nodeType, equipmentTypeId]);

  const nodeQuery = useNodeListQuery(queryHook, [], equipmentOrNodeId, options);
  const nodeTypeQuery = useNodeListQuery(queryHook, nodeTypes, null, options);
  const data = useMemo(
    () => ({
      items: sortPriority(
        _.uniqBy(
          [
            ...(nodeQuery.data?.items ?? []),
            ...(nodeTypeQuery.data?.items ?? [])
          ],
          'id'
        )
      )
    }),
    [nodeQuery.data, nodeTypeQuery.data]
  );

  const query = {
    error: nodeQuery.error || nodeTypeQuery.error,
    isFetched: nodeQuery.isFetched || nodeTypeQuery.isFetched,
    isFetching: nodeQuery.isFetching || nodeTypeQuery.isFetching,
    isLoading: nodeQuery.isLoading || nodeTypeQuery.isLoading,
    isPending: nodeQuery.isPending || nodeTypeQuery.isPending,
    isSuccess: nodeQuery.isSuccess || nodeTypeQuery.isSuccess,
    isError: nodeQuery.isError || nodeTypeQuery.isError,
    data,
    refetch: async () => {
      await nodeQuery.refetch();
      await nodeTypeQuery.refetch();
    }
  };

  return {
    query,
    nodeTypes
  };
};

export interface AssignedItemModel {
  id?: string | null;
  /** @format int32 */
  priority?: number | null;
  nodeId?: string | null;
  name?: string | null;
  data?: string | null;
}

type QueryHookType = typeof PresentationAPIGen.Nodes.getPictures.useQuery;
type QueryHookOptions = Parameters<QueryHookType>[1];

// Common code for dashboards and pictures
const useNodeListQuery = (
  queryHook: QueryHookType,
  nodeTypes: string[],
  nodeId: string,
  options?: QueryHookOptions
) => {
  const nodeIds = _.compact([nodeId, ...(nodeTypes ?? [])]);
  return queryHook(
    { nodeIds },
    {
      ...(options ?? {}),
      enabled: !_.isEmpty(nodeIds) && options?.enabled
    }
  );
};

export type AssignItemPromise =
  typeof PresentationAPIGen.Nodes.assignPictures.promise;

export type DeleteItemPromise =
  typeof PresentationAPIGen.Nodes.unassignPicture.promise;

// Update presentation item relations between node and pictures/dashboards
export const useUpdatePresentationItemMutation = (
  assignPromise: AssignItemPromise,
  deletePromise: DeleteItemPromise,
  nodeId: string,
  options?: UseMutationOptions<void, ProblemDetails, UpdateNodeArguments>
) => {
  const { contextSettings } = useContext(TenantContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      originalData,
      assignedPictures
    }: UpdateNodeArguments) => {
      const newOrChangedProcessMaps = _.reduce(
        assignedPictures,
        (result, processMap) => {
          const oldProcessMap = _.find(originalData.items, {
            id: processMap.id
          });
          if (
            !oldProcessMap ||
            oldProcessMap.priority !== processMap.priority
          ) {
            return [...result, processMap];
          }

          return result;
        },
        []
      );

      const deletedProcessMaps = _.differenceBy(
        originalData.items,
        assignedPictures,
        'id'
      );

      const deletePromises = _.map(deletedProcessMaps, (processMap) =>
        deletePromise(
          contextSettings,
          {
            nodeId: processMap.nodeId,
            id: processMap.id
          },
          null
        )
      );
      return Promise.all([
        ...deletePromises,
        newOrChangedProcessMaps.length === 0
          ? Promise.resolve()
          : assignPromise(
              contextSettings,
              { nodeId },
              newOrChangedProcessMaps,
              null
            )
      ]).then(() => {
        queryClient.invalidateQueries({
          queryKey: [
            ...PresentationAPIGen.Nodes.getPictures.path(contextSettings),
            { nodeIds: nodeId }
          ]
        });
      });
    },
    ...(options ?? {})
  });
};
