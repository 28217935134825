import EctoplannerAPIGen, {
  BuildGraphSeries,
  ProjectResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import {
  EctoplannerNavigationTree,
  useEctoplannerPersistentBuilds
} from 'js/components/Ectoplanner/EctoplannerNavigationControls';
import _ from 'lodash';
import React, { useContext, useMemo, useState } from 'react';
import styles from './EctoplannerEditGraphDialog.module.css';
import { EctoplannerBuildStatus } from 'js/components/Ectoplanner/EctoplannerTypes';

import { useTimeseriesTreeViewNodes } from 'js/components/Ectoplanner/EctoplannerUtils';
import TreeView from 'ecto-common/lib/TreeView/TreeView';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import Notice from 'ecto-common/lib/Notice/Notice';
import { TimeseriesTable } from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerEditGraphDialog';
import T from 'ecto-common/lib/lang/Language';
import { useMutation } from '@tanstack/react-query';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { parseTimeSeriesFile } from 'js/components/Ectoplanner/Calculation/EctoplannerCalculationUtil';
import {
  EctoplannerTimeSeries,
  ResultWithTimeseries
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

const emptySeries: BuildGraphSeries[] = [];
const emptyProjects: ProjectResponse[] = [];
const emptyChangedForms: Record<string, boolean> = {};

const EctoplannerSelectGraphDialog = ({
  isOpen,
  onModalClose,
  onSelectedTimeSeries
}: {
  onModalClose: () => void;
  isOpen: boolean;
  onSelectedTimeSeries: (
    projectId: string,
    buildId: string,
    timeSeriesId: string,
    data: number[]
  ) => void;
}) => {
  const { contextSettings } = useContext(TenantContext);

  const [selectedItem, setSelectedItem] = useState<{
    projectId: string;
    buildId: string;
  }>({
    projectId: null,
    buildId: null
  });

  const isEditingProject = selectedItem.buildId === null;

  const allProjectsQuery =
    EctoplannerAPIGen.EctoGridProjects.listProjects.useQuery({});
  const [builds, buildsQueryIsLoading] = useEctoplannerPersistentBuilds({
    projectId: selectedItem.projectId
  });
  const selectedBuild = isEditingProject
    ? builds.find(
        (build) =>
          build.isMasterBuild && build.projectId === selectedItem.projectId
      )
    : builds.find((build) => build.id === selectedItem.buildId);

  const enableBuildResultsQuery =
    !!selectedBuild &&
    selectedBuild.status === EctoplannerBuildStatus.ResultsDone;

  const buildResultsQuery =
    EctoplannerAPIGen.EctoGridBuilds.resultsDetail.useQuery(
      {
        buildId: selectedBuild?.id
      },
      {
        enabled: enableBuildResultsQuery
      }
    );

  const result = buildResultsQuery.data as ResultWithTimeseries;

  const [nodes, selectedNodes, setSelectedId, currentCategory] =
    useTimeseriesTreeViewNodes({
      timeseries: result?.results?.timeseries
    });

  const allProjects = useMemo(() => {
    return sortByLocaleCompare(
      _.filter(allProjectsQuery.data?.items ?? emptyProjects),
      'name'
    );
  }, [allProjectsQuery.data?.items]);

  const onNavigateProject = (newProjectId: string) => {
    setSelectedItem({
      projectId: newProjectId,
      buildId: null
    });
    setSelectedId(null);
  };

  const onNavigateBuild = (newProjectId: string, newBuildId: string) => {
    setSelectedItem({
      projectId: newProjectId,
      buildId: newBuildId
    });
    setSelectedId(null);
  };

  const isLoadingBuildResults =
    (selectedItem.projectId != null && buildsQueryIsLoading) ||
    (enableBuildResultsQuery && buildResultsQuery.isLoading);

  const downloadResultMutation = useMutation({
    mutationFn: (args: { buildId: string; filename: string }) => {
      return EctoplannerAPIGen.EctoGridBuilds.timeseriesDetail.promise(
        contextSettings,
        {
          buildId: args.buildId,
          filename: args.filename
        },
        null
      );
    },

    onSuccess: (res, args) => {
      const numericalRes = parseTimeSeriesFile(res);
      onSelectedTimeSeries(
        selectedItem.projectId,
        selectedItem.buildId,
        args.filename,
        numericalRes
      );
    }
  });

  const onSelectTimeSeries = (timeSeries: EctoplannerTimeSeries) => {
    downloadResultMutation.mutate({
      buildId: selectedBuild.id,
      filename: timeSeries.id
    });
  };

  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  return (
    <ActionModal
      isOpen={isOpen}
      large
      wide
      onModalClose={onModalClose}
      title={T.ectoplanner.graphs.selectgraph}
      onConfirmClick={onModalClose}
      headerIcon={Icons.Signal}
      messageBodyClassName={styles.body}
      className={styles.dialog}
      disableCancel
      actionText={T.common.done}
      isLoading={downloadResultMutation.isPending}
    >
      <div className={styles.container}>
        <div className={styles.pickerContainer}>
          <div className={styles.item}>
            <EctoplannerNavigationTree
              allProjects={allProjects}
              builds={builds}
              isLoadingBuildsForProject={buildsQueryIsLoading}
              changedForms={emptyChangedForms}
              onNavigateProject={onNavigateProject}
              onNavigateBuild={onNavigateBuild}
              projectId={selectedItem.projectId}
              buildId={selectedItem.buildId}
              embedded
              expanded={expanded}
              setExpanded={setExpanded}
            />
          </div>
          <div className={styles.item}>
            {isLoadingBuildResults && (
              <div className={styles.paddedArea}>
                <Spinner />
              </div>
            )}

            {!isLoadingBuildResults &&
              nodes?.length === 0 &&
              selectedItem.projectId !== null && (
                <Notice className={styles.paddedArea}>
                  {T.ectoplanner.graphs.notimeseries}
                </Notice>
              )}

            <TreeView
              embedded
              nodes={nodes}
              onClickNode={(node) => {
                setSelectedId(node.id);
              }}
              selectedNodes={selectedNodes}
              selectFolder={false}
            />
          </div>
          <div className={styles.item}>
            {currentCategory && (
              <TimeseriesTable
                onAddTimeSeries={onSelectTimeSeries}
                timeseries={currentCategory.timeseries}
                parentName={currentCategory.name}
                selectedSeries={emptySeries}
              />
            )}
          </div>
        </div>
      </div>
    </ActionModal>
  );
};

export default React.memo(EctoplannerSelectGraphDialog);
