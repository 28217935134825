import { useUndoHistoryWithManager } from 'ecto-common/lib/hooks/useUndoHistory';
import { useMemo, useRef } from 'react';
import UndoManager from 'ecto-common/lib/utils/UndoManager';
import _ from 'lodash';

/**
 * This handles switching undo/redo history between different data in a collection of data. For instance
 * a personal dashboard page where you can switch between dashboards on the same page and want the undo
 * stack to change whenever you change dashboard.
 *
 * @param selectedId The currently selected ID in the collection. Used to switch between undo stacks.
 * @param data The initial data for the currently selected collection.
 * @param setData Called when the currently selected collection needs to be updated.
 */
const useUndoHistories = <Type>(
  selectedId: string,
  data: Type,
  setData: (data: Type) => void
): [
  canUndo: boolean,
  canRedo: boolean,
  add: (arg: Type) => void,
  undo: () => void,
  redo: () => void
] => {
  // Keep one undo manager per ID, populate on demand when selected ID changes.
  const undoManagers = useRef<Record<string, UndoManager<Type>>>({});

  const currentManager = useMemo(() => {
    if (undoManagers.current[selectedId] == null) {
      if (data != null) {
        undoManagers.current[selectedId] = new UndoManager<Type>([
          _.cloneDeep(data)
        ]);
      } else {
        undoManagers.current[selectedId] = new UndoManager<Type>([]);
      }
    } else if (data != null && !undoManagers.current[selectedId].hasHistory) {
      undoManagers.current[selectedId] = new UndoManager<Type>([
        _.cloneDeep(data)
      ]);
    }

    return undoManagers.current[selectedId];
  }, [data, selectedId]);

  return useUndoHistoryWithManager<Type>(setData, currentManager);
};

export default useUndoHistories;
