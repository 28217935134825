import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import MarkdownPageContent from 'ecto-common/lib/Markdown/MarkdownPageContent';
import T from 'ecto-common/lib/lang/Language';
import {
  defaultFile,
  getHelpFile,
  getToc
} from 'ecto-common/help/markdownFiles';
import PageMenu from 'ecto-common/lib/PageMenu/PageMenu';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

const HelpPage = () => {
  const navigate = useNavigate();
  const params = queryString.parse(window.location?.search);
  const doc = params?.doc as string;
  const [selectedMarkdownFile, setSelectedMarkdownFile] = useState(
    doc ?? defaultFile
  );
  const fileKey = doc ?? selectedMarkdownFile;
  const language = useCommonSelector((state) => state.general.language);
  const toc = getToc(language);
  let file = getHelpFile(language, fileKey);
  const fallbackIsoCode = 'en';

  if (!file && language !== fallbackIsoCode) {
    file = getHelpFile(fallbackIsoCode, fileKey);
  }
  useEffect(() => {
    document.title = T.location.page.helptitle;
  }, []);
  useEffect(() => {
    setSelectedMarkdownFile((currentFile) =>
      fileKey && !currentFile ? fileKey : currentFile
    );
  }, [fileKey]);

  const onLinkClick = useCallback(
    (docName: string) => {
      if (docName.startsWith('#')) {
        setSelectedMarkdownFile(
          (prevState) => prevState?.replace(/#.*/, '') + docName
        );
      } else {
        navigate(
          '?' + queryString.stringify({ doc: docName }, { encode: false })
        );
        setSelectedMarkdownFile(docName);
      }
    },
    [navigate]
  );

  return (
    <ToolbarContentPage
      showLocationPicker={false}
      wrapContent={false}
      title={T.location.page.helptitle}
      sideMenu={
        <PageMenu toc={toc} onClick={onLinkClick} currentPageId={fileKey} />
      }
    >
      <MarkdownPageContent
        onLinkClick={onLinkClick}
        currentPageId={fileKey}
        file={file}
      />
    </ToolbarContentPage>
  );
};

export default HelpPage;
