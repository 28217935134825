import React, { useEffect, useMemo, useState } from 'react';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { UnlocalizedString } from 'ecto-common/lib/lang/Language';
import ExportOptions, {
  defaultExportOptions,
  ExportOptionsType
} from 'js/components/ExportData/ExportOptions';
import _ from 'lodash';
import T from 'ecto-common/lib/lang/Language';
import styles from './ExportDataDialog.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import ConfirmExportSignalsDialog from 'js/containers/ExportPage/ConfirmExportSignalsDialog';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import { getSignalTypeNameWithUnitFromMap } from 'ecto-common/lib/SignalSelector/SignalUtils';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';

const columns = [
  {
    label: T.equipment.displayname,
    dataKey: 'item.name',
    minWidth: 100,
    flexGrow: 2
  },
  {
    label: T.equipment.signaltype,
    dataKey: 'signalTypeName',
    minWidth: 100,
    flexGrow: 2
  },
  {
    label: T.equipment.category,
    dataKey: 'group.signalProviderName',
    minWidth: 100,
    flexGrow: 2,
    dataFormatter: (value: string) => {
      const localization =
        T.signalproviders.type[value as keyof typeof T.signalproviders.type];
      return localization !== UnlocalizedString ? localization : value;
    }
  }
];

interface ExportDataDialogProps {
  isOpen?: boolean;
  onModalClose: () => void;
  selectedSignals?: ChartSignal[];
}

const ExportDataDialog = ({
  isOpen,
  onModalClose,
  selectedSignals
}: ExportDataDialogProps) => {
  const [options, setOptions] = useState<ExportOptionsType>(
    _.cloneDeep(defaultExportOptions())
  );
  const [dialogVisible, showDialog, hideDialog] = useSimpleDialogState();
  const signalTypesMap = useOperatorSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useOperatorSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const selectedSignalsWithTypes = useMemo(
    () =>
      _.map(selectedSignals, (signal) => {
        return {
          ...signal,
          signalTypeName: getSignalTypeNameWithUnitFromMap(
            signal.item.signalTypeId,
            signalTypesMap,
            signalUnitTypesMap
          )
        };
      }),
    [selectedSignals, signalTypesMap, signalUnitTypesMap]
  );

  useEffect(() => {
    setOptions((oldData) => {
      return {
        ...oldData,
        selectedSignals
      };
    });
  }, [selectedSignals]);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={showDialog}
      title={T.exportpage.exportdata}
      className={styles.dialog}
      headerIcon={Icons.Download}
      actionText={T.exportpage.exportdata}
      cancelText={T.common.done}
    >
      <ExportOptions
        options={options}
        setOptions={setOptions}
        expandingWidthDateInputs
      />
      <DataTable columns={columns} data={selectedSignalsWithTypes} />
      <ConfirmExportSignalsDialog
        data={options}
        isOpen={dialogVisible}
        onModalClose={hideDialog}
        onExportCompleted={onModalClose}
      />
    </ActionModal>
  );
};

export default React.memo(ExportDataDialog);
