import classNames from 'classnames';
import styles from 'ecto-common/lib/KeyValueInput/KeyValueInput.module.css';
import { KeyValueLabel } from 'ecto-common/lib/KeyValueInput/KeyValueLabel';
import React from 'react';
import ModelHelpText from 'ecto-common/lib/ModelForm/ModelHelpText';

export type HorizontalLabelValueWeights = [
  labelWeight: number,
  valueWeight: number
];

export const DefaultHorizontalWeights: HorizontalLabelValueWeights = [1, 2];

export interface KeyValueGenericProps {
  /**
   * Custom content to be wrapped.
   */
  children: React.ReactNode;
  /**
   * Text describing the component. Shown above the component with a small font.
   */
  keyText: React.ReactNode;
  /**
   * Used to override the appearance of the input. Should be a valid CSS class name.
   */
  className?: string;
  /**
   * Whether or not to render the key and value on one line.
   */
  isHorizontal?: boolean;
  /**
   * If using horizontal layout, specify the layout weights of the value and label. For instance, to have the label take 40% and the value take 60% of the available width,
   * you could pass a value of [4, 6].
   */
  horizontalWeights?: HorizontalLabelValueWeights;
  /**
   * Used to override the appearance of the inner container which encapsulates your component. Should be a valid CSS class name.
   */
  valueItemClassName?: string;
  /**
   * If set to true, show a small delimiter border at the bottom of the control.
   */
  withDivider?: boolean;
  /**
   * A helpful text explaining the purpose of the input control
   */
  helpText?: React.ReactNode;

  /**
   * If true, the help text will be shown as a tooltip instead of a label.
   */
  useTooltipHelpTexts?: boolean;
}

/**
 * KeyValueGeneric allows you to annotate a custom component with a key header label in a manner similar to KeyValueInput.
 */
export const KeyValueGeneric = ({
  isHorizontal,
  children,
  keyText,
  className,
  valueItemClassName,
  horizontalWeights = DefaultHorizontalWeights,
  withDivider = false,
  helpText = null,
  useTooltipHelpTexts = false
}: KeyValueGenericProps) => {
  return (
    <>
      <div
        className={classNames(
          styles.keyValueContainer,
          className,
          isHorizontal && styles.keyValueContainerHorizontal,
          withDivider && !helpText && styles.divider
        )}
      >
        <KeyValueLabel
          className={isHorizontal ? styles.keyValueLabelHorizontal : null}
          style={{ flexGrow: isHorizontal ? horizontalWeights[0] : 1 }}
          tooltipText={useTooltipHelpTexts && helpText}
        >
          {keyText}
        </KeyValueLabel>
        <div
          className={classNames(styles.valueItem, valueItemClassName)}
          style={{ flexGrow: isHorizontal ? horizontalWeights[1] : 1 }}
        >
          {children}
        </div>
      </div>
      {helpText && !useTooltipHelpTexts && (
        <ModelHelpText className={withDivider ? styles.divider : undefined}>
          {helpText}
        </ModelHelpText>
      )}
    </>
  );
};
