import {
  EquipmentTemplateResponseModel,
  EquipmentTypeResponseModel,
  NodeEquipmentResponseModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import { AdminSignalTemplates } from 'ecto-common/lib/types/EctoCommonTypes';
import {
  AdminAlarmSignalTemplateResponseModel,
  EquipmentSignalTemplateResponseModel
} from 'ecto-common/lib/API/APIGen';

export type AlarmOrEqSignalTemplateWithProviderType = (
  | AdminAlarmSignalTemplateResponseModel
  | EquipmentSignalTemplateResponseModel
) & {
  type: SignalProviderType;
};

export type AlarmOrEqSignalTemplateWithProviderAndConnectionIdType =
  AlarmOrEqSignalTemplateWithProviderType & {
    connectionId?: string;
  };

export function getSignalsForEquipmentType(
  equipmentTypeId: string,
  alarmSignalGroupTemplateId: string,
  signalTemplates: AdminSignalTemplates
): AlarmOrEqSignalTemplateWithProviderType[] {
  const signalGroupTemplate =
    signalTemplates.equipmentSignalTemplates[equipmentTypeId];
  const alarmSignalTemplate = signalTemplates.alarmSignalTemplates.find(
    (e) => e.alarmSignalGroupTemplateId === alarmSignalGroupTemplateId
  );

  let signals: AlarmOrEqSignalTemplateWithProviderType[] = [];
  if (signalGroupTemplate) {
    signals = signalGroupTemplate.signalTemplates.map((s) => ({
      ...s,
      type: SignalProviderType.Equipment
    }));
  }

  if (alarmSignalTemplate) {
    signals = signals.concat(
      alarmSignalTemplate.alarmSignalTemplates.map((s) => ({
        ...s,
        type: SignalProviderType.Alarm
      }))
    );
  }

  return signals;
}

export function isEquipmentOfType(
  id: string,
  type: string,
  equipmentTypes: EquipmentTypeResponseModel[]
) {
  return getEquipmentTypeName(id, equipmentTypes) === type;
}

export function getEquipmentTypeName(
  id: string,
  equipmentTypes: EquipmentTypeResponseModel[]
) {
  const type = equipmentTypes.find(
    (equipmentType) => equipmentType.equipmentTypeId === id
  );
  return type ? type.name : null;
}

export function beautifyEquipmentName(name: string | undefined): string {
  if (name == null) {
    return '';
  }

  // Short names are just abbreviations, return without spaces. Also
  // return equipment names that already have spaces
  if (name.length <= 4 || name.search(' ') !== -1) {
    return name;
  }

  // Add spaces between words in CamelCase notation for display
  let prevCharWasUpperCase = false;
  let spacedName = '';

  for (let i = 0; i < name.length; i++) {
    const ch = name[i];
    if (ch === ch.toUpperCase()) {
      if (i > 0 && !prevCharWasUpperCase) {
        spacedName += ' ';
      }

      prevCharWasUpperCase = true;
    } else {
      prevCharWasUpperCase = false;
    }

    spacedName += ch;
  }

  return spacedName;
}

export function getRawEquipmentName(
  id: string,
  equipmentTypes: EquipmentTypeResponseModel[]
): string {
  const type = equipmentTypes.find(
    (equipmentType) => equipmentType.equipmentTypeId === id
  );

  if (!type) {
    return '';
  }

  return type.name;
}

export function getEquipmentNameFromTraits(
  nodeTraitIds: string[],
  equipmentTypes: EquipmentTypeResponseModel[]
): string {
  const type = equipmentTypes.find((equipmentType) =>
    nodeTraitIds.includes(equipmentType.equipmentTypeId)
  );

  if (!type) {
    return '';
  }

  return beautifyEquipmentName(type.name);
}

export function getEquipmentName(
  id: string,
  equipmentTypes: EquipmentTypeResponseModel[]
): string {
  const type = equipmentTypes.find(
    (equipmentType) => equipmentType.equipmentTypeId === id
  );

  if (!type) {
    return '';
  }

  return beautifyEquipmentName(type.name);
}

export function getDefaultEquipmentId(
  equipmentTypes: EquipmentTypeResponseModel[]
) {
  const type = equipmentTypes.find(
    (equipmentType) => equipmentType.name === 'Radiator'
  );
  return type ? type.equipmentTypeId : '';
}

export function getEnergyManagerEquipmentTypeId(
  equipmentTypes: EquipmentTypeResponseModel[]
) {
  const type = equipmentTypes.find(
    (equipmentType) => equipmentType.name === 'EnergyManager'
  );
  return type ? type.equipmentTypeId : '';
}

export function isEquipmentTypeEnergyManager(
  equipmentTypeId: string,
  equipmentTypes: EquipmentTypeResponseModel[]
) {
  return isEquipmentOfType(equipmentTypeId, 'EnergyManager', equipmentTypes);
}

export function isEquipmentEnergyManager(
  equipment: NodeEquipmentResponseModel | EquipmentTemplateResponseModel,
  equipmentTypes: EquipmentTypeResponseModel[]
) {
  return isEquipmentTypeEnergyManager(
    equipment.equipmentTypeId,
    equipmentTypes
  );
}

export const EquipmentTypes = {
  ENERGY_MANAGER: '1d6078f6-7c13-4bb9-aa48-d9482161929a',
  ECTOGRID_HEATING_SUBSYSTEM: '87097ba9-1c04-42cd-ad90-d0742c2f3b29',
  ECTOGRID_COOLING_SUBSYSTEM: 'a662e171-24e9-4c86-8ffa-7086b2d3b41f',
  ECTOGRID_ACTIVE_REVERSIBLE_HEAT_PUMP: '7542163b-3a50-4686-9d56-755940265c23', // ectogrid™ Active Balancing Reversible Heat Pump
  ECTOGRID_ACTIVE_DISTRICT_HEATING: 'eaad695a-e50c-4c97-949d-73d876d68bee', // ectogrid™ Active Balancing District Heating
  ECTOGRID_ACTIVE_COOLING_TOWER: 'cc15f8e9-cd5a-4751-b349-b8002bd36516', // ectogrid™ Active Balancing Cooling Tower,
  ECTOGRID_PASSIVE_BALANCING_UNIT: '201a1aa7-d914-4247-ac28-02faa4357f65' // ectogrid™ Passive Balancing Unit
} as const;

export type EquipmentType =
  (typeof EquipmentTypes)[keyof typeof EquipmentTypes];
