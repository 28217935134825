import localStore from 'store';

export const CACHE_KEY_PREFIX = 'ectoCache-';
export const CACHE_KEY_NODES = CACHE_KEY_PREFIX + 'nodes-';
export const CACHE_KEY_NODE_ID = CACHE_KEY_PREFIX + 'node-id-';

export const storeCurrentNodeId = (tenantId: string, nodeId: string) => {
  if (nodeId == null) {
    return;
  }

  localStore.set(
    CACHE_KEY_NODE_ID + tenantId,
    JSON.stringify({ nodeId: nodeId ?? null })
  );
};

export const getLastNodeId = (tenantId: string) => {
  const storeData = localStore.get(CACHE_KEY_NODE_ID + tenantId);

  if (storeData != null) {
    return JSON.parse(storeData);
  }

  return { nodeId: null };
};
