import { useNavigate } from 'react-router-dom';
import { getSignalProvidersPage } from '../utils/commonLinkUtil';
import React, { useCallback, useContext } from 'react';
import TenantContext from '../hooks/TenantContext';
import _ from 'lodash';
import APIGen from '../API/APIGen';
import { ProcessMapDropdownShowMenuCallback } from './useProcessMapDropdown';
import sortByLocaleCompare from '../utils/sortByLocaleCompare';
import { beautifyEquipmentName } from '../utils/equipmentTypeUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useNodeChildren } from 'ecto-common/lib/hooks/useCurrentNode';

const useEquipmentLinkAction = ({
  nodeId,
  showMenu,
  hideMenu
}: {
  nodeId: string;
  showMenu: ProcessMapDropdownShowMenuCallback;
  hideMenu: () => void;
}) => {
  const navigate = useNavigate();
  const { contextSettings } = useContext(TenantContext);
  const { nodeChildren } = useNodeChildren([nodeId]);
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  return useCallback(
    (event: MouseEvent, signalTraitId: string) => {
      if (nodeId == null) {
        return;
      }

      const equipments = _.filter(nodeChildren, (x) =>
        x.nodeTraitIds.includes(signalTraitId)
      );

      if (equipments.length === 0) {
        return;
      }

      const sortedEquipment = sortByLocaleCompare(equipments, 'name');
      showMenu(
        sortedEquipment.map((equipment) => ({
          label: equipment.name,
          disableCloseOnClick: true,
          subtitle: beautifyEquipmentName(
            traitsQuery.data?.find((x) => x.id === signalTraitId)?.name
          ),
          icon: <Icons.ArrowRight />,
          action: () => {
            hideMenu();
            navigate(getSignalProvidersPage(contextSettings.tenantId, nodeId));
          }
        })),
        {
          x: event.clientX,
          y: event.clientY
        }
      );
    },
    [
      nodeId,
      nodeChildren,
      showMenu,
      traitsQuery.data,
      hideMenu,
      navigate,
      contextSettings.tenantId
    ]
  );
};

export default useEquipmentLinkAction;
