import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';
import { hasAccessToResource } from 'ecto-common/lib/utils/accessAndRolesUtil';
import { ResourceType } from 'ecto-common/lib/constants';

import PowerControl from 'js/components/EMPTools/PowerControl/PowerControl';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import APIGen, {
  SignalGroupLiteResponseModel
} from 'ecto-common/lib/API/APIGen';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import { useParams } from 'react-router-dom';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { useCurrentNode } from 'ecto-common/lib/hooks/useCurrentNode';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';

const collectPowerControls = (
  powerControls: SignalGroupLiteResponseModel[],
  parentName: string
): SignalGroupLiteResponseModel[] => {
  return _.map(powerControls || [], (control) => ({
    ...control,
    parentName
  }));
};

interface PowerControlPageProps {
  onTitleChanged: (title: string[]) => void;
}

const PowerControlPage = ({ onTitleChanged }: PowerControlPageProps) => {
  const { nodeId } = useParams<NodeParams>();

  const { currentNode, isLoading } = useCurrentNode();
  const controlsQuery = APIGen.PowerControl.getPowerControlByNodeV2.useQuery({
    nodeIds: [nodeId]
  });

  const controls: SignalGroupLiteResponseModel[] = useMemo(
    () =>
      collectPowerControls(
        controlsQuery.data?.powerControls,
        currentNode?.name
      ),
    [controlsQuery.data?.powerControls, currentNode?.name]
  );
  const { tenantResources } = useContext(TenantContext);

  usePageTitleCallback({
    mainTitle: T.location.tabs.powercontrol,
    subTitle: '',
    onTitleChanged
  });

  if (!hasAccessToResource(ResourceType.POWER_CONTROL, tenantResources)) {
    return (
      <ToolbarContentPage
        title={T.location.tabs.powercontrol}
        showLocationPicker={false}
      >
        {T.powercontrol.unauthorized.text}
      </ToolbarContentPage>
    );
  }

  const showLoading = isLoading || controlsQuery.isLoading;

  return (
    <ToolbarContentPage
      title={T.location.tabs.powercontrol}
      padContent={false}
      helpPath={HelpPaths.docs.operator.powercontrol}
    >
      {showLoading && <LoadingContainer isLoading />}
      {!showLoading && (
        <PowerControl controls={controls} selectedNode={currentNode} />
      )}
    </ToolbarContentPage>
  );
};

export default PowerControlPage;
