import React from 'react';
import Heading from '../Heading/Heading';
import styles from './Toolbar.module.css';
import classNames from 'classnames';

interface ToolbarHeadingProps {
  /**
   * The title of the heading.
   */
  title?: React.ReactNode;
  /**
   * Should be used in conjunction with isPageHeadingToolbar on the Toolbar. Makes the heading look good in a page toolbar context.
   */
  isPageHeading?: boolean;
  /**
   * Used to override the appearance of the toolbar heading. Should be a valid CSS class name.
   */
  className?: string;
}

/**
 * This component renders a heading which looks good inside a Toolbar.
 */
const ToolbarHeading = ({
  title,
  className,
  isPageHeading = false
}: ToolbarHeadingProps) => {
  return (
    <Heading
      className={classNames(
        isPageHeading ? styles.toolbarPageHeading : styles.toolbarHeading,
        className
      )}
      level={isPageHeading ? 2 : 4}
    >
      {title}
    </Heading>
  );
};

export default React.memo(ToolbarHeading);
