import APIGen, {
  NodeV2ResponseModel,
  NodeSearchResultsResponseModel
} from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps,
  DataTableSectionHeader,
  DataTableSectionHeaderType
} from 'ecto-common/lib/DataTable/DataTable';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import React, { useMemo } from 'react';
import _ from 'lodash';
import styles from './PageTreeView.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';

type LocationSearchProps = {
  isLoading: boolean;
  searchResults: NodeSearchResultsResponseModel;
  onNodeSelected: (nodeId: string) => void;
};

const LocationSearch = ({
  isLoading,
  searchResults,
  onNodeSelected
}: LocationSearchProps) => {
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const columns = useMemo<DataTableColumnProps<NodeV2ResponseModel>[]>(() => {
    return [
      {
        dataKey: '_unused',
        width: 20,
        maxWidth: 20,
        dataFormatter: (_unused, item) => {
          let icon = <Icons.Site />;

          if (item.nodeTraitIds.includes(NodeTraitIds.BUILDING)) {
            icon = <Icons.Building />;
          } else if (item.nodeTraitIds.includes(NodeTraitIds.EQUIPMENT)) {
            icon = <Icons.Equipment />;
          }

          return icon;
        }
      },
      {
        dataKey: 'name',
        linkColumn: true
      },
      {
        dataKey: 'nodeTraitIds',
        dataFormatter: (traitIds: string[]) => {
          const traits = sortByLocaleCompare(
            _.compact(
              _.map(traitIds, (traitId) => {
                return _.find(traitsQuery.data, { id: traitId });
              })
            ),
            'name'
          );

          return (
            <TagsGroup
              className={styles.traitTag}
              tags={_.orderBy(traits, 'name').map((t) => t.name)}
            />
          );
        }
      }
    ];
  }, [traitsQuery.data]);

  const searchTableData: (NodeV2ResponseModel | DataTableSectionHeaderType)[] =
    useMemo(() => {
      const ret: (NodeV2ResponseModel | DataTableSectionHeaderType)[] = [];

      let prevParentId = null;

      for (const item of searchResults?.nodes ?? []) {
        if (prevParentId !== item.parentId) {
          let currentParentId = item.parentId;

          let parentText = '';
          while (currentParentId != null) {
            const parent = searchResults?.parents.find(
              (otherParent) => otherParent.nodeId === currentParentId
            );
            if (parentText === '') {
              parentText = parent?.name;
            } else {
              parentText = parent?.name + ' > ' + parentText;
            }

            currentParentId = parent?.parentId;
          }

          ret.push(
            DataTableSectionHeader(
              <div className={styles.searchSectionHeader}>{parentText}</div>
            )
          );
          prevParentId = item.parentId;
        }

        ret.push(item);
      }

      return ret;
    }, [searchResults?.nodes, searchResults?.parents]);

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner size={SpinnerSize.SMALL} />
      </div>
    );
  }

  const onClickRow = (node: NodeV2ResponseModel) => {
    onNodeSelected(node.nodeId);
  };

  return (
    <div className={styles.searchResults}>
      <DataTable
        columns={columns}
        onClickRow={onClickRow}
        data={searchTableData}
        isLoading={isLoading}
        disableHeader
        inline
        smaller
      />
    </div>
  );
};

export default React.memo(LocationSearch);
