import React from 'react';
import EctoplannerResultView from 'js/components/Ectoplanner/EctoplannerResultView';
import { BuildResponse } from 'ecto-common/lib/API/EctoplannerAPIGen';
import { useParams } from 'react-router-dom';
import { EctoplannerParams } from 'js/utils/routeConstants';

interface EctoplannerResultsProps {
  selectedBuild: BuildResponse;
  projectId?: string;
  isRunningCalculation?: boolean;
  formIsUpToDate: boolean;
}

const EctoplannerResults = ({
  projectId,
  selectedBuild,
  isRunningCalculation,
  formIsUpToDate
}: EctoplannerResultsProps) => {
  const params = useParams<EctoplannerParams>();

  return (
    <>
      <EctoplannerResultView
        projectId={projectId}
        buildId={selectedBuild?.id}
        build={selectedBuild}
        curSection={params.itemId}
        isRunningCalculation={isRunningCalculation}
        formIsUpToDate={formIsUpToDate}
      />
    </>
  );
};

export default React.memo(EctoplannerResults);
