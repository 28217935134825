import { useContext, useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import _ from 'lodash';
import SectionListPriority from 'ecto-common/lib/Dashboard/SectionListPriority';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import {
  useNodeChildren,
  useNodes
} from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';

type NodesDataSourceProps = {
  nodeIds?: string[];
  useSiblings?: boolean;
};

const NodesDataSource = ({
  nodeIds,
  useSiblings = false
}: NodesDataSourceProps) => {
  const { nodeId: currentNodeId } = useContext(DashboardDataContext);

  _.noop(useSiblings);

  const allNodeIds = useMemo(() => {
    if (nodeIds && nodeIds.length > 0) {
      return nodeIds;
    }

    return [currentNodeId];
  }, [currentNodeId, nodeIds]);

  const { nodes: nodesList } = useNodes(allNodeIds);

  const parentIds = useMemo(() => {
    if (!useSiblings) {
      return [];
    }

    return _.uniq(
      _.compact(
        _.map(nodesList, (node) => {
          // If the node is a site, we want to show all children of the site, so we return the site node id
          if (node.nodeTraitIds.includes(NodeTraitIds.SITE)) {
            return node.nodeId;
          }

          // Otherwise, it is a building, equipment or similar, so we return
          // the parent id of the node, meaning we want to see all siblings on
          // the same level as the node itself
          return node.parentId;
        })
      )
    );
  }, [nodesList, useSiblings]);

  const allSiblingsQuery = useNodeChildren(parentIds);

  return useMemo(() => {
    return _.uniqBy(nodesList.concat(allSiblingsQuery.nodeChildren), 'nodeId');
  }, [nodesList, allSiblingsQuery.nodeChildren]);
};

export const nodesDataSourceSections: () => ModelFormSectionType<NodesDataSourceProps>[] =
  () => [
    {
      lines: [
        {
          models: [
            {
              key: (input) => input.nodeIds,
              label: T.admin.dashboards.panels.types.nodename.node,
              modelType: ModelType.NODE_LIST,
              placeholder:
                T.admin.dashboards.panels.types.nodename.nodeplaceholder
            }
          ]
        }
      ],
      listPriority: SectionListPriority.Location
    }
  ];

export default NodesDataSource;
