import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import React, { useMemo, useState } from 'react';
import calcPressureLoss from 'js/components/Ectoplanner/Calculation/utils/calcPressureLoss';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import Button from 'ecto-common/lib/Button/Button';
import Icons from 'ecto-common/lib/Icons/Icons';
import T from 'ecto-common/lib/lang/Language';
import ArrayUtils from 'js/components/Ectoplanner/Calculation/utils/arrayUtils';
import { SeriesOptionsType } from 'highcharts/highstock';
import LineChart, {
  HighchartsOptionsWithCrossing
} from 'ecto-common/lib/Charts/LineChart';
import moment from 'moment';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import _ from 'lodash';
import { hasValidFormValues } from 'ecto-common/lib/hooks/useFormState';

type EctoplannerPipeDialogProps = {
  isOpen: boolean;
  onModalClose: () => void;
  form: EctoplannerForm;
};

type PipeGenStatEntry = {
  volFlowRate_m3_s: string;
  volFlowRate_m3_h: string;
  volFlowRate_ltr_s: string;
  massFlowRate_kg_s: string;
  massFlowRate_t_h: string;
};

type PipeStatEntry = {
  annualPumpWork: string;
  minFlowVelocity: string;
  maxFlowVelocity: string;
  maxPressureGradient: string;
};

type PipeResults = {
  genStatsTable: PipeGenStatEntry;
  profileDict: Record<number, number[]>;
  pipeStatsTable: Record<number, PipeStatEntry>;
};

const pipeDiameters = calcPressureLoss.getDeDiameters();

type PipeSettings = {
  selectedBuildings: number[];
  selectedPipeDiameters: number[];
  tempDifference: number;
  pipeRoughness: number;
  pressureClass: string;
};

const defaultPipeSettings: PipeSettings = {
  selectedBuildings: null,
  selectedPipeDiameters: [],
  tempDifference: 10,
  pipeRoughness: 0.1,
  pressureClass: 'PN10'
};

const pressureClassOptions = [
  {
    label: 'PN10',
    value: 'PN10'
  },
  {
    label: 'PN16',
    value: 'PN16'
  }
];

const EctoplannerPipeResults = ({
  results,
  selectedPipeDiameters
}: {
  results: PipeResults;
  selectedPipeDiameters: number[];
}) => {
  const startOfYear = moment().utc().startOf('year').valueOf();
  const series: SeriesOptionsType[] = useMemo(() => {
    return Object.entries(results.profileDict).map(([diam, profile]) => {
      return {
        name: T.format(T.ectoplanner.pipes.pipeformat, diam).join(''),
        type: 'line',
        data: profile.map((value, index) => [
          startOfYear + index * 3600 * 1000,
          value
        ])
      };
    });
  }, [results.profileDict, startOfYear]);

  const config: HighchartsOptionsWithCrossing = {
    legend: {
      enabled: true
    },
    navigator: {
      enabled: true
    },
    tooltip: {
      shared: true,
      valueDecimals: 4,
      valueSuffix: ' ' + T.ectoplanner.units.pam
    },
    xAxis: {
      crosshair: true,
      type: 'datetime',
      gridLineWidth: 0
    },
    yAxis: {
      title: {
        text: T.ectoplanner.unitdescriptions.pam
      }
    },
    exporting: {
      filename: 'pipe-dimensioning-results'
    }
  };

  const columns: DataTableColumnProps<string[]>[] = useMemo(
    () => [
      {
        label: '',
        dataKey: '[0]'
      },
      ...selectedPipeDiameters.map((diam, index) => ({
        label: T.format(T.ectoplanner.pipes.pipeformat, diam),
        dataKey: '[' + (index + 1) + ']'
      }))
    ],
    [selectedPipeDiameters]
  );

  const rows: string[][] = [
    [
      T.ectoplanner.unitdescriptions.maxpam,
      ...selectedPipeDiameters.map(
        (diam) => results.pipeStatsTable[diam].maxPressureGradient
      )
    ],
    [
      T.ectoplanner.unitdescriptions.maxflowvel,
      ...selectedPipeDiameters.map(
        (diam) => results.pipeStatsTable[diam].maxFlowVelocity
      )
    ],
    [
      T.ectoplanner.unitdescriptions.minflowvel,
      ...selectedPipeDiameters.map(
        (diam) => results.pipeStatsTable[diam].minFlowVelocity
      )
    ],
    [
      T.ectoplanner.unitdescriptions.specannpumpwork,
      ...selectedPipeDiameters.map(
        (diam) => results.pipeStatsTable[diam].annualPumpWork
      )
    ]
  ];

  const summaryColumns = [
    {
      dataKey: '[0]'
    },
    {
      dataKey: '[1]'
    }
  ];

  const summaryRows = [
    [
      T.ectoplanner.pipes.summarymaxvolumeflowrate,
      results.genStatsTable.volFlowRate_m3_h +
        ' ' +
        T.ectoplanner.units.m3h +
        ' (' +
        results.genStatsTable.volFlowRate_ltr_s +
        ' ' +
        T.ectoplanner.units.ls +
        ')'
    ],
    [
      T.ectoplanner.pipes.summarymaxmassflowrate,
      results.genStatsTable.massFlowRate_kg_s +
        ' ' +
        T.ectoplanner.units.kgs +
        ' (' +
        results.genStatsTable.massFlowRate_t_h +
        ' ' +
        T.ectoplanner.units.th +
        ')'
    ]
  ];

  return (
    <div>
      <LineChart options={config} series={series} />
      <DataTable<string[]> columns={columns} data={rows} verticalPadding={0} />
      <DataTable<string[]> columns={summaryColumns} data={summaryRows} />
    </div>
  );
};

const EctoplannerPipeDialog = ({
  form,
  isOpen,
  onModalClose
}: EctoplannerPipeDialogProps) => {
  const [pipeSettings, setPipeSettings] =
    useState<PipeSettings>(defaultPipeSettings);
  const [recommendedDiameter, setRecommendedDiameter] = useState<number>(null);
  const [results, setResults] = useState<PipeResults>(null);

  const models: ModelDefinition<PipeSettings>[] = useMemo(
    () => [
      {
        modelType: ModelType.OPTIONS,
        key: (input) => input.selectedBuildings,
        label: T.ectoplanner.pipes.form.buildings,
        options: form.buildings.map((building, index) => ({
          label: building.name,
          value: index
        })),
        isMultiOption: true,
        emptyValueSelectsAll: true
      },
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.tempDifference,
        label: T.ectoplanner.pipes.form.tempdiff,
        hasError: _.isNil
      },
      {
        modelType: ModelType.NUMBER,
        key: (input) => input.pipeRoughness,
        label: T.ectoplanner.pipes.form.piperoughness,
        hasError: _.isNil
      },
      {
        modelType: ModelType.OPTIONS,
        key: (input) => input.pressureClass,
        label: 'Pressure class',
        options: pressureClassOptions,
        hasError: _.isNil
      },
      {
        modelType: ModelType.OPTIONS,
        key: (input) => input.selectedPipeDiameters,
        label: T.ectoplanner.pipes.form.pipediameters,
        helpText:
          recommendedDiameter != null
            ? T.format(
                T.ectoplanner.pipes.recommendeddiameterformat,
                recommendedDiameter
              )
            : null,
        options: pipeDiameters.map((diameter) => ({
          label: T.format(T.ectoplanner.pipes.pipeformat, diameter),
          value: diameter
        })),
        isMultiOption: true
      }
    ],
    [form.buildings, recommendedDiameter]
  );

  const pipeSizingModalState: Record<string, boolean> = {};
  const buildingList =
    pipeSettings.selectedBuildings ??
    form.buildings.map((_building, index) => index);
  for (const building of buildingList) {
    pipeSizingModalState['bldg-' + building] = true;
  }

  const formParams = {
    buildingList: form.buildings.map(
      (building) => building.params.calculations.bes
    ),
    networkOption: 'lowEx',
    diversityFactorLowEx: 1,
    deltaTPipeSizing: pipeSettings.tempDifference,
    pressureClass: pipeSettings.pressureClass,
    pipeRoughnessPipeSizing: pipeSettings.pipeRoughness,
    network: form.network
  };

  const calculate = () => {
    //const normDiameters = calcPressureLoss.getNormDiameters();
    // Get the De diameters regardless of the pressure class
    const deDiameters = calcPressureLoss.getDeDiameters();
    // Calculate the heat flow profile based on the building selection
    // console.log('pipeSizingModalState:', pipeSizingModalState);
    const pipeLoadProfile = calcPressureLoss.calcResidualHeatFlows(
      formParams,
      pipeSizingModalState
    );
    // return the grid mean T profile
    const gridMeanTProfile = calcPressureLoss.calcGridMeanTProfile(formParams);

    // Volume flow from the heat flow, a single value is used to get the flow
    // KPIs that show the flow rates
    const genStatsTable: PipeGenStatEntry =
      calcPressureLoss.calcFlowRatesFromHeatFlow(
        pipeLoadProfile,
        formParams,
        gridMeanTProfile
      );
    const profileDict: Record<number, number[]> = {};
    const pipeStatsTable: Record<number, PipeStatEntry> = {};

    deDiameters.forEach((diam) => {
      if (pipeSettings.selectedPipeDiameters.includes(diam)) {
        const innerDiameter =
          calcPressureLoss.getInnerDiameter(diam, formParams.pressureClass) /
          1000; // mm -> meter
        const velocityProfile = calcPressureLoss.calcVelocityProfile(
          pipeLoadProfile,
          innerDiameter,
          formParams,
          gridMeanTProfile
        );
        const specPressureLossProfile =
          calcPressureLoss.calcSpecPressureLossProfile(
            velocityProfile,
            innerDiameter,
            formParams,
            gridMeanTProfile
          );
        profileDict[diam] = specPressureLossProfile;
        pipeStatsTable[diam] = {
          annualPumpWork: calcPressureLoss
            .calcAnnualPumpWork(
              velocityProfile,
              innerDiameter,
              specPressureLossProfile
            )
            .toFixed(2),
          minFlowVelocity: ArrayUtils.min(velocityProfile).toFixed(2),
          maxFlowVelocity: ArrayUtils.max(velocityProfile).toFixed(2),
          maxPressureGradient: ArrayUtils.max(specPressureLossProfile).toFixed(
            0
          )
        };
      }
    });

    setResults({
      profileDict,
      pipeStatsTable,
      genStatsTable
    });
  };

  const preCalculate = () => {
    let recommendedDiam = 0;
    let smallestRelDev = 1000; // Start with a high value for the relative deviation
    //const normDiameters = calcPressureLoss.getNormDiameters();

    // Get the outside Diameter regardless of the pressure class
    const deDiameters = calcPressureLoss.getDeDiameters();

    // Get the overall load profiles for the selected buildings
    const pipeLoadProfile = calcPressureLoss.calcResidualHeatFlows(
      formParams,
      pipeSizingModalState
    );

    // Get the grid mean T profile
    const gridMeanTProfile = calcPressureLoss.calcGridMeanTProfile(formParams);

    deDiameters.forEach((diam) => {
      const innerDiameter =
        calcPressureLoss.getInnerDiameter(diam, formParams.pressureClass) /
        1000; // mm -> meter
      const velocityProfile = calcPressureLoss.calcVelocityProfile(
        pipeLoadProfile,
        innerDiameter,
        formParams,
        gridMeanTProfile
      );
      const maxVelocity = ArrayUtils.max(velocityProfile);
      const relDev = calcPressureLoss.calcRelDeviationToOptimumByDe(
        maxVelocity,
        diam,
        formParams.pressureClass
      );
      if (relDev < smallestRelDev) {
        recommendedDiam = diam;
        smallestRelDev = relDev;
      }
    });

    setRecommendedDiameter(recommendedDiam);

    // Tick checkboxes of diameters one smaller and one larger than optimal diameter
    const best3Diameters = calcPressureLoss.getBest3Diameters(recommendedDiam);

    setPipeSettings({
      ...pipeSettings,
      selectedPipeDiameters: best3Diameters
    });
  };

  const hasErrors = !hasValidFormValues(pipeSettings, models);

  return (
    <ActionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      onConfirmClick={calculate}
      title={T.ectoplanner.pipes.title}
      headerIcon={Icons.Network}
      cancelText={T.common.done}
      actionText={
        <>
          <Icons.Graph />
          {T.ectoplanner.calculate}
        </>
      }
      disableActionButton={
        hasErrors || pipeSettings.selectedPipeDiameters.length === 0
      }
      leftSideButton={
        <Button onClick={preCalculate}>
          <Icons.Calculator /> {T.ectoplanner.pipes.suggestpipediameters}
        </Button>
      }
    >
      <ModelForm
        models={models}
        input={pipeSettings}
        setInput={setPipeSettings}
      />

      <ActionModal
        large
        isOpen={results != null}
        onModalClose={() => setResults(null)}
        title={T.ectoplanner.pipes.results}
        actionText={T.common.done}
        disableCancel
        headerIcon={Icons.Graph}
        onConfirmClick={() => setResults(null)}
      >
        {results && (
          <EctoplannerPipeResults
            results={results}
            selectedPipeDiameters={pipeSettings.selectedPipeDiameters}
          />
        )}
      </ActionModal>
    </ActionModal>
  );
};

export default React.memo(EctoplannerPipeDialog);
