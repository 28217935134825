import React from 'react';
import classNames from 'classnames';
import '../styles/reset.css';
import '../styles/font/font.css';
import '../styles/variables.css';
import styles from './Layout.module.css';

interface LayoutProps {
  header?: React.ReactElement;
  contentArea: React.ReactElement;
  disabled?: boolean;
}

const Layout = ({ header, contentArea, disabled = false }: LayoutProps) => {
  return (
    <div className={classNames(styles.layout, disabled && styles.disabled)}>
      {header}

      {contentArea}
    </div>
  );
};

export default React.memo(Layout);
