import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import React, { useMemo } from 'react';
import T from 'ecto-common/lib/lang/Language';
import CollapsingSegmentControlPicker from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import { OptionWithIcon } from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import styles from './Jobs.module.css';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import _ from 'lodash';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import Heading from 'ecto-common/lib/Heading/Heading';
import moment from 'moment';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import JobsAPIGenV2, {
  JobDocument,
  JobMetadata,
  MostImportantFeature,
  RunMetadata
} from 'ecto-common/lib/API/JobsAPIGenV2';
import APIGen from 'ecto-common/lib/API/APIGen';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import Icons from 'ecto-common/lib/Icons/Icons';
import { buttonListColumn } from 'ecto-common/lib/utils/dataTableUtils';
import { getJobsUrl } from 'js/utils/routeConstants';
import JobsCompareGraphsModal from 'js/components/Jobs/JobsCompareGraphsModal';
import { TimeFormats } from 'ecto-common/lib/utils/dateUtils';
import { getDefaultDateTimeFormat } from 'ecto-common/lib/utils/dateUtils';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';

const dateFormatter = (date: string) =>
  date != null
    ? moment(date).format(getDefaultDateTimeFormat(TimeFormats.LONG_TIME))
    : '';

function getScoringTableColumns(
  onClicked: (item: RunMetadata) => void
): DataTableColumnProps<RunMetadata>[] {
  return [
    {
      dataKey: 'runDateTime',
      label: T.jobs.columns.scoring,
      dataFormatter: dateFormatter
    },
    {
      dataKey: 'firstValueDateTime',
      label: T.jobs.columns.datastart,
      dataFormatter: dateFormatter
    },
    {
      dataKey: 'lastValueDateTime',
      label: T.jobs.columns.dataend,
      dataFormatter: dateFormatter
    },
    {
      dataKey: 'runValidToDateTime',
      label: T.jobs.columns.expires,
      dataFormatter: dateFormatter
    },
    {
      dataKey: 'jobSubTypeKey',
      label: T.jobs.columns.model
    },
    buttonListColumn<RunMetadata>([
      {
        tooltipText: T.jobs.compare,
        icon: <Icons.Graph />,
        action: onClicked
      }
    ])
  ];
}

const featureImportanceColumns: DataTableColumnProps<MostImportantFeature>[] = [
  {
    label: T.jobs.columns.name,
    dataKey: 'displayName',
    flexGrow: 3
  },
  {
    label: T.jobs.columns.importance,
    dataKey: 'weight',
    flexGrow: 0,
    minWidth: 110,
    maxWidth: 110,
    flexShrink: 0,
    dataFormatter: (weight: number) => weight.toFixed(3)
  }
];

const outputSignalColumns = [
  {
    dataKey: 'name',
    label: T.common.name
  }
];

const EarlierVersionColumns: DataTableColumnProps<JobDocument>[] = [
  {
    dataKey: 'jobCreatedDateTime',
    label: T.jobs.columns.date,
    dataFormatter: dateFormatter
  },
  {
    dataKey: 'jobValidToDateTime',
    label: T.jobs.columns.expires,
    dataFormatter: dateFormatter
  },
  {
    dataKey: 'jobName',
    label: T.common.name
  }
];

const Jobs = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  const params = useParams<NodeParams>();

  const nodeId = useOperatorSelector((state) => state.general.nodeId);

  const listQuery = JobsAPIGenV2.Jobs.listCurrentJobs.useInfiniteQuery(
    { nodeId },
    { pageSize: 20 }
  );

  const allJobs = useMemo(() => {
    if (listQuery.data == null) {
      return [];
    }

    return _.flatMap(listQuery.data.pages, (item) => item.items);
  }, [listQuery.data]);

  const userSelectedJobId = params.subPage ?? allJobs[0]?.jobId;

  const userSelectedJob = allJobs.find?.(
    (job) => job.jobId === userSelectedJobId
  );
  const selectedJob: JobMetadata = userSelectedJob ?? allJobs[0] ?? null;

  const dataQuery = JobsAPIGenV2.Jobs.getCurrentJob.useQuery(
    {
      jobId: selectedJob?.jobId,
      nodeId
    },
    {
      enabled: nodeId != null && selectedJob != null
    }
  );

  const earlierVersionsQuery =
    JobsAPIGenV2.Jobs.listHistoricalJobs.useInfiniteQuery(
      {
        jobId: selectedJob?.jobId,
        nodeId
      },
      {
        pageSize: 15
      },
      {
        enabled: selectedJob != null
      }
    );

  const scoringQuery = JobsAPIGenV2.Jobs.listRuns.useInfiniteQuery(
    { jobId: selectedJob?.jobId, nodeId },
    {
      pageSize: 15
    },
    {
      enabled: selectedJob != null
    }
  );

  const signalInfoQuery = APIGen.Signals.getProvidersBySignalIds.useQuery(
    {
      signalIds: dataQuery.data?.outputDataPointIds
    },
    {
      enabled: dataQuery.data != null
    }
  );

  const outputSignals = useMemo(() => {
    const providers = signalInfoQuery.data;
    const outputIds = dataQuery.data?.outputDataPointIds ?? [];
    const allSignals = _.flatMap(providers, (provider) => provider.signals);
    return _.filter(allSignals, (signal) =>
      outputIds.includes(signal.signalId)
    );
  }, [dataQuery.data?.outputDataPointIds, signalInfoQuery.data]);

  const options: OptionWithIcon<string>[] = useMemo(() => {
    return _.map(
      allJobs,
      (job): OptionWithIcon<string> => ({
        label: job.jobName,
        value: job.jobId
      })
    );
  }, [allJobs]);

  const selectedOption = options.find(
    (option) => option.value === selectedJob?.jobId
  );
  const allRuns = useMemo(() => {
    return _.flatMap(scoringQuery.data?.pages, (page) => page.items);
  }, [scoringQuery.data?.pages]);

  const allEarlierVersions = useMemo(() => {
    return _.flatMap(earlierVersionsQuery.data?.pages, (page) => page.items);
  }, [earlierVersionsQuery.data?.pages]);

  const navigate = useNavigate();

  const scoringTableColumns = useMemo(() => {
    return getScoringTableColumns((item) => {
      navigate(
        getJobsUrl(params.tenantId, nodeId, userSelectedJobId, item.fileName)
      );
    });
  }, [navigate, nodeId, params.tenantId, userSelectedJobId]);

  const timeFormat = getDefaultDateTimeFormat(TimeFormats.LONG_TIME);
  usePageTitleCallback({
    mainTitle: T.location.tabs.jobs,
    subTitle: selectedJob?.jobName,
    onTitleChanged
  });
  return (
    <ToolbarContentPage title={T.location.tabs.jobs} wrapContent={false}>
      <LoadingContainer isLoading={listQuery.isFetching}>
        {!listQuery.isFetching && options.length === 0 && <NoDataMessage />}

        <div className={styles.gridLayout}>
          <div style={{ gridArea: 'toolbar' }}>
            <CollapsingSegmentControlPicker<string>
              options={options}
              value={selectedOption}
              onChangeValue={(option) =>
                navigate(getJobsUrl(params.tenantId, nodeId, option.value))
              }
            />
          </div>

          <div
            style={{
              gridArea: 'details',
              display: 'flex',
              flexDirection: 'column',
              rowGap: dimensions.standardMargin
            }}
          >
            {dataQuery.data && (
              <>
                <PlainBox>
                  <Heading level={4}>{T.jobs.details}</Heading>
                  <KeyValueColumn>
                    <KeyValueGeneric isHorizontal keyText={T.jobs.columns.name}>
                      {dataQuery.data.jobSubTypeName}
                    </KeyValueGeneric>
                    <KeyValueGeneric
                      isHorizontal
                      keyText={T.jobs.columns.lasttrained}
                    >
                      {moment(dataQuery.data.jobCreatedDateTime).format(
                        timeFormat
                      )}
                    </KeyValueGeneric>
                    <KeyValueGeneric
                      isHorizontal
                      keyText={T.jobs.columns.expires}
                    >
                      {moment(dataQuery.data.jobValidToDateTime).format(
                        timeFormat
                      )}
                    </KeyValueGeneric>
                    {dataQuery.data.trainingOutput?.r2 != null && (
                      <KeyValueGeneric isHorizontal keyText={T.jobs.columns.r2}>
                        {dataQuery.data.trainingOutput.r2.toFixed(3)}
                      </KeyValueGeneric>
                    )}

                    {dataQuery.data.trainingOutput?.rmse != null && (
                      <KeyValueGeneric
                        isHorizontal
                        keyText={T.jobs.columns.rmse}
                      >
                        {dataQuery.data.trainingOutput.rmse.toFixed(3)}
                      </KeyValueGeneric>
                    )}
                  </KeyValueColumn>
                </PlainBox>
                {dataQuery.data.trainingOutput != null && (
                  <PlainBox>
                    <Heading level={4}>{T.jobs.featureimportance}</Heading>
                    <DataTable
                      columns={featureImportanceColumns}
                      data={dataQuery.data.trainingOutput.mostImportantFeatures}
                    />
                  </PlainBox>
                )}
                <PlainBox>
                  <Heading level={4}>{T.jobs.outputsignals}</Heading>
                  {signalInfoQuery.isLoading && <Spinner />}
                  {!signalInfoQuery.isLoading && (
                    <DataTable
                      data={outputSignals}
                      columns={outputSignalColumns}
                      disableHeader
                    />
                  )}
                </PlainBox>
              </>
            )}
            {options.length > 0 && (
              <PlainBox style={{ flexGrow: 1 }}>
                <Heading level={4}>{T.jobs.earlierversions}</Heading>
                {earlierVersionsQuery.data && (
                  <DataTable
                    data={allEarlierVersions}
                    columns={EarlierVersionColumns}
                  />
                )}
                <DataTableLoadMoreFooter
                  isFetchingNextPage={earlierVersionsQuery.isFetchingNextPage}
                  hasNextPage={earlierVersionsQuery.hasNextPage}
                  fetchNextPage={earlierVersionsQuery.fetchNextPage}
                />
              </PlainBox>
            )}
          </div>
          {options.length > 0 && (
            <PlainBox style={{ gridArea: 'scoring' }}>
              <Heading level={4}>{T.jobs.scoringhistory}</Heading>
              {scoringQuery.data && (
                <DataTable data={allRuns} columns={scoringTableColumns} />
              )}
              <DataTableLoadMoreFooter
                isFetchingNextPage={scoringQuery.isFetchingNextPage}
                hasNextPage={scoringQuery.hasNextPage}
                fetchNextPage={scoringQuery.fetchNextPage}
              />
            </PlainBox>
          )}
        </div>
      </LoadingContainer>
      <JobsCompareGraphsModal
        nodeId={params.nodeId}
        fileName={params.itemId}
        jobId={params.subPage}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(Jobs);
