import React, { useCallback, useMemo } from 'react';
import _ from 'lodash';

import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { REQ_STATE_PENDING } from 'ecto-common/lib/utils/requestStatus';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import T from 'ecto-common/lib/lang/Language';
import Switch from 'ecto-common/lib/Switch/Switch';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/AutomaticScheduleProviderToolbarButton.module.css';
import { SignalTypeIds } from 'ecto-common/lib/utils/constants';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import APIGen from 'ecto-common/lib/API/APIGen';

const automaticSchedulesEnabledProviderType = 'AutomaticPowerControlSchedules';

const AutomaticScheduleProviderToolbarButton = () => {
  const [
    showAutomaticSchedulesModal,
    onShowAutomaticSchedulesModal,
    onHideAutomaticSchedulesModal
  ] = useDialogState('show-enable-auto-schedules');
  const signalProvidersReqState = useOperatorSelector(
    (state) => state.schedule.signals
  );

  const automaticScheduleProvider = useMemo(() => {
    return _.find(signalProvidersReqState.payload, [
      'signalProviderType',
      automaticSchedulesEnabledProviderType
    ]);
  }, [signalProvidersReqState]);

  const automaticSchedulesEnabledSignal = useMemo(() => {
    return _.find(automaticScheduleProvider?.signals, [
      'signalTypeId',
      SignalTypeIds.AUTOMATIC_SCHEDULES_ENABLED_SIGNAL_TYPE_ID
    ]);
  }, [automaticScheduleProvider?.signals]);

  const lastSignalValueQuery = APIGen.Signals.getLastSignalValues.useQuery(
    {
      signalIds: [automaticSchedulesEnabledSignal?.signalId]
    },
    {
      enabled: !!automaticSchedulesEnabledSignal
    }
  );

  const lastSignalValue = lastSignalValueQuery.data?.[0]?.signals?.[0]?.value;

  const setSignalValueMutation = APIGen.Signals.addSignalValues.useMutation({
    onSuccess: () => {
      const responseText = lastSignalValue
        ? T.powercontrol.dispatch.automatic.schedule.success.disable
        : T.powercontrol.dispatch.automatic.schedule.success.enable;
      toastStore.addSuccessToast(responseText);
      lastSignalValueQuery.refetch();
      onHideAutomaticSchedulesModal();
    },
    onError: () => {
      const responseText = lastSignalValue
        ? T.powercontrol.dispatch.automatic.schedule.failure.enable
        : T.powercontrol.dispatch.automatic.schedule.failure.disable;
      toastStore.addErrorToast(responseText);
    }
  });

  const onSetSignalValue = useCallback(() => {
    setSignalValueMutation.mutate({
      providerId: automaticScheduleProvider.signalProviderId,
      signalId: automaticSchedulesEnabledSignal.signalId,
      values: [
        {
          time: new Date().toISOString(),
          value: lastSignalValue ? 0 : 1
        }
      ]
    });
  }, [
    automaticScheduleProvider?.signalProviderId,
    automaticSchedulesEnabledSignal?.signalId,
    lastSignalValue,
    setSignalValueMutation
  ]);

  const automaticScheduleProviderIsLoading =
    signalProvidersReqState.state === REQ_STATE_PENDING ||
    setSignalValueMutation.isPending ||
    lastSignalValueQuery.isLoading;

  return (
    <>
      {automaticScheduleProvider && (
        <ToolbarItem>
          <div className={styles.automaticSchedulesContainer}>
            <label>{T.powercontrol.dispatch.automatic.schedule.label}: </label>

            <Switch
              isOn={lastSignalValue === 1.0}
              onClick={onShowAutomaticSchedulesModal}
              isLoading={automaticScheduleProviderIsLoading}
              disabled={automaticScheduleProviderIsLoading}
            />
          </div>
        </ToolbarItem>
      )}

      <ActionModal
        isOpen={showAutomaticSchedulesModal}
        onConfirmClick={onSetSignalValue}
        onModalClose={onHideAutomaticSchedulesModal}
        title={T.powercontrol.dispatch.automatic.schedule.label}
        actionText={lastSignalValue ? T.common.disable : T.common.enable}
        isLoading={automaticScheduleProviderIsLoading}
      >
        <>
          {lastSignalValue
            ? T.powercontrol.dispatch.automatic.schedule.verify.disable
            : T.powercontrol.dispatch.automatic.schedule.verify.enable}
        </>
      </ActionModal>
    </>
  );
};

export default AutomaticScheduleProviderToolbarButton;
