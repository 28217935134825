import {
  PATCH_SIGNAL_TYPE_FOLDERS,
  SET_SIGNAL_TYPE_FOLDERS
} from './actionTypes';
import { SignalTypeFolderResponseModel } from 'ecto-common/lib/API/APIGen';
import { AddOrUpdateSignalTypeFolderRequestModel } from '../API/APIGen';

type SignalTypeFoldersPatch = {
  editedItems: AddOrUpdateSignalTypeFolderRequestModel[];
  deletedItems: AddOrUpdateSignalTypeFolderRequestModel[];
};

export function setSignalTypeFolders(
  signalTypeFolders: SignalTypeFolderResponseModel[]
) {
  return {
    type: SET_SIGNAL_TYPE_FOLDERS,
    payload: signalTypeFolders
  };
}

export function patchSignalTypeFolders(folders: SignalTypeFoldersPatch) {
  return {
    type: PATCH_SIGNAL_TYPE_FOLDERS,
    payload: folders
  };
}
