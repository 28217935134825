import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import React from 'react';
import T from 'ecto-common/lib/lang/Language';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';

type ProcessMapZoomSelectorProps = {
  value: number;
  setZoom: (zoom: number) => void;
};

const zoomOptions: GenericSelectOption<number>[] = [
  {
    label: '50%',
    value: 0.5
  },
  {
    label: '100%',
    value: 1
  },
  {
    label: '150%',
    value: 1.5
  },
  {
    label: '200%',
    value: 2
  }
];

const ProcessMapZoomSelector = ({
  value,
  setZoom
}: ProcessMapZoomSelectorProps) => {
  const selectedValue = zoomOptions.find(
    (option) => Math.abs(option.value - value) < 0.0001
  );

  return (
    <ToolbarItem>
      <Tooltip text={T.admin.processmaps.zoom}>
        <Select
          placeholder={T.admin.processmaps.customzoom}
          options={zoomOptions}
          value={selectedValue == null ? null : selectedValue}
          onChange={(option) => {
            setZoom(option.value);
          }}
        />
      </Tooltip>
    </ToolbarItem>
  );
};

export default React.memo(ProcessMapZoomSelector);
