import { useCallback, useMemo } from 'react';
import UndoManager from 'ecto-common/lib/utils/UndoManager';

/**
 *
 * @param onData Function called whenever the data needs to be updated, for instance after an undo or redo
 * @param undoManager The manager responsible for the state
 * @return [canUndo, canRedo, add, undo, redo]
 */
export const useUndoHistoryWithManager = <Type>(
  onData: (data: Type) => void,
  undoManager: UndoManager<Type>
): [
  canUndo: boolean,
  canRedo: boolean,
  add: (newValue: Type) => void,
  undo: () => void,
  redo: () => void
] => {
  const undo = useCallback(() => {
    undoManager.undo(onData);
  }, [undoManager, onData]);

  const add = useCallback(
    (newValue: Type) => {
      undoManager.add(newValue);
    },
    [undoManager]
  );

  const redo = useCallback(() => {
    undoManager.redo(onData);
  }, [undoManager, onData]);

  return [undoManager.canUndo, undoManager.canRedo, add, undo, redo];
};

const useUndoHistory = <Type>(
  initialValues: Type[],
  onData: (data: Type) => void
): [
  canUndo: boolean,
  canRedo: boolean,
  add: (newValue: Type) => void,
  undo: () => void,
  redo: () => void
] => {
  const undoManager = useMemo(() => {
    return new UndoManager<Type>(initialValues);
  }, [initialValues]);

  return useUndoHistoryWithManager(onData, undoManager);
};

export default useUndoHistory;
