import React from 'react';
import styles from './ComponentRow.module.css';

interface ComponentRowProps {
  children?: React.ReactNode;
}

/**
 *  A ComponentRow lays out its children in a horizontal sequence with proper margins and wrapping.
 */
const ComponentRow = ({ children }: ComponentRowProps) => {
  return <div className={styles.componentRow}> {children}</div>;
};

export default ComponentRow;
