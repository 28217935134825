import {
  handleRequestError,
  REQ_STATE_PENDING,
  REQ_STATE_SUCCESS
} from 'ecto-common/lib/utils/requestStatus';
import _ from 'lodash';

import { CommonDispatch } from '../reducers/storeCommon';

export const performAsyncRequestWithStateChanges = async (
  dispatch: CommonDispatch,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  action: any,
  reqStateConstant: string,
  id: string = undefined,
  params = {},
  blocking = false
) => {
  dispatch({
    type: reqStateConstant,
    payload: { state: REQ_STATE_PENDING, payload: null, params, id, blocking }
  });

  try {
    let result;

    if (_.isFunction(action)) {
      result = await action();
    } else {
      result = await action;
    }

    dispatch({
      type: reqStateConstant,
      payload: {
        state: REQ_STATE_SUCCESS,
        id,
        payload: result,
        params,
        blocking
      }
    });

    return result;
  } catch (e) {
    await handleRequestError(reqStateConstant, e, id, dispatch, null, params);
  }
};
