import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import { AllAlarmSeverities } from 'ecto-common/lib/constants';
import { useAlarmCountEventHubSubscription } from 'ecto-common/lib/EventHubConnection/EventHubConnectionHooks';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from 'ecto-common/lib/Dashboard/panels/AlarmStatusPanel.module.css';
import { NavLink } from 'react-router-dom';

import {
  getSignalProvidersPage,
  getAlarmsPage
} from 'ecto-common/lib/utils/commonLinkUtil';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { ASC } from 'ecto-common/lib/DataTable/SortDirection';
import useSort from 'ecto-common/lib/hooks/useSort';
import TableColumn from 'ecto-common/lib/TableColumn/TableColumn';
import AlarmSeverityCount from 'ecto-common/lib/Alarms/AlarmSeverityCount';
import { beautifyEquipmentName } from 'ecto-common/lib/utils/equipmentTypeUtils';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import Tooltip from 'ecto-common/lib/Tooltip/Tooltip';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import HelpPaths from 'ecto-common/help/tocKeys';

import { buildingStatusSection } from './panelUtil';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  BuildingStatus,
  EquipmentTypeResponseModel,
  GridType,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import {
  nodeIsBuilding,
  nodeIsSpace,
  useNodeChildren
} from 'ecto-common/lib/hooks/useCurrentNode';

const pathName = window.location.pathname;

const getAlarmsRoute = (tenantId: string, node: AlarmNode, isAdmin: boolean) =>
  !isAdmin ? getAlarmsPage(tenantId, node.nodeId) : pathName;

// const isSite = (type: string) => type === NodeTypes.SITE;

// const isBuilding = (type: string) => type === NodeTypes.BUILDING;

interface NameColumnTitleProps {
  node: AlarmNode;
}

const NameColumnTitle = ({ node }: NameColumnTitleProps) => {
  // TODO: New domain model: Hardcoded equipmentId check

  const { isAdmin } = useContext(DashboardDataContext);
  const { tenantId } = useContext(TenantContext);

  // const _setNode = useCallback(() => {
  //   if (
  //     (isAdmin && isSite(node.nodeType)) ||
  //     isBuilding(node.nodeType) ||
  //     !isAdmin
  //   ) {
  //     setNode(node.nodeId);
  //   }
  // }, [isAdmin, node.nodeId, node.nodeType, setNode]);

  // if (!node.equipmentId) {
  //   return (
  //     <a onClick={_setNode} className={styles.nameWrapper}>
  //       {node.name}
  //     </a>
  //   );
  // }

  if (!isAdmin) {
    return (
      <NavLink
        to={getSignalProvidersPage(tenantId, node.nodeId)}
        className={styles.nameWrapper}
      >
        {node.name}
      </NavLink>
    );
  }

  return <>{node.name}</>;
};

interface NameColumnProps {
  node: AlarmNode;
}

const NameColumn = ({ node }: NameColumnProps) => (
  <TableColumn
    className={styles.nameWrapper}
    title={<NameColumnTitle node={node} />}
    subtitle={node.subtitle}
  />
);

const approxWidthForSeverityColumn = (maxNumSeverities: number) => {
  // Approx width for severity with two digit value
  const SEVERITY_WIDTH_APPROX = 55;
  // Approx width for navigation arrow
  const SEVERITY_ARROW_APPROX = 25;

  return maxNumSeverities * SEVERITY_WIDTH_APPROX + SEVERITY_ARROW_APPROX;
};

interface SeveritiesColumnProps {
  node: AlarmNode;
}

const SeveritiesColumn = ({ node }: SeveritiesColumnProps) => {
  const { isAdmin } = useContext(DashboardDataContext);
  const { tenantId } = useContext(TenantContext);

  return (
    <NavLink
      to={getAlarmsRoute(tenantId, node, isAdmin)}
      className={styles.alarmSeverities}
    >
      {_.map(node.severities, (count, severity) => (
        <AlarmSeverityCount key={severity} severity={severity} count={count} />
      ))}

      {_.isEmpty(node?.severities) && (
        <Tooltip text={T.admin.alarmstatuspanel.tooltip.noactivealarms}>
          <Icons.CheckmarkCircle className={styles.noSeveritiesIcon} />
        </Tooltip>
      )}

      <div className={styles.arrow}>
        <Icons.NavigationArrowRight />
      </div>
    </NavLink>
  );
};

type AlarmDataTableColumnProps = DataTableColumnProps<AlarmNode> & {
  sortBy?: string[];
};

const getColumns = (maxNumSeverities: number): AlarmDataTableColumnProps[] => {
  // Table columns must have constant width in order to avoid
  // overlap. Make an approximate width for the severity column (which is dynamic),
  const severitiesMinWidth = approxWidthForSeverityColumn(maxNumSeverities);

  return [
    {
      dataKey: 'name',
      label: T.common.nodename,
      canSort: true,
      truncateText: true,
      flexGrow: 2,
      flexShrink: 2,
      dataFormatter: (unused: unknown, node) => <NameColumn node={node} />
    },
    {
      dataKey: 'severities',
      label: T.common.status,
      align: 'right',
      canSort: true,
      flexGrow: 0,
      flexShrink: 0,
      minWidth: severitiesMinWidth,
      maxWidth: severitiesMinWidth,
      sortBy: _.map(AllAlarmSeverities, (severity) => 'severities.' + severity),
      dataFormatter: (unused: unknown, node) => <SeveritiesColumn node={node} />
    }
  ];
};

const getSubtitle = (
  node: NodeV2ResponseModel,
  equipmentTypes: EquipmentTypeResponseModel[]
) => {
  if (nodeIsSpace(node)) {
    return T.nodetypes.site;
  }

  if (nodeIsBuilding(node)) {
    return T.nodetypes.building;
  }

  const equipmentType = equipmentTypes.find((otherEqType) =>
    node.nodeTraitIds.includes(otherEqType.equipmentTypeId)
  );

  if (equipmentType != null) {
    return beautifyEquipmentName(equipmentType.name);
  }

  return '';
};

type AlarmNode = {
  name: string;
  nodeId: string;
  severities: number[];
  subtitle: string;
  parentId?: string;
  nodeTraitIds: string[];
};

type AlarmStatusPanelProps = CustomPanelProps & {
  data: {
    node: NodeV2ResponseModel;
    buildingStatus: BuildingStatus[];
  };
};

const AlarmStatusPanel = ({ data }: AlarmStatusPanelProps) => {
  const parentNode = data.node;

  const isStandingOnASite = data.node && nodeIsSpace(data.node);
  const { equipmentTypes } = useContext(DashboardDataContext);

  const parentNodeIds = useMemo(
    () => _.compact([parentNode?.nodeId]),
    [parentNode?.nodeId]
  );
  const { nodeChildren } = useNodeChildren(parentNodeIds);

  const nodeIds = useMemo(() => {
    if (parentNode == null) {
      return [];
    }

    if (isStandingOnASite) {
      return _.map(nodeChildren, 'nodeId');
    }

    return [parentNode.nodeId];
  }, [isStandingOnASite, nodeChildren, parentNode]);

  const alarmCounts = useAlarmCountEventHubSubscription(
    GridType.Generic, // TODO: New domain model: Hardcoded grid type
    nodeIds,
    !isStandingOnASite,
    data?.buildingStatus
  );

  const nodesWithAlarmSeverities: AlarmNode[] = useMemo(
    () =>
      _.compact(
        _.map(alarmCounts.bySeverity, (item, key) => {
          const node =
            key === parentNode.nodeId
              ? parentNode
              : nodeChildren.find((childNode) => childNode.nodeId === key);

          // if (!node) {
          //   // When we request counts from a building we do a recursive search based on the building
          //   // ID. The results will also return entries for each equipment, but also for the building
          //   // itself. We don't want to show that entry, so don't attempt to fetch it here.
          //   if (!isStandingOnASite) {
          //     return false;
          //   }

          //   node = getNodeFromMap(nodeMap, key);

          //   if (!node) {
          //     return false;
          //   }
          // }

          return {
            name: node?.name,
            nodeId: node?.nodeId,
            nodeTraitIds: node.nodeTraitIds,
            grid: GridType.Generic, // TODO: New domain model: Hardcoded grid type
            severities: item,
            subtitle: getSubtitle(node, equipmentTypes),
            parentNodeId: node?.parentId
          };
        })
      ),
    [alarmCounts.bySeverity, equipmentTypes, nodeChildren, parentNode]
  );

  const maxNumSeverities = Math.max(
    1,
    _.max(
      _.map(nodesWithAlarmSeverities, (node) => _.keys(node.severities).length)
    ) ?? 0
  );

  const columns = useMemo(
    () => getColumns(maxNumSeverities),
    [maxNumSeverities]
  );

  const [sortBy, sortDirection, setSortParams, sortedData] = useSort<AlarmNode>(
    'severities',
    ASC,
    columns,
    nodesWithAlarmSeverities
  );

  const isLoading = alarmCounts.isLoading;

  return (
    <DataTable<AlarmNode>
      data={sortedData}
      columns={columns}
      isLoading={isLoading}
      noDataText={T.alarms.noalarmswerefoundinthecurrentlocation}
      onSortChange={setSortParams}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  );
};

export const AlarmStatusPanelData = {
  emptyTargets: {
    node: {
      sourceType: DataSourceTypes.NODE
    }
  },
  sections: [buildingStatusSection],
  minWidth: 280,
  helpPath: HelpPaths.docs.dashboard.dashboards.alarm_status_list
};

export default React.memo(AlarmStatusPanel);
