import React from 'react';
import SvgIcon, { SvgIconProps } from './SvgIcon';
import styles from './Add.module.css';

const Add = (props: SvgIconProps) => (
  <SvgIcon viewBox="0 0 42 42" {...props}>
    <g>
      <line className={styles.cls1} x1="21" y1="6" x2="21" y2="36" />
      <line className={styles.cls1} x1="6" y1="21" x2="36" y2="21" />
    </g>
  </SvgIcon>
);

export default Add;
