import React from 'react';

import styles from './MarkdownPageContent.module.css';
import MarkdownContent from './MarkdownContent';
import T from 'ecto-common/lib/lang/Language';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';

interface MarkdownPageContentProps {
  onLinkClick(docName: string): void;
  currentPageId: string;
  file?: string;
}

const MarkdownPageContent = ({
  onLinkClick,
  currentPageId,
  file
}: MarkdownPageContentProps) => (
  <div className={styles.container}>
    <PlainBox className={styles.boxContent}>
      <div className={styles.content}>
        {file ? (
          <MarkdownContent
            currentPageId={currentPageId}
            markdownFile={file}
            onClick={onLinkClick}
          />
        ) : (
          <p className={styles.missingFileMessage}>
            {T.help.missingfilemessage}
          </p>
        )}
      </div>
    </PlainBox>
  </div>
);

export default MarkdownPageContent;
