import React, { useCallback, useEffect } from 'react';

import _ from 'lodash';
import MapMarker from 'ecto-common/lib/Map/MapMarker';
import StyledMapMarker from './StyledMapMarker';
import {
  DashboardOption,
  PointFeatureType
} from 'ecto-common/lib/Dashboard/panels/LocationMapPanel/DashboardMap';
import { MatchSignalsForNodesResult } from 'ecto-common/lib/Dashboard/datasources/SignalValuesDataSource';
import { SignalValueType } from 'ecto-common/lib/hooks/useLatestSignalValues';
import { nodeIsSpace } from 'ecto-common/lib/hooks/useCurrentNode';

interface DashboardMapMarkerProps {
  cluster: PointFeatureType;
  onClick(
    cluster: PointFeatureType,
    latitude: number,
    longitude: number,
    focusThisNode: () => void
  ): void;
  isDragging: boolean;
  signalValues?: SignalValueType[];
  focusId?: string;
  onChangeFocusId?(focusId: string): void;
  signalInfo?: MatchSignalsForNodesResult;
  isLoading?: boolean;
  selectedSignal?: DashboardOption;
  currentNodeId?: string;
  panToLocation?(latitude: number, longitude: number): void;
  lat?: number;
  lng?: number;
  zIndex: number; // Needs to be set as props, will be picked up by google maps package
}

const DashboardMapMarker = ({
  zIndex,
  lng: _lng,
  lat: _lat,
  cluster,
  onClick,
  isDragging,
  focusId,
  onChangeFocusId,
  signalValues,
  signalInfo,
  isLoading,
  selectedSignal,
  currentNodeId,
  panToLocation
}: DashboardMapMarkerProps) => {
  _.noop(zIndex);
  const [longitude, latitude] = cluster.geometry.coordinates;
  const { nodeCount } = cluster.properties;
  const isCluster = !!cluster?.id;
  const isSiteOrCluster = isCluster || nodeIsSpace(cluster.properties);

  const focusThisNode = useCallback(() => {
    onChangeFocusId(cluster.properties.nodeId);
  }, [onChangeFocusId, cluster]);

  const _onHidePopup = useCallback(() => {
    // To prevent marker popup from closing if the user moves around in the map while popup is open.
    if (!isDragging) {
      onChangeFocusId(null);
    }
  }, [isDragging, onChangeFocusId]);

  const _onClick = useCallback(() => {
    if (isCluster) {
      onClick?.(cluster, latitude, longitude, focusThisNode);
    } else {
      panToLocation(cluster.properties.latitude, cluster.properties.longitude);
      onChangeFocusId(cluster.properties.nodeId);
    }
  }, [
    cluster,
    isCluster,
    latitude,
    longitude,
    onChangeFocusId,
    onClick,
    focusThisNode,
    panToLocation
  ]);

  useEffect(() => {
    document.addEventListener('mouseup', _onHidePopup);

    return () => {
      document.removeEventListener('mouseup', _onHidePopup);
    };
  }, [_onHidePopup]);

  const focusActive = !!focusId;
  const nodeIsFocused =
    focusId === cluster.properties.nodeId ||
    (isCluster &&
      _.find(cluster.properties.nodes, { nodeId: focusId }) !== undefined);

  const isCurrentNode = cluster.properties.nodeId === currentNodeId;

  if (!isCluster) {
    return (
      <StyledMapMarker
        properties={cluster.properties}
        onClick={_onClick}
        showPopup={nodeIsFocused}
        focusActive={focusActive}
        nodeIsFocused={nodeIsFocused}
        signalValues={signalValues}
        signalInfo={signalInfo}
        isLoading={isLoading}
        selectedSignal={selectedSignal}
        isCurrentNode={isCurrentNode}
      />
    );
  }

  return (
    <MapMarker
      lat={latitude}
      lng={longitude}
      isCluster={isSiteOrCluster && !!nodeCount}
      onClick={_onClick}
      focusActive={focusActive}
      nodeIsFocused={nodeIsFocused}
    >
      <>
        <span>{isSiteOrCluster && nodeCount > 0 && nodeCount}</span>
      </>
    </MapMarker>
  );
};

export default React.memo(DashboardMapMarker);
