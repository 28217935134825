import React from 'react';
import classNames from 'classnames';
import styles from './ContentArea.module.css';

interface ContentAreaProps {
  children: React.ReactNode;
  className?: string;
}

const ContentArea = ({ children, className }: ContentAreaProps) => {
  return <main className={classNames(styles.main, className)}>{children}</main>;
};

export default React.memo(ContentArea);
