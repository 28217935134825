import React from 'react';
import SignalTypePicker from 'ecto-common/lib/SignalTypePicker/SignalTypePicker';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import { ModelEditorProps } from 'ecto-common/lib/ModelForm/ModelEditor';
import { ModelDefinitionInternal } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';

export type SignalTypeModelDefinition<
  ObjectType extends object,
  EnvironmentType extends object = object
> = {
  modelType: typeof ModelType.SIGNAL_TYPE;
  selectFolder?: boolean;
  nodeId?: string;
  isClearable?: boolean;
} & ModelDefinitionInternal<ObjectType, EnvironmentType, string>;

type ModelEditorSignalTypeProps = ModelEditorProps & {
  model: SignalTypeModelDefinition<object, object>;
};

const ModelEditorSignalType = ({
  model,
  disabled,
  rawValue,
  hasError,
  updateItem,
  helpText
}: ModelEditorSignalTypeProps) => {
  return (
    <KeyValueGeneric keyText={model.label} helpText={helpText}>
      <SignalTypePicker
        disabled={disabled}
        hasError={hasError}
        value={rawValue}
        onChange={updateItem}
        selectFolder={model.selectFolder}
        selectOnlyFolders={model.selectFolder}
        nodeId={model.nodeId}
        isClearable={model.isClearable}
      />
    </KeyValueGeneric>
  );
};

export default React.memo(ModelEditorSignalType);
