import React, { useMemo } from 'react';
import styles from 'ecto-common/lib/ToolbarContentPage/ToolbarLocationPicker.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import CopyToClipboardTooltip from '../CopyToClipboardTooltip/CopyToClipboardTooltip';
import ToolbarNavControl from 'ecto-common/lib/ToolbarContentPage/ToolbarNavControl';
import {
  nodeIsBuilding,
  nodeIsEnergyManager,
  nodeIsEquipment,
  nodeIsSpace,
  useCurrentNode
} from 'ecto-common/lib/hooks/useCurrentNode';
import APIGen, {
  NodeV2ResponseModel,
  NodeParentInformationResponseModel
} from 'ecto-common/lib/API/APIGen';
import { DeviceIcon } from 'ecto-common/lib/Icon';
import Spinner, { SpinnerSize } from 'ecto-common/lib/Spinner/Spinner';
import _ from 'lodash';
import TagsGroup from 'ecto-common/lib/TagsGroup/TagsGroup';

export const getSubtitle = (
  node: NodeV2ResponseModel,
  parents: NodeParentInformationResponseModel[],
  hasEquipment = false
) => {
  if (node == null) {
    return ['', ''];
  }

  const rootParent = parents.find((parent) => parent.nodeId === node.parentId);
  let parent = rootParent;
  let parentText = '';

  const MAX_PARENT_TEXT_LENGTH = 70;

  const indices = [];
  while (parent != null) {
    parentText = parent.name + parentText;
    parent = parents.find((p) => p.nodeId === parent.parentId);

    if (parent) {
      parentText = ' > ' + parentText;
      indices.push(parentText.length);
    }
  }

  if (hasEquipment) {
    parentText += ' > ' + node.name;
  }

  if (parentText.length > MAX_PARENT_TEXT_LENGTH && rootParent != null) {
    if (hasEquipment) {
      let abbreviated = '... > ' + rootParent.name + ' > ' + node.name;
      if (abbreviated.length > MAX_PARENT_TEXT_LENGTH) {
        abbreviated = '... > ' + node.name;
      }

      return [abbreviated, parentText];
    }

    return ['... > ' + rootParent.name, parentText];
  }

  return [parentText, parentText];
};

interface ToolbarLocationPickerProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const ToolbarLocationPicker = ({
  isOpen,
  setIsOpen
}: ToolbarLocationPickerProps) => {
  const { nodeId, currentNode, parents } = useCurrentNode();

  let icon = null;
  let largeIcon = false;
  const isEquipment = nodeIsEquipment(currentNode);

  if (nodeIsBuilding(currentNode)) {
    icon = <Icons.Building />;
  } else if (nodeIsSpace(currentNode)) {
    icon = <Icons.Site />;
  } else if (nodeIsEnergyManager(currentNode)) {
    icon = <Icons.EnergyManager />;
  } else if (isEquipment) {
    largeIcon = true;
    icon = <DeviceIcon />;
  }

  // TODO: New domain model, need to fix this

  const locationText =
    currentNode?.name != null ? (
      currentNode.name
    ) : (
      <Spinner size={SpinnerSize.TINY} />
    );
  const [subtitle, fullSubtitle] = getSubtitle(
    currentNode,
    parents,
    isEquipment
  );

  const navTitle = (
    <>
      <CopyToClipboardTooltip valueToCopy={currentNode?.name ?? ''}>
        <label>{locationText}</label>
      </CopyToClipboardTooltip>
    </>
  );

  const navSubtitle = (
    <>
      <CopyToClipboardTooltip valueToCopy={fullSubtitle}>
        {subtitle}
      </CopyToClipboardTooltip>
    </>
  );

  const allNodeTraitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const propertiesQuery =
    APIGen.NodesV2.getNodePropertyValuesWithProperties.useQuery(
      {
        nodeId
      },
      {
        enabled: nodeId != null
      }
    );

  const columns = useMemo(() => {
    const ret: React.ReactNode[][] = [];
    const properties = propertiesQuery.data ?? [];

    for (let i = 0; i < properties.length; i += 2) {
      ret.push(
        properties.slice(i, i + 2).map((property) => {
          return (
            <div key={property.propertyId} className={styles.row}>
              <label>{property.name}:</label>&nbsp;
              <CopyToClipboardTooltip valueToCopy={property.value}>
                {property.value}
              </CopyToClipboardTooltip>
            </div>
          );
        })
      );
    }

    const nodeTraitStrings = _.compact(
      currentNode?.nodeTraitIds?.map((traitId) => {
        const trait = allNodeTraitsQuery.data?.find((t) => t.id === traitId);

        return trait?.name;
      })
    );

    const nodeTraitInfo =
      nodeTraitStrings.length > 0 ? (
        <div className={styles.row} key="traitInfo">
          <label>Node traits: </label>&nbsp;
          <div style={{ marginTop: -1 }}>
            <TagsGroup tags={nodeTraitStrings} />
          </div>
        </div>
      ) : null;

    let lastColumn = ret[ret.length - 1];

    if (lastColumn == null || lastColumn.length === 2) {
      lastColumn = [nodeTraitInfo];
      ret.push(lastColumn);
    } else {
      lastColumn.push(nodeTraitInfo);
    }

    return ret;
  }, [
    allNodeTraitsQuery.data,
    currentNode?.nodeTraitIds,
    propertiesQuery.data
  ]);

  return (
    <div className={styles.container}>
      <ToolbarNavControl
        icon={icon}
        title={navTitle}
        subtitle={navSubtitle}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        largeIcon={largeIcon}
      />

      <div className={styles.properties}>
        {columns.map((column, idx) => (
          <div key={idx} className={styles.column}>
            {column}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToolbarLocationPicker;
