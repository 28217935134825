import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import styles from './TreeView.module.css';
import _ from 'lodash';
import { isNullOrWhitespace } from 'ecto-common/lib/utils/stringUtils';
import { matchSorter } from 'match-sorter';
import { centerListEntry } from 'ecto-common/lib/utils/scrollUtils';
import TreeViewNode, {
  InternalTreeViewNodeType,
  TreeViewColumnType,
  TreeViewNodeType
} from 'ecto-common/lib/TreeView/TreeViewNode';

export type TreeViewNodeTypeWithParentId = TreeViewNodeType & {
  parentId?: string;
};
export const isTreeNodeFolder = (node: TreeViewNodeType) =>
  node.children != null;
export const isTreeNodeRootLevel = (node: TreeViewNodeTypeWithParentId) =>
  node.parentId == null;

const expandPath = (path: string, parent: TreeViewNodeType) => {
  if (parent != null) {
    if (path === '') {
      return parent.name;
    }

    return path + ' -> ' + parent.name;
  }

  return '';
};

const flatten = (
  node: TreeViewNodeType,
  level: number,
  openFolderIds: Record<string, boolean>,
  isVisible: boolean,
  parent: TreeViewNodeType,
  path: string
): InternalTreeViewNodeType[] => {
  const childPath = expandPath(path, node);
  return [
    { node, level, isVisible, parent, path },
    ..._.flatMap(node.children, (child) =>
      flatten(
        child,
        level + 1,
        openFolderIds,
        isVisible && openFolderIds[node.id] === true,
        node,
        childPath
      )
    )
  ];
};

export const treeViewIconMinWidth = 16;

export const treeViewIconFormatter = (leafIcon: React.ReactNode) => {
  return (node: TreeViewNodeType, isNodeOpen: boolean) => {
    if (isTreeNodeFolder(node)) {
      return isNodeOpen ? <Icons.FolderOpen /> : <Icons.FolderClosed />;
    }

    return leafIcon;
  };
};

const DefaultColumns: TreeViewColumnType[] = [
  {
    dataFormatter: treeViewIconFormatter(<Icons.File />),
    minWidth: treeViewIconMinWidth
  },
  {
    dataFormatter: (node: TreeViewNodeType) => node.name
  }
];

const getOpenFolderIdsFromSelectedNodes = (
  selectedNodes: Record<string, boolean>,
  nodes: TreeViewNodeType[]
) => {
  const openFolderIds: Record<string, boolean> = {};

  if (selectedNodes == null) {
    return openFolderIds;
  }

  const markRecursively = (node: TreeViewNodeType) => {
    if (
      selectedNodes[node.id] === true ||
      _.some(_.map(node.children, markRecursively))
    ) {
      // Open every folder leading up to selected item. But if selected item is folder,
      // no need to open it.
      if (node.children?.length > 0 && selectedNodes[node.id] !== true) {
        openFolderIds[node.id] = true;
      }

      return true;
    }

    return false;
  };

  for (const node of nodes) {
    markRecursively(node);
  }

  return openFolderIds;
};

interface TreeViewProps<ItemType extends TreeViewNodeType = TreeViewNodeType> {
  nodes: ItemType[];
  onSelectedNodesChanged?: Dispatch<SetStateAction<Record<string, boolean>>>;
  onClickNode: (node: ItemType) => void;
  selectedNodes: Record<string, boolean>;
  multiSelect?: boolean;
  searchFilter?: string;
  selectFolder: boolean;
  searchFolders?: boolean;
  // If set to true: remove border radius, make it more friendly for embedding in other components
  embedded?: boolean;
  columns?: TreeViewColumnType<ItemType>[];
}

function TreeView<ItemType extends TreeViewNodeType = TreeViewNodeType>({
  nodes,
  onSelectedNodesChanged = null,
  onClickNode,
  selectedNodes,
  multiSelect = false,
  searchFilter = null,
  selectFolder,
  searchFolders = false,
  embedded = false,
  columns = DefaultColumns
}: TreeViewProps<ItemType>) {
  const [openFolderIds, setOpenFolderIds] = useState<Record<string, boolean>>(
    {}
  );
  const centerEntryRef = useRef(null);
  const hasSearchFilter = !isNullOrWhitespace(searchFilter);

  const flattenedList = useMemo(() => {
    return _.flatMap(nodes, (node) =>
      flatten(node, 0, openFolderIds, true, null, '')
    );
  }, [nodes, openFolderIds]);

  const searchListInput: InternalTreeViewNodeType[] = useMemo(() => {
    // We don't want to recalculate the flattened list every time a search term changes. Likewise,
    // we don't want to recalculate this input whenever the open folder id:s are changed as they
    // are not relevant when searching (everything is shown then except folders). Keep separate
    // precalculated list here for searching. Note that openFolderIds is empty.
    const flat = _.flatMap(nodes, (node) =>
      flatten(node, 0, {}, true, null, '')
    );

    if (searchFolders) {
      return flat;
    }

    return _.reject(flat, (item) => isTreeNodeFolder(item.node));
  }, [nodes, searchFolders]);

  const filteredList: InternalTreeViewNodeType[] = useMemo(() => {
    // Use natural matching
    const _filteredList: InternalTreeViewNodeType[] = matchSorter(
      searchListInput,
      searchFilter,
      { keys: [{ threshold: matchSorter.rankings.CONTAINS, key: 'node.name' }] }
    );

    // Disable indentation and show all items that match using matchSorter
    return _.map(_filteredList, (listItem) => ({
      ...listItem,
      isVisible: true,
      level: 0
    }));
  }, [searchListInput, searchFilter]);

  const listToUse = hasSearchFilter ? filteredList : flattenedList;

  useEffect(() => {
    if (!hasSearchFilter) {
      // Whenever selected nodes change, see if folders are open so
      // that the user can see the selected nodes. If not enough folders
      // are open, this means that we either just entered the tree view
      // or if we returned from search. When this happens, center the
      // scroll on the first selected item.
      setOpenFolderIds((oldOpenFolderIds) => {
        const newOpenFolderIds = getOpenFolderIdsFromSelectedNodes(
          selectedNodes,
          nodes
        );

        if (
          _.some(
            newOpenFolderIds,
            (value, nodeId) => value !== oldOpenFolderIds[nodeId]
          )
        ) {
          return { ...oldOpenFolderIds, ...newOpenFolderIds };
        }

        return oldOpenFolderIds;
      });

      const firstSelectedNodeId = _.head(_.keys(selectedNodes));
      if (firstSelectedNodeId != null) {
        // Defer so DOM can be initialized properly
        _.defer(() => {
          centerListEntry(firstSelectedNodeId, centerEntryRef.current);
        });
      }
    }
  }, [hasSearchFilter, selectedNodes, nodes]);

  const expandFolder = useCallback((node: ItemType) => {
    if (node.children?.length > 0) {
      setOpenFolderIds((oldOpenFolderIds) => ({
        ...oldOpenFolderIds,
        [node.id]: oldOpenFolderIds[node.id] !== true
      }));
    }
  }, []);

  const _onClickNode = useCallback(
    (node: ItemType) => {
      if (!isTreeNodeFolder(node) || selectFolder) {
        if (multiSelect) {
          onSelectedNodesChanged?.((oldSelectedNodes) => ({
            ...oldSelectedNodes,
            [node.id]: !oldSelectedNodes[node.id]
          }));
        } else {
          onSelectedNodesChanged?.({ [node.id]: true });
        }
      } else if (node.children?.length > 0) {
        expandFolder(node);
      }

      onClickNode?.(node);
    },
    [
      onClickNode,
      onSelectedNodesChanged,
      multiSelect,
      selectFolder,
      expandFolder
    ]
  );

  const onClickExpand = useCallback(
    (node: ItemType) => {
      if (isTreeNodeFolder(node) && !hasSearchFilter) {
        expandFolder(node);
      } else {
        _onClickNode(node);
      }
    },
    [_onClickNode, expandFolder, hasSearchFilter]
  );

  return (
    <div className={styles.treeContainer}>
      <div ref={centerEntryRef}>
        <div>
          {listToUse.map((internalNode) => (
            <TreeViewNode
              node={internalNode.node}
              path={internalNode.path}
              isVisible={internalNode.isVisible}
              level={internalNode.level}
              onDoubleClickNode={onClickExpand}
              onClickNode={_onClickNode}
              onClickExpand={onClickExpand}
              key={internalNode.node.id}
              isNodeSelected={selectedNodes?.[internalNode.node.id] === true}
              isNodeOpen={openFolderIds[internalNode.node.id] === true}
              columns={columns}
              searchFilterActive={hasSearchFilter}
              multiSelect={multiSelect}
              embedded={embedded}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(TreeView);
