import { SignalCollectionsReducerProps } from 'js/modules/signalCollections/signalCollections';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';

export const getSelectedSignalCollection = (
  signalCollections: SignalCollectionsReducerProps
) => {
  if (signalCollections.selectedSignalCollectionId) {
    return signalCollections.signalCollections.find(
      (x) => x.id === signalCollections.selectedSignalCollectionId
    );
  }

  return null;
};

const emptySelectedSignals: ChartSignal[] = [];
export const getSelectedSignals = (
  signalCollections: SignalCollectionsReducerProps
): ChartSignal[] => {
  const collection = getSelectedSignalCollection(signalCollections);
  if (collection) {
    return collection.signals;
  }

  return emptySelectedSignals;
};
