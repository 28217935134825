import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react';
import _ from 'lodash';
import { buttonListColumn } from 'ecto-common/lib/utils/dataTableUtils';
import AddButton from 'ecto-common/lib/Button/AddButton';
import T from 'ecto-common/lib/lang/Language';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import SelectSignalsDialog from 'ecto-common/lib/SelectSignalsDialog/SelectSignalsDialog';
import EditSignalSettingsDialog from 'ecto-common/lib/EditSignalSettingsDialog/EditSignalSettingsDialog';
import { TimeRangeOptionsText } from 'ecto-common/lib/types/TimeRangeOptions';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import Icons from 'ecto-common/lib/Icons/Icons';
import {
  EquipmentTypeResponseModel,
  SignalProviderType
} from 'ecto-common/lib/API/APIGen';
import UUID from 'uuidjs';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import SignalSettingName from 'ecto-common/lib/SignalSettingName/SignalSettingName';
import Flex, {
  FlexItem,
  FlexWrap,
  JustifyContent
} from 'ecto-common/lib/Layout/Flex';
import LayoutDirection from 'ecto-common/lib/types/LayoutDirection';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import TextInput from 'ecto-common/lib/TextInput/TextInput';
import {
  isConstantSignal,
  SignalTypeIds
} from 'ecto-common/lib/utils/constants';
import { SignalProviderSignalResponseModel } from 'ecto-common/lib/API/APIGen';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { SignalInputType } from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';

export const getSignalFilterModels = (
  equipmentTypes: EquipmentTypeResponseModel[],
  signalProviderTypes: SignalProviderType[]
): ModelDefinition<SignalInputType>[] => [
  {
    key: (input) => input.signalTypeId,
    modelType: ModelType.SIGNAL_TYPE,
    label: T.signals.signaltype,
    placeholder: T.signals.signaltype,
    hasError: (value: string) => value == null
  },
  {
    key: (input) => input.signalProviderType,
    label: T.signals.signalprovidertype,
    modelType: ModelType.OPTIONS,
    isClearable: true,
    options: sortByLocaleCompare(
      _.map(signalProviderTypes, (type) => ({
        label: type,
        value: type
      })),
      'label'
    ),
    onDidUpdate: (unused: unknown, value: SignalProviderType) => {
      if (value !== SignalProviderType.Equipment) {
        return [[(input) => input.equipmentTypeId, null]];
      }

      return [];
    }
  },
  {
    key: (input) => input.equipmentTypeId,
    label: T.signals.equipmenttype,
    modelType: ModelType.OPTIONS,
    options: _.map(equipmentTypes, (type) => ({
      label: type.name,
      value: type.equipmentTypeId
    })),
    isClearable: true,
    enabled: (input) => {
      return input.signalProviderType === SignalProviderType.Equipment;
    },
    onDidUpdate: (_name: unknown, value: string, _input: SignalInputType) => {
      if (value !== null) {
        return [
          [(input) => input.signalProviderType, SignalProviderType.Equipment]
        ];
      }

      return [];
    }
  }
];

/**
 * Required details per signal
 */
export const getSignalNameModels = (
  equipmentTypes: EquipmentTypeResponseModel[],
  signalProviderTypes: SignalProviderType[]
): ModelDefinition<SignalInputType>[] => [
  ...getSignalFilterModels(equipmentTypes, signalProviderTypes),
  {
    key: (input) => input.color,
    modelType: ModelType.COLOR,
    label: T.signals.customcolor
  },
  {
    key: (input) => input.timeRange,
    modelType: ModelType.OPTIONS,
    label: T.graphs.timerange.titlewithfilterdescription,
    options: _.map(TimeRangeOptionsText, (label, value) => ({ label, value })),
    isMultiOption: false,
    isClearable: true
  }
];

interface SignalNamesTableProps {
  onSignalsChanged?(signals: SignalInputType[]): void;
  signals?: SignalInputType[];
  optionalSignalModels?: ModelDefinition<SignalInputType>[];
  minItems?: number;
  maxItems?: number;
  useConstantValues?: boolean;
}
const SignalNamesTable = ({
  onSignalsChanged,
  signals: signalsIn,
  optionalSignalModels,
  minItems,
  maxItems,
  useConstantValues
}: SignalNamesTableProps) => {
  const signals: SignalInputType[] = useMemo(
    () => signalsIn ?? [],
    [signalsIn]
  );

  const movableRows = !(minItems === 1 && maxItems === 1);
  const canCopy =
    !(minItems === 1 && maxItems === 1) && signals.length < maxItems;

  useEffect(() => {
    if (signals !== signalsIn) {
      onSignalsChanged(signals);
    }
  }, [minItems, signals, onSignalsChanged, signalsIn]);

  const { nodeId, equipmentTypes, signalProviderTypes } =
    useContext(DashboardDataContext);

  const [isSignalsDialogOpen, showSignalDialog, hideSignalDialog] =
    useSimpleDialogState();
  const [editSignal, setEditSignal] = useState<SignalInputType>(null);
  const [editSignalIndex, setEditSignalIndex] = useState(-1);

  const [currentlySelectedSignals, setCurrentlySelectedSignals] = useState<
    ChartSignal[]
  >([]);

  const addConstant = useCallback(() => {
    const newSignals: SignalInputType[] = [
      ...signals,
      {
        id: UUID.generate(),
        signalTypeId: SignalTypeIds.CONSTANT_SIGNAL,
        displayName: null,
        value: 0
      }
    ];
    onSignalsChanged(newSignals);
  }, [signals, onSignalsChanged]);

  const addSignal = useCallback(() => {
    setEditSignal({
      id: UUID.generate(),
      displayName: null,
      signalTypeId: null,
      signalProviderType: null,
      equipmentTypeId: null,
      color: null,
      timeRange: null
    });
  }, []);

  const removeSignal = useCallback(
    (_item: unknown, index: number) => {
      const newSignals = [...signals];
      newSignals.splice(index, 1);
      onSignalsChanged(newSignals);
    },
    [onSignalsChanged, signals]
  );

  const updateEditedSignal = useCallback(
    (settings: SignalInputType) => {
      _.merge(editSignal, settings);

      if (editSignalIndex !== -1) {
        const newSignals = [...signals];
        newSignals[editSignalIndex] = editSignal;
        onSignalsChanged(newSignals);
      } else {
        const newSignals = [...signals, editSignal];
        onSignalsChanged(newSignals);
      }

      setEditSignalIndex(-1);
      setEditSignal(null);
    },
    [editSignal, editSignalIndex, signals, onSignalsChanged]
  );

  const stopEditingSignal = useCallback(() => {
    setEditSignal(null);
    setEditSignalIndex(-1);
  }, []);

  const onEditSignal = useCallback(
    (signalToEdit: SignalInputType, index: number) => {
      setEditSignal(signalToEdit);
      setEditSignalIndex(index);
    },
    []
  );

  const moveSignalUp = useCallback(
    (_unused: SignalInputType, indexToMove: number) => {
      if (indexToMove > 0) {
        const newSignals = [...signals];
        newSignals.splice(
          indexToMove - 1,
          2,
          newSignals[indexToMove],
          newSignals[indexToMove - 1]
        );
        onSignalsChanged(newSignals);
      }
    },
    [signals, onSignalsChanged]
  );

  const moveSignalDown = useCallback(
    (_unused: SignalInputType, indexToMove: number) => {
      if (indexToMove < signals.length - 1) {
        const newSignals = [...signals];
        newSignals.splice(
          indexToMove,
          2,
          newSignals[indexToMove + 1],
          newSignals[indexToMove]
        );
        onSignalsChanged(newSignals);
      }
    },
    [signals, onSignalsChanged]
  );

  const copySignal = useCallback(
    (signalToCopy: SignalInputType, indexToCopyFrom: number) => {
      const newSignals = [...signals];
      newSignals.splice(indexToCopyFrom + 1, 0, {
        ..._.cloneDeep(signalToCopy),
        id: UUID.generate()
      });
      onSignalsChanged(newSignals);
    },
    [signals, onSignalsChanged]
  );

  const onConstantSignalChanged = useCallback(
    (signal: SignalInputType, signalIndex: number, value: string) => {
      const newSignals = [...signals];
      newSignals[signalIndex] = { ...signal, value: _.toNumber(value) };
      onSignalsChanged(newSignals);
    },
    [onSignalsChanged, signals]
  );

  const columns: DataTableColumnProps<SignalInputType>[] = useMemo(() => {
    const hasTimeRange = _.some(signals, 'timeRange');
    const hasCategory = _.some(signals, 'category');

    return _.compact([
      {
        label: T.admin.dashboards.forms.signalnames.name,
        dataKey: 'signalTypeId',
        minWidth: 150,
        dataFormatter: (_unused: unknown, item, index) => {
          if (isConstantSignal(item)) {
            return (
              <TextInput
                type="number"
                value={item.value}
                onChange={(e) => {
                  onConstantSignalChanged(item, index, e.target.value);
                }}
              />
            );
          }
          return <SignalSettingName signal={item} />;
        }
      },
      hasCategory && {
        label: T.admin.dashboards.forms.signalnames.category,
        dataKey: 'category',
        minWidth: 100,
        maxWidth: 100,
        flexGrow: 0
      },
      hasTimeRange && {
        label: T.admin.dashboards.forms.signalnames.timerange,
        dataKey: 'timeRange',
        minWidth: 120,
        maxWidth: 120,
        flexGrow: 0,
        dataFormatter: (value: string) => TimeRangeOptionsText[value]
      },
      buttonListColumn(
        _.compact([
          movableRows && {
            icon: <Icons.NavigationArrowDown />,
            action: moveSignalDown
          },
          movableRows && {
            icon: <Icons.NavigationArrowUp />,
            action: moveSignalUp
          },
          canCopy && {
            icon: <Icons.Copy />,
            action: copySignal
          },
          {
            icon: <Icons.Edit />,
            action: onEditSignal,
            enabled: (item) => !isConstantSignal(item)
          },
          {
            icon: <Icons.Delete />,
            action: removeSignal
          }
        ])
      )
    ]);
  }, [
    onConstantSignalChanged,
    movableRows,
    canCopy,
    removeSignal,
    signals,
    onEditSignal,
    copySignal,
    moveSignalUp,
    moveSignalDown
  ]);

  const onSignalsSelected = useCallback(
    (selectedSignals: ChartSignal[]) => {
      const newSignalItems = selectedSignals.map((signal) => {
        // let equipmentTypeId = null;
        // TODO: New domain model, need to fix this
        // if (signal.group.signalProviderType === SignalProviderType.Equipment) {
        //   const equipmentObj = getNodeFromMap(
        //     equipmentMap,
        //     _.head(signal.group.nodeIds)
        //   );
        //   equipmentTypeId = equipmentObj?.equipmentTypeId;
        // }

        return {
          id: UUID.generate(),
          signalProviderType: signal.group.signalProviderType,
          equipmentTypeId: null,
          signalTypeId: signal.item.signalTypeId
        };
      });

      const newSignals = [...signals, ...newSignalItems];
      onSignalsChanged(newSignals);
      hideSignalDialog();
      setCurrentlySelectedSignals([]);
    },
    [hideSignalDialog, signals, onSignalsChanged]
  );

  const cancelSignalSection = useCallback(() => {
    hideSignalDialog();
    setCurrentlySelectedSignals([]);
  }, [hideSignalDialog]);

  const disabledAddButton = maxItems && signals.length >= maxItems;

  const allModels: ModelDefinition<SignalInputType>[] = useMemo(() => {
    return getSignalNameModels(equipmentTypes, signalProviderTypes).concat(
      optionalSignalModels ?? []
    );
  }, [optionalSignalModels, equipmentTypes, signalProviderTypes]);

  // The user can only select unique signal types
  const hasSignal = useCallback(
    (array: ChartSignal[], signal: SignalProviderSignalResponseModel) => {
      const inCurrentSelection = _.some(
        array,
        (otherSignal) => otherSignal.item.signalTypeId === signal.signalTypeId
      );
      const inCurrentSignals = _.some(
        signals,
        (item) => item.signalTypeId === signal.signalTypeId
      );

      return inCurrentSelection || inCurrentSignals;
    },
    [signals]
  );

  return (
    <Flex direction={LayoutDirection.VERTICAL}>
      <FlexItem>
        <DataTable<SignalInputType>
          data={signals}
          columns={columns}
          noDataText={T.admin.dashboards.forms.signalnames.empty}
          showNoticeHeaders={false}
        />
      </FlexItem>
      <FlexItem>
        <Flex justifyContent={JustifyContent.FLEX_END} wrap={FlexWrap.WRAP}>
          {useConstantValues && (
            <FlexItem>
              <AddButton disabled={disabledAddButton} onClick={addConstant}>
                {T.admin.dashboards.forms.signalnames.addconstant}
              </AddButton>
            </FlexItem>
          )}
          <FlexItem>
            <AddButton disabled={disabledAddButton} onClick={addSignal}>
              {T.common.add}
            </AddButton>
          </FlexItem>
          <FlexItem>
            <AddButton disabled={disabledAddButton} onClick={showSignalDialog}>
              {T.admin.dashboards.forms.signalnames.addfromexisting}
            </AddButton>
          </FlexItem>
        </Flex>
      </FlexItem>
      <EditSignalSettingsDialog
        isOpen={!_.isNil(editSignal)}
        onModalClose={stopEditingSignal}
        onSuccess={updateEditedSignal}
        models={allModels}
        input={editSignal}
        isEditing={editSignalIndex !== -1}
      />
      <SelectSignalsDialog
        isOpen={isSignalsDialogOpen}
        onModalClose={cancelSignalSection}
        onSignalsSelected={onSignalsSelected}
        selectedSignals={currentlySelectedSignals}
        nodeId={nodeId}
        hasSignal={hasSignal}
        title={T.admin.dashboards.forms.signalnames.modaltitle}
        showSignalType
      />
    </Flex>
  );
};

export default React.memo(SignalNamesTable);
