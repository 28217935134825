import React, { useCallback, useState } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import SegmentControl from 'ecto-common/lib/SegmentControl/SegmentControl';
import { SegmentControlItem } from 'ecto-common/lib/SegmentControl/SegmentControlItem';

import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarHeading from 'ecto-common/lib/Toolbar/ToolbarHeading';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';

import DeleteButton from 'ecto-common/lib/Button/DeleteButton';
import AddButton from 'ecto-common/lib/Button/AddButton';

import AutomaticScheduleProviderToolbarButton from 'js/components/EMPTools/PowerControl/Dispatch/AutomaticScheduleProviderToolbarButton';
import PowerControlSignalChart from 'js/components/EMPTools/PowerControl/Dispatch/PowerControlSignalChart';
import styles from 'js/components/EMPTools/PowerControl/Dispatch/CommonDispatch.module.css';
import ScheduleList from 'js/components/EMPTools/PowerControl/Dispatch/ScheduleList';
import {
  BuildingStatus,
  NodeV2ResponseModel,
  SignalGroupLiteResponseModel
} from 'ecto-common/lib/API/APIGen';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';

// Convert camel case to spaces
const makePowerControlNameReadable = (name: string) =>
  name ? name.replace(/([A-Z])/g, ' $1').trim() : '';

interface ControlDispatchProps {
  selectedPowerControl: SignalGroupLiteResponseModel;
  selectedNode?: NodeV2ResponseModel;
}

const ControlDispatch = ({
  selectedPowerControl,
  selectedNode
}: ControlDispatchProps) => {
  const [seriesInterval, setSeriesInterval] = useState(48);
  const [isShowingAddSchedule, showAddSchedule, hideAddSchedule] =
    useDialogState('show-create-schedule');
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  const onGraphZoomed = useCallback(() => {
    setSeriesInterval(-1);
  }, []);

  return (
    <div className={styles.dispatchPage}>
      <Toolbar className={styles.toolbar}>
        <ToolbarHeading
          isPageHeading
          title={makePowerControlNameReadable(selectedPowerControl.name)}
        />

        <ToolbarFlexibleSpace />

        <AutomaticScheduleProviderToolbarButton />

        <ToolbarItem>
          <AddButton onClick={showAddSchedule}>
            {T.powercontrol.dispatch.scheduled.action.addschedule}
          </AddButton>
        </ToolbarItem>

        {_.get(selectedNode, 'buildingInfo.buildingStatus') ===
          BuildingStatus.Testing && (
          <ToolbarItem>
            <DeleteButton onClick={() => setShowConfirmDeleteDialog(true)}>
              {T.powercontrol.dispatch.scheduled.action.removeall}
            </DeleteButton>
          </ToolbarItem>
        )}

        <ToolbarItem>
          <SegmentControl>
            {[12, 24, 48].map((interval) => {
              return (
                <SegmentControlItem
                  compact
                  key={`intervalButton${interval}`}
                  active={seriesInterval === interval}
                  onClick={() => setSeriesInterval(interval)}
                >
                  {interval}
                  {T.powercontrol.dispatch.chart.hour}
                </SegmentControlItem>
              );
            })}
          </SegmentControl>
        </ToolbarItem>
      </Toolbar>

      <PowerControlSignalChart
        seriesInterval={seriesInterval}
        onGraphZoomed={onGraphZoomed}
      />

      <ScheduleList
        showAddSchedule={isShowingAddSchedule}
        onCloseAddSchedule={hideAddSchedule}
        showConfirmDeleteDialog={showConfirmDeleteDialog}
        onCloseShowConfirmDeleteDialog={() => setShowConfirmDeleteDialog(false)}
        selectedNode={selectedNode}
        selectedPowerControl={selectedPowerControl}
      />
    </div>
  );
};

export default ControlDispatch;
