import React, { useContext, useMemo } from 'react';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext from 'ecto-common/lib/hooks/DashboardDataContext';
import { CustomPanelProps } from 'ecto-common/lib/Dashboard/Panel';
import {
  LastSignalValuesDataSourceResult,
  SignalInputType
} from 'ecto-common/lib/Dashboard/datasources/LastSignalValuesDataSource';
import DataSourceTypes from 'ecto-common/lib/Dashboard/datasources/DataSourceTypes';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import SignalsTable from './SignalsTable';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import { nodeIsSpace } from '../../hooks/useCurrentNode';

const EmptyNodes: NodeV2ResponseModel[] = [];

type LocationMapPanelDataType = {
  signals: LastSignalValuesDataSourceResult;
};

type LocationMapPanelProps = CustomPanelProps & {
  data: LocationMapPanelDataType;
};

const SignalsTablePanel = ({ data }: LocationMapPanelProps) => {
  const nodes = data?.signals?.nodes ?? EmptyNodes;
  const { nodeId } = useContext(DashboardDataContext);

  const { node } = useNode(nodeId);

  // When showing a site, don't show the actual site only it's children.
  // The exception to this is when the site does not contain any children.
  const filteredNodes = useMemo(() => {
    if (nodeIsSpace(node)) {
      // The site is the only node, keep it
      if (nodes.length === 1 && nodes[0].nodeId === node?.nodeId) {
        return nodes;
      }

      return _.without(nodes, node);
    }

    return nodes;
  }, [nodes, node]);

  return <SignalsTable nodeList={filteredNodes} signals={data?.signals} />;
};

const optionalSignalModels: ModelDefinition<SignalInputType>[] = [
  {
    key: (input) => input.displayName,
    modelType: ModelType.TEXT,
    label: T.signals.displayname,
    placeholder: T.signals.displaynameplaceholder
  }
];

export const SignalsTablePanelData = {
  helpPath: HelpPaths.docs.dashboard.dashboards.map,
  dataSourceSectionsConfig: {
    [DataSourceTypes.SIGNALS_LAST_VALUE]: {
      minItems: 0,
      optionalSignalModels
    }
  },
  emptyTargets: {
    signals: {
      sourceType: DataSourceTypes.SIGNALS_LAST_VALUE,
      useSiblings: true
    }
  }
};

export default React.memo(SignalsTablePanel);
