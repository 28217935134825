import React from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { getApiEnvironment } from 'ecto-common/lib/utils/apiEnvironment';
import { AuthenticationContext } from 'ecto-common/lib/hooks/useAuthentication';
import { useSimpleEventHubConnection } from 'ecto-common/lib/EventHubConnection/useSimpleEventHubConnection';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { HubConnection } from '@microsoft/signalr';
import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

export const AlarmEventTypes = {
  Created: 0,
  Activated: 1,
  Acknowledged: 2,
  Inactivated: 3
};

export type AlarmEventType = ObjectValues<typeof AlarmEventTypes>;

export type AlarmEvent = {
  nodeId: string;
  sourceUri: string;
  signalId?: string;
  signalName?: string;
  severity: number;
  eventType: AlarmEventType;
};

export type AlarmUpdateContextProps = {
  addListener: (listener: (data: AlarmEvent) => void) => void;
  removeListener: (listener: (data: AlarmEvent) => void) => void;
  listeners: ((data: AlarmEvent) => void)[];
};

export const AlarmUpdateContext = createContext<AlarmUpdateContextProps>({
  addListener: null,
  removeListener: null,
  listeners: []
});

export const useAlarmUpdates = (onAlarmEvent: (event: AlarmEvent) => void) => {
  const { scopes, msalConfiguration, currentAccount } = useContext(
    AuthenticationContext
  );

  const methods = useMemo(
    () => [
      {
        method: 'SendAlarmEvent',
        handler: (data: unknown) => {
          onAlarmEvent?.(data as AlarmEvent);
        }
      }
    ],
    [onAlarmEvent]
  );

  const onError = useCallback((error: unknown) => {
    console.error(error);
  }, []);

  const { tenantId } = useContext(TenantContext);

  const alarmScopes = useMemo(() => [scopes.alarms], [scopes]);
  const url = useMemo(
    () =>
      getApiEnvironment().urls.alarmsSignalRUrl?.replace('/api', '') +
      '/alarmEventsHub',
    []
  );
  const { contextSettings } = useContext(TenantContext);
  const onStart = useCallback(
    (connection: HubConnection) => {
      const severity = 0;
      connection.invoke('Subscribe', tenantId, severity);
    },
    [tenantId]
  );

  useSimpleEventHubConnection(
    contextSettings,
    onError,
    url,
    alarmScopes,
    msalConfiguration,
    currentAccount,
    methods,
    onStart
  );
};

export const AlarmUpdateProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const contextData = useMemo(() => {
    const listeners: ((data: AlarmEvent) => void)[] = [];
    return {
      listeners,
      addListener: (listener: (data: AlarmEvent) => void) => {
        listeners.push(listener);
      },
      removeListener: (listener: (data: AlarmEvent) => void) => {
        const index = listeners.indexOf(listener);
        if (index > -1) {
          listeners.splice(index, 1);
        }
      }
    };
  }, []);

  const onAlarmEvent = useCallback(
    (event: AlarmEvent) => {
      contextData.listeners.forEach((listener) => listener(event));
    },
    [contextData.listeners]
  );

  useAlarmUpdates(onAlarmEvent);
  return (
    <AlarmUpdateContext.Provider value={contextData}>
      {children}
    </AlarmUpdateContext.Provider>
  );
};
