import React, { Fragment } from 'react';
import styles from './Documentation.module.css';
import stripIndent from 'strip-indent';
import { exampleSourceData } from 'ecto-common/lib/Documentation/DocumentationData';

type ExampleFunc = {
  (): React.ReactNode;
  description?: string;
};

interface DocumentationExampleProps {
  example?: ExampleFunc;
  name?: string;
}

const DocumentationExample = ({ example, name }: DocumentationExampleProps) => {
  let source = null;

  if (name in exampleSourceData) {
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    source = stripIndent((exampleSourceData as any)[name].source);
  }

  return (
    <Fragment>
      {example.description && (
        <div className={styles.exampleDescription}>{example.description}</div>
      )}

      <div className={styles.example}>
        <div className={styles.exampleInner}>{example()}</div>
        <div className={styles.exampleSource}>
          <pre>{source}</pre>
        </div>
      </div>
    </Fragment>
  );
};

export default DocumentationExample;
