import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import _ from 'lodash';

import {
  isNullOrWhitespace,
  searchByCaseInsensitive
} from 'ecto-common/lib/utils/stringUtils';
import { getLatestDataPoint } from 'ecto-common/lib/SignalsTable/signalsTableUtils';
import ProcessMapHeader from 'ecto-common/lib/ProcessMap/ProcessMapHeader';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import SignalsTable, {
  SignalTableColumnsKey,
  SIGNALS_TABLE_COLUMNS,
  SignalsTableSignal,
  InternalSignalsTableSignal
} from 'ecto-common/lib/SignalsTable/SignalsTable';
import { useLiveEquipmentSignals } from 'ecto-common/lib/hooks/useLiveEquipmentSignals';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';

import styles from './ProcessMap.module.css';
import { useProcessMapSignals } from './ProcessMapHooks';
import sortByLocaleCompare from 'ecto-common/lib/utils/sortByLocaleCompare';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import ProcessMapEquipmentHeader from 'ecto-common/lib/ProcessMap/ProcessMapEquipmentHeader';
import { useImmer } from 'use-immer';
import {
  identity,
  translate,
  compose,
  inverse,
  toCSS
} from 'transformation-matrix';

import {
  FullSignalProviderResponseModel,
  SignalResponseModel,
  SignalTypeResponseModel,
  UnitResponseModel
} from 'ecto-common/lib/API/APIGen';
import { SignalProviderSignalWithProviderResponseModel } from 'ecto-common/lib/types/EctoCommonTypes';
import { Moment } from 'moment';
import { LastSignalValuesResultWithMetadata } from 'ecto-common/lib/Dashboard/panels/SignalListPanel';
import ProcessMapViewV2, {
  MouseActions
} from 'ecto-common/lib/ProcessMap/ProcessMapViewV2';
import {
  ProcessMapObject,
  ProcessMapActionTypes,
  ProcessMapDocument,
  ProcessMapRectHandle,
  ProcessMapSignalTextObject,
  ProcessMapTextObject,
  emptyProcessMapDocument,
  smallConnectionCircleRadius,
  EquipmentProcessMapAction,
  NodeProcessMapAction,
  ProcessMapViewSignal
} from 'ecto-common/lib/ProcessMap/ProcessMapViewConstants';
import { resizeTextNode } from 'ecto-common/lib/ProcessMap/ProcessMapViewUtils';
import T from '../lang/Language';
import { GenericSelectOption } from '../Select/Select';
import { ProcessMapNodeDataType } from '../ProcessMaps/ProcessMapDataHandling';
import { ASC, DESC, SortDirectionType } from '../DataTable/SortDirection';
import CollapsingSegmentControlPicker from '../SegmentControl/CollapsingSegmentControlPicker';
import { ProcessMapEditorActions } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorActions';
import { ProcessMapViewState } from 'ecto-common/lib/ProcessMaps/ProcessMapEditorTypes';
import dimensions from 'ecto-common/lib/styles/dimensions';
import ProcessMapZoomSelector from 'ecto-common/lib/ProcessMaps/ProcessMapZoomSelector';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';
import { NodeTraitIds } from 'ecto-common/lib/utils/constants';

const AllKeys = _.map(SIGNALS_TABLE_COLUMNS, ({ dataKey }) => dataKey);

const HiddenKeys = [SignalTableColumnsKey.SIGNAL_TYPE_DESCRIPTION];

const ColumnKeys = _.filter(AllKeys, (key) => {
  return !_.includes(HiddenKeys, key);
});

type ProcessMapProps = {
  selectedSignalProviders: FullSignalProviderResponseModel[];
  selectedNodeId?: string;
  isLoading?: boolean;
  selectedSignalIds: Record<string, boolean>;
  setSelectedSignalIds?: Dispatch<SetStateAction<Record<string, boolean>>>;
  selectedImageId?: string;
  onSelectedImageIdChanged?: (id: string) => void;
  hasError?: boolean;
  searchFilter?: string;
  fromDate?: Moment;
  onOpenEquipmentType?: (event: MouseEvent, equipmentTypeId: string) => void;
  onNavigateToNodeId: (
    event: MouseEvent,
    nodeId: string,
    previewSignalIds: string[]
  ) => void;
  onSortChange?: (orderBy: string, sortDirection: SortDirectionType) => void;
  sortBy?: string;
  sortDirection?: SortDirectionType;
};

type ProcessMapNewVersionType = {
  image: ProcessMapDocument;
  signalProviders: FullSignalProviderResponseModel[];
  signalData: LastSignalValuesResultWithMetadata;
  mouseActions: MouseActions;
  signalTypesMap: Record<string, SignalTypeResponseModel>;
  signalUnitTypesMap: Record<string, UnitResponseModel>;
  isLoading: boolean;
  showDeleteConnections: boolean;
  zoomButtonVerticalOffset?: number;
};

const emptyHoverRectHandles: ProcessMapRectHandle[] = [];

// TODO: Maybe rename set current/edit signal to 'onOverSignal' and 'onClickSignal' for more generic use.
export const useMouseActions = ({
  setCurrentEditSignal,
  toggleSignalSelection,
  setCurrentSignal,
  openEquipmentPage,
  onNavigateToNodeId
}: {
  setCurrentEditSignal?: (signal: ProcessMapViewSignal) => void | undefined;
  toggleSignalSelection?: (signal: ProcessMapViewSignal) => void | undefined;
  setCurrentSignal?: (signal: ProcessMapViewSignal | null) => void | undefined;
  openEquipmentPage?: (event: MouseEvent, equipmentTypeId: string) => void;
  onNavigateToNodeId?: (
    event: MouseEvent,
    nodeId: string,
    previewSignalIds: string[]
  ) => void;
}) => {
  return useMemo(
    (): MouseActions => ({
      [ProcessMapActionTypes.Signal]: {
        onClick: (
          _unusedEvent: MouseEvent,
          _unusedNode: ProcessMapObject,
          signal: ProcessMapViewSignal,
          isWritable: boolean
        ) => {
          if (isWritable) {
            setCurrentEditSignal?.(signal);
          } else {
            toggleSignalSelection?.(signal);
          }
        },
        onMouseOver: (
          _unusedEvent: MouseEvent,
          _unusedNode: ProcessMapObject,
          signal: ProcessMapViewSignal
        ) => {
          setCurrentSignal?.(signal);
        },
        onMouseOut: (
          _unusedEvent: MouseEvent,
          _unusedNode: ProcessMapObject
        ) => {
          setCurrentSignal?.(null);
        }
      },
      [ProcessMapActionTypes.EquipmentType]: {
        onClick: (event, node) =>
          openEquipmentPage?.(
            event,
            (node.action as EquipmentProcessMapAction)?.equipmentType
          ),
        onMouseOver: _.noop,
        onMouseOut: _.noop
      },
      [ProcessMapActionTypes.Node]: {
        onClick: (event, node) =>
          onNavigateToNodeId?.(
            event,
            (node.action as NodeProcessMapAction).nodeId,
            (node.action as NodeProcessMapAction).previewSignalIds ?? []
          ),
        onMouseOver: _.noop,
        onMouseOut: _.noop
      }
    }),
    [
      onNavigateToNodeId,
      openEquipmentPage,
      setCurrentEditSignal,
      setCurrentSignal,
      toggleSignalSelection
    ]
  );
};

export const ProcessMapNewVersionWrapper = ({
  signalProviders,
  signalData,
  mouseActions,
  image,
  signalTypesMap,
  signalUnitTypesMap,
  showDeleteConnections,
  isLoading,
  zoomButtonVerticalOffset = 75
}: ProcessMapNewVersionType) => {
  const [processMapState, setProcessMapState] =
    useImmer<ProcessMapViewState>(null);

  let processMap = processMapState;

  if (processMap == null) {
    const res: ProcessMapDocument =
      image ?? _.cloneDeep(emptyProcessMapDocument);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (res as any).oldSvgData;
    const mapState: ProcessMapViewState = {
      processMap: res,
      transform: {
        current: identity(),
        inverse: identity()
      }
    };
    setProcessMapState(mapState);
    processMap = mapState;
  }

  const updateTextSize = useCallback(
    (objectIndex: number, rectIndex: number, width: number, height: number) => {
      _.defer(() => {
        setProcessMapState((draft) => {
          const object = draft.processMap.objects[objectIndex] as
            | ProcessMapTextObject
            | ProcessMapSignalTextObject;
          const rect = object.rects[rectIndex];
          resizeTextNode(object, rect, width, height);
        });
      });
    },
    [setProcessMapState]
  );

  const svgRef: React.RefObject<SVGSVGElement> = useRef(null);
  const [isMoving, setIsMoving] = useState(false);

  const onMouseDown: React.MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      if (event.button === 2 || event.button === 1) {
        event.preventDefault();
        event.stopPropagation();
        setIsMoving(true);
      }
    },
    []
  );

  const onMouseMove: React.MouseEventHandler<SVGSVGElement> = useCallback(
    (event) => {
      if (isMoving) {
        setProcessMapState((oldState) => {
          oldState.transform.current = compose(
            oldState.transform.current,
            translate(event.movementX, event.movementY)
          );
          oldState.transform.inverse = inverse(oldState.transform.current);
        });
      }
    },
    [isMoving, setProcessMapState]
  );

  const onMouseUp: React.MouseEventHandler<SVGSVGElement> = useCallback(() => {
    setIsMoving(false);
  }, []);

  const matrixTransform = toCSS(processMap?.transform?.current);

  const setZoom = useCallback(
    (zoom: number) => {
      setProcessMapState((draft) =>
        ProcessMapEditorActions.setZoom(
          draft,
          zoom,
          draft.processMap.width / 2.0,
          draft.processMap.height / 2.0
        )
      );
    },
    [setProcessMapState]
  );

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: zoomButtonVerticalOffset,
          right: dimensions.standardMargin
        }}
      >
        <ProcessMapZoomSelector
          value={processMapState?.transform?.current?.a}
          setZoom={setZoom}
        />
      </div>
      <svg
        ref={svgRef}
        width={processMap.processMap.width}
        height={processMap.processMap.height + dimensions.standardMargin}
        viewBox={
          '0 0 ' +
          processMap.processMap.width +
          ' ' +
          processMap.processMap.height
        }
        style={{
          maxWidth: '100%',
          width: '100%',
          overflow: 'visible',
          cursor: isMoving ? 'move' : undefined,
          boxSizing: 'border-box',
          paddingBottom: dimensions.standardMargin
        }}
        onContextMenu={(event) => event.preventDefault()}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
      >
        <ProcessMapViewV2
          connectionCircleRadius={smallConnectionCircleRadius}
          signalProviders={signalProviders}
          signalData={signalData}
          signalTypesMap={signalTypesMap}
          signalUnitTypesMap={signalUnitTypesMap}
          processMap={processMap?.processMap}
          mouseActions={mouseActions}
          matrixTransform={matrixTransform}
          hoverRectHandles={emptyHoverRectHandles}
          pendingConnectionLine={null}
          pendingConnectionSymbol={null}
          draggingSingleLinePoint={false}
          isMouseDown={false}
          isLoading={isLoading}
          updateTextSize={updateTextSize}
          setRectResizeElement={_.noop}
          showDeleteConnections={showDeleteConnections}
          onAddNewLinePoint={null}
          zoom={1.0}
        />
      </svg>
    </>
  );
};

const ProcessMap = ({
  selectedSignalProviders,
  selectedNodeId = null,
  isLoading = false,
  selectedSignalIds,
  setSelectedSignalIds = null,
  selectedImageId = null,
  onSelectedImageIdChanged = null,
  searchFilter = null,
  hasError = false,
  fromDate = null,
  onOpenEquipmentType = null,
  onNavigateToNodeId,
  onSortChange = null,
  sortBy = null,
  sortDirection = ASC
}: ProcessMapProps) => {
  const [currentEditSignal, setCurrentEditSignal] =
    useState<SignalProviderSignalWithProviderResponseModel>(null);
  const [currentSignal, setCurrentSignal] =
    useState<ProcessMapViewSignal>(null);

  const _setCurrentEditSignal = useCallback(
    (signal: SignalProviderSignalWithProviderResponseModel) => {
      setCurrentEditSignal(signal);
    },
    []
  );

  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );

  const {
    image,
    images,
    error,
    signalData,
    isLoading: isLoadingProcessMap,
    selectedImage
  } = useProcessMapSignals({
    nodeId: selectedNodeId,
    signalProviders: selectedSignalProviders,
    onlyMappedSignals: false,
    fromDate,
    selectedImageId
  });
  const isPageLoading = isLoading || isLoadingProcessMap;

  const { node } = useNode(selectedNodeId);

  const equipmentNodeIds = useMemo(() => {
    if (node.nodeTraitIds.includes(NodeTraitIds.EQUIPMENT)) {
      return [selectedNodeId];
    }
    return [];
  }, [node.nodeTraitIds, selectedNodeId]);

  useLiveEquipmentSignals(equipmentNodeIds);

  const tableData: SignalsTableSignal[] = useMemo(() => {
    let filteredSignals = _.flatMap(selectedSignalProviders, (provider) => {
      return _.map(provider.signals, (signal) => ({
        ...signal,
        signalProvider: provider,
        rawSignal: signal
      }));
    });

    filteredSignals = sortByLocaleCompare(filteredSignals, [
      'signalProvider.signalProviderName',
      'name'
    ]);
    filteredSignals = _.map(filteredSignals, (signal) => ({
      ...signal,
      signalType: signalTypesMap[signal.signalTypeId]
    }));

    if (!isNullOrWhitespace(searchFilter)) {
      filteredSignals = searchByCaseInsensitive(
        filteredSignals,
        searchFilter,
        'name',
        'signalType.name'
      );
    }
    let tableSignals = _.map(filteredSignals, (signal) => {
      const dataPoint = getLatestDataPoint(signalData[signal.signalId]);

      return {
        ...signal,
        ...dataPoint,
        step: signalData[signal.signalId]?.step
      };
    });

    if (sortBy === 'value') {
      tableSignals = _.sortBy(tableSignals, (signal) => signal.value);
    } else {
      tableSignals = sortByLocaleCompare(tableSignals, [sortBy, 'name']);
    }

    if (sortDirection === DESC) {
      tableSignals = tableSignals.reverse();
    }

    return tableSignals;
  }, [
    selectedSignalProviders,
    searchFilter,
    sortBy,
    sortDirection,
    signalTypesMap,
    signalData
  ]);

  const showTable = !isPageLoading && tableData.length > 0;
  const selectSignal = useCallback(
    (signal: SignalResponseModel) => {
      const { signalId } = signal;

      setSelectedSignalIds?.((oldSelectedSignals) => {
        const newSelectedSignals = { ...oldSelectedSignals };

        if (oldSelectedSignals[signalId] != null) {
          delete newSelectedSignals[signalId];
        } else {
          newSelectedSignals[signalId] = true;
        }

        return newSelectedSignals;
      });
    },
    [setSelectedSignalIds]
  );

  const _setCurrentSignal = useCallback(
    (newSignal: ProcessMapViewSignal) => {
      setCurrentSignal(newSignal);
    },
    [setCurrentSignal]
  );

  const showProcessMap = isNullOrWhitespace(searchFilter);

  const otherSignals = useMemo(
    () => _.flatMap(selectedSignalProviders, (provider) => provider.signals),
    [selectedSignalProviders]
  );

  const selectProcessMapViewSignal = useCallback(
    (signal: ProcessMapViewSignal) => {
      selectSignal(signal.rawSignal);
    },
    [selectSignal]
  );

  const selectSignalsTableSignal = useCallback(
    (signal: InternalSignalsTableSignal) => {
      selectSignal(signal.rawSignal);
    },
    [selectSignal]
  );

  const mouseActions = useMouseActions({
    setCurrentEditSignal: _setCurrentEditSignal,
    toggleSignalSelection: selectProcessMapViewSignal,
    setCurrentSignal: _setCurrentSignal,
    openEquipmentPage: onOpenEquipmentType,
    onNavigateToNodeId
  });

  const imageOptions = useMemo(() => {
    return images.map((item) => ({
      label: item.name,
      value: item
    }));
  }, [images]);

  const selectedImageOption = useMemo(() => {
    return imageOptions.find(
      (imageOption) =>
        imageOption.value.id === (selectedImageId ?? selectedImage?.id)
    );
  }, [imageOptions, selectedImageId, selectedImage?.id]);

  const onSelectImage = useCallback(
    (option: GenericSelectOption<ProcessMapNodeDataType>) => {
      if (option.value !== image) {
        onSelectedImageIdChanged?.(option.value.id);
        setCurrentEditSignal(null);
        setCurrentSignal(null);
      }
    },
    [image, onSelectedImageIdChanged]
  );

  return (
    <>
      {error && (
        <ErrorNotice className={styles.error}>{T.common.error}</ErrorNotice>
      )}
      {imageOptions.length > 1 && (
        <CollapsingSegmentControlPicker
          options={imageOptions}
          value={selectedImageOption}
          onChangeValue={onSelectImage}
          className={styles.select}
          isLoading={isLoadingProcessMap}
          placeholder={isLoadingProcessMap ? T.common.loading : null}
        />
      )}
      <ProcessMapEquipmentHeader />
      {image && showProcessMap && (
        <PlainBox className={styles.container}>
          <ProcessMapHeader
            currentSignal={currentSignal}
            signalData={signalData}
          />
          <ProcessMapNewVersionWrapper
            key={selectedImageId}
            image={image}
            mouseActions={mouseActions}
            signalProviders={selectedSignalProviders}
            signalData={signalData}
            signalTypesMap={signalTypesMap}
            signalUnitTypesMap={signalUnitTypesMap}
            isLoading={isLoadingProcessMap}
            showDeleteConnections={false}
          />
        </PlainBox>
      )}

      {isPageLoading && <LoadingContainer isLoading />}

      {showTable && (
        <div data-disableclosedropdownmenuonclick="1">
          <SignalsTable
            sortDirection={sortDirection}
            sortBy={sortBy}
            onSortChange={onSortChange}
            signals={tableData}
            isLoading={isLoading}
            hasError={hasError}
            className={styles.mappedTable}
            onClickRow={selectSignalsTableSignal}
            setCurrentEditSignal={_setCurrentEditSignal}
            currentEditSignal={currentEditSignal}
            signalValuesById={signalData}
            otherSignals={otherSignals}
            selectedSignalIds={selectedSignalIds}
            columnKeys={ColumnKeys}
          />
        </div>
      )}
    </>
  );
};

export default React.memo(ProcessMap);
