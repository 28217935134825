import { DashboardCollectionViewResponseModel } from 'ecto-common/lib/API/APIGen';
import localStore from 'store';

export const DASHBOARD_COLLECTIONS_KEY = 'DASHBOARD_COLLECTIONS_KEY';

let collections: Record<string, DashboardCollectionViewResponseModel> = null;

const tryLoadCollections = () => {
  if (collections == null) {
    collections = localStore.get(DASHBOARD_COLLECTIONS_KEY) ?? {};
  }
};

export const getCachedDashboardCollection = (dashboardCollectionId: string) => {
  tryLoadCollections();
  return collections[dashboardCollectionId];
};

export const setCachedDashboardCollection = (
  collection: DashboardCollectionViewResponseModel
) => {
  tryLoadCollections();
  collections[collection.dashboardCollectionId] = collection;
  localStore.set(DASHBOARD_COLLECTIONS_KEY, collections);
};
