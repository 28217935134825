import React, {
  MouseEventHandler,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { EctoplannerTimeSeries } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';

import _ from 'lodash';
import EctoplannerEditGraphDialog from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerEditGraphDialog';
import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import Toolbar from 'ecto-common/lib/Toolbar/Toolbar';
import Select, {
  GenericSelectOption,
  useLabelSelectOptions as getLabeledSelectProps
} from 'ecto-common/lib/Select/Select';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import Icons from 'ecto-common/lib/Icons/Icons';
import HighchartsReact from 'highcharts-react-official';
import { Highcharts } from 'ecto-common/lib/Highcharts/Highcharts';
import T from 'ecto-common/lib/lang/Language';
import { useQueries, useQueryClient } from '@tanstack/react-query';
import EctoplannerAPIGen, {
  BuildGraphAggregation,
  BuildGraphResponse,
  BuildGraphSeries,
  BuildGraphType,
  BuildResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { parseTimeSeriesFile } from 'js/components/Ectoplanner/Calculation/EctoplannerCalculationUtil';
import styles from './EctoplannerGraphBrowser.module.css';
import ToolbarFlexibleSpace from 'ecto-common/lib/Toolbar/ToolbarFlexibleSpace';
import {
  EctoplannerAggregatorFunctions,
  EctoplannerSamplingIntervals,
  EctoplannerStandardGraphColors,
  EctoplannerZoomLevel,
  EctoplannerZoomLevels,
  EctoplannerZoomToSamplingInterval,
  ectoplannerHourNames,
  ectoplannerLongMonthNames,
  ectoplannerMonthBins,
  getEctoplannerHighchartsCategories
} from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowserTypes';
import Notice from 'ecto-common/lib/Notice/Notice';
import ToolbarMenuDivider from 'ecto-common/lib/Toolbar/ToolbarMenuDivider';
import { EctoplannerGraphStoreContext } from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphStore';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import { useStore } from 'zustand';
import useUndoShortcuts from 'ecto-common/lib/hooks/useUndoShortcuts';
import { EctoplannerUnitMwh } from './EctoplannerGraphBrowserTypes';
import { downloadBlobTextWithoutUTF8BOM } from 'ecto-common/lib/utils/downloadBlob';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import classNames from 'classnames';
import Color from 'color';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { boostHighchartsSettings } from 'ecto-common/lib/SignalSelector/ChartUtils';
import { formatNumberUnit } from 'ecto-common/lib/utils/stringUtils';

// Add draggable points module
/* eslint-disable @typescript-eslint/no-var-requires */
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsDraggablePoints from 'highcharts/modules/draggable-points';
HighchartsMore(Highcharts);
HighchartsDraggablePoints(Highcharts);

const shouldConvertKwhToMwh = (
  unit: string,
  zoom: EctoplannerZoomLevel,
  graphType: BuildGraphType
) => {
  if (
    graphType === BuildGraphType.DurationCurve ||
    graphType === BuildGraphType.Scatter
  ) {
    return false;
  }

  return (
    unit === 'kWh' &&
    (zoom === EctoplannerZoomLevels.MONTH ||
      zoom === EctoplannerZoomLevels.YEAR)
  );
};

const domProps = {
  style: {
    height: '100%'
  }
};

const transformSeriesData = (
  values: number[],
  xAxisTimeSeries: number[],
  zoom: EctoplannerZoomLevel,
  aggregation: BuildGraphAggregation,
  scaling: number,
  graphType: BuildGraphType
): number[] | number[][] => {
  const samplingInterval = EctoplannerZoomToSamplingInterval[zoom];
  const aggregator = EctoplannerAggregatorFunctions[aggregation];
  let newValues: number[] = [];

  if (graphType === BuildGraphType.DurationCurve) {
    newValues = [...values];
    newValues.sort((a, b) => b - a);
    return newValues;
  } else if (graphType === BuildGraphType.Scatter) {
    if (xAxisTimeSeries?.length > 0) {
      return values.map((value, index) => [
        xAxisTimeSeries?.[index] ?? 0,
        value
      ]);
    }

    return values;
  }

  switch (samplingInterval) {
    case EctoplannerSamplingIntervals.MONTH:
      {
        let curIndex = 0;

        for (
          let curMonth = 0;
          curMonth < ectoplannerMonthBins.length;
          curMonth++
        ) {
          const startIndex = curIndex;
          const endIndex = curIndex + ectoplannerMonthBins[curMonth] * 24;
          // TODO: End inclusive?
          newValues.push(
            aggregator(values.slice(startIndex, endIndex)) / scaling
          );
          curIndex = endIndex;
        }
      }
      break;
    case EctoplannerSamplingIntervals.DAY:
      for (let day = 0; day < values.length; day += 24) {
        newValues.push(aggregator(values.slice(day, day + 24)) / scaling);
      }
      break;
    case EctoplannerSamplingIntervals.HOUR:
      // No need to do anything except scaling, samples are already per hour. Can't aggregate with just one value either.
      newValues = values.map((value) => value / scaling);

      for (let i = 0; i < newValues.length; i++) {
        newValues[i] /= scaling;
      }

      break;
    default:
      break;
  }

  return newValues;
};

type ZoomSettings = {
  zoom: EctoplannerZoomLevel;
  range: [number, number];
  title: string;
};

const getHighchartsChartType = (
  graphType: BuildGraphType
): 'column' | 'scatter' | 'line' => {
  switch (graphType) {
    case BuildGraphType.Column:
      return 'column';
    case BuildGraphType.Scatter:
      return 'scatter';
    default:
    case BuildGraphType.DurationCurve:
    case BuildGraphType.Line:
      return 'line';
  }
};

const getHighchartOptions = (
  collectionSeries: BuildGraphSeries[],
  scatterPlotXAxisSeriesId: string,
  seriesById: Record<string, EctoplannerTimeSeries>,
  seriesDataById: Record<string, string>,
  zoomSettings: ZoomSettings,
  graphType: BuildGraphType,
  onClickIndex: (index: number) => void
): Highcharts.Options => {
  const allSeries = _.compact(
    collectionSeries.map((series) => {
      if (
        seriesById[series.id] == null ||
        (graphType === BuildGraphType.Scatter &&
          series.id === scatterPlotXAxisSeriesId)
      ) {
        return null;
      }
      let unit = seriesById[series.id].unit ?? '';
      let scaling = 1;

      if (shouldConvertKwhToMwh(unit, zoomSettings.zoom, graphType)) {
        unit = EctoplannerUnitMwh;
        scaling = 1000;
      }

      return {
        ...seriesById[series.id],
        unit,
        scaling,
        aggregation: series.aggregation
      };
    })
  );

  const currentColors = {
    ...EctoplannerStandardGraphColors
  };

  const yAxis = _.uniq(
    allSeries.map((series) => {
      return series?.unit ?? '';
    })
  ).map((unit, idx) => {
    return {
      id: unit,
      title: {
        text: unit
      },
      showEmpty: true,
      opposite: idx % 2 === 1
    };
  });

  const disableZoom =
    graphType === BuildGraphType.DurationCurve ||
    graphType === BuildGraphType.Scatter;

  const type = getHighchartsChartType(graphType);

  const xAxisTimeSeries =
    graphType === BuildGraphType.Scatter
      ? parseTimeSeriesFile(seriesDataById[scatterPlotXAxisSeriesId])
      : null;

  const categories = getEctoplannerHighchartsCategories(
    zoomSettings.zoom,
    graphType
  );
  return _.mergeWith(
    { ...boostHighchartsSettings() },
    {
      yAxis,
      chart: {
        type,
        animation: false,
        resetZoomButton: {
          theme: {
            style: {
              display: 'none'
            }
          }
        }
      },
      navigator: {
        enabled: false
      },
      legend: {
        enabled: true
      },
      noData: {
        style: {
          display: 'none'
        }
      },
      credits: {
        enabled: false
      },
      title: {
        text: zoomSettings.title
      },
      tooltip: {
        valueDecimals: 2
      },
      xAxis:
        graphType === BuildGraphType.Scatter && xAxisTimeSeries?.length > 0
          ? {
              title: {
                text:
                  (seriesById[scatterPlotXAxisSeriesId]?.name ?? '') +
                  ' (' +
                  (seriesById[scatterPlotXAxisSeriesId]?.unit ?? '') +
                  ')'
              }
            }
          : {
              categories,
              min: disableZoom ? undefined : zoomSettings.range[0],
              max: disableZoom ? undefined : zoomSettings.range[1] - 1
            },
      plotOptions: {
        series: {
          cursor: 'pointer',
          // @ts-ignore-next-line
          groupPadding: 0.1,
          pointPadding: 0,
          events: {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            click: (e: any) => {
              onClickIndex(e.point.index);
            }
          }
        }
      },
      series: allSeries.map((series) => {
        const seriesData = transformSeriesData(
          parseTimeSeriesFile(seriesDataById[series.id]),
          xAxisTimeSeries,
          zoomSettings.zoom,
          series.aggregation,
          series.scaling,
          graphType
        );

        const color = currentColors[series.type] ?? '#000000';

        currentColors[series.type] = new Color(color).darken(0.2).hex();

        const subcategoryText =
          series.subcategory != null ? series.subcategory + ' - ' : '';
        let name = series.category + ' - ' + subcategoryText + series.name;
        if (series.unit !== '') {
          name += ' (' + series.unit + ')';
        }

        return {
          type,
          yAxis: series.unit,
          name,
          data: seriesData,
          unit: series.unit,
          color,
          tooltip:
            graphType === BuildGraphType.Scatter
              ? {
                  pointFormatter: function () {
                    let prefix = '';
                    if (xAxisTimeSeries?.length > 0) {
                      prefix =
                        formatNumberUnit(this.x, null) +
                        ' ' +
                        (seriesById[scatterPlotXAxisSeriesId]?.unit ?? '') +
                        ', ';
                    }

                    return (
                      ectoplannerHourNames[this.index] +
                      '<br/><strong>' +
                      prefix +
                      formatNumberUnit(this.y, null) +
                      ' ' +
                      series.unit +
                      '</strong>'
                    );
                  }
                }
              : undefined
        };
      })
    }
  );
};

const EctoplannerGraphBrowser = ({
  build,
  timeseries,
  withPadding = true
}: {
  timeseries: EctoplannerTimeSeries[];
  build?: BuildResponse;
  isRunningCalculation: boolean;
  formIsUpToDate: boolean;
  withPadding?: boolean;
}) => {
  const buildId = build?.id;

  const [isShowingDialog, showDialog, hideDialog] =
    useDialogState('show-edit-graph');
  const [zoomSettings, setZoomSettings] = useState<ZoomSettings>({
    zoom: EctoplannerZoomLevels.YEAR,
    range: [0, 12],
    title: T.ectoplanner.graphs.referenceyear
  });

  const chartComponentRef = useRef(null);

  const { contextSettings } = useContext(TenantContext);
  const seriesById = useMemo(() => {
    return _.keyBy(timeseries, 'id');
  }, [timeseries]);

  const graphStore = useContext(EctoplannerGraphStoreContext);

  const { undo, redo, pastStates, futureStates } =
    graphStore.temporal.getState();

  const markGraphAsSaved = useStore(
    graphStore,
    (store) => store.markGraphAsSaved
  );

  const isLoading = useStore(graphStore, (store) => store.loading);

  const currentCollectionId = useStore(
    graphStore,
    (store) => store.currentCollectionId
  );

  useUndoShortcuts(undo, redo);

  const graphs = useStore(graphStore, (store) => store.graphs);
  const backendGraphs = useStore(graphStore, (store) => store.backendGraphs);
  const updateGraph = useStore(graphStore, (store) => store.updateGraph);

  const setCurrentCollectionId = useStore(
    graphStore,
    (store) => store.setCurrentCollectionId
  );
  const createNewCollection = useStore(
    graphStore,
    (store) => store.createNewCollection
  );

  const duplicateCollection = useStore(
    graphStore,
    (store) => store.duplicateCollection
  );

  const removeGraph = useStore(graphStore, (store) => store.removeGraph);

  const graphOptions: GenericSelectOption<string>[] = useMemo(() => {
    return Object.values(graphs).map((collection) => {
      const isSaved = _.isEqual(collection, backendGraphs[collection.id] ?? {});
      const unsavedSuffix = !isSaved
        ? ' ' + T.ectoplanner.graphs.unsavedsuffix
        : '';
      return {
        label: collection.name + unsavedSuffix,
        value: collection.id
      };
    });
  }, [backendGraphs, graphs]);

  const currentCollection = graphs[currentCollectionId];

  const signalDataQueries = useQueries({
    queries: _.compact(
      currentCollection.series.map((seriesReference) => {
        const series = seriesById[seriesReference.id];

        if (series == null) {
          return null;
        }

        return {
          queryKey: [
            'ectoplanner',
            'graphsignaldata',
            buildId,
            seriesReference.id
          ],
          queryFn: ({ signal }: { signal?: AbortSignal }) => {
            return EctoplannerAPIGen.EctoGridBuilds.timeseriesDetail.promise(
              contextSettings,
              {
                buildId,
                filename: series.id
              },
              signal
            );
          }
        };
      })
    )
  });

  const seriesDataById = useMemo(() => {
    const allData = signalDataQueries.map((query) => query.data);
    const ret: Record<string, string> = {};

    for (let i = 0; i < allData.length; i++) {
      ret[currentCollection.series[i].id] = allData[i];
    }

    return ret;
  }, [signalDataQueries, currentCollection.series]);

  const copyToClipboard = useCallback(() => {
    const graphLines: string[][] = [];

    for (const seriesId of Object.keys(seriesDataById)) {
      const seriesData = seriesDataById[seriesId];
      const seriesInfo = seriesById[seriesId];

      if (seriesData == null || seriesInfo == null) {
        continue;
      }

      const parsedData = parseTimeSeriesFile(seriesData);
      if (currentCollection.graphType === BuildGraphType.DurationCurve) {
        parsedData.sort((a, b) => b - a);
      }

      const dataLines: string[] = [];
      dataLines.push(
        seriesInfo.category +
          ' - ' +
          seriesInfo.name +
          ' (' +
          seriesInfo.unit +
          ')'
      );
      for (let i = 0; i < parsedData.length; i++) {
        dataLines.push(parsedData[i].toString());
      }
      graphLines.push(dataLines);
    }

    const lines: string[] = [];
    const maxNumLines =
      _.max(graphLines.map((graphLine) => graphLine.length)) ?? 0;
    for (let i = 0; i < maxNumLines; i++) {
      let line = '';
      for (const graphLine of graphLines) {
        if (i < graphLine.length) {
          line += graphLine[i] + '\t';
        } else {
          line += '\t';
        }
      }

      lines.push(line);
    }

    const textToCopy = lines.join('\n');

    navigator.clipboard.writeText(textToCopy).then(() => {
      toastStore.addSuccessToast(T.common.copytoclipboard.success);
    });
  }, [currentCollection.graphType, seriesById, seriesDataById]);

  const exportCollection: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      const lines: string[] = [];
      lines.push(
        currentCollection.series
          .map((series) => {
            const seriesInfo = seriesById[series.id];
            if (seriesInfo == null) {
              return '';
            }

            const subCategorySuffix =
              seriesInfo.subcategory != null
                ? ' - ' + seriesInfo.subcategory
                : '';

            return (
              seriesInfo.category +
              ' - ' +
              seriesInfo.name +
              subCategorySuffix +
              ' (' +
              seriesInfo.unit +
              ')'
            );
          })
          .join(',')
      );
      const dataArrays = currentCollection.series.map(
        (series) => seriesDataById[series.id]
      );
      const parsedData = dataArrays.map((array) => parseTimeSeriesFile(array));

      for (let i = 0; i < parsedData[0].length; i++) {
        const vals = parsedData.map((data) => (data[i] == null ? '' : data[i]));
        lines.push(vals.join(','));
      }

      _.defer(() => {
        downloadBlobTextWithoutUTF8BOM(
          lines.join('\n'),
          currentCollection.name + '_export.csv'
        );
      });
    }, [currentCollection, seriesById, seriesDataById]);

  const hasDataForAllSeries = _.every(currentCollection.series, (series) => {
    return seriesDataById[series.id] != null;
  });

  const setCurrentCollection = useCallback(
    (
      collection:
        | BuildGraphResponse
        | ((oldCollection: BuildGraphResponse) => BuildGraphResponse)
    ) => {
      // updateGraph
      const newCollection = _.isFunction(collection)
        ? collection(currentCollection)
        : collection;

      updateGraph(newCollection);
    },
    [currentCollection, updateGraph]
  );

  const zoomOut = useCallback(() => {
    switch (zoomSettings.zoom) {
      case EctoplannerZoomLevels.YEAR: {
        // Can't zoom out of year
        break;
      }
      case EctoplannerZoomLevels.MONTH:
        setZoomSettings({
          zoom: EctoplannerZoomLevels.YEAR,
          range: [0, 12],
          title: T.ectoplanner.graphs.referenceyear
        });
        break;
      default:
      case EctoplannerZoomLevels.DAY:
        {
          // Zoom out to month
          let dayIndex = 0;
          for (
            let monthIndex = 0;
            monthIndex < ectoplannerMonthBins.length;
            monthIndex++
          ) {
            dayIndex += ectoplannerMonthBins[monthIndex];
            if (dayIndex * 24 >= zoomSettings.range[0]) {
              setZoomSettings({
                zoom: EctoplannerZoomLevels.MONTH,
                range: [dayIndex - ectoplannerMonthBins[monthIndex], dayIndex],
                title: ectoplannerLongMonthNames[monthIndex]
              });

              break;
            }
          }
        }
        break;
    }
  }, [zoomSettings]);

  const [
    showingConfirmDeleteDialog,
    showConfirmDeleteDialog,
    hideConfirmDeleteDialog
  ] = useSimpleDialogState();

  const onClickIndex = useCallback(
    (index: number) => {
      if (
        currentCollection.graphType === BuildGraphType.DurationCurve ||
        currentCollection.graphType === BuildGraphType.Scatter
      ) {
        return;
      }

      switch (zoomSettings.zoom) {
        case EctoplannerZoomLevels.YEAR: {
          // User has clicked on a specific month. Zoom to that month.

          let dayIndex = 0;
          for (let curIndex = 0; curIndex < index; curIndex++) {
            dayIndex += ectoplannerMonthBins[curIndex];
          }
          setZoomSettings({
            zoom: EctoplannerZoomLevels.MONTH,
            range: [dayIndex, dayIndex + ectoplannerMonthBins[index]],
            title: ectoplannerLongMonthNames[index]
          });

          break;
        }
        case EctoplannerZoomLevels.MONTH:
          setZoomSettings({
            zoom: EctoplannerZoomLevels.DAY,
            range: [index * 24, index * 24 + 24],
            title:
              zoomSettings.title + ' ' + (index - zoomSettings.range[0] + 1)
          });
          break;
        default:
        case EctoplannerZoomLevels.DAY:
          break;
      }
    },
    [
      currentCollection.graphType,
      zoomSettings.range,
      zoomSettings.title,
      zoomSettings.zoom
    ]
  );

  const highchartOptions = useMemo(() => {
    return getHighchartOptions(
      currentCollection?.series,
      currentCollection.scatterPlotXAxisSeriesId,
      seriesById,
      seriesDataById,
      zoomSettings,
      currentCollection.graphType,
      onClickIndex
    );
  }, [
    currentCollection?.series,
    currentCollection.scatterPlotXAxisSeriesId,
    currentCollection.graphType,
    seriesById,
    seriesDataById,
    zoomSettings,
    onClickIndex
  ]);

  const queryClient = useQueryClient();

  const deleteCollectionMutation =
    EctoplannerAPIGen.EctoGridBuilds.graphsDelete.useMutation({
      onSuccess: () => {
        removeGraph(currentCollectionId);
        queryClient.invalidateQueries({
          queryKey: EctoplannerAPIGen.EctoGridBuilds.graphsDetail.path(
            contextSettings,
            {
              buildId
            }
          )
        });
        hideConfirmDeleteDialog();
      }
    });

  const saveCollectionMutation =
    EctoplannerAPIGen.EctoGridBuilds.graphsUpdate.useMutation(
      {
        buildId,
        graphId: currentCollection.id
      },
      {
        onSuccess: () => {
          markGraphAsSaved(currentCollection.id);
          queryClient.invalidateQueries({
            queryKey: EctoplannerAPIGen.EctoGridBuilds.graphsDetail.path(
              contextSettings,
              { buildId }
            )
          });
        }
      }
    );

  const saveGraph = useCallback(() => {
    saveCollectionMutation.mutate(currentCollection);
  }, [currentCollection, saveCollectionMutation]);

  const currentGraphOption = graphOptions.find(
    (option) => option.value === currentCollectionId
  );

  const collectionSelectProps = getLabeledSelectProps(
    T.ectoplanner.graphs.collection,
    currentGraphOption
  );

  const currentCollectionIsSaved = _.isEqual(
    backendGraphs[currentCollectionId],
    currentCollection
  );

  const onDelete = useCallback(() => {
    deleteCollectionMutation.mutate({
      buildId,
      graphId: currentCollectionId
    });
  }, [buildId, currentCollectionId, deleteCollectionMutation]);

  // Do this convoluted key in order to force Highcharts to rerender when the scatter plot x-axis series
  // is loaded.
  const chartKey =
    currentCollectionId +
    '-' +
    currentCollection.graphType +
    (seriesDataById[currentCollection.scatterPlotXAxisSeriesId]?.length ?? 0);

  return (
    <div className={styles.container}>
      <Toolbar
        className={classNames(styles.toolbar, withPadding && styles.paddedArea)}
      >
        <ToolbarItem>
          <Select
            className={styles.graphSelector}
            options={graphOptions}
            value={currentGraphOption}
            onChange={(option) => {
              setCurrentCollectionId(option.value);
            }}
            isLoading={
              saveCollectionMutation.isPending ||
              deleteCollectionMutation.isPending
            }
            isClearable={false}
            {...collectionSelectProps}
          />
        </ToolbarItem>
        <ToolbarItem>
          <ToolbarMenu>
            <ToolbarMenuButton
              tooltipText={T.common.edit}
              icon={<Icons.Edit />}
              onClick={showDialog}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              tooltipText={T.common.save}
              icon={<Icons.Save />}
              onClick={saveGraph}
              disabled={currentCollectionIsSaved}
            />
            <ToolbarMenuButton
              tooltipText={T.common.undo}
              icon={<Icons.Undo />}
              onClick={() => undo()}
              disabled={pastStates.length === 0}
            />
            <ToolbarMenuButton
              tooltipText={T.common.redo}
              icon={<Icons.Redo />}
              onClick={() => redo()}
              disabled={futureStates.length === 0}
            />
            <ToolbarMenuButton
              tooltipText={T.graphs.deletebutton}
              icon={<Icons.Delete />}
              onClick={showConfirmDeleteDialog}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              tooltipText={T.graphs.editmodal.addtitle}
              icon={<Icons.NewDocument />}
              onClick={() => {
                createNewCollection();
              }}
            />
            <ToolbarMenuButton
              tooltipText={T.graphs.duplicatebutton}
              icon={<Icons.Duplicate />}
              onClick={duplicateCollection}
            />
            <ToolbarMenuDivider />
            <ToolbarMenuButton
              tooltipText={T.ectoplanner.exportcsv}
              icon={<Icons.Download />}
              onClick={exportCollection}
              disabled={
                currentCollection.series.length === 0 || !hasDataForAllSeries
              }
            />
            <ToolbarMenuButton
              tooltipText={T.common.copytoclipboard.tooltip}
              icon={<Icons.Copy />}
              onClick={copyToClipboard}
              disabled={_.isEmpty(seriesDataById)}
            />
          </ToolbarMenu>
        </ToolbarItem>
        <ToolbarFlexibleSpace />
        <ToolbarItem>
          <GreyButton
            onClick={zoomOut}
            disabled={zoomSettings.zoom === EctoplannerZoomLevels.YEAR}
          >
            <Icons.ZoomOut />
            {T.ectoplanner.graphs.zoomout}
          </GreyButton>
        </ToolbarItem>
      </Toolbar>

      <div className={styles.graphContainer}>
        {currentCollection.series.length > 0 && (
          <HighchartsReact
            options={highchartOptions}
            key={chartKey}
            highcharts={Highcharts}
            containerProps={domProps}
            ref={chartComponentRef}
          />
        )}
        {currentCollection.series.length === 0 && !isLoading && (
          <Notice className={classNames(withPadding && styles.paddedArea)}>
            {T.ectoplanner.graphs.nosignalsselected}
          </Notice>
        )}
        {isLoading && (
          <div className={classNames(withPadding && styles.paddedArea)}>
            <Spinner />
          </div>
        )}
      </div>
      <EctoplannerEditGraphDialog
        timeseries={timeseries}
        isOpen={isShowingDialog}
        onModalClose={hideDialog}
        currentCollection={currentCollection}
        setCurrentCollection={setCurrentCollection}
        seriesById={seriesById}
      />
      <ConfirmDeleteDialog
        isOpen={showingConfirmDeleteDialog}
        onModalClose={hideConfirmDeleteDialog}
        itemName={currentCollection.name}
        isLoading={deleteCollectionMutation.isPending}
        onDelete={onDelete}
      />
    </div>
  );
};

export default React.memo(EctoplannerGraphBrowser);
