import React, { useCallback } from 'react';
import _ from 'lodash';
import Icons from 'ecto-common/lib/Icons/Icons';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';
import KeyValueInternalSelectableInput from 'ecto-common/lib/KeyValueInput/Internal/KeyValueInternalSelectableInput';
import SelectSignalTypeDialog from 'ecto-common/lib/SignalTypePicker/SelectSignalTypeDialog';
import T from 'ecto-common/lib/lang/Language';
import { getSignalTypeNameWithUnitFromMap } from 'ecto-common/lib/SignalSelector/SignalUtils';

import styles from './SignalTypePicker.module.css';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';

const components = {
  Menu: () => {
    return <></>;
  }
};

interface SignalTypePickerProps {
  onChange?(signalTypeId: string): void;
  value?: string;
  hasError?: boolean;
  disabled?: boolean;
  selectFolder?: boolean;
  selectOnlyFolders?: boolean;
  showIcon?: boolean;
  nodeId?: string;
  isClearable?: boolean;
}

const SignalTypePicker = ({
  onChange,
  value,
  hasError,
  disabled,
  selectFolder,
  selectOnlyFolders,
  nodeId = null,
  showIcon = true,
  isClearable = false
}: SignalTypePickerProps) => {
  const nodeIdFromRedux = useCommonSelector((state) => state.general.nodeId);
  const signalTypesMap = useCommonSelector(
    (state) => state.general.signalTypesMap
  );
  const signalTypeFoldersMap = useCommonSelector(
    (state) => state.general.signalTypeFoldersMap
  );
  const signalUnitTypesMap = useCommonSelector(
    (state) => state.general.signalUnitTypesMap
  );
  const [dialogIsOpen, showDialog, hideDialog] = useSimpleDialogState();

  let formattedTypeName;

  if (selectFolder) {
    const signalFolder = signalTypeFoldersMap[value];
    formattedTypeName = signalFolder?.name;
  } else {
    formattedTypeName = getSignalTypeNameWithUnitFromMap(
      value,
      signalTypesMap,
      signalUnitTypesMap
    );
  }

  const icon = selectFolder ? <Icons.Folder /> : <Icons.Signal />;

  const options =
    value == null
      ? []
      : [
          {
            value: formattedTypeName,
            label: (
              <>
                {' '}
                {showIcon && icon} {formattedTypeName}
              </>
            )
          }
        ];

  const placeholder = selectOnlyFolders
    ? T.signaltypes.selectfolder
    : T.signaltypes.selecttype;

  const onChangeSelect = useCallback(
    (newValue: unknown) => {
      if (newValue === null) {
        onChange(null);
      }
    },
    [onChange]
  );

  return (
    <>
      <KeyValueInternalSelectableInput
        options={options}
        hasError={hasError}
        isSearchable={false}
        onChange={onChangeSelect}
        onMenuOpen={showDialog}
        value={_.head(options)}
        components={components}
        disabled={disabled}
        placeholder={placeholder}
        className={styles.input}
        isClearable={isClearable}
      />
      <SelectSignalTypeDialog
        isOpen={dialogIsOpen}
        signalTypeId={value}
        onChangeSignalTypeId={onChange}
        onModalClose={hideDialog}
        selectFolder={selectFolder}
        selectOnlyFolders={selectOnlyFolders}
        nodeId={nodeId ?? nodeIdFromRedux}
      />
    </>
  );
};

export default React.memo(SignalTypePicker);
