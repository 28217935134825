import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';

export const getNodePage = (tenantId: string, node: NodeV2ResponseModel) => {
  return `/${tenantId}/home/${node.nodeId}/dashboard`;
};

export const getSignalProvidersPage = (tenantId: string, nodeId: string) => {
  const path = `/${tenantId}/home/${nodeId}/signalproviders`;

  return path;
};

export const getAlarmsPage = (tenantId: string, nodeId: string) =>
  `/${tenantId}/home/${nodeId}/alarms/signalClusters`;
